
import {PureComponent} from 'react'
import FloatingPalette          from '../floatingPalette/FloatingPalette'
import StyleSelectBox           from '../sharedComponents/StyleSelectBox'
import constants                from '../sharedComponents/constants'
import type {TableComputedData} from '../computedDataTable/getDefaultTableComputedData'
import {getTableComputedData}   from '../appCode/getMemoizedComputedData'
import saveTableAsCSV           from '../fp_table_old/saveTableAsCSV'
import type {Delimiter, FormatChoice} from '../fp_table_old/saveTableAsCSV'

const TOTAL_HEIGHT = 480
const TOTAL_WIDTH  = 492
const INITIAL_PLACEMENT_TOP  = constants.TOP_MENUBAR_HEIGHT + 100
const INITIAL_PLACEMENT_LEFT = 200
const LEFT_RIGHT_TEXT_MARGINS = 20
const SELECT_INDENT = 20

// These two variables allow me to retain the selection state between mounts/unmounts.
// OK as I expect only a single usage of this component.
// To be moved to session state when this component moved to new floating pallette
var globalDownloadAllColumns : boolean = false
var globalDownloadAllRows :boolean = false
var globalUseDisplayedColNames : boolean= true
var globalDelimiter : Delimiter = 'comma'     // 'comma' | 'tab' | 'unitSeparator'
var globalFormatChoice : FormatChoice =  'formatted'   // 'internal' | 'formatted' | 'noPrefixSuffixCommas'


type OwnProps = {
  tableId : string, 
  closePopup  : () => void,
}

type LocalState = {
  currentLeft:number,
  currentTop: number,
  downloadAllColumns: boolean,
  downloadAllRows: boolean,
  useDisplayedColNames: boolean, 
  delimiter: Delimiter,
  formatChoice: FormatChoice, 
}

class DownloadTable extends PureComponent<OwnProps, LocalState> {

  constructor (props: OwnProps) {
    super(props)
    this.state = {
      currentLeft: INITIAL_PLACEMENT_LEFT,
      currentTop : INITIAL_PLACEMENT_TOP,
      downloadAllColumns: globalDownloadAllColumns,
      downloadAllRows: globalDownloadAllRows,
      useDisplayedColNames: globalUseDisplayedColNames,
      delimiter: globalDelimiter,
      formatChoice: globalFormatChoice,
    }
  }

  // One for each of four selection options.
  setDownloadAllColumnsTrue = ():void => {
    this.setState({downloadAllColumns:true })
    globalDownloadAllColumns = true
  }
  setDownloadAllColumnsFalse= ():void => {
    this.setState({downloadAllColumns:false})
    globalDownloadAllColumns = false
  }


  setDownloadAllRowsTrue    = ():void => {
    this.setState({downloadAllRows:true })
    globalDownloadAllRows = true
  }
  setDownloadAllRowsFalse   = ():void => {
    this.setState({downloadAllRows:false})
    globalDownloadAllRows = false
  }


  setUseDisplayedColNamesTrue    = ():void => {
    this.setState({useDisplayedColNames:true })
    globalUseDisplayedColNames = true
  }
  setUseDisplayedColNamesFalse   = ():void => {
    this.setState({useDisplayedColNames:false})
    globalUseDisplayedColNames = false
  }


  setDelimiterComma = ():void => {
    this.setState({delimiter:'comma' })
    globalDelimiter = 'comma'
  }
  setDelimiterTab= ():void => {
    this.setState({delimiter: 'tab'})
    globalDelimiter = 'tab'
  }
  setDelimiterUnitSeparator= ():void => {
    this.setState({delimiter:'unitSeparator'})
    globalDelimiter = 'unitSeparator'
  }


  setValuesFormat_internalPrecision = ():void => {
    this.setState({formatChoice:'internal'})
    globalFormatChoice = 'internal'
  }
  setValuesFormat_noPrefixSuffixCommas  = ():void => {
    this.setState({formatChoice:'noPrefixSuffixCommas'})
    globalFormatChoice = 'noPrefixSuffixCommas'
  }
  setValuesFormat_withPrefixSuffixCommas  = ():void => {
    this.setState({formatChoice:'formatted'})
    globalFormatChoice = 'formatted'
  }


  onActiveDrag = (left:number, top:number) : void => {
    // Constraint the position:
    top = Math.max( 20, top )
    top = Math.min( window.innerHeight - TOTAL_HEIGHT - 20, top )
    left= Math.max( 20, left )
    left= Math.min( window.innerWidth - TOTAL_WIDTH - 20, left )
    this.setState( {currentLeft:left, currentTop:top} )
  }

  handleTableDownload = ():void => {
    const tableComputedData = getTableComputedData( this.props.tableId )
    if (!tableComputedData) {return}
    const {downloadAllColumns, downloadAllRows, delimiter,
             useDisplayedColNames, formatChoice} = this.state
    //code splitting to reduce initial load size
    saveTableAsCSV(tableComputedData, downloadAllColumns, downloadAllRows,
                               useDisplayedColNames, delimiter, formatChoice)
    this.props.closePopup()
  }

  render() {
    const {closePopup, tableId} = this.props
    if (!tableId) { return null }
    const tableComputedData = getTableComputedData( tableId )
    if ( !tableComputedData) { return null }
    const {table, tablelook, tabledata, sortedRowKeys} = tableComputedData
    const {currentLeft, currentTop} = this.state
    const year = table.attributes.createdDate?.slice(0,4)
    const title= `"${table.attributes.tableTitle}"`
    const URL= `http://datatables/table/${table.id}`
    const numAllRows = tabledata.attributes.tableValues[0].length
    const numSortedRowKeys = sortedRowKeys.length
    var numAllCols = 0
    var numVisibleCols = 0
    const columns = table.attributes.columns
    const lookColumns = tablelook.attributes.lookColumns
    columns.forEach( (thisCol,i)=>{
      if ( thisCol.isDeleted ) { return }
      numAllCols++
      if ( lookColumns[i].hidden > 0 ) { return }
      numVisibleCols++
    })


    return (

      <FloatingPalette
        left={currentLeft}
        top={currentTop}
        height={TOTAL_HEIGHT}
        width={TOTAL_WIDTH}
        onClose={closePopup}
        onActiveDrag={this.onActiveDrag}
        title='Download Table:'
        isDraggable={true}
      >

        <div className={'rc_DownLoadTable'}
          style={{ fontSize:14}} >

          <div className={'spacer'} style={{height:10}}/>

          <div
              style={{
                paddingLeft: LEFT_RIGHT_TEXT_MARGINS,
                paddingRight: LEFT_RIGHT_TEXT_MARGINS,
                width: '100%',
                display: 'inline-block',
                verticalAlign: 'top',
              }}
          >
              {'If distributing this data set, credit the original sources (found in the main title description), ' +
               'this table, or both.  The reference for this table is:' }
          </div>

          <div className={'spacer'} style={{height:6}}/>
          <div
              style={{
                paddingLeft: LEFT_RIGHT_TEXT_MARGINS,
                paddingRight: LEFT_RIGHT_TEXT_MARGINS,
                width: '100%',
                display: 'inline-block',
                verticalAlign: 'top',
                background: 'rgb(255,231,240)'
              }}
          >
            { `${title}, ${year}, ` + URL }
          </div>

          <StyleSelectBox 
            indent={SELECT_INDENT}
            id={'downloadAllColumns'}
            preSpacer={20}
            onClick={ this.setDownloadAllColumnsTrue }
            isSelected={ this.state.downloadAllColumns }
            text={`Include all columns (${numAllCols}) in original order.`}
            foregroundColor={'#010101'} />
          <StyleSelectBox 
            indent={SELECT_INDENT}
            id={'downloadVisibleColumns'}
            preSpacer={0}
            onClick={ this.setDownloadAllColumnsFalse }
            isSelected={ !this.state.downloadAllColumns }
            text={`Include only visible, not hidden columns (${numVisibleCols}) in current order.`}
            foregroundColor={'#010101'} />



          <StyleSelectBox
            indent={SELECT_INDENT}
            id={'downloadAllRows'}
            preSpacer={20}
            onClick={ this.setDownloadAllRowsTrue }
            isSelected={ this.state.downloadAllRows }
            text={`Include all rows (${numAllRows}) in original order.`}
            foregroundColor={'#010101'} />
          <StyleSelectBox
            indent={SELECT_INDENT}
            id={'downloadVisibleRows'}
            preSpacer={0}
            onClick={ this.setDownloadAllRowsFalse }
            isSelected={ !this.state.downloadAllRows }
            text={`Include only filtered visible rows (${numSortedRowKeys}) in sorted order.`}
            foregroundColor={'#010101'} />


          <StyleSelectBox 
            indent={SELECT_INDENT}
            id={'useDisplayedColNames'}
            preSpacer={20}
            onClick={ this.setUseDisplayedColNamesTrue }
            isSelected={ this.state.useDisplayedColNames }
            text={`Use the displayed column names (currently visible in table).`}
            foregroundColor={'#010101'} />
          <StyleSelectBox 
            indent={SELECT_INDENT}
            id={'useSourceDataColNames'}
            preSpacer={0}
            onClick={ this.setUseDisplayedColNamesFalse }
            isSelected={ !this.state.useDisplayedColNames }
            text={`Use source data column names (from the originally loaded CSV).`}
            foregroundColor={'#010101'} />


          <StyleSelectBox
            indent={SELECT_INDENT}
            id={'delimiterComma'}
            preSpacer={20}
            onClick={ this.setDelimiterComma }
            isSelected={ this.state.delimiter === 'comma' }
            text={`Delimiter: comma  (The character separating column values.)`}
            foregroundColor={'#010101'} />
          <StyleSelectBox
            indent={SELECT_INDENT}
            id={'delimiterTab'}
            preSpacer={0}
            onClick={ this.setDelimiterTab }
            isSelected={ this.state.delimiter === 'tab' }
            text={`Delimiter: tab `}
            foregroundColor={'#010101'} />
          <StyleSelectBox
            indent={SELECT_INDENT}
            id={'delimiterUnitSeparator'}
            preSpacer={0}
            onClick={ this.setDelimiterUnitSeparator }
            isSelected={ this.state.delimiter === 'unitSeparator' }
            text={'Delimiter: Unit_Separator  (asci character #31)'}
            foregroundColor={'#010101'} />



          <StyleSelectBox
            indent={SELECT_INDENT}
            id={'internal'}
            preSpacer={20}
            onClick={ this.setValuesFormat_internalPrecision }
            isSelected={ this.state.formatChoice === 'internal' }
            text={`Values: Output the internal (full precision) values.`}
            foregroundColor={'#010101'} />
          <StyleSelectBox
            indent={SELECT_INDENT}
            id={'noPrefixSuffixComma'}
            preSpacer={0}
            onClick={ this.setValuesFormat_noPrefixSuffixCommas }
            isSelected={ this.state.formatChoice === 'noPrefixSuffixCommas' }
            text={`Values: Use formatting, but no Prefix, Suffix, or numerical commas.`}
            foregroundColor={'#010101'} />
          <StyleSelectBox
            indent={SELECT_INDENT}
            id={'withPrefixSuffixComma'}
            preSpacer={0}
            onClick={ this.setValuesFormat_withPrefixSuffixCommas }
            isSelected={ this.state.formatChoice === 'formatted' }
            text={`Values: Use formatting, including Prefix, Suffix, and commas.`}
            foregroundColor={'#010101'} />

              

          <div className={'spacer'} style={{height:6}}/>

          <button className={'downloadButton'}
            onClick={ this.handleTableDownload  }
            style={{
              fontSize: 14, fontWeight: 'bold',
              display: 'block',
              height: 30, width: 90,
              position: 'relative', left: 180, top: 10,
              borderRadius: 6, borderStyle: 'outset', borderWidth: 2,
              borderColor: constants.COLHEADER_INPUT_BORDER_COLOR,
              verticalAlign: 'center',
            }}>
              {'Download'}
          </button>

        </div>

      </FloatingPalette>
    )
  }
}

export default DownloadTable
