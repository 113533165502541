import type { Property } from 'csstype'
import invariant from 'invariant'
import { isEqual } from 'radash'
import React, { PureComponent } from 'react'
import createTapListener from 'teeny-tap'
import {encode as heEncode} from 'he'
import rStateFloatingPalette from '../floatingPalette/rStateFloatingPalette'
import { isScryHyperlink_fromUserInputs } from '../sharedFunctions/isTypeHyperlink'
import constants from '../sharedComponents/constants'
import getPathInTapListener from '../sharedComponents/getPathInTapListener'
import reactDispatch from '../sharedComponents/reactDispatch'
import { cleanScryInputText2 } from '../sharedFunctions/cleanScryInputText'
import { getCursorXY } from '../sharedFunctions/measureText'
import { deepClone, typedKeys } from '../sharedFunctions/utils'
import type { TextWithLinks, EmbeddedLink } from '../types'
import HyperlinkButton from './HyperlinkButton'
import HyperlinkEditor from './HyperlinkEditor'

const DEBUG = false
const shouldPut_textwithlinks_TypeOntoClipboard = true
const FONT_SIZE_description = constants.DESCRIPTION_EDITOR_FONT_SIZE

export type TapListener = any

const LinkDelimiterStart: string = '@KnIl@'
const LinkDelimiterStop: string = '@KnIl@'
// Next regexp captures the link string, either enclosed in double quotes, or single quotes.
// The link text is saved in group[1] ( match[2] )
// The text will need to have the leading/trailing quote characters stripped
const regexpLink : RegExp = new RegExp( `<a([^>]+)>(.+?)</a>`, 'i' )
const regexpHREF : RegExp = new RegExp( `s*hrefs*=s*("([^"]*")|'[^']*'|([^'">s]+))`, 'i' )

/* NOTE: Cursor and selectionStart/End are NOT
at a character.  They fall BETWEEN characters!
Easy to remember because cursorPosition[0]
must be before character[0].  If I have a string.length === 10,
then cursor[0] is a placement before string[0] character.
And cursor[10] is a placement after the string[9] character.
There is always one more cursor position than number of characters.

Our current whitespace rules say every blackText or link must
end in a space:


          space                     space

    ...text ╰─╯ | link    ...   link ╰─╯ | text ...
                |                        |
                |                        |
                 -->                     -->
            Cursor here                  Cursor here
          belongs to link.             belongs to text.
            It will be                   It will be
          link colored.                black colored.
          An insert here               An insert here
          belongs to link.             belongs to text.

*/


type ParentMode = '' | 'cellEditor' | 'colDescription' | 'tableDescription' | 'plotDescription' | 'plotSeriesDescription'

type OwnProps = {
  width: number,
  labelStyle: Object,

  heightLabel: number,
  heightMaxViewMode: number,
  heightEditMode: number,

  // Next parameter is option and default value is null.
  // Null means isActive is controlled by this component.
  // forceActive allows the parent to force this component
  // to be active or inActive.
  // Why: because of the way tapListeners work, it is possible
  // for the parent to know the activeState of this component,
  // BEFORE the component itself knows it is active!!  Odd by true.
  // Easiest fix is to give the parent optional control to force
  // this component to either the active or inactive state.
  forceActive: boolean,

  tableid: string,   // Needed to save state
  plotid: string,

  colOrSeriesKey: number,
  canEdit: boolean,
  parentMode: ParentMode,
  textWithLinks: TextWithLinks,
  passErrMsgToParent: (errMsg: string) => void,
  passActiveStateToParent: (activeState: boolean) => void
}

type DefaultProps = {
  tableid: string
  plotid: string
  plotOrSeriesKey: number
  passActiveStateToParent: (activeState: boolean) => void
  forceActive: boolean
}

type Props = OwnProps & DefaultProps

type LocalState = {
  lastColOrSeriesKey: number,
  lastParentMode: ParentMode,
  lastTextWithLinks: TextWithLinks,

  txtValue: string,
  links: EmbeddedLink[],
  cursorPosition: number,
  isInLink: boolean,
  linkIndex: number,
  parentBorderColor: string,
  parentBorderStyle: string,
  linkName: string,
  linkUrl: string,
  errMsg: string,
  badLinksArray: number[],
  // Next is height a the self-sizing div.
  // Depends on number of text lines user has entered.
  empericallyMeasuredSelfSizingDivHeight: number,
  isHyperlinkPositionVisible: boolean, // Don't display editor if/when link scrolled beyond visible view.
  isHyperlinkPositionTop: boolean , // Is the current selected link in topHalf of textEdit window?
                                    // Determines whether the hyperlink editor is display in the
                                    // top or bottom half of the textEditor window.
  priorSelStart: number
  priorSelEnd: number
  isActive: boolean, // True when user focus or click in: textEditor, createLink button, HyperlinkEditor
}


export default class EditTextWithLinks extends PureComponent<Props, LocalState> {

  constructor(props: Props) {
    super(props)
    this.state={
      lastColOrSeriesKey: -1,
      lastParentMode: '',
      lastTextWithLinks: { text:'', links:[] },
      txtValue: '',   // Synch'ed with the textArea.value
      links: [],
      parentBorderColor: constants.COLHEADER_INPUT_BORDER_COLOR,
      parentBorderStyle: 'inset',
      cursorPosition: 0,
      isInLink: false,
      linkIndex: -1,
      linkName: '',
      linkUrl : '',
      errMsg: '',
      badLinksArray: [],
      empericallyMeasuredSelfSizingDivHeight: 0,
      isHyperlinkPositionVisible: false,
      isHyperlinkPositionTop: true,
      priorSelStart: 0,
      priorSelEnd: 0,

      isActive: false,
    }
    // This listener captures when the cursor changes position!  When clicking
    // through text, are we in a link or not?
    document.addEventListener( 'selectionchange', this.handleSelectionChange )
  }

  static defaultProps: DefaultProps = {
    tableid: '',
    plotid: '',
    plotOrSeriesKey: -1,
    passActiveStateToParent: ()=>{},
    forceActive: false,
  }

  /*  TAP LISTENER:
      We wish to know when the user is actively editing the textArea.
      When editing the textArea, one of next two will always be visible,
      depending on whether the current cursor position is Inside a link.
          1) !isInLink  (black text area)  Then show Selection->Create Link button
          2)  isInLink  (blue  test area)  Then show the HyperlinkEditor tool.
      And click outside the EditTextArea
  */


  tapListener: TapListener | null = null
  componentDidMount = ()=>{ this.setEmpericallyMeasuredSelfSizingDivHeight() }
  componentDidUpdate = ()=>{
    this.setEmpericallyMeasuredSelfSizingDivHeight()
    if (!this.tapListener && rStateFloatingPalette.floatingPaletteDomNode) {
      this.tapListener = createTapListener(rStateFloatingPalette.floatingPaletteDomNode, this.handleTap)
    }
  }
  componentWillUnmount = () => {
    if ( this.props.canEdit && this.timeoutID !== null ) {
      const { txtValue, links } = this.state
      this.updateReactState( txtValue, links )
    }
    document.removeEventListener( 'selectionchange', this.handleSelectionChange )
    if (this.tapListener) {
      this.tapListener.remove()
      this.tapListener = null
    }
  }


  handleTap = ( e: React.MouseEvent<HTMLDivElement> ) => {

    // Require two tests for EditTextWithLinks Parent/Children losing focus:
    // Test #1; Was there a mouse event outside rc_EditTextWithLinks (the parent and children)
    // Test #2; Is the current focus outside 'overlayTextAreaWithTransparentFont'
    // Why:  MouseDown in textArea + mouseUp in tapListener area is interpreted as:
    //       - textArea retains the focus
    //       - tapListener says mouse click() outside of textArea.
    const path = getPathInTapListener(e)
    var isTapOutsideTextWithLinks = ! path.some( thisClassName => {
      return    (thisClassName === 'rc_EditTextWithLinks')
    })
    var isFocusOutside_TextAreaWithTransparentFont =
      (document.activeElement?.className !== 'overlayTextAreaWithTransparentFont')

    //if ( isTapOutsideTextWithLinks ) {
    if ( isTapOutsideTextWithLinks && isFocusOutside_TextAreaWithTransparentFont ) {
      // DEBUGGING handletap !!!!!!!
      // Next console.log is not output to the console until well after this
      // function has been called.  Reason unknown.  Happens to two nearly identical
      // tapHandlers if EditTextWithLinks and EditColFormula
      // Put and console.log into the parentComponent function: this.props.passActiveStateToParent
      // A console.log at that location appears to print 'temporally' in the correct order of execution.
      //console.log( 'textWithLinks handleTap: setState and passActiveStateToParent -- both false' )
      this.setState({isActive:false})
      this.props.passActiveStateToParent(false)
      // This is also the onBlur handler.  So 'unset' the blue selection border.
      if ( this.editorParentDiv ) {
        this.editorParentDiv.style.borderColor = constants.COLHEADER_INPUT_BORDER_COLOR
        this.setState({
          parentBorderColor: constants.COLHEADER_INPUT_BORDER_COLOR,
          parentBorderStyle: 'inset',
        })
      }

          // Chrome allows us to set cursor position and cursor in text area is shown
          // even though the textArea in Chrome is NOT active.

          // Safari forces the textArea to 'Focus' is we set the cursor position.
          // Where-as this is clearly a problem as this function's purpose is
          // to recognize cases where the TextWithLinks becomes in-active.
      //let n = this.textAreaNode
      //if ( n ) { n.setSelectionRange( this.cursorPosition, this.cursorPosition ) }

    }
  }



  hyperlinkEditorNameNode: HTMLInputElement | null = null // Needs to be known here so we can pass it focus
  setHyperlinkEditorNameNode = ( n : HTMLInputElement | null ) => { this.hyperlinkEditorNameNode = n }

  // Internal state values that do NOT force a rerender.
  TIMEOUT_DELAY = 300
  timeoutID: NodeJS.Timeout | string | number | undefined = undefined

  // DO CHANGE these values while processing edits:
  // Global only because every editing function processes these values.
  // Working values eventually saved to state.
  // ASSUMPTION is only one description component is open at any give time.
  currentCursor: number = 0                 // After processing, sets textArea.selectionStart & textArea.selectionEnd
  currentValue : string = ''                // After processing, sets state.txtValue  & textArea.value
  currentLinks : EmbeddedLink[] = []   // After processing, sets state.links


  // Next function for debugging as to what properties were
  // modified in the inputProps/local state.  Just change
  // the name to be the valid react ComponentUpdate call.
  shouldComponentUpdate2( nextProps: OwnProps, nextState: LocalState ) {
    console.log( 'EditTextWithLinks.shouldComponentUpdate2() for debugging')
    for (const thisKey of typedKeys(this.props)) {
      if (nextProps[thisKey] !== this.props[thisKey]) {console.log('  PropChanged:', thisKey)}
    }
    for (const thisKey of typedKeys(this.state)) {
      if (nextState[thisKey] !== this.state[thisKey]) {console.log( '  StateChanged:', thisKey)}
    }
    return true
  }

  static getDerivedStateFromProps( nextProps:OwnProps,   prevState:LocalState ) {
    // We re-initialize the local state whenever the parentMode or colOrSeriesKey changes:
    // Otherwise the local state is tracking and managing edits and display.
    const { textWithLinks, parentMode, colOrSeriesKey, forceActive } = nextProps
    const { text, links} = textWithLinks
    var   stateModifications = {}

    // Get a potentially 'active' link information (cursor within a link):
    var {isInLink, linkName, linkUrl, linkIndex } =
            EditTextWithLinks.getLinkInfo( links, text, prevState.cursorPosition )

    // Above isInLink test uses the cursor position.  But if user is making a selection,
    // above test doesn't guarantee the ENTIRE selection is inside a link.
    // If ANY of a selected group of text falls outside the link, consider isInLink to be false.
    const {priorSelStart:selStart, priorSelEnd:selEnd} = prevState
    if ( isInLink && (selStart < links[linkIndex].startCharIndex ||
                      selEnd   > links[linkIndex].stopCharIndex )) { isInLink = false }

    // Error check the TextWithLinks object
    // Pass errMsg to the parent (usually an empty string!)
    const {errMsg, badLinksArray} = EditTextWithLinks.errorCheckTextWithLinks(links, text, isInLink, linkIndex)
    if ( errMsg !== prevState.errMsg ) {
      nextProps.passErrMsgToParent(errMsg)
      stateModifications = { ...stateModifications, errMsg }
    }
    if ( errMsg !== prevState.errMsg || ! isEqual(badLinksArray, prevState.badLinksArray) ) {
      stateModifications = { ...stateModifications, badLinksArray }
    }

    // isActive uses the current value -- UNLESS input prop forceActive is set!
    const nextIsActive = forceActive !== null ? forceActive : prevState.isActive
    stateModifications = { ...stateModifications, isActive:nextIsActive,
                           isInLink, links, linkName, linkUrl, linkIndex }

    if ( parentMode     !== prevState.lastParentMode || colOrSeriesKey !== prevState.lastColOrSeriesKey ) {
      stateModifications = { ...stateModifications, lastParentMode:parentMode,
          lastColOrSeriesKey: colOrSeriesKey, lastTextWithLinks: textWithLinks, txtValue:text }
    }
    // State changes due to editing the hyperlink Editor component:
    else if ( textWithLinks !== prevState.lastTextWithLinks ) {
      stateModifications = { ...stateModifications, lastTextWithLinks: textWithLinks, txtValue:text }
    }
    return stateModifications
  }



  // Next handlers are to manage the overlay editors 'focus' highlight.
  // The input with focus is the top 'transparent text' textArea.
  // However, this textArea may be taller or shorter than the parentDiv which
  // contains the scroll bar.  So when the textArea recieves focus the focus
  // highlight does not align with the container the user sees.
  // So we remove the highlight from the textArea, and use the textArea
  // onFocus and onBlue to instead highlight the container they see.
  handleFocus = ( ) => {
    // Highlight the parent container
    if ( this.editorParentDiv ) {
      this.editorParentDiv.style.borderColor = constants.SCRY_SELECTION_HIGHTLIGHT_COLOR
      this.setState({
        parentBorderColor: constants.SCRY_SELECTION_HIGHTLIGHT_COLOR,
        parentBorderStyle: 'solid',
        isActive: true,
      })
      this.props.passActiveStateToParent(true)
    }
    // Textarea will (by default) place the cursor at position zero onFocus().
    // We want to re-set the cursor to it's last position, either set at the
    // time of onBlur(), or where it was placed while last synching the textArea
    // to the active focused label input.  Otherwise, if/when we re-enter the component,
    // the default cursor position at zero does NOT match the current saved state.
    // --- Hardly noticeable, unless one puts the cursor at position 0 when returning
    // the focus.  And the handleSelectionChange() does not trigger! ---
    if ( this.textAreaNode ) {
      this.textAreaNode.setSelectionRange( this.currentCursor, this.currentCursor )
    }
    this.setState({priorSelStart: this.currentCursor, priorSelEnd:this.currentCursor})
  }



  handleSelectionChange = ( ) => {
    var element : HTMLTextAreaElement | null = document.activeElement ? document.activeElement as HTMLTextAreaElement : null
    if ( element && element === this.textAreaNode ) {
      let priorSelStart = element.selectionStart
      let priorSelEnd   = element.selectionEnd
      this.currentCursor = priorSelEnd
      var {isInLink, linkName, linkUrl, linkIndex} =
               EditTextWithLinks.getLinkInfo( this.state.links, this.state.txtValue, this.currentCursor )
      // Do NOT consider the cursor isInLink when selStart !== selEnd.
      // Meaning, open the hyperlink editor whenever cursor is inside a link, except when
      // user is selecting text for cut/copy/paste.  Selecting text identified by setStart !== selEnd

      isInLink = (priorSelStart !== priorSelEnd ) ? false : isInLink
      var {isHyperlinkPositionVisible, isHyperlinkPositionTop} = this.getSelectedLinkPosition( )
      this.setState({ isInLink, linkName, linkUrl, linkIndex, cursorPosition:this.currentCursor,
        isHyperlinkPositionTop, isHyperlinkPositionVisible,
        priorSelStart, priorSelEnd})
    }
  }

  // Prevent the tab key from default behavior of exiting the editor
  handleKeyDown_TabKey = ( e: React.KeyboardEvent<HTMLTextAreaElement>) : void => {
    if ( e.key === 'Tab' ) {
      const target = e.target as HTMLTextAreaElement
      var cursor = target.selectionStart
      var priorText = target.value.substring(0,cursor)
      var priorLineFeed = cursor
      while ( priorLineFeed > 0 && priorText[priorLineFeed] !== '\n' ) { priorLineFeed-- }
      var cursorPositionInThisLine = cursor - priorLineFeed - 1 // -1 because cursor is always 1 greater then the preceeding char
      var mod4 = cursorPositionInThisLine % 4
      var numSpacesToNextTab = (mod4 === 0 ) ? constants.FORMULA_INDENT_LENGTH : constants.FORMULA_INDENT_LENGTH - mod4
      var indentStrg = (' ').repeat(numSpacesToNextTab)
      var newValue = priorText + indentStrg + target.value.substring(cursor)
      var newCursor= cursor + numSpacesToNextTab
      var newLinks   = this.adjustLinksAfterInsertion( this.currentLinks, cursor, numSpacesToNextTab )
      var result = this.applySpacingRules( newValue, newLinks, newCursor, true )
      this.currentValue  = result.value
      this.currentLinks  = result.links
      this.currentCursor = result.cursor
      target.value = result.value.slice()
      target.setSelectionRange( result.cursor, result.cursor )
      var { isInLink, linkName, linkUrl, linkIndex } =
                 EditTextWithLinks.getLinkInfo( result.links, result.value, result.cursor )
      this.setState({ isInLink, linkName, linkUrl, linkIndex, txtValue: result.value,
         errMsg:'', links: result.links, cursorPosition:result.cursor,
          priorSelStart:result.cursor, priorSelEnd: result.cursor })

      clearTimeout(this.timeoutID)
      const {value, links} = result
      this.timeoutID = setTimeout( () => {this.updateReactState(value, links)}, this.TIMEOUT_DELAY)
      e.preventDefault()
    }
  }

  // Function ONLY called by debug_annotateText2
  // Adds an underline accent to all link characters.
  // So the links are easily visible in our debug statements.
  debug_underlineLinks = ( strg: string, links:EmbeddedLink[], startIndex:number ) : string => {
      var workingStrg = ''
      for ( let charIndex=0; charIndex < strg.length; charIndex++) {
        var originalIndex = charIndex+startIndex
        var isLinkChar = false
        for (let i=0; i < links.length; i++) {
          let {startCharIndex, stopCharIndex} = links[i]
          if (originalIndex >= startCharIndex && originalIndex < stopCharIndex ) { isLinkChar = true}
        }
        if (isLinkChar) {
          workingStrg += strg[charIndex] + '\u0332'
        } else {
          workingStrg += strg[charIndex]
        }
      }
      return workingStrg
  }

  // Called only when DEBUG === true
  debug_annotateText2 = ( text:string, links: EmbeddedLink[], startCursor:number, endCursor:number ) => {
    var front  = text.slice( 0, startCursor )
    var middle = text.slice( startCursor, endCursor )
    var back   = text.slice( endCursor )
    front  = this.debug_underlineLinks( front,  links, 0 )
    middle = this.debug_underlineLinks( middle, links, startCursor )
    back   = this.debug_underlineLinks( back,   links, endCursor )
    var out : string = `"${front}|${middle}|${back}"`
    console.log ( out )
  }


  // Is the cursor position in a link or not?
  static getLinkInfo = ( links:EmbeddedLink[], value:string, cursorPosition:number ) :
              {isInLink:boolean, linkIndex:number, linkName:string, linkUrl:string} => {
      var linkIndex = -1   // Assumption;  Means cursor NOT currently in a link.
      var isInLink = false // assumption
      var linkName = ''    // assumption
      var linkUrl  = ''    // assumption
      links.forEach( (link,i) => {
        let {startCharIndex, stopCharIndex} = link
        if (cursorPosition >= startCharIndex && cursorPosition < stopCharIndex) {
          linkIndex= i
          isInLink = true
          linkName = value.slice( startCharIndex, stopCharIndex )
          linkUrl  = links[i].href
        }
      })
      return {isInLink, linkIndex, linkName, linkUrl}
  }

  static errorCheckTextWithLinks = (links:EmbeddedLink[], text:string,
         isInLink:boolean, linkIndex:number) :
         {errMsg:string, badLinksArray: number[]} => {
    // 1st priority:  Missing description
    if ( text.trim() === '' ) {
      return {errMsg:'Missing a description', badLinksArray:[] }
    }
    // 2nd priority:  If hyperlink editor is open, any error associated with the the open link.
    if ( isInLink ) {
      let {errorMsg} = isScryHyperlink_fromUserInputs( 'do not care', links[linkIndex].href )
      if ( errorMsg !== '' ) { return {errMsg:errorMsg, badLinksArray:[linkIndex]} }
    }
    // If no error in the open link:'
    // 3rd Priority: No line feeds (returns) inside a link!
    for ( let i=0; i<links.length; i++ ) {
      let {startCharIndex, stopCharIndex} = links[i]
      const linkText = text.slice( startCharIndex, stopCharIndex )
      if ( linkText.indexOf('\n') >= 0 ) {
        return {errMsg:'Line feeds (returns) not allowed inside a link', badLinksArray:[i] }
      }
    }

    // 4rth Priority: Adjacent links  (May look like one link to a quick reader)
    for ( let i=0; i<links.length-1; i++ ) {
      var isAdjacent = EditTextWithLinks.isAdjacentLink( links, text, i )
      if (isAdjacent) {
        return {errMsg:'Insert lineFeed or text between adjacent links', badLinksArray:[i,i+1] }
      }
    }
    // 5th priority
    // Count number of bad links;
    // Should never include the current isInLink as bad, because this would
    // already be caught as the 2nd priority.
    var   numberBadLinks = 0
    const badLinksArray: number[] = []
    links.forEach( (thisLink,i) => {
      let {errorMsg} = isScryHyperlink_fromUserInputs( 'do not care', thisLink.href )
      if ( errorMsg !== '' ) {
        numberBadLinks++
        badLinksArray.push( i )
      }
    })
    if (numberBadLinks > 0) {
      return {errMsg: `Found ${numberBadLinks} bad links.`, badLinksArray}
    }
    // expected return path -- No errors:
    return {errMsg:'', badLinksArray:[]}
  }



  // Two adjacentLinks have no black characters, nor a linefeed between them.
  // To the reader, they will appear as one link (although close inspection
  // will reveal the link underline is NOT continuous between the two links.)
  static isAdjacentLink = (links:EmbeddedLink[], text:string, linkIndex:number): boolean => {
    // Return True if this linkIndex is the FIRST index of two adjacent links.
    // (Function will always return false for the last index)
    if ( linkIndex >= links.length - 1 ) { return false } // last index
    const startBlackText = links[linkIndex].stopCharIndex
    const stopBlackText  = links[linkIndex+1].startCharIndex
    // Is a lineFeed or black text found between links?
    const textBetweenLinks = text.slice( startBlackText, stopBlackText )
    if ( textBetweenLinks.indexOf('\n') >= 0 ) return false
    if ( textBetweenLinks.trim().length >  0 ) return false
    return true
  }


  // This function tells me where in the visible edit text area is a selected hyperlink.
  // Is is near top half or the bottom half??
  // We will place the position of the hyperlink editor accordingly.
  getSelectedLinkPosition = ( ) => {
    var isHyperlinkPositionTop = true // assumption
    var isHyperlinkPositionVisible = false // assumption
    const {textAreaNode, editorParentDiv, currentCursor} = this
    const {heightEditMode} = this.props
    if (textAreaNode && editorParentDiv && currentCursor >= 0 ) {
      var {y} = getCursorXY( textAreaNode, currentCursor )
      var cursorYposition = y - editorParentDiv.scrollTop
      // 50 emperically determined by watching to make sure a link in center
      // of edit area is visible, regardless of whether the hyperlink editor is
      // placed near the top or the bottom of the textArea.
      isHyperlinkPositionTop = cursorYposition > 50
      isHyperlinkPositionVisible = (cursorYposition > -5 && cursorYposition < heightEditMode - 20)
    }
    return {isHyperlinkPositionTop, isHyperlinkPositionVisible}
  }



  onScrollHandler = () => {
    const {isHyperlinkPositionTop, isHyperlinkPositionVisible} = this.getSelectedLinkPosition( )
    this.setState({isHyperlinkPositionTop, isHyperlinkPositionVisible})
  }



  adjustLinksAfterInsertion = ( links: EmbeddedLink[], insertPosition:number, insertLength:number ) : EmbeddedLink[] => {
      /*  PASTE (INSERT) RULES:

      link:        <------------------------>         startCharIndex    stopCharIndex
      paste: ****                                    += numCharPaste   += numCharPaste

      link:        <------------------------>         startCharIndex    stopCharIndex
      paste: ****************                        += numCharPaste   += numCharPaste

      link:        <------------------------>         startCharIndex    stopCharIndex
      paste: *************************************   += numCharPaste   += numCharPaste

      Everything above, the characters are pasted to preceding black text.
      Everything below, we paste to the link:

      link:        <------------------------>           no change       stopCharIndex
      paste:       ******                                              += numCharPaste

      link:        <------------------------>           no change       stopCharIndex
      paste:       ******************************                      += numCharPaste

      link:        <------------------------>           no change       stopCharIndex
      paste:               ****                                        += numCharPaste

      link:        <------------------------>           no change       stopCharIndex
      paste:               **********************                      += numCharPaste

      Two cases below: The paste is to the following black text or subsequent substrings.
      No change in current link.

      link:        <------------------------>           no change         no change
      paste:                                ****

      link:        <------------------------>           no change         no change
      paste:                                   *****
      */

      if ( insertLength === 0 ) { return links }
      for (let i=0; i < links.length; i++ ) {
        var {startCharIndex, stopCharIndex} = links[i]

        // Rules for link start position
        if ( insertPosition >  startCharIndex) { }
        else                                   { links[i].startCharIndex += insertLength}

        // Rules for link stop position
        if ( insertPosition >= stopCharIndex ) { }
        else                                   { links[i].stopCharIndex  += insertLength }
      }
      return links
  }



  adjustLinksAfterDeletion = ( links: EmbeddedLink[], deleteStart:number, deleteEnd:number ) : EmbeddedLink[] => {
      /*  CUT (DELETE) rules:

      link:        <------------------------>         startCharIndex     stopCharIndex
      cut:  ****                                     -= deleteLength    -= deleteLength

      link:        <------------------------>          deleteStart       stopCharIndex
      cut:  ****************                                            -= deleteLength

      link:        <------------------------>          deleteStart       stopCharIndex
      cut:         *********                                            -= deleteLength

      link:        <------------------------>          deleteStart       stopCharIndex
      cut:  **************************************                       = deleteStart

      link:        <------------------------>          deleteStart       stopCharIndex
      cut:         *****************                                    -= deleteLength

      link:        <------------------------>          deleteStart       stopCharIndex
      cut:         **************************                           -= deleteLength (or deleteStart)

      link:        <------------------------>           no change        stopCharIndex
      cut:                 ******************                           -= deleteLength (or deleteStart)

      link:        <------------------------>           no change       stopCharIndex
      cut:                 ****                                         -= deleteLength

      link:        <------------------------>           no change       stopCharIndex
      cut:                 **********************                        = deleteStart

      link:        <------------------------>           no change         no change
      cut:                                     *****
      */

      var deleteLength = deleteEnd - deleteStart
      if ( deleteLength === 0 ) { return links }
      for (let i=0; i < links.length; i++ ) {
        var {startCharIndex, stopCharIndex} = links[i]

        // Rules for link start position
        if     ( deleteEnd   <  startCharIndex ) { links[i].startCharIndex -= deleteLength }
        else if( deleteStart <= startCharIndex ) { links[i].startCharIndex  = deleteStart }
        // else no change

        // Rules for link stop position
        if      ( deleteStart >= stopCharIndex)  { }
        else if ( deleteEnd   >= stopCharIndex ) { links[i].stopCharIndex  = deleteStart }
        else                                     { links[i].stopCharIndex -= deleteLength }
      }
      return links
  }


  deleteEmptyLinks = ( text: string, links: EmbeddedLink[] ) : EmbeddedLink[] => {
      // Empty link === (zero length or only spaces)
      const re = new RegExp( ' ', 'g' )
      for (let k=0; k < links.length; k++ ) {
        let {startCharIndex, stopCharIndex} = links[k]
        let linkName = text.slice( startCharIndex, stopCharIndex )
        linkName = linkName.replace( re, '' )
        if ( linkName.length === 0 ) { delete links[k] }
        // Also delete links that are later (right of) the text.
        else if (startCharIndex > text.length) { delete links[k] }
      }
      links = links.filter( ()=>true )
      return links
  }


  insertTextWithLinks = ( parent: TextWithLinks, inserted: TextWithLinks, position: number ) : TextWithLinks => {
      // Insert the pasted text at currentCursor:
      var insertedLength = inserted.text.length
      const newText = parent.text.slice(0, position) + inserted.text + parent.text.slice( position )
      // Shift the currentLinks positions to account for inserted characters:
      const newLinks = this.adjustLinksAfterInsertion( parent.links, position, insertedLength )
      // Move links that belonged to the inserted text, into newLinks
      if ( inserted.links.length > 0 ) {
        inserted.links.forEach( thisLink =>
          newLinks.push({ href: thisLink.href,
                          startCharIndex : thisLink.startCharIndex + position,
                          stopCharIndex  : thisLink.stopCharIndex  + position })
        )
        newLinks.sort( (a,b)=> a.startCharIndex - b.startCharIndex );
      }
      if (DEBUG) {
        var endCursorPosition = position + insertedLength
        console.log( '' )
        console.log( `  INSERTION - inserted ${insertedLength} char(s) at startPosition:${position} to endPosition:${endCursorPosition}`)
        this.debug_annotateText2( newText, newLinks, endCursorPosition, endCursorPosition )
      }
      return { text:newText, links:newLinks }
  }


  extractTextWithLinks_slice = ( parent: TextWithLinks, start: number, stop: number ) : TextWithLinks => {
      // Returns an 'extracted' TextWithLinks object.
      // Text will be equivalent of a inputText.slice(start,stop)
      // Links will be that subset of links that intersect the slice.
      // If a links intersects the slice but is only spaces (empty), these are ignored (not present in output).
      const newText = parent.text.slice( start, stop )
      const links = parent.links
      const newLinks : EmbeddedLink[] = []
      const re = new RegExp( ` *`, 'g' )
      links.forEach( thisLink => {
        var {startCharIndex, stopCharIndex, href} = thisLink
        // Intersects our slice?
        if ( start < stopCharIndex && stop >= startCharIndex ) {
          var newStartCharIndex = startCharIndex - start
          var newStopCharIndex  = stopCharIndex  - start
          // limits of the link cannot extend beyond (0, textWithLinks.length)
          newStartCharIndex = Math.max( 0, newStartCharIndex )
          newStopCharIndex  = Math.min( stop-start, newStopCharIndex)
          // Extract the linkName (text from start-stop)
          // If linkName is empty space, ignore the link
          var linkName = newText.slice( newStartCharIndex, newStopCharIndex )
          linkName = linkName.replace( re, '' )
          if (linkName.length > 0) {
            newLinks.push({href, startCharIndex:newStartCharIndex, stopCharIndex: newStopCharIndex })
          }
        }
      })
      return { text: newText, links: newLinks }
  }


  static convertTextWithLinksToHTML = ( textWithLinks: TextWithLinks ) : string => {
      var txt = textWithLinks.text
      var newTxt = '<span>'
      var lastStopChar = 0
      var linkName = ''
      for (const thisLink of textWithLinks.links) {
        var {startCharIndex, stopCharIndex, href} = thisLink
          newTxt += heEncode( txt.slice( lastStopChar, startCharIndex ))
          linkName = heEncode( txt.slice(startCharIndex, stopCharIndex) )
        newTxt += `<a href="${href}">${linkName}</a>`
        lastStopChar = stopCharIndex
      }
      newTxt += heEncode( txt.slice(lastStopChar) )
      newTxt += '</span>'
      newTxt = newTxt.replace( /\n/g, '</br>' )
      return newTxt
  }


  static convertHTMLtoTextWithLinks = ( htmlStringIn:string ) : TextWithLinks => {
        // Converts pasted HTML that MAY contain links, into our TextWithLinks dataType.
        // Supports pasted HTML info pulled from the clipboard, then pasted into
        // our EditDescription textArea.  Maintains the placement and hrefs of 'links'.
        var htmlString = htmlStringIn.slice()
        var links : EmbeddedLink[] = []

        // STEP #1:  Find the anchor tags; Parse them to save the HREF, then replace
        // anchor tags with a 'LinkDelimiter' (which effectively is marking the
        // anchor position with a textString, rather than an HTML tag.)

        // This match attempts to find a complete anchor tag.
        // If/when found, we are going to replace the entire tag:  </a ... >text</a>   with: textDelimiter+text+textDelimiter
        // This is the only tag of concern.  All other HTML tags can/will be ignored.
        var m = htmlString.match( regexpLink )
        var linkCounter = 0
        while ( m ) {
          let linkText = m[2]
          let anchorText = m[1] // anchorText is the text found inside the <a ... > tag.
          let anchorStartIndex = Number(m.index)
          let anchorStopIndex = anchorStartIndex + m[0].length
          let mHREF = anchorText.match( regexpHREF ) // Find the href value inside the anchor tag
          if ( mHREF ) { // Found an HREF string!
            let href = mHREF[1].slice(1, mHREF[1].length-1 ) // Strip the leading and trailing quote.
            links.push({ href, startCharIndex:linkCounter, stopCharIndex:linkCounter })
            linkCounter++
            // Replace the entire anchor text.
            htmlString = htmlString.slice(0,anchorStartIndex) +
                         LinkDelimiterStart + linkText + LinkDelimiterStop +
                         htmlString.slice(anchorStopIndex)
          }
          // Look for next link:
          m = htmlString.match( regexpLink )
        }

        // STEP #1.5 Stripping HTML will also strip all </br>
        // They are not considered part of the text.
        // We want to retain the line-feed formatting.
        // Chrome/Firefox use </br>    Safari uses <br>
        htmlString = htmlString.replace( /<\/br>/g, '\n' )
        htmlString = htmlString.replace( /<br>/g, '\n' )

        // STEP #2 Convert the remaining HTML to text:
        var tempDiv = document.createElement('div')
        tempDiv.innerHTML = htmlString
        var textString = tempDiv.textContent

        if (!textString) {
          return { text: '', links }
        }
        // STEP #3 Clean the external HTML input:
        const cursorPosition = 0  // Not important for this usage, however 0 is quickest to clean.
        const options = { mergeSpaces: false,
                          deleteLeadingSpaces: false,
                          ignoreLineFeeds: false
                        }
        const cleanResult = cleanScryInputText2( textString, cursorPosition, options)
        textString = cleanResult.newValue

        // STEP #4  - replace the delimiters we introduced in step #1.
        // Location of the delimiters marks the location of the links.
        const subStringsArray = textString.split( LinkDelimiterStart )
        var lengthCounter = 0
        linkCounter = 0
        subStringsArray.forEach( (thisStrg, i) => {
          let isLink = (i%2 === 1) // Every odd string index is a link
          if ( isLink ) {
            links[linkCounter].startCharIndex = lengthCounter
            links[linkCounter].stopCharIndex  = lengthCounter + thisStrg.length
            linkCounter++
          }
          lengthCounter += thisStrg.length
        })
        const subStringsJoined = subStringsArray.join('')
        const textWithLinks : TextWithLinks = { text: subStringsJoined, links }
        //console.log( 'textWidthLinks', textWithLinks )
        return textWithLinks
  }


  handleOnCut = ( e: React.ClipboardEvent<HTMLTextAreaElement> ) : void => {
    this.handleOnCopy( e )
    var cursor = this.state.priorSelStart
    var links  = this.currentLinks
    var value  = this.currentValue
    var selStart = this.state.priorSelStart
    var selEnd   = this.state.priorSelEnd

    value = value.slice(0, selStart) + value.slice( selEnd )
    links = this.adjustLinksAfterDeletion( links, selStart, selEnd )
    links = this.deleteEmptyLinks( value, links )

    if (DEBUG) {
      var deleteLength = selEnd - selStart
      console.log( '' )
      console.log( `  DELETION - deleted ${deleteLength} Characters from ${selStart} to ${selEnd}`)
      this.debug_annotateText2( value, links, cursor, cursor )
    }

    // Reset the textArea display; And set local State parameters.
    const txtArea = this.textAreaNode
    if ( txtArea ) {
      txtArea.value = value // This line will also reset selection start to useless value.
      txtArea.setSelectionRange( cursor, cursor )
    }
    this.currentCursor = cursor
    this.currentValue  = value
    this.currentLinks  = links
    var { isInLink, linkName, linkUrl, linkIndex } =
           EditTextWithLinks.getLinkInfo( links, value, cursor )
    this.setState({ isInLink, linkName, linkUrl, linkIndex, txtValue: value,
                    errMsg:' ', links, cursorPosition:cursor,
                    priorSelStart:cursor, priorSelEnd:cursor })
    clearTimeout(this.timeoutID)
    this.timeoutID = setTimeout( () => {this.updateReactState(value, links)}, this.TIMEOUT_DELAY)
    e.preventDefault()

  }

  handleOnCopy = ( e: React.ClipboardEvent<HTMLTextAreaElement> ) : void => {
    if ( DEBUG ) { console.log( 'call to handleOnCopy' ) }
    var {txtValue, links, priorSelStart, priorSelEnd} = this.state
    var parent = {text:txtValue, links}
    // Convert the selected range of text into a newTextWithLinks object.
    var newTextWithLinks = this.extractTextWithLinks_slice( parent, priorSelStart, priorSelEnd )
    var newHTML = EditTextWithLinks.convertTextWithLinksToHTML(newTextWithLinks)
    //console.log ( 'Copy to Clipboard', newHTML )
    e.clipboardData.setData( 'text/html', newHTML )
    e.clipboardData.setData( 'text/plain', newTextWithLinks.text )
    if ( process.env.NODE_ENV === 'development' && shouldPut_textwithlinks_TypeOntoClipboard ) {
      e.clipboardData.setData( 'textwithlinks', JSON.stringify(newTextWithLinks) )
    }
    e.preventDefault()
  }

  // JSON.stringify( Obj or array, replacer, space)
  // jsObj or Array = JSON.parse( string )

  handleOnPaste = ( e: React.ClipboardEvent<HTMLTextAreaElement> ) : void => {

      var errMsg = ''   // Assumption:  we clear any prior errMsg
      var textWithLinks: TextWithLinks = {text: '', links: [] } // Assumption: If we can't find anything usable on the clipboard
      var pastedTypes = e.clipboardData.types
      /*
      var temp0 = e.clipboardData.getData( 'text/plain' )
      var temp1 = e.clipboardData.getData( 'text/html' )
      var temp2 = e.clipboardData.getData( 'textwithlinks' )
      console.log( 'onPaste', temp0, temp1, temp2 )
      console.log( 'handleOnPaste', this.currentValue, this.currentLinks ) */

      // Else check for HTML
      if ( pastedTypes.indexOf( 'text/html') >= 0 ) {
        var data = e.clipboardData.getData( 'text/html' )
        textWithLinks = EditTextWithLinks.convertHTMLtoTextWithLinks( data )
      }
      // Else check for plain text:
      else if ( pastedTypes.indexOf( 'text/plain') >= 0 ) {
        data = e.clipboardData.getData( 'text/plain' )
        textWithLinks = {text: data, links: [] }
      }

      if ( process.env.NODE_ENV === 'development' && pastedTypes.indexOf( 'textwithlinks' ) >= 0 ) {
        if (DEBUG) {
          console.log('\nFound TextWithLinks on clipboard; Verified its consistancy with converted HTML.')
        }
        // Round-trip translation test:   TextWithLinks -> 'text/html' => new TextWithLinks
        // Does TextWithLinks === new TextWithLinks ??
        var textWithLinksConverted = textWithLinks  // From the 'test/html' clipboard data above
        data = e.clipboardData.getData( 'textwithlinks' )
        // This next version is the textWithLinks that was directly saved to the clipboard,
        // put back into our original textWithLinks data structure:
        var textWithLinksOriginal : TextWithLinks = JSON.parse( data ) 
        var doesNotMatch = false // assumption
        if ( textWithLinksOriginal.text !== textWithLinksConverted.text ) { doesNotMatch = true }
        if ( textWithLinksOriginal.links.length !== textWithLinksConverted.links.length ) { doesNotMatch = true }
        textWithLinksOriginal.links.forEach( (thisLink,i) => {
          var {href, startCharIndex, stopCharIndex} = thisLink
          var {href:newhref, startCharIndex:newStart, stopCharIndex:newStop} = textWithLinksConverted.links[i]
          if ( href !== newhref || startCharIndex !== newStart || stopCharIndex !== newStop ) { doesNotMatch = true }
        })
        if (doesNotMatch) {
          var message = 'Cut/Copy, followed by a Paste did the following conversions:\n' +
                        "TextWithLinks -> 'text/html' => new TextWithLinks\n" +
                        'ERROR:  TextWithLinks !== new TextWithLinks\n' +
                        'ORIGINAL  TEXT:\n' + textWithLinksOriginal.text  + '\n' +
                        'CONVERTED TEXT:\n' + textWithLinksConverted.text + '\n'
          message += 'ORIGINAL  LINKS:\n' + JSON.stringify(textWithLinksOriginal.links) + '\n'
          message += 'CONVERTED LINKS:\n' + JSON.stringify(textWithLinksConverted.links) + '\n'
          invariant( false, message )
        }
      }

      // We may need to cut this text prior to paste!
      var selStart = this.state.priorSelStart
      var selEnd   = this.state.priorSelEnd
      if ( selStart < selEnd ) {
        this.currentValue  = this.currentValue.slice(0, selStart) + this.currentValue.slice( selEnd )
        this.currentLinks  = this.adjustLinksAfterDeletion( this.currentLinks, selStart, selEnd )
        this.currentLinks  = this.deleteEmptyLinks( this.currentValue, this.currentLinks )
        this.currentCursor = selStart
        if (DEBUG) {
          var deleteLength = selEnd - selStart
          console.log( '' )
          console.log( `  DELETION - deleted ${deleteLength} Characters from ${selStart} to ${selEnd}`)
          this.debug_annotateText2( this.currentValue, this.currentLinks, this.currentCursor, this.currentCursor )
        }
      }

      // Is the user is pasting text that includes links, into a pre-existing link?  Then:
      // - Paste only the text, as if any pasted link references do not exist.
      if ( this.currentLinks.length > 0 ) {
        for ( let i=0; i < this.currentLinks.length; i++ ) {
          var {startCharIndex, stopCharIndex} = this.currentLinks[i]
          if ( this.currentCursor > startCharIndex && this.currentCursor < stopCharIndex ) {
            textWithLinks.links = []
          }
        }
      }

      const parent = { text: this.currentValue, links: this.currentLinks }
      const inserted = textWithLinks
      var   position = this.currentCursor
      const newTextWithLinks = this.insertTextWithLinks( parent, inserted, position )
      var insertedLength = inserted.text.length
      var endCursorPosition = position + insertedLength
      if (DEBUG) {
        console.log( '' )
        console.log( `  INSERTION - inserted ${insertedLength} char(s) at startPosition:${position} to endPosition:${endCursorPosition}`)
        this.debug_annotateText2( newTextWithLinks.text, newTextWithLinks.links, endCursorPosition, endCursorPosition )
      }
      let result = this.applySpacingRules( newTextWithLinks.text, newTextWithLinks.links, endCursorPosition, true )
      newTextWithLinks.text  = result.value
      newTextWithLinks.links = result.links
      this.currentCursor = result.cursor
      this.currentValue  = result.value
      this.currentLinks  = result.links
      // Reset the textArea display; And set local State parameters.
      const txtArea = this.textAreaNode
      if ( txtArea ) {
        txtArea.value = newTextWithLinks.text.slice()  // This line will also reset selection start to useless value.
        txtArea.setSelectionRange( this.currentCursor, this.currentCursor )
      }
      var { isInLink, linkName, linkUrl, linkIndex } =
                 EditTextWithLinks.getLinkInfo( this.currentLinks, this.currentValue, this.currentCursor )
      this.setState({ isInLink, linkName, linkUrl, linkIndex, txtValue: this.currentValue,
        errMsg, links: this.currentLinks, cursorPosition: this.currentCursor,
        priorSelStart:this.currentCursor, priorSelEnd: this.currentCursor })

      clearTimeout(this.timeoutID)
      const {currentValue, currentLinks} = this
      this.timeoutID = setTimeout( () => {this.updateReactState(currentValue, currentLinks)}, this.TIMEOUT_DELAY)
      e.preventDefault()
  }



  updateLocalState = ( e: React.ChangeEvent<HTMLTextAreaElement>) : void => {
    
      // HOW THIS WORKS
      //   1) The 'e.target.value' in event above was already modified by textArea,
      //      and MAY need to be further modified by our spacing rules.
      //   2) We assume the link positions are all bad and its our responsibility to
      //      calculate how they have shifted; then shift them!
      //   3) We assume the cursorPosition is bad and its our responsibility to
      //      calculate any shift due to our spacing rules; then shift cursor!
      var links  = deepClone( this.state.links )
      var value  = e.target.value.slice( )
      var cursor = e.target.selectionEnd
      var selStart = this.state.priorSelStart
      var selEnd   = this.state.priorSelEnd
      const didCut      = (selStart !== selEnd)
      const didInsertion= (e.target.selectionStart > selStart)
      const didBackspace= (selStart === selEnd && cursor < selEnd )

      if (DEBUG) {
        console.log( '' )
        console.log( '' )
        console.log( '' )
        console.log( 'OnChange Handler' )
        console.log( '    priorSelStart, priorSelStop, currentCursor', selStart, selEnd, this.currentCursor )
        console.log( '    textarea did cut :', didCut )
        console.log( '    textarea did insertion :', didInsertion )
        console.log( '    textarea did backspace:', didBackspace)
        console.log( '    textarea.value; as passed to onChange() handler.' )
        this.debug_annotateText2( value, links, cursor, cursor )
      }
      // Turn a backspace into a 'cut'
      // Back space is equivalent of selecting a character, then doing a control-cut.
      // Except we won't put the selected character on the clipboard.
      if ( didBackspace ) { selStart-- }

      // Make the cut
      if ( didCut || didBackspace ) {
        links = this.adjustLinksAfterDeletion( links, selStart, selEnd )
        links = this.deleteEmptyLinks( value, links )
        if (DEBUG) {
          console.log( '' )
          var deleteLength = selEnd - selStart
          console.log( `   DELETION - deleted ${deleteLength} Characters from ${selStart} to ${selEnd}`)
          console.log( '   workingLinks', deepClone( this.currentLinks) )
          this.debug_annotateText2( value, links, cursor, cursor )
        }
      }

      // Make the insertion (paste)
      if ( didInsertion ) {
        const options = { mergeSpaces: false,
                          deleteLeadingSpaces: false,
                          ignoreLineFeeds: false,
                        };
        let result = cleanScryInputText2(value, cursor, options);
        cursor = result.newSelectionStop
        value  = result.newValue
        let numInsertedChars = cursor - selStart
        links = this.adjustLinksAfterInsertion( links, selStart, numInsertedChars )
        if (DEBUG) {
          console.log( '' )
          console.log( `  INSERTION - inserted ${numInsertedChars} char(s) at startPosition:${selStart} to endPosition:${cursor}`)
          this.debug_annotateText2( value, links, cursor, cursor )
        }
      }

      ({value, links, cursor} = this.applySpacingRules( value, links, cursor, didInsertion ))
      if (DEBUG) {
        console.log( '' )
        console.log( '  After applySpacingRules()' )
        this.debug_annotateText2( value, links, cursor, cursor )
      }

      // Update textArea
      e.target.value = value.slice()
      e.target.setSelectionRange( cursor, cursor )
      // Update local globals
      this.currentCursor = cursor
      this.currentValue  = value
      this.currentLinks  = links

      // Set the local State:
      var { isInLink, linkName, linkUrl, linkIndex } =
                  EditTextWithLinks.getLinkInfo( links, value, cursor )
      this.setState({ isInLink, linkName, linkUrl, linkIndex, txtValue: value, links,
                  cursorPosition:cursor,
                  priorSelStart:cursor, priorSelEnd:cursor })
      if (DEBUG) {
        console.log( 'onChange textValue', `"${value}"` )
        console.log( 'SetState to:', value, links )
      }
      clearTimeout(this.timeoutID)
      this.timeoutID = setTimeout( () => {this.updateReactState(value, links)}, this.TIMEOUT_DELAY)
  }


  // We synchronize any Link Editor inputs to the TextWithLinks object
  synchLinkEditorNameToParentDescriptionText = ( newValue:string ) => {
    var {linkName, linkIndex, txtValue, links} = this.state
    var {startCharIndex, stopCharIndex} = links[linkIndex]
    // Insert the edited newValue into the current description:
    var newText = txtValue.slice( 0, startCharIndex) + newValue + txtValue.slice( stopCharIndex )
    links[linkIndex].stopCharIndex = links[linkIndex].startCharIndex + newValue.length
    // Adjust location of all links to the right
    var deltaLinkChars = newValue.length - linkName.length
    for (let i=linkIndex+1; i < links.length; i++ ) {
      links[i].startCharIndex += deltaLinkChars
      links[i].stopCharIndex  += deltaLinkChars
    }
    // Synchronize the visible text in the description editor.
    let node = this.textAreaNode
    if ( node ) {
      node.value = newText.slice()
      node.setSelectionRange( startCharIndex, startCharIndex )
    }
    // update the local globals and the local state.
    this.currentCursor = startCharIndex
    this.currentValue  = newText
    this.currentLinks  = links
    // Set the local State:
    this.setState({ linkName:newValue, txtValue:newText, links, cursorPosition:this.currentCursor,
                    priorSelStart: startCharIndex, priorSelEnd:startCharIndex })
    // $FlowFixMe
    clearTimeout(this.timeoutID)
    this.timeoutID = setTimeout( () => {this.updateReactState(newText, links)}, this.TIMEOUT_DELAY)
}


/*  applySpacingRules( )

Most efficient algorithm would be to do all the bookkeeping (link start/stop positions,
and cursor position) while minimizing the string processing.  But I found this
hell to debug!  This approach uses string processing, so I can easily see the
current result 'on-the-fly'.   Bookkeeping to calculate the final links data
structure is done last (and independent of application of string processing rules).

Unfortunately, we still need to do the bookkeeping for the cursor position.
Because I don't see any easy way to encode this within the txtArray.

'black' strings refer to characters that display as black text.
'blue'  strings refer to characters that display as a link.


The data structure for processing is:
  txtArray : Array<string> =

      txtArray[0]:  'black' chars preceeding first link.  May be an empty string
      txtArray[1]:  'blue'  chars of first link.  If no links, then array element does not exist
      txtArray[2]:  'black'
      txtArray[3]:  'blue'
       ...
       ...
      txtArray[last even Index]: 'black' chars of final non-link text

 - All even indexed elements are 'black'
 - All odd  indexed elements are 'blue'
 - Array length is always odd!  Of total length = 2*links.length + 1
 - We allow for 'illegal' sequences (adjacent links, ... );
 - This function will apply spacing rules by inserting spaces.  But gross errors
   must be fixed by the user.  This function NEVER reorders or deletes input characters.
 - No such thing as a zero length 'blue' sequence of characters.  This would be
   considered a deleted link and will already have been removed in preceeding code.
 - No such thing as a empty (invisible) 'blue' sequence of characters.  For example
   a sequence of only 5 'blue' spaces. This is also considered a deleted link and will
   already have been removed in the preceeding code.  Note: the 5 'blue' spaces are
   not deleted!  Prior code to delete empty links will have simple converted them
   to 5 'black' spaces by deleting the link reference.
 - There is such a thing as zero length 'black' text.  Specifically
      txtArray[0] = '' when input begins with a link
      txtArray[last odd index] = '' when input ends with a link
      txtArray[even] = '' when there is no black text between adjacent links (although an error issued)
  - The most degenerate input ( but an acceptable input into this function) is:
      txtArray = [
        '',
        'some link',
        '',
        'another link',
        '' ]


Rules:
  0) Links should be trimmed from both sides, to keep the link sequence
     as short as possible.  Trimming does not remove or modify the text!!
     It just contracts the link's startCharIndex and stopCharIndex.  Trimming
     converts invisible 'blue' characters (spaces and lineFeeds) into 'black'
     characters.

  1)  Must be at least one 'black' space (not part of the link) prior to every link.
      Same as: 'black' chars preceeding a link must end in a space.
      For example, assume the input text begins with a link.  Therefore
      txtArray[0] = ''.   On output, txtArray[0] = ' '  (one empty black space)
      This is a new, inserted space!!  Reason:  How else is the user able to
      insert black text prior to the first link??

  2) Must be at least one 'blue ' space ( is part of the link) at end of each link.

          Rules 1 & 2 mean there is always a space between visible 'blue' and 'black'
          characters. So the user can position the cursor either in the 'blue' or
          the 'black' string.  Prevents the ambiguity we have all experienced in
          markup editors where one can't properly insert characters at the boundary
          of a style change.

  3) A link may contain a lineFeed, if that is what the user currently has typed.
        ( However, a lineFeed in a link will throw an error message to user.  We won't
          delete the lineFeed here.  Just report that the user needs to fix it. )
     A linefeed that either leads or trails all the blue characters is
     solved by trimming (rule 0).  The linkfeed remains, but when trimmed the
     'blue' linefeed became a 'black' linefeed. Hence, never a linefeed
     at beginning or end of a link.  And an internal linefeed will throw a user error.

  4) Cursor position - Should never move.  Our job is to make sure it doesn't.
     Easy: If we insert a space prior to cursor, then cursorPosition++.

     Exception: When the cursor position is ambigous!
     Specifically, when the cursor is at the position of an inserted space.
     And this is a common case!  Does the cursor go before or after the inserted space?
     For example, suppose I delete a space, then this algorithm inserts that space
     back again. Then the cursor position gets stuck.  The cursor position can
     get stuck in both cases of deleting or inserting a space.
     To get it 'unstuck', we need to ask: 'What direction was the cursor moving?'
     If moving to the left (a deletion, cut, ... ) then cursor goes left of the
     inserted space.  Else (moving to the right), the cursor goes to right of the
     inserted space.   Unsure if my code covers all cases.  But if you find a
     case where the cursor gets 'stuck', then make sure you test/fix cursor
     travel in BOTH directions.

*/



  applySpacingRules = ( value: string, links: EmbeddedLink[], cursorPosition:number, isInsertion:boolean )
      : { value: string, links: EmbeddedLink[], cursor: number } => {

    // These funcs process characters
    const isNotVisible = (a:string) : boolean => {
            return (a!==undefined && (a===' ' || a==='\n' || a==='\r'))
    }
    const lastChar = ( a:string ) : string => { return a[a.length-1] }
    let re = /\n/gi   // In debugging statements, replaces '\n' with a reverse arrow.

    // Two degenerate cases:
    if (value.length === 0) {
      return {value:'', links:[], cursor:0}
    }
    if (links.length === 0) {
      return { value: value.slice(), links:[], cursor:cursorPosition }
    }

    // Step 1: create txtArray
    const txtArray : string[] = []
    var   lastStopCharIndex = 0
    links.forEach( (thisLink,i) => {
      let {startCharIndex, stopCharIndex} = thisLink
      txtArray.push( value.slice(lastStopCharIndex, startCharIndex))
      txtArray.push( value.slice(startCharIndex, stopCharIndex))
      lastStopCharIndex = stopCharIndex
    })
    txtArray.push( value.slice(lastStopCharIndex))

    if (DEBUG) {
      console.log( 'applySpacingRules: input strings', txtArray )
      txtArray.forEach( thisString => {
        let temp = thisString.replace( re, '↩' )
        console.log( '    ', `"${temp}"` )
      })
      console.log('')
    }

    // Step 2: trim the links
    for ( var i=1; i<txtArray.length; i+=2 ) {
      while ( isNotVisible(txtArray[i][0])) {
        // Move first char from link to prior black text:
        txtArray[i-1] += txtArray[i][0]
        txtArray[i]    = txtArray[i].slice(1)
      }
      while ( isNotVisible( lastChar(txtArray[i]))) {
        // Move last char from link to next black text:
        txtArray[i+1] = lastChar(txtArray[i]) + txtArray[i+1]
        txtArray[i]   = txtArray[i].slice(0, txtArray[i].length-1)
      }
      // Above while loop trimmed all invisible
      // characters from the end of the link.
      // However, links must end in a blue space!
      // Easier to just put the last 'blue' space back
      // into the link (if it was there to begin with).
      // If it is NOT there (unlikely, but possible), we
      // will need to insert a blue space (later, not now).
      // At this time we simply ask:
      // 'Was the blue space already there? If so, put it back.'
      if ( txtArray[i+1][0] === ' ' ) {
        txtArray[i+1] = txtArray[i+1].slice(1)
        txtArray[i] += ' '
      }
    }


    if (DEBUG) {
      console.log( 'applySpacingRules: after trimming links:' )
      txtArray.forEach( thisString => {
        var temp = thisString.replace( re, '↩' )
        console.log( '    ', `"${temp}"` )
      })
      console.log('')
    }

    // Step 3: Insure black strings end in a space (exception last string):
    //         Insure blue  strings end in a space:
    var runningCharCount = 0
    txtArray.forEach( (thisTxt,i) => {
      runningCharCount += txtArray[i].length
      if ( i < txtArray.length - 1 && lastChar(txtArray[i]) !== ' ' ) {
        txtArray[i] += ' '
        runningCharCount ++
        if ( cursorPosition > runningCharCount ) {
          cursorPosition++  // somewhere after the inserted space.
        }
        if (cursorPosition === runningCharCount && isInsertion) {
          cursorPosition++  // Immediately after inserted space.
        }
        // implied case: cursorPosition === runningCharCount && isDeletion
        // puts the cursor immediately before the inserted space.
      }
    })

    if (DEBUG) {
      console.log( 'applySpacingRules: after inserting potential spaces:' )
      txtArray.forEach( thisString => {
        var temp = thisString.replace( re, '↩' )
        console.log( '    ', `"${temp}"` )
      })
      console.log('')
    }

    // Last step:  Convert txtArray into newValue and newLinks
    const newValue = txtArray.join('')
    const newLinks = []
    // For each odd indexed string (blue) create a link
    runningCharCount = 0
    var runningLinkIndex = 0
    for ( let i=1; i<txtArray.length; i+=2 ) {
      runningCharCount += txtArray[i-1].length
      let startCharIndex = runningCharCount
      runningCharCount += txtArray[i].length
      let stopCharIndex = runningCharCount
      newLinks.push( {startCharIndex, stopCharIndex, href: links[runningLinkIndex].href } )
      runningLinkIndex++
    }

    if (DEBUG) {
      console.log( 'applySpacingRules: output strings' )
      txtArray.forEach( thisString => {
        let temp = thisString.replace( re, '↩' )
        console.log( '    ', `"${temp}"` )
      })
      console.log( 'cursorPosition:', cursorPosition )
      console.log('')
      console.log( 'applySpacingRules outputs : ', newValue, newLinks )
    }


    return { value: newValue, links: newLinks, cursor: cursorPosition }
  }




  updateReactState = ( txtValue:string, links: EmbeddedLink[]) : void  => {

    // This function may or may not have been called by a timer.
    if ( this.timeoutID ) {
      clearTimeout(this.timeoutID)
      this.timeoutID = undefined
    }

    const newState = {text:txtValue, links: links.slice() }
    const {colOrSeriesKey, parentMode, tableid, plotid} = this.props
    var   mods = []
    switch ( parentMode ) {
      case 'colDescription' :
        mods = [{ newVal: newState, path: `attributes.columns[${colOrSeriesKey}].colDescription` }]
        reactDispatch(mods, 'TableCol Description Edit', '', 'tables', tableid)
        break
      case 'tableDescription' :
        mods = [{ newVal: newState, path: `attributes.tableDescripton` }]
        reactDispatch(mods, 'Table Description Edit', '', 'tables', tableid)
        break
      case 'plotDescription' :
        mods = [{ newVal: newState, path: `attributes.plotDescription` }]
        reactDispatch(mods, 'Plot Description Edit', '', 'plots', plotid)
        break
      case 'plotSeriesDescription' :
        mods = [{ newVal: newState, path: `attributes.series[${colOrSeriesKey}].seriesDescription` }]
        reactDispatch(mods, 'PlotSeries Description Edit', '', 'plots', plotid)
        break
      default:
        if ( process.env.NODE_ENV === 'development' ) {
          invariant( false, `Unexpected parentMode '${parentMode}'.` )
        }
    }
  }


  createLink = ( ) => {
    var {txtValue: value, links, priorSelStart:selStart, priorSelEnd:selEnd} = this.state
    links = links.slice()
    var cursor = this.currentCursor
    // Skip creation in Case of a zero length selection (no characters)
    if ( selStart >= selEnd ) { return }
    // Skip creation in Case of selected text overlaps an existing link:
    for (let j=0; j < links.length; j++) {
      let {startCharIndex, stopCharIndex} = links[j]
      if ( selStart < stopCharIndex && selEnd >= startCharIndex ) { return }
    }
    // Don't allow the selection to begin with a lineFeed
    while ( value[selStart] === '\n' ) { selStart++ }
    if ( selStart >= selEnd ) { return }
    // Don't allow the selection to include a linefeed.   Change the
    // selection such that the selEnd is prior to the line feed.
    let selectedText = value.slice(selStart, selEnd)
    let indexOfLineFeed = selectedText.indexOf( '\n' )
    if (indexOfLineFeed >= 0) {
      selEnd = selStart + indexOfLineFeed
    }
    if ( selStart >= selEnd ) { return }

    // Push the new link onto links array; re-order by start position.
    links.push({
      startCharIndex:selStart,
      stopCharIndex:selEnd,
      href:''
    })
    links.sort( (a,b)=> a.startCharIndex - b.startCharIndex );

    const isInsertion = false; // Leave the cursor positioned after last visible character of link.
    ({value, links, cursor} = this.applySpacingRules( value, links, cursor, isInsertion ))
    let n = this.textAreaNode
    if ( n ) {
      n.value = value
      n.setSelectionRange( cursor, cursor )
    }
    this.currentValue  = value
    this.currentLinks  = links
    this.currentCursor = cursor

    // Set state:
    var { isInLink, linkName, linkUrl, linkIndex } =
                EditTextWithLinks.getLinkInfo( links, value, cursor )
    this.setState({ isInLink, linkName, linkUrl, linkIndex, txtValue: value, links,
                    errMsg:'', cursorPosition:cursor })
    this.updateReactState( value, links )
    if (DEBUG && n) {
      console.log( '' )
      console.log( 'Created a New Link' )
      console.log( 'workingLinks', deepClone(links) )
      this.debug_annotateText2( value, links, cursor, cursor )
    }
  }


  ColorCodedText = ( ) => {
    // This returns a React Component!
    // A <div> Array[Children] </div>
    const {txtValue, links, badLinksArray} = this.state
    const {canEdit} = this.props
    var txtValue2 = txtValue.slice()

    // If this is NOT edit mode, remove any/all trailing whitespace and linefeeds.
    var len = txtValue2.length
    if (!canEdit) {
      // In this case, NEVER an empty line as the last line.
      while (txtValue2[len-1] === ' ' || txtValue2[len-1] === '\n' ) { len-- }
      txtValue2 = txtValue2.slice(0,len)
      // If nothing remains, use a default message:
      if ( len === 0 ) { txtValue2 = 'Missing a Description' }
    }

    if ( canEdit && txtValue2.slice(-1) === '\n' ) {
      // In this case, ALWAYS an empty line for any trailing space or linefeed!
      // Our code depends on the div to be self-sizing
      // But the div will NOT resize itself for a trailing line-feed.
      // In other words, if user types a linefeed in the div, the div will
      // NOT increase in height until we type the next character.
      // However, we need to treat the line-feed as an actual character so
      // the cursor will advance to the next line.
      // We fix this quirk here by forcing the div to recognize a blank
      // space after any trailing line feed.   Now the div resizes for
      // all characters, including a trailing linefeed.
      txtValue2 += ' '
    }

    const backgroundErrColor = '#ffdddd'
    const children = []
    var lastStopIndex = 0
    for (let i=0; i < links.length; i++) {
      var start = links[i].startCharIndex
      var stop  = links[i].stopCharIndex
      // This is erroneous link (formatting) if the index 'i' is found
      // in the badLinksArray
      var isBadLink = ( badLinksArray.indexOf(i) >= 0 )
      const linkStyle = ( canEdit )
        ? { color:constants.LINK_COLOR,
            textDecoration: (isBadLink) ? 'underline red' : `underline ${constants.LINK_COLOR}`,
            background:     (isBadLink) ? backgroundErrColor : 'unset',
           }
        : { color: (isBadLink) ? 'unset' : constants.LINK_COLOR,
          textDecoration: 'unset',
          textDecorationColor: 'unset',
          background: 'unset',
        }

      // When using view mode (anchors) set the stopCharIndex to last non-space char of the link.
      if (canEdit === false) {
        while ( txtValue2[stop-1] === ' ' ) { stop-- }
      }
      let subString1 = txtValue2.slice(lastStopIndex, start )
      let subString2 = txtValue2.slice(start, stop )
      lastStopIndex = stop
      children.push(
        <span key={ String(i)+String(lastStopIndex) }>
          {subString1}
          { canEdit
            ?
              <span key={String(i)+String(start)} style={linkStyle}>
                {subString2}
              </span>
            :
              <a key={String(i)+String(start)}
                 href={links[i].href}
                 target='_blank'
                 rel="noopener noreferrer"
                 style={linkStyle}>
                {subString2}
              </a>
          }
        </span>
      )
    }
    // Last segment of uncolored text
    // This is the entire original text when links.length === 0
    children.push(
      <span key={'final'}>
        { txtValue2.slice(lastStopIndex) }
      </span>
    )

    return (
      <div>
         {children}
      </div>
    )
  }


  setEmpericallyMeasuredSelfSizingDivHeight = ()=> {
    // Measure the underlying div area where text is displayed.
    // We will align and size the over lying div (owns the cursor
    // and text editor) to be identical size.
    // We measure the underlying div because it self-sizes, whereas
    // the overlying textArea does not!
    if ( this.colorCodedTextDiv ) {
      const newHeight = this.colorCodedTextDiv.clientHeight
      if ( this.state.empericallyMeasuredSelfSizingDivHeight !== newHeight ) {
        this.setState({ empericallyMeasuredSelfSizingDivHeight : newHeight })
      }
    }
  }

  textAreaNode: HTMLTextAreaElement | null = null
  colorCodedTextDiv: HTMLDivElement | null = null
  editorParentDiv: HTMLDivElement | null = null
  textWithLinksComponentDiv: HTMLDivElement | null = null

  initEditorParentDiv = (element: HTMLDivElement | null): void => {
    this.editorParentDiv = element
  }
  initTextWithLinksComponentDiv = (element: HTMLDivElement | null): void => {
    this.textWithLinksComponentDiv = element
  }
  initColorCodedTextDiv = (element: HTMLDivElement | null): void => {
    this.colorCodedTextDiv = element
  }
  initTextAreaNode = (element: HTMLTextAreaElement | null): void => {
    this.textAreaNode = element
  }




  render() {
    const { width, canEdit, colOrSeriesKey, heightLabel,
      heightEditMode, heightMaxViewMode, tableid, parentMode, textWithLinks } = this.props
    const {txtValue, isInLink, linkName, linkUrl, linkIndex, isActive,
      empericallyMeasuredSelfSizingDivHeight, parentBorderStyle,
      isHyperlinkPositionTop, parentBorderColor } = this.state
    const ColorCodedText = this.ColorCodedText
    const isViewMode = !canEdit
    // This is the height of the scrolled description area inside above container
    // We set the text area to the full height of the container (in which case no scroll bar)
    // or the larger empericallyMeasuredSelfSizingDivHeight, in which there will be a scrollbar.
    const currentTextareaHeight = Math.max( heightEditMode, empericallyMeasuredSelfSizingDivHeight)
    if (DEBUG) {
      console.log( 'Render TextWithLinks', textWithLinks, 'forceActive', this.props.forceActive )
    }

    /* DO NOT CHANGE: className={'rc_EditTextWithLinks'}
       This specific className is used by the taplistener.
    */

    /*  HOW TO GET IDENTICAL WORD-WRAPPING ON A TEXTAREA AND UNDERLYING DIV:
     Default word wrapping of browser (chrome, but I others may differ)
     div:      wordWrap : 'normal'
     textArea: wordWrap : 'break-word'

     We need to make sure we override both to guarantee they match (same
     behavior for both, regardless of how the browser chooses its defaults.)

     One can set overflowWrap, or wordWrap which is an alias for overflowWrap:

     wordWrap:  'normal'       A long word will over print the right edge.
                'anywhere'     Appears to me to be same rule as 'break-word'.
                'break-word'   Break at the space between words; Or if a
                               single word exceeds the available width, break
                               the word at the some internal character.

     The style for the TEXT_EDIT in the constants file uses overflowWrap : 'break-word'
     for both the div and the textArea.

     */



    return (
      <div className={'rc_EditTextWithLinks'}
        ref={ this.initTextWithLinksComponentDiv }
        style={{
          position:'relative',
          width, height:'100%',
          fontSize: FONT_SIZE_description
        }}>

        <div className={'Label'}
          style={{
            height: heightLabel, width: 88,
            marginLeft: canEdit ? 20 : 0,
            paddingTop: (heightLabel-FONT_SIZE_description)/2 -1,
            //background: 'green',
          }}>
          {'Description\u2009:'}
        </div>

{isViewMode &&
        <div className={'viewModeContainer'}
          style={{
            position:'relative', top:0, left:0,
            width, height: '100%', maxHeight: heightMaxViewMode,
            ...constants.STYLE_PALETTE_TEXT_INPUT_STYLES,
            ...constants.TEXTAREA_OVER_DIV_StyleTextArea,
            fontSize: FONT_SIZE_description,
            overflow: 'auto' as Property.OverflowWrap,
            color: 'black',
            borderWidth: 2, borderRadius: 5,
            borderStyle: parentBorderStyle,
            borderColor:parentBorderColor,
            background: 'SCRY_WHITE',
          }}>
                <ColorCodedText/>
          </div>
}


{canEdit &&
          <div className={'editModeContainer'}
            ref={ this.initEditorParentDiv }
            style={{
              position:'relative', top:0, left:0,
              width, height: heightEditMode,
              overflow: 'auto',
              borderWidth: 2, borderRadius: 5,
              borderStyle: parentBorderStyle,
              borderColor:parentBorderColor,
              background: 'white',
            }}
            onScroll={this.onScrollHandler}
            onFocus={ this.handleFocus }
          >


              <div className={'colorCodedTextContainer'}
                ref={ this.initColorCodedTextDiv }
                style={{
                  position:'relative', top:0, left:0,
                  width: '100%', // Div is self-sizing in height; based on the internal text content.
                  ...constants.TEXTAREA_OVER_DIV_StyleDiv,
                  fontSize: FONT_SIZE_description,
                  wordWrap: 'break-word',
                  //overflow: 'hidden',
                  //background: 'orange', //'white',
                }}>
                    <ColorCodedText/>
              </div>

              {/* DO NOT change the next class name.  It is used in the this
                  module as a test condition!  Just search for the className. */}
              <textarea className={'overlayTextAreaWithTransparentFont'}
                ref={ this.initTextAreaNode }
                style={{
                  position:'absolute', left:0, top: 0,
                  width: '100%', height: currentTextareaHeight,
                  ...constants.TEXTAREA_OVER_DIV_StyleTextArea,
                  fontSize: FONT_SIZE_description,
                  resize:'none',
                  caretColor: isInLink ? constants.LINK_COLOR : 'black',
                  borderWidth: 0,
                  overflow: 'hidden',
                  // Next attribute hides the blue 'got focus' outline
                  // The focus highlight is applied to the parent container.
                  outline: 'none',
                  // For debugging the overlay - THESE ARE NORMALLY COMMENTED OUT!
                  //color:'green',
                  //opacity: 0.3,
                }}
                onChange ={this.updateLocalState}
                onFocus  ={
                  this.handleFocus }
                onKeyDown={this.handleKeyDown_TabKey}
                onPaste  ={this.handleOnPaste}
                onCut    ={this.handleOnCut}
                onCopy   ={this.handleOnCopy}
                tabIndex={-1}
                value={txtValue}
                autoComplete='off'
                spellCheck='false'
              />

          </div>
}


{/*   THE HYPERLINK EDITOR;
      OPENED WHEN ONE CLICKS IN A LINK  */}

        { isInLink && canEdit && isActive &&
        <div className={'HyperLinkEditor'}
          style={{
            fontSize: 14,
            position: 'absolute',
            // Placement of editor always located at bottom of Component
            // Could make it programable based on current cursor position,
            // but at this time, always your at the bottom.
            right:22, top: heightLabel + heightEditMode - 8,
            borderRadius: 6, borderStyle: 'outset', borderWidth: 2,
            borderColor : constants.SCRY_SELECTION_HIGHTLIGHT_COLOR,
            background: constants.SCRY_WHITE,
            boxShadow:'0 2px 4px rgba(0, 0, 0, 1)'
        }}>
              <HyperlinkEditor
                tableid={tableid}
                tabledataid={''}
                parentMode={parentMode}
                colOrSeriesKey={colOrSeriesKey}
                rowKey={-1}
                linkIndex={linkIndex}
                textWithLinks={textWithLinks}
                synchCurrentName={ this.synchLinkEditorNameToParentDescriptionText }
                setCurrentNameNode={ this.setHyperlinkEditorNameNode }
                currentCursor={this.currentCursor}
                inputName={linkName}
                inputUrl ={linkUrl}/>
        </div>
        }


{/*   THE SELECTION -> CREATE LINK BUTTON;
      OPENED WHENEVER THE HYPERLINK EDITOR IS NOT OPEN */}

        { !isInLink && canEdit && isActive &&
        <HyperlinkButton
          handleClick={this.createLink}
          height={20}
          width={190}
          mode={'descriptionEditor'}
          top={ (isHyperlinkPositionTop)
            ? heightLabel - 5
            : heightLabel + heightEditMode - 18 }
          right={30}
        />
        }

      </div>
    )
  }
}
