
import type {TableComputedData, TableDomNodes, DomNode}  from '../computedDataTable/getDefaultTableComputedData'
import type { SessionState} from '../appCode/getDefaultSessionState'

import React, {Component, Fragment} from 'react'
import {list} from 'radash'
import {getRowGroupPlacement} from '../computedDataTable/layoutCalculator'
import {ColumnLineNumsHead, 
        ColumnLineNumsData}   from './ColumnLineNums'
import {ColumnControlsLastLockedHidden,
        ColumnControlsLastMovingHidden} from './ColumnControls'
import  CellTitle             from './CellTitle'
import  CellPublisher         from './CellPublisher'
import  FilterControl         from './FilterControl'
import  ColumnHeaders         from './ColumnHeaders'
import {RowGroup}             from './ColumnData'
import  StatsBar              from './StatsBar'

// Everything in the table layout is placed with absolute positioning.
// The primary origin is at upperLeft corner of full area available to the table.
// This is the full browser area, minus the navigationBar and the menuBar.
// The sideBar is considered a part of the table.

// There are four secondary orgins, each defined as the upperLeft corner of:
//     headerLocked_block, headerMoving_block, dataLocked_block, dataLocked_moving.
// For the two moving blocks, the secondary orgin corresponds to the container
// which holds the scrollable content.
// However, for the locked blocks, there is no equivalent container (the container
// borders are an illusion).  These locked blocks include all the scrollable
// content, plus the left/right borders, plus the left rowNumbers.
// For example, headerLocked_block's origin is the intersection of the rowNumbers
// left edge, and the column control's top edge.  (~ underneath the filter funnel).

type OwnProps = {
  tableComputedData: TableComputedData,
  sessionState: SessionState,
}
export default class LayoutMain extends Component<OwnProps> {

  init_layoutMain                 = (element: DomNode): void => { 
                                      this.props.tableComputedData.tableDomNodes.layoutMain = element}
  init_headLocked_block           = (element: DomNode): void => { 
                                      this.props.tableComputedData.tableDomNodes.headLocked_block = element}
  init_pinnedRowsBackgroundLocked = (element: DomNode): void => { 
                                      this.props.tableComputedData.tableDomNodes.pinnedRowsBackgroundLocked = element} 
  init_headMoving_block           = (element: DomNode): void => { 
                                      this.props.tableComputedData.tableDomNodes.headMoving_block = element}
  init_headMoving_container       = (element: DomNode): void => { 
                                      this.props.tableComputedData.tableDomNodes.headMoving_container = element}
  init_headMoving_content         = (element: DomNode): void => {
                                      this.props.tableComputedData.tableDomNodes.headMoving_content = element}
  init_pinnedRowsBackgroundMoving = (element: DomNode): void => {
                                      this.props.tableComputedData.tableDomNodes.pinnedRowsBackgroundMoving = element}
  init_dataLocked_block           = (element: DomNode): void => { 
                                      this.props.tableComputedData.tableDomNodes.dataLocked_block = element}
  init_dataLocked_content         = (element: DomNode): void => {
                                      this.props.tableComputedData.tableDomNodes.dataLocked_content = element}
  init_dataLocked_container       = (element: DomNode): void => {
                                      this.props.tableComputedData.tableDomNodes.dataLocked_container = element}
  init_dataMoving_block           = (element: DomNode): void => {
                                      this.props.tableComputedData.tableDomNodes.dataMoving_block = element}
  init_dataMoving_container       = (element: DomNode): void => {
                                      this.props.tableComputedData.tableDomNodes.dataMoving_container = element}
  init_dataMoving_content         = (element: DomNode): void => {
                                      this.props.tableComputedData.tableDomNodes.dataMoving_content = element}

  render() {
    //console.log( ' call to layoutMain render' )
    const tableComputedData = this.props.tableComputedData
    const { widthObj:w, heightObj:h, styleObj:s, filteredRowKeys, 
      sortedRowKeys, scrollLeft, scrollTop, isPublisherRendered,
      derivedColAttributesArray, tableDomNodes, colOrder,
      numColsHiddenInGapBetweenTables, numColsHiddenRightOfMovingTable,
      table, tablelook, tabledata } = tableComputedData
    const {pinnedRowKeys, isRowNumberVisible } = tablelook.attributes
    const {tableValues} = tabledata.attributes
    const {columns} = table.attributes
    const numRowsUnfiltered = tableValues[0].length
    const numColumns = w.displayedColWidths.length

    const headerLeftRightBorderStyle : React.CSSProperties = {
        position:'absolute', top: h.gapControlsHead + h.colControls,
        width: w.borderThickness,
        // Next line: The purpose of -1 is intentional.  Because
        // there is an overlap of the last grid line (1px) and the
        // bottom table border (1-5 px). I don't want the last header grid
        // line visible at all.  And it is easier to just cover it up with
        // the table border, rather than use a conditional during cell 
        // rendering.  Hence the total expected height in next calculation
        // is will be 1px less than what one would expect.
        height:h.headerHeight + h.pinnedHeight + 2*h.borderThickness - 1,
        background: s.foregroundColor,
    }
    var firstMovingColIndex = (w.isCombinedTable ? 0 : w.numLockedCols )

    return (

    <div  className={'rc_LayoutMain'}
      ref={ this.init_layoutMain }
      style={{
        position:'relative',
        height: h.viewHeightPx,  
        width : w.viewWidthPx,
        background: 'white', }}>

      <CellTitle
        fontSize={s.mainTitleFontSize}
        titleHeight={h.mainTitle}
        titleWidth={w.mainTitle}
        titleLeft={w.mainTitleLeft}
        titleTop={h.gapTopMainTitle}
        titleTxt={table.attributes.tableTitle} 
        tableDomNodes={tableDomNodes} />

{ isPublisherRendered &&
      <CellPublisher
        fontSize={s.publisherTitleFontSize}
        titleHeight={h.publisherTitle}
        titleWidth={w.publisherTitle}
        titleLeft={w.publisherTitleLeft}
        titleTop={h.gapTopMainTitle + h.mainTitle}
        titleTxt={table.attributes.publisherTitle}
        tableDomNodes={tableDomNodes} />
}

      <StatsBar tableComputedData={tableComputedData}/>


{/*   LOCKED HEADER BLOCK  (lineNumbers & Table)
  w.rowNumbers-h.scaledFunnelIconSize-6*/ }

        <div className={'HeadLocked_block'}
        ref={ this.init_headLocked_block }
        style={{ position:'absolute',
        top:h.colControlsTop, transform:`translate(${w.centeringOffset}px, 0px)`,
        width:w.totalLockedAllocated, height:h.totalHeaderHeight}}>

              <ColumnLineNumsHead
              lineNumColWidth={w.rowNumbers}
              pinnedRowKeys={pinnedRowKeys}
              sortedRowKeys={sortedRowKeys}
              isRowNumberVisible={isRowNumberVisible}
              pinnedTopRelative={h.pinnedTopRelative}
              pinnedHeight={h.pinnedHeight}
              rowHeight={h.rowHeight}
              rowNumbersPaddingRight={w.rowNumbersPaddingRight}
              fontSize={s.rowNumberFontSize}
              paddingTop={(h.rowHeight -1 - s.rowNumberFontSize) / 2} />

              <FilterControl
              height={h.scaledFunnelIconSize}
              numfilteredRowKeys={filteredRowKeys.length}
              numRowsUnfiltered={numRowsUnfiltered}
              top={0}
              left={0}
              width={h.scaledFunnelIconSize}/>

{ !w.isCombinedTable && w.numLockedCols > 0 &&  <Fragment>

              <div className={'LeftLockedTableBorder'}
              style={{...headerLeftRightBorderStyle, left:w.rowNumbers }} />

              <div className={'RightLockedTableBorder'}
              style={{...headerLeftRightBorderStyle, right:0}} />

              {/* Next item is the isolated 'unhide' control that appears between locked/moving tables */}
              <ColumnControlsLastLockedHidden
              foregroundColor={s.foregroundColor}
              colHeight={h.colControls}
              numColsHiddenInGapBetweenTables={numColsHiddenInGapBetweenTables}
              gapLockedMoving={w.gapLockedMoving} />

              <div className={'HeaderLockedContent'}
              style={{ position:'relative', top:0, left: w.rowNumbers+w.borderThickness,
              width: w.lockedRequired, height:'100%'}}>

                    <div className={'pinnedRowsBackground'}
                    ref={ this.init_pinnedRowsBackgroundLocked }
                    style={{position:'absolute', top:h.pinnedTopRelative, left:0,
                    width:w.lockedAllocated, height:h.pinnedHeight}}/>

                    <ColumnHeaders
                    table={table}
                    tablelook={tablelook}
                    tabledata={tabledata}
                    tableComputedData={tableComputedData}
                    startColIndex={0}
                    stopColIndex={w.numLockedCols-1}/>

              </div>
</Fragment>}
        </div>

{/*  MOVING HEADER TABLE */}

        <div className={'HeadMoving_block'}
          ref={ this.init_headMoving_block }
          style={{ position:'absolute',
          top:h.colControlsTop, //left:w.movingLeft-w.borderThickness,
          transform:`translate(${w.movingLeft-w.borderThickness}px, 0px)`,
          width:w.totalMovingAllocated, height:h.totalHeaderHeight, }}>

              <div className={'LeftMovingTableBorder'}
              style={{...headerLeftRightBorderStyle, left:0}} />

              <div className={'RightMovingTableBorder'}
              style={{...headerLeftRightBorderStyle, right:w.scrollControlTotalWidth }} />

              {/* Next item is the isolated 'unhide' control that may appear after moving table */}
              <ColumnControlsLastMovingHidden
              tableDomNodes={this.props.tableComputedData.tableDomNodes}
              foregroundColor={s.foregroundColor}
              colHeight={h.colControls}
              priorHiddenColumns={numColsHiddenRightOfMovingTable}
              lastMovingRightWidth={w.scrollControlTotalWidth}
              scrollLeftOffset={w.movingRequired - w.movingAllocated - scrollLeft} />

              <div className={'MovingContainer_RelativeCoords'}
              ref={ this.init_headMoving_container }
              style={{position:'relative', left:w.borderThickness,
              width:w.movingAllocated, height:'100%', overflow:'hidden', }} >

                  <div className={'HeadMoving_content'}
                  ref={ this.init_headMoving_content }
                  style={{position:'relative', transform:`translate(${-scrollLeft}px, 0px)`,
                  width: w.movingRequired, height:'100%'}}>

                        <div className={'pinnedRowsBackground'}
                        ref={ this.init_pinnedRowsBackgroundMoving }
                        style={{position:'absolute', top:h.pinnedTopRelative, left:0,
                        width:'100%', height:h.pinnedHeight}}/>

                        <ColumnHeaders
                        table={table}
                        tablelook={tablelook}
                        tabledata={tabledata}
                        tableComputedData={tableComputedData}
                        startColIndex={firstMovingColIndex}
                        stopColIndex={numColumns-1}/>

                  </div>
              </div>
        </div>

{/* Error Message if there are zero rows */}

{ !h.doDataRowsExist &&

        <div className={'zeroRowsErrorMessage'}
        style={{ position: 'absolute', top: h.dataTop,
        height:h.dataAllocatedWithBorder, width: '100%',
        color : '#990000', textAlign: 'center' }}>
          <div>All rows removed by filtering. Disable some or all your row filters.</div>
        </div>

}


{ h.doDataRowsExist && <Fragment>

{/*  LOCKED DATA TABLE */}

        <div className={'DataLocked_block'}
        ref={ this.init_dataLocked_block }
        style={{ position:'absolute', top:h.dataTop-h.borderThickness,
        width: w.totalLockedAllocated, height: h.dataAllocatedWithBorder,
        transform:`translate(${w.centeringOffset}px, 0px)`, }} >

{/*  LOCKED LINE NUMBERS */}

            <div className={'LineNumContainer_RelativeCoords'}
            style={{position:'absolute', left:0, top:h.borderThickness,
            width:w.rowNumbers, height:h.dataAllocated, overflow: 'hidden'}} >

                <div className={'LockedContent'}
                ref={ this.init_dataLocked_content }
                style={{position:'relative', width:'100%', height:h.dataRequired }} >

                    { list( h.numRowGroups-1 ).map( rowGroupIndex=>{
                      const {topOffset, firstRowIndex} = getRowGroupPlacement( rowGroupIndex, 
                                 scrollTop, h.numRowGroups, h.rowsPerGroup, h.rowGroupHeight )
                      return(

                      <div className={`RowGroup_${rowGroupIndex}`}
                      ref={ (n)=>this.props.tableComputedData.tableDomNodes.lineNumRowGroups[rowGroupIndex] = n }
                      key={rowGroupIndex}
                      style = {{ position: 'absolute', transform: `translate(0px, ${topOffset}px)`,
                      //background: (rowGroupIndex%2 === 0) ? 'pink' : 'red',
                      height: h.rowGroupHeight, width: '100%', }}>

                          <ColumnLineNumsData
                          firstRowIndex={firstRowIndex}
                          rowsPerGroup={h.rowsPerGroup}
                          isRowNumberVisible={tablelook.attributes.isRowNumberVisible}
                          rowHeight={h.rowHeight}
                          rowNumbersPaddingRight={w.rowNumbersPaddingRight}
                          fontSize={s.rowNumberFontSize}
                          paddingTop={(h.rowHeight -1 - s.rowNumberFontSize) / 2} />

                      </div>
                    )})}
                </div>
            </div>

{/*  LOCKED DATA TABLE  */}

{ !w.isCombinedTable && w.numLockedCols > 0 &&  <Fragment>

            <div className={'LockedContainer_RelativeCoords'}
            ref={ this.init_dataLocked_container }
            style={{position:'absolute', left:w.rowNumbers,
            width:w.lockedAllocatedWithBorder, height:h.dataAllocatedWithBorder,
            boxSizing: 'border-box', overflow: 'hidden',
            borderWidth : w.borderThickness, borderColor: s.foregroundColor, borderStyle: 'solid'}} >

                <div className={'LockedContent'}
                ref={ this.init_dataLocked_content }
                style={{position:'relative', width:w.lockedRequired, height:h.dataRequired,
                lineHeight: 1, whiteSpace: 'nowrap',
                fontSize:s.cellFontSize, fontWeight: 'normal',
                }} >

                    { list( h.numRowGroups-1 ).map( rowGroupIndex=>{
                    const {topOffset, firstRowIndex} = getRowGroupPlacement( rowGroupIndex, 
                                scrollTop, h.numRowGroups, h.rowsPerGroup, h.rowGroupHeight )
                    return (

                          <div className={'RowGroupContainer'}
                          ref={ (n)=> this.props.tableComputedData.tableDomNodes.lockedRowGroups[rowGroupIndex] = n }
                          key={rowGroupIndex}
                          style = {{ position: 'absolute', height: h.rowGroupHeight, width: '100%',
                            //background: (rowGroupIndex%2 === 0) ? 'pink' : 'red',
                          transform: `translate(0px, ${topOffset}px)`, }}>

                              <RowGroup
                              tableComputedData={tableComputedData}
                              sortedRowKeys={sortedRowKeys}
                              startColLeft={w.startColLeft}
                              displayedColWidths={w.displayedColWidths}
                              firstVisibleLockedCol={w.firstVisibleLockedCol}
                              firstVisibleMovingCol={w.firstVisibleMovingCol}
                              startColIndex={0}
                              stopColIndex={w.numLockedCols-1}
                              isBrightField={s.isBrightField}
                              isCombinedTable={w.isCombinedTable}
                              rowGroupIndex={rowGroupIndex}
                              pinnedRowKeys={null}
                              backgroundEven={s.cellColorEven}
                              backgroundOdd={s.cellColorOdd}
                              gridHorzColor={s.gridHorzColor}
                              gridVertColor={s.gridVertColor}
                              cellBottomOffset={h.cellBottomOffset}
                              firstRowIndex={firstRowIndex}
                              rowsPerGroup={h.rowsPerGroup}
                              rowHeight={h.rowHeight}
                              derivedColAttributesArray={derivedColAttributesArray}
                              colOrder={colOrder}
                              tableValues={tableValues}
                              />

                        </div>
                    )})}
                </div>
            </div>

</Fragment>}

        </div>

{/*  MOVING DATA TABLE */}

        <div className={'DataMoving_block'}
        ref={ this.init_dataMoving_block }
        style={{ position:'absolute', top:h.dataTop-h.borderThickness,
        width: w.movingAllocatedWithBorder, height: h.dataAllocatedWithBorder,
        transform:`translate(${w.movingLeft-w.borderThickness}px, 0px)` }} >

            <div className={'MovingContainer_RelativeCoords'}
            ref={ this.init_dataMoving_container }
            style={{position:'relative',
            width:w.movingAllocatedWithBorder, height:h.dataAllocatedWithBorder,
            boxSizing: 'border-box', overflow: 'hidden',
            borderStyle: s.borderStyle,
            borderWidth: w.borderThickness, borderColor:s.foregroundColor}}>

                <div className={'MovingContent'}
                ref={ this.init_dataMoving_content }
                style={{position:'absolute', transform:`translate(${-scrollLeft}px, 0px)`,
                width:w.movingRequired, height:h.dataRequired,
                lineHeight: 1, whiteSpace: 'nowrap',
                fontSize:s.cellFontSize, fontWeight: 'normal', }} >

                    { list( h.numRowGroups-1 ).map( rowGroupIndex=>{
                    const {topOffset, firstRowIndex} = getRowGroupPlacement( rowGroupIndex, 
                              scrollTop, h.numRowGroups, h.rowsPerGroup, h.rowGroupHeight )
                    return(

                        <div className={`RowGroupContainer`}
                        ref={ (n)=> this.props.tableComputedData.tableDomNodes.movingRowGroups[rowGroupIndex] = n }
                        key={rowGroupIndex}
                        style = {{ position: 'absolute', height: h.rowGroupHeight, width: '100%',
                        //background: (rowGroupIndex%2 === 0) ? 'pink' : 'red',
                        transform: `translate(0px, ${topOffset}px)`, }}>


                            <RowGroup
                            tableComputedData={tableComputedData}
                            sortedRowKeys={sortedRowKeys}
                            startColLeft={w.startColLeft}
                            displayedColWidths={w.displayedColWidths}
                            firstVisibleLockedCol={w.firstVisibleLockedCol}
                            firstVisibleMovingCol={w.firstVisibleMovingCol}
                            startColIndex={firstMovingColIndex}
                            stopColIndex={numColumns-1}
                            isBrightField={s.isBrightField}
                            isCombinedTable={w.isCombinedTable}
                            rowGroupIndex={rowGroupIndex}
                            pinnedRowKeys={null}
                            backgroundEven={s.cellColorEven}
                            backgroundOdd={s.cellColorOdd}
                            gridHorzColor={s.gridHorzColor}
                            gridVertColor={s.gridVertColor}
                            cellBottomOffset={h.cellBottomOffset}
                            firstRowIndex={firstRowIndex}
                            rowsPerGroup={h.rowsPerGroup}
                            rowHeight={h.rowHeight}
                            derivedColAttributesArray={derivedColAttributesArray}
                            colOrder={colOrder}
                            tableValues={tableValues}
                            />

                        </div>
                      )})}
                </div>
            </div>
        </div>

</Fragment>}

    </div>)}  // Top Level Div
}
