import invariant from 'invariant'
import type { TableWidthObj, 
              TableHeightObj, 
              TableStyleObj} from '../computedDataTable/layoutCalculator'

var underlyingTarget = {
  focusVertSlice : '',
  focusHorzSlice : '',
  parentTop : 0,
  parentLeft: 0,
  parentWidth: 0,
  parentHeight: 0,
  colKey: -1,
  rowKey: -1,
  colIndex: 0,
  rowIndex: 0,
  cellTop : 0,
  cellLeft: 0,
  cellWidth: 0,
  cellHeight: 0,
  controlName: '',
  caseName: '',  // This parameter is assigned later (not in this function)
}
export type TableFocalPlane_UnderlyingTarget = typeof underlyingTarget


const matchList = ( coord: number, matchList : Object ): { key : string, offset: number, dimension: number} => {
  let lastOffset: number = 0
  let lastDimension: number = 0
  var output = {
    key: '',
    offset: 0,
    dimension: 0,
  }
  Object.entries(matchList).every(
    ([key,value]) => {
      let dimension = Number(value)
      var offset = lastOffset + lastDimension
      if ( coord < offset + dimension ) {
        output = { key, offset, dimension }
        return false
      }
      lastOffset = offset
      lastDimension = dimension
      return true
    }
  )
  return output
}




// Given a (x,y) point, and the widthObj, and heightObj,
// find the corresponding location in the layout.
export const focusCalculator = ( x: number, y: number, w:TableWidthObj, 
  h:TableHeightObj, s:TableStyleObj, scrollLeft: number, 
  scrollTopIn: number, colOrder: Array<number>, sortedRowKeys: Array<number>, 
  pinnedRowKeys: Array<number>) : TableFocalPlane_UnderlyingTarget => {

  const {startColLeft, displayedColWidths} = w
  const stopColLeft = ( colIndex: number ) : number => {
    return startColLeft[colIndex] + displayedColWidths[colIndex]
  }

  // check filter funnel first
  const funnelLeft = w.centeringOffset
  const funnelTop  = h.filterControlTop
  const funnelSize = h.scaledFunnelIconSize
  if (x > funnelLeft && x < funnelLeft + funnelSize
    && y > funnelTop && y < funnelTop + funnelSize) {
      return {
        focusVertSlice : 'rowNumbers',
        focusHorzSlice : 'colControls',
        parentTop : funnelLeft,
        parentLeft: funnelTop,
        parentWidth: funnelSize,
        parentHeight: funnelSize,
        colKey: -1,
        rowKey: -1,
        colIndex: 0,
        rowIndex: 0,
        cellTop : funnelTop,
        cellLeft: funnelLeft,
        cellWidth: funnelSize,
        cellHeight: funnelSize,
        controlName: 'rowFiltering',
      }
    }

  const grid = h.borderThickness

  // Layout heights, from top to bottom:
  const heightList = {
    gapTopMainTitle : h.gapTopMainTitle,
    mainTitle     : h.mainTitle,
    publisherTitle  : h.publisherTitle,
    gapSourceTitleControls : h.gapSourceTitleControls,
    colControls: h.colControls,
    gapControlsHead: h.gapControlsHead + grid,
    head : h.headerHeight,
    pinned: h.pinnedHeight,
    gapBorderHeadData: h.gapHeadData + 2*grid,
    data : h.dataAllocated,
    horzScroll: h.horzScrollControlHeight + grid,
    emptySpace: h.emptySpaceBelowTable,
    statsHeight: h.statsHeight,
  }

  // Layout widths, from left to right:
  const widthList = {
    leftMargin : w.centeringOffset,
    rowNumbers : w.rowNumbers,
    lockedLeftGridBorder : grid,
    locked : w.lockedAllocated,
    gapBorderLockedMoving : (w.isCombinedTable || w.numLockedCols === 0 ) ? 0 : w.gapLockedMoving + 2*grid,
    moving : w.movingAllocated,
    movingRightGridBorder : grid,
    scroll : s.gapTableScroll + s.scrollThumbWidth,
    rightMargin : w.centeringOffset,
  }

  var { key, offset, dimension } = matchList ( y, heightList )
  const out : TableFocalPlane_UnderlyingTarget = underlyingTarget
  out.focusHorzSlice = key
  out.parentTop = offset
  out.parentHeight = dimension;
  ({ key, offset, dimension } = matchList ( x, widthList ))
  out.focusVertSlice = key
  out.parentLeft = offset
  out.parentWidth = dimension
  if ( out.focusHorzSlice === '' || 
       out.focusVertSlice === '' || 
       out.focusHorzSlice === 'emptySpace' ||
       out.focusVertSlice === 'emptySpace ') { return out }

  // Calc the cell position and indices (colKey and rowKey )
  var scrollTop = 0 // Case of header, colControls
  var xCursorLocationInContent = 0
  var xCursorLocationInCell = 0
  var cellTopWithRespectToContainer = 0
  var cellTopWithRespectToView = 0
  if (out.focusHorzSlice === 'data') scrollTop = scrollTopIn

  switch ( out.focusHorzSlice ) {
    case 'colControls':
      out.cellTop = h.colControlsTop
      out.cellHeight = h.colControls
      break

    case 'data' :
    case 'pinned' :
      let yRow = y - out.parentTop + scrollTop
      var indexAsViewed = Math.max( 0, Math.trunc( yRow / h.rowHeight))
      out.rowIndex = indexAsViewed
      out.rowKey = (out.focusHorzSlice === 'data')
        ? out.rowKey = sortedRowKeys[indexAsViewed]
        : out.rowKey = pinnedRowKeys[indexAsViewed ]
      cellTopWithRespectToContainer = indexAsViewed * h.rowHeight - scrollTop
      cellTopWithRespectToView = cellTopWithRespectToContainer + out.parentTop
      out.cellTop = cellTopWithRespectToView
      out.cellHeight = h.rowHeight
      break

    case 'head' :
      out.rowIndex = 0
      out.rowKey = 0
      out.cellTop = 0
      out.cellHeight = h.headerHeight
      break

   // change out.focusHorzSlice to '' if the click exceeds the text width.
    case 'mainTitle' :
      if ( x < w.mainTitleLeft || x > w.mainTitleLeft + w.mainTitle ) {
        out.focusHorzSlice = ''
      }
      break
    case 'publisherTitle' :
      if ( x < w.publisherTitleLeft || x > w.publisherTitleLeft + w.publisherTitle ) {
        out.focusHorzSlice = ''
      }
      break

    default:
      out.rowIndex = 0
      out.rowKey = 0
      out.cellTop = 0
      out.cellHeight = 0
  }


  switch ( out.focusVertSlice ) {
    case 'locked' :
    case 'moving' :
      var xCursorLocationInContainer = (x - out.parentLeft)
      xCursorLocationInContent = (out.focusVertSlice === 'locked')
        ? xCursorLocationInContainer
        : xCursorLocationInContainer += scrollLeft
      var i = 0
      if (out.focusVertSlice === 'locked') {
        for (i=0; i< w.numLockedCols; i++ ) {
          if ( xCursorLocationInContent < stopColLeft(i) ) { break }
        }
      } else if (out.focusVertSlice === 'moving' && !w.isCombinedTable) {
        for (i=w.numLockedCols; i< startColLeft.length; i++ ) {
          if ( xCursorLocationInContent < stopColLeft(i) ) { break }
        }
      } else if (out.focusVertSlice === 'moving' &&  w.isCombinedTable) {
        for (i=0; i< startColLeft.length; i++ ) {
          if ( xCursorLocationInContent < stopColLeft(i) ) { break }
        }
      }
      i = Math.min( i, startColLeft.length-1 )   // Safety check
      out.colIndex = i
      out.colKey = colOrder[i]
      out.cellWidth= displayedColWidths[i]
      out.cellLeft = out.parentLeft + stopColLeft(i) - out.cellWidth
      if ( w.isCombinedTable || out.focusVertSlice === 'moving' ) out.cellLeft -= scrollLeft
      break
    case 'rowNumbers':
      out.colKey = -1
      out.cellLeft = widthList.leftMargin
      out.cellWidth = widthList.rowNumbers
      break
    default:
      out.colIndex = 0
      out.colKey = 0
      out.cellLeft = 0
      out.cellWidth = 0
  }

  // Extra refinement of

  // Extra refinement of location needed for col controls
  xCursorLocationInCell = x - out.cellLeft
  var cursorHeightRespectToParent = y - out.parentTop
  out.controlName = ''
  // Controls set into square containers, of h.colControls in both x and y.
  if (out.focusHorzSlice === 'colControls' && (out.focusVertSlice === 'locked' || out.focusVertSlice === 'moving') ) {
    out.cellWidth = h.colControls
    if      ( xCursorLocationInCell < 1*h.colControls ) { out.controlName = 'hidden'}
    else if ( xCursorLocationInCell < 2*h.colControls ) { out.controlName = 'drag'; out.cellLeft += h.colControls}
    else if ( xCursorLocationInCell < 3*h.colControls ) { out.controlName = 'sort'; out.cellLeft += 2 * h.colControls}
    else { out.controlName = 'empty' }
  }

  if (out.controlName === 'sort') {
    out.cellHeight = h.colControls/2
    //var cursorHeightRespectToParent = y - out.parentTop
    if      ( cursorHeightRespectToParent >  h.colControls/2 ) { out.controlName = 'sortDescending'; out.cellTop += h.colControls/2}
    else if ( cursorHeightRespectToParent <  h.colControls/2 ) { out.controlName = 'sortAscending'}
    else { out.controlName = 'empty'}
  }

  // Location of 'hide & unhide' controls when they correspond to a defined column'
  if (out.controlName === 'hidden' && out.colKey >= 0 ) {
    out.cellHeight = h.colControls/2
    //var cursorHeightRespectToParent = y - out.parentTop
    if      ( cursorHeightRespectToParent >  h.colControls/2 ) { out.controlName = 'hide'; out.cellTop += h.colControls/2}
    else if ( cursorHeightRespectToParent <  h.colControls/2 ) {
      // check if there are actually any columns to unhide
      if (out.colIndex > 0 && displayedColWidths[out.colIndex - 1] === 0) {
        out.controlName = 'unhide'
      } else {
        out.controlName = 'empty'
      }
    }
    else    { out.controlName = 'empty'}
  }

  // Location of the 2 lastColumn 'hide & unhide' controls.  They DO NOT fall within a defined column!
  if (out.focusHorzSlice === 'colControls') {
    // Is the last lockedCol unhide control present?
    // zero width column means the unhide control is displayed
    if (  w.numLockedCols && displayedColWidths[w.numLockedCols-1] === 0 && out.focusVertSlice === 'gapBorderLockedMoving') {
      out.controlName = 'unhideLastLockedCol'
      out.colKey = colOrder[w.numLockedCols-1]
      out.colIndex = w.numLockedCols-1
      out.cellLeft = out.parentLeft
      out.cellWidth = out.parentWidth
    }
    let lastColIndex = displayedColWidths.length - 1
    if (  displayedColWidths[lastColIndex] ===0   // zero width column means the unhide control is displayed
        && out.focusVertSlice === 'scroll'                      // Click area is intersection of scroll Col and controls Row
        && w.movingRequired - ( w.movingAllocated + scrollLeft ) < 20  // Right edge of table must be in current scroll view. (allow it to be 'near'.)
       ) {
      out.controlName = 'unhideLastMovingCol'
      out.colKey = colOrder[lastColIndex]
      out.colIndex = lastColIndex
      out.cellLeft = out.parentLeft
      out.cellWidth = out.parentWidth
    }
  }

  //console.log( w, out )
  return out
}



// Given a name:
//   'titleMain', 'publisherMain', 'legend', 'bottomAxis', or 'leftAxis
// plus the seriesKey and seriesOrder (for legend) and the w,h,s objects:
// Return the geometric locations {top, left, height, width} that will be
// used for the highlighter outline when selected.
export const focusCalculator_nameToCellObj = (
  name:string, colIndex:number, rowIndex:number, scrollLeft:number, scrollTop:number,
  w: TableWidthObj, h: TableHeightObj, s: TableStyleObj ) :
      {top:number, left:number, height:number, width:number, isCellVisible:boolean } => {


    // This function is only usefull in 4 conditions:
    // name === 'mainTitle'
    // name === 'publisherTitle'
    // rowIndex >= 0 and colIndex >= 0  (Same condition as name = pinned or data)

    var isRowIndexPresent = ( rowIndex >= 0 )
    var isColIndexPresent = ( colIndex >= 0 )
    var isSelectionRequiresIndices = (name === 'tableHeader' || name === 'pinnedCell' || name === 'dataCell')

    // Invalid calling parameters is this selection requires indices but row/col may not be set. (-1 or undefined)
    var isInvalidCall = isSelectionRequiresIndices && (!isRowIndexPresent || !isColIndexPresent)
    if ( process.env.NODE_ENV === 'development' && isInvalidCall ) {
      invariant( false, `Called focusCalculator_nameToCellObj: invalid parameters! rowInd=${rowIndex}, colInd=${colIndex}, name=${name}`)
    } else if ( isInvalidCall ) {
      return {top:0, left:0, width:0, height:0, isCellVisible:false}
    }


    ///////////////////////////////////////////////////////////////////////
    //  Step #1: Find the bounding box for the selected cell
    ///////////////////////////////////////////////////////////////////////

    const {viewWidthPx, movingLeft, numLockedCols, isCombinedTable, movingAllocated,
      lockedLeft, mainTitle:mainTitleWidth, publisherTitle:publisherTitleWidth} = w


    // Calculation of left/right selected cell position
    if ( name === 'mainTitle' ) {
      var left  = (viewWidthPx - mainTitleWidth)/2
      var right = (viewWidthPx + mainTitleWidth)/2
      left  = Math.max( 0, left )
      right = Math.min( viewWidthPx, right )
    } else if ( name === 'publisherTitle' ) {
      left  = (viewWidthPx - publisherTitleWidth)/2
      right = (viewWidthPx + publisherTitleWidth)/2
      left  = Math.max( 0, left )
      right = Math.min( viewWidthPx, right )
    } else if ( name === 'rowFiltering' ) {
      left  = w.centeringOffset - 2
      right = left + h.scaledFunnelIconSize + 4
    } else if ( colIndex > numLockedCols-1 || isCombinedTable ) {
      left  = movingLeft + w.startColLeft[colIndex] - scrollLeft
      right = left + w.displayedColWidths[colIndex]
      left = Math.max( left, movingLeft )
      right= Math.min( right, movingLeft+movingAllocated )
    } else {  // A locked column
      left  = lockedLeft + w.startColLeft[colIndex]
      right = left + w.displayedColWidths[colIndex]
    }

    // Calculation of top/bottom selected cell position
    const {borderThickness, headTop, headerHeight, gapTopMainTitle,
      mainTitle, publisherTitle, rowHeight, dataAllocated, dataTop,
      viewHeightPx, statsHeight } = h

    if ( name === 'tableHeader' ) {
      var top = borderThickness + headTop
      var bottom = top + headerHeight
    } else if ( name === 'mainTitle' ) {
      top = gapTopMainTitle
      bottom = top+mainTitle
    } else if ( name === 'publisherTitle' ) {
      top = gapTopMainTitle+mainTitle
      bottom = top+publisherTitle
    } else if ( name === 'rowFiltering' ) {
      top = h.colControlsTop - 4
      bottom = top+h.scaledFunnelIconSize + 6
    } else if ( name === 'pinnedCell' ) {
      top = borderThickness + headTop + headerHeight + rowHeight * rowIndex
      bottom = top + rowHeight
    } else if ( name === 'dataCell' ) {
      top = (rowHeight * rowIndex ) - scrollTop + dataTop
      bottom = top + rowHeight
      top = Math.max( dataTop, top )
      bottom = Math.min( dataTop+dataAllocated, bottom )
    } else {
      top = 0
      bottom = 0
    }

    //  If the selected cell is 'out-of-view', then we want to
    //  close the palette.   We need to make this determination BEFORE
    //  we even begin to calculate the Editor's size and location.
    //
    //   Because if isCellHidden === true, we NEED to close the palette
    //   ASAP.  NOT closing the palette immediately will trigger a Editor render,
    //   which triggers the SizeMe routine to 'double render'.   And this SizeMe
    //   double render will fail because the editor exists on the first SizeMe
    //   render, will subsequently unmounted before the second SizeMe render.

    var isCellHidden = false
    if (name === 'dataCell' && bottom < dataTop + rowHeight/2) { isCellHidden = true }
    if (name === 'dataCell' && top > dataTop + dataAllocated - rowHeight/2) { isCellHidden = true }
    if (name === 'tableHeader' && top > viewHeightPx - statsHeight - rowHeight ) { isCellHidden = true }

    // Lack of valid row/col index if required:
    if (isRowIndexPresent === false && isSelectionRequiresIndices) { isCellHidden = true }
    if (isColIndexPresent === false && isSelectionRequiresIndices) { isCellHidden = true }

    // row/column indices valid, but we scroll this column off the left  edge of table
    if ( isSelectionRequiresIndices ) {
      if ((colIndex > numLockedCols-1 || isCombinedTable) && right < movingLeft + 20) { isCellHidden = true }
      // close palette if we scroll this column off the right edge of table
      if ((colIndex > numLockedCols-1 || isCombinedTable) && left  > movingLeft + movingAllocated - 20) { isCellHidden = true }
    }




    return  { top, left, height:bottom-top, width:right-left, isCellVisible : !isCellHidden }
}
