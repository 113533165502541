import toCamelCase from 'to-camel-case'

// This code built from the following npm packages:
// dom-css
// prefix-style
// add-px-to-style

/* The following list is defined in React's core */
const IS_UNITLESS: {[key: string]: boolean} = {
    animationIterationCount: true,
    boxFlex: true,
    boxFlexGroup: true,
    boxOrdinalGroup: true,
    columnCount: true,
    flex: true,
    flexGrow: true,
    flexPositive: true,
    flexShrink: true,
    flexNegative: true,
    flexOrder: true,
    gridRow: true,
    gridColumn: true,
    fontWeight: true,
    lineClamp: true,
    lineHeight: true,
    opacity: true,
    order: true,
    orphans: true,
    tabSize: true,
    widows: true,
    zIndex: true,
    zoom: true,

    // SVG-related properties
    fillOpacity: true,
    stopOpacity: true,
    strokeDashoffset: true,
    strokeOpacity: true,
    strokeWidth: true
}

export type Value = number | string

const addPxToStyle = (name: string, value: Value): string => {
    if(typeof value === 'number' && !IS_UNITLESS[ name ]) {
        return `${value}px`
    } else {
        return `${value}`
    }
}

var div: HTMLDivElement | null = null
const prefixes = [ 'Webkit', 'Moz', 'O', 'ms' ]

const prefixStyle = (prop: string): string | undefined => {
    // re-use a dummy div
    if (!div) {
        div = document.createElement('div')
    }

    const style = div.style

    // prop exists without prefix
    if (prop in style) {
        return prop
    }

    // borderRadius -> BorderRadius
    var titleCase = prop.charAt(0).toUpperCase() + prop.slice(1)

    // find the vendor-prefixed prop
    for (var i = prefixes.length; i >= 0; i--) {
        var name = prefixes[i] + titleCase
        // e.g. WebkitBorderRadius or webkitBorderRadius
        if (name in style) {
            return name
        }
    }

    return
}

export type Properties = {
    [key: string]: Value
}

const cache: {[key: string]: string} = { 'float': 'cssFloat' }

const detect = (cssProp: string): string | undefined => {
    var camel = toCamelCase(cssProp)
    var result = prefixStyle(camel)
    if (result === undefined) {
        return undefined
    }
    cache[camel] = cache[cssProp] = cache[result] = result
    return result
}

const style = (element: HTMLElement, property: string, value: Value): void => {
    let camel: string | undefined = cache[property]
    if (camel === undefined) {
        camel = detect(property)
    }

    // may be false if CSS prop is unsupported
    if (camel) {
        if (value === undefined) {
            return
        }

        element.style.setProperty(camel, addPxToStyle(camel, value))
    }
}

const each = (element: HTMLElement, properties: Properties): void => {
    for (const k in properties) {
        if (properties.hasOwnProperty(k)) {
            style(element, k, properties[k])
        }
    }
}

export const set = (element: HTMLElement, properties: Properties): void => {
    each(element, properties)
}

export default set
