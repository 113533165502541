import type { PlotXyComputedData } from '../computedDataPlotXY/xy_plotTypes'
import type {
  ConnectDragPreview,
  ConnectDragSource,
  ConnectDropTarget,
  DragSourceConnector,
  DragSourceMonitor,
  DragSourceSpec,
  DropTargetConnector,
  DropTargetMonitor,
} from 'react-dnd'

import {Component, PureComponent}   from 'react'
import {DragSource, DropTarget} from 'react-dnd'
import {set_floatingPalette_isActiveDrag} from '../floatingPalette/HighlightedFloatingPalette'
import {reactMinorDispatch}     from '../sharedComponents/reactDispatch'


const DEBUG = false
const FONT_SIZE  = 14
const COLOR_SVG_SIZE = 18
const COLOR_SVG_MARGINRIGHT = 6
const TITLE_WIDTH = 180
const DND_SVG_SIZE = 14
const DND_SVG_WIDTH = 24    // SVG centered in this width. DND grabSource = this width

type OwnProps = {
  seriesKey: number,
  moveItem: (seriesKey: number, atIndex: number, dragStop: boolean) => void,
  plotXyComputedData: PlotXyComputedData,
  rowHeight: number,
}
type SourceProps = {
  connectDragPreview: ConnectDragPreview,
  connectDragSource: ConnectDragSource,
  isDragging: boolean,
}
type TargetProps = {
  connectDropTarget: ConnectDropTarget,
}
type Props = OwnProps & SourceProps & TargetProps

class SeriesItemRender extends PureComponent<Props> {

  render() {
    const {connectDragPreview, connectDragSource, connectDropTarget,
      isDragging, seriesKey, plotXyComputedData, rowHeight } = this.props
    const { seriesTitle, color }  =  plotXyComputedData.seriesAttributesArray[seriesKey]

    const dropTarget = connectDropTarget(
      <div className={'rc_SeriesItem'}
        style={{ opacity: isDragging ? 0 : 1,}}>


{connectDragSource(
        <div
          style={{
            cursor: 'pointer',
            height: rowHeight,
            background: DEBUG ? 'green' : 'none',
            display: 'flex',
            flexFlow: 'row nowrap', alignItems:'center',
          }}
        >

            <div style={{
              flex: '0 0 auto',
              width:COLOR_SVG_SIZE, height:COLOR_SVG_SIZE,
              marginRight:COLOR_SVG_MARGINRIGHT,
              }}
            >
               <svg
                className='ColorBoxSVG'
                version='1.1'
                viewBox='0 0 1024 1024'
                xmlns='http://www.w3.org/2000/svg' >
                  <path style={{ fill:color }} d='M 0,0 H 1024 V 1024 H 0 Z' />
                </svg>
            </div>

            <div
              style={{
                flex: '0 0 auto',
                width: TITLE_WIDTH,
                fontSize: FONT_SIZE,
                lineHeight: 1,
                height: '100%',
                background: DEBUG ? 'red' : 'transparent',
                paddingTop: Math.round((rowHeight - FONT_SIZE)/2),  // Centering.
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                paddingRight: 8,
              }}> {seriesTitle} </div>

            <div className='DnDdragCaptureDiv'
              style={{
                flex: '0 0 auto',
                width: DND_SVG_WIDTH, height:'100%',
                background:DEBUG?'orange':'none',
                display: 'flex',
                flexFlow: 'row nowrap', alignItems:'center', justifyContent:'center' , // center the SVG in this div
              }}
            >
              <div style={{flex: '0 0 auto', width: DND_SVG_SIZE, height: DND_SVG_SIZE }}>
                <svg
                  className='DragNdropIcon'
                  style={{cursor: 'grab'}}
                  version="1.1"
                  viewBox="0 0 512 512"
                  xmlns="http://www.w3.org/2000/svg"
                  >
                  <rect height="32" width="512" y="144"/>
                  <rect height="32" width="512" y="240"/>
                  <rect height="32" width="512" y="336"/>
                </svg>
              </div>
            </div>
        </div>
)}
      </div>
    )

    if (dropTarget) {
      return connectDragPreview(dropTarget)
    } else {
      return null
    }
  }
}

type DragObject = {
  seriesKey: number
  originalSeriesIndex: number
}

const mapDragSource: DragSourceSpec<Props, DragObject> = {
  beginDrag: (props: Props, monitor: DragSourceMonitor, component: Component<Props>): DragObject => {
    const {seriesKey, plotXyComputedData} = props
    set_floatingPalette_isActiveDrag(true)
    const originalSeriesIndex = plotXyComputedData.seriesOrder.indexOf( seriesKey )
    reactMinorDispatch([{newVal: -1, path: 'attributes.minorState.sideBarSeriesKeyExpanded'}])
    return {
      seriesKey,
      originalSeriesIndex
    }
  },
  endDrag: (props: Props, monitor: DragSourceMonitor, component: Component<Props>): void => {
    set_floatingPalette_isActiveDrag(false)
    const {seriesKey: draggedSeriesKey, originalSeriesIndex } = monitor.getItem()
    props.moveItem(draggedSeriesKey, originalSeriesIndex, true)
  },
}

const mapDropTarget = {
  hover: (props: Props, monitor: DropTargetMonitor, component: Component<Props>): void => {
    const { seriesKey: draggedSeriesKey } = monitor.getItem()
    const overSeriesKey = props.seriesKey
    const overIndex = props.plotXyComputedData.seriesOrder.indexOf( overSeriesKey )
    if (draggedSeriesKey !== overSeriesKey) {
      props.moveItem(draggedSeriesKey, overIndex, false)
    }
  },

  canDrop: (props: Props, monitor: DropTargetMonitor): boolean => { return false }
}

const mapSourceProps = (connect: DragSourceConnector, monitor: DragSourceMonitor): SourceProps => ({
  // Functions we need or wish to pass to the source component (as props)
  connectDragPreview: connect.dragPreview(),
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging(),
})

const mapTargetProps = (connect: DropTargetConnector, monitor: DropTargetMonitor): TargetProps => (
  { connectDropTarget: connect.dropTarget() }
)

export const DragSeriesItemType = 'PLOT_SERIES'
const SeriesItemDragSource = DragSource(DragSeriesItemType, mapDragSource, mapSourceProps)(SeriesItemRender)
const SeriesItem           = DropTarget(DragSeriesItemType, mapDropTarget, mapTargetProps)(SeriesItemDragSource)
export default SeriesItem
