
import type { PlotXyComputedData } from '../computedDataPlotXY/xy_plotTypes'
import type { Table, Tablelook } from '../types'
import type { TableComputedData } from '../computedDataTable/getDefaultTableComputedData'

import { PureComponent, Fragment } from 'react'
import reactDispatch from '../sharedComponents/reactDispatch'
import constants from '../sharedComponents/constants'
import { distinctColors } from '../sharedComponents/ShapeAndColorPicker'
import { HelpPanelDescription } from '../fp_table_old/HelpPanels'
import { parseRenderedLayerID } from '../computedDataPlotXY/plotUtils'

import EditPlotSeriesData from './EditPlotSeriesData'
import EditPlotSeriesStyle from './EditPlotSeriesStyle'
import EditPlotSeriesOrder from './EditPlotSeriesOrder'
import FilterRules from '../sharedComponents/FilterRules'
import EditTextWithLinks from '../sharedComponents/EditTextWithLinks'
import HorzTaperedDivider from '../sharedComponents/HorzTaperedDivider'
import EditorMenuButton from '../sharedComponents/EditorMenuButton'
import EditColMessageDisplay from '../sharedComponents/EditColMessageDisplay'

const subIndent = '\u00A0\u00A0\u00A0\u00A0'

const displayButtonText = "More Series Actions:\u00A0\u00A0 clone, delete, reorder, ..."
const seriesActionsMenuItems = {
  cloneSeries: {
    displayedName: displayButtonText,
    menuText: ["Insert a clone of this series."]
  },
  deleteSeries: {
    displayedName: displayButtonText,
    menuText: ["Delete this series",
      subIndent + 'Deleted series cannot be restored.']
  },
  reorderSeries: {
    displayedName: "More Column Actions\u00A0\u00A0(insert, delete, ... )",
    menuText: ["Reorder series",
      subIndent + 'Tool to modify the series order.']
  },
  exitActionMenu: {
    displayedName: displayButtonText,
    menuText: ["Exit this menu"]
  },
}


type OwnProps = {
  seriesKey: number,
  plotXyComputedData: PlotXyComputedData,
  tablelook: Tablelook,
  table: Table,
  tableComputedData: TableComputedData,
}

type LocalState = {
  isReorderSeriesActive: boolean,
  textWithLinksErrMsg: string,
  isHelpDescriptionActive: boolean,
}

export default class EditPlotSeries extends PureComponent<OwnProps, LocalState> {

  constructor(props: OwnProps) {
    super(props)
    this.state = {
      isReorderSeriesActive: false,
      textWithLinksErrMsg: '',
      isHelpDescriptionActive: false,
    }
  }

  toggleHelpDescriptionActive = () => {
    //console.log( 'toggle help description to:', !this.state.isHelpDescriptionActive )
    this.setState({ isHelpDescriptionActive: !this.state.isHelpDescriptionActive })
  }
  setHelpDescriptionActiveFalse = () => {
    this.setState({ isHelpDescriptionActive: false })
  }

  exitPlotSeriesOrder = () => {
    // ONLY set to false when window is already opened!!
    // Otherwise, the click to open the window (controlled
    // by the custom selection component) with also
    // call this click handle, which will immediately
    // reset the open state back to false.
    if (this.state.isReorderSeriesActive) {
      this.setState({ isReorderSeriesActive: false })
    }
  }

  handleActionMenuSelection = (value: string) => {
    //console.log( 'handleActionMenuSelection', value )
    this.setState({ isReorderSeriesActive: false })
    switch (value) {
      case 'deleteSeries':
        this.deleteSeries(this.props.seriesKey)
        break
      case 'cloneSeries':
        this.cloneSeries(this.props.seriesKey)
        break
      case 'reorderSeries':
        this.setState({ isReorderSeriesActive: true })
        break
      case 'exitMenu':
      default:
    }
  }


  deleteSeries = (deletedSeriesKey: number) => {
    // Modified May, 2022 to insure the plot series Array is dense (not sparse)
    // Simplifies the huge number of plot series loops.  OK because we've
    // decided to permanently delete (hence never restore) a deleted plot series.
    // Hence deleting a series also requires decrementing the series index of
    // all larger seriesKeys.
    //    - in seriesOrder array
    //    - in the plot's series array
    //    - in all renderedLayerID's
    const { seriesOrder, series } = this.props.plotXyComputedData.plotResourceObj.attributes
    var newSeries = []
    for (let i = 0; i < series.length; i++) {
      if (i === deletedSeriesKey) { continue }
      newSeries.push(series[i])
    }
    // new seriesOrder must still inludes all indices from 0 to n-1 (continuous sequence).
    const newSeriesOrder: number[] = []
    seriesOrder.forEach(oldSeriesIndex => {
      if (oldSeriesIndex < deletedSeriesKey) { newSeriesOrder.push(oldSeriesIndex) }
      else if (oldSeriesIndex > deletedSeriesKey) { newSeriesOrder.push(oldSeriesIndex - 1) }
      // else priorSeriesIndex === deletedSeriesKey, hence NOT pushed
    })
    // All renderedLayerID's need to remove any references to deleted series.
    // And any references to a sKey larger than the deleted reference must be shifted 'left'
    // (subtract one) from the current seriesKey.
    const { renderedLayersArr } = this.props.plotXyComputedData
    const newRenderedLayersArr: string[] = []
    renderedLayersArr.forEach(renderedLayerID => {
      var { plottedValue, visTypeUser, sKeyArr } = parseRenderedLayerID(renderedLayerID)
      var newSeriesKeyArr: number[] = []
      sKeyArr.forEach((renderedKey, i) => {
        if (renderedKey < deletedSeriesKey) { newSeriesKeyArr.push(renderedKey) }
        if (renderedKey > deletedSeriesKey) { newSeriesKeyArr.push(renderedKey - 1) }
        // else renderedKey === deletedSeriesKey -- not pushed to new arr
      })
      if (newSeriesKeyArr.length === 0) {
        // Then the deleted key was the only key for this particular renderedLayerID.
        // The entire renderedLayerID is deleted from renderedLayersArr.
        // (By not pushing it to the newly created array)
        return
      }
      var sKeyArrAsString = `[${newSeriesKeyArr[0]}`
      for (let i = 1; i < newSeriesKeyArr.length; i++) { sKeyArrAsString += `,${newSeriesKeyArr[i]}` }
      sKeyArrAsString += ']'
      var newRenderedLayerID = `${plottedValue}:${visTypeUser}:${sKeyArrAsString}`
      newRenderedLayersArr.push(newRenderedLayerID)
    })
    const mods = [{ newVal: newSeriesOrder, path: `attributes.seriesOrder` },
    { newVal: newSeries, path: `attributes.series` },
    { newVal: newRenderedLayersArr, path: `attributes.renderedLayersArr` },
    // Also, close the popup, as the seriesKey has changed and in
    // case of last seriesKey, doesn't even exist.
    { newVal: { name: '', seriesKey: -1 }, path: 'attributes.minorState.selection' },
    { newVal: false, path: 'attributes.minorState.isEditorOpen' },]
    reactDispatch(mods, 'Delete plot series')
  }


  cloneSeries = (toCloneSeriesKey: number) => {
    const { plotXyComputedData } = this.props
    const plot = plotXyComputedData.plotResourceObj
    const { seriesAttributesArray, seriesOrder } = plotXyComputedData
    var nextSeriesKey = seriesAttributesArray.length
    const currentlyUsedColorValues: string[] = []
    seriesAttributesArray.forEach(thisSeries => {
      currentlyUsedColorValues.push(thisSeries.color)
    })
    var newSeries = structuredClone(plot.attributes.series[toCloneSeriesKey])
    // DON't copy the series description.  Force it to be written or copied new! Same with series Title
    newSeries.seriesDescription = { text: '', links: [] }
    newSeries.seriesTitle = 'newClone'
    // For every rendered layer string containing the toCloneSeriesKey to be cloned,
    // we append the newly created series key to the end of the sKey array. 
    const { renderedLayersArr } = plot.attributes
    const newRenderedLayersArr: string[] = []
    for (const renderedLayerID of renderedLayersArr) {
      var { plottedValue, visTypeUser, sKeyArr, sKeyArrAsString } = parseRenderedLayerID(renderedLayerID)
      if (sKeyArr.indexOf(toCloneSeriesKey) === -1) {
        // This renderedLayerID contains no data concerning the cloned series
        // This layer is simply passed (unaltered) to the new renderedLayersArr
        newRenderedLayersArr.push(renderedLayerID)
        return
      }
      // This renderedLayerID includes the seriesKey to be cloned.
      // Hence, this renderedLayerID should also include our newly cloned series.
      // Append the nextSeriesKey to this renderedLayerID
      var extendedKeyArrAsString = sKeyArrAsString.slice(0, -1) + ',' + String(nextSeriesKey) + ']'
      newRenderedLayersArr.push(`${plottedValue}:${visTypeUser}:${extendedKeyArrAsString}`)
    }
    // choose the next unused seriesColor
    newSeries.color = distinctColors[0].value  // default in case next loop cannot find a new unique color.
    let numColors = distinctColors.length
    for (let i = 0; i < numColors; i++) {
      if (currentlyUsedColorValues.indexOf(distinctColors[i].value) > -1) { continue }
      newSeries.color = distinctColors[i].value
      break
    }
    const newSeriesArray = plot.attributes.series.slice()
    newSeriesArray.push(newSeries)
    const newSeriesOrder = seriesOrder.slice()
    newSeriesOrder.push(nextSeriesKey)
    const mods = [
      { newVal: newSeriesArray, path: `attributes.series` },
      { newVal: newSeriesOrder, path: `attributes.seriesOrder` },
      { newVal: newRenderedLayersArr, path: `attributes.renderedLayersArr` },
      { newVal: { name: 'legend', seriesKey: nextSeriesKey }, path: 'attributes.minorState.selection' },
    ]
    const dispatchMsg = 'Add newly cloned Series'
    reactDispatch(mods, dispatchMsg)
  }


  passTextWithLinksErrMsgToParent = (errMsg: string): void => {
    this.setState({ textWithLinksErrMsg: errMsg })
  }



  render() {
    const { seriesKey, tablelook, table, plotXyComputedData, tableComputedData } = this.props
    const { canEdit } = plotXyComputedData
    const tablelookid = tablelook.id
    const tableid = table.id
    const HORZ_MARGIN = 10

    const { textWithLinksErrMsg, isHelpDescriptionActive } = this.state
    const shouldRenderDescriptionHelp = (canEdit && isHelpDescriptionActive)
    const shouldRenderTextWithLinks = !isHelpDescriptionActive
    const shouldRenderMoreSeriesActions = canEdit && !isHelpDescriptionActive

    var highestPriorityErrMsg = ''
    // Set the error message in order of highest priority first
    // At this time, the 'SeriesData, Name, and Filtering all display
    // thier own error messages adequately.  Therefore, there are no
    // error messages from any series editor input 'above' the TextWithLinks
    // editor.
    // Therefore, only the TextWithLinks has potential errors that we
    // need to display.
    if (textWithLinksErrMsg) {
      highestPriorityErrMsg = textWithLinksErrMsg
    }

    return (

      <div className={'rc_EditPlotSeries'}
        style={{
          position: 'relative', boxSizing: 'border-box',
          display: 'flex', flexFlow: 'column nowrap',
        }}>

        <div className={'vertSpacer'} style={{ height: HORZ_MARGIN }} />

        <div style={{ marginLeft: HORZ_MARGIN, marginRight: HORZ_MARGIN }}>
          <EditPlotSeriesData
            seriesKey={seriesKey}
            plotXyComputedData={plotXyComputedData}
            tablelookid={tablelookid}
            tableid={tableid} />
        </div>

        <div className={'vertSpacer'} style={{ height: 6 }} />
        <HorzTaperedDivider background={constants.FLOATING_PALETTE_BACKGROUND} />
        <div className={'vertSpacer'} style={{ height: 6 }} />

        <div style={{ marginLeft: HORZ_MARGIN, marginRight: HORZ_MARGIN }}>
          <EditPlotSeriesStyle
            seriesKey={seriesKey}
            plotXyComputedData={plotXyComputedData}
            tablelook={tablelook}
            table={table} />
        </div>

        <div className={'vertSpacer'} style={{ height: 8 }} />
        <HorzTaperedDivider background={constants.FLOATING_PALETTE_BACKGROUND} />
        <div className={'vertSpacer'} style={{ height: 4 }} />

        <div style={{ marginLeft: HORZ_MARGIN, marginRight: HORZ_MARGIN }}>
          <FilterRules
            tablelook={tablelook}
            tableComputedData={tableComputedData}
            plotXyComputedData={plotXyComputedData}
            seriesKey={seriesKey}
            multiLineMode={'1line'}
            resourceName={'commonFilters'} />
        </div>

        <div className={'vertSpacer'} style={{ height: 4 }} />

        <div style={{ marginLeft: HORZ_MARGIN, marginRight: HORZ_MARGIN }}>
          <FilterRules
            tablelook={tablelook}
            tableComputedData={tableComputedData}
            plotXyComputedData={plotXyComputedData}
            seriesKey={seriesKey}
            multiLineMode={'1line'}
            resourceName={'seriesFilters'} />
        </div>

        <div className={'vertSpacer'} style={{ height: 8 }} />
        <HorzTaperedDivider background={constants.FLOATING_PALETTE_BACKGROUND} />

        {/* Next large block is either the help for the Description, or the
  TextWithLinks component to enter the discription. */}

        {shouldRenderDescriptionHelp &&
          <Fragment>
            <div className={'vertSpacer'} style={{ height: 6 }} />
            <div className={'HelpDescriptionContainer'}
              style={{
                width: 400,
                position: 'relative', top: 0, left: HORZ_MARGIN, // relative coord system
                background: 'pink'
              }}
            >
              <HelpPanelDescription
                className={'descriptionHelpPanel'}
                onDismissClick={this.setHelpDescriptionActiveFalse}
              />
              <button className={'helpWithDescriptionButton'}
                onClick={this.toggleHelpDescriptionActive}
                style={{
                  position: 'absolute', top: 0,
                  height: 16, width: 16,
                  fontSize: 13, fontWeight: 'bold',
                  paddingTop: 0, paddingLeft: 3,
                  ...constants.STYLE_EDITOR_RAISED_BUTTON,
                  background: (isHelpDescriptionActive) ? constants.HIGHLIGHT_COLOR_HALF : 'rgb(230,230,230)',
                }}>
                {'?'}
              </button>
            </div>
          </Fragment>
        }

        {shouldRenderTextWithLinks &&
          <Fragment>
            <div className={'editTextWithLinksParent'}
              style={{
                position: 'relative', left: 0, top: 0, // relative coord system
                marginLeft: HORZ_MARGIN,
                zIndex: 10,
              }}
            >

              <EditTextWithLinks
                heightLabel={canEdit ? 22 : 20}
                heightMaxViewMode={60}
                heightEditMode={100}
                // horzMargin={HORZ_MARGIN}
                width={260 + 50 + 86}
                labelStyle={{
                  marginLeft: canEdit ? 20 : 0,
                  fontSize: 14,
                  width: 80
                }}
                tableid={tableid}
                plotid={plotXyComputedData.plotid}
                colOrSeriesKey={seriesKey}
                canEdit={canEdit}
                parentMode={'plotSeriesDescription'}
                textWithLinks={plotXyComputedData.seriesAttributesArray[seriesKey].seriesDescription}
                passErrMsgToParent={this.passTextWithLinksErrMsgToParent} />


              {/* CONCERNING LABEL AND ERRMSG HEIGHTS:
                        Heights assume we ALWAYS have single-line error message
                        To reset heights to support potential double-line error
                        messages, we need to modify heights for:
                        Top of button placement, height of EditColMessage, and
                        EditTextWithLinks labelHeight.  See the sizes I used
                        in the EditColHeader component. */}
              {canEdit &&
                <Fragment>
                  <button className={'helpWithDescriptionButton'}
                    onClick={this.toggleHelpDescriptionActive}
                    style={{
                      position: 'absolute', top: 0,
                      height: 18, width: 16,
                      marginTop: 0,
                      fontSize: 13, fontWeight: 'bold',
                      paddingTop: 0, paddingLeft: 3,
                      ...constants.STYLE_EDITOR_RAISED_BUTTON,
                      background: (isHelpDescriptionActive) ? constants.HIGHLIGHT_COLOR_HALF : 'rgb(230,230,230)',
                    }}> {'?'} </button>

                  <div className={'placementContainer'}
                    style={{ position: 'absolute', top: 0, left: 112 }}>
                    <EditColMessageDisplay
                      width={280}
                      height={22}
                      errMessage={highestPriorityErrMsg} />
                  </div>
                </Fragment>
              }

            </div>
          </Fragment>
        }

        {shouldRenderMoreSeriesActions &&
          <Fragment>
            <div className={'vertSpacer'} style={{ height: canEdit ? 6 : 2 }} />
            <HorzTaperedDivider background={constants.FLOATING_PALETTE_BACKGROUND} />
            <div className={'vertSpacer'} style={{ height: 6 }} />

            <div className={'moreActionsContainer'}
              style={{
                position: 'relative', top: 0, left: 0, // create local coord system for popup menu placement.
                width: 300 + 20, height: 21,
                marginLeft: 58, marginTop: 0, marginBottom: 8,
              }}
            >
              <EditPlotSeriesOrder
                isSelected={this.state.isReorderSeriesActive}
                width={250}
                right={-50}
                bottom={-4}
                plotXyComputedData={plotXyComputedData}
                onExit={this.exitPlotSeriesOrder} />
              <EditorMenuButton
                menuItems={seriesActionsMenuItems}
                onSelection={this.handleActionMenuSelection}
                onClick={this.exitPlotSeriesOrder}
                selectedValue={'exitActionMenu'}
                height={21}
                widthDisplayText={300}
                widthDisplayCaret={0}
                textAlign={'center'}
                menuWidth={250}
                menuRight={-30}
                menuBottom={-20}
                shouldStyleAsButton={true} />
            </div>
          </Fragment>
        }

      </div>

    )
  }
}
