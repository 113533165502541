import { Middleware } from 'redux'
import { updateSession } from './sessionReducer'
import { ensureTableResourcesThunk, PLOTS_SORT, plotsForTableListId } from './ensureTableResourcesThunk'
import { asyncDispatch } from '../sharedFunctions/utils'
import ensure from './ensure'

/*
getList = (ensurePlotsList: EnsurePlotsFunction, tableid: string, id: string, PLOTS_SORT: string, offset: number) => {
  if (tableid && id) {
    ensurePlotsList(tableid, id, PLOTS_SORT, offset)
  }
};
const listId = plotsForTableListId(tableid, tableOwnerid, plotsSort)
asyncDispatch(dispatch, ensure.list('plots', listId, offset, 1000, force))
this.getList(ensurePlotsList, tableid, userid, PLOTS_SORT, 0);
this.getList(ensurePlotsList, tableid, tableOwnerid, PLOTS_SORT, 0);
*/

const sessionMiddleware: Middleware = (storeAPI) => (next) => (action) => {
  if (updateSession.match(action)) {
    // The middleware runs before the reducer, so we check certain actions here
    // and update the derived state before the reducer runs if we wish.
    const currentTableid = storeAPI.getState().session.currentTableid
    if ( 'currentTableid' in action.payload ) {
      const newTableid = action.payload.currentTableid ?? ''
      if (currentTableid !== newTableid) {
        // dispatch the tableid to ensureTableResourcesThunk.  use asyncDispatch.
        asyncDispatch(storeAPI.dispatch, ensureTableResourcesThunk(newTableid))
      
        const userid = storeAPI.getState().app.userid
        const tableowner = storeAPI.getState().api.resources?.tables[newTableid]?.attributes?.ownerid
        if (userid && tableowner) {
          let listId = plotsForTableListId(newTableid, userid, PLOTS_SORT)
          asyncDispatch(storeAPI.dispatch, ensure.list('plots', listId, 0, 1000, true))
          listId = plotsForTableListId(newTableid, tableowner, PLOTS_SORT)
          asyncDispatch(storeAPI.dispatch, ensure.list('plots', listId, 0, 1000, true))
        }
      }
    }

  }
  return next(action)
}

export default sessionMiddleware
