import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SessionState, getDefaultSessionState } from '../appCode/getDefaultSessionState'
import { LightweightMod } from '../types'

type SparseSessionState = Partial<SessionState>
//export type SessionState = SessionState //used by store.ts
const DEBUG = false

const sessionSlice = createSlice({
  name: 'session',
  initialState: getDefaultSessionState(),
  reducers: {
    updateSession(state, action: PayloadAction< SparseSessionState | LightweightMod[]>) {
      // The incoming data is one or more changes to the AppState.
      // A key is a hierarchical path to the state that needs to be updated.
      // payload will be an array of LightweightMod objects
      // or it could be a single object with a sparse structure as the state
      if (Array.isArray(action.payload)) {
        console.error('updateSession: LightweightMod[] not implemented - use reactDispatch')
      } else if (typeof action.payload === 'object') {
        if (DEBUG) {
          //determine if action.payload is actually applying a change:
          const keys = Object.keys(action.payload)
          let changeCount: { [key: string]: { old: any, new: any } } = {}
          keys.forEach(key => {
            if (state[key] !== action.payload[key]) {
              changeCount[key] = { old: state[key], new: action.payload[key] }
            }
          })
          if (Object.keys(changeCount).length === 0) {
            console.warn('updateSession: no changes detected')
          } else {
            console.table(changeCount)
          }
        }
        return { ...state, ...action.payload }
      }
    }
  }
})

export const { updateSession } =
  sessionSlice.actions

export default sessionSlice.reducer
