import type { ChangeEvent } from 'react'

import {Component} from 'react'
import reactDispatch      from '../sharedComponents/reactDispatch'
import {cleanScryInputText2} from '../sharedFunctions/cleanScryInputText'

type Props = {
  tabledataid: string,
  value: string,
  rowKey:number,
  colKey:number,
  canEdit: boolean,
}
type LocalState = {
  currentInput: string,
  colKey: number,
  rowKey: number,
}



export default class EditCellString extends Component<Props, LocalState> {

  constructor(props: Props) {
    super(props)
    this.state={ currentInput: '', colKey:-1, rowKey:-1 }
  }

  static getDerivedStateFromProps( nextProps:Props,   prevState:LocalState ) {
    const { value, colKey, rowKey } = nextProps
    //console.log( 'Get String Editor derived State', nextProps )
    const isNewCell = ( prevState.colKey !== colKey || prevState.rowKey  !== rowKey )
    const currentInput = isNewCell ? value : prevState.currentInput
    return {currentInput, rowKey, colKey }
  }

  componentWillUnmount = () => {
    if ( this.timeoutID !== null ) {
      clearTimeout(this.timeoutID)
      this.updateReactState( )
    }
  }

  TIMEOUT_DELAY = 1
  timeoutID: NodeJS.Timeout | string | number | undefined = undefined

  updateLocalState = ( e: ChangeEvent<HTMLTextAreaElement> ): void => {
    var {newValue, newSelectionStop} = cleanScryInputText2( e.target.value, e.target.selectionEnd ?? 0 )
    if ( e.target.value.length !== newValue.length ) {
      e.target.value = newValue.slice()  // This line also resets selection start to useless value.
      e.target.selectionStart = newSelectionStop
      e.target.selectionEnd   = newSelectionStop
    }
    this.setState( {currentInput: newValue} )
    clearTimeout(this.timeoutID)
    this.timeoutID = setTimeout( this.updateReactState, this.TIMEOUT_DELAY)
  }

  updateReactState = ():void => {
    const mods = [{
      newVal: this.state.currentInput,
      path: `attributes.tableValues[${this.props.colKey}][${this.props.rowKey}]`
    }]
    reactDispatch(mods, 'cell value change', '', 'tabledatas', this.props.tabledataid)
  }

  render() {
    //console.log( 'rendering EditCellString' )
    return (
        <textarea className={'rc_EditCellString'}
          onChange={this.updateLocalState}
          style={{
            resize:'none',
            width: '100%',
            height:'100%',
            fontFamily: 'serif',
            fontSize: '16px',
            paddingLeft: 8, paddingRight: 8,
            paddingTop: 4, paddingBottom: 10,
            borderWidth: 0,
          }}
          value={this.state.currentInput}
          autoComplete='off'
          spellCheck='false'
          readOnly={!this.props.canEdit}
        />
    )
  }
}
