import { Component } from 'react'
import constants           from '../sharedComponents/constants'

// To use this component, you will need to replace some other editor with this component.
// For example, have the 'titleMain' selection point to this component.


var myTestText = 'ill tll   Hll xxll   vll Mll tll      .      ylll ;l hll . gll ' +
                 'wll  glll   ill 7ll   .  oll ll jl oll  Lll .  il . ll -ll  bll qll  Wll hll '
myTestText += myTestText
myTestText += myTestText
myTestText += myTestText
myTestText += myTestText

type OwnProps = { }
export default class TestTextAreaOverlay extends Component<OwnProps> {
  render() {

    return (
        <div className={'rc_Test_TextAreaOverDiv_Overlay'}
          style={{
            position:'relative',
            width: '100%', height: '100%',
            overflow: 'auto',
            background: 'white',
          }}
        >
          <div className={'InternalScrolledDiv'}
            style = {{
              width:  '100%',
              height: '100%',
              fontSize: constants.DESCRIPTION_EDITOR_FONT_SIZE,
            }}>

                  <div className={'Underlying_VisibleText'}
                    style={{
                      position:'absolute', top:0, left:0,
                      width: '100%',
                      ...constants.TEXTAREA_OVER_DIV_StyleDiv,
                      color: 'blue', opacity: 1,
                    }}>
                      {myTestText}
                  </div>

                  <textarea className={'Overlay_InvisibleTextArea'}
                    style={{
                      position:'absolute', top:0, left:0,
                      width: '100%', height: '300%',
                      ...constants.TEXTAREA_OVER_DIV_StyleTextArea,
                      //borderRightWidth: 0,
                      color: 'red', opacity: '0.5',
                    }}

                    tabIndex={-1}
                    rows={20}
                    defaultValue={myTestText}
                    autoComplete='off'
                    spellCheck='false'
                  />

          </div>
        </div>
    )
  }
}
