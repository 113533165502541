import { memo, useContext } from 'react'
import {TableComputedDataContext}  from '../viewTableTriple/TtGetComputedData'
import type {DomNode, TableComputedData} from '../computedDataTable/getDefaultTableComputedData' 
import {getStatsBarPlacementAndVisibleWidth} from './tableResponsiveState'            

type StatsBarProps= { tableComputedData : TableComputedData }

const StatsBar: React.FC<StatsBarProps> = memo(function StatsBar(props: StatsBarProps) {

  const tableComputedData_fromContext = useContext( TableComputedDataContext )
  const { widthObj:w, heightObj:h, styleObj:s, statsBarLayout, tableDomNodes, table, stats_LegalActiveStatsColKey} = tableComputedData_fromContext
  if ( stats_LegalActiveStatsColKey === -1 ) { return null }

  const setStatsBarNode = (element: DomNode): void => { tableDomNodes.statsBar = element }

  const colName = table.attributes.columns[stats_LegalActiveStatsColKey].colTitle
  const fontSize = s.cellFontSize
  const statsHeight = h.statsHeight
  const statsBarTop = h.statsBarTop
  const viewWidthPx = w.viewWidthPx
  var {titleWidth, groupArr, groupLeftArr} = statsBarLayout
  const {leftPlacementOfStatsBar, visibleWidthOfStatsBar} = getStatsBarPlacementAndVisibleWidth( viewWidthPx, groupLeftArr )
  const topHeight = Math.floor( statsHeight * 0.5 )
  const bottomHeight = statsHeight - topHeight

  if ( groupArr.length === 0 ) { return null }

  return (
    <div className={'rc_StatsBar'}
      ref={ setStatsBarNode }
      style={{
          position: 'absolute', top: statsBarTop, left: 0,
          transform: `translate(${leftPlacementOfStatsBar}px, 0px)`,
          height: statsHeight, 
          //backgroundColor: 'pink',
          // The entire potential width of statsBar is rendered.
          // Because we do not want to do a re-render during responsive state
          // of sideBar opening or closing.
          // So we control the visible width by just hiding
          // potential stats that don't fit in the allocated space. 
          width: visibleWidthOfStatsBar,
          overflow: 'hidden' }}
  >

    <div className={'ColumnName'}
        style={{
          height: '100%', width: titleWidth, //background:'olive',
          lineHeight: '100%',
          position: 'relative', left: 0,
          fontSize: fontSize, fontWeight: 'bold',
          paddingLeft: '0px',
          paddingTop: (statsHeight - fontSize)/2 - 4,  // centering vertically
        }}
      > {colName} </div>

      <div>
        { groupArr.map( (thisGroup,i) => {
            var {label1, label2, value1, value2, labelWidth, valueWidth} = thisGroup
            return (
              <div className={'StatsItemContainer'}
                key={i}
                style={{
                  position: 'absolute', 
                  bottom:0, 
                  left: groupLeftArr[i],
                  height: statsHeight, 
                  width : thisGroup.groupWidth,
                  fontSize,
                }}
              >
                <div className={'TopLine_Label'}
                    style={{
                      position: 'absolute', top:0, left:0,
                      height:topHeight, width: labelWidth,
                      textAlign: 'right', 
                      paddingTop:2, paddingLeft:3, paddingRight:3,
                      //backgroundColor: 'yellow'
                    }}>{label1}</div>
                <div className={'BottomLine_Label'}
                    style={{
                      position: 'absolute',  top:topHeight, left:0,
                      height: bottomHeight,  width: labelWidth,
                      textAlign: 'right',
                      paddingTop:2, paddingLeft:3, paddingRight:3,
                      //backgroundColor: 'green'
                    }}>{label2}</div>
                <div className={'TopLine_Value'}
                    style={{
                      position: 'absolute', left:labelWidth, top: 0, 
                      height:topHeight,  width: valueWidth,
                      textAlign: 'left', 
                      paddingBottom:1, paddingLeft:3, paddingRight:3,
                      display: 'flex', alignItems: 'flex-end',  // aligns exponetial formatted number correctly
                                                                // Same approach I use for cell strings.
                      //backgroundColor: 'pink'
                    }}>{value1}</div>
                <div className={'BottomLine_Value'}
                    style={{
                      position: 'absolute', left:labelWidth, top: topHeight, 
                      height: bottomHeight, width: valueWidth,
                      textAlign: 'left',
                      paddingBottom:1, paddingLeft:3, paddingRight:3,
                      display: 'flex', alignItems: 'flex-end',  
                      //backgroundColor: 'red'
                    }}>{value2}</div>
              </div>
            )
          })}
        </div>
    </div>
  )
})


// type OwnProps = {
//   tableDomNodes: TableDomNodes, 
//   statsBarLayout: StatsBarLayout,
//   statsBarTop: number,
//   statsHeight: number, 
//   viewWidthPx: number,
//   fontSize: number, 
//   colName: string,
// }

// class StatsBarLeaf extends PureComponent<OwnProps> {

//   setStatsBarNode = (element: DomNode): void => {
//       this.props.tableDomNodes.statsBar = element
//   }

//   render() {
//     //console.log( 'Call to renderStats' )
//     var {statsHeight, statsBarTop, fontSize, statsBarLayout, viewWidthPx, colName} = this.props
//     var {titleWidth, groupArr, groupLeftArr} = statsBarLayout
//     // empty statsLayoutObj means:
//     //  1) there is no activeStatsColKey
//     //  2) or a column has been selected, but stats are not yet available for rendering.
//     if ( groupArr.length === 0 ) { return null }
//     const {leftPlacementOfStatsBar, visibleWidthOfStatsBar} = getStatsBarPlacementAndVisibleWidth( viewWidthPx, groupLeftArr )
//     const topHeight = Math.floor( statsHeight * 0.5 )
//     const bottomHeight = statsHeight - topHeight
//     return (
//         <div className={'rc_StatsBar'}
//             ref={ this.setStatsBarNode }
//             style={{
//                 position: 'absolute', top: statsBarTop, left: 0,
//                 transform: `translate(${leftPlacementOfStatsBar}px, 0px)`,
//                 height: statsHeight, 
//                 //backgroundColor: 'pink',
//                 // The entire potential width of statsBar is rendered.
//                 // Because we do not want to do a re-render during responsive state
//                 // of sideBar opening or closing.
//                 // So we control the visible width by just hiding
//                 // potential stats that don't fit in the allocated space. 
//                 width: visibleWidthOfStatsBar,
//                 overflow: 'hidden' }}
//         >

//           <div className={'ColumnName'}
//               style={{
//                 height: '100%', width: titleWidth, //background:'olive',
//                 lineHeight: '100%',
//                 position: 'relative', left: 0,
//                 fontSize: fontSize, fontWeight: 'bold',
//                 paddingLeft: '0px',
//                 paddingTop: (statsHeight - fontSize)/2 - 4,  // centering vertically
//               }}
//             > {colName} </div>

//             <div>
//               { groupArr.map( (thisGroup,i) => {
//                   var {label1, label2, value1, value2, labelWidth, valueWidth} = thisGroup
//                   return (
//                     <div className={'StatsItemContainer'}
//                       key={i}
//                       style={{
//                         position: 'absolute', 
//                         bottom:0, 
//                         left: groupLeftArr[i],
//                         height: statsHeight, 
//                         width : thisGroup.groupWidth,
//                         fontSize,
//                       }}
//                     >
//                       <div className={'TopLine_Label'}
//                           style={{
//                             position: 'absolute', top:0, left:0,
//                             height:topHeight, width: labelWidth,
//                             textAlign: 'right', 
//                             paddingTop:2, paddingLeft:3, paddingRight:3,
//                             //backgroundColor: 'yellow'
//                           }}>{label1}</div>
//                       <div className={'BottomLine_Label'}
//                           style={{
//                             position: 'absolute',  top:topHeight, left:0,
//                             height: bottomHeight,  width: labelWidth,
//                             textAlign: 'right',
//                             paddingTop:2, paddingLeft:3, paddingRight:3,
//                             //backgroundColor: 'green'
//                           }}>{label2}</div>
//                       <div className={'TopLine_Value'}
//                           style={{
//                             position: 'absolute', left:labelWidth, top: 0, 
//                             height:topHeight,  width: valueWidth,
//                             textAlign: 'left', 
//                             paddingBottom:1, paddingLeft:3, paddingRight:3,
//                             display: 'flex', alignItems: 'flex-end',  // aligns exponetial formatted number correctly
//                                                                       // Same approach I use for cell strings.
//                             //backgroundColor: 'pink'
//                           }}>{value1}</div>
//                       <div className={'BottomLine_Value'}
//                           style={{
//                             position: 'absolute', left:labelWidth, top: topHeight, 
//                             height: bottomHeight, width: valueWidth,
//                             textAlign: 'left',
//                             paddingBottom:1, paddingLeft:3, paddingRight:3,
//                             display: 'flex', alignItems: 'flex-end',  
//                             //backgroundColor: 'red'
//                           }}>{value2}</div>
//                     </div>
//                   )
//                 })}
//               </div>
//           </div>)
//         }
// }

export default StatsBar
