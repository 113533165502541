import { AnyAction, Dispatch } from '@reduxjs/toolkit'
import axios from 'axios'
import type {
  AxiosHeaders,
  AxiosRequestConfig,
  AxiosResponse,
  Method,
  RawAxiosRequestHeaders,
} from 'axios'
import { get, set } from 'radash'
import { deepClone, typedKeys } from '../sharedFunctions/utils'
import type { GetStateFunc } from '../types'
import { apiActions, pendingRequests } from './apiReducer'
import { defaultResourceTypeIdList } from './types'
import type { AxiosError, EndpointError, JsonApiDocument, ResponseError, Resource, ResourceError, ResourceId, ResourceType, ResourceTypeIdList, SerializableAxiosConfig } from './types'

const jsonContentTypes = [
  'application/json',
  'application/vnd.api+json'
]

// following type copied from Axios since it was not exported
type MethodsHeaders = Partial<{
  [Key in Method as Lowercase<Key>]: AxiosHeaders
} & { common: AxiosHeaders }>

const headersToSerializableHeaders = (headers: (RawAxiosRequestHeaders & MethodsHeaders) | AxiosHeaders | undefined): RawAxiosRequestHeaders | undefined => {
  const serializableHeaders: RawAxiosRequestHeaders = {}

  if (!headers) {
    return undefined
  }

  for (const key in typedKeys(headers)) {
    const value = headers[key]
    if (value !== null && (
      typeof value === 'string' ||
      typeof value === 'boolean' ||
      typeof value === 'number' ||
      (typeof value === 'object' && Array.isArray(value))
    )) {
      serializableHeaders[key] = value
    }
  }
  return serializableHeaders
}

const configToSerializableConfig = (config: AxiosRequestConfig<JsonApiDocument>): SerializableAxiosConfig => {
  return {
    url: config.url,
    method: config.method,
    baseURL: config.baseURL,
    // transformRequest?: AxiosRequestTransformer | AxiosRequestTransformer[];
    // transformResponse?: AxiosResponseTransformer | AxiosResponseTransformer[];
    headers: headersToSerializableHeaders(config.headers),
    // params?: any;
    // paramsSerializer?: ParamsSerializerOptions | CustomParamsSerializer;
    // data?: D;
    timeout: config.timeout,
    timeoutErrorMessage: config.timeoutErrorMessage,
    withCredentials: config.withCredentials,
    // adapter?: AxiosAdapterConfig | AxiosAdapterConfig[];
    auth: config.auth,
    responseType: config.responseType,
    responseEncoding: config.responseEncoding,
    xsrfCookieName: config.xsrfCookieName,
    xsrfHeaderName: config.xsrfHeaderName,
    // onUploadProgress?: (progressEvent: AxiosProgressEvent) => void;
    // onDownloadProgress?: (progressEvent: AxiosProgressEvent) => void;
    maxContentLength: config.maxContentLength,
    // validateStatus?: ((status: number) => boolean) | null;
    maxBodyLength: config.maxBodyLength,
    maxRedirects: config.maxRedirects,
    maxRate: config.maxRate,
    // beforeRedirect?: (options: Record<string, any>, responseDetails: { headers: Record<string, string> }) => void;
    socketPath: config.socketPath,
    // transport?: any;
    // httpAgent?: any;
    // httpsAgent?: any;
    proxy: config.proxy,
    // cancelToken?: CancelToken;
    decompress: config.decompress,
    transitional: config.transitional,
    // signal?: GenericAbortSignal;
    insecureHTTPParser: config.insecureHTTPParser,
    // env?: {
    //   FormData?: new (...args: any[]) => object;
    // };
    // formSerializer?: FormSerializerOptions;
    family: config.family,
    // lookup?: ((hostname: string, options: object, cb: (err: Error | null, address: LookupAddress | LookupAddress[], family?: AddressFamily) => void) => void) |
    //     ((hostname: string, options: object) => Promise<[address: LookupAddressEntry | LookupAddressEntry[], family?: AddressFamily] | LookupAddress>);
  }
}

const toSerializable = (request: any) => {
  // TODO: consider implementing something like how configToSerializableConfig was implemented
  return JSON.parse(JSON.stringify(request))
}

const errToAxiosError = (err: unknown): AxiosError => {
  // return a serializable object for Redux,
  // pulling just the known serializable fields from the error
  const error = err as AxiosError
  let serializableConfig: SerializableAxiosConfig | undefined = undefined
  if (error.config) {
    serializableConfig = configToSerializableConfig(error.config)
  }

  let serializableRequest
  if (error.request) {
    serializableRequest = toSerializable(error.request)
  }

  let serializableResponse
  if (error.response) {
    serializableResponse = toSerializable(error.response)
  }

  return {
    message: error.message,
    code: error.code,
    config: serializableConfig,
    request: serializableRequest,
    response: serializableResponse
  }
}

const hasValidContentType = (response: AxiosResponse<JsonApiDocument>): boolean => {
  const responseContentType = get<string[]>(response, 'headers.content-type', [])
  const someTest = (contentType: string): boolean => (responseContentType.indexOf(contentType) > -1)
  return jsonContentTypes.some(someTest)
}

const emptyJsonApiDocument: JsonApiDocument = {
  data: null,
}

const apiRequest = async (url: string, options: AxiosRequestConfig<JsonApiDocument>): Promise<JsonApiDocument> => {
  let allOptions = deepClone(options)
  allOptions.url = url
  allOptions = set(allOptions, 'headers.Accept', 'application/vnd.api+json')
  allOptions = set(allOptions, 'headers.Content-Type', 'application/vnd.api+json')

  const res = await axios(allOptions)
  if (res.status === 204) {
    return emptyJsonApiDocument
  }

  if (hasValidContentType(res) === false) {
    const error = new Error('Invalid Content-Type in response') as ResponseError
    // error.config = res.config
    error.response = res
    throw error
  }

  return res.data
}

export const createResource = (resource: Resource) => {
  return async (dispatch: Dispatch<AnyAction>, getState: GetStateFunc): Promise<JsonApiDocument> => {
    try {
      dispatch(apiActions.apiWillCreate())
      const { config } = getState().api
      const options: AxiosRequestConfig<JsonApiDocument> = {
        ...config,
        method: 'POST',
        data: {
          data: resource,
        },
      }

      const json = await apiRequest(resource.type, options)
      dispatch(apiActions.apiCreated(json))
      return json
    } catch (err) {
      const error: ResourceError = { resource, ...errToAxiosError(err) }
      dispatch(apiActions.apiCreateFailed(error))
      throw error
    }
  }
}

// readEndpoint is used by ensureResource and ensureList to make GET calls
// to the server if necessary
const readEndpoint = async (endpoint: string, dispatch: Dispatch<AnyAction>, getState: GetStateFunc): Promise<JsonApiDocument> => {
  try {
    const state = getState().api
    const pendingPromise = pendingRequests[endpoint]
    if (pendingPromise) {
      return pendingPromise
    }

    const { config } = state
    const promise = apiRequest(endpoint, config)
    pendingRequests[endpoint] = promise
    dispatch(apiActions.apiWillRead(endpoint))

    const json = await promise
    dispatch(apiActions.apiRead({ endpoint, ...json }))
    return json
  } catch (err) {
    const error: EndpointError = { endpoint, ...errToAxiosError(err) }
    dispatch(apiActions.apiReadFailed(error))
    throw error
  }
}

/**
 * The purpose of this function is to ensure that the specific resource, and
 * optionally the related resources for the relationships listed in the include
 * param are present in the state, or if not, that the resources are loaded with
 * as few extra calls as possible.
 * If the specified resource is not present, the include parameter
 * will be added to the url when requesting the resource.
 * If the specified resource is present, any missing related resources
 * indicated by the include param will be loaded with a filtered search,
 * using one request per resource type.
 */
export const ensureResource = (type: ResourceType, id: string, include?: string, force: boolean = false) => {
  return (dispatch: Dispatch<AnyAction>, getState: GetStateFunc): Promise<JsonApiDocument | JsonApiDocument[]> => {
    const url = include ? `${type}/${id}?include=${include}` : `${type}/${id}`
    if (force) {
      return readEndpoint(url, dispatch, getState)
    } else {
      const promises = []
      const state = getState().api
      const resource = state.resources[type][id]
      if (!resource) {
        const promise = readEndpoint(url, dispatch, getState)
        promises.push(promise)
      } else if (include) {
        const resourceRels = resource?.relationships
        const includeRels = include.split(',')
        const missingResources: ResourceTypeIdList = defaultResourceTypeIdList()
        const maybeAddToMissing = (item: ResourceId): void => {
          if (!state.resources[item.type][item.id]) {
            missingResources[item.type].push(item.id)
          }
        }
        for (const includedRel of includeRels) {
          const includeResourceOrList: Array<ResourceId> | ResourceId | null = resourceRels?.[includedRel].data
          if (includeResourceOrList) {
            if (Array.isArray(includeResourceOrList)) {
              for (const item of includeResourceOrList) {
                maybeAddToMissing(item)
              }
            } else {
              maybeAddToMissing(includeResourceOrList)
            }
          }
        }
        const missingTypes = Object.keys(missingResources) as ResourceType[]
        for (const missingType of missingTypes) {
          const missingIds = missingResources[missingType]
          if (missingIds.length) {
            let url = `${missingType}/?`
            let and = ''
            for (const missingId of missingIds) {
              url += `${and}filter[id]=${missingId}`
              and = '&'
            }
            const promise = readEndpoint(url, dispatch, getState)
            promises.push(promise)
          }
        }
      }
      return Promise.all(promises)
    }
  }
}

/**
 * The purpose of this function is to ensure that the specified list is present
 * or fetch it if necessary. May also be used to ensure additional pages of a
 * paged list.
 * 
 * We track the last 5 list requests, and if the same request is made within 2 minutes,
 * we do not fetch it again.  If a resource / list is deleted its important to call clearEnsureListCache
 * to clear the cache.
 * 
 */

export const ensureList = (type: ResourceType, listId: string, offset = 0, limit = 1000, force = false) => {
  return async (dispatch: Dispatch<AnyAction>, getState: GetStateFunc): Promise<JsonApiDocument> => {
    const url = `${type}?${listId}&page[limit]=${limit}&page[offset]=${offset}`
    if (force) {
      //console.log(`[${new Date().toISOString()}] Forced a new list:\t\t ${type}, \t${listId}`)
      return readEndpoint(url, dispatch, getState)
    }
    const state = getState().api
    const list = state.lists[type][listId]
    if (list && (list.ids.length === list.total || list.ids.length >= offset + limit)) {
      //Data has been pulled.
      return Promise.resolve({
        jsonapi: {
          version: '1.0'
        },
        meta: {
          page: {
            offset,
            limit,
            total: list.total
          }
        },
        links: {
          self: `${state.config.baseURL}${url}`
        },
        data: list.ids.map((id: string) => ({ type, id, attributes: {} })),
        included: []
      })
    } else {
      const promise = readEndpoint(url, dispatch, getState)
      await promise
      //const state = getState().api
      //const list = get(state, `lists.${type}.${listId}`, null)
      //console.log(`[${new Date().toISOString()}] Loaded list:\t\t\t ${type}, \t${listId}`)
      //console.log(' --- length:', list.ids.length)
      return promise
    }
  }
}

export const updateResource = (resource: Resource) => {
  return async (dispatch: Dispatch<AnyAction>, getState: GetStateFunc): Promise<JsonApiDocument> => {
    try {
      dispatch(apiActions.apiWillUpdate(resource))

      const { config } = getState().api
      const options = {
        ...config,
        method: 'PATCH',
        data: {
          data: resource
        }
      }

      const endpoint = `${resource.type}/${resource.id}`
      const json = await apiRequest(endpoint, options)
      dispatch(apiActions.apiUpdated({ endpoint, ...json }))
      return json
    } catch (err) {
      const error: ResourceError = { resource, ...errToAxiosError(err) }
      dispatch(apiActions.apiUpdateFailed(error))
      throw error
    }
  }
}

export const deleteResource = (resource: Resource) => {
  return async (dispatch: Dispatch<AnyAction>, getState: GetStateFunc): Promise<JsonApiDocument> => {
    const endpoint = `${resource.type}/${resource.id}`
    try {
      dispatch(apiActions.apiWillDelete(resource))

      const { config } = getState().api

      const options = {
        ...config,
        method: 'DELETE'
      }

      const json = await apiRequest(endpoint, options)
      dispatch(apiActions.apiDeleted(resource))
      return json
    } catch (err) {
      const error: EndpointError = { endpoint, ...errToAxiosError(err) }
      dispatch(apiActions.apiDeleteFailed(error))
      throw error
    }
  }
}
