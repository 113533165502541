import type { CSSProperties, ReactNode }       from 'react'
import type { ScryFormula, SubStringInfo } from './formulaTypes'
import type { AccessCellStringReturnType, GetTableValue, TableComputedData } from './getDefaultTableComputedData'

import invariant              from 'invariant'
import {Fragment}             from 'react'
import constants                        from '../sharedComponents/constants'
import {RPN_evaluate}                   from './RPN_evaluate'
import {
  FormattingObj,
  getFormattingObj,
  numberFormatMeasureOnly,
  numberFormatReactNode
}                from '../sharedFunctions/numberFormat'
import {measureHTMLwidth, measureText}  from '../sharedFunctions/measureText'

// TODO: JPS Move these types to RPN_evaluate
export type TempValue = string | undefined
export type TempVars = {
  [key: string]: TempValue
}

export type FormulaLineResult = {
  wasExecuted: boolean
  exprResult: string | number
  tmpVars: TempVars
}

const thinSpaceChar = constants.thinSpaceChar  // Half the normal space width  \u2009
const hairSpaceChar = constants.hairSpaceChar
const multiplyChar  = constants.multiplyChar
const MultiplyElement = constants.MultiplyElement
const divideChar    = constants.divideChar  // longer slash
const subtractChar  = constants.subtractChar  // longer dash
const SCRY_VERYLIGHT_BLUE = constants.SCRY_VERYLIGHT_BLUE
//const SCRY_LIGHT_BLUE = constants.SCRY_LIGHT_BLUE
const LIGHTGREY_BACKGROUND =  '#EEEEEE'
const SUP_PERCENT_SHRINK = 80
const EXTENSION_BLUEBOX_BEYOND_TEXT_PER_SIDE = 2
const FONT_SIZE = constants.COL_FORMULA_EDITOR_FONT_SIZE    // nominal

type FormualFormatReturnMode = 'reactFormulaNode' | 'reactCellNode' | 'htmlFormulaString'

type FormulaProps = {
  scryExpression: ScryFormula,
  colKey: number, rowKey: number,
  tableComputedData: TableComputedData,
  isValidFormula: boolean,
  returnMode: FormualFormatReturnMode,
}

/*
Mode: 'htmlFormulaString'
    formulas put in 'htmlScript' 
    Used only for Debugging, to insure html and reactNode formula formats render identically.

Mode: 'reactFormulaNode'
    As best possible 'mathmatical expression' 
    For the viewMode of column formulas.
    This mode does NOT require the getTableValue() function.
    It is called at the same time we are error checking and
    summarizing the information for column expressions.
    This is all done BEFORE getTableValue() can be created.

Mode: 'reactCellNode'
    Includes the formula line AND actual values for that specific row
    For asking why a particular cell seems to have a bad calculated value.
    This mode requires getTableValue() and can only be accesss AFTER the
    updateTableComputedData() has created the getTableValue() function.
*/

// A react Component style interface to the createPrettyFormula function.
export function CreatePrettyFormats ( props: FormulaProps ): JSX.Element {
  const {scryExpression, colKey, rowKey, tableComputedData, 
    isValidFormula, returnMode } = props
  if (returnMode === 'reactCellNode') {
    return createPrettyFormulaCellNode( scryExpression, colKey, rowKey,
      tableComputedData, isValidFormula )
  } else {
    return createPrettyFormulaReactNode( scryExpression, colKey,
      tableComputedData, isValidFormula )
  }
}


// An interface to measure the prettyFormula line length.
// Used for error checking. Does one line at at time.
export const getPrettyFormulaLineLength = 
     ( subStrings: SubStringInfo[], colTitleArr: string[] ) => {
  const resultByFormulaLineNum: FormulaLineResult = {wasExecuted: false, exprResult: '', tmpVars: {}}
  const formattingObjArr: FormattingObj[] = []
  const getTableValue = (colKey: number, rowKey: number, hideErroneousValues: boolean): AccessCellStringReturnType => {
    return {
       value: '',
       isErroneous: false,
       isMissingRowName: false
    }
  }
  var {htmlScript} = onePrettyLine( subStrings, -1, -1, -1,   // rowKey, colKey, lineNum = -1
       resultByFormulaLineNum, 
       colTitleArr, formattingObjArr, 
       getTableValue, 'htmlFormulaString' )
  htmlScript = '<span>' + htmlScript + '<br/></span>'
  return measureHTMLwidth(htmlScript, constants.COL_FORMULA_EDITOR_FONT_SIZE + 'px')
}




// DEBUGGING :

// This function creates pretty, formatted formulas in two different formats.
// As a reactNode, which is displayed with react.  (Which is what we use for rendered formulas)
// As an html script, used ONLY for measuring line length (checking for a formatted formula too long error).
// How to verify and keep the two formats in sync??  (identical lengths)
// There is debugging code in component module /table/EditColFormula
// This debugging code will display BOTH react and html formulas.
// See my notes in EditColFormula module.


export const createPrettyFormula = ( scryExpression: ScryFormula, colKey: number, rowKey: number,  
                tableComputedData:TableComputedData, isValidFormula: boolean,
                returnMode: FormualFormatReturnMode ) => {

  // Unlike the editable format, this format does NOT need to directly
  // overlay the string input text. Purpose of this format is Readability/Prettiness.
  // Three versions, depending on return Mode:
  //     reactFormulaNode       No cell selected; Hence col header editor is currently selected.
  //                            Display the formula as best possible like a 'Math' expressions
  //                            Display format is a reactNode.
  //     reactCellNode          A specific dependent Column cell (rowKey) is selected.
  //                            Intention now is to show the calculation, and potential runtime errors.
  //                            We won't show any comment lines.  And will make un-executed lines nearly translucent
  //                            Show varNames & colNames as a pair of name & value.
  //     htmlFormulaString      Same information as in reactFormulaNode, however in htmlFormat.
  //                            Used for measuring the PrettyFormat expression lengths (by line).
  //                            Also can be used for rendering by using 'dangerously Set Inner HTML( )', however,
  //                            this is only done component EditColFormula for the purpose of
  //                            debugging/testing --- to insure reactFormulaNode and htmlFormulaString
  //                            render exactly alike.

  const { tableValuesWorking, derivedColAttributesArray, getTableValue} = tableComputedData
  const colTitleArr     = derivedColAttributesArray.map( thisCol => thisCol.colTitle )
  const formattingObjArr= derivedColAttributesArray.map( thisCol => thisCol.formattingObj )
  if ( !isValidFormula ) {
    return  (returnMode === 'htmlFormulaString')
       ? [ '<div># No valid formula is defined.</div>' ]
       :    <div># No valid formula is defined.</div>
  }
  // IFF cellMode, evaluate this specify single row in mode: isInstrumented = true
  // This will extract whatever additional information we deem useful for debugging formulas.
  // This step is only used when isCellMode===true
  let resultByFormulaLineNum: FormulaLineResult[] = []
  if ( returnMode === 'reactCellNode' ) {
      const program = scryExpression.program_RPN
      const isInstrumented = true;
      const tempTableValuesWorking = []
      for ( const [i,val] of tableValuesWorking.entries()  ) {
        tempTableValuesWorking[i] = [...val]
      }
      ({resultByFormulaLineNum} = RPN_evaluate( program, rowKey, rowKey, colKey,
              tempTableValuesWorking, isInstrumented ))
  }

  // Special formatting case for exponential numbers:
  // Consider a 'constExp' (a constant in exponential format) raised to some power.
  // If the user writes 5e2**3, this means: 5e2 cubed = 125e6
  // How do we display this in HTML ??
  // NOT  5*10 squared cubed =   5e6 !!
  // But (5*10 squared)cubed = 125e6
  // IF an exponential number is followed by 'op_**' THEN wrap the exponential number in parens.
  // Code below: IFF constExp is followed by 'op_**' THEN rename it: constExpWrapped
  // Note that this is a distinction made ONLY for the purpose of formatting.
  // During computation: constExp, constExpWrapped, and constAnything are treated equally as 'const'
  // The various subString names are for debug and/or display purposes only.
  const {formulaSubStrings} = scryExpression
  formulaSubStrings.forEach( (thisLine,lineNum) => {
    var priorName = ''
    var priorIndex = 0
    thisLine.forEach ( (thisMatch,i) => {
      var thisName = thisMatch.name
      switch( thisName ) {
        case 'op_**':
          if (priorName === 'constExp' ) { formulaSubStrings[lineNum][priorIndex].name = 'constExpWrapped' }
          break
        case 'emptyString':
          break
        default:
          priorName = thisName
          priorIndex= i
      }
    })
  })

  // Also wrap the colName in case of colName**exp
  formulaSubStrings.forEach( (thisLine,lineNum) => {
    var priorName = ''
    var priorIndex = 0
    thisLine.forEach ( (thisMatch,i) => {
      var thisName = thisMatch.name
      switch( thisName ) {
        case 'op_**':
          if (priorName === 'colName' ) { formulaSubStrings[lineNum][priorIndex].name = 'colNameWrapped' }
          break
        case 'emptyString':
          break
        default:
          priorName = thisName
          priorIndex= i
      }
    })
  })

  // Step 4:  Create one Component per Expression line.
  //          Will skip comment only lines when in cellMode.
  let reactNodeByLineNumber: ReactNode[] = []   // One react Node object for each lineNum.
  let htmlScriptByLineNumber: string[] = []   // One string for each lineNum.
  var lineNum = -1
  for (const thisExpr of formulaSubStrings) {
      lineNum++
      // When isCellMode, we are going to skip outputting blank lines,
      // and skip outputting any comments following an expression
      if ( !isThisLineAnExpression(thisExpr) && returnMode === 'reactCellNode' ) {
        reactNodeByLineNumber[lineNum]  = null
      } else if ( scryExpression.isBlankLine[lineNum] ) {
        reactNodeByLineNumber[lineNum]  = null
      } else {
        var result = onePrettyLine( thisExpr, colKey, rowKey, lineNum, 
                                    resultByFormulaLineNum[lineNum], colTitleArr, formattingObjArr, getTableValue, returnMode )
        reactNodeByLineNumber[lineNum] = result.reactNodeArray
        htmlScriptByLineNumber[lineNum] = '<span>' + result.htmlScript + '<br/></span>'
      }
  }

  // Easy, early return for htmlString formatted expressions:
  if (returnMode === 'htmlFormulaString') { return htmlScriptByLineNumber }

  // IFF reactCellNode' and we had a run-time 'Missing Return' error, then this will NOT be
  // found anywhere in the formulaSubStrings information.
  // But we can ask whether this particular cell has the value 'Missing Return'.
  // And we can add this information to outputArray.
  if ( returnMode === 'reactCellNode' ) {
    var {value } = getTableValue( colKey, rowKey, false )
    if ( value === 'Missing Return' ) {
      reactNodeByLineNumber.push( <span style={{color:'red'}}>Missing Return Statement</span> )
      resultByFormulaLineNum.push( {wasExecuted: true, tmpVars:{}, exprResult:'Missing Return'})
    }
  }

  // Line level formatting (use nearly transparent lines if un-executed).
  // If 'reactCellNode' mode, skip lines that are NOT expressions (blank lines and comment only lines)
  return(
    <div style={{fontSize:FONT_SIZE, lineHeight:'130%'}} >
      { reactNodeByLineNumber.map( (thisLine, i)=> {
        // We previously defined lines we wish to NOT output as 'null' (NOT an array of react formatted substrings).
        // These are lines in View DepColumn Cell mode, where comment only lines and empty lines
        // are considered to consume more vertical space than we desire, hence we skip them on output.
        // This is NOT the same as a user expression line that is empty (just a carriage return).
        // An expression line with no text will be rendered as an empty line, EXCEPT in View DepColumn Cell mode.
        if ( thisLine === null ) { return null }
        let isNonExecutedLine = (returnMode === 'reactCellNode' && !(resultByFormulaLineNum[i] && resultByFormulaLineNum[i].wasExecuted))
        let opacity = (isNonExecutedLine) ? 0.2 : 1
        return  <span key={i} style={{opacity}}>
                    {reactNodeByLineNumber[i]}
                <br/></span>
      })}
    </div>
  )
}

export const createPrettyFormulaReactNode = ( scryExpression: ScryFormula, colKey: number,
  tableComputedData:TableComputedData, isValidFormula: boolean): JSX.Element =>
{
  return createPrettyFormula(scryExpression, colKey, 0, tableComputedData, isValidFormula, 'reactFormulaNode' ) as JSX.Element
}

export const createPrettyFormulaCellNode = ( scryExpression: ScryFormula, colKey: number, rowKey: number,
  tableComputedData:TableComputedData, isValidFormula: boolean): JSX.Element =>
{
  return createPrettyFormula(scryExpression, colKey, rowKey, tableComputedData, isValidFormula, 'reactFormulaNode' ) as JSX.Element
}

export const createPrettyFormulaHTMLString = ( scryExpression: ScryFormula, colKey: number,
  tableComputedData:TableComputedData, isValidFormula: boolean): string[] =>
{
  return createPrettyFormula(scryExpression, colKey, 0, tableComputedData, isValidFormula, 'reactFormulaNode' ) as string[]
}


const insertNameValuePair = ( name: string, nameStyle: CSSProperties, reactValue: ReactNode,
  width:number, valColor:string, background:string )  => {

  width += 2*EXTENSION_BLUEBOX_BEYOND_TEXT_PER_SIDE
  let styleSpan1 = {
    fontSize: FONT_SIZE,
    lineHeight: 2.4,
  }
  const inlineDiv: CSSProperties = {
    display:'inline-block',
    fontSize: FONT_SIZE,
    height: 2*FONT_SIZE-2,
    width,
    textAlign: 'center',
    verticalAlign: 'middle',
     // `vertical-align:-${FONT_SIZE/2,
    lineHeight:1.15,
    background
  }
  const halfDiv1: CSSProperties = {
    height: FONT_SIZE-2,
    width: '100%',
    ...nameStyle,
  }
  const halfDiv2: CSSProperties = {
    color:valColor,
    height:FONT_SIZE,  // Value div needs more height to hold exponential formats.
    width:'100%'
  }


  return  <span key={keyCount++} style={styleSpan1}>
            <div style={inlineDiv}>
                <div key={keyCount++} style={halfDiv1}>{name}</div>
                <div key={keyCount++} style={halfDiv2}>{reactValue}</div>
            </div>
          </span>
}


const isThisLineAnExpression = ( thisLine: Array<SubStringInfo> ) : boolean => {
  if ( thisLine.length === 1 ) {  return false  }
  if ( thisLine.length === 2 && thisLine[1].name === 'comment' ) { return false }
  return true
}





var keyCount = 0

type SubStringTerm = [ReactNode, string]

const onePrettyLine = ( thisExpr: SubStringInfo[], colKey: number, rowKey: number, lineNum: number, resultByFormulaLineNum: FormulaLineResult,
                        colTitleArr: string[], formattingObjArr: FormattingObj[], getTableValue: GetTableValue, returnMode: FormualFormatReturnMode )
                        : { reactNodeArray: ReactNode[], htmlScript:string }=> {


  // React requires a key whenever we have what appear to be identical child nodes.
  // Difficult to predict when and where a arbitary formula may insert identical children.
  // So use a keyCounter to give each child a unique key.
  // Periodically re-set the key counter to zero.
  // Now is a good time!
  keyCount = 0

  // Functions to display row specific, calculated values for colNames, varNames, return, if, and elif
  const getColNameInfo = (colKey: number): {colName: string, reactValue: ReactNode, width: number, valColor: string} => {
    // Input colKey is the column from which data is used, not the current colKey being calculated.
    const {value, isErroneous} = getTableValue( colKey, rowKey, false )
    const colTitle = colTitleArr[colKey]
    const formattingObj = formattingObjArr[colKey]
    let reactValue: ReactNode
    let measureOnlyValue: string

    if ( !isErroneous && value === '' ) {
      reactValue = 'None'
      measureOnlyValue = 'None'
    } else if ( !isErroneous ) {
      const tmpFormattingObj = {...formattingObj, allowsPrefixSuffix:false}
      reactValue = numberFormatReactNode(value, tmpFormattingObj)
      measureOnlyValue = numberFormatMeasureOnly(value, tmpFormattingObj)
    } else {  // isErroneous && value !== ''
      reactValue = 'NaN'
      measureOnlyValue   = 'NaN'
    }
    const widthOfName  = measureText(colTitle, `${FONT_SIZE}px`)
    const widthOfValue = measureText(measureOnlyValue, `${FONT_SIZE}px` )
    const width = Math.round(Math.max( widthOfName, widthOfValue ))
    return { colName: colTitle, reactValue, width, valColor: 'black' }
  }

  const getVarNameInfo = ( varName:string, lineNum:number ):
            {varName: string, reactValue: ReactNode, width: number, valColor: string} => {
    const value : TempValue = resultByFormulaLineNum.tmpVars[varName]
    let valColor = 'black'
    let reactValue: ReactNode
    let measureOnlyValue: string
    if ( value === '' ) {
      reactValue = 'None'
      measureOnlyValue = 'None'
    } else if ( value === undefined ) {
      reactValue = 'Named value was not defined'
      measureOnlyValue = 'Named value was not defined'
      valColor = 'red'
    } else if ( Number.isFinite( Number(value) )  ) {
      const formattingObj = getFormattingObj('defaultEng')
      reactValue = numberFormatReactNode(value, formattingObj)
      measureOnlyValue   = numberFormatMeasureOnly(value, formattingObj)
    } else {  // not a finite number
      reactValue = 'NaN'
      measureOnlyValue   = 'NaN'
    }
    const widthOfName  = measureText(varName, `${FONT_SIZE}px`)
    const widthOfValue = measureText(measureOnlyValue, `${FONT_SIZE}px` )
    const width = Math.round(Math.max( widthOfName, widthOfValue ))
    return { varName, reactValue, width, valColor }
  }

  const setVarNameInfo = ( varName:string, lineNum:number ):
            {varName:string, reactValue:ReactNode, width:number, valColor:string} => {
    var value = resultByFormulaLineNum.exprResult
    var valColor = 'black'
    let reactValue: ReactNode
    let measureOnlyValue: string
    if ( value === '' ) {
      reactValue = 'None'
      measureOnlyValue = 'None'
    } else if ( Number.isFinite( Number(value) )  ) {
      const formattingObj = getFormattingObj('defaultEng')
      reactValue = numberFormatReactNode(String(value), formattingObj)
      measureOnlyValue = numberFormatMeasureOnly(String(value), formattingObj)
    } else {  // not a finite number
      reactValue = 'NaN'
      measureOnlyValue   = 'NaN'
    }
    const widthOfName  = measureText(varName, `${FONT_SIZE}px`)
    const widthOfValue = measureText(measureOnlyValue, `${FONT_SIZE}px` )
    const width = Math.round(Math.max( widthOfName, widthOfValue ))
    return { varName, reactValue, width, valColor }
  }

  const returnInfo = ( colName:string, lineNum:number ):
            {colName: string, reactValue: ReactNode, width: number, valColor: string} => {
    var value = resultByFormulaLineNum.exprResult
    var valColor = 'black'
    var widthOfName = measureText('return', `${FONT_SIZE}px`, 'bold' )
    let reactValue: ReactNode
    let measureOnlyValue: string
    if ( value === '' ) {
      reactValue = 'None'
      measureOnlyValue = 'None'
    } else if ( Number.isFinite( Number(value))) {
      const formattingObj = formattingObjArr[colKey]
      const tmpFormattingObj = {...formattingObj, allowsPrefixSuffix:false}
      reactValue = numberFormatReactNode(String(value), tmpFormattingObj)
      measureOnlyValue   = numberFormatMeasureOnly(String(value), formattingObj)
    } else {
      reactValue = 'Not a Number'
      measureOnlyValue = 'Not a Number'
      valColor = 'red'
    }
    const widthOfValue = measureText(measureOnlyValue, `${FONT_SIZE}px` )
    const width = Math.round(Math.max( widthOfName, widthOfValue ))
    return { colName, reactValue, width, valColor }
  }

  const ifElifInfo = ( name:string, lineNum:number ):
            {name:string, reactValue:ReactNode, width:number, valColor:string} => {
    const reactValue: ReactNode = !!resultByFormulaLineNum.exprResult ? 'True' : 'False'
    // We set the width to hold 'False'. Hence all if/elif will be width
    // of 'False'.  Just a touch better when if/elif constuctions are aligned vertically.
    const widthOfFalse = measureText('False', `${FONT_SIZE}px` )
    return { name, reactValue, width:widthOfFalse, valColor:'black' }
  }


  let parts: string[] = []
  var isRaised = false
  var funcName = ''
  var nbSpace = constants.nonBreakingSpace
  // $FlowFixMe
  var useNameValueFormatting = ( returnMode === 'reactCellNode' && resultByFormulaLineNum.wasExecuted )


  const reactSubStringArray : SubStringTerm[] = thisExpr.map( (thisSubString: SubStringInfo, index: number): SubStringTerm => {

      var {name:subStringName, text, value} = thisSubString

      //  Lump all functions into the same group
      if ( subStringName.slice(0,4) === 'func' ) {
        funcName = subStringName.slice(4)
        subStringName = 'func'
      }

      //  Lump all bad (illegal) opcodes into the same group
      if ( subStringName.slice(0,6) === 'op_bad' ) {
        funcName = subStringName.slice(4)
        subStringName = 'op_bad'
      }

      switch( subStringName ) {

        case 'op_(':
              // is next op an open parens?  If so, then no space between a pair of parens.
              var isDoubleParens = ( thisExpr[index+1] && thisExpr[index+1].name === subStringName )
              if (isRaised && isDoubleParens ) {
                return  ['(', '(']
              } else if (isRaised) {
                return  ['('+thinSpaceChar, '('+thinSpaceChar] // standard size, then space
              } else if ( isDoubleParens ) {
                return  [  <big key={keyCount++}>(</big> , '<big>(</big>' ] // large size, no space after 1st of double parens
              } else {
                return  [ <Fragment key={keyCount++} >
                              <big key={keyCount++}>(</big>
                              {thinSpaceChar}
                          </Fragment>
                       , `<big>(</big>${thinSpaceChar}` ]
              }


        case 'op_)':
              // is prior op a close parens?  If so, then no space between double parens.
              isDoubleParens = ( thisExpr[index-1] && thisExpr[index-1].name === subStringName )
              if (isRaised && isDoubleParens ) {
                return  [')',')'] // standard size, no space between double parens
              } else if (isRaised) {
                return  [thinSpaceChar+')', thinSpaceChar+')'] // standard size, then space
              } else if ( isDoubleParens ) {
                return [ <big key={keyCount++}>)</big> ,'<big>)</big>' ]     // large size, no space between double parens
              } else {
                return  [ <Fragment key={keyCount++}>
                            {' '}
                            <big key={keyCount++}>)</big>
                          </Fragment>
                       , `${nbSpace}<big>)</big>` ]
              }


        // Special case of exponent operator.
        // There is no output text, other than we raise the subsequent text.
        // And space between significand and raised text is a thinSpaceChar
        case 'op_**':
              if ( useNameValueFormatting ) {
                return [  <Fragment  key={keyCount++}>
                            {thinSpaceChar}
                            <span style={{fontSize:'80%', position:'relative', bottom:'0.07em'}}>
                              {MultiplyElement}
                              {hairSpaceChar}
                              {MultiplyElement}
                            </span>
                            {thinSpaceChar}
                          </Fragment>
                          , '' ]
              }
              isRaised = true
              return ['sup delimiter','sup delimiter']  //  Will become <sup style={{fontSize:`${SUP_PERCENT_SHRINK}%`}}>

        case 'endExp':
              if ( useNameValueFormatting ) { return [null, ''] }
              isRaised = false
              return  ['sup delimiter','sup delimiter'] //  Will become </sup>


        // NO spacing to either side of uniSub.  It is preceeded by an operator that
        // already has a trailing space.  And we don't want a space before the value.
        case 'uniSub': return [ <span  key={keyCount++} style={{fontSize:'130%', position:'relative', bottom:'-0.03em'}}>
                                  {thinSpaceChar + '-'}
                                </span>
                             , `<span style="font-size:130%; position:relative; bottom:-0.03em;">${thinSpaceChar+'-'}</span>` ]


        // These math operators use thinSpaceChar spacing.  One half-space to each side of operator:
        case 'op_=': return [ <span  key={keyCount++} style={{fontSize:'125%', position:'relative', bottom:'+0.01em'}}>
                                {thinSpaceChar + '=' + thinSpaceChar}
                              </span>
                              ,`<span style="font-size:125%; position:relative; bottom:0.01em;">${thinSpaceChar+'='+thinSpaceChar}</span>` ]
        case 'op_/': return [ thinSpaceChar + divideChar + thinSpaceChar
                            , thinSpaceChar + divideChar + thinSpaceChar ]
        case 'op_*': return [ <Fragment  key={keyCount++}>
                                {thinSpaceChar}
                                {MultiplyElement}
                                {thinSpaceChar}
                              </Fragment>
                              , thinSpaceChar + multiplyChar + thinSpaceChar ]
        case 'op_//': return  [ <Fragment  key={keyCount++}>
                                  <span style={{position:'relative', left:'0.25em'}}>
                                    {divideChar}
                                  </span>
                                  {divideChar}{thinSpaceChar}
                                </Fragment>
                                , `<span style="position:relative; left:0.25em;">${divideChar}</span>${divideChar+thinSpaceChar}` ]
        case 'op_%': return [ <Fragment  key={keyCount++}>
                                {thinSpaceChar}
                                <span style={{fontSize:'90%', fontWeight:'bold', position:'relative', bottom:'0.02em'}}>%</span>
                                {thinSpaceChar}
                              </Fragment>
                              ,  thinSpaceChar +
                                '<span style="font-size:90%; font-weight:bold; position:relative; bottom:0.02em;">%</span>' +
                                 thinSpaceChar  ]
        case 'op_+': return [ <Fragment  key={keyCount++}>
                                {thinSpaceChar}
                                <span style={{fontSize:'105%', position:'relative', bottom:'0.05em'}}>+</span>
                                {thinSpaceChar}
                              </Fragment>
                              , `${thinSpaceChar}<span style="font-size:105%; position:relative; bottom:0.05em;">+</span>${thinSpaceChar}` ]
        case 'op_-': return [ <Fragment  key={keyCount++}>
                                {thinSpaceChar}
                                <span style={{fontSize:'90%', fontWeight:'bold', position:'relative', bottom:'0.12em'}}>
                                  {subtractChar}
                                </span>
                                {thinSpaceChar}
                              </Fragment>
                              , thinSpaceChar + '<span style="font-size:90%; font-weight:bold; position:relative; bottom:0.12em;">' +
                                subtractChar  + '</span>' + thinSpaceChar ]
        case 'op_,': return [ <Fragment  key={keyCount++}>
                                {thinSpaceChar}
                                <span style={{fontSize:'160%', position:'relative', bottom:'0.0em'}}>,</span>
                                {thinSpaceChar}
                              </Fragment>
                              , `${thinSpaceChar}<span style="font-size:160%; position:relative; bottom:0.0em;">,</span>${thinSpaceChar}` ]


        // These operators use a full, simple spacing.  One REGULAR space to each side of operator.
        // Use regular spaces because these three ops can appear adjacent to each other.
        // IF we use nbSpace (usually my preference) then 'and not' and 'or not' constructions result in two spaces,
        // instead of the normal oen space between symbols.
        case 'op_and': return [ <span  key={keyCount++} style={{fontWeight:'bold'}}>{' and '}</span>
                             , `<span style="font-weight:bold;"> and </span>` ]
        case 'op_or' : return [ <span  key={keyCount++} style={{fontWeight:'bold'}}>{' or '}</span>
                             , `<span style="font-weight:bold;"> or </span>` ]
        case 'op_not': return [ <span  key={keyCount++} style={{fontWeight:'bold'}}>{' not '}</span>
                             , `<span style="font-weight:bold;"> not </span>` ]

        // These operators use a full, simple spacing.  One nbSpace space to each side of operator.
        case 'op_==' : return [ <span  key={keyCount++} style={{position:'relative', bottom:'0.05em'}}>{nbSpace+'=='+nbSpace}</span>
                             , `<span style="position:relative; bottom:0.05em;">${nbSpace+'=='+nbSpace}</span>` ]
        case 'op_<'  : return [ <span  key={keyCount++} style={{fontSize:'80%', fontWeight:'bold', position:'relative', bottom:'0.13em'}}>{nbSpace+'<'+nbSpace}</span>
                             , `<span style="font-size:80%; position:relative; bottom:0.13em;">${nbSpace+'&lt;'+nbSpace}</span>` ]
        case 'op_>'  : return [ <span  key={keyCount++} style={{fontSize:'80%', fontWeight:'bold', position:'relative', bottom:'0.13em'}}>{nbSpace+'>'+nbSpace}</span>
                             , `<span style="font-size:80%; position:relative; bottom:0.13em;">${nbSpace+'&gt;'+nbSpace}</span>` ]
        case 'op_:'  : return [ <span  key={keyCount++} style={{position:'relative', fontWeight:'bold', bottom:'0.08em'}}>{nbSpace+':'+nbSpace}</span>
                             , `<span style="position:relative; font-weight:bold; bottom:0.08em;">${nbSpace+':'+nbSpace}</span>` ]
        case 'op_!=' : return [ <Fragment  key={keyCount++}>
                                  {nbSpace+'!'+hairSpaceChar}
                                  <span style={{position:'relative', bottom:'0.05em'}}>{'='+nbSpace}</span>
                                </Fragment>
                              , nbSpace+'!'+hairSpaceChar+'<span style="position:relative; bottom:0.05em;">='+nbSpace+'</span>' ]
        case 'op_<=' : return [ <Fragment  key={keyCount++}>
                                  <span  key={keyCount++}   style={{fontSize:'80%', fontWeight:'bold', position:'relative', bottom:'0.14em'}}>{nbSpace+'<'}</span>
                                  <span  key={keyCount++} style={{position:'relative', bottom:'0.05em'}}>{hairSpaceChar+'='+nbSpace}</span>
                                </Fragment>
                              , `<span style="font-size:80%; font-weight:bold; position:relative; bottom:0.14em;">${nbSpace+'&lt;'}</span>` +
                                `<span style="position:relative; bottom:0.05em;">${hairSpaceChar+'='+nbSpace}</span>` ]
        case 'op_>=' : return [ <Fragment key={keyCount++}>
                                  <span key={keyCount++} style={{fontSize:'80%', fontWeight:'bold', position:'relative', bottom:'0.14em'}}>{nbSpace+'>'}</span>
                                  <span key={keyCount++} style={{position:'relative', bottom:'0.05em'}}>{hairSpaceChar+'='+nbSpace}</span>
                                </Fragment>
                              , `<span style="font-size:80%; font-weight:bold; position:relative; bottom:0.14em;">${nbSpace+'&gt;'}</span>` +
                                `<span style="position:relative; bottom:0.05em;">${hairSpaceChar+'='+nbSpace}</span>` ]



        case 'emptySpace':
        case 'endReturn':
              return [ null, '' ]

        // const and varName values have no pre/post spaces.  All spacing is managed by the operators.
        case 'constTrue' : return [ 'True','True' ]
        case 'constFalse': return [ 'False','False' ]
        case 'constpi'   : return [ 'pi','pi' ]

        case 'constFlt':
        case 'constInt':
              text = text.trim()
              if ( text[0] === '.' ) { text = '0'+text }
              return [ <Fragment key={keyCount++}>{text}</Fragment>
                     , text ]

        case 'constB60':
              text = text.trim()
              parts = text.split(':')
              return  [ <Fragment key={keyCount++}>
                          {parts[0]}
                          <big>:</big>
                          {parts[1]}
                        </Fragment>
                      , parts[0]+'<big>:</big>'+parts[1] ]

        case 'constB60B60':
              text = text.trim()
              parts = text.split(':')
              return  [ <Fragment key={keyCount++}>
                          {parts[0]}
                          <big>:</big>
                          {parts[1]}
                          <big>:</big>
                          {parts[2]}
                        </Fragment>
                      , parts[0]+'<big>:</big>'+parts[1]+'<big>:</big>'+parts[2] ]

        case 'constExp':
              text = text.trim()
              parts = text.trim().split('e')
              if ( parts[0][0] === '.' ) { parts[0] = '0' + parts[0] }
              return  [ <Fragment key={keyCount++}>
                          {parts[0]}
                          {thinSpaceChar}
                          {MultiplyElement}
                          {'10'}
                          <sup>
                             {parts[1]}
                          </sup>
                        </Fragment>
                      , parts[0]+thinSpaceChar+multiplyChar+'10<sup>'+parts[1]+'</sup>' ]

        case 'constExpWrapped':
              text = text.trim()
              parts = text.trim().split('e')
              if ( parts[0][0] === '.' ) { parts[0] = '0' + parts[0] }
              return  [ <Fragment key={keyCount++}>
                          <big key={keyCount++}>(</big>
                          {thinSpaceChar}
                          {parts[0]}
                          {thinSpaceChar}
                          {MultiplyElement}
                          {'10'}
                          <sup key={keyCount++}>
                            {parts[1]}
                          </sup>
                          {thinSpaceChar}
                          <big key={keyCount++}>)</big>
                        </Fragment>
                      , '<big>(</big>'+thinSpaceChar+parts[0]+thinSpaceChar+multiplyChar+
                        '10<sup>'+parts[1]+'</sup>'+ thinSpaceChar+'<big>)</big>' ]

        case 'varName':
        case 'setVarName':
              var varNameBeingAccessed = String(value)
              if (useNameValueFormatting) {
                let reactValue: ReactNode = '', width, valColor
                if ( subStringName === 'varName' ) {
                  ({reactValue, width, valColor} = getVarNameInfo(varNameBeingAccessed, lineNum))
                } else {
                  ({reactValue, width, valColor} = setVarNameInfo(varNameBeingAccessed, lineNum))
                }
                let nameStyle={}  // Nothing unique about styling varNames.
                return [ insertNameValuePair( varNameBeingAccessed, nameStyle, reactValue, width, valColor, LIGHTGREY_BACKGROUND )
                      , varNameBeingAccessed ]
              }
              return [ varNameBeingAccessed
                     , varNameBeingAccessed ]


        // colName values have a thinSpace to each side.  This is considered part of the
        // colName and will have the blue background.  Operators before/after a colName
        // will manage the visual spacing between blue block and operators.
        case 'colName':
              if (useNameValueFormatting) {
                let {colName, reactValue, width, valColor} = getColNameInfo(Number(value))
                let nameStyle={}
                return [ insertNameValuePair( colName, nameStyle, reactValue, width, valColor, SCRY_VERYLIGHT_BLUE )
                       , colName ]
              }
              var colName = colTitleArr[Number(value)]
              return  [ <span key={keyCount++} style={{background:SCRY_VERYLIGHT_BLUE}}>
                          {thinSpaceChar}
                          {colName}
                          {thinSpaceChar}
                        </span>
                        , `<span style="background:${SCRY_VERYLIGHT_BLUE};">` +
                           thinSpaceChar + colName.replace( /</g, '&lt;' ) + thinSpaceChar + '</span>' ]

         case 'colNameWrapped':
               if (useNameValueFormatting) {
                 let {colName, reactValue, width, valColor} = getColNameInfo(Number(value))
                 let nameStyle={}
                 return [ insertNameValuePair( colName, nameStyle, reactValue, width, valColor, SCRY_VERYLIGHT_BLUE )
                        , colName ]
               }
               colName = colTitleArr[Number(value)]
               return  [ <Fragment key={keyCount++}>
                         <big>(</big>
                         <span style={{background:SCRY_VERYLIGHT_BLUE}}>
                           {thinSpaceChar}{colName}{thinSpaceChar}
                         </span>
                         <big>)</big>
                         </Fragment>

                         , `<span style="background:${SCRY_VERYLIGHT_BLUE};">` +
                            thinSpaceChar + colName.replace( /</g, '&lt;' ) + thinSpaceChar + '</span>' ]

                    //   , '<big>(</big>'+thinSpaceChar+parts[0]+thinSpaceChar+'<big>)</big>' ]

        case 'flowpass': return [ 'pass', 'pass' ]
        case 'None'    : return [ 'None', 'None' ]
        case 'flowelse': return [ <span key={keyCount++} style={{fontWeight:'bold', color:'#222222'}}>else</span>
                                ,`<span style="font-weight:bold; color:#222222;">else</span>` ]

        case 'flowif'  :
        case 'flowelif':
        case 'flowreturn':
              let flowname = subStringName.slice(4)
              let nameStyle = {fontWeight:'bold', color:'#222222'}
              let reactValue, width, valColor
              if (useNameValueFormatting) {
                if ( flowname === 'return' ) {
                  ({reactValue, width, valColor} = returnInfo(flowname, lineNum))
                } else {
                  ({reactValue, width, valColor} = ifElifInfo(flowname, lineNum))
                }
                return  [ <Fragment key={keyCount++}>
                            {insertNameValuePair( flowname, nameStyle, reactValue, width, valColor, LIGHTGREY_BACKGROUND )}
                            {nbSpace+nbSpace+nbSpace+nbSpace}
                          </Fragment>
                        , '' ]
              }
              return    [ <span key={keyCount++} style={nameStyle}>{flowname+nbSpace+nbSpace}</span>
                        , `<span style="font-weight:bold; color:#222222;">${flowname+nbSpace+nbSpace}</span>` ]


        // Functions need no pre-spacing. But open parens is followed with a
        // thinSpaceChar to preceed the first function argument.
        case 'func':
              if (isRaised) {
                return [ funcName+'('+thinSpaceChar, funcName+'('+thinSpaceChar ]
              } else {
                return  [ <Fragment key={keyCount++}>
                            {funcName}
                            <big>(</big>
                            {thinSpaceChar}
                          </Fragment>
                        , funcName + '<big>(</big>' + thinSpaceChar ]
              }


        // Indent spaces are some integer multiple of the indentation level (indent.value)
        case 'indent':
             if ( value === -1 ) { return ['',''] }   // Common case of no indent.
             return [ <Fragment key={keyCount++}>
                        {(constants.nonBreakingSpace).repeat( Number(value) * constants.FORMULA_INDENT_LENGTH )}
                      </Fragment>
                    ,    (constants.nonBreakingSpace).repeat( Number(value) * constants.FORMULA_INDENT_LENGTH ) ]

        case 'comment':
              if (returnMode === 'reactCellNode') {
                return [ null, '' ]
              }
              // Spaces preceeding a comment; Rules by priority:
              // If a comment only line (following the indent), trim both sides of comment.
              // Otherwise, typical comment usage at end of line, with preceding spaces as user pleases. Only do a trimRight().
              if (index === 1) { text = text.trim() }  // A comment only line, following the indent.
              else { text = text.trimRight() }
              return [ <span key={keyCount++} style={{color:'#404040'}}>{text}</span>
                      , text ]

        case 'unmatched':
        case 'op_bad':
              return [ <Fragment key={keyCount++}>{text}</Fragment>
                     , text ]

        default:
              invariant( false, `Unrecognized opcode ${subStringName} in display formatting` )

      }
      // Next line of code should never be reached, but needed to prevent earlier linter error that reports
      // the mapping function MUST have a return statement for all paths.
      return [null, '']

  })


  // Next two blocks could be combined, but keeping them apart for debugging.
  // Split the array into alternating baseline and raised subBlocks
  var currentSubBlock: SubStringTerm[] = []
  const subBlocks: SubStringTerm[][] = [ currentSubBlock ]  //  same as: [[]]
  reactSubStringArray.forEach( subString =>{
    if ( subString[0] === 'sup delimiter' ) {
      currentSubBlock = []  // Start a new subBlock
      subBlocks.push( currentSubBlock )
    } else {
      currentSubBlock.push( subString) // Append subString to current subBlock
    }
  })

  // Re-assemble subBlocks into single array with raised (nested) subBlocks:
  var nestedArray: ReactNode[] = []
  var htmlScript  = ''
  subBlocks.forEach(  (thisBlock, index)=> {
    if ( index%2 === 0 ) {
      let reactTerms = thisBlock.map( thisTerm=>{ return thisTerm[0] })
      let htmlTerms  = thisBlock.map( thisTerm=>{ return thisTerm[1] })
      htmlScript += htmlTerms.join('')
      nestedArray = nestedArray.concat( reactTerms )   // baseline substrings
    } else {
      let reactTerms = thisBlock.map( thisTerm=>{ return thisTerm[0] })
      let htmlTerms  = thisBlock.map( thisTerm=>{ return thisTerm[1] })
      htmlScript += `<sup style="font-size:80%" >` +
                        htmlTerms.join('') +
                    '</sup>'

      nestedArray.push(
        <sup key={keyCount++} style={{fontSize:`${SUP_PERCENT_SHRINK}%`}}>
           {reactTerms}
        </sup>,
      )
    }
  })

  return {reactNodeArray : nestedArray, htmlScript}
}
