import React, { PureComponent, Fragment, Component } from 'react'
import { DragSource, DropTarget } from 'react-dnd'
import type {
  ConnectDragPreview,
  ConnectDragSource,
  ConnectDropTarget,
  DragSourceConnector,
  DragSourceMonitor,
  DragSourceSpec,
  DropTargetConnector,
  DropTargetMonitor,
} from 'react-dnd'
import isScryNumber from '../sharedFunctions/isTypeNumber'
import { numberFormat, getFormattingObj } from '../sharedFunctions/numberFormat'
import constants from '../sharedComponents/constants'
import reactDispatch from '../sharedComponents/reactDispatch'
import { CleanTextOptions, cleanScryInputText2 } from '../sharedFunctions/cleanScryInputText'
import type { DerivedColAttributes } from '../computedDataTable/getDefaultTableComputedData'
import { deepClone } from '../sharedFunctions/utils'
import type { FilterRelation, FilterRule } from '../types'
import { getRelationMenu } from '../sharedFunctions/filterRows'
import EditorMenuButton, { MenuItems } from './EditorMenuButton'
import { getDefaultScryNumber } from '../sharedFunctions/parseScryInputStringTypes'
import { FilterLayoutObj } from './FilterRules'

/*
jps Oct, 2021:
SEE DESIGN NOTES IN MODULE filterRows.js !!!!
SEE DESIGN NOTES IN MODULE filterRows.js !!!!
SEE DESIGN NOTES IN MODULE filterRows.js !!!!
SEE DESIGN NOTES IN MODULE filterRows.js !!!!
*/


type OwnProps = {
  colOrder: Array<number>,
  derivedColAttributesArray: Array<DerivedColAttributes>,
  findItemIndex: (ruleKey: number) => number,
  moveItem: (ruleKey: number, atIndex: number, dragStop:boolean ) => void,
  rowFilters: Array<FilterRule>,
  ruleCount: number,
  ruleIndex: number,
  seriesKey: number,

  resourcePath: string,
  resourceName: 'rowFilters' | 'seriesFilters' | 'commonFilters',
  multiLineMode: '1line' | '2line',
  filterLayout: FilterLayoutObj,
}

type SourceProps = {
  connectDragPreview: ConnectDragPreview,
  connectDragSource: ConnectDragSource,
  isDragging: boolean,
}

type TargetProps = {
  connectDropTarget: ConnectDropTarget,
}

type LocalState = {
  ruleValue: string,
  ruleValueColor: string,
  lastSeriesKey: number,
  lastRuleIndex: number,
}

type Props = OwnProps & SourceProps & TargetProps

// Editor rules:
// LocalState tracks what the user types.
// Persisted State (reactDispatch) is only updated when LocalState
// has a valid ruleValue (eg IsScryNumber has no error).
// On column key change, check the old and new column formats,
// and if they differ, null out ruleValue (in both local state and persised state).

class FilterRuleItemRender extends PureComponent<Props, LocalState> {

  constructor(props: Props) {
    super(props)
    const {rowFilters, ruleIndex} = this.props
    const {value} = rowFilters[ruleIndex]
    this.state = {ruleValue:value, ruleValueColor: 'black',
                  lastSeriesKey: -1,  lastRuleIndex: -1 }
  }

  static getDerivedStateFromProps( nextProps:Props,   prevState:LocalState ) {

    const {derivedColAttributesArray, rowFilters, ruleIndex, seriesKey} = nextProps
    const {colKey, value:resourceRuleValue} = rowFilters[ruleIndex]
    if ( prevState.lastSeriesKey !== seriesKey || prevState.lastRuleIndex !== ruleIndex ) {
      let formatRule = (colKey >=0 ) ? derivedColAttributesArray[colKey].formatRule : 'defaultString'
      let internalDataType = (colKey >=0 ) ? derivedColAttributesArray[colKey].internalDataType : 'string'
      let overrides = { prefix:'', suffix:'', useCommas:false, forceFullPrecision:true }
      let formattingObj = getFormattingObj( formatRule, overrides )
      let isNotLegalNumber = (resourceRuleValue !== '' && internalDataType === 'number'
                              && isScryNumber(resourceRuleValue).numberType === 'DATA_ERR' )
      //  if internalDataType is illegal number, then display the saved resource string:
      if ( internalDataType === 'number' && isNotLegalNumber ) {
        var ruleValue = resourceRuleValue
      } else {
        // Otherwise use a formatted number as our initial value:
        ruleValue = numberFormat(resourceRuleValue, formattingObj, 'noHtml')
      }
      return {
        ruleValue,
        ruleValueColor: (isNotLegalNumber) ? 'red' : 'black',
        lastSeriesKey: seriesKey,
        lastRuleIndex: ruleIndex,
      }
    }
    return { }
  }



  handleOneFilterChange = (attribute: keyof FilterRule, newVal: number | boolean | string | FilterRelation, description: string ): void => {
    const {rowFilters, ruleIndex, resourcePath} = this.props
    const newRulesArray = [ ...rowFilters ]                // New rulesArray pointer (shallow clone)
    const clonedRule = { ...newRulesArray[ruleIndex] }     // New rulesArray[ruleIndex] Object pointer
    if (attribute === 'colKey') {                          // New rule parameter
      clonedRule.colKey = newVal as number
    } else if (attribute === 'enabled') {
      clonedRule.enabled = newVal as boolean
    } else if (attribute === 'key') {
      clonedRule.key = newVal as number
    } else if (attribute === 'relation') {
      clonedRule.relation = newVal as FilterRelation
    } else if (attribute === 'value') {
      clonedRule.value = newVal as string
    } 

    if ( attribute !== 'enabled' ) {
      clonedRule['enabled'] = true               // Force enabled to 'true' whenever rule is modified
    }
    newRulesArray[ruleIndex] = clonedRule
    const mods = [ { newVal: newRulesArray, path: resourcePath } ]
    reactDispatch(mods, description)
  }

  setValueColor = ( colKey: number, ruleValue:string ) => {
    const internalDataType = colKey >= 0 ? this.props.derivedColAttributesArray[colKey].internalDataType : 'none'
    if (internalDataType === 'number') {
      if (ruleValue !== '' && isScryNumber(ruleValue).numberType === 'DATA_ERR')  {
        this.setState({ruleValueColor: 'red'})
      } else {
        this.setState({ruleValueColor: 'black'})
      }
    } else {
      this.setState({ruleValueColor: 'black'})
    }

  }

  handleColKeyChange = (colOrderIndex:string) => {
    // The menu item keys are in the order of the visible table (colOrder)
    // We need the internal colKey !
    const {rowFilters, ruleIndex, colOrder} = this.props
    const colKey = colOrder[Number(colOrderIndex)]
    //console.log( colOrder, colOrderIndex, colKey )
    this.setValueColor( Number(colKey), rowFilters[ruleIndex].value )
    this.handleOneFilterChange('colKey',  Number(colKey), 'change filtering column')
  }

  handleEnableToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.handleOneFilterChange('enabled',  Boolean(e.target.checked), 'toggle filter rule enabled')
  }

  handleRelationChange = (selection: string) => {
    this.handleOneFilterChange('relation',  selection, 'change filtering relation')
  }

  handleDeleteRule = (e: React.MouseEvent<HTMLButtonElement>) => {
    const {rowFilters, ruleIndex, resourcePath} = this.props
    const filtersClone = deepClone(rowFilters)
    filtersClone.splice(ruleIndex, 1)
    const mods = [ { newVal: filtersClone, path: resourcePath }]
    reactDispatch(mods, 'deleted filtering rule')
  }


  triggerFilterValueChange = (): void => {
    // This function called to make a reactDispatch ONLY when typing has 'stopped'
    const {derivedColAttributesArray, rowFilters, ruleIndex} = this.props
    const {colKey} = rowFilters[ruleIndex]
    var internalDataType = (colKey >= 0) ? derivedColAttributesArray[colKey].internalDataType : 'none'
    const {ruleValue}   = this.state
    const scryNumber    = (internalDataType === 'number') ? isScryNumber(ruleValue) : getDefaultScryNumber()
    const dispatchValue = (internalDataType === 'number' && scryNumber.numberType !== 'DATA_ERR' )
        ? scryNumber.internalFormat  // dispatch the canonical internal number format when a valid number
        : ruleValue                  // Else dispatch whatever was typed and currently seen
    this.setValueColor( colKey, ruleValue )
    this.handleOneFilterChange('value',  dispatchValue, 'change filter value')
  }

  timeoutID: NodeJS.Timeout | string | number | undefined = undefined

  handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const options: CleanTextOptions = { maxCharCount: 120 }
    var {newValue, newSelectionStop} = cleanScryInputText2( e.target.value, Number(e.target.selectionEnd), options  )
    if ( e.target.value.length !== newValue.length ) {
      e.target.value = newValue.slice()  // This line also resets selection start to useless value.
      e.target.selectionStart = newSelectionStop
      e.target.selectionEnd   = newSelectionStop
    }
    this.setState({ruleValue:newValue})
    if (this.timeoutID) { clearTimeout(this.timeoutID) }
    this.timeoutID = setTimeout(this.triggerFilterValueChange, 300)
  }



  render () {
    const {colOrder, connectDragPreview, connectDragSource, connectDropTarget,
      isDragging, rowFilters, ruleCount, ruleIndex, derivedColAttributesArray,
      multiLineMode, filterLayout } = this.props
    //  console.log( '   FilterItem', rowFilters )
    const {colKey, enabled, relation} = rowFilters[ruleIndex]
    const isColumnDeleted = (colKey >= 0 && derivedColAttributesArray[colKey].isDeleted)
    var {ruleValue, ruleValueColor} = this.state
    if ( isColumnDeleted ) {
      ruleValue = 'Deleted Column'
      ruleValueColor = 'red'
    }

    // No delete button if this is lastRule of table rowfilters.
    // Always a delete button for all rules is 'common' or 'series' rules.
    const isDeleteButtonVisible = !(multiLineMode === '2line' && ruleIndex === rowFilters.length - 1)
    const commasOnlyFormattingObj = getFormattingObj( 'commasOnly' )
    const remainingRowsStr = numberFormat( String(ruleCount), commasOnlyFormattingObj, 'noHtml'  )
    const relationMenu = getRelationMenu( derivedColAttributesArray, colKey )
    const numRelationItems = Object.keys(relationMenu).length
    const isResourceRelationValid = Boolean( relationMenu[relation] )
    const colKeyMenuItems: MenuItems = {}
    var numMenuItems = 0
    colOrder.forEach( (thisKey,i) => {
      let name = derivedColAttributesArray[thisKey].colTitle
      let isDeleted = derivedColAttributesArray[thisKey].isDeleted
      if (isDeleted) { return }
      numMenuItems++
      // This list is in colOrder:
      colKeyMenuItems[i] = {
        displayedName: name,
        menuText: [name]
      }
    })
    const {DEBUG, WIDTH_COLMATCH_TEXT, WIDTH_RELATION_TEXT, WIDTH_CARET, WIDTH_VALUE, GAP_BETWEEN_INPUTS,
           WIDTH_REMAINING_TEXT, WIDTH_ENABLE_CONTROL, WIDTH_DND,
           WIDTH_DELETE_2lineMode, WIDTH_DELETE_1lineMode, TOTAL_WIDTH_1lineMode, TOTAL_WIDTH_2lineMode,
           FIRST_ROW_HEIGHT, GAP_BETWEEN_ROWS_1lineMode, SECOND_ROW_HEIGHT} = filterLayout

    // TWO line mode -> Used for the table's rowFilters.
    // ONE line mode -> Used for the plot's series editor.  (excludes enable, remaining rows, and Dnd;  delete moved to 1st line)
    const deleteWidth = (multiLineMode === '1line') ? WIDTH_DELETE_1lineMode : WIDTH_DELETE_2lineMode
    const totalWidth  = (multiLineMode === '1line') ? TOTAL_WIDTH_1lineMode  : TOTAL_WIDTH_2lineMode
    const gapAfterSelectors = (multiLineMode === '1line') ? GAP_BETWEEN_ROWS_1lineMode : 0

    const dropTarget = connectDropTarget (
        <div className={'rc_FilterRuleItem'} style={{ opacity: isDragging ? 0 : 1 }}>

{ /* First line:  colKey, relation, matchValue  */}
          <div style={{width: totalWidth, height:FIRST_ROW_HEIGHT + gapAfterSelectors, background:DEBUG? 'green' : 'unset'}} >

            <div className='centertext inlinediv'
              style={{ position: 'relative', top:0, left:0, // local coord system
                width: WIDTH_COLMATCH_TEXT + WIDTH_CARET,
                verticalAlign: 'top'}}>

              <EditorMenuButton
                menuItems={colKeyMenuItems}
                onSelection={this.handleColKeyChange}
                selectedValue={String(colOrder.indexOf(colKey))}
                height={FIRST_ROW_HEIGHT}
                widthDisplayText={WIDTH_COLMATCH_TEXT}
                widthDisplayCaret={WIDTH_CARET}
                textAlign={'center'}
                menuWidth={200}
                menuLeft={WIDTH_COLMATCH_TEXT+20}
                menuTop={-numMenuItems*17/2}    // 17px is the rowHeight used for singleline menu items
                displayValueColor={isColumnDeleted ? 'red' : 'black'}
                displayValueOverrideText={isColumnDeleted ? derivedColAttributesArray[colKey].colTitle: ''}
                />

            </div>

            <div className='centertext inlinediv'
              style={{
                position:'relative', top:0, left:0, // local coord system
                width: WIDTH_RELATION_TEXT + WIDTH_CARET, height: FIRST_ROW_HEIGHT,
                marginLeft: GAP_BETWEEN_INPUTS,
                marginRight: GAP_BETWEEN_INPUTS,
                verticalAlign: 'top'}}>

              <EditorMenuButton
                menuItems={relationMenu}
                onSelection={this.handleRelationChange}
                selectedValue={isResourceRelationValid ? relation : ''}
                height={FIRST_ROW_HEIGHT}
                widthDisplayText={WIDTH_RELATION_TEXT}
                widthDisplayCaret={WIDTH_CARET}
                textAlign={'center'}
                menuWidth={160}
                menuLeft={WIDTH_RELATION_TEXT+15}
                menuTop={-(46*numRelationItems)/2-6}  />

            </div>

            <div className='centertext inlinediv'
              style={{width: WIDTH_VALUE, height: FIRST_ROW_HEIGHT,
                verticalAlign: 'top'}}>

              <input
                onChange={this.handleValueChange}
                style={{
                  color: ruleValueColor,
                  width: WIDTH_VALUE, height: FIRST_ROW_HEIGHT,
                  border: 2, borderStyle:'inset', borderRadius: 4,
                  paddingLeft: 4,
                  borderColor: constants.COLHEADER_INPUT_BORDER_COLOR}}
                type='text'
                value={ruleValue}
                autoComplete='off'
                spellCheck='false'
              />

            </div>

{multiLineMode === '1line' &&
            <div className='righttext inlinediv grabcursor'
              style={{width: deleteWidth, height: FIRST_ROW_HEIGHT, background:DEBUG? 'pink' : 'unset',}}>
              <DeleteButton handleDeleteRule={this.handleDeleteRule} />
            </div>
}

          </div>

{ /* 2nd line:  enable control, remainingRows, delete control, and DnD control */}
{multiLineMode === '2line' &&

          <div style={{height:SECOND_ROW_HEIGHT, position:'relative', top:-2,}} >
            <div className='lefttext inlinediv'
              style={{width: WIDTH_ENABLE_CONTROL,
                background:DEBUG? 'pink' : 'unset',
              }}>
              <input
                style={{position:'relative', top:0}}
                id='enableRule'
                checked={enabled}
                onChange={this.handleEnableToggle}
                type='checkbox'
                autoComplete='off'
                spellCheck='false'
              />
              <label htmlFor='enableRule'>{'On'}</label>
            </div>

            <div className='centertext inlinediv'
              style={{
                width: WIDTH_REMAINING_TEXT,
                background: DEBUG? 'yellow' : 'unset',
                fontSize:14}}
            >Remaining Rows: {remainingRowsStr}</div>

{isDeleteButtonVisible &&
            <Fragment>
            <div className='righttext inlinediv grabcursor' style={{width: deleteWidth, background: DEBUG? 'pink' : 'unset',}}>
              <DeleteButton handleDeleteRule={this.handleDeleteRule} />
            </div>

          { connectDragSource(
            <div className='centertext inlinediv grabcursor' style={{width: WIDTH_DND, background: DEBUG? 'yellow' : 'unset',}}>
              <DndButton/>
            </div> )}
            </Fragment>
}

          </div>
}
        </div>
    )

    if (dropTarget) {
      return connectDragPreview(dropTarget)
    } else {
      return null
    }
  }

}

type DragObject = {
  originalIndex: number
  ruleKey: number
}

const mapDragSource: DragSourceSpec<Props, DragObject> = {
  beginDrag: (props: Props, monitor: DragSourceMonitor, component?: Component<Props>): DragObject => {
    const {ruleIndex, rowFilters} = props
    const ruleKey = rowFilters[ruleIndex].key
    return {
      originalIndex: ruleIndex,
      ruleKey,
    }
  },
  endDrag: (props: Props, monitor: DragSourceMonitor, component?: Component<Props>): void => {
    const {originalIndex, ruleKey} = monitor.getItem()
    props.moveItem(ruleKey, originalIndex, true)
  },
}

const mapSourceProps = (connect: DragSourceConnector, monitor: DragSourceMonitor): SourceProps => ({
  // Functions we need or wish to pass to the source component (as props)
  connectDragPreview: connect.dragPreview(),
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging(),
})

const mapDropTarget = {
  hover: (props: Props, monitor: DropTargetMonitor, component: Component<Props>): void => {
    const {findItemIndex, moveItem, ruleIndex, rowFilters } = props
    const overRuleKey = rowFilters[ruleIndex].key
    const {ruleKey: draggedRuleKey} = monitor.getItem()

    if (draggedRuleKey !== overRuleKey) {
      const overIndex = findItemIndex(overRuleKey)
      moveItem(draggedRuleKey, overIndex, false)
    }
  },

  canDrop: (props: Props, monitor: DropTargetMonitor): boolean => {
    return false
  }
}

const mapTargetProps = (connect: DropTargetConnector, monitor: DropTargetMonitor): TargetProps => (
  {
    connectDropTarget: connect.dropTarget(),
  }
)

export const DragFilterRuleItemType = 'TABLE_FILTER_RULE'

const FilterRuleItemDragSource = DragSource(DragFilterRuleItemType, mapDragSource, mapSourceProps)(FilterRuleItemRender)
const FilterRuleItem = DropTarget(DragFilterRuleItemType, mapDropTarget, mapTargetProps)(FilterRuleItemDragSource)
export default FilterRuleItem



// The delete button as a component
type PropsDelete = {
  handleDeleteRule: (e: React.MouseEvent<HTMLButtonElement>)=> void ,
}
class DeleteButton extends PureComponent<PropsDelete> {

  render( ) {
    const { handleDeleteRule } = this.props
    return (
        <button
          data-rh='Remove Filter Rule'
          onClick={handleDeleteRule}
          style={{
            background: 'none',
            border: 'none',
            cursor: 'pointer',
            outline: 'none',
            paddingTop: 1,  // emperically set
          }}
        >
          <svg
            className='inlinesvg'
            version='1.1'
            viewBox='0 0 1024 1024'
            xmlns='http://www.w3.org/2000/svg'
          >
              <path style={{fill:'red'}} d='M 1006,512 A 512,512 0 0 1 512,1006 512,512 0 0 1 16,512 512,512 0 0 1 512,16 512,512 0 0 1 1006,512 Z' />
              <path d='M 1024,512 A 512,512 0 0 1 512,1024 512,512 0 0 1 0,512 512,512 0 0 1 512,0 512,512 0 0 1 1024,512 Z m -64,0 A 448,448 0 0 0 512,64 448,448 0 0 0 64,512 448,448 0 0 0 512,960 448,448 0 0 0 960,512 Z' />
              <path d='M 256,448 H 768 V 576 H 256 Z' />
          </svg>
        </button>
    )
  }
}



// The DND button as a component
type PropsDND = {

}
class DndButton extends PureComponent<PropsDND> {

  render( ) {
    return (
        <svg
          className='inlinesvg'
          style={{cursor: 'grab',  height: '16px', width: '16px'}}
          version="1.1"
          viewBox="0 0 512 512"
          xmlns="http://www.w3.org/2000/svg"
          >
          <rect height="32" width="512" y="144" fill={'#090909'} />
          <rect height="32" width="512" y="240" fill={'#090909'} />
          <rect height="32" width="512" y="336" fill={'#090909'} />
        </svg>
    )
  }
}
