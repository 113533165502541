import invariant from 'invariant'
import React, { Component } from 'react'
import type { CSSProperties } from 'react'
import type { ResourceType } from '../jsonapi/types'
import { cleanScryInputText2 } from '../sharedFunctions/cleanScryInputText'
import type { CleanTextOptions } from '../sharedFunctions/cleanScryInputText'
import { measureText } from '../sharedFunctions/measureText'
import type { LightweightMod } from '../types'
import constants from './constants'     // This in the application's constants file.
import reactDispatch from './reactDispatch'             //'../sharedComponents/reactDispatch'

type OwnProps = {
    resourceStringValue: string,
    resourceID: string,
    resourceType: ResourceType,
    modPath: string,
    modMessage: string,
}
type DefaultProps= {
    isValueRequired: boolean,
    canEdit : boolean,
    cleanScryInputOptions: CleanTextOptions,
    inputStyleOptions: CSSProperties,
}
type Props = OwnProps & DefaultProps

type LocalState = {
    currentInput: string | undefined,
}


class EditInputString extends Component<Props, LocalState> {

  defaultProps: DefaultProps = {
    isValueRequired: false,
    canEdit : true,
    cleanScryInputOptions: {maxCharCount: 100},
    inputStyleOptions: {
      fontSize: 16,
      height: 24,
      width: 420
    }
  }

  // let null currentInput represent an 'unitialized' state.
  // It should be set to null when a component is mounted.
  // Also set to null when unmounting as apparently

  constructor(props: Props) {
    super(props)
    this.state={ currentInput: undefined }
  }

  static getDerivedStateFromProps = ( nextProps:Props, prevState:LocalState ) => {
    // We need to separate the first time input is rendered, for all subsequent renders.
    // On the first render (onMount & identified by currentInput === undefined) we
    //    initialize the local state to the value that was in our resource.
    // On subsequent changes to the displayed text, currentInput is modified
    //    by updateLocalState().
    // Periodically (last set to 2 second interval) currentInput is sent to
    //    the server as a resource change.
    // Same as:
    //    On mounting component, the resource value is King.
    //    Afterwards, value under edit (this module) is King.
    if (prevState.currentInput === undefined) {   // We just mounted! We initial
                                             // to property passed in by user.
      var {resourceStringValue} = nextProps
      resourceStringValue = !resourceStringValue ? '' : resourceStringValue
      return { currentInput: resourceStringValue }
    }
    return { }   // No state changes;  updateLocalState is in charge!
  }

  componentWillUnmount = () => {
    this.setState({currentInput : undefined})
    if ( this.timeoutID !== null ) { this.updateReactState() }
  }

  TIMEOUT_DELAY = 2000 // 2sec
  timeoutID: NodeJS.Timeout | string | number | undefined = undefined

  updateLocalState = ( e: React.ChangeEvent<HTMLTextAreaElement>) : void => {
    // Step #1 : Do not allow user to type any more characters than will
    // fit in the input string control.   This allows a variable number of
    // characters, but restrict the total rendered width.
    // Whether this string fits neatly across the top of the rendered table
    // is up the the user, and what they have choosen for title font size.
    // And what user as choosen as thier browser width.   If title is
    // too long for the table layout space, it is centered, but truncated
    // on both ends.
    var inputString = e.target.value
    const {paddingLeft, paddingRight} = constants.STYLE_PALETTE_TEXT_INPUT_STYLES
    const widthOfInputControl = Number(this.props.inputStyleOptions.width) - Number(paddingLeft) - Number(paddingRight) - 25
    const fontSizeOfInputControl = this.props.inputStyleOptions.fontSize
    if ( process.env.NODE_ENV === 'development' ) {
      invariant ( widthOfInputControl, 'EditStringInput component MUST be passed a style.width attribute')
    }
    var inputStringPxLength = measureText( e.target.value, `${fontSizeOfInputControl}px`, 'normal' )
    while (inputStringPxLength > widthOfInputControl ) {
      inputString = inputString.slice(0,inputString.length-1 )
      inputStringPxLength = measureText( inputString, `${fontSizeOfInputControl}px`, 'normal' )
    }
    var cursorPosition = Number(e.target.selectionEnd)   // Save cursor position value prior to resetting value.
    cursorPosition = Math.min( cursorPosition, inputString.length )

    var {newValue, newSelectionStop} =
           cleanScryInputText2( inputString, cursorPosition, this.props.cleanScryInputOptions  )
    e.target.value = inputString.slice()        // ALWAYS set the textEdit value BEFORE selectionStart/Stop
    e.target.selectionStart = newSelectionStop  // Changing it afterwards resets start/stop to zero.
    e.target.selectionEnd   = newSelectionStop

    this.setState( {currentInput: newValue} )

    // We will update every two seconds.
    // Behavior if we clear this.timeoutID here:
    //     -- Update occurs two seconds after LAST typed character
    // Behavior if we clear this.timeoutID ONLY by executing updateReactState():
    //     -- Update occurs two seconds after 1st typed character
    if ( this.timeoutID ) { return }  // timer already in progress!
    // Else start the timer.
    this.timeoutID = setTimeout( this.updateReactState, this.TIMEOUT_DELAY)
  }

  updateReactState = ():void => {
    this.timeoutID = undefined;  // Our signal to updateLocalState to restart a new timer.
    const {modPath, modMessage, resourceType, resourceID} = this.props
    const mods: LightweightMod[] = [{
      newVal: this.state.currentInput,
      path: modPath
    }]
    // We choose NOT to use action group (for DnD, Scrolling etc.)
    // Because we are updating the resource every TIMEOUT_DELAY.
    // Hence backup means: 'backup the last two seconds of editing'.
    reactDispatch(mods, modMessage, '', resourceType, resourceID)
  }

  render() {
    const {currentInput} = this.state
    const stdAppInputStyle = constants.STYLE_PALETTE_TEXT_INPUT_STYLES
    const textStyle={ ...stdAppInputStyle, overflow:'hidden', ...this.props.inputStyleOptions }
    return (
        <textarea className={'rc_EditInputString'}
          onChange={this.updateLocalState}
          style={textStyle}
          value={currentInput}
          autoComplete='off'
          spellCheck='false'
          readOnly={!this.props.canEdit}
        />
    )
  }
}

export default EditInputString
