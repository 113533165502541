import {Component}          from 'react'
import type {TableComputedData} from '../computedDataTable/getDefaultTableComputedData' 
import SideBarParent2 from '../viewSideBar/SideBarParent2'
import TableTabSizing from '../viewSideBar/Table_TabSizing'
import TableTabShading from '../viewSideBar/Table_TabShading'
import TableTabPublish from '../viewSideBar/Table_TabPublish'
import { SessionState } from '../appCode/getDefaultSessionState'

import {tableSideBarAnimationStart,
        tableSideBarAnimation,
        tableSideBarAnimationStop}  from '../viewTable/tableResponsiveState'

type Props = {
  activeView: string, 
  sessionState: SessionState,
  tableComputedData : TableComputedData,
  sourcePlotXyComputedState : string, 
  workingPlotXyComputedState : string, 
  closeNavColumnPopups: ()=>void, 
}

export default class TtSideBarAnimation extends Component <Props> {

  render () {
    const {tableComputedData, sessionState} = this.props
    return (
      <div>
          <SideBarParent2 
          isOpen={sessionState.isSideBarOpen.tableView}
          tabIndex={sessionState.sideBarTabIndex.tableView}
          setIsOpenPath  ={'isSideBarOpen.tableView'}
          setTabIndexPath={'sideBarTabIndex.tableView'}
          tableComputedData={tableComputedData}
          parentAnimationStart= { ()     => tableSideBarAnimationStart( tableComputedData )}
          parentAnimation     = { (width)=> tableSideBarAnimation(width)}
          parentAnimationStop = { ()     => tableSideBarAnimationStop() }
          showChildOnlyIfCanEdit={[false,false,true]}
          tabChildren = {[
              <TableTabSizing
                  tableComputedData={tableComputedData}
                  key={0} tabName1={'Table'} tabName2={'Sizing'} />, 
              <TableTabShading
                  tableComputedData={tableComputedData}
                  key={1} tabName1={'Table'} tabName2={'Shading'} />, 
              <TableTabPublish
                  tableComputedData={tableComputedData}
                  key={2} tabName1={'Publish'} tabName2={''} />
          ]} />


      </div>
  )} 
}


