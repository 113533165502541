import {floatingPaletteLayoutDefault}   from './type'
import {FLOATING_PALETTE_TITLE_BAR_HEIGHT} from '../sharedComponents/constants'
import { OptionalHTMLDivElement, OptionalSVGElement } from '../types'
//import calcHighlightLine from './calcHighlightLine'


var rStateFloatingPalette = {

  // Parent Node of each column subunit.
  // One column node per header column
  // RowsPerGroup nodes per data column
  floatingPaletteDomNode  : null as OptionalHTMLDivElement,
  editorContainerDomNode  : null as OptionalHTMLDivElement,
  highlightConnectingLine : null as OptionalSVGElement,


  // This is the equivalent of widthObj, heightObj, styleObj -- except for the floating palette.
  layoutObj : floatingPaletteLayoutDefault,

  // Does the next frame have same or different attributes from last frame?
  lastLeft      : 0,
  lastTop       : 0,
  lastWidth     : 0,
  lastHeight    : 0,
  lastHighlight : '',
  lastVisibility: 'unset',    // values 'hidden' or 'unset'

  // These are the attributes before we begin any responsive action.
  // They need to be the attributes after the responsive action in order
  // for the react virtual dom comparison to work properly.
  startLeft      : 0,
  startTop       : 0,
  startWidth     : 0,
  startHeight    : 0,
  startHighlight : '',
  startVisibility: 'unset',   // values 'hidden' or 'unset'

  hideFloatingPalette: ( ) => {
    // This function is for animations where at the completion of the
    // animation, react will close potentially open floating palettes.
    const {floatingPaletteDomNode, highlightConnectingLine } = rStateFloatingPalette
    if ( floatingPaletteDomNode && highlightConnectingLine ) {
      floatingPaletteDomNode.style.visibility = 'hidden'
      highlightConnectingLine.setAttribute( 'points', '')
    }
  },

  // Get the starting attributes directly from the domTree.
  synchStart : ( ) => {
      const {floatingPaletteLeft, floatingPaletteTop, floatingPaletteWidth,
            floatingPaletteHeight, highlightLine} = rStateFloatingPalette.layoutObj
      //console.log( 'Call to rStateFloatingPalette.synchStart', rStateFloatingPalette.layoutObj )
      rStateFloatingPalette.lastLeft  = floatingPaletteLeft
      rStateFloatingPalette.startLeft = floatingPaletteLeft
      rStateFloatingPalette.lastTop   = floatingPaletteTop
      rStateFloatingPalette.startTop  = floatingPaletteTop
      rStateFloatingPalette.lastVisibility = 'unset'
      rStateFloatingPalette.startVisibility = 'unset'
      rStateFloatingPalette.lastWidth   = floatingPaletteWidth
      rStateFloatingPalette.startWidth  = floatingPaletteWidth
      rStateFloatingPalette.lastHeight  = floatingPaletteHeight
      rStateFloatingPalette.startHeight = floatingPaletteHeight
      rStateFloatingPalette.lastHighlight = highlightLine
      rStateFloatingPalette.startHighlight = highlightLine

  },

  // Reset virtual react dom to the initial conditions.
  // These will NOT SURVIVE to the next react rendered frame.
  // Because the react state change will overwrite these with
  // the next react state (which should be identical to last responsive state.)
  synchStop : ( ) => {
    //console.log( 'Call to rStateFloatingPalette.synchStop' )
    rStateFloatingPalette.redraw (
      rStateFloatingPalette.startLeft,
      rStateFloatingPalette.startTop,
      rStateFloatingPalette.startWidth,
      rStateFloatingPalette.startHeight,
      rStateFloatingPalette.startHighlight,
      rStateFloatingPalette.startVisibility )
  },


  redraw :  ( left:number, top:number, width:number, height:number,
               highlight:string, visibility:string ):void => {

      //console.log( 'Call to rStateFloatingPalette.redraw' )
      const {highlightConnectingLine, floatingPaletteDomNode, editorContainerDomNode, lastLeft, lastTop,
                lastHighlight, lastVisibility, lastWidth, lastHeight } = rStateFloatingPalette

      if (highlightConnectingLine && floatingPaletteDomNode && editorContainerDomNode ) {
        if ( left !== lastLeft || top !== lastTop ) {
          rStateFloatingPalette.lastLeft = left
          rStateFloatingPalette.lastTop  = top
          floatingPaletteDomNode.style.transform = `translate(${left}px,${top}px)`
        }
        if ( highlight !== lastHighlight  ) {
         highlightConnectingLine.setAttribute( 'points', highlight)
         rStateFloatingPalette.lastHighlight = highlight
        }
        if (visibility !== lastVisibility ) {
          floatingPaletteDomNode.style.visibility = visibility
          rStateFloatingPalette.lastVisibility = visibility
        }
        if ( width !== lastWidth ) {
          floatingPaletteDomNode.style.width = `${width}px`
          rStateFloatingPalette.lastWidth = width
        }
        if ( height !== lastHeight ) {
          editorContainerDomNode.style.height = `${height - FLOATING_PALETTE_TITLE_BAR_HEIGHT}px`
          floatingPaletteDomNode.style.height = `${height}px`
          rStateFloatingPalette.lastHeight = height
        }
      }
  },


}

export default rStateFloatingPalette
