import type { AnyAction, Dispatch } from '@reduxjs/toolkit'
import type { RootState } from '../redux/store'
import { PureComponent } from 'react'
import { connect } from 'react-redux'
import { ParentType } from '../jsonapi/types'
import { createRedoAction, createUndoAction, getRedoTitle, getUndoTitle } from '../redux/undoReducer'
// import UndoHistory from './UndoHistory'

type UndoRedoButtonOwnProps = {
  disabled: boolean
  isRedo: boolean
  onClick: () => void
  title: string | null
}

class UndoRedoButton extends PureComponent<UndoRedoButtonOwnProps> {
  render() {
    const { disabled, isRedo, onClick, title } = this.props
    const fillColor = disabled ? '#666' : 'white'
    return (
      <button
        className={'rc_UndoRedoButtons'}
        data-rh={title}
        data-rh-at='bottom'
        onClick={disabled ? undefined : onClick}
        style={{
          display: 'inline-block',
          width: 36,
          //background: 'green',
          background: 'none',
          border: 'none',
          cursor: 'pointer',
          outline: 'none',
        }}
      >
        <svg
          height='20px'
          version='1.1'
          viewBox='0 0 256 256'
          width='20px'
          xmlns='http://www.w3.org/2000/svg'
        >
          {!isRedo &&
            <path
              d='m 71,151 41,41 H 0 V 80 L 47,127 C 71,108 100,96 133,96 c 56,0 103,34 123,80 l -32,16 c -15,-38 -50,-63 -91,-64 -24,1 -45,9 -62,23 z'
              style={{ fill: fillColor }}
            />
          }
          {isRedo &&
            <path
              d='m 185,151 -41,41 H 256 V 80 l -47,47 C 185,108 156,96 123,96 67,96 20,130 0,176 l 32,16 c 15,-38 50,-63 91,-64 24,1 45,9 62,23 z'
              style={{ fill: fillColor }}
            />
          }
        </svg>
      </button>
    )
  }
}

// class UndoHistoryButton extends PureComponent<{
//   disabled: boolean,
//   onClick: (e: SyntheticMouseEvent<HTMLButtonElement>) => void,
// }> {
//   render () {
//     const {disabled, onClick} = this.props
//     const fillColor = disabled ? 'gray' : 'white'
//     return (
//       <button
//         data-rh='Undo History'
//         data-rh-at='bottom'
//         onClick={disabled ? undefined : onClick}
//         style={{
//           background: 'none',
//           border: 'none',
//           cursor: 'pointer',
//           outline: 'none',
//         }}
//       >
//         <svg
//           height='20px'
//           version='1.1'
//           viewBox='0 0 256 256'
//           width='20px'
//           xmlns='http://www.w3.org/2000/svg'
//         >
//           <path
//             d="M 85.25,85.25 116,116 V 32 H 32 L 67.25,67.25 C 53,85.25 44,107 44,131.75 c 0,42 25.5,77.25 60,92.25 l 12,-24 C 87.5,188.75 68.75,162.5 68,131.75 68.75,113.75 74.75,98 85.25,85.25 Z"
//             style={{fill: fillColor}}
//           />
//           <path
//             d="M 170.75,170.75 140,140 v 84 h 84 L 188.75,188.75 C 203,170.75 212,149 212,124.25 212,82.25 186.5,47 152,32 l -12,24 c 28.5,11.25 47.25,37.5 48,68.25 -0.75,18 -6.75,33.75 -17.25,46.5 z"
//             style={{fill: fillColor}}
//           />
//         </svg>
//       </button>
//     )
//   }
// }




type OwnProps = {
  parentId: string
  parentType: ParentType
}

type StateProps = {
  redoTitle: string | null
  undoTitle: string | null
}

type DispatchProps = {
  redo: () => void
  undo: () => void
}

type Props = OwnProps & StateProps & DispatchProps

type LocalState = {
  // showUndoHistory: boolean
}

class UndoRedoButtonsRender extends PureComponent<Props, LocalState> {
  state = {
    // showUndoHistory: false,
  }

  // handleHistoryButtonClick = (e: SyntheticMouseEvent<HTMLButtonElement>): void => {
  //   this.setState({showUndoHistory: true})
  // }

  // closeHistory = (): void => {
  //   this.setState({showUndoHistory: false})
  // }

  parentDomNode: HTMLDivElement | null = null
  initRef = (element: HTMLDivElement | null): void => {
    this.parentDomNode = element
  }

  redoHandler = (): void => {
    const { redo } = this.props
    redo()
  }

  undoHandler = (): void => {
    const { undo } = this.props
    undo()
  }

  render() {
    const {/*parentId, parentType, */ redoTitle, undoTitle } = this.props
    // const {showUndoHistory} = this.state
    return (
      <div
        ref={this.initRef}
        style={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'nowrap',
          paddingRight: '9px',
        }}
      >
        {/*showUndoHistory &&
          <UndoHistory closeHistory={this.closeHistory} parentDomNode={this.parentDomNode} parentId={parentId} parentType={parentType} />
        */}
        <UndoRedoButton
          disabled={!undoTitle}
          isRedo={false}
          onClick={this.undoHandler}
          title={undoTitle}
        />
        {/*
        <UndoHistoryButton
          onClick={this.handleHistoryButtonClick}
          disabled={!undoTitle && !redoTitle}
        />
        */}
        <UndoRedoButton
          disabled={!redoTitle}
          isRedo={true}
          onClick={this.redoHandler}
          title={redoTitle}
        />
      </div>
    )
  }
}

const mapState = (state: RootState, ownProps: OwnProps): StateProps => {
  const { parentType, parentId } = ownProps
  const redoTitle = getRedoTitle(state, parentType, parentId)
  const undoTitle = getUndoTitle(state, parentType, parentId)
  return {
    redoTitle,
    undoTitle,
  }
}

const mapDisp = (dispatch: Dispatch<AnyAction>, ownProps: OwnProps): DispatchProps => (
  {
    redo: (): void => {
      dispatch(createRedoAction({ parentType: ownProps.parentType, parentId: ownProps.parentId }))
    },
    undo: (): void => {
      dispatch(createUndoAction({ parentType: ownProps.parentType, parentId: ownProps.parentId }))
    },
  }
)

const UndoRedoButtons = connect(mapState, mapDisp)(UndoRedoButtonsRender)
export default UndoRedoButtons
