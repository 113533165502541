// @flow
import React from 'react'
import constants          from '../sharedComponents/constants'
import type {ScryFormula} from './formulaTypes'

function colorCodedSpan( colorIn:string, substrg:string, charIndex ) {
  let color = colorIn.toLowerCase()
  if ( color === 'redchar' ) {   // Coloring for text errors (red text)
    return <font key={charIndex} style={{color:'#ff0000'}}>{substrg}</font>
  }
  else if ( color === 'redback' ) {   // Coloring for indent errors (red background)
    return <font key={charIndex} style={{background:'#ffaaaa'}}>{substrg}</font>
  }
  else if ( color === 'blue' ) {   // Coloring for colNames
    return <font key={charIndex} style={{background:constants.SCRY_VERYLIGHT_BLUE}}>{substrg}</font>
  }
  else if ( color === 'bluered' ) {  // Coloring for erroneous colNames
    return <font key={charIndex} style={{background:constants.SCRY_VERYLIGHT_BLUE, color:'#aa0000'}}>{substrg}</font>
  }
  else if ( substrg === '' ) {   // line break; no coloring
    return <br key={charIndex}/>
  }
  // text line with no coloring (default: color is black font on transparent background)
  // Typical exit path, except for erroneous lines or empty line feed.
  return <font key={charIndex}>{substrg}</font>
}


type ThisProps = {
  scryExpression: ScryFormula
}

const ColorCodedFormula = ( props: ThisProps) => {

  // This function needs to create an HTML colored string that exactly
  // overlays the input text format:
  //    -- Must replace text spaces with non-breaking HTML spaces.
  //    -- Must overlay the formulaStrings text exactly.
  //    -- Must NOT use raised exponents or thin spaces, ( although both are used in the display format)
  //    -- Must use red text for errors.  As identified in highlightArray.
  //    -- Must use blue background for column names.
  //    -- Must support potentially overlapping blue/red substrings.
  //    -- Must use red background (as opposed to red text) when error string is all spaces (indent errors)

  //  Approach:
  //      This next algorithm works 'per formula line'
  //      Use the formulaStrings.  (easier than any of the substrings format)
  //      Create a color map array, same length as formulaStrings, one color per character.
  //        - For example, a ten char expression    => [n,n,n,n,n,n,n,n,n,n]     // uncolored
  //        - Now color 'red' chars start:2, end:6  => [n,n,r,r,r,r,n,n,n,n]
  //        - Now color 'blue chars start:4, end:8  => [n,n,r,r,p,p,b,b,n,n]   where p maps to redBlue overlap
  //      From original string, and color array, we have everything we need to build
  //      the colorCodedHTML. Could be done many ways, but this seems to be easiest.

  // let 'none', 'red', 'blue', and 'redBlue'  refer to each individual character's color/background

  const {scryExpression} = props
  const {formulaStrings, highlightArray, formulaSubStrings} = scryExpression
  const result = []
  formulaStrings.forEach( (thisLine, lineNum) => {

        // This makes sure completely blank lines have some set of characters to underline.
        // This characters only effect the visual background text.  Not the set of potentially
        // missing characters in the foreground edit text.
        // Purpose, to make sure we can 'underline' a blank line to mark a position
        // between current lines.
        // THIS CODE IS NO LONGER NEEDED AS REMOVED THE NEED FOR UNDERLINES.
           //const thisLine = ( thisLineIn.trim().length === 0 ) ? '        ' : thisLineIn

        const charColorArr = new Array( thisLine.length ).fill ('none')

        // Fill in those erroneous 'redChar' or 'redBack' positions:
        highlightArray.forEach( thisHighlight => {
          if ( thisHighlight.lineNum !== lineNum ) { return }
          for (let j=thisHighlight.start; j<thisHighlight.end; j++) {
            charColorArr[j] = (thisHighlight.colorMode) ? thisHighlight.colorMode : 'redChar'
          }
        })

        // Fold in the blue colNames characters:
        // The blue colName background will sit 'under' the black or red character color.
        // Character color is independent of background color!
        // However the 'redBack' colorMode (red background) will have precedence over
        // a colName blue background.
        formulaSubStrings[lineNum].forEach ( (thisMatch,index) => {
          if ( thisMatch.name === 'colName' ) {

            // Next four lines don't color left/right space that may have been
            // captured as part of the colName tagging process.  Easier to ignore
            // these spaces here, rather than create tagging rules that avoid having
            // spaces allocated to colNames.
            var colorStart = thisMatch.start
            while ( thisLine[colorStart] === ' ' && thisLine[colorStart+1] === ' ') { colorStart++ }
            var colorEnd = thisMatch.end
            while ( thisLine[colorEnd-1] === ' ' && thisLine[colorEnd-2] === ' ') { colorEnd-- }

            for (let j=colorStart; j<colorEnd; j++) {
              // A red background (syntax error) has priority of the blue (colName) background.
              if ( charColorArr[j] === 'redBack' ) continue
              // First char position for column names uses UpperCase first character.
              // Because we want to know (independent of the colorChanges) where a colName starts.
              if (j === colorStart) {
                charColorArr[j] = ( charColorArr[j] === 'redChar' ) ? 'Bluered' : 'Blue'
              } else {
                charColorArr[j] = ( charColorArr[j] === 'redChar' ) ? 'bluered' : 'blue'
              }
            }

          }
        })

        // Walk through the colored character array.
        // The index of any color change identifies where HTML <font> controls belong.
        var priorColor = (charColorArr.length > 0) ? charColorArr[0] : 'none'   // Initialize prior color to first character color.
        var priorStart = 0  // Character index where this current color began
        var spanList = []   // The HTML string we will ultimately return.

        for (let i=0; i<charColorArr.length; i++ ) {
          let currentColor = charColorArr[i]

          // Special case of two consecutive colNames.  They will appear as one continuous blue
          // block, UNLESS we delineate some type of line between the two.  Unfortunately, the
          // css font object allows to set the background blue, but can't find a way to mark
          // either the left or right edges.  Anything other than continuous blue background
          // would be an improvement.
          // Best alternative (so far) is to insert a hairline space between the two colNames.
          // This hairline only exists in the overlaid color text-- but NOT in the underlying
          // text edit control. But it is thin enough that it doesn't misalign the cursor too
          // much. (At least if we are inserting only a single hairline.)
          // So check for the case where redblue->Redblue  OR   blue->Blue
          // These are the cases of two potentially abutted colNames.
          // If this case, then insert a hairline space. (unicode: \u200A )
          let isTwoAdjacentColNames =  ( priorColor[0].toLowerCase() === 'b' && currentColor[0] === 'B' ) ? true : false

          // Case: no state change -- continue with the next character.
          if ( priorColor.toLowerCase() === currentColor.toLowerCase() && !isTwoAdjacentColNames ) { continue }

          // Fall through Case: A color change!
          var thisSubstring = thisLine.slice( priorStart, i).replace(/ /g, constants.nonBreakingSpace )
          if ( isTwoAdjacentColNames ) { thisSubstring += constants.thinSpaceChar }
          spanList.push( colorCodedSpan(priorColor, thisSubstring, i) )
          priorColor = currentColor
          priorStart = i
        }

        // Slightly different behavior for last substring
        thisSubstring = thisLine.slice( priorStart, thisLine.length).replace( / /g, constants.nonBreakingSpace )
        spanList.push( colorCodedSpan(priorColor, thisSubstring, -1) )
        result[lineNum] = spanList
  })

  return (
    <div className={'rc_ColorCodedFormula'}
      style={{ fontSize:constants.COL_FORMULA_EDITOR_FONT_SIZE, color:'black', background:'unset' }}>
      { result.map( (thisLine,lineNum) => {
        let temp = scryExpression.highlightLineNums
        let opacity = (temp.length > 0 && !temp.includes(lineNum)) ? 0.3 : 1
        let textDecoration = (scryExpression.highlightUnderline === lineNum) ? '#ff0000 underline' : 'unset'
        let lineStyle = {opacity, textDecoration}
        return < SpanListComponent key={`${lineNum} formulaStrings[lineNum]`} result={result} lineNum={lineNum} lineStyle={lineStyle} />
      })}
    </div>
  )
}


type Props3 = {
  result: [],
  lineNum :number,
  lineStyle: Object
}

// This function takes a set of colored substrings,
// and places them sequentially within a single line (div).
function SpanListComponent( props:Props3 ) {
  var {result, lineNum, lineStyle} = props
  return (
    <div style={lineStyle}>
        { result[lineNum].map( thisSpan => {
          return( thisSpan )
        })}
    </div>
  )
}

export default ColorCodedFormula
