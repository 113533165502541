import type { OptionalHTMLDivElement } from '../types'

import {PureComponent} from 'react'
import invariant        from 'invariant'
import dynamics         from 'dynamics.js'
import rStateFloatingPalette from '../floatingPalette/rStateFloatingPalette'
//import type {ColValues} from '../types'
import {startTimer, logTime, stopTimer} from '../sharedFunctions/timer'
import {wasSuspendPointerEventsSuccessful,
  restorePointerEvents} from '../sharedFunctions/pointerEvents'

const SIZE = 20
const OPACITY = [0.2, 0.4, 0.6, 0.8]
const DEBUG = false
const TIMER = false

type ShutterTypeID = 'locked' | 'moving' | 'pinned' | 'rowNum'

type ShutterTypeMap = {
  [key in ShutterTypeID]: OptionalHTMLDivElement
}
// state values needed to manage the shutter domNodes
var shutter: ShutterTypeMap = {
  moving  : null,
  rowNum  : null,
  locked  : null,
  pinned  : null,
}

// State variables for the sorting algorithm
var dataHeight   : number = 0   // property value into the Shutter Component; h.dataAllocated
var pinnedHeight : number = 0   // property value into the Shutter Component; h.dataAllocated


type Props = {
  windowHeight: number,
  typeID: ShutterTypeID,
  backgroundColor: string,
  doDataRowsExist: boolean
}
class Shutter extends PureComponent<Props> {

  refShutterNode = (element: OptionalHTMLDivElement): void => {
    shutter[this.props.typeID] = element
  }

  render( ) {
    const {windowHeight, backgroundColor, doDataRowsExist } = this.props
    //height = dataAllocated // This height needed for event driven animations.
    //console.log( 'call to Shutter component', typeID, height)
    if ( !doDataRowsExist ) return null

    return (
        <div className={'rc_Shutter'}
          ref={ this.refShutterNode }
          style={{
            width:'100%',
            position: 'relative',
            top: windowHeight ,  // Nominally open position
          }}
        >
            <div style={{height:SIZE, opacity:OPACITY[0], backgroundColor }}/>
            <div style={{height:SIZE, opacity:OPACITY[1], backgroundColor }}/>
            <div style={{height:SIZE, opacity:OPACITY[2], backgroundColor }}/>
            <div style={{height:SIZE, opacity:OPACITY[3], backgroundColor }}/>
            <div style={{height:windowHeight ,opacity:1, backgroundColor }}/>
            <div style={{height:SIZE, opacity:OPACITY[3], backgroundColor }}/>
            <div style={{height:SIZE, opacity:OPACITY[2], backgroundColor }}/>
            <div style={{height:SIZE, opacity:OPACITY[1], backgroundColor }}/>
            <div style={{height:SIZE, opacity:OPACITY[0], backgroundColor }}/>
        </div>
    )
  }
}


type OwnProps = {
  widthRowNumbers : number,
  lockedAllocated : number,
  movingAllocated : number,
  dataAllocated : number,
  dataTop : number,
  pinnedRowsTop: number,
  pinnedHeight: number,
  rowNumbersLeft : number,
  lockedLeft : number,
  movingLeft : number,
  backgroundColor: string,
  doDataRowsExist: boolean,
}

export default class ActionSort extends PureComponent<OwnProps> {
  render ( ) {
    const {
      widthRowNumbers, lockedAllocated, movingAllocated, dataAllocated,
      dataTop, rowNumbersLeft, lockedLeft, movingLeft, backgroundColor,
      pinnedRowsTop, doDataRowsExist} = this.props
    const background = (DEBUG) ? 'green' : backgroundColor
    //console.log ('Call to rendering Action Sort', dataTop, dataAllocated )
    dataHeight = dataAllocated  // This height needed for event driven animations.
    pinnedHeight = this.props.pinnedHeight
    return (
      <div className={'rc_ActionSort'}
        style={{
          position:'absolute',
          top:0, left:0,
          height: '100%',
          width:  '100%',
          overflow: 'hidden',
          //background: 'white',
        }}
      >
        <div className={'MovingContainer'}
          style={{
            position: 'relative',
            width : movingAllocated,
            height: dataAllocated,
            top   : dataTop,
            left  : movingLeft,
            //background: 'green',
            overflow: 'hidden',
          }}
        >
            <Shutter
              windowHeight={dataAllocated}
              typeID={'moving'}
              backgroundColor={background}
              doDataRowsExist={doDataRowsExist}
            />
        </div>

        <div className={'DataRowNumContainer'}
          style={{
            position: 'absolute',
            width : widthRowNumbers,
            height: dataAllocated,
            top   : dataTop,
            left  : rowNumbersLeft,
            overflow: 'hidden',
          }}
        >
            <Shutter
              windowHeight={dataAllocated}
              typeID={'rowNum'}
              backgroundColor={background}
              doDataRowsExist={doDataRowsExist}
            />
        </div>



        <div className={'LockedContainer'}
          style={{
            position: 'absolute',
            width : lockedAllocated,
            height: dataAllocated,
            top   : dataTop,
            left  : lockedLeft,
            overflow: 'hidden',
          }}
        >
            <Shutter
              windowHeight={dataAllocated}
              typeID={'locked'}
              backgroundColor={background}
              doDataRowsExist={doDataRowsExist}
            />
        </div>


        <div className={'PinnedRowNumContainer'}
          style={{
            position: 'absolute',
            width : widthRowNumbers,
            height: pinnedHeight,
            top   : pinnedRowsTop,
            left  : rowNumbersLeft,
            overflow: 'hidden',
            //background: 'green',
          }}
        >
            <Shutter
              windowHeight={pinnedHeight}
              typeID={'pinned'}
              backgroundColor={background}
              doDataRowsExist={doDataRowsExist}
            />
        </div>


      </div>
    )
  }
}


const redrawShutter = ( offsetData: number, offsetPinned: number ) : void => {
  const dataOffsetPx = `${offsetData}px`
  const pinnedOffsetPx = `${offsetPinned}px`
  if (shutter.moving) { shutter.moving.style.top = dataOffsetPx }
  if (shutter.locked) { shutter.locked.style.top = dataOffsetPx }
  if (shutter.rowNum) { shutter.rowNum.style.top = dataOffsetPx }
  if (shutter.pinned) { shutter.pinned.style.top = pinnedOffsetPx }
}

const durationOfAnimation = (DEBUG) ? 2000 : 150  // Open/close speed in msec  ( 250 nominal )

type ShutterAnimationObj = {
  offsetData: number
  offsetPinned: number
}

const SORT_ANIMATION_NAME = 'Sort Column'

const shutterAnimate = ( command: string, direction: string, reactDispatchFunc: () => void ) : void => {
  //console.log ('Call to animate shutter:', command, direction)
  rStateFloatingPalette.hideFloatingPalette( ) // next react render floating palette will be closed.
  const closePosition = -4*SIZE
  switch( `${command}-${direction}` ) {
    case 'open-topToBottom':
      var startDataOffset = closePosition
      var stopDataOffset = dataHeight
      var startPinnedOffset = closePosition
      var stopPinnedOffset = pinnedHeight
      break
    case 'open-bottomToTop':
      startDataOffset = closePosition
      stopDataOffset = -(dataHeight+8*SIZE)
      startPinnedOffset = closePosition
      stopPinnedOffset = -(pinnedHeight+8*SIZE)
      break
    case 'close-topToBottom':
      startDataOffset = -(dataHeight+8*SIZE)
      stopDataOffset = closePosition
      startPinnedOffset = -(pinnedHeight+8*SIZE)
      stopPinnedOffset = closePosition
      break
    case 'close-bottomToTop':
      startDataOffset = dataHeight
      stopDataOffset = closePosition
      startPinnedOffset = pinnedHeight
      stopPinnedOffset = closePosition
      break
    default:
      invariant (false, 'Illegal argumuments passed to shutterAnimate' )
  }

  dynamics.animate (
    // First two args are Start && Stop values
    { offsetData: startDataOffset,  offsetPinned: startPinnedOffset},
    { offsetData: stopDataOffset,   offsetPinned: stopPinnedOffset},
    { // Options:
      change: (obj: ShutterAnimationObj)=> { redrawShutter ( obj.offsetData, obj.offsetPinned ) },
      complete:( ) => {
        //console.log( 'shutter animation complete', command ) 
        if (command === 'close' ) {
            logTime( 'id_Sort', 'End Close Shutter animation.' )
            reactDispatchFunc( )  // state change; tableComputedData execution; sorted table rendered;
            window.requestAnimationFrame(  ()=>{
              logTime( 'id_Sort', 'Begin Open Shutter animation.' )
              shutterAnimate( 'open', 'bottomToTop', () => {} )
            })
        }
        if (command === 'open') {
            restorePointerEvents(SORT_ANIMATION_NAME)
            logTime( 'id_Sort', 'End of Open Shutter animation.' )
            stopTimer( 'id_Sort' )    
        }
      },
      duration: durationOfAnimation,
    }
  )
}


export const sortHandler = ( reactDispatchFunc: () => void ) : void => {
  if (!wasSuspendPointerEventsSuccessful( SORT_ANIMATION_NAME )) { return }
  if (TIMER) { startTimer( 'id_Sort') }
  logTime( 'id_Sort', 'Start of Close Shutter animation.' )
  shutterAnimate( 'close', 'topToBottom', reactDispatchFunc)
}
