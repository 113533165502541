import type { ChangeEvent, CSSProperties, ReactNode } from 'react'
import type { FormattingObj } from '../sharedFunctions/numberFormat'
import type { ScryNumber } from '../sharedFunctions/parseScryInputStringTypes'

import {Component, Fragment} from 'react'
import reactDispatch          from '../sharedComponents/reactDispatch'
import {numberFormatReactNode, numberFormatNoHTML } from '../sharedFunctions/numberFormat'
import {getDefaultScryNumber} from '../sharedFunctions/parseScryInputStringTypes'
import isScryNumber           from '../sharedFunctions/isTypeNumber'
import constants              from '../sharedComponents/constants'
import HorzTaperedDivider     from '../sharedComponents/HorzTaperedDivider'
import {cleanScryInputText2}  from '../sharedFunctions/cleanScryInputText'

const LABEL_WIDTH = 90
const INPUT_WIDTH = 244
const TOTAL_WIDTH = LABEL_WIDTH + INPUT_WIDTH
const ROW_HEIGHT  = 20
const TOTAL_HEIGHT_VIEWMODE = 2.3*ROW_HEIGHT
const TOTAL_HEIGHT_EDITMODE = 4.45*ROW_HEIGHT
const TOTAL_HEIGHT_WITH_EXAMPLES = 9.7*ROW_HEIGHT
const SCRY_NUMBER_INPUT_MAX_CHARACTERS  = constants.SCRY_NUMBER_INPUT_MAX_CHARACTERS
const FLOATING_PALETTE_BACKGROUND       = constants.FLOATING_PALETTE_BACKGROUND
const HELP_BUTTON_BACKGROUND            = constants.HIGHLIGHT_COLOR_HALF
const borderColor                       = constants.COLHEADER_INPUT_BORDER_COLOR

type Props = {
  tabledataid: string,
  canEdit: boolean,
  resourceValue: string,
  formattingObj: FormattingObj,
  rowKey: number,
  colKey: number,
}
type LocalState = {
  currentInput: string,
  lastRowKey: number,
  lastColKey: number,
  areExamplesVisible: boolean,
  scryNumberResult: ScryNumber,
}
export default class EditCellNumber extends Component<Props, LocalState> {

  constructor(props: Props) {
    super(props)
    this.state={ currentInput: '', lastColKey:-1, lastRowKey:-1,
      areExamplesVisible:false, scryNumberResult:getDefaultScryNumber() }
  }

  static getDerivedStateFromProps( nextProps:Props,   prevState:LocalState ) {
                  const {rowKey, colKey, resourceValue, formattingObj } = nextProps

    if ( prevState.lastColKey !== colKey || prevState.lastRowKey  !== rowKey ) {
      // on Mounting this Component, this block will always execute.
      // IFF moving selection to a newCell, then we init currentInput to the stored value.
      var scryNumberResult = isScryNumber( resourceValue )
      if (scryNumberResult.errorID === '' )  {           // valid number
        // Use FullPrecision for all poor man's editors!!
        let editFormattingObj = { ...formattingObj, forceFullPrecision: true, allowsPrefixSuffix:false, useCommas:false }
        var currentInput : string  = numberFormatNoHTML(resourceValue, editFormattingObj )
      } else {
        currentInput = resourceValue
      }
      return {currentInput, lastRowKey:rowKey, lastColKey:colKey, scryNumberResult}
    }
    // ELSE this is same row/col table cell under edit,
    // We don't need any changes to the local state
    // Hence -- ignore the input 'stored value'!  This value will be the canonical
    // form of the currentInput.  However, we want to continue editing whatever
    // form the user is busy typing.  Only if they leave this edit, then return,
    // will we 're-Initialize' to the stored canonical format.
    // But while they are typing, they can type whatever spaces and garbage they
    // desire.  Whether valid or not.   If valid, then exit/return to this cell
    // will be in the canonical format.  If they typed garbage or any type of
    // input error, then exit/return will display the last garbage.   In other
    // words, the saved canonical form of erroneous input IS the erroneous input.
    return { }
  }


  componentWillUnmount = () => {
    if ( this.timeoutID !== null ) {
      clearTimeout(this.timeoutID)
      this.updateReactState( )
    }
  }

  TIMEOUT_DELAY = 300
  timeoutID: NodeJS.Timeout | string | number | undefined = undefined

  updateLocalState = ( e: ChangeEvent<HTMLInputElement>) : void => {
    var {newValue, newSelectionStop} = cleanScryInputText2( e.target.value, e.target.selectionEnd ?? 0 )
    // Don't accept any new input beyond 2 chars over the max
    newValue = newValue.slice(0,SCRY_NUMBER_INPUT_MAX_CHARACTERS+2 );
    ({newValue, newSelectionStop} = cleanScryInputText2( newValue, newSelectionStop  ))
    if ( e.target.value.length !== newValue.length ) {
      e.target.value = newValue.slice()  // This line also resets selection start to useless value.
      e.target.selectionStart = newSelectionStop
      e.target.selectionEnd   = newSelectionStop
    }
    var scryNumberResult = isScryNumber( newValue )
    //console.log(scryNumberResult)
    this.setState( {currentInput: newValue, scryNumberResult} )

    clearTimeout(this.timeoutID)
    this.timeoutID = setTimeout( this.updateReactState, this.TIMEOUT_DELAY)
  }

  updateReactState = ():void => {
    //console.log( 'updateReactState was called')
    const {internalFormat, originalInput, numberType } = this.state.scryNumberResult
    const newValue = (numberType !== 'DATA_ERR')
      ? internalFormat
      : originalInput
    const mods = [{
      newVal: newValue,
      path: `attributes.tableValues[${this.props.colKey}][${this.props.rowKey}]`
    }]
    reactDispatch(mods, 'cell value change', '', 'tabledatas', this.props.tabledataid)
  }

  toggleHelpState = () => {
    this.setState({areExamplesVisible: !this.state.areExamplesVisible})
  }


  render() {
    const {formattingObj, canEdit } = this.props
    const {areExamplesVisible, scryNumberResult, currentInput} = this.state
    //console.log( 'Call to render EditCellNumber', canEdit )
    let noPrefixSuffixFormattingObj = {...formattingObj, allowsPrefixSuffix:false}
    let displayedStoredValue: string
    let displayedFormattedValue: ReactNode
    if (scryNumberResult.numberType === 'MISSING_VALUE' ) {
      displayedStoredValue    = 'Missing Value'
      displayedFormattedValue = 'Missing Value'
    } else if ( scryNumberResult.numberType === 'DATA_ERR' && canEdit ) {
      displayedStoredValue    = 'Not A Number'
      displayedFormattedValue = 'Not A Number'
    } else if ( scryNumberResult.numberType === 'DATA_ERR' ) {   // viewer mode
      displayedStoredValue    = 'Missing Value'
      displayedFormattedValue = 'Missing Value'
    } else {  // numberType is a valid number.
      displayedStoredValue    = scryNumberResult.internalFormat
      displayedFormattedValue = numberFormatReactNode(displayedStoredValue, noPrefixSuffixFormattingObj )
    }


    // Here we build a colorCoded HTML string for the underlying div.
    // We replace all spaces with HTML non-breaking spaces ( &nbsp; )
    // And and the <font> elements for 'erroneous substrings'.
    const highlightArray = scryNumberResult.highlightArray
    var highlightTextFragmentArray = []   // substrings of characters, with either red or black color incoding.
                                          // Could be one substring (all black no errors).
                                          // Could be worse case  [black, red, black, red, ... ]
    var lastCharPosition = 0
    highlightArray.forEach( thisHighlight => {
      highlightTextFragmentArray.push( currentInput.slice(lastCharPosition, thisHighlight.start) ) // black characters
      highlightTextFragmentArray.push(
        <span key={thisHighlight.start} color="red">
          {currentInput.slice(thisHighlight.start, thisHighlight.end)}
        </span>
      )
      lastCharPosition = thisHighlight.end
    })
    // Ending 'black' characters (may or may not be any).
    highlightTextFragmentArray.push( currentInput.slice(lastCharPosition) )

    var paletteHeight = TOTAL_HEIGHT_VIEWMODE
    if ( canEdit ) {paletteHeight = TOTAL_HEIGHT_EDITMODE}
    if ( canEdit && areExamplesVisible ) {paletteHeight = TOTAL_HEIGHT_WITH_EXAMPLES}

    const fontSize = 14
    const height = ROW_HEIGHT
    const boxSizing = 'border-box'
    const display = 'inline-block'
    const paddingTop = (ROW_HEIGHT - fontSize)/2
    const marginTop  = 2
    const marginLeft = 6
    const paddingLeft = 4
    const labelStyle: CSSProperties = {
      fontSize, height, boxSizing,display,paddingTop,marginTop,
      textAlign: 'right',
      width: LABEL_WIDTH,
      //background: 'orange',
    }
    const valueStyle: CSSProperties = {
      fontSize, height, boxSizing,display,paddingTop,marginTop,paddingLeft,marginLeft,
      textAlign: 'left',
      width: INPUT_WIDTH,
    }
    const underlyingControlStyle: CSSProperties = {
      fontSize, height, boxSizing,display,marginTop,paddingTop,paddingLeft,marginLeft,
      width: INPUT_WIDTH,
      background: 'white',
      overflow: 'hidden',
    }
    const inputControlStyle: CSSProperties = {
      fontSize, height, boxSizing, marginTop, paddingTop, marginLeft,
      paddingLeft:paddingLeft - 2,  // Need to subtract 2 because this box has a left border of width 2
      width: INPUT_WIDTH,
      background: 'transparent',
      color: 'transparent',
      caretColor:'black',
      borderRadius: 4, borderWidth: 2, borderStyle: 'inset', borderColor,
    }

    // Assume we have a black warning message.
    // Majority of time the message is empty ''
    var messageText = scryNumberResult.warningID
    var messageColor = 'black'
    if ( scryNumberResult.errorID !== '') {
      messageText = scryNumberResult.errorID
      messageColor = 'red'
    }
    const errorMessageStyle: CSSProperties = {
      fontSize, height, boxSizing,display, paddingTop, marginTop,
      width: TOTAL_WIDTH,
      textAlign: 'center',
      color: messageColor,
    }

    return (
      <div className={'rc_EditCellNumber'}
        style={{
          width: TOTAL_WIDTH + 12,    // Need some extra room for the blue floating palette outline and spacing.
          height: paletteHeight,
          position: 'relative',
        }}>


          <div style={{...labelStyle, position:'absolute', top:0, left:0}}>Internal:</div>
          <div style={{...valueStyle, position:'absolute', top:0, left:LABEL_WIDTH}}>{displayedStoredValue}</div>

          <div style={{...labelStyle, position:'absolute', top:ROW_HEIGHT, left:0}}>Formatted:</div>
          <div style={{...valueStyle, position:'absolute', top:ROW_HEIGHT, left:LABEL_WIDTH}}>
              {/* This is how html text with exponents are aligned vertically using the baseline of the text,
                  rather than the top of the text (default).  Top of the text does not work because
                  it varies depending on the presence or absence of an exponent.  This trick used for all
                  data cells, and anywhere else we may render html with optional exponents. */}
              <div className={'formattedInternalValue'}
                style={{position:'absolute', bottom:0, left:0, paddingBottom:2, paddingLeft}}>
                {displayedFormattedValue}
              </div>
          </div>

{canEdit && <Fragment>

          <div style={{...labelStyle, position:'absolute', top:2*ROW_HEIGHT, left:0}}>Edit Value:</div>
          <span className={'colorCodedInputValue'}
            style={{...underlyingControlStyle, position:'absolute', top:2*ROW_HEIGHT, left:LABEL_WIDTH}}
          >
            {highlightTextFragmentArray}
          </span>

          <input
            onChange={this.updateLocalState}
            style={{...inputControlStyle, position:'absolute', top:2*ROW_HEIGHT, left:LABEL_WIDTH}}
            type='text'
            value={currentInput}
            maxLength={SCRY_NUMBER_INPUT_MAX_CHARACTERS+2}
            autoComplete='off'
            spellCheck='false'
          />

          <div className={'ErrorMessage'}
            style={{...errorMessageStyle, position:'absolute', top:3*ROW_HEIGHT, left:0}}>
            {messageText}
          </div>



{ areExamplesVisible &&
          <div className={'NumberHelpText'} onClick={ this.toggleHelpState }>

                <div style={{position:'absolute', top:4.3*ROW_HEIGHT, left:0 , height: ROW_HEIGHT, width:'100%'}}>
                  <HorzTaperedDivider  background={FLOATING_PALETTE_BACKGROUND}/>
                </div>

                <div className={'FormatExamplesTitle'}
                  style={{...errorMessageStyle, color:'black', position:'absolute', top:4.6*ROW_HEIGHT, left:0}}>
                  Example Number Formats
                </div>

                <table
                  style={{ position:'absolute', top:5.8*ROW_HEIGHT, left:0, fontSize:14, textAlign:'center', lineHeight:1 }}
                  width={TOTAL_WIDTH}

                >
                  <tbody>
                    <tr><td> 1.2</td><td> 10 ** 3</td><td>1e3</td><td>1:23</td><td>True</td></tr>
                    <tr><td> 1.23456e4</td><td>-10 **-3</td><td>-1E-3</td><td>1:23:45</td><td>False</td></tr>
                    <tr><td> 12345.6</td><td>1 * 10 ** 3</td><td>5e3</td><td>-1:23:45.67</td><td>1</td></tr>
                    <tr><td> (No commas!)</td><td>3.1*10**3</td><td>3.14E3</td><td>0:12:34</td><td>0</td></tr>
                  </tbody>
                </table>

          </div>
}

          <button className={'helpWithFormatsButton'}
            onClick={ this.toggleHelpState }
            style={{
              position:'absolute', right:5, top:3*ROW_HEIGHT+5,
              height: ROW_HEIGHT, width: ROW_HEIGHT,
              fontWeight: 'bold', //borderRadius: .3*ROW_HEIGHT,
              paddingTop:  (ROW_HEIGHT-fontSize)/2 - 3,
              outline:'none',
              paddingLeft: (ROW_HEIGHT-fontSize)/2,
              background: (areExamplesVisible) ? HELP_BUTTON_BACKGROUND : 'unset',
              ...constants.STYLE_EDITOR_RAISED_BUTTON
            }}>{'?'}</button>

</Fragment> }

      </div>
    )
  }
}
