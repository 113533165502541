import React, {Component} from 'react'
import SVGwrapper2      from '../SVGs/SVGwrapper2'
import SVGcloseIcon     from '../SVGs/SVGcloseIcon'
import dayjs      from 'dayjs'


type Props = {
  tableid: string,
  tablelookid: string,
  tabledataid: string,
  derivedColAttributesArray: derivedColAttributesArray,
  width: number,
  selectedColKey: number,  // Tells us 'where to insert restored columns'

  onExit: ()=> void,
  insertAnimation: (number, 'new'|'cloned'|'restored', number) => void,
  deleteAnimation: (number, boolean) => void,
}

type LocalState = {
  deletedSortedColKeys: Array<number>,
  restore: Array<boolean>,
  deletedPermanent: Array<boolean>,
}

class EditColRestore extends Component<Props, LocalState> {

  constructor(props: Props) {
      super(props)
      const deletedCols = []
      const restore = []
      const {derivedColAttributesArray} = this.props
      props.derivedColAttributesArray.forEach( (thisCol, colKey) => {
        if ( thisCol.isDeleted ) {
          deletedCols.push(colKey)
          restore[colKey] = false
        }
      })
      const compareFunc = (colKeyA, colKeyB) => {
        return new Date(derivedColAttributesArray[colKeyB].deletedDate) - new Date(derivedColAttributesArray[colKeyA].deletedDate)
      }
      const deletedSortedColKeys = deletedCols.slice().sort( compareFunc  )
      this.state={ deletedSortedColKeys, restore }
  }

  // Animation and state change as restore button changes state
  handleRestoreClick = ( restoredColKey:number) : void => {
      // Whatever the current state, toggle it.
      var {restore} = this.state
      var {selectedColKey, insertAnimation, deleteAnimation} = this.props
      restore[restoredColKey] = !restore.slice()[restoredColKey]
      var newState = restore[restoredColKey]   // True => restore/insert action;  false => delete action
      this.setState( { restore } )
      if ( newState === true ) {
        // insert the restored column
        insertAnimation( selectedColKey, 'restored', restoredColKey)
      } else {
        // newState is false; delete selectedColKey
        const shouldSetDeletionDate = false
        deleteAnimation( restoredColKey, shouldSetDeletionDate )
      }
  }


  render() {
    const {derivedColAttributesArray} = this.props
    //const {deletedSortedColKeys, deletedPermanent} = this.state
    const {deletedSortedColKeys} = this.state


    // These widths constitute a single row in the tab
    const gapTopOfTable = 20
    const closeIconSize = 14

    const gapLeftOfTable = 10
    const widthDate = 145
    const widthTitle= 140
    const widthType = 100
    const gapLeftOfRestore = 0  // gap between dataType and first select box
    const widthRecoverCol = 70
    const gapRightOfTable = 10
    const totalWidth = gapLeftOfTable + widthDate + widthTitle + widthType + 
                       gapLeftOfRestore + widthRecoverCol + gapRightOfTable




    return (

          <div  className={"rc_EditColRestore"}
            style = {{
              //background: 'orange',
              width: totalWidth, 
              fontWeight:'bold',
              userSelect: 'none',  // Text cannot be selected / copied
          }}>
          
            <div className={'CloseWindowIcon'}
              style={{ position: 'absolute', top:4, right:8, height: closeIconSize, width:closeIconSize}}
              onClick={ this.props.onExit } >
              <SVGwrapper2 className={'SVGwrapper2'}>
                  <SVGcloseIcon width={closeIconSize} height={closeIconSize} />
              </SVGwrapper2>
            </div>

            <div className={'TitleBar'}
              style={{fontWeight:'bold', 
                      marginTop: gapTopOfTable, marginLeft: gapLeftOfTable}}>

                <div style={{
                  display: 'inline-block', width:widthDate, textAlign: 'center', //background: 'red',
                  }}>
                    <span>{'Date -Time'}</span><br/>
                    <span>{'of Deletion'}</span>
                </div>

                <div style={{
                  display: 'inline-block', width:widthTitle, textAlign: 'center', //background: 'yellow',
                  }}>
                  <span>{'Displayed'}</span><br/>
                  <span>{'Column Name'}</span>
                </div>

                <div style={{
                  display: 'inline-block', width:widthType, textAlign: 'center', //background: 'blue'
                  }} >
                  <span>{'Data'}</span><br/>
                  <span>{'Type'}</span>
                </div>

                <div style={{
                    display: 'inline-block', width:widthRecoverCol,  textAlign: 'center', //background: 'green',     
                  }}>
                  <span>{'Recover'}</span><br/>
                  <span>{'Column'}</span>
                </div>

            </div>

            <div className={'TableContainer'}
              style={{
                width:'100%', height: 144,
                fontWeight:'normal',
                marginTop: 10,
              }}
            >

              <div className={'TableContent'}
                style={{
                  width:'100%', height:'100%',
                  overflow: 'auto'
                }}
              >

                { deletedSortedColKeys.length > 0

                    ? deletedSortedColKeys.map( colKey=>{
                          var {colTitle, internalDataType, deletedDate} = derivedColAttributesArray[colKey]
                          var {restore} = this.state
                          var formattedDate = dayjs(deletedDate).format('YYYY MMMDD - HH:mm')
                          return (
                            <div key={colKey} style={{fontWeight:'normal', marginLeft:gapLeftOfTable,}}>
                                <div style={{ display: 'inline-block', width:widthDate, textAlign:'center',
                                              whiteSpace:'nowrap', overflow:'hidden', verticalAlign:'bottom'}}
                                >{formattedDate}</div>
                                <div style={{ display: 'inline-block', width:widthTitle, textAlign:'center', 
                                              whiteSpace:'nowrap', overflow:'hidden', verticalAlign:'bottom'}}
                                >{`'${colTitle}'`}</div>
                                <div style={{ display: 'inline-block', width:widthType, textAlign:'center', 
                                              whiteSpace:'nowrap', overflow:'hidden', verticalAlign:'bottom'}}
                                >{`${internalDataType}`}
                                </div>
                                <input type={'checkbox'} name={'restoreCheck'}
                                    checked={ restore[colKey] }
                                    onChange={ ()=>this.handleRestoreClick(colKey)}
                                    style={{display: 'inline-block', verticalAlign:'bottom',
                                            horizontalAlign: 'center', width:widthRecoverCol}}/>
                            </div>
                          )
                      })

                    : <div style={{marginTop: 30, marginLeft: 100, fontSize:16}}>
                        {'This table has no prior deleted columns.'}
                      </div>

                  }

              </div>
            </div>
        </div>


    )
  }
}

export default EditColRestore
