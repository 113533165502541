import type { ReactNode } from 'react'
import type { ConnectDropTarget, DropTargetConnector, DropTargetMonitor } from 'react-dnd'
import type { PlotXyComputedData } from '../computedDataPlotXY/xy_plotTypes'

import {PureComponent} from 'react'
import {DropTarget} from 'react-dnd'
import reactDispatch from '../sharedComponents/reactDispatch'
import SeriesItem, {DragSeriesItemType} from './EditPlotSeriesItem'


type OwnProps = {
  plotXyComputedData: PlotXyComputedData,
  rowHeight: number,
}
type CollectProps = {
  connectDropTarget: ConnectDropTarget,
}
type Props = OwnProps & CollectProps //& DefaultProps

class SeriesSetRender extends PureComponent<Props> {

  moveItem = (draggedSeriesKey: number, atIndex: number, dragStop: boolean): void => {
    if ( dragStop ) {
      reactDispatch( [],  'Reorder Series', 'dragStop' )
      return
    }
    const newSeriesOrder = this.props.plotXyComputedData.seriesOrder.slice()
    const draggedSeriesIndex = newSeriesOrder.indexOf( draggedSeriesKey )
    if ( draggedSeriesIndex === atIndex ) { return }  // Case of illegal drop, or dropped at original position.
    // Swap the seriesKeys at draggedSeriesIndex & atIndex
    newSeriesOrder[draggedSeriesIndex] = newSeriesOrder[atIndex]
    newSeriesOrder[atIndex] = draggedSeriesKey
    const mods = [{
        newVal: newSeriesOrder,
        path: `attributes.seriesOrder`
    }]
    reactDispatch(mods, 'Reorder Series', 'drag')
  }

  render() {
    const {connectDropTarget, plotXyComputedData, rowHeight } = this.props
    const {isTransposed, seriesOrder, isStacked_anyLayer } = plotXyComputedData
    // For some plots, what the user sees is:
    //   series1 on the bottom; lastSeries on the top
    // Hence, we reverse the displayed series names, so 1st on bottom, last on top.
    const workingSeriesOrder = seriesOrder.slice()
    if ( isStacked_anyLayer && isTransposed === false) {
      workingSeriesOrder.reverse()
    }
    return connectDropTarget(

      <div className={'rc_SeriesSet'}>

        {workingSeriesOrder.map(( seriesKey: number, index: number): ReactNode => {
            return (
              <SeriesItem
                key={seriesKey}
                seriesKey={seriesKey}
                moveItem={this.moveItem}
                plotXyComputedData={plotXyComputedData}
                rowHeight={rowHeight}/>
            )
        })}

      </div>
    )
  }
}


const mapDropTarget = {
  drop: (props: Props, monitor: DropTargetMonitor): Object | void => {
  },
}
const mapCollectedProps = (connect: DropTargetConnector, monitor: DropTargetMonitor): CollectProps => (
  { connectDropTarget: connect.dropTarget() }
)
const SeriesSet = DropTarget(DragSeriesItemType, mapDropTarget, mapCollectedProps)(SeriesSetRender)
export default SeriesSet
