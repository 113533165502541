import type { ScryFormula }             from './formulaTypes'
import invariant                        from 'invariant'
import constants                        from '../sharedComponents/constants'
import {createTableidSpacedStrings}     from './updateTableSupportFuncs'
import {getPrettyFormulaLineLength}     from './formulaCreatePrettyFormats'
import measureText                      from '../sharedFunctions/measureText'

//const thinSpace = constants.thinSpaceChar
const lastVal = (a:Array<any>):any => a[ a.length-1 ]


const verifyReturns = ( scryFormula : ScryFormula ) : void => {
    // Objective:
    //    Find lines of 'unreachable code' that appear after a return statement.
    //    Find expressions that are not returned (same as needing a final return)
    //    Find returns with no subsequent expression.

    // Does NOT look for a branching construction where a path has NO return.
    // That algorithm is identical to the 'used before set varName'.  Hence
    // a path with no return is in a different function.

    const {isExpression}  = scryFormula
    var c_Level :number = 0
    // We need to track whether any given block has a prior return or not:
    // And if it does have a return, what is line number?  For error coloring.  Not currently used.
    const isReturned : boolean[] = Array(50).fill( false )
    const isReturnedLineNum : number[] = Array(50).fill( 0 )
    var   numExpr : number = 0  // Excludes blank lines and comments

    // Use a 'for' so we can break
    for ( let lineNum=0; lineNum<scryFormula.formulaSubStrings.length; lineNum++ ) {

      if ( scryFormula.errorID !== '' ) { return }
      if ( isExpression[lineNum] === false ) { continue }   // ONLY process expressions. (ignore comments)
      var thisLine = scryFormula.formulaSubStrings[lineNum]
      //lastExpr = thisLine
      //lastLineNum = lineNum
      numExpr++
      var p_Level:number = c_Level  // p_Level = priorLevel
      c_Level = Number(thisLine[0].value)  // currentLevel
      var deltaIndent  = c_Level - p_Level
      var isReturnLine = thisLine[1].name === 'flowreturn'

      // Error testing for unreachable code after a return statement:
      // Every indent level has a 'isReturned' flag.
      // When we enter a new block (deltaIndent === 1) the flag is set false.
      // Set it true on first instance of 'return' line.
      // Any subsequent lines in that block (level) are an error.
      if (deltaIndent === 1) { isReturned[c_Level] = false }
      if ( isReturned[c_Level] === true ) {
        scryFormula.errorID = `Line will never execute due to prior 'return'.`
        scryFormula.highlightArray = [{lineNum, start:0, end: lastVal( thisLine ).end}]
        break
      }
      if ( isReturnLine ) {
        isReturned[c_Level] = true
        isReturnedLineNum[c_Level] = lineNum
        // error check that something follows the return !
        if ( !thisLine[2] || thisLine[2].name === 'endReturn' ) {
          // Insert ' None' substring.
          thisLine.splice(2,0,{name:'None', text:' None', start:thisLine[0].end, end:thisLine[0].end+5, value:0})
          // Shift all subsequent substrings by 5 characters.
          for ( let i=3; i<thisLine.length; i++ ) {
            thisLine[i].start += 5
            thisLine[i].end   += 5
          }
          scryFormula.warningID = "WARNING: Saved as 'return None' (returns empty cell)."
          break
        }
      }
    }

    // Early return for any of above errors
    if ( scryFormula.errorID !== '' ) { return }

    // Error check the final expression; Had better be some type of 'return ...'
    var lastLineNum = scryFormula.formulaStrings.length - 1
    while ( isExpression[lastLineNum] === false && lastLineNum >= 0 ) { lastLineNum-- }
    const lastExpr = scryFormula.formulaSubStrings[lastLineNum]

    if (process.env.NODE_ENV !== 'production' ) {
      invariant( lastExpr.length > 0, 'Last parsed expression: expected this line to have some parsed subStrings[]' )
    }

    if ( scryFormula.errorID !== '' ) { return }

    // Unique testing for the final expression,
    // Case of multiple lines, and last line is flowpass.
    if ( lastExpr[1].name === 'op_=' || lastExpr[1].name === 'flowpass') {
      // No highlighted characters
      scryFormula.errorID = "Missing the final line to 'return' some value."
      return
    }
    // Case of last line isIfElseElif
    if ( lastExpr[1].name==='flowif' ||  lastExpr[1].name==='flowelse' || lastExpr[1].name==='flowelif' ) {
      scryFormula.errorID = `Must be at least one indented expression<br>following ' ${lastExpr[1].name.slice(4)} ' statement.`
      scryFormula.highlightArray = [{lineNum:lastLineNum, start:0, end: lastExpr[ lastExpr.length-1 ].end}]
      return
    }
    // Case of last line is a 'setVarName', instead of return.
    // Could be first or last line.
    if ( lastExpr[1].name === 'setVarName' && numExpr === 1 ) {
      scryFormula.highlightArray = [{lineNum:lastLineNum, start:0, end: lastExpr[2].end}]
      scryFormula.errorID = "This line should 'return', not set a named value."
      return
    }
    if ( lastExpr[1].name === 'setVarName' ) {
      scryFormula.highlightArray = [{lineNum:lastLineNum, start:0, end: lastExpr[2].end}]
      scryFormula.errorID = "Last expression should 'return' a value, not set a named value."
      return
    }
    // Case of a single line expression, it is NOT a flow statement, and line has no 'return'
    // Insert 'flowreturn' at index 1.  Insert 'endReturn' at end of expression (prior to a comment)
    if ( lastExpr[1].name !== 'flowreturn' ) {
      // Splice is an 'in-place' operation.
      let endReturnIndex = ( lastVal(lastExpr).name === 'comment') ? lastExpr.length-1 : lastExpr.length
      lastExpr.splice(endReturnIndex,0,
        {name:'endReturn', text:'', start:lastExpr[endReturnIndex-1].end, end:lastExpr[endReturnIndex-1].end, value:0})
      lastExpr.splice(1,0,{name:'flowreturn', text:'', start:lastExpr[0].end, end:lastExpr[0].end, value:0})
      // Slightly different warning for single line vrs multi-line formulas.
      if ( numExpr === 1 ) {
        scryFormula.warningID = "WARNING: Automatically inserted 'return'<br>before your expression."
      } else {
        scryFormula.warningID = "WARNING: Automatically inserted 'return'<br>before last line of formula."
      }
      return
    }
}


const varName_UsedBeforeSetSimple = ( scryFormula : ScryFormula ) : void => {

    // Objective:
    //  Issue an error any time we find a 'varName' used before 'setVarName'
    //  These errors should be easy for beginners to understand and fix.
    //  Hence we will do a compile time error check.
    //
    //  This algorithm DOES NOT catch all potential 'used before set' errors.
    //  It is possible to write a branching algorithm: one potential branch
    //  setVarName, and another potentially access 'varName'.  I wrote an
    //  algorithm to find such potential errors.  (They are potential because
    //  even though the error exists at compile time, the real data may never
    //  use the offending branches, hence no errors at runtime.)
    //
    //  The problem with the smart 'branch checking' algorithm is "how do
    //  you show the problematic path?"  The full-check algorithm will highlight
    //  the executed lines of code, hence should the problematic code flow.
    //  However, still difficult for beginners to understand this concept.
    //
    //  The alternative approach (our current code) is run-time error checking
    //  for 'varName before setVarName'.  Hence only cells that that the offending
    //  set of branches will appear as 'NaN' after calculation.  And inspecting
    //  any one of these cells shows (with real example) the coding error.  And
    //  this approach works well -- sufficiently well even for beginners.
    //
    //  Therefore:
    //    - We choose to use runtime error checking for 'varName before setVarName'.
    //    - But also compile time error checking for cases when the error is simply
    //      a failure to set the variable anywhere (any prior line).
    //    - Function below the 'simple' compile time check to test for obvious failure
    //      to 'setVarName'.
    //    - Run time error checking remains, but the only coding errors it will
    //      potentially catch are the more complex branching related errors.

    if ( scryFormula.errorID !== '' ) {return}
    const setVarNames : Array<string> = []  // Purpose: for looping over varNames.

    for( const [lineNum, subStringsArr] of scryFormula.formulaSubStrings.entries() ) {
      if ( !scryFormula.isExpression[lineNum] ) { return }
      for ( const ss of subStringsArr ) {
        let { name, value } = ss    // name refers to opCode; value is the variable name
        let varName = value as string
        if ( name === 'setVarName' ) {
          setVarNames.push( varName )
        }
        if ( name === 'varName' && setVarNames.indexOf(varName) === -1 ) {
          scryFormula.errorID = `Named value '${ss.value}': Not a column name nor assigned a value.`
          scryFormula.highlightArray.push({lineNum, start:ss.start, end:ss.end})
        }
      }
    }
}




  // Indentation Design Rules:  (User Interface)
  //
  //  Objectives:
  //     -- Track indents to make sure subsequent lines use the same indent sizes.
  //     -- Error check the sequence of 'if' 'elif' 'else'
  //     -- Error check for improper indents (missing or extra) based on 'if' 'elif' 'else'
  //
  //  1. When a formula is loaded from state:
  //        if valid, it will use indents of size INDENT_LENGTH
  //        if invalid, it will use the indents the user last typed.
  //  2. When we save a valid formula to state, the canonical rules
  //     will always use INDENT_LENGTH.
  //  3. After editing by user begins, indents can be modified to
  //     any size.  In other words, the error checking for indentation
  //     should not be dependend of 'size of indents'. Only alignment
  //     of indents.
  //  4. A return keystroke by user will auto-indent.

  //  (5,6 Not supported at this time)
  //  5  A tab keystroke at beginning of lines will be considered an
  //     'indent'.  It will align the insertion point as defined
  //     by the rules below.  We will never insert the tab character
  //     into the formula.  We will replace it with spaces.
  //  6  A tab keystroke anywhere other than indent will be replaced
  //     with INDENT_LENGTH spaces.

  //  7  Errors for a missing indent will highlight the error line
  //     of text.
  //  8  Errors for too much indent will be highlighted as red background
  //     over the empty space.  (need to add this to our color coding
  //     function.)
  //  9  A indent substring is encoded with both indent.text and
  //     indent.level.   For the canonical format:
  //             indent.text.lenght() === indent.level*INDENT_LENGTH
  //  10 For general formulas currently being edited, above relation
  //     should NOT be assumed. It needs to use indent alignment, NOT
  //     absolute space counting.  Hence indent alignment determines
  //     the indent.level.  And we converted to canonicalFormat,
  //     indent.level is mapped to our standard INDENT_LENGTH.
  //  11 There is a clear order for 'if' 'elif' 'else', which should
  //     be error checked




const verifyIndentation = ( scryFormula : ScryFormula ) : void => {

    // For each indent substring, use the value attribute to save
    // the indentation 'level'.
    // We can get the indentation # spaces from subString.text.length
    // However, everything downstream of this verification will
    // use the indentation 'level'.  (subString.value)
    // And the indentation 'level' will be used when creating our
    // standard 'saved' and 'display' formated formulas.

    if ( scryFormula.errorID !== '' ) {return}

    // Just initialize an array much larger than practical.
    // Easier than trying to get it right or even close to 'worse case nesting'.
    // We'll know the worse case nesting AFTER this analysis.
    const c_Block : Array<string> = Array(50).fill( 'none' )
    const c_BlockIndentLength : Array<number> = Array(50).fill( 0 )

    // initialize current lineNumber attributes:
    // these will also become initial values for prior lineNumber attributes,
    var c_Level :number = 0
    var c_LineType :string = 'none'
    var c_LineNumber: number = 0
    var c_endOfLine: number = 0
    var c_numSpaces: number = 0
    var c_isIfElseElif : boolean = false
    var isFirstNonCommentedLine = true


    // Use a 'for' so we can break after setting an errorID
    for ( let lineNum=0; lineNum<scryFormula.formulaSubStrings.length; lineNum++ ) {


      // ONLY process expressions. Ignore comments for now.
      // We will set the comment line indents later, based on indents of neighboring expressions.
      if ( scryFormula.isExpression[lineNum] === false ) { continue }

      // New current lineNumber attributes
      var thisLine = scryFormula.formulaSubStrings[lineNum]

      // Set prior line attributes
      var p_endOfLine:number     = c_endOfLine
      var p_Level:number         = c_Level
      var p_LineType :string     = c_LineType   // p_Line  = 'none', 'if', 'elif', 'else'
      var p_LineNumber:number    = c_LineNumber
      var p_numSpaces:number     = c_numSpaces
      var p_isIfElseElif:boolean = c_isIfElseElif

      c_numSpaces = thisLine[0].text.length
      c_endOfLine = lastVal( thisLine ).end  // Used for constructing error messages
      c_LineNumber = lineNum

      if ( isFirstNonCommentedLine && c_numSpaces > 0) {
        // Set errorID if first expression (non-comment) is indented.
        // Not a strict Python error.  But simplifies the look and
        // editing of the formula, as well as this algorithm.
        scryFormula.errorID = 'Please use no indenting for the first expression.'
        scryFormula.highlightArray = [{lineNum, start:0, end:thisLine[0].end, colorMode:'redBack'}]
        break
      }
      isFirstNonCommentedLine = false

      // Is current line if, elif, else?   Otherwise set c_LineType to 'none'
      c_LineType = 'none'  // assumption
      if ( thisLine[1].name.slice(0,4) === 'flow' ) { c_LineType = thisLine[1].name.slice(4) }
      c_isIfElseElif = ( c_LineType === 'if' || c_LineType === 'else' || c_LineType === 'elif' )


      // CASE: Missing an expected indent
      if ( p_isIfElseElif && c_numSpaces <= p_numSpaces ) {
        scryFormula.errorID = `At least one indented expression must<br>follow '${p_LineType}'.  Use 'pass' if necessary.`
        scryFormula.highlightArray = [{lineNum:p_LineNumber, start:0, end:p_endOfLine}]
        break
      }

      // Here is where the indented number of spaces is mapped to
      // a discrete indentation 'level'
      // The each level of indentation may be 1 additional space or many spaces.
      if ( c_numSpaces === p_numSpaces ) {
        c_Level = p_Level
      } else if ( c_numSpaces > p_numSpaces ) {
        c_Level = p_Level + 1
        c_Block[c_Level] = 'none'    // Assumption.  May be overridden later.
        c_BlockIndentLength[c_Level] = c_numSpaces
        // Error test for an indent NOT associated with an if/else/elif
        if ( !p_isIfElseElif ) {
          let indentEnd = thisLine[0].end
          scryFormula.errorID = 'Illegal indent.  Expect alignment to a prior line.'
          scryFormula.highlightArray = [{lineNum, start:0, end:indentEnd, colorMode:'redBack'}]
          break
        }
      } else {    // c_numSpaces < p_numSpaces  NEGATIVE indentation.
        // This indented number of spaces MUST match a prior level's indent.
        // Else we set an illegal indention error.
        for ( let level=c_Level-1; level>=0; level-- ) {   // Loop in reverse order. Makes n_numspaces > indent work properly.
          let thisLevelIndentSpaces = c_BlockIndentLength[level]
          if ( c_numSpaces > thisLevelIndentSpaces ) {
            // Insufficient indentation to properly align with
            // the prior code.
            let indentEnd = thisLine[0].end
            scryFormula.errorID = 'Indent should align with prior expression or prior if, elif, else.'
            scryFormula.highlightArray = [{lineNum, start:thisLevelIndentSpaces, end:indentEnd, colorMode:'redBack'}]
            break  // This break insufficient as it only exits this local for loop.
          }
          else if ( c_numSpaces === thisLevelIndentSpaces ) {
            c_Level = level
            break
          }
        }
      }

      if (scryFormula.errorID !== '' ) break

      // Save the indentation level to the subString.value attribute:
      thisLine[0].value = c_Level

      // Error testing for properly sequenced if/elif/else
      // Every indent level has a 'c_Block' identifyer -- 'none', 'if', 'elif', 'else'
      // When we enter a new block (deltaIndent === 1) c_Block[c_Level] = 'none'
      // when we encounter an if, elif, else, set c_Block[c_Level] accordingly.
      // Error check for proper sequencing.

      // CASE: 'if'
      if ( c_LineType === 'if'   ) { c_Block[c_Level] = 'if' }
      // CASE: 'elif' order testing
      else if ( c_LineType === 'elif' ) {
        if ( c_Block[c_Level] === 'else' ) {
          scryFormula.errorID = "'elif' cannot follow an 'else'.<br>Perhaps you want the 'elif' first."
          scryFormula.highlightArray = [{lineNum, start:0, end:c_endOfLine}]
          break
        }
        else if ( c_Block[c_Level] === 'none' ) {
          scryFormula.errorID = "'elif' without prior 'if'."
          scryFormula.highlightArray = [{lineNum, start:0, end:c_endOfLine}]
          break
        }
        else  { c_Block[c_Level] = 'elif' }
      }
      // CASE: 'else' order testing
      else if ( c_LineType === 'else' ) {
        if ( c_Block[c_Level] === 'else' ) {
          scryFormula.errorID = "'else' cannot follow an 'else'.<br>Perhaps you want an 'elif' first."
          scryFormula.highlightArray = [{lineNum, start:0, end:c_endOfLine}]
          break
        }
        else if ( c_Block[c_Level] === 'none' ) {
          scryFormula.errorID = "'else' without prior 'if'."
          scryFormula.highlightArray = [{lineNum, start:0, end:c_endOfLine}]
          break
        }
        else  { c_Block[c_Level] = 'else' }
      }
    }  // Next formula lineNum



    // Above loop skipped all commentLines
    // Indentation rules for commentLines:
    //   1 - Equal to the indent of the next expression line.
    //   2 - In case of no subsequent expression line, a missing
    //       expression error will be thrown anyway.  So no need
    //       to set the comment line indent anyway.
    const numLines = scryFormula.formulaStrings.length
    var lastIndentLevel = -1
    for ( let lineNum=numLines-1; lineNum >= 0; lineNum-- ) {
          if ( scryFormula.isBlankLine[lineNum] ) {
            // Set nothing.
          }
          else if ( scryFormula.isExpression[lineNum] ) {
            lastIndentLevel = scryFormula.formulaSubStrings[lineNum][0].value as number
          }
          else if ( scryFormula.isCommentLine[lineNum] && lastIndentLevel !== -1 ) {
            scryFormula.formulaSubStrings[lineNum][0].value = lastIndentLevel
          }
          else {
            // Align to prior expression line.
            // Since this loop runs from bottom to top, we need
            // to look 'above' to find the next expression line.
            scryFormula.formulaSubStrings[lineNum][0].value = 0
            for (let priorLineNum = lineNum-1; priorLineNum>=0; priorLineNum--) {
              if ( scryFormula.isExpression[priorLineNum] ) {
                scryFormula.formulaSubStrings[lineNum][0].value = scryFormula.formulaSubStrings[priorLineNum][0].value
                break
              }
            }
          }

    } // end for loop
}


const verifyFormattedExpressionLength = (scryFormula: ScryFormula, colTitleArr: string[] ) : void => {
  if ( scryFormula.errorID !== '' ) { return }

  // Generate the canonical form (our standard spacings) of the formula strings
  // Test if each canonical form is 'too long'.
  let { formulaStrings } = createTableidSpacedStrings( scryFormula )
  for (let lineNum=0; lineNum < formulaStrings.length; lineNum++) {
    let canonicalLength = measureText( formulaStrings[lineNum], constants.COL_FORMULA_EDITOR_FONT_SIZE + 'px' )
    if ( canonicalLength > constants.COL_FORMULA_MAX_LINE_LENGTH ) {
      scryFormula.errorID = 'The formatted version of this expression is too long.<br>Please write as two shorter expressions.'
      scryFormula.highlightArray.push({lineNum, start:0, end:formulaStrings[lineNum].length, colorMode:'redBack'})
      return
    }
  }

  // Create and measure the prettyFormula lengths:
  for ( const [lineNum, subStringArr] of scryFormula.formulaSubStrings.entries() ) {
    var len = getPrettyFormulaLineLength( subStringArr, colTitleArr )
    if ( len > constants.COL_FORMULA_MAX_LINE_LENGTH ) {
      scryFormula.errorID = 'The formatted version of this expression is too long.<br>Please write as two shorter expressions.'
      scryFormula.highlightArray.push({lineNum, start:0, end:formulaStrings[lineNum].length, colorMode:'redBack'})
      return
    }
  }
}




// This function MAY modifie scryFormula insitu. (to canonical form)
export const errorCheckFormulaStructure = ( scryFormula:ScryFormula, colTitleArr : string[],
                                     isJestTestCall:boolean = false) : ScryFormula => {

    if ( scryFormula.errorID !== '' ) {return scryFormula}
    if ( scryFormula.errorID === '' ) verifyIndentation( scryFormula  )
    if ( scryFormula.errorID === '' ) verifyReturns( scryFormula  )
    if ( scryFormula.errorID === '' ) varName_UsedBeforeSetSimple( scryFormula  )
    if ( scryFormula.errorID === '' && !isJestTestCall ) verifyFormattedExpressionLength( scryFormula, colTitleArr )
    return scryFormula
}




