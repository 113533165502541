import type { SizeMeProps } from 'react-sizeme'
import type { Table, Tabledata, Tablelook } from '../types'
import type { PlotXyComputedData } from '../computedDataPlotXY/xy_plotTypes'
import type { TableComputedData } from '../computedDataTable/getDefaultTableComputedData'
import type { FloatingPaletteLayoutObj } from './type'

import {PureComponent, ReactNode}         from 'react'
import { getGeometricNameFromSelectionName } from '../computedDataPlotXY/plotUtils'
import rStateFloatingPalette          from '../floatingPalette/rStateFloatingPalette'
import constrainPosition              from '../floatingPalette/constrainPosition'
import calcHighlightLine              from '../floatingPalette/calcHighlightLine'
import {floatingPaletteLayoutDefault} from '../floatingPalette/type'
import {reactMinorDispatch}       from '../sharedComponents/reactDispatch'
import constants                  from '../sharedComponents/constants'
import {isScryHyperlink_fromTableValue}  from '../sharedFunctions/isTypeHyperlink'
// Table imports
import {focusCalculator_nameToCellObj} from '../viewTable/focusCalculator'

// XY Plot imports
import {xy_plotCalculator_nameToCellObj} from '../viewPlotXY/xy_plotCalculatorInv'

// The component Viewers & Editors:
import      FloatingPalette          from './FloatingPalette'
import      EditCellResizable        from './EditCellResizable'  // Used for a few, but not all editors.

             // Table Viewers/Editors:
import       EditCellNumber          from '../fp_table_old/EditCellNumber'
import       EditCellString          from '../fp_table_old/EditCellString'
import       HyperlinkEditor         from '../sharedComponents/HyperlinkEditor'
import       EditCellFormulaDebugger from '../fp_table_old/EditCellFormulaDebugger'
import       EditColHeader           from '../fp_table_old/EditColHeader'
import       EditTitle               from '../fp_table_old/EditTitle'
import       EditSource              from '../fp_table_old/EditSource'
import       TestTextAreaOverlay     from '../viewTable/Test_TextAreaOverlay'
import       EditRowFiltering        from '../fp_table_old/EditRowFiltering'

             // XY Plot Viewers/Editors:
import       EditXYMainTitle         from '../fp_plotxy_old/EditXYMainTitle'
import       EditXYPublisherTitle    from '../fp_plotxy_old/EditXYPublisherTitle'
import       EditAxisStyle           from '../fp_plotxy_old/EditAxisStyle'
import       EditPlotSeries          from '../fp_plotxy_old/EditPlotSeries'

var DEBUG = false

var isActiveDrag = false
export const set_floatingPalette_isActiveDrag = (input:boolean) : void => {
  isActiveDrag = input
}
export const get_floatingPalette_isActiveDrag = (): boolean => {
  return isActiveDrag
}



type Props = {
  tablelook : Tablelook,
  tabledata : Tabledata,
  table:      Table,
  tableComputedData: TableComputedData,
  plotXyComputedData?: PlotXyComputedData,
  //plot:       ?Plot,
}

export default class HighlightedFloatingPalette extends PureComponent<Props, FloatingPaletteLayoutObj> {

  // The local state is an analogy to widthObj, hieghtObj, styleObj -- but for
  // the floating palette.  We create the state here, when a floating palette is
  // rendered.  And a copy is saved to rStateFloatingPalette to facilitate
  // redrawing during responsive actions.

  constructor(props: Props) {
    if (DEBUG) {console.log( 'Mounting HighlightedFloatingPalette' )}
    super(props)
    this.state = floatingPaletteLayoutDefault
  }


  // static defaultProps = {
  //     tablelook: null,
  //     tabledata: null,
  //     table: null,
  //     tableComputedData: null,
  //     plotXyComputedData: null
  // }

  static getDerivedStateFromProps( nextProps:Props, prevState:FloatingPaletteLayoutObj ) {

    const {tablelook, tableComputedData, plotXyComputedData} = nextProps
    const { canEdit } = tableComputedData
    //console.log( 'DerivedState func in HighlightedFloatingPalette')

    const view = (plotXyComputedData) ? 'xyPlotView' : 'tableView'

    var selectionName='',
        minorStateColIndex=-1, minorStateRowIndex=-1, minorStateSeriesKey=-1,
        minorStateColKey=-1, minorStateRowKey=-1,
        // The extreme values will be constrained such that default position when not
        // specified in the minorState will be near upper right corner.
        cellEditor_left=20, cellEditor_top=20,
        colDataType='string', isDepCol=false, isDeleted=false,
        hidden=false, activeEditor='', minorStateWidth=null, minorStateHeight=null,
        sideBarWidth = 0,
        floatingPaletteWidth=prevState.floatingPaletteWidth,
        floatingPaletteHeight=prevState.floatingPaletteHeight

    // Extract the necessary table resource values
    if ( view === 'tableView' && tablelook && tableComputedData ) {
        let {colOrder, minorState} = tablelook.attributes;
        ({cellEditor_left, cellEditor_top} = minorState);
        ({name:selectionName, colIndex:minorStateColIndex, rowIndex:minorStateRowIndex } = minorState.selection);
        //sideBarWidth = (tablelook.attributes.minorState.isSideBarVisible)   
        //        ? constants.SIDEBAR_TOTAL_LEFT_BORDER_WIDTH
        //        : 0
        sideBarWidth = 0

        if ( minorStateColIndex >= 0 ) {
          minorStateColKey = colOrder[minorStateColIndex]
          hidden = !!tablelook.attributes.lookColumns[minorStateColKey].hidden;
          ({isDeleted, colDataType, isDepCol} = tableComputedData.derivedColAttributesArray[minorStateColKey]);
        }
        if ( minorStateRowIndex >= 0 ) {
          minorStateRowKey = tableComputedData.sortedRowKeys[minorStateRowIndex]
        }

        // Case of tableHeader, pinnedCell, or dataCell that should NEVER be opened for view or edit.
        // If column is deleted; if column is hidden;  if cell is an active hyperlink;
        // Good test - Select a hyperlink cell in 'edit' mode.  Change to 'view' mode.
        // The floating palette should close itself as this is no longer a valid cell for view or edit.
        switch ( selectionName ) {
          case 'tableHeader' :
          case 'pinnedCell' :
          case 'dataCell' :
            const isActiveHyperlinkCell = (colDataType === 'hyperlink' && canEdit === false && selectionName !== 'tableHeader' )
            if ( hidden || isActiveHyperlinkCell || isDeleted ) {
              //console.log( 'Close Editor in is hidden test')
              reactMinorDispatch([ {newVal:false, path: 'attributes.minorState.isEditorOpen'} ])
              return {highlightLine:'', activeEditor: '', floatingPaletteLeft:0, floatingPaletteTop:0 }
            }
            break
          default :
        }

        activeEditor = HighlightedFloatingPalette.determineEditorFromTableSelectionState( selectionName, colDataType, isDepCol )

        // For any resizable editors, we retrieve the saved minor state editor size.
        if (activeEditor === 'stringEditor') {
          if ( minorState.stringEditorSize[minorStateColKey] ) {
            ({width:minorStateWidth, height:minorStateHeight} = minorState.stringEditorSize[minorStateColKey]);
          } else {
            // Otherwise we just assign a size.  This small size will be over-ridden
            // by the constraints on the resizable object.  So I just pick small values
            // and expect the constraints will reset this to the current minSize
            // for this editor type.
            minorStateWidth = 10
            minorStateHeight= 10
          }
        }

    }

    else if ( view==='xyPlotView' && plotXyComputedData ) {
      // Easy direct mapping possible for xyPlots
      ({cellEditor_left, cellEditor_top} = plotXyComputedData.minorState);
      // plotWidthObj does not include a sideBar width.
      // But we can back into the value from the value of focalPlanePx.
      // let availableWidthIncludingSideBar = window.innerWidth - constants.NAV_COLUMN_WIDTH
      sideBarWidth = 0 //(tablelook.attributes.minorState.isSideBarVisible)   
          //? constants.SIDEBAR_TOTAL_LEFT_BORDER_WIDTH
          //: 0
      // Easy direct mapping of activeEditor to the selectionName
      activeEditor = selectionName = plotXyComputedData.minorState.selection.name
      minorStateSeriesKey = plotXyComputedData.minorState.selection.seriesKey
    }

    if (DEBUG) {
      console.log( '' )
      console.log( 'Call to HighlightedFloatingPaletter derivedState: view:', view, 'selectionName:', selectionName )
    }

    // If the editor is resizable, then its last size is saved in the minor state.
    // Next function:
    //    If NOT a resizable editor, always returns size of (null, null)
    //    If resizable, starts with minorState saved size, then constraints by min/max limits we put on the resizability.
    const minorStateSizeAndConstraints =
           HighlightedFloatingPalette.constrainResizableEditorWidthHeight( activeEditor, minorStateWidth, minorStateHeight );


    // Floating palette size could be one of three states. By priority:
    // 1: If the minorState specifies a size (when editor is resizable), then this is the floating palette size
    // 2: For fixed size editors, the size is unknown when mounting, initial state is a size of (0,0)
    // 3: For fixed size editors, the onSize() function will render, measure the size, then set the floatingPalette width/height
    ({minorStateWidth, minorStateHeight} = minorStateSizeAndConstraints)
    if ( minorStateWidth !== null ) {
      if (DEBUG) { console.log ( 'Palette sized specified by minorStateWidth.' )}
      HighlightedFloatingPalette.isOnSizeActive = false  // Otherwise onSize will issue unnecessary state change.
      floatingPaletteWidth = minorStateWidth

    }
    if ( minorStateHeight !== null ) {
      if (DEBUG) { console.log ( 'Palette sized specified by minorStateHeight.' )}
      HighlightedFloatingPalette.isOnSizeActive = false  // Otherwise onSize will issue unnecessary state change.
      floatingPaletteHeight = minorStateHeight
    }

    // This may now be a fixed size editor, UNLESS either of the width/height value
    // is still zero.  In which case we need an early exit, waiting while react render
    // does its initial rendering of some unknown sized floating palette:
    if ( floatingPaletteWidth  === 0 || floatingPaletteHeight === 0 ) {
      if (DEBUG) {console.log ( 'Palette not yet sized; Early exit in derived state calculation.' )}
      HighlightedFloatingPalette.isOnSizeActive = true
      // We are watching for changes in activeEditor, minorStateColKey and minorStateSeriesKey.
      // So, even if this is an early exit, make sure these state values are updated
      // with the latest values.
      return { activeEditor, minorStateColKey, minorStateSeriesKey, ...minorStateSizeAndConstraints }
    }

    // Beyond this point, the palette has a valid size.
    if (DEBUG) {console.log ( 'Palette has now been sized.', floatingPaletteWidth, floatingPaletteHeight )}


    // Find the selected cell's location and if 'table', determine whether it is Visible:
    var cellLeft=0, cellTop=0, cellWidth=100, cellHeight=100, isCellVisible=true,
        availableWidthIncludingSideBar=100, availableHeight = 100
    if ( view === 'tableView' && tableComputedData ) {
        const { widthObj:w, heightObj:h, styleObj:s, scrollLeft, scrollTop } = tableComputedData;
        availableWidthIncludingSideBar = w.tableLayoutWidth
        availableHeight= h.tableLayoutHeight;
        ({left:cellLeft, top:cellTop, width:cellWidth, height:cellHeight, isCellVisible} =
             focusCalculator_nameToCellObj( selectionName, minorStateColIndex, 
                                            minorStateRowIndex, scrollLeft, scrollTop, w, h, s ));
        // If selected cell is not visible, no need to proceed. We set the activeEditor to empty.
        // Not visible is almost always due to the active cell being scrolled beyond view.
        // Test this code by simply scrolling a cell out of view.  Expected behavior is the
        // responsive state code should 'hide' the floating palette as selection leaves the view.
        // And un-hide the selection if one scrolls the selection back into view.
        // If the selection remains out-of-view for the subsequent react render, (at scroll stop),
        // then the following lines will close the activeEditor.
        if (!isCellVisible) {
          //console.log( 'Close Editor in isCellVisible test')
          reactMinorDispatch([ {newVal:false, path: 'attributes.minorState.isEditorOpen'} ])
          return { ...minorStateSizeAndConstraints, activeEditor: '', view }
        }
    }

    // Find the selected cell's location for xyPlot:
    else if ( view === 'xyPlotView' && plotXyComputedData ) {
      availableWidthIncludingSideBar = window.innerWidth - constants.NAV_COLUMN_WIDTH
      availableHeight = window.innerHeight - constants.TOP_MENUBAR_HEIGHT
      // Replace the basisA, basisB, ... naming convention with leftAxis, bottomAxis, ...
      const geometricName = getGeometricNameFromSelectionName(selectionName, plotXyComputedData)

      const seriesKey = plotXyComputedData.minorState.selection.seriesKey
      const {seriesOrder, plotWidthObj, plotHeightObj, plotStyleObj} = plotXyComputedData;
      ({left:cellLeft, top:cellTop, width:cellWidth, height:cellHeight} =
            xy_plotCalculator_nameToCellObj( geometricName, seriesKey, seriesOrder, plotWidthObj, plotHeightObj, plotStyleObj ))
      isCellVisible = true
    }

    if (DEBUG) {console.log( 'Floating Palette position specified by cellEditor left/top:', `(${cellEditor_left},${cellEditor_top})` )}


    // Constrain the palette left/top position to fit within the currently available
    // view.  Regardless of the suggested position that comes for the minorState.
    // Note that this also requires knowledge of the selected cell's position.
    // Because one of the position constrains is that the floating palette cannot
    // 'overylay' the selectedCell.  Overlay defined as obscuring the center point
    // of the selected cell.  isActiveDrag parameter is required because the
    // constrain concerning 'overlay' of the existing cell depends on whether we
    // are dragging or not.

    // Also:  If the minorState position (cellEditor_left,cellEditor_top) has never
    // been set, the default initial state is (0,0).   This is NOT a desirable
    // initial position as it doesn't appear the palette is intended to float.
    // We will use the lower right corner.  Don't need to know the position
    // exactly because the following constraint function will reposition it
    // legally.
    if ( !cellEditor_left && !cellEditor_top ) {
      cellEditor_left = 10000
      cellEditor_top  = -100
    }

    const {constrainedLeft, constrainedTop } =
      constrainPosition( cellLeft, cellTop, cellWidth, cellHeight,
          cellEditor_left, cellEditor_top, floatingPaletteWidth, floatingPaletteHeight,
          availableWidthIncludingSideBar, availableHeight, sideBarWidth, isActiveDrag)

    // If we've constrained the position, write the new position to the tablelook.
    if (cellEditor_left !== constrainedLeft || cellEditor_top !== constrainedTop ) {
      reactMinorDispatch([
        {newVal:constrainedLeft, path: 'attributes.minorState.cellEditor_left'},
        {newVal:constrainedTop, path:  'attributes.minorState.cellEditor_top'}
      ])
    }

    if (DEBUG) {console.log( 'Floating Palette constrained position:', `(${constrainedLeft},${constrainedTop})` )}

    const highlightLine = calcHighlightLine( cellLeft,    cellTop,    cellWidth,    cellHeight,
          constrainedLeft, constrainedTop, floatingPaletteWidth, floatingPaletteHeight )

    // Save the local state in this component (for react)
    // Save a copy in rStateFloatingPalette (for responsive states)
    rStateFloatingPalette.layoutObj = {
      ...minorStateSizeAndConstraints,
      floatingPaletteLeft : constrainedLeft,
      floatingPaletteTop: constrainedTop,
      floatingPaletteWidth,
      floatingPaletteHeight,
      cellLeft,
      cellTop,
      cellWidth,
      cellHeight,
      highlightLine,
      activeEditor,
      selectionName,
      minorStateRowIndex,
      minorStateRowKey,
      minorStateColIndex,
      minorStateColKey,
      minorStateSeriesKey,
      sideBarWidth,
      availableWidthIncludingSideBar,
      availableHeight,
      view,
    }
    return { ...rStateFloatingPalette.layoutObj }
  }

  onClose = ():void => {
      // We need to know which editor (a minor state attribute) is open, because
      // the minor state Type differs depending on whether current view is 'table', 'xyPlot', ???
      if (this.state.view === 'xyPlotView' && this.props.plotXyComputedData) {
          const {selection, isEditorOpen} = this.props.plotXyComputedData.minorState
          if (isEditorOpen || selection.name !== '' || selection.seriesKey !== -1 ) {
            const mods = [ {newVal:false, path: 'attributes.minorState.isEditorOpen'},
                         {newVal:{name:'', seriesKey:-1}, path: 'attributes.minorState.selection'} ]
            reactMinorDispatch(mods, 'plots', this.props.plotXyComputedData.plotid)
          }
      }

      if (this.state.view === 'tableView' && this.props.tablelook ) {
          const {selection, isEditorOpen} = this.props.tablelook.attributes.minorState
          if (isEditorOpen || selection.name !== '' || selection.colIndex !== -1 || selection.rowIndex !== -1) {
            const mods = [ {newVal:false, path: 'attributes.minorState.isEditorOpen'},
                     {newVal:{name:'', colIndex:-1, rowIndex:-1}, path: 'attributes.minorState.selection'} ]
            // $FlowFixMe
            reactMinorDispatch(mods, 'tablelooks', this.props.tablelook.id)
          }
      }
  }

  onActiveDrag = ( dragLeft:number, dragTop:number ) : void => {
    const {floatingPaletteWidth:width, floatingPaletteHeight:height} = this.state
    //console.log( 'OnActiveDrag params passed to executeRedraw', dragLeft, dragTop, width, height )
    this.executeRedraw( dragLeft, dragTop, width, height )
  }

  onActiveResize = ( deltaWidth:number, deltaHeight:number ) : void => {
    const {floatingPaletteLeft:left, floatingPaletteTop: top, floatingPaletteWidth:width,
         floatingPaletteHeight:height, minWidth, maxWidth, minHeight, maxHeight} = this.state
    var newWidth = width + deltaWidth
    var newHeight= height+ deltaHeight
    if ( newWidth ) {
      newWidth = Math.max( minWidth, newWidth)
      newWidth = Math.min( maxWidth, newWidth)
    }
    if ( newHeight ) {
      newHeight = Math.max( minHeight, newHeight)
      newHeight = Math.min( maxHeight, newHeight)
    }
    //console.log( `OnActiveResize deltaWidth,deltaHeight = (${deltaWidth},${deltaHeight})` )
    //console.log( `OnActiveResize      width,     height = (${newWidth},${newHeight})` )
    this.executeRedraw( left, top, newWidth, newHeight )
  }

  executeRedraw = ( left:number, top:number, width:number, height:number ) : void => {
      const {cellLeft, cellTop, cellWidth, cellHeight, sideBarWidth, availableHeight,
              availableWidthIncludingSideBar } = this.state
      const isActiveDrag = true
      const {constrainedLeft, constrainedTop } = constrainPosition( cellLeft, cellTop,
            cellWidth, cellHeight, left, top, width, height,
            availableWidthIncludingSideBar, availableHeight, sideBarWidth, isActiveDrag, )
      const highlightLine = calcHighlightLine( cellLeft, cellTop, cellWidth, cellHeight,
            constrainedLeft, constrainedTop, width, height )
      const visibility = 'unset'
      rStateFloatingPalette.redraw( constrainedLeft, constrainedTop, width, height, highlightLine, visibility )
  }


  // The sizeable textArea used in the string editor needs to disable this
  // the onSize handler.   Otherwise, it becomes part of the (per frame)
  // textArea resizing operation.
  // So inside component EditCellResizable, drag operation:
  //  - on dragStart setIsOnSizeActive(false)
  //  - on dragStop  setIsOnSizeActive(true )
  static isOnSizeActive : boolean = true
  setIsOnSizeActive = (value:boolean) :void => { HighlightedFloatingPalette.isOnSizeActive = value }


  onSize = ( size: SizeMeProps['size'] ) : void => {
    if (DEBUG) {console.log( 'onSize handler called. isOnSizeActive = ', HighlightedFloatingPalette.isOnSizeActive  )}
      if ( HighlightedFloatingPalette.isOnSizeActive ) {
        if (DEBUG) {console.log( `onSize handler setting State with size: (${size.width},${size.height})` )}
        const {width, height} = size
        if (width !== null && height !== null) {
          this.setState( {floatingPaletteWidth: width, floatingPaletteHeight: height} )
        }
      }
  }


  dispatchReactRepositionOrResize = (xIn: number | null, yIn:number | null, deltaWidth: number | null = null, deltaHeight: number | null = null ) : void => {
      const {cellLeft, cellTop, cellWidth, cellHeight, floatingPaletteWidth, floatingPaletteHeight, availableHeight,
             floatingPaletteLeft, floatingPaletteTop, minorStateColKey, availableWidthIncludingSideBar,
              minWidth, minHeight, maxWidth, maxHeight, view, sideBarWidth} = this.state

      var x = (xIn !== null) ? xIn : floatingPaletteLeft
      var y = (yIn !== null) ? yIn : floatingPaletteTop
      var width  = (deltaWidth === null) ? floatingPaletteWidth  : floatingPaletteWidth + deltaWidth
      var height = (deltaHeight=== null) ? floatingPaletteHeight : floatingPaletteHeight+ deltaHeight
      // Only constraint the width/height in case of Resize
      if ( deltaWidth !== null ||  deltaHeight !== null ) {
        width = Math.max(minWidth, width)
        width = Math.min(maxWidth, width)
        height = Math.max(minHeight, height)
        height = Math.min(maxHeight, height)
      }
      //console.log( 'RespositionOrResize', x, y, width, height, deltaWidth, minWidth, maxWidth )

      const isActiveDrag = false
      const {constrainedLeft, constrainedTop } = constrainPosition(
             cellLeft, cellTop, cellWidth, cellHeight, x, y, width, height,
             availableWidthIncludingSideBar, availableHeight, sideBarWidth, isActiveDrag)

      // And set the react saved minor state! Else next render will be the old minor state values.
      if ( constrainedLeft !== floatingPaletteLeft || constrainedTop !== floatingPaletteTop ) {
          reactMinorDispatch([
            {newVal:constrainedLeft, path: 'attributes.minorState.cellEditor_left'},
            {newVal:constrainedTop, path:  'attributes.minorState.cellEditor_top'}
          ])
      }
      if ( view === 'tableView' && (width !== floatingPaletteWidth || height !== floatingPaletteHeight )) {
          reactMinorDispatch([
            {newVal: {width,height}, path: `attributes.minorState.stringEditorSize[${minorStateColKey}]`},
          ])
      }

  }

  static determineEditorFromTableSelectionState( name:string, dataTypeIn: string, isDepCol:boolean ) : string {
      var activeEditor:string = ''  // assumption;
      const dataType = isDepCol ? 'depColExpression' : dataTypeIn
      switch ( name ) {
        case 'mainTitle'     : {activeEditor = 'titleEditor';  break}
        case 'PlotXYPublisherTitle': {activeEditor = 'publisherEditor'; break}
        case 'tableHeader'   : {activeEditor = 'headerEditor'; break}
        case 'rowFiltering'  : {activeEditor = 'filteringEditor'; break}
        case 'pinnedCell'    :
        case 'dataCell'      :
                switch( dataType ) {
                  case 'string'           : {activeEditor = 'stringEditor'; break}
                  case 'number'           : {activeEditor = 'numberEditor'; break}
                  case 'numberSeconds'    : {activeEditor = 'numberEditor'; break}
                  // case 'numberDegrees'    : {activeEditor = 'numberEditor'; break}
                  case 'hyperlink'        : {activeEditor = 'hyperlinkEditor'; break}
                  case 'depColExpression' : {activeEditor = 'depColEditor'; break}
                  default:
                }
                break
        default:
      }
      return activeEditor
  }





  static constrainResizableEditorWidthHeight( activeEditor:string, widthIn: number | null, heightIn: number | null ) :
      // Return object:
     {minorStateWidth: number | null,  minorStateHeight: number | null,
       minWidth:number, minHeight:number,
       maxWidth:number, maxHeight:number }  {

      // Default width/height are fixed dimensions, vary by editor, and are measured by OnSize() function.
      // Exception is any 'resizable'. Its initial size comes from the minor state
      // with each colKey having a potential custom size;  And the min/max allowed
      // size comes from constants file.
      // Defaults values:  size MUST be null for those editors (most) that will size themselves.
      var minorStateWidth = null
      var minorStateHeight= null
      var minWidth  = 100   // Set some defaults that make palette visible in case of bugs.
      var maxWidth  = 200
      var minHeight = 100
      var maxHeight = 2000

      switch ( activeEditor ) {
          case 'stringEditor':
            minWidth = constants.STRING_EDITOR_MIN_WIDTH
            maxWidth = constants.STRING_EDITOR_MAX_WIDTH
            minHeight= constants.STRING_EDITOR_MIN_HEIGHT
            maxHeight= constants.STRING_EDITOR_MAX_HEIGHT
            break
          case 'Test_TextAreaOverlay':
            minorStateWidth = 393
            minorStateHeight= 600
            minWidth = 100
            maxWidth = 500
            minHeight= 200
            maxHeight= 600
            break
        default:
      }
      // Just in case, apply the constraints to the current requested minorState size:
      if ( widthIn ) {
        minorStateWidth = Math.max( minWidth, widthIn )
        minorStateWidth = Math.min( maxWidth, minorStateWidth )
      }
      if ( heightIn ) {
        minorStateHeight = Math.max( minHeight, heightIn )
        minorStateHeight = Math.min( maxHeight, minorStateHeight)
      }
      return { minorStateWidth, minorStateHeight, minWidth, maxWidth, minHeight, maxHeight }
  }


  getTableEditor = ( ) : {FloatingPaletteEditor: ReactNode, floatingPaletteTitle:string}  => {
      const { tablelook, tabledata, table, tableComputedData } = this.props
      const { canEdit, getTableValue } = tableComputedData
      if ( !tablelook || !tabledata || !table || !tableComputedData ) {
        return {FloatingPaletteEditor: null, floatingPaletteTitle: ''}
      }
      const {minorState, lookColumns } = tablelook.attributes
      const {columns} = table.attributes
      const {name:selectionName, colIndex:minorStateColIndex, rowIndex:minorStateRowIndex} = minorState.selection
            // THIS IS THE WRONG COLORDER;  WE NEED TO HAVE ACCESS TO TABLE COMPUTED DATA IF FLOATING PLALETTE IS REQUIRED TO USE COLORDER.
            // WHY IS THIS HERE?  PROBABLE NOT WHAT WE WANT.   JPS JULY/2024
      const minorStateColKey = tablelook.attributes.colOrder[ minorStateColIndex ]
      const minorStateRowKey = (selectionName === 'pinnedCell' )
        ? tablelook.attributes.pinnedRowKeys[ minorStateRowIndex ]
        : tableComputedData.sortedRowKeys[ minorStateRowIndex ]
      const {value: tableValue, isErroneous:minorState_isErroneous} = (tabledata && minorStateColKey >=0 && minorStateRowKey >=0 )
         ? getTableValue(minorStateColKey,minorStateRowKey, false)
         : {value:'', isErroneous:true}
      const sharedResizeProps = {
           dispatchReactRepositionOrResize: this.dispatchReactRepositionOrResize,
           onActiveResize: this.onActiveResize,
           setIsOnSizeActive: this.setIsOnSizeActive,
      }
      // Will the floating palette title say Editor or Viewer?
      const mode = canEdit ? ' Editor' : ' Viewer'
      let floatingPaletteTitle = ''
      let FloatingPaletteEditor: ReactNode = null
      switch ( this.state.activeEditor ) {
        case 'headerEditor':
            const headerEditorProps = {
              table,
              tablelook,
              tabledataid:tabledata.id,
              tableComputedData: tableComputedData,
              colKey: minorStateColKey,
              duplicateRowNames: tableComputedData.duplicateRowNames,
              missingRowNames: tableComputedData.missingRowNames,
            }
            floatingPaletteTitle  = 'Column Header' + mode
            FloatingPaletteEditor = <EditColHeader  {...headerEditorProps}/>
            break
        case 'XYPlotTitleEditor':
            const xyPlotTitleEditorProps = {
              tablelook,
              table,
            }
            floatingPaletteTitle  = 'Table Overview' + mode
            FloatingPaletteEditor = <EditTitle {...xyPlotTitleEditorProps}/>
            break
        case 'XYPlotPublisherEditor':
            const xyPlotPublisherEditorProps = {
              tablelook,
              table,
            }
            floatingPaletteTitle  = 'Publisher Information' + mode
            FloatingPaletteEditor = <EditSource {...xyPlotPublisherEditorProps}/>
            break
        case 'filteringEditor':
            const filteringEditorProps = {
              tablelook,
              tableComputedData,
            }
            floatingPaletteTitle  = 'Row Filtering'
            FloatingPaletteEditor = <EditRowFiltering {...filteringEditorProps}/>
            break
        case 'stringEditor':
            floatingPaletteTitle  = 'String' + mode
            const stringEditorProps = {
              tabledataid:tabledata.id,
              value: tableValue,
              isErroneous: minorState_isErroneous,
              colKey: minorStateColKey,
              rowKey: minorStateRowKey,
              canEdit,
            }
            FloatingPaletteEditor = <EditCellResizable {...sharedResizeProps}>
                                      <EditCellString  {...stringEditorProps}/>
                                    </EditCellResizable>
            break
        case 'Test_TextAreaOverlay':    // this is a forcedEditor option.
            floatingPaletteTitle  = 'String' + mode
            const textAreaEditorProps = {
              tabledataid:tabledata.id,
              forcedEditor: 'Test_TextAreaOverlay',
            }
            FloatingPaletteEditor = <EditCellResizable {...sharedResizeProps}>
                                      <TestTextAreaOverlay  {...textAreaEditorProps}/>
                                    </EditCellResizable>
            break
        case 'depColEditor':
            // However, '#_debugger' found in the 1st line of program will enable the more complex debugger.
            // In either case, the component rendered is wrapper component: EditCellFormulaDebugger.
            const depColEditorProps = {
              tableid:table.id,
              tablelookid:tablelook.id,
              colKey: minorStateColKey,
              rowKey: minorStateRowKey,
              canEdit,
              lookColumns: lookColumns,
              columns: columns,
              tableComputedData,
            }
            const parsedScryFormula = tableComputedData.derivedColAttributesArray[minorStateColKey].parsedScryFormula
            let wasDebuggerStatementFound = false // assumption
            if (parsedScryFormula ) {
              wasDebuggerStatementFound =
                ( canEdit && parsedScryFormula.formulaSubStrings[0][1].text.slice(0,10) === '#_debugger' )
            }
            floatingPaletteTitle  = (wasDebuggerStatementFound) ? 'Formula Debugger' : 'Cell Calculation:'
            FloatingPaletteEditor = <EditCellFormulaDebugger  {...depColEditorProps}/>
            break
        case 'numberEditor':
            const numberEditorProps = {
              tabledataid:tabledata.id,
              formattingObj: tableComputedData.derivedColAttributesArray[minorStateColKey].formattingObj,
              resourceValue: tableValue,
              //isErroneous: minorState_isErroneous,
              colKey: minorStateColKey,
              rowKey: minorStateRowKey,
              canEdit,
            }
            floatingPaletteTitle  = 'Number' + mode
            FloatingPaletteEditor = <EditCellNumber {...numberEditorProps}/>
            break
        case 'hyperlinkEditor':
            if ( !canEdit ) {
              // Clicking on a hyper link when NOT in editMode will follow the link.
              // At this point, we simply do not open the hyperlink editor.
              FloatingPaletteEditor = null
              break
            }
            floatingPaletteTitle  = 'Hyperlink' + mode
            const isScryHyperlinkResult = isScryHyperlink_fromTableValue(tableValue)
            const hyperlinkEditorProps = {
              tabledataid:tabledata.id,
              inputName: isScryHyperlinkResult.tableLabel,
              inputUrl: isScryHyperlinkResult.canonicalUrl,
              colOrSeriesKey: minorStateColKey,
              rowKey: minorStateRowKey }
            FloatingPaletteEditor = <HyperlinkEditor parentMode='cellEditor' {...hyperlinkEditorProps}/>
            break
        default:
            floatingPaletteTitle  = ''
            FloatingPaletteEditor = null
      }
      return { FloatingPaletteEditor, floatingPaletteTitle}
  }


  getPlotEditor = ( ) : {FloatingPaletteEditor: ReactNode, floatingPaletteTitle:string}  => {
      const { table, tablelook, plotXyComputedData, tableComputedData } = this.props
      const {canEdit} = plotXyComputedData ?? {canEdit: false}
      const { activeEditor, minorStateSeriesKey:seriesKey } = this.state
      const mode = canEdit ? ' Editor' : 'Viewer'
      let floatingPaletteTitle  = ''
      let FloatingPaletteEditor: ReactNode = null

      switch ( activeEditor ) {

        case 'legend':
            const isSeriesKeyIllegal = !plotXyComputedData ||
                                        seriesKey <0 ||
                                        seriesKey >= plotXyComputedData.seriesOrder.length
            if ( table === null || tablelook === null || plotXyComputedData === null ||
              tableComputedData === null || isSeriesKeyIllegal ) {
              floatingPaletteTitle  = ''
              FloatingPaletteEditor = null;
              break
            } else {
              const legendEditorProps = {
                plotXyComputedData,
                seriesKey,
                table,
                tablelook,
                tableComputedData,
              }
              floatingPaletteTitle  = 'Data Series ' + mode
              FloatingPaletteEditor = <EditPlotSeries {...legendEditorProps}/>
            }
            break

        case 'mainTitle':
            const mainTitleEditorProps = {
              plotXyComputedData
            }
            floatingPaletteTitle  = 'Title ' + mode
            FloatingPaletteEditor = <EditXYMainTitle {...mainTitleEditorProps}/>
            break

        case 'publisherTitle':
            const publisherTitleEditorProps = {
              plotXyComputedData
            }
            floatingPaletteTitle  = 'Publisher ' + mode
            FloatingPaletteEditor = <EditXYPublisherTitle {...publisherTitleEditorProps}/>
            break

        case 'basisA':
        case 'basisB':
        case 'basisC':
            const basisEditorProps = {
              plotXyComputedData: this.props.plotXyComputedData,
              basisPath: this.state.activeEditor,   // 'basisA', 'basisB', 'basisC'
              preSpacer: 20,
              postSpacer: 20,
            }
            floatingPaletteTitle  = 'Axis' + mode
            FloatingPaletteEditor = <EditAxisStyle {...basisEditorProps}/>
            break


        default:
            floatingPaletteTitle  = ''
            FloatingPaletteEditor = null
      }

      return {FloatingPaletteEditor, floatingPaletteTitle}
  }

  initHighlightConnectingLine = (element : SVGElement | null ) : void => {
    rStateFloatingPalette.highlightConnectingLine = element
  }

  render() {
    const { highlightLine, activeEditor, floatingPaletteLeft, floatingPaletteTop,
            minorStateWidth, minorStateHeight, floatingPaletteWidth, floatingPaletteHeight } = this.state
    if ( activeEditor === '' ) { return null }

    // We have a chicken/egg problem with rendering.
    // - We need to render to calculate the size.
    // - We don't know the position yet because position = function(size)   (The constraints algorithm)
    // - Hence position calculation must come after 1st render, and then re-render at correct position.
    // - This is a visible flicker! We can't skip the 1st render, and we can't know the necessary position !!
    // - I'll just have to use brute force do 1st render 'hidden'

    // We identify the 1st render when onSize( ) has not yet set both floatingPaletteWidth/Height.
    const isFirstSizingRender : boolean = ( floatingPaletteWidth === 0 || floatingPaletteHeight === 0 )
    const visibilityValue = isFirstSizingRender ? 'hidden' : 'unset'
    const view = (this.props.plotXyComputedData) ? 'xyPlotView' : 'tableView'
    if ( view === 'tableView' ) {
      var {FloatingPaletteEditor, floatingPaletteTitle} = this.getTableEditor( )
    } else if ( view === 'xyPlotView' ) {
      ({FloatingPaletteEditor, floatingPaletteTitle} = this.getPlotEditor( ))
    } else {
      FloatingPaletteEditor = null
      floatingPaletteTitle = ''
    }

    if (DEBUG) {
        console.log( 'Rendering', activeEditor, ` at(${floatingPaletteLeft},${floatingPaletteTop}) ` +
        ` and selfDetermined size (${floatingPaletteWidth},${floatingPaletteHeight})` +
        ` or minorState size (${minorStateWidth},${minorStateHeight})` )
        console.log( 'tableComputedData passed to HighlightedFP:', this.props.tableComputedData )
    }

    return (
      <div className={'rc_HighlightedFloatingPalette'}
        style={{
          position: 'absolute',
          top:0, left:0,
          width: '100%', height:'100%',
          visibility: visibilityValue,
          // background: 'red', opacity: 0.1,
        }}
      >

            <FloatingPalette
              title={floatingPaletteTitle}
              left={floatingPaletteLeft}
              top={floatingPaletteTop}
              width={minorStateWidth ?? undefined}
              height={minorStateHeight ?? undefined}
              isDraggable={true}
              onClose={this.onClose}
              dispatchReactRepositionOrResize={this.dispatchReactRepositionOrResize}
              onSize={this.onSize}
              onActiveDrag={ this.onActiveDrag }
              onActiveResize={ this.onActiveResize }

            > {FloatingPaletteEditor} </FloatingPalette>


          <svg style={{width:'100%', height:'100%' }}>
              <polyline
                ref={ this.initHighlightConnectingLine }
                points={highlightLine}
                style={{
                  stroke:     constants.HIGHLIGHT_COLOR,
                  strokeWidth:constants.HIGHLIGHT_WIDTH,
                  opacity: (view === 'tableView' )
                            ? constants.HIGHLIGHT_OPACITY_TABLE
                            : constants.HIGHLIGHT_OPACITY_XYPLOT,
                  fill: 'none',
                  strokeLinecap:"round",
                  strokeLinejoin:"bevel",
                  visibility:'unset',
                }}
              />
            </svg>

      </div>
    )
  }
}
