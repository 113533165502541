import type { CSSProperties, ReactElement} from 'react'
import type {DomNode, TableComputedData} from '../computedDataTable/getDefaultTableComputedData'

import {Component}              from 'react'
import TabControl2              from '../sharedComponents/TabControl2'

import {NAV_COLUMN_WIDTH, 
        TOP_MENUBAR_HEIGHT,
        SIDEBAR_BLUE_BORDER_WIDTH,
        SIDEBAR_TOTAL_LEFT_BORDER_WIDTH,
        SIDEBAR_BLACK_LEFT_EDGE_WIDTH,
        SIDEBAR_TOTAL_WIDTH,
        SIDEBAR_RANGE_OF_MOTION} from '../sharedComponents/constants'
import {SCRY_BLUE, 
        SCRY_WHITE, 
        SCRY_DARK_BLUE} from '../sharedComponents/constants'

import {sessionStateChangeDispatch} from '../sharedComponents/reactDispatch'
import dynamics                 from 'dynamics.js'
import SVGwrapper2              from '../SVGs/SVGwrapper2'
import {wasSuspendPointerEventsSuccessful,
  restorePointerEvents}         from '../sharedFunctions/pointerEvents'



const SIDEBAR_OPENCONTROL_WIDTH = 14
const SIDEBAR_OPENCONTROL_HEIGHT = 50
const TOP_BUTTON_OFFSET_FROM_BOTTOM_OF_MENU_BAR = 70

// Open close buttons.
const buttonStyle : CSSProperties = {
  border: `solid 1px black`, boxSizing : 'border-box',
  backgroundColor: SCRY_BLUE,
  width: SIDEBAR_OPENCONTROL_WIDTH, height: SIDEBAR_OPENCONTROL_HEIGHT,
}

var sideBarDomNode : DomNode = null // This one shifts left/right;  Main visible animation
var buttonDomNode  : DomNode = null // This one shifts right/left (exact opposite of main animation)
                                    // It effectively 'cancels' the main sidebar shift, such that
                                    // the open control button appears motionless (stuck next
                                    // to the right edge of the visible browser window).
//var sideBarContentDomNode : DomNode= null  // Set this one's visibility, to prevent rendered controls
                                  // that are currently offscreen to the right of main
                                  // window from participating the TAB sequence of selecting
                                  // input boxes/controls.

type Direction = 'open' | 'close'

type SideBarProps = {
  isOpen: boolean, 
  setIsOpenPath: string,
  tabIndex: number,
  setTabIndexPath: string,
  showChildOnlyIfCanEdit: boolean[],
  tabChildren: ReactElement[],
  tableComputedData: TableComputedData,     // For now; Better solution is for
                                            // a parent to manage sidebar, rather
                                            // than sideBar manage the parent
  parentAnimationStart: ( tCD:TableComputedData ) => void, 
  parentAnimation     : ( width:number ) => void,
  parentAnimationStop : ( ) => void, 
}

export default class SideBarParent2 extends Component<SideBarProps> {

  toggleSideBar = () => {
    const {isOpen} = this.props
    // We do the opposite of the current state. ( if isOpen then 'close' )
    const direction : Direction = (isOpen) ? 'close' : 'open'
    resizeTableSideBar(direction, this.props)
  }
  initSideBarDomNode = (element: DomNode): void => {
    sideBarDomNode = element
  }
  initButtonDomNode = (element: DomNode): void => {
    buttonDomNode = element
  }

  render () {
    const { isOpen, tableComputedData, tabChildren, 
            showChildOnlyIfCanEdit, tabIndex, setTabIndexPath } = this.props
    // The placement sets sideBar 'out-of-view' to the left of the main view space,
    // with exception of 3 pixels consisting of a blue border on left and
    // 1px black line on right.  This three pixel border is entirely 
    // cosmetic, to the left edge of viewArea matches the right edge of viewArea.
    // WHEN sideBar opening/closing, we move it with a transform, from the
    // nominal 'out-of-view' placment.
    // WHEN sideBar is opening, the 'open' utton is shifted in the opposite 
    // direction!! Since it is a child of the the container, which is
    // is in the process of opening, the net shift of the button is to
    // appear motionless.  The opening sideBar will occlude the open button.
    var shift : number = 0
    shift = (isOpen) ? +SIDEBAR_RANGE_OF_MOTION : 0
    const openButtonTransform  = `translate(${shift}px,0px)`
    shift = (isOpen) ? -SIDEBAR_RANGE_OF_MOTION : 0
    const sideBarLeftTransform = `translate(${shift}px,0px)`
    return (
      <div className={'rc_SideBarParent'} 
        ref={ this.initSideBarDomNode } 
        style={{ 
          position: 'absolute',   
          left: window.innerWidth - NAV_COLUMN_WIDTH - SIDEBAR_TOTAL_LEFT_BORDER_WIDTH,
          top : 0,  
          width: SIDEBAR_TOTAL_WIDTH,
          height: window.innerHeight - TOP_MENUBAR_HEIGHT,
          background: 'red',
          transform: sideBarLeftTransform,
        }}>

        {/* Use this 'open' button for final layout.  
            Its lies below of the sideBar; 
            Not visible when sidebar is open. */}
{true &&
                <div className={'SideBarOpenButton'}
                    ref={ this.initButtonDomNode } 
                    onClick={ this.toggleSideBar }
                    style={{ ...buttonStyle,
                        position: 'absolute',
                        left: -SIDEBAR_OPENCONTROL_WIDTH, 
                        top: TOP_BUTTON_OFFSET_FROM_BOTTOM_OF_MENU_BAR,
                        transform: openButtonTransform,
                        borderTopLeftRadius: '6px', borderBottomLeftRadius: '6px',
                        borderRight: 'none', }}>
                    <SVGwrapper2>
                          { arrowSVG(SIDEBAR_OPENCONTROL_WIDTH-4, SIDEBAR_OPENCONTROL_HEIGHT-30, 'pointingLeft') }
                    </SVGwrapper2>
                </div>
}
              <div className={'SideBarBlueBorder'}
                style={{
                  /* Disable 'visibility' when a sideBar out-of-view:
                    Save this for later.  At some time we should have our tab
                    key (on keyboard) cycle through user inputs.  This may
                    include input located in the sideBar.  However, they may
                    be rendered by out-of-view, which would be quite confusing
                    to the use model.  In this case, we want to set the 
                    out-of-view input to 'hidden', hence they are skipped.
                    Why not just set the values here and now?  Because
                    we also need to use the responsive animation functions
                    to set these controls visible BEFORE an animation to
                    open the sideBar begins.  Save for later, because not
                    obvious how to do this best, and can't test whether it
                    works until we do a lot of other similar work. */
                  visibility: isOpen? 'visible' : 'visible',   
                  position: 'absolute', 
                  boxSizing: 'border-box',
                  top:0, left:0, height: '100%', width: '100%',
                  overflow: 'hidden',
                  borderColor: SCRY_DARK_BLUE,
                  borderStyle: 'solid',
                  borderWidth: `0px ${SIDEBAR_BLUE_BORDER_WIDTH}px  ${SIDEBAR_BLUE_BORDER_WIDTH}px ${SIDEBAR_BLUE_BORDER_WIDTH}px`,
                }} >
                        <div className={'SideBarContent'}
                        ref={ this.initSideBarDomNode }
                        style={{
                            // Disable visibility to tabKey when sideBar out-of-view
                            visibility: isOpen? 'visible' : 'visible',   
                            boxSizing: 'border-box',
                            height: '100%', width: '100%',
                            overflow: 'visible',
                            background: SCRY_WHITE,
                            borderLeft: `1px solid black`,
                        }}>
                        
                                <TabControl2 
                                    tableComputedData={tableComputedData}
                                    tabChildren={tabChildren}
                                    showChildOnlyIfCanEdit={showChildOnlyIfCanEdit}
                                    tabIndex={tabIndex}
                                    setTabIndexPath={setTabIndexPath}/>

                        </div> 
                </div>

{/* Use this 'open' button for debugging layout.  Its on top of the sideBar */}
{false &&
                <div className={'SideBarOpenButton'}
                    onClick={ this.toggleSideBar }
                    style={{ ...buttonStyle,
                        position: 'absolute',
                        left: -SIDEBAR_OPENCONTROL_WIDTH, 
                        top: TOP_BUTTON_OFFSET_FROM_BOTTOM_OF_MENU_BAR,
                        transform: openButtonTransform,
                        borderTopLeftRadius: '6px', borderBottomLeftRadius: '6px',
                        borderRight: 'none', }}>
                    <SVGwrapper2>
                          { arrowSVG(SIDEBAR_OPENCONTROL_WIDTH-4, SIDEBAR_OPENCONTROL_HEIGHT-30, 'pointingLeft') }
                    </SVGwrapper2>
                </div>
}
                <div className={'SideBarCloseButton'}
                    onClick={ this.toggleSideBar }
                    style={{ ...buttonStyle,
                        position: 'absolute',
                        left: SIDEBAR_BLUE_BORDER_WIDTH + SIDEBAR_BLACK_LEFT_EDGE_WIDTH, 
                        top: TOP_BUTTON_OFFSET_FROM_BOTTOM_OF_MENU_BAR,
                        borderTopRightRadius: '6px', borderBottomRightRadius: '6px',
                        borderLeft: 'none', }}>
                    <SVGwrapper2>
                          { arrowSVG(SIDEBAR_OPENCONTROL_WIDTH-4, SIDEBAR_OPENCONTROL_HEIGHT-30, 'pointingRight') }
                    </SVGwrapper2>
                </div>
      </div>
    )
  }
}






const arrowSVG = ( width:number, height:number, direction:string ): SVGRectElement => {
  let transformText = 'scale(1,1)'   // As drawn is pointingLeft.
  if (direction === 'pointingRight') { transformText = 'scale(-1,1)' }
  return (
        <svg
           className='SideBarButtonArrow'
           xmlns="http://www.w3.org/2000/svg"
           version="1.1"
           width={width}
           height={height}
           viewBox="0 0 16 32"
           preserveAspectRatio='none'
           style={{display:'block', transform: transformText }}>
          <path
             style={{
               fill:'none',
               stroke: '#000000',
               strokeWidth: 6,
               strokeLinecap: 'round',
             }}
             d="M 13,32 l -8,-16 8,-16" />
        </svg>
  )
}

type SideBarAnimationObj = {
  mainViewWidth: number
  styleBarPlacement: number
}

export const resizeTableSideBar = (direction: Direction, props: SideBarProps) => {
    const {setIsOpenPath, tableComputedData, parentAnimationStart, 
            parentAnimation, parentAnimationStop} = props
    if (! wasSuspendPointerEventsSuccessful( `Table SideBar - ${direction}` )) { return }
    parentAnimationStart( tableComputedData )
    // IF sideBar is closed, it's visibility is also 'unset'.  This is so tabbing the
    // inputs will NOT sequence throught the rendered, but out-of-view potential sideBar
    // inputs.  But we must make the sideBar visible before we begin the animiation to 
    // expose it.
    if ( direction=== 'open' && sideBarDomNode) {
        sideBarDomNode.style.visibility = 'visible'
    }  

    const {layoutProps} = tableComputedData
    const startingWidth = layoutProps.tableLayoutWidth
    const startingSideBarTransform = (sideBarDomNode) ? sideBarDomNode.style.transform : `translate(0px,,0px)`
    // These two parameters are for resizing the main view width by SIDEBAR_RANGE_OF_MOTION
    const mainViewWidthStart = startingWidth
    const mainViewWidthStop  = startingWidth + ((direction==='open') ? - SIDEBAR_RANGE_OF_MOTION 
                                                                     : + SIDEBAR_RANGE_OF_MOTION )
    //console.log( "widthStart/stop", mainViewWidthStart, mainViewWidthStop ) 
    // These two parameters are for using transform to shift the sideBar by TOTAL_RANGE_OF_MOTION:
    const styleBarPlacementStart = (direction==='open') ?  0 : -SIDEBAR_RANGE_OF_MOTION   
    const styleBarPlacementStop  = (direction==='open') ?  -SIDEBAR_RANGE_OF_MOTION : 0  
    dynamics.animate (
      { mainViewWidth : mainViewWidthStart, styleBarPlacement : styleBarPlacementStart }, // Start
      { mainViewWidth : mainViewWidthStop,  styleBarPlacement : styleBarPlacementStop },  // Stop
      { // Options:
        change: (animationObj: SideBarAnimationObj)=> {
            let {mainViewWidth, styleBarPlacement} = animationObj
            parentAnimation( mainViewWidth ) 
            if ( sideBarDomNode ) { sideBarDomNode.style.transform = `translate(${+styleBarPlacement}px,0px)`  }
            if ( buttonDomNode  ) { buttonDomNode.style.transform  = `translate(${-styleBarPlacement}px,0px)`  }
        },

        complete: () => {
          restorePointerEvents( `Table SideBar - ${direction}` )
          parentAnimationStop( ) 
          if ( sideBarDomNode) { sideBarDomNode.style.transform = startingSideBarTransform  }
          let mods = [ {newVal: (direction==='open'), path : setIsOpenPath} ]
          sessionStateChangeDispatch( mods, `${direction} sideBar` )
        },
        duration: 400,
        friction: 300,
      }
    )
}