import type { ChangeEvent, CSSProperties } from 'react'
import type { Column, Columnlook, FilterRule, SortBy } from '../types'
import type { RootState } from '../redux/store'
import type { PlotXyComputedData } from '../computedDataPlotXY/xy_plotTypes'

import invariant from 'invariant'
import {PureComponent} from 'react'
import {connect}              from 'react-redux'
import reactDispatch          from '../sharedComponents/reactDispatch'
import { getAxisMapping}      from '../computedDataPlotXY/plotUtils'
import {STYLE_PALETTE_TEXT_INPUT_STYLES} from '../sharedComponents/constants'
import {cleanScryInputText2}  from '../sharedFunctions/cleanScryInputText'

type OwnProps = {
  seriesKey: number,
  plotXyComputedData: PlotXyComputedData,
  tableid: string,
  tablelookid: string,
}
type StateProps = {
  columns: Column[],
  lookColumns: Columnlook[],
  colOrder: number[],
  seriesTitle: string,
  seriesFilter: FilterRule[],
  sortBy: SortBy,
  sortByColKey: number,
  colKeyA: number,
  colKeyB: number,

  isDeletedTableColKeyA: boolean,
  isDeletedTableColKeyB: boolean,
  isDeletedTableSortByColKey: boolean,

  isUnsetSeriesColKeyA: boolean,
  isUnsetSeriesColKeyB: boolean,
  isUnsetSeriesSortByColKey: boolean,

  isDataTypeMismatchA: boolean,
  isDataTypeMismatchB: boolean,
  isDataTypeMismatchSortByCol: boolean,

  axisNameA: string,
  axisNameB: string,
  isDistribution: boolean,
  isParametric: boolean,

}
type Props = OwnProps & StateProps

const COL0_WIDTH = 50   // Bottom, Right, Left, etc.
const COL1_WIDTH = 89   // The text 'Axis Values'
const COL2_WIDTH = 257


class EditPlotSeriesDataRender extends PureComponent<Props> {

  // This function called whenever the column assigned to anySeries/anyAxis changes
  // This function needs axis A,B,C input argument, currently encoded in the 'id'.
  colKeyChangeHandler = ( id: 'colKeyA' | 'colKeyB' | 'colKeyC' | 'sortByColKey', newColKey:number ) : void => {
    const {seriesKey, columns, colKeyA, colKeyB, sortByColKey, seriesTitle } = this.props

    if ( (id === 'colKeyA'      && colKeyA      !== newColKey)  ||
         (id === 'colKeyB'      && colKeyB      !== newColKey)  ||
         (id === 'sortByColKey' && sortByColKey !== newColKey)  ) {

      let mods = []
      mods.push({ newVal: newColKey, path: `attributes.series[${seriesKey}].${id}` })
      if ( id === 'sortByColKey' ) {
        mods.push({ newVal: 'valueColKey', path: `attributes.series[${seriesKey}].sortBy` })
      }
      // If the title is not defined, use the new colTitle:
      if ( seriesTitle === 'Undefined Series' || seriesTitle === '') {
        mods.push({ newVal: columns[newColKey].colTitle, path: `attributes.series[${seriesKey}].seriesTitle` })
        mods.push({ newVal: columns[newColKey].colDescription, path: `attributes.series[${seriesKey}].seriesDescription` })
      }
      reactDispatch( mods, `Style - Series Column ${id}` )
    }
  }

  seriesTitleChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const {seriesKey, seriesTitle} = this.props
    const options = { maxCharCount: 40 }
    var {newValue, newSelectionStop} = cleanScryInputText2( e.target.value, e.target.selectionEnd ?? 0, options  )
    // When empty, we default to a 'red' text saying 'Missing Name'
    // If user types after this (e.g. 'Missing NameA') to begin a real name,
    // Then delete the 'Missing Name' characters.
    if ( newValue.slice(0,12) === 'Missing Name' && newValue.length > 12 ) {
      newValue = newValue.slice(12)
      newSelectionStop -= 12
    }
    // Same if they type the new name starting at index 0.
    if ( newValue.slice(-12) === 'Missing Name' && newValue.length > 12 ) {
      newValue = newValue.slice(0, newValue.length-12)
      newSelectionStop -= 12
    }
    if ( e.target.value.length !== newValue.length ) {
      e.target.value = newValue.slice()  // This line also resets selection start to useless value.
      e.target.selectionStart = newSelectionStop
      e.target.selectionEnd   = newSelectionStop
    }
    if (seriesTitle !== newValue ) {
      let mods = []
      mods.push({ newVal: newValue, path: `attributes.series[${seriesKey}].seriesTitle` })
      reactDispatch( mods, 'Style - Series Title' )
    }
  }


  render() {
    const {columns, colOrder, isDistribution, isParametric,
        colKeyA, colKeyB, sortByColKey, isDeletedTableColKeyA,
        isDeletedTableColKeyB, isDeletedTableSortByColKey,
        isUnsetSeriesColKeyA, isUnsetSeriesColKeyB, isUnsetSeriesSortByColKey,
        isDataTypeMismatchA, isDataTypeMismatchB, isDataTypeMismatchSortByCol,
        seriesTitle, axisNameA, axisNameB} = this.props

    const rowStyle: CSSProperties = {
      position: 'relative', top:0, left:0, // Sets a local coord system for this line
      fontSize: 14,
      alignItems: 'center',
      display: 'flex',
      height: 22,
      marginTop: 2,
      //background: 'pink',
    }
    //const colKeyC = 0
    const isSeriesNameMissing = seriesTitle === ''
    const isColKeyAerroneous   = isUnsetSeriesColKeyA  || isDeletedTableColKeyA      || isDataTypeMismatchA
    const isColKeyBerroneous   = isUnsetSeriesColKeyB  || isDeletedTableColKeyB      || isDataTypeMismatchB
    const isSortByKeyerroneous = isUnsetSeriesSortByColKey || isDeletedTableSortByColKey || isDataTypeMismatchSortByCol

    var thisDataTypeA      = (colKeyA >= 0) ? columns[colKeyA].colDataType : 'unset'
    var thisDataTypeB      = (colKeyB >= 0) ? columns[colKeyB].colDataType : 'unset'
    var thisDataTypeSortBy = (sortByColKey >= 0) ? columns[sortByColKey].colDataType : 'unset'
    if ( thisDataTypeA.slice(0,6) === 'number' ) { thisDataTypeA = 'number' }
    if ( thisDataTypeB.slice(0,6) === 'number' ) { thisDataTypeB = 'number' }
    if ( thisDataTypeSortBy.slice(0,6) === 'number' ) { thisDataTypeSortBy = 'number' }

    return (
      <div className={'rc_EditPlotSeriesData'}>

          {/* SERIES NAME  */}
          <div style={rowStyle}>
            <div style={{  width:COL0_WIDTH + COL1_WIDTH }}>{'Data Series Name\u2009:'}</div>
            <input
              id='seriesTitle'
              onChange={this.seriesTitleChangeHandler}
              style={{
                width: COL2_WIDTH,
                ...STYLE_PALETTE_TEXT_INPUT_STYLES,
                color: isSeriesNameMissing ? 'red' : 'unset',
              }}
              value={isSeriesNameMissing ? 'Missing Name' : seriesTitle}
              autoComplete='off'
              spellCheck='false'
            />
          </div>

          {/* SERIES SELECTOR (sets the colKey) */}
          <div style={rowStyle}>
            <div style={{width:COL0_WIDTH}}>{axisNameA}</div>
            <div style={{width:COL1_WIDTH}}>{'Axis Values\u2009:'}</div>
            <select
              id='ColKeyA'
              onChange={ (e)=>this.colKeyChangeHandler('colKeyA', Number(e.target.value)) }
              style={{width: COL2_WIDTH,
                      // Two possible error conditions are red text:
                      //    - The column has not yet been set
                      //    - Column was set, but then subsequently deleted
                      color: isColKeyAerroneous ? 'red' : 'unset',
                      ...STYLE_PALETTE_TEXT_INPUT_STYLES,
                      paddingLeft: 0,
                    }}
              value={colKeyA}
            >
              {(
                (_: string)=>{
                  // Set an option for colKeyA === -1
                  // This will show as a red error message instead of colKey's name.
                  switch (true) {
                    case isUnsetSeriesColKeyA:
                      return <option key={-1} value={-1}>{`Choose a Table Column`}</option>
                    case isDeletedTableColKeyA:
                      return <option key={-1} value={-1}>{`Deleted Column: ${columns[colKeyA].colTitle}`}</option>
                    case isDataTypeMismatchA:
                      return <option key={-1} value={-1}>{`Bad dataType: ${columns[colKeyA].colTitle} (${thisDataTypeA})`}</option>
                    default:
                      return null
                }}
              )('call/execute this anonymous function!') }

              {colOrder.map((colKey) => {
                // These are the options for all the valid colKeys.
                // These are in colOrder !!
                // And colOrder skips deleted columns.
                // And we will also skip columns of unsupported dataTypes. (links, ?? )
                let colType = columns[colKey].colDataType
                if ( colType.slice(0,6) === 'number' ) { colType = 'number' }
                if (  colType === 'number' ||
                     (colType === 'string' && !isParametric )) {
                  return (
                    <option key={colKey} value={colKey}>
                      {`${columns[colKey].colTitle} (${colType})`}
                    </option>
                )}
                return null
              })}
            </select>
          </div>



{!isDistribution &&
          <div style={rowStyle}>
            <div style={{width:COL0_WIDTH}}>{axisNameB}</div>
            <div style={{width:COL1_WIDTH}}>{'Axis Values\u2009:'}</div>
            <select
              id='ColKeyB'
              onChange={ (e)=>this.colKeyChangeHandler('colKeyB', Number(e.target.value)) }
              style={{width: COL2_WIDTH,
                      // Two possible error conditions are red text:
                      //    - The column has not yet been set
                      //    - Column was set, but then subsequently deleted
                      color: isColKeyBerroneous ? 'red' : 'unset',
                      ...STYLE_PALETTE_TEXT_INPUT_STYLES,
                      paddingLeft:0,
                    }}
              value={colKeyB}
            >

              {(
                (_: string)=>{
                  // Set an option for colKeyB === -1
                  // This will show as a red error message instead of colKey's name.
                  switch (true) {
                    case isUnsetSeriesColKeyB:
                      return <option key={-1} value={-1}>{`Choose a Table Column`}</option>
                    case isDeletedTableColKeyB:
                      return <option key={-1} value={-1}>{`Deleted Column: ${columns[colKeyB].colTitle}`}</option>
                    case isDataTypeMismatchB:
                      return <option key={-1} value={-1}>{`Bad dataType: ${columns[colKeyB].colTitle} (${thisDataTypeB})`}</option>
                    default:
                      return null
                  }}
              )('call/execute this anonymous function!') }

              {colOrder.map((colKey) => {
                let colType = columns[colKey].colDataType
                if ( colType.slice(0,6) === 'number' ) { colType = 'number' }
                if (  colType === 'number' ||
                     (colType === 'string' && !isParametric )) {
                  return (
                      <option key={colKey} value={colKey}>
                          {`${columns[colKey].colTitle} (${colType})`}
                      </option>
                  )
                }
                return null
              })}
            </select>
          </div>
}


          {/* Right Axis colKey input */}
{false  &&  <div></div> }


          {/* sortByColKey input */}
{ isParametric &&
          <div style={rowStyle}>
            <div style={{width:COL0_WIDTH}}>{'Sort By'}</div>
            <div style={{width:COL1_WIDTH}}>{'Axis Values\u2009:'}</div>
            <select
              id='sortByColKey'
              onChange={ (e)=>this.colKeyChangeHandler('sortByColKey', Number(e.target.value)) }
              style={{width: COL2_WIDTH,
                      // Two possible error conditions are red text:
                      //    - The column has not yet been set
                      //    - Column was set, but then subsequently deleted
                      color: isSortByKeyerroneous ? 'red' : 'unset',
                      ...STYLE_PALETTE_TEXT_INPUT_STYLES,
                      paddingLeft:0,
                    }}
              value={sortByColKey}
            >

              {(
                (_: string)=>{
                  // Set an option for colKeyB === -1
                  // This will show as a red error message instead of colKey's name.
                  switch (true) {
                    case isUnsetSeriesSortByColKey:
                      return <option key={-1} value={-1}>{`Choose a Table Column`}</option>
                    case isDeletedTableSortByColKey:
                      return <option key={-1} value={-1}>{`Deleted Column: ${columns[sortByColKey].colTitle}`}</option>
                    case isDataTypeMismatchSortByCol:
                      return <option key={-1} value={-1}>{`Bad dataType: ${columns[sortByColKey].colTitle} (${thisDataTypeSortBy})`}</option>
                    default:
                      return null
                  }}
              )('call/execute this anonymous function!') }

              {colOrder.map((colKey) => {
                let colType = columns[colKey].colDataType
                if ( colType.slice(0,6) === 'number' ) {
                  return (
                      <option key={colKey} value={colKey}>
                          {`${columns[colKey].colTitle} (number)`}
                      </option>
                  )
                }
                return null
              })}
            </select>
          </div>
}



      </div>
    )
  }
}

const mapState = (state: RootState, ownProps: OwnProps): StateProps => {
  const {isTransposed, isMirrored, plotColDataType, seriesAttributesArray } = ownProps.plotXyComputedData
  const thisSeries = seriesAttributesArray[ ownProps.seriesKey ]
  const tablelook = state.api.resources.tablelooks[ownProps.tablelookid] ?? null
  const table     = state.api.resources.tables[ownProps.tableid] ?? null
  invariant(table, 'EditPlotSeriesData mapState called with no table resource')
  const {columns} = table.attributes
  invariant(tablelook, 'EditPlotSeriesData mapState called with no tablelook resource')
  const {colOrder, lookColumns} = tablelook.attributes
  const isDistribution = (plotColDataType === '1Col')
  const isParametric   = (plotColDataType === '3Col')
  const {axisName: axisNameA} = getAxisMapping( 'A', isTransposed, isMirrored )
  var   {axisName: axisNameB} = getAxisMapping( 'B', isTransposed, isMirrored )
  const {seriesTitle, seriesFilter, colKeyA, colKeyB, sortBy, sortByColKey,
          isUnsetSeriesColKeyA, isUnsetSeriesColKeyB, isUnsetSeriesSortByColKey,
          isDataTypeMismatchA, isDataTypeMismatchB, isDataTypeMismatchSortByCol,
          isDeletedTableColKeyA, isDeletedTableColKeyB, isDeletedTableSortByColKey } = thisSeries

  return {
    columns,
    lookColumns,
    colOrder,
    seriesTitle,
    seriesFilter,
    sortBy,
    sortByColKey,
    colKeyA,
    colKeyB,

    isDeletedTableColKeyA,
    isDeletedTableColKeyB,
    isDeletedTableSortByColKey,

    isUnsetSeriesColKeyA,
    isUnsetSeriesColKeyB,
    isUnsetSeriesSortByColKey,

    isDataTypeMismatchA,
    isDataTypeMismatchB,
    isDataTypeMismatchSortByCol,

    axisNameA,
    axisNameB,
    isDistribution,
    isParametric,
  }
}

const EditPlotSeriesData = connect(mapState, null)(EditPlotSeriesDataRender)
export default EditPlotSeriesData
