import equivalent from './equivalent'

const DEBUG = false

export const shouldComponentUpdateImpl = (curProps, nextProps, curState, nextState, classnameIn) : bool => {

  const propKeys = Object.keys(curProps)

  // className is embedded in the Props object.
  // However, if className is undefined, the classNameIn
  // seems to always have a useful value.
  var className = curProps.className
  if ( className === undefined ) { className = classnameIn }
  const outputErrMsg = ( DEBUG )
      ? ( msg ) : void => console.log('Update ReactVis: YES', className, msg)
      : ( msg ) : void => {}

  if ( propKeys.length !== Object.keys(nextProps).length ) {
    outputErrMsg( 'curProps.length !== nextProps.length' )
    return true
  }

  // Use forLoop so a 'return' means exit function.
  // Exit on first mismatch.
  for (let i=0; i<propKeys.length; i++) {
      var key = propKeys[i]
      if (key === 'data' && curProps['data'] !== nextProps['data']) {
          outputErrMsg( `  currentData !== nextData  sKey:${curProps.index}  ${curProps.plotPtAttributeB}`)
          return true
      }
      else if (key === '_allData') {
          // _allData is an array with data for each series, may be huge.
          // just check that the data arrays are strictly equal
          //mismatch = curProps[key].some((data, index)=>(data !== nextProps[key][index]))
      }
      else if (key === 'layers' && curProps['layers'].length !== nextProps['layers'].length) {
          // layers is an array of objects with two props:
          // renderLayer: a static function that does the rendering for the layer,
          // newProps: the full props structure that react-vis gets, including data and _allData
          // For this reason we handle it specially
          //console.log( 'key is layers')
          outputErrMsg( `  Current layers.length !== next layers.length` )
          return true
      }
      else if (key === 'layers') {
          // layers is an array of objects with two props:
          // renderLayer: a static function that does the rendering for the layer,
          // newProps: the full props structure that react-vis gets, including data and _allData
          // For this reason we handle it specially
          let layers = curProps['layers']
          var shouldUpdate = false
          // Force an update any change in layers, and or newProps!
          // So we 'or' the prior shouldUpdate value.
          for (let j=0; j<layers.length; j++) {
            // Check equivalence of each renderLayer
            if ( !equivalent(layers[j].renderLayer, nextProps['layers'][j].renderLayer)) {
              outputErrMsg( `  misMatch on key:renderLayer --`,
                       layers[j].renderLayer,
                       nextProps['layers'][j].renderLayer )
              shouldUpdate = shouldUpdate || true
            }
            // Force an update any change in layers, and or newProps!
            // So we 'or' the prior shouldUpdate value.
            shouldUpdate = shouldUpdate ||
                    shouldComponentUpdateImpl(layers[j].newProps, nextProps['layers'][j].newProps,
                     null, null, layers[j].newProps.className ) //`Layer_index_${j}`)
          }
          if ( shouldUpdate ) {
            outputErrMsg( `  misMatch on one or more children (RenderLayers).` )
            return true
          }
      }

      else {
        const start = performance.now()
        let misMatch = !equivalent(curProps[key], nextProps[key])
        const time = performance.now() - start
        if ( misMatch ) {
          outputErrMsg( ` misMatch on key:${key}`,curProps[key], nextProps[key])
          return true
        }
        if (DEBUG && time > 1) {
          console.log(`ShouldComponentUpdateImpl took ${time} on`, curProps[key])
        }
      }

  }


  // If we reach here with no prior return, then there is NO mismatch!
  if (DEBUG) {
    var  id = ''
    if (curProps.plotPtAttributesB) {
      id = `sKey:${curProps.index}  ${curProps.plotPtAttributeB}`
    }
    console.log(`Update ReactVis: NO props changes found:  ${className}  ${id}` )
  }
  return false
}


