import constants from '../sharedComponents/constants'

const leftOverlap = constants.ALLOWED_LEFT_OVERLAP_OF_EDITOR_WITH_NAVBAR
const rightOverlap= constants.ALLOWED_RIGHT_OVERLAP_OF_EDITOR_WITH_SIDEBAR

const constrainPosition = (
  cellLeft:number, cellTop:number, cellWidth:number, cellHeight:number,
  paletteLeft:number=0, paletteTop:number=0, paletteWidth:number=0, paletteHeight:number=0,
  availableWidthIncludingSideBar:number, availableHeight:number, sideBarWidth:number,
  isActiveDrag:boolean ) : {constrainedLeft:number, constrainedTop:number} => {

      var MIN_LEFT_VALUE = - leftOverlap
      var MAX_LEFT_VALUE =  availableWidthIncludingSideBar - paletteWidth - Math.max( 30, sideBarWidth - rightOverlap)
      var MIN_TOP_VALUE  = -10
      var MAX_TOP_VALUE  = availableHeight - paletteHeight - 12
      // If the floating window is wider than available space, this order of
      // constraints will place the window to far left, with right side of
      // window truncated.
      var constrainedLeft = Math.min( paletteLeft, MAX_LEFT_VALUE )
      constrainedLeft = Math.max( constrainedLeft, MIN_LEFT_VALUE )
      // If the floating window is taller than available space, this order of
      // constraints will place the window at top edge, with bottom portion of
      // window truncated.
      var constrainedTop = Math.min(  paletteTop, MAX_TOP_VALUE )
      constrainedTop  = Math.max( constrainedTop, MIN_TOP_VALUE  )
      //console.log( 'parentWidth, paletteWidth, paletteLeft, sideBarWidth, MAX_LEFT_VALUE, constrainedLeft',
      //    parentWidth, paletteWidth, paletteLeft, sideBarWidth, MAX_LEFT_VALUE, constrainedLeft )

      if ( isActiveDrag ) return { constrainedTop, constrainedLeft }

      // I will define 'blocking' the view of the selected cell as
      // the center of the selected cell falls beneath the floating
      // palette -- hence 'blocking' our view of the selected cell.
      const centerX = cellLeft+ cellWidth / 2
      const centerY = cellTop + cellHeight / 2
      const doesEditorBlockSelectedCell = ( ) => {
        return  ( centerY > constrainedTop  && centerY < constrainedTop  + paletteHeight &&
                  centerX > constrainedLeft && centerX < constrainedLeft + paletteWidth)
      }


      const parentWidth  = availableWidthIncludingSideBar  - sideBarWidth
      const parentHeight = availableHeight
      let isCellLeftOfCenter = false

      if ( doesEditorBlockSelectedCell()) {
        // Drag is NOT in progress (palette stationary) AND palette is blocking selected cell!
        // Shift palette to either far left or right.  Whichever provides the most room.
        if ( centerX > parentWidth/2 ) {
          isCellLeftOfCenter = false
          constrainedLeft = MIN_LEFT_VALUE
        } else {
          // center of cell is on left half of table
          isCellLeftOfCenter = true
          constrainedLeft = MAX_LEFT_VALUE
        }
      }

      // If the table has insufficient width, then far left/far right placement
      // may still not be sufficient.  If we still block the selected cell, try
      // shifting to the far top/bottom placement!  This will result in the
      // editor placement being in the 'farthest' available corner.
      if ( doesEditorBlockSelectedCell() ) {
        // Drag is NOT in progress (palette stationary) AND palette is blocking selected cell!
        // Shift palette to either far left or right.  Whichever provides the most room.
        if ( centerY > parentHeight/2 ) {
          // center of cell is on bottom half of table
          constrainedTop = MIN_TOP_VALUE
        }else{
          // center of cell is on top half of table
          constrainedTop = MAX_TOP_VALUE
        }
      }

      // Maybe the table extent has both insufficient width AND insufficient
      // height to fully display the floating palette!
      // In this case:
      // 1) Place the draggable top of the floating palette at the top
      //    edge of the available table space.  So the floating window
      //    can still be dragged.
      // 2) Place the editor to the left/right side that has the most room.
      // 3) Place the edge of the editor adjacent to the edge of the cell.
      // By definition, at this point there is no room for the editor, so the
      // editor will be forced to overrun our constrained position on either
      // the left or the right edge.  If editor is unusable, then user has no
      // choice but to increase the size of the browser window, so the editor
      // can be properly displayed.
      if ( doesEditorBlockSelectedCell() ) {
        constrainedTop = MAX_TOP_VALUE
        constrainedLeft = isCellLeftOfCenter ?  cellLeft + cellWidth : cellLeft - paletteWidth
      }

      return { constrainedTop, constrainedLeft }
  }

  export default constrainPosition
