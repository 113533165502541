
import {Component} from 'react'
import invariant from 'invariant'
import StyleSelectBox         from '../sharedComponents/StyleSelectBox'
import constants from '../sharedComponents/constants'
import {sessionStateChangeDispatch} from '../sharedComponents/reactDispatch'

// This Component is the selection button option
// for 'editMode' verses 'viewMode' that appears
// in the Nav Menu Bar when the tableOwnerid or plotOwnerid === userid
// It's value (menuOption_isEditMode:boolean) is owned and managed by appState.
// It's value is universal to both tables and plots.  (If you set
// menuOption_isEditMode = true for table, it is also true for plots.)
// HOWEVER, you are only allowed editing privileges is BOTH
// (menuOption_isEditMode === true && ownerid === userid)

const spacer = constants.nonBreakingSpace + constants.thinSpaceChar // To align horz text between the two lines.
const SELECT_INDENT = 40
const SELECT_PRESPACER = 0
const FOREGROUND_COLOR = '#ffffff'
const BACKGROUND_CIRCLE_COLOR = constants.SCRY_BLUE
const WIDTH = 350

type Props = {
  appState: any,
  view: 'tableView' | 'xyPlotView',
}

export default class EditModeSelector extends Component<Props> {

  setEditModeTrue  = () => {
    sessionStateChangeDispatch( [{newVal:true , path: 'menuOption_isEditMode'}], 'set EditMode true' )
  }
  setEditModeFalse = () => {
    sessionStateChangeDispatch( [{newVal:false, path: 'menuOption_isEditMode'}], 'set EditMode false' )
  }


  render() {
    const {menuOption_isEditMode} = this.props.appState
    const {view} = this.props
    var editModeText = ''
    var viewModeText = ''
    switch (view) {
      case 'tableView' :
        editModeText = `Edit ${spacer}Mode - Only available to table owner`   // assumption: rendering table
        viewModeText = `View Mode - Table as seen by others`
        break
      case 'xyPlotView':
        editModeText = `Edit ${spacer}Mode - Only available to plot owner`
        viewModeText = `View Mode - Plot as seen by others`
        break
      default:
        invariant( true, 'Missing case in EditModeSelector' )
    }
    return (

          <div className={'rc_EditModeSelector'}
            style={{
              flex: '0 0 auto',   // TopMenuBar positions menu items using flex row.
              position:'relative', height: constants.TOP_MENUBAR_HEIGHT, width: WIDTH, top:2,
              display: 'flex', flexFlow: 'column nowrap', //justifyContent:'flexStart',
            }}>


                <StyleSelectBox className={'editModeSelectBox'}
                  indent={SELECT_INDENT}
                  id={'editMode'}
                  preSpacer={SELECT_PRESPACER}
                  onClick={ this.setEditModeTrue }
                  isSelected={ menuOption_isEditMode? true : false }
                  text={editModeText}
                  textWidth={285}
                  foregroundColor={FOREGROUND_COLOR}
                  backgroundCircleColor={BACKGROUND_CIRCLE_COLOR}
                  outlineCircleColor={'#d0d0d0'}/>

                <StyleSelectBox className={'viewModeSelectBox'}
                  indent={SELECT_INDENT}
                  id={'viewMode'}
                  preSpacer={SELECT_PRESPACER}
                  onClick={ this.setEditModeFalse }
                  isSelected={menuOption_isEditMode? false : true }
                  text={viewModeText}
                  textWidth={270}
                  foregroundColor={FOREGROUND_COLOR}
                  backgroundCircleColor={BACKGROUND_CIRCLE_COLOR}
                  outlineCircleColor={'#d0d0d0'}/>

          </div>
    )
  }
}
