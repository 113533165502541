import type { PlotXyComputedData } from '../computedDataPlotXY/xy_plotTypes'

import {PureComponent} from 'react'
import EditInputString        from '../sharedComponents/EditInputString'


type OwnProps = {
  plotXyComputedData: PlotXyComputedData,
}

const WIDTH = 520
const HEIGHT = 105
const MARGIN_FLOATING_PALLET_EDGES = 30
const FONT_SIZE = 16

export default class EditXYMainTitle extends PureComponent<OwnProps> {
  render() {
    const {mainTitleDisplayed, plotid } = this.props.plotXyComputedData

    // isValueRequired and isValueRequiredErrMsg refer to the input Box, NOT the much larder 'Main Title'. 
    // We don't need an error message in the input box, because rendering displays an errmsg 
    // in the large displayed Main Title.

    return (
        <div style={{
          marginLeft : MARGIN_FLOATING_PALLET_EDGES, 
          marginRight: MARGIN_FLOATING_PALLET_EDGES,
           width: WIDTH, 
           height: HEIGHT,
          }}>
        
          
        <p>Alpha Testers: 
          This popup will contain general information 
          about the plot.  Specifics to be decided.</p>
          <div>Title:</div>
          <div className={'container for text input'}>
                <EditInputString 
                  resourceStringValue={mainTitleDisplayed} 
                  resourceType= 'plots'
                  resourceID  = {plotid}

                  modPath     = 'attributes.mainTitle'
                  modMessage  = 'Edit publisher title'

                  canEdit         = {true}
                  isValueRequired = {false}
                  inputStyleOptions = {{
                    width: WIDTH,  
                    height  : 24,
                    fontSize: FONT_SIZE,
                  }} 
                  cleanScryInputOptions = {{maxCharCount:80}}   
                /> 
            </div>
        </div>
    )
  }
}





/*

type InputTitleProps = {
  mainTitle : string,
  plotid: string, 
}

class InputPlotTitle extends PureComponent<InputTitleProps> {

  FONT_SIZE = 16
  HEIGHT = 24
  WIDTH = '100%'

  
  constructor(props: Props) {
    super(props)
    this.state={
      inputPlotTitleText : '',  // This value MAY diverge
      lastPlotid: '',
    }
  }

  static getDerivedStateFromProps(props, state) {
    // Poor man's editior:
    var {title, plotid} = props
    if ( plotid !== state.lastPlotid ) {
      return {
        lastPlotid       : props.plotid,
        inputPlotTitleText : props.title,    // 
      }
    }
    return { }
  }

  mainTitleChangeHandler = (e: SyntheticInputEvent<HTMLInputElement>): void => {
    debugger
    const options = { maxCharCount: 40 }
    var {newValue, newSelectionStop} = cleanScryInputText2( e.target.value, e.target.selectionEnd, options  )
    if ( e.target.value.length !== newValue.length ) {
      e.target.value = newValue.slice()  // This line also resets selection start to useless value.
      e.target.selectionStart = newSelectionStop
      e.target.selectionEnd   = newSelectionStop
    }
    if ( this.props.title !== newValue) {
      let mods = []
      mods.push({ newVal: newValue, path: `attributes.mainTitle` })
      reactDispatch( mods, `Change plot's main Title` )
    }
  }


  render() {
    debugger
    return (
      <input className={'rc_TitleEdit'}
        onChange={this.mainTitleChangeHandler}
        onClick={ ()=>{} }
        readOnly={false}
        style={{
          backgroundColor : plotLayoutConsts.backgroundColor, 
          border: 'none',
          fontSize: this.FONT_SIZE,
          height: this.HEIGHT,
          width: this.WIDTH,
          textAlign: 'center',
          width: '100%',
        }}
        type='text'
        value={this.props.mainTitle}
        autoComplete='off'
        spellCheck='false'
      />
    )
  }
}

*/
