import type { InternalColDataType, PlotColDataType, SortBy } from '../types'
import type { GetTableValue } from '../computedDataTable/getDefaultTableComputedData'
import type { FilteredPtsStats, SparsePlotPt, StringAxis_TextToIndexObj } from './xy_plotTypes'

import invariant from 'invariant'
import { plotLayoutConsts } from '../viewPlotXY/plotLayoutConsts'
import { getLinearCoefficients } from './plotUtils'
import { getDefaultFilteredPtsStats, getDefaultSparsePlotPt } from './xy_plotTypes'


const emptySeries = Array<SparsePlotPt>()     // Use a constant ref so comparisons of empty series always match.

export type FilterSeriesParams = {
  seriesWideDataTypeA: InternalColDataType
  seriesWideDataTypeB: InternalColDataType
  colKeyA: number
  colKeyB: number
  plotColDataType: PlotColDataType
  internalDataTypeA: InternalColDataType
  internalDataTypeB: InternalColDataType
}

export type FilterSeriesRefs = {
  seriesFilteredRowKeys: number[]
}

export type FilterSeriesOtherArgs = {
  getTableValue: GetTableValue
}

export type FilterSeriesResult = {
  seriesRowPlotPts: SparsePlotPt[]
}

export const createSeriesRowPlotPts = (paramsObj: FilterSeriesParams, refsObj: FilterSeriesRefs, otherArgsObj: FilterSeriesOtherArgs): FilterSeriesResult => {

  const { seriesWideDataTypeA, seriesWideDataTypeB, colKeyA, colKeyB, plotColDataType,
    internalDataTypeA, internalDataTypeB } = paramsObj
  const { seriesFilteredRowKeys } = refsObj
  const { getTableValue } = otherArgsObj
  if (seriesFilteredRowKeys.length === 0 ||   // Case of no filtered plotPts
    (internalDataTypeA !== seriesWideDataTypeA) ||
    (internalDataTypeB !== seriesWideDataTypeB)) { return { seriesRowPlotPts: emptySeries } }
  let seriesRowPlotPts = Array<SparsePlotPt>()
  var A, isValidA, B, isValidB
  if (plotColDataType === '1Col') {
    for (const rowKey of seriesFilteredRowKeys) {
      ({ value: A, isValid: isValidA } = getPlotValue(colKeyA, rowKey, seriesWideDataTypeA, getTableValue))
      if (isValidA) {   // Not every filtered row becomes a plotPt !
        var thisPt = getDefaultSparsePlotPt()  // seals object
        thisPt = {
          type: 'sparse',
          A: (seriesWideDataTypeA === 'string') ? 0 : Number(A),
          Astring: (seriesWideDataTypeA === 'string') ? String(A) : '',
          B: 0,
          Bstring: '',
          rowKey: rowKey
        }
        seriesRowPlotPts.push(thisPt)
      }
    }
    return { seriesRowPlotPts }
  }
  // else plotColDataType is 2Col or 3Col
  for (const rowKey of seriesFilteredRowKeys) {
    ({ value: A, isValid: isValidA } = getPlotValue(colKeyA, rowKey, seriesWideDataTypeA, getTableValue));
    ({ value: B, isValid: isValidB } = getPlotValue(colKeyB, rowKey, seriesWideDataTypeB, getTableValue))
    if (isValidA && isValidB) { // Not every filtered row becomes a plotPt !
      thisPt = getDefaultSparsePlotPt()  // seals object
      thisPt = {
        type: 'sparse',
        A: (seriesWideDataTypeA === 'string') ? 0 : Number(A),
        B: (seriesWideDataTypeB === 'string') ? 0 : Number(B),
        Astring: (seriesWideDataTypeA === 'string') ? String(A) : '',
        Bstring: (seriesWideDataTypeB === 'string') ? String(B) : '',
        rowKey: rowKey
      }
      seriesRowPlotPts.push(thisPt)
    }
  }
  return { seriesRowPlotPts }

}

export type SortSeriesParams = {
  isStringAxisA: boolean
  isStringAxisB: boolean
  sortBy: SortBy
  sortByColKey: number
  plotColDataType: PlotColDataType
}

export type SortSeriesRefs = {
  textToIndexObjA: StringAxis_TextToIndexObj
  textToIndexObjB: StringAxis_TextToIndexObj
  seriesRowPlotPts: SparsePlotPt[]
}

export type SortSeriesOtherArgs = {
  getTableValue: GetTableValue
}

export type SortSeriesResult = {
  seriesRowPlotPts_sortedInA: SparsePlotPt[]
}

export const createSeriesRowPlotPts_sortedInA = (paramsObj: SortSeriesParams, refsObj: SortSeriesRefs, otherArgsObj: SortSeriesOtherArgs): SortSeriesResult => {
  const { textToIndexObjA, textToIndexObjB, seriesRowPlotPts } = refsObj  // may be null if not a string axis
  const { isStringAxisA, isStringAxisB, sortBy, sortByColKey, plotColDataType } = paramsObj
  const { getTableValue } = otherArgsObj
  // Initialize the filterSortedPtsArr as a clone of seriesRowPlotPts:
  const seriesRowPlotPts_sortedInA = structuredClone(seriesRowPlotPts)
  // Back annotate the pt.A and/or the pt.B values
  if (isStringAxisA) {
    for (const thisPt of seriesRowPlotPts_sortedInA) {
      var { Astring } = thisPt
      if (Astring === '') { Astring = '(no text)' }
      thisPt.A = textToIndexObjA[Astring].mappedIndex
    }
  }
  if (isStringAxisB) {
    for (const thisPt of seriesRowPlotPts_sortedInA) {
      var { Bstring } = thisPt
      if (Bstring === '') { Bstring = '(no text)' }
      thisPt.B = textToIndexObjB[Bstring].mappedIndex
    }
  }
  // Sort the plotPts (in Place operation)
  if (plotColDataType === '3Col') {
    // The interface (error checking) forces parametric plots 3rd
    // colDataType (the column we sort by) to be 'number'
    sortPlotPts_inPlace(seriesRowPlotPts_sortedInA, sortBy, sortByColKey, 'number', getTableValue)
  } else {
    // 2Col are always sorted by 'valueA'
    sortPlotPts_inPlace(seriesRowPlotPts_sortedInA, 'valueA', -1, 'number', getTableValue)
  }
  return { seriesRowPlotPts_sortedInA }
}

export type StatsSeriesParams = {
}

export type StatsSeriesRefs = {
  seriesRowPlotPts_sortedInA: SparsePlotPt[]
}

export type StatsSeriesOtherArgs = {
}

export type StatsSeriesResult = {
  seriesRowPlotPts_stats: FilteredPtsStats
}

export const createSeriesRowPlotPts_stats = (_1: StatsSeriesParams, refsObj: StatsSeriesRefs, _2: StatsSeriesOtherArgs): StatsSeriesResult => {
  const { seriesRowPlotPts_sortedInA } = refsObj  // may be null if not a string axis
  //const {isStringAxisA, isStringAxisB} = paramsObj
  var minB = +Infinity, minA = +Infinity
  var maxB = -Infinity, maxA = -Infinity
  var sumA = 0, sumB = 0, sumAB = 0, sumAA = 0, sumlogA = 0, sumlogB = 0, sumlogAlogA = 0
  var sumBB = 0, sumlogBlogB = 0, sumlogAB = 0, sumAlogB = 0, sumlogAlogB = 0
  var isIntegersInA = true // assumption
  var isNonnegativeInA = true // assumption

  for (const thisPt of seriesRowPlotPts_sortedInA) {
    // Accumulate the A/B statistics.
    var A = thisPt.A
    var B = thisPt.B
    let logA = Math.log10(Math.abs(A))
    let logB = Math.log10(Math.abs(B))
    minA = Math.min(minA, A)
    maxA = Math.max(maxA, A)
    minB = Math.min(minB, B)
    maxB = Math.max(maxB, B)
    sumA += A
    sumlogA += logA
    sumB += B
    sumlogB += logB
    sumAB += A * B
    sumlogAB += logA * B
    sumAlogB += A * logB
    sumlogAlogB += logA * logB
    sumAA += A * A
    sumlogAlogA += logA * logA
    sumBB += B * B
    sumlogBlogB += logB * logB
    isIntegersInA = isIntegersInA && Number.isInteger(thisPt.A)   // fast compute construction
    isNonnegativeInA = isNonnegativeInA && thisPt.A >= 0
  }

  // Next step insures a 'sealed' FilterSeriesStats object:
  // This sets one series of stats, and calcs one series of RegFitCoefs.
  const seriesRowPlotPts_stats = getDefaultFilteredPtsStats({
    numFilteredPts: seriesRowPlotPts_sortedInA.length,
    isIntegersInA, isNonnegativeInA,
    minB, maxB, minA, maxA, sumA, sumB, sumAA, sumBB, sumlogA, sumlogB,
    sumlogAlogA, sumlogBlogB, sumAB, sumlogAB, sumAlogB, sumlogAlogB
  })
  calcLinearRegFitCoefs(seriesRowPlotPts_stats)
  return { seriesRowPlotPts_stats }
}



// Helper function to calculate linear fit coefficients
// for each of four possible lin/log axis configurations
const calcLinearRegFitCoefs = (filteredPtsStats: FilteredPtsStats): void => {
  const n = filteredPtsStats.numFilteredPts
  const { sumA, sumAA, sumB, sumBB, sumAB, sumlogA, sumlogAlogA, sumlogAB,
    sumlogB, sumlogBlogB, sumAlogB, sumlogAlogB } = filteredPtsStats
  filteredPtsStats.linfit_AB = getLinearCoefficients(sumA, sumAA, sumB, sumBB, sumAB, n, n)
  filteredPtsStats.linfit_LogAB = getLinearCoefficients(sumlogA, sumlogAlogA, sumB, sumBB, sumlogAB, n, n)
  filteredPtsStats.linfit_ALogB = getLinearCoefficients(sumA, sumAA, sumlogB, sumlogBlogB, sumAlogB, n, n)
  filteredPtsStats.linfit_LogALogB = getLinearCoefficients(sumlogA, sumlogAlogA, sumlogB, sumlogBlogB, sumlogAlogB, n, n)
}

// Helper function to retrieve and error check tableValues
const getPlotValue = (colKey: number, rowKey: number, dataType: string, getTableValue: GetTableValue): { value: number | string, isValid: boolean } => {
  var { value, isErroneous } = getTableValue(colKey, rowKey, false)
  if (dataType === 'string') {
    // Truncate the string at first line feed and/or max allowed numCharacters:
    let truncatedString = value.slice(0, plotLayoutConsts.maxCharactersForEnumStringAxes)
    let truncatedLineFeed = truncatedString.split(/[\r\n]+/)[0]
    return { value: truncatedLineFeed, isValid: true }
  } else if (dataType === 'number') {
    return {
      value: Number(value),
      isValid: (isErroneous || value === '') ? false : true
    }
  } else if (process.env.NODE_ENV === 'development') {
    invariant(false, `Unexpected dataType '${dataType}' during plot filtering.`)
  }
  return { value: '', isValid: false }
}




const sortPlotPts_inPlace = (data: SparsePlotPt[], sortBy: string,
  sortByColKey: number, dataTypeOf_sortByCol: string, getTableValue: GetTableValue) => {

  // Sorting by numeric valueA
  if (sortBy === 'valueA' || sortBy === 'unset') {
    data.sort(compareA)
  }
  // Sorting by a value in a 3rd column, where 3rd column
  // dataType is typically numeric, but writing this function
  // to allow 3rd column dataType to also be a string.
  else if (sortBy === 'valueColKey' && sortByColKey >= 0) {
    const compareFunction = (dataTypeOf_sortByCol === 'number')
      ? (ptA: SparsePlotPt, ptB: SparsePlotPt) => {
        var { value: val1 } = getTableValue(sortByColKey, ptA.rowKey, false)
        var { value: val2 } = getTableValue(sortByColKey, ptB.rowKey, false)
        var val1n = Number(val1)
        var val2n = Number(val2)
        if (val1n < val2n) {
          return -1
        }
        if (val1n > val2n) {
          return 1
        }
        return 0
      }
      : (ptA: SparsePlotPt, ptB: SparsePlotPt) => {
        var { value: val1 } = getTableValue(sortByColKey, ptA.rowKey, false)
        var { value: val2 } = getTableValue(sortByColKey, ptB.rowKey, false)
        return val1.localeCompare(val2)
      }

    data.sort(compareFunction)
  }
  else {
    if (process.env.NODE_ENV === 'development') {
      invariant(false, `Unexpected switch case: series sortBy is set: ${sortBy}`)
    }
  }
}


const compareA = (ptA: SparsePlotPt, ptB: SparsePlotPt) => {
  if (ptA.A < ptB.A) { return -1 }
  if (ptA.A > ptB.A) { return 1 }
  return 0
}
