import type { PlotXyComputedData } from '../computedDataPlotXY/xy_plotTypes'

import {PureComponent} from 'react'
import EditInputString        from '../sharedComponents/EditInputString'


type OwnProps = {
  plotXyComputedData: PlotXyComputedData,
}

const WIDTH = 420
const HEIGHT= 105
const MARGIN_FLOATING_PALLET_EDGES = 30
const FONT_SIZE = 16

// isValueRequired and isValueRequiredErrMsg refer to the input Box, NOT the much larder 'publisher Title'. 
// We don't need an error message in the input box, because rendering displays an errmsg 
// in the large displayed publisher Title.

export default class EditXYPublisherTitle extends PureComponent<OwnProps> {
  render() {
    const {plotid, publisherTitleDisplayed} = this.props.plotXyComputedData
    return (
        <div style={{
          marginLeft : MARGIN_FLOATING_PALLET_EDGES, 
          marginRight: MARGIN_FLOATING_PALLET_EDGES,
           width : WIDTH, 
           height: HEIGHT,
          }}>
        
          
        <p>Alpha Testers: 
          This popup will contain general information 
          about the publisher.  Specifics to be decided.</p>
          <div>Title:</div>
          <div className={'container for text input'}>
                <EditInputString 
                  resourceStringValue={publisherTitleDisplayed} 
                  resourceType= 'plots'
                  resourceID  = {plotid}

                  modPath     = 'attributes.publisherTitle'
                  modMessage  = 'Edit main title'

                  canEdit         = {true}
                  isValueRequired = {false}
                  inputStyleOptions = {{
                    width: WIDTH,  
                    height  : 24,
                    fontSize: FONT_SIZE,
                  }} 
                  cleanScryInputOptions = {{maxCharCount:60}}   
                /> 
            </div>
        </div>
    )
  }
}




