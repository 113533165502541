import type {StatsDataType}   from '../computedDataTable/updateStatsShared'


export type SessionState = {
  windowInnerWidth: number,
  windowInnerHeight: number,

  lastSearchQueryString: string,
  floatingPallette: {
        activePallette: string,     // Empty string means no pallette is open
        position_left: number,
        position_top : number,
        flexibleSizeParams: [],
        colIndex: number,
        rowIndex: number,
        seriesIndex: number,
  },

  activeTableid: string, // The tableId of the active table, not the search table
  currentTableid: string, // The tableId of the current view, including search tableid.

  isSideBarOpen : {
    tableView : boolean,
    searchView: boolean,
    xyPlotView: boolean,
  },

  sideBarTabIndex : {
    tableView : number,
    searchView: number,
    xyPlotView: number,
  },

  activeStatsColKey : number, 

  activeTableScrollTop : number,
  activeTableScrollLeft: number, 
  currentTableTriplet  : string,

  menuOption_isEditMode: boolean,

  renderIndex: number,
}

export const getDefaultSessionState = () : SessionState => {
  return {
    windowInnerWidth: window.innerWidth,
    windowInnerHeight: window.innerHeight,
    lastSearchQueryString: '',
    floatingPallette: {
        activePallette: '',
        position_left: 300,
        position_top : 100,
        flexibleSizeParams: [],
        colIndex: 0,
        rowIndex: 0,
        seriesIndex: 0,
    },

    activeTableid: '',
    currentTableid: '',

    isSideBarOpen : {
      tableView : false,
      searchView: false,
      xyPlotView  : false,
    },

    sideBarTabIndex : {
      tableView : 0,
      searchView: 0,
      xyPlotView  : 0,
    },

    activeStatsColKey: -1, 

    activeTableScrollTop : 0,
    activeTableScrollLeft: 0, 
    currentTableTriplet  : '',
    menuOption_isEditMode: true,
    renderIndex: 0,
  }
}

//Following are options for refactoring the appState

/*
export type FPname =
    null |     // null means the most common state, which is 'No FloatingPallette Displayed'
    // NavBar and MenuBar
    'createXYplot' | 'createTable' | 'downloadPlot' | 'deletePlot' | 'downloadTable' |
    // XYplot
    'XYplotSeries' | 'XYplotBasisA' | 'XYplotBasisB' | 'XYplotMainTitle' |
    // table
    'rowFiltering' | 'tableHeader' | 'pinnedCell' | 'dataCell' |'tableMainTitle' | 'publisherTitle'

//John: If we add the name to FPconfig, we could use an array of them
//      to store the state of multiple floating pallettes.
export interface FPconfig {
    position_left: number;
    position_top : number;
    flexibleSizeParams: Array<number>;
    colIndex: number | null;
    rowIndex: number | null;
    seriesIndex: number | null;
}

const getDefaultFPconfig = () : FPconfig => {
  return {
    position_left: 300,
    position_top : 100,
    flexibleSizeParams: [],
    colIndex: null,
    rowIndex: null,
    seriesIndex: null,
  }
}

export interface MemoizedResult  {
    result   : Object;
    paramsObj: Object;
    refsObj  : Object;
}


export interface SideBar {
    isEnabled: boolean;
    isOpen : boolean;
    tabIndex: number;
}

const getDefaultSideBar = () : SideBar => {
  return {
    isEnabled: false,
    isOpen : false,
    tabIndex: 0,
  }
}

export interface TableTriplet  {
    tableComputedData       : TableComputedData | null;
    userPlotDerivedState    : Object | null;     //  'xyPlot', 'piePlot', ... // plotTypes
    sourcePlotDerivedState  : Object | null;    //  'xyPlot', 'piePlot', ... // plotTypes
    // Saved Table popup states (one per table)
    FProwFiltering   : FPconfig;
    FPtableMainTitle : FPconfig;
    FPpublisherTitle : FPconfig;
    // Saved Table popup states (one per table column)
    FPtableHeader : Array<FPconfig>;
    FPpinnedCell  : Array<FPconfig>;
    FPdataCell    : Array<FPconfig>;
    // Sidebar state  (opened/close for each of table or plot view).  And current tab index
    tableSideBar : SideBar;
    plotSideBar  : SideBar;
    // Which stats to show at bottom of the table view
    activeStatsColKey : number | null,
}

const getDefaultTableTriplet = () : TableTriplet => {
  return {
    tableComputedData       : null,
    userPlotDerivedState    : null,
    sourcePlotDerivedState  : null,
    // Saved Table popup states (one per table)
    FProwFiltering   : getDefaultFPconfig(),
    FPtableMainTitle : getDefaultFPconfig(),
    FPpublisherTitle : getDefaultFPconfig(),
    // Saved Table popup states (one per table column)
    FPtableHeader : [],
    FPpinnedCell  : [],
    FPdataCell    : [],
    // Sidebar state
    tableSideBar : getDefaultSideBar(),
    plotSideBar  : getDefaultSideBar(),
    // Which stats to show at bottom of the table view
    activeStatsColKey : null,
  }
}

export interface appState  {
    windowInnerWidth: number;
    windowInnerHeight: number;

    // Only one floating pallette will be displayed at a time.
    // FPname === null means NO rendered floating pallette.
    // When FP is open, the state below defines:
    //    - which style of pallette
    //    - geometric config (left, top, all sizable width/height options).
    //    - colKey, rowKey, seriesKey if/when appropriate.
    // When FP opens, some prior saved state is copied to the 'activeFP'
    // When FP closes, the activeFP values are written to the appropriate saved state.
    activeFP       : FPname;   // FPname === null is the typical state of NO open floating pallette.
    activeFPcolKey : number;   // Sometimes the FPconfig is f( colKey )
    activeFProwKey : number;   // Sometimes the FP values displayed are f( colKey, rowKey )
    activeFPconfig : FPconfig;

    lastFPconfig : {
        // ONLY one copy saved for this app:
        createXYplot : FPconfig;
        createTable  : FPconfig;
        downloadTable: FPconfig;
        downloadPlot : FPconfig;
        deletePlot   : FPconfig;
    };

    activeTableTriplet: TableTriplet;
    searchTableTriplet: TableTriplet;

    lastSearchQueryString: string;

    // This menu option is ONLY displayed if userID === ownerID
    // Internal value 'canEdit' is ONLY true if ( userID === ownerID && menuOption_isEditMode === true )
    // This is a single value for all possible Views.
    // Hence:
    //        If you don't own a view's resource, then you don't see the menu option and canEdit always false.
    //        If you do    own a view's resource, then you do    see the menu option and canEdit depends on next value.
    // This value is only set/unset by the menuOption handler, AND as stated above, only available if userID===ownerID
    menuOption_isEditMode: boolean;

    // The function our handlers call to modify this state
    sessionStateChangeDispatch : ( mods: LightweightMod[] ) => void;

    // Next parameter used ONLY for doing a test
    // of how fast react can pass (sometimes unneccsarily)
    // the tableComputedData through the table's domTree.
    // IF it is a constant value, it has no effect on
    // render times or efficiency.
    renderIndex: number;
}

/*

export const getDefaultAppState = (  ) : appState => {
  const newObj = {
    windowInnerWidth : window.innerWidth,
    windowInnerHeight: window.innerHeight,
    activeFP       : null,
    activeFPcolKey : 0,
    activeFProwKey : 0,
    activeFPconfig : getDefaultFPconfig(),
    lastFPconfig : {
        // ONLY one copy saved for this app:
        createXYplot : getDefaultFPconfig(),
        createTable  : getDefaultFPconfig(),
        downloadTable: getDefaultFPconfig(),
        downloadPlot : getDefaultFPconfig(),
        deletePlot   : getDefaultFPconfig(),
    },
    activeTableTriplet: getDefaultTableTriplet(),
    searchTableTriplet: getDefaultTableTriplet(),
    lastSearchQueryString: '',
    menuOption_isEditMode: true,
    sessionStateChangeDispatch : (_: LightweightMod[]) => {},
    renderIndex: -2,
  }
  Object.seal( newObj )
  return newObj
}



//MBDEUBG: Suggested structure for the app state
//One concern, we can only store simple data not classes or functions
export type TableState = {
  tableId: string; //we always index by tableId
  //tableTriplet: TableTriplet;
  menuOption_isEditMode: boolean;
}
export type SearchState = {
  searchId: string;
  lastSearchQueryString: string;
}
export type AppStateMB = {
  activeTableid: string;
  tableState: TableState[];
  searchState: SearchState; //Only one search state

  // Global state not dependent on table
  windowInnerWidth: number;
  windowInnerHeight: number;
  //Floating Pallette
  activeFP       : FPname;   // FPname === null is the typical state of NO open floating pallette.
  activeFPcolKey : number;   // Sometimes the FPconfig is f( colKey )
  activeFProwKey : number;   // Sometimes the FP values displayed are f( colKey, rowKey )
  activeFPconfig : FPconfig;

  //John: here we could use an array of FPconfigs to store the state of multiple floating pallettes.
  lastFPconfig : {
      // ONLY one copy saved for this app:
      createXYplot : FPconfig;
      createTable  : FPconfig;
      downloadTable: FPconfig;
      downloadPlot : FPconfig;
      deletePlot   : FPconfig;
  };

  // For debugging purposes
  renderIndex: number;
}

export const initialAppState = (): AppStateMB => {
  return {
    activeTableid: '',
    tableState: [],
    searchState: {
      searchId: '',
      lastSearchQueryString: '',
    },
    windowInnerWidth: window.innerWidth,
    windowInnerHeight: window.innerHeight,
    activeFP: null,
    activeFPcolKey: 0,
    activeFProwKey: 0,
    activeFPconfig: getDefaultFPconfig(),
    lastFPconfig: {
      createXYplot: getDefaultFPconfig(),
      createTable: getDefaultFPconfig(),
      downloadTable: getDefaultFPconfig(),
      downloadPlot: getDefaultFPconfig(),
      deletePlot: getDefaultFPconfig(),
    },
    renderIndex: -2,
  };
};

*/

