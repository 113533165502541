import type { Relationships } from '../jsonapi/types'
import type { PlotXyComputedData } from '../computedDataPlotXY/xy_plotTypes'
import type { TableComputedData } from '../computedDataTable/getDefaultTableComputedData'

import { Component } from 'react'
import slugify from 'slugify'
import { updateTablelook_recentPlots } from '../computedDataPlotXY/plotUtils'
import { TOP_MENUBAR_HEIGHT, NAV_COLUMN_WIDTH, } from '../sharedComponents/constants'
import { reactMinorDispatch } from '../sharedComponents/reactDispatch'
import fileDownload from '../sharedFunctions/fileDownload'
import { getObjectDiffPaths } from '../sharedFunctions/getObjectDiff'
// import PlotSideBar from '../viewSideBar/PlotSideBar'
// Next ComponentXY ONLY used for downloading an SVG
// The call from this function is NOT where plots are rendered!
import ComponentXY from './ComponentXY'
import PlotFocalPlane from './PlotFocalPlane'
import { rStateXY_Plot } from './xy_responsiveState'


type OwnProps = {
  plotXyComputedData: PlotXyComputedData | null
  tableComputedData: TableComputedData | null
  shouldRenderSVG: boolean
  shouldDeleteSVG: boolean
  saveSvgFinishedCallback: () => void
  deleteSvgFinishedCallback: () => void
  closeNavColumnPopups: () => void
}

class PlotParent extends Component<OwnProps> {

  shouldComponentUpdate(nextProps: OwnProps) {
    // IF we switch plots (change in the plotid) then close any open editors on exit of earlier plot.
    // Meaning if we exit a plot with editor open (by viewing a different plot), then
    // switch back to original plot, no editor will be open.
    const { plotXyComputedData } = this.props
    const curPlotId = plotXyComputedData?.plotid
    const nextPlotid = nextProps.plotXyComputedData?.plotid
    if (curPlotId && curPlotId !== nextPlotid) {
      // Close any open editor
      const plot_mods = [{ newVal: { name: '', seriesKey: -1 }, path: 'attributes.minorState.selection' },
      { newVal: false, path: 'attributes.minorState.isEditorOpen' }]
      reactMinorDispatch(plot_mods, 'plots', curPlotId)
    }
    if (curPlotId && curPlotId === nextPlotid) {
      //same plot, but something else changed. Check if we need to update recentPlots
      //ignoring changes to the following keys:
      //most of this is to stop the popups from causing a recent plot update.
      const pathsToSkip = [
        'canvasLayersArray', 'delaunayLayersArray',
        'plotResourceObj.attributes.minorState.selection.name',
        'plotResourceObj.attributes.minorState.isEditorOpen',
        'plotResourceObj.attributes.minorState.cellEditor_left',
        'plotResourceObj.attributes.minorState.selection.seriesKey',
        'plotResourceObj.attributes.minorState.cellEditor_top',
        'minorState.selection.name', 'minorState.cellEditor_left',
        'minorState.isEditorOpen', 'minorState.selection.name',
        'minorState.selection.seriesKey', 'minorState.cellEditor_top']
      //This is very fast - 0.2ms for Boston Marathon plot with only above changes or 1.2ms changing the series.
      if (nextProps.plotXyComputedData) {
        const diffs = getObjectDiffPaths(plotXyComputedData, nextProps.plotXyComputedData, pathsToSkip)

        const nextTableComputedData = nextProps.tableComputedData // temp for compatibility
        const nextTable = nextTableComputedData?.table // temp for compatibility
        const nextTablelook = nextTableComputedData?.tablelook // temp for compatibility

        if (diffs.length > 0 && nextTable && nextTablelook) {
          //console.log(' keys have changed: \n', diffs)
          updateTablelook_recentPlots(nextTable.id, nextTablelook, nextPlotid)
          return true
        }
      }
    }

    return true //we end up always updating
  }


  componentDidUpdate() {
    if (this.props.shouldRenderSVG) { this.saveSvg() }
    if (this.props.shouldDeleteSVG) { this.deleteSvg() }

  }

  deleteSvg = () => {
    console.log(`execute deleteSvg in PlotFocalPlane ${performance.now()}`)
    const container = document.getElementById('hiddenDeleteSVGContainer')
    if (!container) {
      return
    }
    this.props.deleteSvgFinishedCallback()

  }

  // Based on svg-crowbar
  // https://github.com/NYTimes/svg-crowbar
  saveSvg = () => {
    const { plotXyComputedData } = this.props
    //console.log(`execute saveSvg in PlotFocalPlane ${performance.now()}`)
    const container = document.getElementById('hiddenSVGRenderContainer')
    if (!container) {
      return
    }
    const svgs = container.getElementsByClassName('rv-xy-plot__inner')
    if (svgs.length > 0) {
      const svg = svgs[0]
      const prefix = {
        xmlns: 'http://www.w3.org/2000/xmlns/',
        xlink: 'http://www.w3.org/1999/xlink',
        svg: 'http://www.w3.org/2000/svg'
      }

      svg.setAttribute('version', '1.1')

      // add nearly invisible rect so selection works in OpenOffice
      var rectEl = document.createElement('rect')
      if (svg instanceof SVGSVGElement) {
        rectEl.setAttribute('height', `${svg.height.baseVal.value}`)
        rectEl.setAttribute('width', `${svg.width.baseVal.value}`)
      }
      rectEl.setAttribute('style', 'opacity: 0.00000001;')
      svg.insertBefore(rectEl, svg.firstChild)

      // removing attributes so they aren't doubled up
      svg.removeAttribute('xmlns')
      svg.removeAttribute('xlink')

      // These are needed for the svg
      if (!svg.hasAttributeNS(prefix.xmlns, 'xmlns')) {
        svg.setAttributeNS(prefix.xmlns, 'xmlns', prefix.svg)
      }

      if (!svg.hasAttributeNS(prefix.xmlns, 'xmlns:xlink')) {
        svg.setAttributeNS(prefix.xmlns, 'xmlns:xlink', prefix.xlink)
      }

      const serializer = new XMLSerializer()
      let svgString = serializer.serializeToString(svg)
      const mainTitle = plotXyComputedData?.mainTitle ?? ''
      var fileName = slugify(mainTitle)
      if (!fileName) { fileName = 'plot' }
      fileDownload(svgString, fileName + '.svg', 'image/svg+xml')
    }

    // Output the plot JSON, suitable for adding to genData directory
    const plotResourceObj = plotXyComputedData?.plotResourceObj
    if (plotResourceObj && process.env.NODE_ENV === 'development') {
      let newPlot = structuredClone(plotResourceObj)
      delete newPlot.links
      const tableRelation = newPlot.relationships?.table
      if (tableRelation) {
        delete tableRelation.links
        delete tableRelation.meta
      }
      const ownerRelation = newPlot.relationships?.owner
      if (ownerRelation) {
        delete ownerRelation.links
        delete ownerRelation.meta
      }
      const tagsRelation = newPlot.relationships?.tags
      if (tagsRelation) {
        delete tagsRelation.links
        delete tagsRelation.meta
      }
      // Type widening needed here because originatorPlot is a manatory relationship,
      // and so typescript will not allow deleting it.
      const relationships = newPlot.relationships as Relationships
      if (relationships) {
        delete relationships.originatorPlot
      }

      let plotJSON = JSON.stringify(newPlot)
      fileDownload(plotJSON, 'plot.json', 'text/csv')
    }
    this.props.saveSvgFinishedCallback()
  }

  initFocalPlaneContainer = (element: HTMLDivElement | null): void => {
    rStateXY_Plot.focalPlaneContainer = element
  }

  render() {
    const { plotXyComputedData, shouldRenderSVG, shouldDeleteSVG, tableComputedData } = this.props
    // console.log( "PlotComponent - shouldRenderSVG = ", shouldRenderSVG)
    const plot = plotXyComputedData?.plotResourceObj // temp for compatibility
    const table = tableComputedData?.table // temp for compatibility
    const tablelook = tableComputedData?.tablelook // temp for compatibility
    const tabledata = tableComputedData?.tabledata// temp for compatibility

    if (!plotXyComputedData || !plot || !table || !tabledata || !tablelook || !tableComputedData) { return null }
    const { canEdit } = plotXyComputedData
    // const isSideBarVisible = (plotXyComputedData) ? plotXyComputedData.isSideBarVisible : false

    return (

      <div className={'rc_PlotComponent'}
        tabIndex={1}
        style={{
          position: 'relative',
          left: 0, top: 0,
          width: window.innerWidth - NAV_COLUMN_WIDTH,
          height: window.innerHeight - TOP_MENUBAR_HEIGHT,
        }}>


        <div className={'focalPlaneContainer'}
          ref={this.initFocalPlaneContainer}
          style={{
            position: 'absolute',
            left: 0, top: 0,
            width: plotXyComputedData.focalPlaneWidthPx,
            height: plotXyComputedData.focalPlaneHeightPx,
            overflow: 'unset',   // The title and publisher may overflow left/right extents.
          }}>
          <PlotFocalPlane
            canEdit={canEdit}
            plotXyComputedData={plotXyComputedData}
            plot={plot}
            table={table}
            tablelook={tablelook}
            tableComputedData={tableComputedData}
            closeNavColumnPopups={this.props.closeNavColumnPopups} />
        </div>

        {/*false &&
          <div className={'SideBarContainer'}
            style={{ position: 'absolute', top: 0, right: 0, }}
          >
            <PlotSideBar
              plotXyComputedData={plotXyComputedData}
              plot={plot}
              canEdit={canEdit}
              isSideBarVisible={isSideBarVisible}
              tableid={table.id}
              tablelookid={tablelook.id}
              numRowsUnfiltered={table.attributes.numRowsUnfiltered} />
          </div>
        */}

        {shouldRenderSVG && plotXyComputedData &&
          <div id='hiddenSVGRenderContainer' style={{ visibility: 'hidden' }}>
            <ComponentXY
              canEdit={canEdit}
              shouldRenderSVG={true}
              plotXyComputedData={plotXyComputedData}
              tableComputedData={tableComputedData}
            />
          </div>
        }
        {shouldDeleteSVG && plotXyComputedData &&
          <div id='hiddenDeleteSVGContainer' style={{ visibility: 'hidden' }}>
            <ComponentXY
              canEdit={canEdit}
              // shouldDeleteSVG={true}
              plotXyComputedData={plotXyComputedData}
              tableComputedData={tableComputedData}
            // plot={plot}
            // table={table}
            // tablelook={tablelook}
            />
          </div>
        }

      </div> //focalplane
    )

  } //return
} //class


export default PlotParent
