import type { ChangeEvent, CSSProperties } from 'react'
import type { Table, Tablelook } from '../types'
import type { PlotXyComputedData } from '../computedDataPlotXY/xy_plotTypes'

import {Component, Fragment}     from 'react'
import ShapeAndColorPicker    from '../sharedComponents/ShapeAndColorPicker'
import reactDispatch          from '../sharedComponents/reactDispatch'
import constants              from '../sharedComponents/constants'
import StyleLinearSlider      from '../sharedComponents/StyleLinearSlider'
import StyleExpEnumSlider     from '../sharedComponents/StyleExpEnumSlider'
import StyleEnumSlider        from '../sharedComponents/StyleEnumSlider'
import {plotLayoutConsts}     from '../viewPlotXY/plotLayoutConsts'


type OwnProps = {
  plotXyComputedData: PlotXyComputedData,
  table: Table,
  tablelook: Tablelook,
  seriesKey: number,
}

export default class EditPlotSeriesStyle extends Component<OwnProps> {

  onColorSelection = (value: string): void => {
    const {plotXyComputedData, seriesKey} = this.props
    if (plotXyComputedData.seriesAttributesArray[seriesKey].color !== value) {
      let mods = []
      mods.push({ newVal: value, path: `attributes.series[${seriesKey}].color` })
      reactDispatch( mods, 'Style - Series Color' )
    }
  }

  onShapeSelection = (newShape: string, newSize:number): void => {
    const {plotXyComputedData, seriesKey} = this.props
    let mods = []
    if (plotXyComputedData.seriesAttributesArray[seriesKey].markShape !== newShape) {
      mods.push({ newVal: newShape, path: `attributes.series[${seriesKey}].markShape` })
    }
    if (plotXyComputedData.seriesAttributesArray[seriesKey].markSize !== newSize) {
      mods.push({ newVal: newSize, path: `attributes.series[${seriesKey}].markSize` })
    }
    if (mods.length>0 ) {
      reactDispatch( mods, 'Style - Series Point Shape' )
    }
  }

  
  drawing_ModeChangeHandler = (e: ChangeEvent<HTMLSelectElement>) => {
    //const {plotXyComputedData, seriesKey} = this.props
    //const newVal = e.target.value
    //if (plotXyComputedData.seriesAttributesArray[seriesKey].drawing Mode !== newVal) {
    //  let mods = []
    //  mods.push({ newVal: newVal, path: `attributes.series[${seriesKey}].drawing Mode` })
    //  reactDispatch( mods, 'Style - Series Drawing Mode' )
    //}
  }

  seriesOrderByChangeHandler = (e: ChangeEvent<HTMLSelectElement>) => {
    var newOrder = e.target.value
    const {seriesKey} = this.props
    if ( newOrder === 'TopBottom') {
      var colKey = -1
      var sortBy = 'valueA'
    } else if (newOrder === 'LeftRight') {
      colKey = -1
      sortBy = 'valueA'
    } else { // Then a colKey was returned and sortBy is implied to be 'valueColKey'
      colKey = Number(newOrder)
      sortBy = 'valueColKey'
    }
    let mods = []
    mods.push({ newVal: sortBy, path: `attributes.series[${seriesKey}].sortBy` })
    mods.push({ newVal: colKey,   path: `attributes.series[${seriesKey}].sortByColKey` })
    reactDispatch( mods, 'Style - Series Order Points' )
  }

  render() {
    const {columns} = this.props.table.attributes
    const {colOrder} = this.props.tablelook.attributes
    // TODO: Check with JPS if isHisto is the proper replacement for isSmoothed
    const {derivedColAttributesArray, seriesAttributesArray, isTransposed, isHisto} = this.props.plotXyComputedData
    const {seriesKey} = this.props
    const {sortBy, sortByColKey, seriesLineSmoothing, seriesSamplingDensity, color, seriesOpacity,
           markShape, markSize, numRandomSampledFamilies} = seriesAttributesArray[seriesKey]
    //const plotHasPoints = (plotFamily Type === 'xyScatterFamily' && drawing Mode !== 'xyScatterLines')
    //const isLinePlot = (plotFamily Type === 'xyScatterFamily' &&
    //                   (drawing Mode === 'xyScatterLines' || drawing Mode === 'xyScatterPointLines'))
    //const isMultipleSampleSizes = (plotHasPoints && numRandomSampledFamilies > 1)
    //const isMultipleSampleSizes = false
    const truncatedSamplingDensityLabels  = plotLayoutConsts.samplingDensityLabels.slice( -numRandomSampledFamilies )
    const truncatedSamplingDensityOptions = plotLayoutConsts.samplingDensityOptions.slice(-numRandomSampledFamilies )

    var sortByValue
    switch (true) {
      case sortBy === 'valueA' && !isTransposed : sortByValue = 'LeftRight'; break
      case sortBy === 'valueA' &&  isTransposed : sortByValue = 'TopBottom'; break
      // Else remaining case is sortBy 'valueColKey' and the sortByValue is a colKey
      default : sortByValue = sortByColKey
    }

    const DRAWMODE_SELECTOR_WIDTH = 96
    const ROW_HEIGHT = 22
    const SHAPE_SELECTOR_WIDTH = 62
    const COLOR_SELECTOR_WIDTH = 36
    const ORDER_SELECTOR_WIDTH = 198
    const PLOTTED_PTS_LABEL_WIDTH_1stPosition = 104
    const OPACITY_LEFT_PLACEMENT = 244


    const rowStyle: CSSProperties = {
      position: 'relative', top:0, left:0,    // Sets a local coord system for this line
      fontSize: '14px',
      height: ROW_HEIGHT,
      //background: 'pink',
      display: 'flex',
      alignItems: 'center',
      marginTop : 2,
    }
    const rowItems = {
      display: 'inline-block',
      height: ROW_HEIGHT,
      alignItems: 'center',
    }

    return (
        <div className={'rc_EditPlotSeriesStyles'}>

            <div style={{...rowStyle }}>
                <label htmlFor='drawing Mode'>{'Draw\u2009:'}</label>

                <select
                  id='drawing Mode'
                  onChange={this.drawing_ModeChangeHandler}
                  style={{
                    position:'absolute', top:0, left: 41,
                    ...rowItems,
                    ...constants.STYLE_PALETTE_TEXT_INPUT_STYLES,
                    paddingLeft: 0,
                    width: DRAWMODE_SELECTOR_WIDTH,
                    height: ROW_HEIGHT,
                  }}
                  value={'xyScatterPoints'}
                >
                    <option value='xyScatterPoints'>{'Points Only'}</option>
                    <option value='xyScatterLines'>{'Lines Only'}</option>
                    <option value='xyScatterPointLines'>{'Pts & Lines'}</option>
                    <option value='xyScatterLinearFit'>{'Linear Fit'}</option>
                    <option value='xyScatterSmoothFit'>{'Smooth Fit'}</option>
                </select>

                <div className={'ShapePickerContainer'}
                  style={{ position:'absolute', top:0, left: 139, height:ROW_HEIGHT}}>
                      <ShapeAndColorPicker
                        mode={'colorSelector'}
                        seriesKey={seriesKey}
                        selectedColor={color}
                        width={COLOR_SELECTOR_WIDTH}
                        height={ROW_HEIGHT}/>
                </div>

                <div className={'ShapePickerContainer'}
                  style={{ position:'absolute', top:0, left: 177, height:ROW_HEIGHT}}>
                      <ShapeAndColorPicker
                        mode={'shapeSelector'}
                        seriesKey={seriesKey}
                        selectedShape={markShape}
                        selectedSize={markSize}
                        width={SHAPE_SELECTOR_WIDTH}
                        height={ROW_HEIGHT}/>
                </div>

                <div style={{position:'absolute', top:0, left: OPACITY_LEFT_PLACEMENT }}>
                  <StyleLinearSlider
                    indent={0}
                    preSpacer={0}
                    displayFixed={1}
                    valMin={0.1}
                    valMax={1.0}
                    numSteps={9}
                    currentValue={seriesOpacity}
                    modPath={`attributes.series[${seriesKey}].seriesOpacity`}
                    styleName={'Opacity\u2009:'}
                    titleWidth={54}
                    sliderWidth={76}
                    displayWidth={10}
                    layoutLines={1}
                  />
                </div>
          </div>

{/* 2nd line */}
          <div style={{...rowStyle}}>

            { true &&
              <Fragment>
              <label htmlFor='sortBy'>{'Order:'}</label>
              <select
                id='sortBy'
                onChange={this.seriesOrderByChangeHandler}
                style={{
                  position:'absolute', top:0, left: 41,
                  ...rowItems,
                  ...constants.STYLE_PALETTE_TEXT_INPUT_STYLES,
                  width: ORDER_SELECTOR_WIDTH,
                  paddingLeft:0,
                }}
                value={sortByValue}>

                    <option value='LeftRight'>{'Left-to-Right'}</option>
                    <option value='TopBottom'>{'Top-to-Bottom'}</option>
                    {colOrder.map( colKey => {
                      let {internalDataType} = derivedColAttributesArray[colKey]
                      if ( internalDataType === 'number' ) {
                        return (
                            <option key={colKey} value={colKey}>
                                {`Col: ${columns[colKey].colTitle}`}
                            </option>
                        )
                      } else { return null }
                    })}
              </select>
              </Fragment>
              }

              {isHisto &&
              <div style={{...rowItems, position:'absolute', top:0, left: 0 }}>
                  <StyleExpEnumSlider
                    indent={0}
                    titleWidth={94}
                    sliderWidth={106}
                    preSpacer={0}
                    displayFixed={1}
                    valMin={plotLayoutConsts.minSmoothingKernelWidth}
                    valMax={plotLayoutConsts.maxSmoothingKernelWidth}
                    numSteps={plotLayoutConsts.numSmoothingKernelSteps}
                    currentValue={seriesLineSmoothing}
                    modPath={`attributes.series[${seriesKey}].seriesLineSmoothing`}
                    styleName={'Smoothing Fit\u2009:'}
                    layoutLines={1}
                  />
              </div>
              }

              {false &&
              <div style={{...rowItems, position:'absolute', top:0, left: 0 }}>
                <StyleEnumSlider
                  indent={0}
                  preSpacer={0}
                  titleWidth={PLOTTED_PTS_LABEL_WIDTH_1stPosition}
                  displayWidth={32}
                  enumLabels={truncatedSamplingDensityLabels}
                  enumValues={truncatedSamplingDensityOptions}
                  modPath={`attributes.series[${seriesKey}].seriesSamplingDensity`}
                  currentValue={seriesSamplingDensity}
                  styleName={'Sampled Points\u2009:'}
                  layoutLines={1}
                />
              </div>
              }

              {false &&
              <div style={{...rowItems, position:'absolute', top:0, left: OPACITY_LEFT_PLACEMENT }}>
                <StyleEnumSlider
                  indent={0}
                  preSpacer={0}
                  enumLabels={truncatedSamplingDensityLabels}
                  enumValues={truncatedSamplingDensityOptions}
                  modPath={`attributes.series[${seriesKey}].seriesSamplingDensity`}
                  currentValue={seriesSamplingDensity}
                  styleName={'#Points\u2009:'}
                  titleWidth={54}
                  sliderWidth={76}
                  displayWidth={10}
                  layoutLines={1}
                />
              </div>
              }

        </div>

    </div>

)}}
