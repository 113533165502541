import {list} from 'radash'
import type {
    TableComputedData, DomNode, 
    TableLayoutProps, StatsBarLayout,
    TableDomNodes}                  from '../computedDataTable/getDefaultTableComputedData'
import type { TableWidthObj}        from '../computedDataTable/layoutCalculator'
import {enableForcedCombinedTable,
        disableForcedCombinedTable} from '../computedDataTable/layoutCalculator'
import {getDefaultTableLayoutProps} from '../computedDataTable/getDefaultTableComputedData'
import {widthCalculator }           from '../computedDataTable/layoutCalculator'
import  rStateFloatingPalette       from '../floatingPalette/rStateFloatingPalette'
import { startTimer, stopTimer } from '../sharedFunctions/timer'


const isTIMER_ENABLED = false 
const px = (value: number) : string => { return `${value}px` }


var statsBar_renderedLeft: number = 0
var scrollLeft_init      : number = 0
var numRowGroups         : number = 0
var colOrder             : number[] = []
var w_responsiveStateInit: TableWidthObj
var statsBarLayout       : StatsBarLayout
var statsBarTransformInit: string = ''
var statsBarWidthInit    : string = ''
var w_lastFrame          : TableWidthObj
var tableDomNodes        : TableDomNodes
var layoutProps          : TableLayoutProps = getDefaultTableLayoutProps(1,0)

// Debugging counters, used if/when isTIMER_ENABLED
//var counter1 : number = 0
//var counter2 : number = 0
//var counter3 : number = 0
//var counter4 : number = 0


export const initTableResponsiveState = ( tableComputedData:TableComputedData) : void => {
    ({layoutProps, scrollLeft: scrollLeft_init, statsBarLayout } = tableComputedData);
    tableDomNodes = tableComputedData.tableDomNodes
    w_responsiveStateInit = w_lastFrame = tableComputedData.widthObj;
    colOrder = tableComputedData.layoutProps.colOrder;
    ({numRowGroups} = tableComputedData.heightObj);
    const {statsBar} = tableDomNodes
    if ( statsBar ) {
      statsBarTransformInit = statsBar.style.transform
      statsBarWidthInit = statsBar.style.width
    }
    //counter1 = counter2 = counter3 = counter4 = 0   // Used for debugging when isTIMER_ENABLED
    if ( isTIMER_ENABLED ) { 
      startTimer( 'tableResponsiveState' ) 
    }
}


// We need to restore the dom tree to its initial state prior to the
// next react state change.  Because when react compares the virtual
// DOM to the current dom, we must return the current dom to its
// prior state.  Otherwise, small chance that react will skip some
// component update. But very difficult to debug.  Hence, easier to
// follow a general rule: "restore the DOM to its pre-ResponsiveState".
export const restoreInitialTableResponsiveState = (  ) : void => {
    updateTableBlocks( w_responsiveStateInit, scrollLeft_init )
    rStateFloatingPalette.synchStop( ) 
    const {statsBar} = tableDomNodes
    if ( statsBar ) {
        statsBar.style.transform = statsBarTransformInit
        statsBar.style.width     = statsBarWidthInit
    }
    if ( isTIMER_ENABLED ) { 
        stopTimer( 'tableResponsiveState' )
    }
}


// Next function looks for 'edits' between frames.
// Not sure this helps, but don't know how the DOM
// tree updates so I make as few edits as possible
export const updateTableBlocks  = ( w: TableWidthObj, scrollLeftIn: number ) : void => {
    const {headLocked_block, headMoving_block, dataLocked_block, dataMoving_block,
            headMoving_content, dataMoving_content, dataLocked_content,
            headMoving_container, dataMoving_container, dataLocked_container,
            actionScroll, layoutMain, search2MainContainer, mainTitle, publisherTitle,
            statsBar } = tableDomNodes

    // This keeps the text in the Stats bar centered.  This is best we can
    // do, unless we also recalculate how much width is available, and
    // recalculate the stats bar information (perhaps adding or removing
    // a pair of stats as room allows).  Since we don't need that level
    // of complexity, the rules I will use are:
    // 1) DO NOT add or remove the last pair of stats responsively,
    //    regardless of whether we have the room or have run out of room.
    // 2) Given we have sufficient room, keep what stats are currently
    //    rendered centered.
    // 3) If/when we run out of room ( the centering width goes to zero ),
    //    then pin the left side of the stats text to the left edge,
    //    and let the extra space be truncated over the right table edge.
    // 4) On the next re-render, the statBar will add/remove pairs of stats
    //    as necessary to show as many as possible without the space available.
    // Hence, unlike most other responsive state animation, the statsBar
    // MAY flash (re-render) at the end of the responsive animation.
    // I BELIEVE IT APPEARS BETTER IF WE DO NOT TRY TO ADJUST THE STATS
    // CENTERING DURING WIDTH CHANGES.  LEAVE ON FOR NOW, BUT MAYBE NOT BEST
    // ALTERNATIVE.

    let deltaWidth = w.viewWidthPx - w_lastFrame.viewWidthPx
    if (statsBar && deltaWidth) {
        let newStatsCenteringWidth = Math.max(20, statsBar_renderedLeft + deltaWidth/2 )
        statsBar.style.transform = `translate(${newStatsCenteringWidth}px, 0px)`
    }

    // This keeps the titles centered. (And the searchBar proper width) :
    if( w.viewWidthPx !== w_lastFrame.viewWidthPx ) {
    if (layoutMain)  { layoutMain.style.width = px(w.viewWidthPx) }
    if (mainTitle)   { mainTitle.style.transform = `translate( ${w.mainTitleLeft}px, 0px )` }
    if (publisherTitle) { publisherTitle.style.transform = `translate( ${w.publisherTitleLeft}px, 0px )` }
    if( search2MainContainer ) { search2MainContainer.style.width  = px(w.viewWidthPx) }
    }

    // HEAD LOCKED BLOCK:
    if (headLocked_block && w.totalLockedAllocated !== w_lastFrame.totalLockedAllocated ) {
        headLocked_block.style.width = px(w.totalLockedAllocated)
    }
    if (headLocked_block && w.centeringOffset !== w_lastFrame.centeringOffset ) {
        headLocked_block.style.transform = `translate(${w.centeringOffset}px, 0px)`
    }

    // DATA LOCKED BLOCK:
    if (dataLocked_block && w.totalLockedAllocated !== w_lastFrame.totalLockedAllocated ) {
        dataLocked_block.style.width = px(w.totalLockedAllocated)
    }
    if ( dataLocked_block && w.centeringOffset !== w_lastFrame.centeringOffset ) {
        dataLocked_block.style.transform = `translate(${w.centeringOffset}px, 0px)`
    }

    // HEAD MOVING
    if (headMoving_block && w.totalMovingAllocated !== w_lastFrame.totalMovingAllocated ) {
        headMoving_block.style.width = px(w.totalMovingAllocated)
    }
    if ( headMoving_block && w.movingLeft !== w_lastFrame.movingLeft ) {
        headMoving_block.style.transform = `translate(${w.movingLeft-w.borderThickness}px, 0px)`
    }
    if (headMoving_container && w.movingAllocated !== w_lastFrame.movingAllocated ) {
        headMoving_container.style.width = px(w.movingAllocated)
    }
    if (headMoving_content && w.movingRequired !== w_lastFrame.movingRequired ) {
        headMoving_content.style.width = px(w.movingRequired)
    }

    // DATA LOCKED
    if ( dataLocked_block && w.totalLockedAllocated !== w_lastFrame.totalLockedAllocated ) {
        dataLocked_block.style.width = px(w.totalLockedAllocated)
    }
    if ( dataLocked_block && w.movingLeft !== w_lastFrame.movingLeft ) {
        dataLocked_block.style.transform = `translate(${w.centeringOffset}px, 0px)`
    }
    if ( dataLocked_container && w.lockedAllocatedWithBorder !== w_lastFrame.lockedAllocatedWithBorder ) {
        dataLocked_container.style.width = px(w.lockedAllocatedWithBorder)
    }
    if ( dataLocked_content && w.lockedRequired !== w_lastFrame.lockedRequired ) {
        dataLocked_content.style.width = px(w.lockedRequired)
    }

    // DATA MOVING
    if ( dataMoving_block && w.totalMovingAllocated !== w_lastFrame.movingAllocatedWithBorder ) {
        dataMoving_block.style.width = px(w.movingAllocatedWithBorder)
    }
    if ( dataMoving_block && w.movingLeft !== w_lastFrame.movingLeft ) {
        dataMoving_block.style.transform = `translate(${w.movingLeft-w.borderThickness}px, 0px)`
    }
    if ( dataMoving_container && w.movingAllocatedWithBorder !== w_lastFrame.movingAllocatedWithBorder ) {
        dataMoving_container.style.width = px(w.movingAllocatedWithBorder)
    }
    if ( dataMoving_content && w.movingRequired !== w_lastFrame.movingRequired ) {
        dataMoving_content.style.width = px(w.movingRequired)
    }

    // TOUCH (ACTION) SCROLL COMPONENT -- NOT needed for the touchScroll rectangle itself. BUT
    // does have the user visible right side scrollControl attached to this rectangle.
    // So this set of changes shifts the vertical scrolling rail either left/right with 
    // changes to the table widths.
    if(  w.touchLeft !== w_lastFrame.touchLeft || w.touchWindowWidth !== w_lastFrame.touchWindowWidth ) {
        if (actionScroll) {
          actionScroll.style.width=px(w.touchLeft + w.touchWindowWidth + w.scrollControlTotalWidth)
        }
    }
 
    //  Changes to the tableWidth layout MAY make the current scrollLeft value
    //  go 'out-of-bounds'. I constrain the scrollLeft on each frame.
    //  And always synch this.  (Easier than testing the conditions for any changes).
    const scrollLeft_legal = Math.max(0, Math.min( scrollLeftIn, w.movingRequired - w.movingAllocated))
    synchScrollLeft( scrollLeft_legal, w.movingAllocated, w.movingRequired )

    if (statsBar) {
        const { leftPlacementOfStatsBar, visibleWidthOfStatsBar} =
                getStatsBarPlacementAndVisibleWidth( w.viewWidthPx, statsBarLayout.groupLeftArr )
        statsBar.style.transform = `translate(${leftPlacementOfStatsBar}px, 0px)`
        statsBar.style.width     = px( visibleWidthOfStatsBar )
    }
    w_lastFrame = w
}


/* 
Next function provide information enabling us to 
dynamically 'resize' the statsBar, without re-rending its texts or layout.
All stats bars are rendered at 'up to 12 horzontal blocks'.  This 'worse
case' scenario should be used for testing and will be stats for
any string axis.  MarathonData firstNames is a good test case.
The stats are the most frequent first names, in cardinal order:
In this case the 2 blocks are:
    Col Name;  ValidCells;     0:name;  2:name;  4:name; ... 18:name;  
               Empty/Errors:   1:name;  3:name;  5:name;     19:name;
In this case, the width of the stats bar is very likely to exceed the
available with to display the stats bar!

But to say responsive code from rerendering potential new blocks,
I render ALL blocks, assuming they 'may' have room to be visible.

Next function returns two values;
1) What is the width that does fit?  When snapped to boundary between groups.
2) Give the width that does fit, what is the left offset to center this width?

During rendering, and during responsive state width changes, we control the
'left' attribute of the parent window (for centering), and the 'width' attribute
of the parent to show only those groups that fit into available width.  
Those that don't fit (to the right) are overflow:hidden.

In updateTableBlocks above, we can/do adjust the left & width attributes
on every frame.  Although the rendered width does NOT vary smoothly, but
jumps whenever the number of fitting stats groups changes (either wider
or narrower).
*/


const STATS_BAR_LEFT_RIGHT_MARGINS = 20
export const getStatsBarPlacementAndVisibleWidth = 
                         ( viewWidthPx:number, statsBarGroups_LeftArr: number[] ) => {
  const maxAvailableWidth = viewWidthPx - 2 * STATS_BAR_LEFT_RIGHT_MARGINS
  var maxLegalLeftPlacement = statsBarGroups_LeftArr[0]
  for ( const thisLeftPlacement of statsBarGroups_LeftArr ) {
    if( thisLeftPlacement > maxAvailableWidth) { break }
    maxLegalLeftPlacement = thisLeftPlacement
  }
  return {
    leftPlacementOfStatsBar : (maxAvailableWidth - maxLegalLeftPlacement) / 2 + STATS_BAR_LEFT_RIGHT_MARGINS,
    visibleWidthOfStatsBar  : maxLegalLeftPlacement
  }
}

export const colResize = ( colIndex: number, w: TableWidthObj ): void => {
    //w_thisFrame = w
    // This function assumes new width !== to current width.
    // Assumes the case of 'do nothing' was already screened by debouncer.
    //console.log( 'Call to colResize', colIndex, w.displayedColWidths[colIndex] )
    const { columnsHead, columnsData } = tableDomNodes;
    const newWidth  = px(w.displayedColWidths[colIndex])
    var colKey = colOrder[colIndex]
    // We only need to resize one column width.
    // Then reposition all columns 'right' of colIndex.
    // One repositioning for pinned Header,
    // and one block to resize for each rowGrouP
    var thisDomNode = columnsHead[colKey]
    if ( thisDomNode ) { thisDomNode.style.width = newWidth }

    for (let j=0; j< numRowGroups; j++) {
      thisDomNode = columnsData[j][colKey]
      if ( thisDomNode ) { thisDomNode.style.width = newWidth }
    }
    // For all columns 'right' of the resized column,
    // we keep the same size, but shift the blocks position.
    // colIndices is the 0 to n sequential order in Column order!
    // colKey is the resource column
    if ( !w.isCombinedTable && colIndex < w.numLockedCols ) {
      var columnIndicesToShift = list( colIndex+1, w.numLockedCols )
    } else {
      columnIndicesToShift = list( colIndex+1, columnsHead.length )
    }
    for ( let colIndex of columnIndicesToShift ) {
      colKey = colOrder[colIndex]
      let thisDomNode = columnsHead[colKey]
      if (thisDomNode) { 
        thisDomNode.style.transform = `translate(${w.startColLeft[colIndex]}px, 0px)` 
      }
      for (let ii=0; ii<numRowGroups; ii++) {
        let thisDomNode = columnsData[ii][colKey]
        if (thisDomNode) {
            thisDomNode.style.transform = `translate(${w.startColLeft[colIndex]}px, 0px)`
        }
      }
    }
  }


export const updateFloatingPaletteHighlight = ( w : TableWidthObj ) : void => {

    return
    /*
    const { selectionName, minor StateColIndex, minor StateRowIndex,
        floatingPaletteLeft:fpLeft, floatingPaletteTop: fpTop,
        floatingPaletteWidth:fpWidth, floatingPaletteHeight: fpHeight,
        availableWidthIncludingSideBar, tableLayoutHeight} = r StateFloatingPalette.layoutObj

    const { scrollLeft, scrollTop, heightObj:h, styleObj:s } = r State
    const result = focusCalculator_nameToCellObj( selectionName, minor StateColIndex, minor StateRowIndex, scrollLeft, scrollTop, w, h, s)
    var {left, top, width, height, isCellVisible } = result
    const isActiveDrag = false
    const { constrainedLeft, constrainedTop } = constrainPosition(
                left, top, width, height,      fpLeft, fpTop, fpWidth, fpHeight,
                availableWidthIncludingSideBar, tableLayoutHeight, w.sideBar, isActiveDrag )
    if ( isCellVisible ) {
        var highlight = calcHighlightLine( left, top, width, height, constrainedLeft, constrainedTop, fpWidth, fpHeight )
    } else {
        highlight = ''
    }
    const visibility = isCellVisible ? 'unset' : 'hidden'
    rStateFloatingPalette.redraw(constrainedLeft, constrainedTop, fpWidth, fpHeight, highlight, visibility)
    */
}

export const calcThumbLength = (containerPx:number, contentPx: number) : number => {
    const minThumbLength = Math.min( 35, Math.round( containerPx / 2 ))
    const bestThumbLength = Math.round ( containerPx / contentPx * containerPx)
    return  Math.max( minThumbLength, bestThumbLength )
}


export const calcThumbOffset_fromScrollValue = (containerPx:number, contentPx: number, scrollValue: number ) : number => {
    const thumbLength : number  = calcThumbLength (containerPx, contentPx)
    // In case of thumb very near full extent, put it at full extent.
    if ( containerPx + scrollValue + 0.5 >= contentPx ) { return containerPx - thumbLength }
    // Case of no need for thumb.
    if (contentPx === containerPx ) { return 0 }
    // The common use case:
    var thumbOffset = Math.round( scrollValue / (contentPx - containerPx) * (containerPx - thumbLength) )
      // offset can never exceed containerPx - thumbLength !
    thumbOffset = Math.min ( thumbOffset, containerPx - thumbLength)
      // offset is never < zero
    thumbOffset = Math.max ( thumbOffset, 0 )
    return thumbOffset
}


const redrawHorzScrollControl_fromScrollPosition = ( scrollPosition: number, 
                               containerPx : number, contentPx: number ) : void => {
    var {horzScrollRail:railNode, horzScrollThumb:thumbNode} = tableDomNodes 
    if ( railNode?.style ) { 
        railNode.style.width = px(containerPx)
        // When NOT needed, we hide the scrollControl
        const isHidden = (containerPx >= contentPx)
        railNode.style.visibility = isHidden ? 'hidden' : 'unset'
    }
    const thumbOffset = calcThumbOffset_fromScrollValue( containerPx, contentPx, scrollPosition )
    const thumbLength = calcThumbLength( containerPx, contentPx )
    if ( thumbNode?.style ) {
        thumbNode.style.width = px(thumbLength)
        thumbNode.style.transform = `translate( ${thumbOffset}px, 0px )`
    }
}




// USE this function when:
//   Redraws the Scroll Control (rail length, thumb length, thumb position)
//   Redraws the table (colResizing, scrollOfset, ... )
//   Updates the location of the floating palette 'Cyan' outline, if necessary.
//   No throttling.  Runs at full frame rate 16ms/sec 
export const synchScrollLeft = ( newOffset: number, containerPx: number, contentPx: number ) : void => {
    updateScrollLeft( newOffset, containerPx, contentPx )
    redrawHorzScrollControl_fromScrollPosition( newOffset, containerPx, contentPx )
}


const updateScrollLeft = ( scrollLeft: number, containerPx: number, contentPx: number ) : void => {
    // Assume global geometric parameters have been set.
    // Specically: movingAllocated, movingRequired, scrollLeft, w_thisFrame.
    const { headMoving_content, dataMoving_content, lastMovingUnhideControl } = tableDomNodes
    if (headMoving_content ) { headMoving_content.style.transform = `translate(${-scrollLeft}px, 0px)` }
    if (dataMoving_content ) { dataMoving_content.style.transform = `translate(${-scrollLeft}px, 0px)` }
    if (lastMovingUnhideControl ) { lastMovingUnhideControl.style.transform   =
                    `translate( ${contentPx - containerPx - scrollLeft}px, 0px)` }
}


export const setColumnOpacity = ( opacity: number, colKey : number ) => {
    const {columnsHead, columnsData } = tableDomNodes
    const thisColumn: DomNode = columnsHead[colKey] 
    if ( thisColumn ) { 
        thisColumn.style.opacity = String(opacity)
    }
    if ( columnsData && columnsData[0][colKey] ) {
        // DO NOT assume above array length === number of rowGroups!
        for (let i=0; i<numRowGroups; i++) {
            var thisDomNode = columnsData[i][colKey]
            if ( thisDomNode ) {
                thisDomNode.style.opacity = String(opacity)
            }
        }
    }
}


export const tableSideBarAnimationStart = ( tableComputedData: TableComputedData ) : void => {
    initTableResponsiveState( tableComputedData )
    enableForcedCombinedTable( tableComputedData.widthObj.isCombinedTable)
}

export const tableSideBarAnimation = ( mainViewWidth: number ) : void => {
  var perturbedProps = {
    ...layoutProps,
    tableLayoutWidth: mainViewWidth,
    isSideBarVisible: true,  // ?????????????//  should no longer be part of layoutCalculator
  }
  let w = widthCalculator( perturbedProps )
  //synchScrollLeft( scrollLeft_init, w.movingAllocated, w.movingRequired )
  updateTableBlocks( w, scrollLeft_init )
}

export const tableSideBarAnimationStop = ( ) : void => {
  restoreInitialTableResponsiveState( )  // Do I need a forced state change here? Probably
  disableForcedCombinedTable() 
}

