import {PureComponent} from 'react'
import type {Plot} from '../types'
import FloatingPalette        from '../floatingPalette/FloatingPalette'
import constants              from '../sharedComponents/constants'
import {plotLayoutConsts}     from '../viewPlotXY/plotLayoutConsts'
import StyleEnumSlider        from '../sharedComponents/StyleEnumSlider'
import StyleLinearSlider      from '../sharedComponents/StyleLinearSlider'
import type {PlotXyComputedData}   from '../computedDataPlotXY/xy_plotTypes'

const TOTAL_HEIGHT = 402
const TOTAL_WIDTH  = 452
const NUMERAL_INDENT = 10
const INSTRUCTION_INDENT = 16
const SLIDER_INDENT = 100
const SLIDER_SPACING = -2
// Space allocated to the slider titles
// Must be wide enough to handle the worse case slider title.
// Yet should be identical for all sliders for nice alignment.
// When not passed, uses the slider control's default TITLE_WIDTH
const SLIDER_TITLE_WIDTH = 120

const INSTRUCTION_WIDTH = TOTAL_WIDTH - INSTRUCTION_INDENT - 30
//const INITIAL_PLACEMENT_TOP  = TOP_MENUBAR_HEIGHT + 50
const INITIAL_PLACEMENT_TOP  = window.innerHeight - TOTAL_HEIGHT - 300
const INITIAL_PLACEMENT_LEFT = window.innerWidth - TOTAL_WIDTH - 100

type OwnProps = {
  plot        : Plot | null,
  plotXyComputedData  : PlotXyComputedData | null,
  closePopup  : () => void,
  setRenderSVG: () => void,
}

type LocalState = {
  currentLeft:number,
  currentTop: number,
}

class DownloadPlot extends PureComponent<OwnProps, LocalState> {

  constructor (props: OwnProps) {
    super(props)
    this.state = {
      currentLeft: INITIAL_PLACEMENT_LEFT,
      currentTop : INITIAL_PLACEMENT_TOP
    }
  }

  onActiveDrag = (left:number, top:number) : void => {
    // Constraint the position:
    top = Math.max( 20, top )
    top = Math.min( window.innerHeight - TOTAL_HEIGHT - 20, top )
    left= Math.max( 20, left )
    left= Math.min( window.innerWidth - TOTAL_WIDTH - 20, left )
    this.setState( {currentLeft:left, currentTop:top} )
  }

  render() {
    const {closePopup, setRenderSVG, plot, plotXyComputedData} = this.props
    const {currentLeft, currentTop} = this.state
    if ( !plot || !plotXyComputedData ) {return null }
    const year = plot.attributes.createdDate?.slice(0,4) || String(new Date().getFullYear())
    const title= `"${plot.attributes.mainTitle}"`
    const URL= `http://datatables/plot/${plot.id}`
    // We need to know with basis (A,B,C) is assigned to bottom axis.
    // This is the basis that owns the tick label angle.
    const bottomBasisPath = plotXyComputedData.bottomAxis.basisPath
    const leftBasisPath   = plotXyComputedData.leftAxis.basisPath
    const labelAngle      = plotXyComputedData[bottomBasisPath].labelAngle
    const bottomTickSize = plot.attributes[bottomBasisPath].fontScaleTickValues
    const leftTickSize   = plot.attributes[leftBasisPath].fontScaleTickValues

    return (

      <FloatingPalette
        left={currentLeft}
        top={currentTop}
        height={TOTAL_HEIGHT}
        width={TOTAL_WIDTH}
        onClose={closePopup}
        onActiveDrag={this.onActiveDrag}
        title='Download Plot:'
        isDraggable={true}
      >

        <div className={'rc_DownLoadPlot'}
          style={{ fontSize:14}} >

          <div className={'spacer'} style={{height:10}}/>
          <div
              style={{
                marginLeft: NUMERAL_INDENT,
                width: INSTRUCTION_INDENT,
                display: 'inline-block',
                verticalAlign: 'top',
              }}
            >{'1.'}</div>

          <div style={{ width: INSTRUCTION_WIDTH, display: 'inline-block' }}>
          {'The top lines (title, publisher) are not downloaded.  If republishing, ' +
           'credit the original sources.  You may cut & paste this reference:' }
          </div>

          <div className={'spacer'} style={{height:6}}/>
          <div
              style={{
                marginLeft: NUMERAL_INDENT,
                width: INSTRUCTION_INDENT,
                display: 'inline-block',
                verticalAlign: 'top',
              }}
            >{''}</div>
          <div style={{ width: INSTRUCTION_WIDTH, display: 'inline-block', background: 'rgb(255,231,240)' }}>
            { `Publisher's_Info, ${year}, ${title}, ` + URL }
          </div>


          <div className={'spacer'} style={{display:'block',height:14}}/>
          <div
              style={{
                marginLeft: NUMERAL_INDENT,
                width: INSTRUCTION_INDENT,
                display: 'inline-block',
                verticalAlign: 'top',
              }}
            >{'2.'}</div>

          <div style={{ width: INSTRUCTION_WIDTH, display: 'inline-block' }}>
          {'You can set the plot size after it is pasted into your application. ' +
           'But roughly set the shape of the plot now, before download. This will help ' +
           'you chose reasonable font sizes.'
          }
          </div>

          <StyleEnumSlider
            indent={SLIDER_INDENT}
            preSpacer={10}
            titleWidth={SLIDER_TITLE_WIDTH}
            enumLabels={plotLayoutConsts.aspectRatioLabels}
            enumValues={plotLayoutConsts.aspectRatioOptions}
            modPath='attributes.aspectRatio'
            currentValue={plot.attributes.aspectRatio}
            styleName='Aspect Ratio'
            layoutLines={1}
          />

          <div className={'spacer'} style={{display:'block',height:10}}/>
          <div
              style={{
                marginLeft: NUMERAL_INDENT,
                width: INSTRUCTION_INDENT,
                display: 'inline-block',
                verticalAlign: 'top',
              }}
            >{'3.'}</div>

          <div style={{ width: INSTRUCTION_WIDTH, display: 'inline-block' }}>
          {'Set the font sizes as you wish:'}
          </div>

          <StyleLinearSlider
            indent={SLIDER_INDENT}
            preSpacer={6}
            titleWidth={SLIDER_TITLE_WIDTH}
            styleName={"Legend"}
            modPath={'attributes.fontScaleLegend'}
            currentValue={plot.attributes.fontScaleLegend}
            valMin={plotLayoutConsts.fontSizeMinFactor}
            valMax={plotLayoutConsts.fontSizeMaxFactor}
            numSteps={60}
            displayFixed={2}
            layoutLines={1}
          />

          <StyleLinearSlider
            indent={SLIDER_INDENT}
            preSpacer={SLIDER_SPACING}
            titleWidth={SLIDER_TITLE_WIDTH}
            styleName={"Axis Names"}
            modPath={'attributes.fontScaleAxisNames'}
            currentValue={plot.attributes.fontScaleAxisNames}
            valMin={plotLayoutConsts.fontSizeMinFactor}
            valMax={plotLayoutConsts.fontSizeMaxFactor}
            numSteps={60}
            displayFixed={2}
            layoutLines={1}
          />

          <StyleLinearSlider
            indent={SLIDER_INDENT}
            preSpacer={SLIDER_SPACING}
            titleWidth={SLIDER_TITLE_WIDTH}
            styleName={"Left Axis Values"}
            modPath={`attributes.${leftBasisPath}.fontScaleTickValues`}
            currentValue={leftTickSize}
            valMin={plotLayoutConsts.tickFontSizeMinFactor}
            valMax={plotLayoutConsts.tickFontSizeMaxFactor}
            numSteps={40}
            displayFixed={2}
            layoutLines={1}
          />

          <StyleLinearSlider
            indent={SLIDER_INDENT}
            preSpacer={SLIDER_SPACING}
            titleWidth={SLIDER_TITLE_WIDTH}
            styleName={"Bottom Axis Values"}
            modPath={`attributes.${bottomBasisPath}.fontScaleTickValues`}
            currentValue={bottomTickSize}
            valMin={plotLayoutConsts.tickFontSizeMinFactor}
            valMax={plotLayoutConsts.tickFontSizeMaxFactor}
            numSteps={40}
            displayFixed={2}
            layoutLines={1}
          />

          <StyleEnumSlider
            indent={SLIDER_INDENT}
            preSpacer={SLIDER_SPACING}
            titleWidth={SLIDER_TITLE_WIDTH}
            styleName='Bottom Axis Angle'
            enumLabels={plotLayoutConsts.xTickAngleLabels}
            enumValues={plotLayoutConsts.xTickAngleValues}
            modPath={`attributes.${bottomBasisPath}.labelAngle`}
            currentValue={labelAngle}
            layoutLines={1}
          />

          <button className={'downloadButton'}
            onClick={ setRenderSVG }
            style={{
              fontSize: 14, fontWeight: 'bold',
              display: 'block',
              height: 30, width: 90,
              position: 'relative', left: 165, top: 10,
              borderRadius: 6, borderStyle: 'outset', borderWidth: 2,
              borderColor: constants.COLHEADER_INPUT_BORDER_COLOR,
              verticalAlign: 'center',
            }}>
              {'Download'}
          </button>

        </div>

      </FloatingPalette>
    )
  }
}

export default DownloadPlot
