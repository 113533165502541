import { Component } from 'react'
import {Tablelook} from '../types'
import invariant from 'invariant'
import type { ReactElement} from 'react'
import type {TableComputedData} from '../computedDataTable/getDefaultTableComputedData'
import { sessionStateChangeDispatch } from './reactDispatch'
import {SCRY_WHITE, SCRY_DARK_BLUE, 
        SIDEBAR_INTERNAL_WIDTH}   from '../sharedComponents/constants'

const DEBUG = false

const TAB_HALF_HEIGHT = 16
const FONT_SIZE = 14
const BOTTOM_BORDER_HEIGHT = 1  // The black line under non-selected tabs.

// These params determine the size and shape (curvature) of the blackBackground
// Or the shape of the tabs, depending on your point of view.
// (may be black or near white!)
const GAP_BETWEEN_TABS = 6   //black  Keep this value even. (divisible by 2)
const GAP_ABOVE_TABS = 10     //black
const MARGIN_LEFT_RIGHT = 2   //
const OVAL_HALF_HEIGHT = TAB_HALF_HEIGHT     //black   determines effective radius of the bottom half of tab
const TAB_TOP_CORNER_RADIUS = 10  //curvature of the top half of the tab.
//  NOTE: OVAL_HALF_HEIGHT >= TAB_HALF_HEIGHT - BOTTOM_BORDER_HEIGHT
const INACTIVE_TAB_COLOR = SCRY_DARK_BLUE
const ACTIVE_TAB_COLOR = SCRY_WHITE

type OwnProps = {
  tableComputedData: TableComputedData,
  tabChildren: ReactElement[],
  tabIndex: number,
  showChildOnlyIfCanEdit: boolean[],
  setTabIndexPath: string, 
}


type Props = OwnProps

export default class TabControl2 extends Component<Props> {

  /*
     What is the purpose of the parent passing a 'setTabHandler' function?
     - setTabIndex (next function) is the 'nominal' way we expect the sideBar
       to work.  Clicking a tab should change the display (the rendered tab) but
       should not effect the table state, plot state, or and other resource state.
       The tab control is simple a way to fit layers of interface controls into
       the same space (sideBar).
     - Twice during development, I desired a temporary interface to control the
       code I was testing.  The controls I desired were complex enough that I
       create 'tabbed layers'.   And I desired to change my sessionState
       based on changes to the displayed tab.  Hence clicking on a tab -> change
       to my local or resource state.   I DO NOT RECOMMEND THIS FROM FINAL PRODUCT.
     - Hence 'setTabHandler' passed into the function can be used such that the
       parent (tableComponent, plotComponent, ... ? ) can make a state change when
       the currently visible tab is changed.  The setTabHandler will return the
       tabName (params tabName and tabName2), as well as the tabIndex (0 to n).
       You parent code should look for a matching tabName --> some desired state change.
       Because this child-component ('TabControl') is shared between table, plot, ...
  */


  setTabIndex = ( i: number ): void => {
    sessionStateChangeDispatch([{newVal: i, path:this.props.setTabIndexPath}], 'Tab Selection')
  }

  render () {
    const { tabChildren, tabIndex, tableComputedData, showChildOnlyIfCanEdit } = this.props
    if (process.env.NODE_ENV !== 'production' && tabChildren.length !== showChildOnlyIfCanEdit.length) {
        invariant( false, 'Inputs arrays tabChildren and showChildOnlyIfCanEdit are NOT same length' )
    }
    var validTabIndex = tabIndex // Assumption that the requested tabIndex is valid
    const {styleObj, canEdit} = tableComputedData
    const tablelook = tableComputedData.tablelook as Tablelook
    const {isBrightField} = tablelook.attributes
    const {foregroundColor} = styleObj
    const width = SIDEBAR_INTERNAL_WIDTH
    // We MAY not display all tabs!  Only validTabs
    const displayedTabs = [] 
    for ( const [i, boolVal] of showChildOnlyIfCanEdit.entries() ) {
        if (canEdit === true || boolVal === false) { displayedTabs.push(i) }
    }
    if (process.env.NODE_ENV !== 'production' && displayedTabs.length === 0) {
        invariant( false, 'There are no valid tabIndices to display.' )
    }
    // If the tabIndex requested is not is the available safe to display list,
    // then use the 1st in the list:
    if ( displayedTabs.indexOf( validTabIndex ) === -1 ) { 
        validTabIndex = displayedTabs[0] 
    }
    const ActiveTab = tabChildren[validTabIndex]
        // Skip the tab wrapper if we have only a single tab.
    if ( displayedTabs.length <= 1 ) {return (<div>{ActiveTab}</div>) }
    const tabWidth = Math.trunc( (width - 2*MARGIN_LEFT_RIGHT)/ displayedTabs.length )
    const initialLeftOffset = ( width - (tabWidth * displayedTabs.length) ) / 2  // Will be equal to or slightly > than MARGIN_LEFT_RIGHT
    const tabBackgroundColor = isBrightField ? 'black' : '#808080'   // Roughly same color as stats bar

    return (
      <div className={'rc_TabControl'} >
        <div style={{
        position: 'relative',
        width : SIDEBAR_INTERNAL_WIDTH,
        height: 2*TAB_HALF_HEIGHT + GAP_ABOVE_TABS,
        backgroundColor: tabBackgroundColor,
        overflow: 'hidden',
        }}>

        { displayedTabs.map( i => {  
            //if (canEdit === false && showChildOnlyIfCanEdit[i] ) { return null }  // Skip tabs that are only visible to table owner. 
            var leftOffset =  initialLeftOffset + i*tabWidth
            const tabName1 = tabChildren[i].props.tabName1
            const tabName2 = tabChildren[i].props.tabName2
            return (
                <div key={tabName1+tabName2} >
                    <div className={'Tab_topHalf'}
                      style={{
                        display: 'block',
                        position: 'absolute',
                        borderTopLeftRadius: TAB_TOP_CORNER_RADIUS ,
                        borderTopRightRadius: TAB_TOP_CORNER_RADIUS ,
                        left: leftOffset + GAP_BETWEEN_TABS/2,
                        top: GAP_ABOVE_TABS,
                        width:tabWidth-GAP_BETWEEN_TABS,
                        height:TAB_HALF_HEIGHT,
                        backgroundColor: (i===validTabIndex) ? ACTIVE_TAB_COLOR : INACTIVE_TAB_COLOR,
                        color: (i===validTabIndex) ? foregroundColor : 'white',
                        textAlign : 'center',
                        fontSize: FONT_SIZE,
                        fontWeight: (i===validTabIndex) ? 'bold' : 'normal', }}
                      onClick={ ()=>this.setTabIndex(i) }
                    >
                            {tabName1}
                    </div>

                    <div className={'Tab_bottomHalf'}
                      style={{
                        display: 'block',
                        position: 'absolute',
                        left: leftOffset,
                        top: TAB_HALF_HEIGHT + GAP_ABOVE_TABS,
                        width:tabWidth,
                        height:TAB_HALF_HEIGHT,
                        backgroundColor: (i===validTabIndex) ? ACTIVE_TAB_COLOR : INACTIVE_TAB_COLOR,
                        color: (i===validTabIndex) ? foregroundColor : 'white',
                        textAlign : 'center',
                        fontSize: FONT_SIZE,
                        fontWeight: (i===validTabIndex) ? 'bold' : 'normal',
                        borderBottom: (i===validTabIndex) ? 'none' : `${BOTTOM_BORDER_HEIGHT}px solid ${tabBackgroundColor}`,
                        boxSizing: 'border-box', }}
                      onClick={ ()=>this.setTabIndex(i) }
                    > 
                            {tabName2} 
                    </div>

                    <div className={'Oval'}
                        style={{
                            position: 'absolute',
                            left: leftOffset + tabWidth - GAP_BETWEEN_TABS/2 ,
                            top: GAP_ABOVE_TABS - BOTTOM_BORDER_HEIGHT,
                            width:GAP_BETWEEN_TABS,
                            height:2*OVAL_HALF_HEIGHT,
                            borderRadius: `${GAP_BETWEEN_TABS/2}px/${OVAL_HALF_HEIGHT}px`,
                            backgroundColor: (DEBUG) ? 'pink' : tabBackgroundColor,
                            opacity: (DEBUG) ? 0.5 : 1,
                            zIndex: 1,
                        }}/>

                </div>
            )
        })}
        
        <div className={'First_Oval'}
          style={{
            position: 'absolute',
            left: initialLeftOffset - GAP_BETWEEN_TABS / 2 ,
            top: GAP_ABOVE_TABS - BOTTOM_BORDER_HEIGHT,
            width:GAP_BETWEEN_TABS,
            height:2*OVAL_HALF_HEIGHT,
            borderRadius: `${GAP_BETWEEN_TABS/2}px/${OVAL_HALF_HEIGHT}px`,
            backgroundColor: (DEBUG) ? 'pink' : tabBackgroundColor,
            opacity: (DEBUG) ? 0.5 : 1,
            zIndex: 1, }}>
        </div>
 
      </div>

      {ActiveTab}
      
    </div>
    )
  }
}