import type { Property } from 'csstype'
import type { ReactNode } from 'react'
import type { DraggableData, DraggableEvent } from 'react-draggable'
import type { SizeMeProps, WithSizeOnSizeCallback } from 'react-sizeme'

import React, {Component}     from 'react'
import {DraggableCore}        from 'react-draggable'
import constants              from '../sharedComponents/constants'
import {withSize}             from 'react-sizeme'
import rStateFloatingPalette  from './rStateFloatingPalette'


const TITLE_BAR_HEIGHT   = constants.FLOATING_PALETTE_TITLE_BAR_HEIGHT
const TITLE_BAR_FONTSIZE = constants.FLOATING_PALETTE_TITLE_BAR_FONTSIZE
const FLOATING_PALETTE_BACKGROUND = constants.FLOATING_PALETTE_BACKGROUND
const BORDER_COLOR = constants.SCRY_DARK_BLUE
const BORDER_WIDTH = 4  // The blue border width around editor

type DragableProps = {
  children: ReactNode,
  left: number,
  top:number,
  width?: number,
  height?: number,
  title:string,
  onClose: () => void,
  onSize : WithSizeOnSizeCallback,
}

class DragablePalette extends Component<DragableProps> {

  stopEventPropagation = (e: React.UIEvent<HTMLDivElement>): void => {
    e.stopPropagation()
  }

  preventDefault = (e: React.UIEvent<HTMLDivElement>): void => {
    e.preventDefault()
  }

  initEditorContainerDomNode = (element: HTMLDivElement | null): void => {
    rStateFloatingPalette.editorContainerDomNode = element
  }

  initFloatingPaletteDomNode = (element: HTMLDivElement | null): void => {
    rStateFloatingPalette.floatingPaletteDomNode = element
  }

  /* Close the dialog if the user presses the X, enter key, or escape key */
  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown)
  }

  // Next function works well to close a palette when
  // user is entering text, then hits 'return'.
  // Should only be applied when in simple single-line editors.

  handleKeyDown = (e: KeyboardEvent) => {
    const applyTo = ['Title  Editor', 'Publisher  Editor']
    if (applyTo.includes(this.props.title) && (e.key === 'Enter' || e.key === 'Escape')) {
      this.props.onClose()
    }
  }

  render () {
    const { left, top, title, onClose, children, width, height } = this.props

    // If width and height are set, then this is a resizable editor, and the
    // width and height are coming from the minor state.
    // Otherwise, width and height are null, and the floating palette
    // will use Onsize() to measure its own size.
    var sizedTotalHeight: Property.Height<number> = 'unset'
    var sizedTotalWidth: Property.Width<number> = 'unset'
    var editorContainerHeight: Property.Height<number> = 'unset'
    if ( width && height ) {
      sizedTotalHeight = height
      sizedTotalWidth = width
      editorContainerHeight = height - TITLE_BAR_HEIGHT
      //console.log( `FloatingPalette Rendered Size set by input values of`, width, height   )
    } else {
      //console.log( 'FloatingPalette is self sizing (onSize function).' )
    }

    return (

      <div className={'rc_DraggablePalette'}
        ref={ this.initFloatingPaletteDomNode }
        style={{
          filter: 'drop-shadow(3px 3px 6px rgba(0, 0, 0, 1))',
          position: 'absolute',
          // For most editors, width/height are null and the editor renders
          // and then calls onSize to report the rendered size to the parent.

          // However, IFF width and height have values, then these values
          // represent the dimensions of the entire floating palette.  And
          // we expect these values to vary as the editor is 'resizable'.
          width : sizedTotalWidth,
          height: sizedTotalHeight,
          transform: `translate(${left}px,${top}px)`,
          //display: 'flex', flexFlow: 'column nowrap',
          zIndex: 80,
        }}
        //onWheel={this.preventDefault} //This causes a chrome violation.
        onKeyUp={this.stopEventPropagation}
      >

                <div
                  className='floatingPaletteTitleBar'
                  style={{
                    color: 'black',
                    background: 'DarkGray',
                    borderBottom: 'gray',
                    borderTopLeftRadius: '6px', borderTopRightRadius: '6px',
                    display: 'flex', flexFlow: 'row nowrap',
                    //flex: '0 0 auto',    // vertical sizing
                    width: '100%', height: `${TITLE_BAR_HEIGHT}px`,
                    fontSize: `${TITLE_BAR_FONTSIZE}px`,
                    paddingBottom: '2px', paddingTop: '2px',
                    paddingLeft: '15px', paddingRight: '5px',
                    borderLeftWidth: 1, borderTopWidth: 1,
                    borderRightWidth: 1, borderBottomWidth: 0,
                    borderColor: 'black', borderStyle: 'solid',
                  }}
                >
                  <div
                    className={'unselectable'}
                    style={{
                      flexGrow: 1,
                      fontWeight: 'bold',
                      textAlign: 'center',
                      paddingLeft: 22,   // Emperically determined to center the title.
                    }}
                  > {title} </div>
                  <div style={{ flexShrink: 0, }} >
                      <button
                        style={{
                          background: 'none',
                          border: 'none',
                          cursor: 'pointer',
                          outline: 'none',
                          paddingRight: 4,
                        }}
                        onClick={onClose}
                      >
                          <svg
                            height='14px'
                            style={{fill: 'currentColor'}}
                            viewBox='-6 -6 12 12'
                            width='14px'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <polygon points='1.5 0  5.5 4.5  4 6  0 2  -4 6  -5.5 4.5  -1.5 0  -5.5 -4.5  -4 -6  0 -2  4 -6  5.5 -4.5  1.5 0' />
                          </svg>
                        </button>
                  </div>
                </div>

                <div  className={'EditorContainerWithBlackBorder'}
                  ref={ this.initEditorContainerDomNode }
                  style={{
                    // Thin black border
                    //flex: '1 1 auto',   // This is for the height
                    width: '100%',
                    height: editorContainerHeight,
                    boxSizing: 'border-box',
                    borderLeftWidth: 1,
                    borderBottomWidth: 1,
                    borderRightWidth: 1,
                    borderTopWidth: 0,
                    borderColor: 'black',
                    borderStyle: 'solid',
                  }}>

                    <div className={'EditorContainerWithBlueBorder'}
                      style={{
                        // Thicker blue border
                        boxSizing: 'border-box',
                        height: '100%',
                        width:'100%',
                        background: FLOATING_PALETTE_BACKGROUND,
                        borderWidth: BORDER_WIDTH,
                        borderColor: BORDER_COLOR,
                        borderStyle: 'solid',
                        overflow: 'visible',
                        color: 'black',
                      }}>

                        {children}

                    </div>

                </div>

      </div>
    )
  }
}

//const SizedDragablePalette = withSize({monitorHeight:true, refreshMode:'debounce'}) (DragablePalette)
const SizedDragablePalette = withSize({monitorHeight:true}) (DragablePalette)


type FP_Props = {
  children: ReactNode,
  className?: string,
  left: number,
  top: number,
  width?: number,
  height?: number,
  title: string,
  isDraggable: boolean,
  onClose: () => void,
  dispatchReactRepositionOrResize: (x:number, y:number, width?:number, height?:number) => void,
  onSize : WithSizeOnSizeCallback,
  onActiveDrag: (left:number, right:number ) => void,
  onActiveResize?: (deltaWidth:number, deltaHeight:number) => void,
}

class FloatingPalette extends Component<FP_Props> {

  static defaultProps = {
    onSize: (_: SizeMeProps['size'])=>{},
    onActiveDrag: ()=>{},
    dispatchReactRepositionOrResize: ()=>{},
    width: null,
    height: null,
  }

  dragStartX    :number = 0
  dragStartY    :number = 0
  dragStartLeft :number = 0
  dragStartTop  :number = 0
  mostRecentPositionX = 0
  mostRecentPositionY = 0
  isActiveRAF = false

  handleTitleBarDragStart = (e: DraggableEvent, position: DraggableData) => {
    //console.log( 'handleDragStart' )
    e.preventDefault( )
    e.stopPropagation( )
    this.dragStartX = position.x
    this.dragStartY = position.y
    this.mostRecentPositionX = position.x
    this.mostRecentPositionY = position.y
    this.isActiveRAF = false
    this.dragStartLeft = this.props.left
    this.dragStartTop  = this.props.top
    rStateFloatingPalette.synchStart( )
  }

  // Unthrottled drag handler
  handleTitleBarDrag = ( e: DraggableEvent, position: DraggableData ): void => {
    e.preventDefault( )
    e.stopPropagation( )
    //console.log( 'title bar being dragged' )
    if ( this.mostRecentPositionX === position.x && this.mostRecentPositionY === position.y ) return
    this.mostRecentPositionX = position.x
    this.mostRecentPositionY = position.y
    if ( !this.isActiveRAF ) {
      window.requestAnimationFrame( ()=>this.throttledTitleBarDrag(e) )
      this.isActiveRAF = true
    }
  }

  // Throttled drag handler
  throttledTitleBarDrag = (e: DraggableEvent) : void => {
    const newLeft = this.dragStartLeft + (this.mostRecentPositionX - this.dragStartX)
    const newTop  = this.dragStartTop  + (this.mostRecentPositionY - this.dragStartY)
    //console.log( 'onActiveDrag', newLeft, newTop )
    this.props.onActiveDrag( newLeft, newTop )
    this.isActiveRAF = false
  }

  handleTitleBarDragStop = (e: DraggableEvent, position: DraggableData) => {
    e.preventDefault( )
    e.stopPropagation( )
    const newLeft = this.dragStartLeft + (this.mostRecentPositionX - this.dragStartX)
    const newTop  = this.dragStartTop  + (this.mostRecentPositionY - this.dragStartY)
    rStateFloatingPalette.synchStop( )  // Resets react virtualDom to state prior to drag.
    // This is the call for parent to dispatch new floating palette position.
    this.props.dispatchReactRepositionOrResize( newLeft, newTop )
    this.isActiveRAF = false
  }

  stopEventPropagation = (e: React.MouseEvent<HTMLDivElement>): void => {
    e.stopPropagation()
  }

  preventDefault = (e: React.MouseEvent<HTMLDivElement>): void => {
    e.preventDefault()
  }


  render () {
    const {children, className, onClose, title, isDraggable, left, top, onSize, width, height} = this.props
    //console.log( 'Floating Palette Render: props=', this.props )
    return (
      <div className={className}
        onClick={this.stopEventPropagation}
        onMouseDown={this.stopEventPropagation}
        onMouseUp={this.stopEventPropagation}
      >

      {/* Above div is required! Because the stopPropagation on mouseUp
        in DraggableCore, for reasons unknown, closes the DraggableCore */}

        <DraggableCore
          handle='.floatingPaletteTitleBar'
          enableUserSelectHack={false}
          onStart={ (e,data)=> this.handleTitleBarDragStart( e, data)}
          onDrag ={ (e,data)=> this.handleTitleBarDrag(e, data)}
          onStop ={ (e,data)=> this.handleTitleBarDragStop(e,data)}
          disabled={!isDraggable}
        >

        {/* This next div is required!  Else the draggableCore will not work.
            Suspect some bug in draggable core interacting with sizeMe function. */}

        <div className={'rc_SizedDragablePalette'}>

          <SizedDragablePalette
            children={children}
            left={left}
            top={top}
            width={width}
            height={height}
            title={title}
            onClose={onClose}
            onSize={onSize} />

        </div>

      </DraggableCore>
      </div>

    )
  }
}

export default FloatingPalette
