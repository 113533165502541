/// @flow
import React, {PureComponent} from 'react'
import invariant              from 'invariant'
import reactDispatch          from '../sharedComponents/reactDispatch'
import {cleanScryInputText2} from '../sharedFunctions/cleanScryInputText'
import constants from '../sharedComponents/constants'

type OwnProps = {
  id: string,   // The id used by the matching label string.
  tablelookid: string,
  tableid: string,
  mode: 'colUnits' | 'colPrefix' | 'colSuffix' | 'colName' | 'colDescription',
  width: number,
  height: number,
  colKey: number,
  textColor: string,
  colDataType: string,
  formatRule: string,
  initializationValue: string,
  textAreaHorzMargin: number,
}
type LocalState = {
  editableStrg: string,
  lastColKey: number,
  lastColDataType: string,
  lastFormatRule: string,
}
type Props = OwnProps

export default class EditColHeaderInputs extends PureComponent<Props, LocalState> {

  static defaultProps = {
    textAreaHorzMargin: 3,
    textColor: 'black',
  }

  constructor(props: Props) {
    super(props)
    this.state = {
      editableStrg: '',
      lastColKey: -1,
      lastColDataType: '',
      lastFormatRule: '',
    }
  }

  static getDerivedStateFromProps( nextProps:Props,   prevState:LocalState ) {
    // If we change the column key being edited, than ignore the local string under
    // edit and reinitialize the editor the that columns state value.
    // Also, we have cases where the parent needs to force a new editable string.
    // For example, within the same column, we change the dataType which forces
    // the units to a different value.  In cases like this, only the parent can
    // now that the editor needs to be reset.
    if ( nextProps.colKey       !== prevState.lastColKey ||
         nextProps.colDataType  !== prevState.lastColDataType ||
         nextProps.formatRule   !== prevState.lastFormatRule ) {
      return { editableStrg: nextProps.initializationValue,
               lastColKey: nextProps.colKey,
               lastColDataType : nextProps.colDataType,
               lastFormatRule: nextProps.formatRule }
    }
    return { }
  }

  componentWillUnmount = () => {
    //$FlowFixMe
    clearTimeout(this.timeoutID)
  }

  TIMEOUT_DELAY = 400
  timeoutID = null


  updateLocalState = ( e: SyntheticInputEvent<HTMLInputElement>) : void => {
    // Prefix and suffix get special treatment, where we retain
    // the user's spacing preferences.  (multiple consecutive spaces displayed)
    const {mode} = this.props
    switch ( mode ) {
      case 'colPrefix':
      case 'colSuffix':
        var maxCharCount = constants.MAX_LENGTH_PRESUFFIX
        break
      case 'colUnits':
        maxCharCount = constants.MAX_LENGTH_COL_UNITS
        break
      case 'colName':
        maxCharCount = constants.MAX_LENGTH_COL_NAME
        break
      default:
        maxCharCount = 1000000
    }
    const options = { mergeSpaces : (mode !== 'colPrefix' && mode !== 'colSuffix' ),
                      deleteLeadingSpaces : (mode !== 'colSuffix'),
                      maxCharCount  }
    var {newValue, newSelectionStop} = cleanScryInputText2( e.target.value, e.target.selectionEnd, options )
    if ( e.target.value.length !== newValue.length ) {
      e.target.value = newValue.slice()  // This line also resets selection start to useless value.
      e.target.selectionStart = newSelectionStop
      e.target.selectionEnd   = newSelectionStop
    }
    this.setState( {editableStrg: newValue } )
    //$FlowFixMe
    clearTimeout(this.timeoutID)
    this.timeoutID = setTimeout( this.updateReactState, this.TIMEOUT_DELAY)
  }


  updateReactState = ():void => {
    const {mode, colKey, tablelookid, tableid} = this.props
    var path = ''
    var actionDescription = ''
    var resId = ''
    var resType = ''
    var currentStrg = this.state.editableStrg
    switch ( mode ) {

        case 'colPrefix' :
          path = `attributes.lookColumns[${colKey}].prefix`
          actionDescription = 'Edit column prefix'
          resId = tablelookid
          resType = 'tablelooks'
          break

        case 'colSuffix' :
          path = `attributes.lookColumns[${colKey}].suffix`
          actionDescription = 'Edit column suffix'
          resId = tablelookid
          resType = 'tablelooks'
          break

        case 'colUnits' :
          path = `attributes.columns[${colKey}].units`
          actionDescription = 'Edit column units'
          resId = tableid
          resType = 'tables'
          break

        case 'colDescription' :
          path = `attributes.columns[${colKey}].description`
          actionDescription = 'Edit column description'
          resId = tableid
          resType = 'tables'
          break

        case 'colName' :
          // remove extraSpaces from the title
          currentStrg = currentStrg.trim()                    // No external spaces.
          currentStrg = currentStrg.replace( / {2,}/g, ' ' )
          path = `attributes.columns[${colKey}].colTitle`
          actionDescription = 'Edit column name'
          resId = tableid
          resType = 'tables'
          break

        default:
          if ( process.env.NODE_ENV === 'development' ) {
            invariant( false, `Unknown mode '${mode}' passed to EditUniCodeInputs.js` )
          }
    }
    reactDispatch( [{ newVal: currentStrg, path, resId, resType }] , actionDescription )
  }

  render() {
    const {width, height, id, mode, textAreaHorzMargin, textColor} = this.props
    var inputComponent = 'input'   // assumption
    if ( mode === 'colDescription' ) {
      inputComponent = 'textarea'
    }
    const borderColor = constants.COLHEADER_INPUT_BORDER_COLOR

    if ( inputComponent === 'input' ) {
      let style : Object = {
        display: 'inline-block',
        fontSize: 14,
        width, height,
        marginRight: 0,
        paddingLeft: 3, paddingTop: 0,
        verticalAlign: 'top',
        borderWidth: 2, borderRadius: 4, borderColor,
        color:textColor,
      }

      return <input
                className={'rc_EditColHeaderInputs'}
                id={id}
                onChange={this.updateLocalState}
                style={style}
                type='text'
                value={this.state.editableStrg}
                autoComplete='off'
                spellCheck='false' />
    }


    if ( inputComponent === 'textarea' ) {
      let style :Object = {
        width, height: 82,
        marginLeft:  textAreaHorzMargin, marginRight: textAreaHorzMargin,
        paddingLeft: 3, paddingRight: 2,
        lineHeight: '140%',
        resize: 'none',
        borderWidth: 2, borderColor, borderStyle:'inset', borderRadius: 5,
        color:textColor,
        fontSize: 16, fontFamily: 'serif',
      }

      return <textarea
                id={id}
                onChange={this.updateLocalState}
                style={style}
                type='text'
                value={this.state.editableStrg}
                autoComplete='off'
                spellCheck='false' />
    }
  }
}
