import type { Column, Columnlook } from '../types'
import type { SubStringInfo } from '../computedDataTable/formulaTypes'
import type { TableComputedData } from '../computedDataTable/getDefaultTableComputedData'

import { isEmpty } from 'radash'
import {Component} from 'react'
import EditColFormula   from './EditColFormula'
import {nonBreakingSpace} from '../sharedComponents/constants'

const spacer:string = nonBreakingSpace+nonBreakingSpace

// const rowHeight  = 20
const width      = 490  // FORMULA_EDITOR_WIDTH of 480 for text area, and 5 for margin, both sides
// const labelStyle: CSSProperties = {
//   display: 'inline-block',
//   overflow: 'hidden',
//   paddingTop:  2,  // determined emperically ! will change with fontsize.
//   textAlign: 'right',
//   width: 60,
//   height: rowHeight,
//   fontWeight: 'bold',
//   //background: 'red',
// }

type Props = {
  // Next two never used.  Just present to trigger a re-render
  lookColumns: Columnlook[],
  columns: Column[],

  tableid: string,

  colKey: number,
  rowKey: number,
  canEdit: boolean,
  tableComputedData: TableComputedData,
}

// This function needs BOTH the ScryFormula from the state (always saved as canonical form)
// AND the editoScryFormula - contains both current 'in-process-of-edit' form, and canonical form.
export default class EditCellFormulaDebugger extends Component<Props> {

  render() {
    //console.log( 'EditCellFormulaDebugger Render called' )
    const {tableid, colKey, rowKey, tableComputedData} = this.props
    const {canEdit} = tableComputedData
    const { parsedScryFormula } = tableComputedData.derivedColAttributesArray[colKey]

    let formulaSubStrings: SubStringInfo[][] = []
    let tableComputedDataErrorID = ''

    if ( parsedScryFormula ) {
      formulaSubStrings = parsedScryFormula.formulaSubStrings
      tableComputedDataErrorID = parsedScryFormula.errorID
    }

    // We define debugger state as the very first line beginning with '#_debugger'
    // If properly typed by user, this text will be found in subString[0][1].text
    const wasDebuggerStatementFound = (canEdit && formulaSubStrings[0][1].text.slice(0,10) === '#_debugger' )
    // $FlowFixMe
    const program_RPN = parsedScryFormula?.program_RPN
    // $FlowFixMe
    const program_OPT = parsedScryFormula?.program_OPT
    var mergedFormulaSubStrings: SubStringInfo[] = []

    if ( wasDebuggerStatementFound ) {
      for (const thisLineArray of formulaSubStrings) {
        mergedFormulaSubStrings = mergedFormulaSubStrings.concat( ...thisLineArray )
        mergedFormulaSubStrings.push( {} as SubStringInfo )
      }
    }

    if ( wasDebuggerStatementFound === false ) {
      return (
            <div className={'containerForEditColFormula'}
              style={{  marginTop: 5,
                marginLeft:5, marginRight:5 }}>

               <EditColFormula
                 heightLabel={20}
                 heightMaxViewMode={180}
                 heightEditMode={180}
                 width={width}
                 tableid={tableid}
                 colKey={colKey}
                 rowKey={rowKey}
                 isCellMode={true}
                //  labelStyle={{
                //    ...labelStyle,
                //    paddingLeft: 0,
                //  }}
                 tableComputedData={tableComputedData}
                 errMessageFormula={tableComputedDataErrorID}
                //  wrnMessageHeader={''}
                 errMessageHeader={''}/>
          </div>
    )}

    // Else, following code will render the entire debugger:
    return (

        <div className={'rc_EditCellFormulaDebugger'}
          style={{
            //display: 'flex', flexFlow: 'column nowrap', alignItems:'stretch',
            height: '100%', width: '100%',
            marginLeft: 5, marginRight: 5, marginTop:5,
            fontSize: '14px',
          }}>


            <EditColFormula
              // canEdit={true}
              isCellMode={false}
              heightLabel={canEdit ? 36 : 24}
              heightMaxViewMode={180}
              heightEditMode={180}
              width={width}
              tableid={tableid}
              colKey={colKey} rowKey={-1}
              tableComputedData={tableComputedData}
              // labelStyle={labelStyle}
              errMessageHeader={''}
              // wrnMessageHeader={''}
              errMessageFormula={tableComputedDataErrorID} />

            <EditColFormula
              isCellMode={true}
              heightLabel={canEdit ? 36 : 24}
              heightMaxViewMode={180}
              heightEditMode={180}
              width={width}
              tableid={tableid}
              colKey={colKey} rowKey={rowKey}
              tableComputedData={tableComputedData}
              // labelStyle={labelStyle}
              errMessageHeader={''}
              // wrnMessageHeader={''}
              errMessageFormula={tableComputedDataErrorID} />

            <div className={'scrollableContainer'}
                style={{marginTop: 2, width:width+100, height:300, overflowY:'scroll'}}>
              <div className={'scrollableContent'}
                   style={{ overflow:'hidden' }}>

                  <div style={{display:'inline-block',  width:170, overflowX:'hidden', marginLeft:8}}>
                        <div style={{fontWeight:'bold', height:24}}>Parsed:</div>

                        {mergedFormulaSubStrings.map( (token, i) => {
                            let FdisplayStrg
                            if ( !isEmpty(token) ) {
                              let {name, text, value} = token
                              text = text.replace( / /g, nonBreakingSpace )
                              FdisplayStrg = `${name}`
                              if ( name==='indent' && text==='' ) FdisplayStrg += `${spacer}none`
                              else if ( text !== '' ) FdisplayStrg += `${spacer}'${text}'`
                              else if ( name==='colName' ) { FdisplayStrg += String(value) }
                              else if ( name.slice(0,6)==='number' ) { FdisplayStrg += String(value) }
                            } else { FdisplayStrg = '' }
                            // We need to use HTML to retain the original spaces.
                            return (
                              <div className='ParsedSubStrings' key={FdisplayStrg+String(i)}
                                   style={{display: 'inline-block', height:16, width:170, whiteSpace:'nowrap' }}>
                                {FdisplayStrg}
                              </div>
                            )
                          })
                        }
                  </div>

                  <div style={{display:'inline-block', width:160, verticalAlign:'top', overflowX:'hidden', marginLeft:5}}>
                      <div style={{fontWeight:'bold', height:24}}>Compiled:</div>
                      {program_RPN && program_RPN.map( (opCode,lineNum) => { // Each opCode a lineNum. NOT related to expression lineNumbers.
                          let spaceCount = 1 + opCode.indentLevel * 2
                          // 3 digit lineNums needed.  Set indent spacing to align text right of lineNums.
                          if ( program_OPT && program_OPT.length >= 100 ) {
                            if ( Number(lineNum) < 10 ) {spaceCount += 4}
                            else if ( Number(lineNum) < 100 ) {spaceCount += 2}
                          } else {
                            //only two digit lineNums required
                            if (  Number(lineNum) < 10 ) {spaceCount += 2}
                          }
                          const lineNumString = String(lineNum) + '\u00A0'.repeat(spaceCount)
                          return (
                              <div className='unOptimizedRPN' key={opCode.name+lineNumString}
                                style={{display: 'inline-block', height:16, width:140, whiteSpace:'nowrap' }}>
                                {`${lineNumString}${opCode.name} ${String(opCode.value)}`}
                              </div>
                          )
                        })
                      }
                  </div>

                  <div style={{display:'inline-block', width:215, verticalAlign:'top', overflowX:'hidden', marginLeft:5}}>
                      <div style={{fontWeight:'bold', height:24}}>Optimized:</div>
                      {program_OPT && program_OPT.map( (opCode,lineNum) => {  // Each opCode a lineNum. NOT related to expression lineNumbers.
                          let spaceCount = 1 + opCode.indentLevel * 2
                          // 3 digit lineNums needed.  Set indent spacing to align text right of lineNums.
                          if ( program_OPT.length >= 100 ) {
                            if ( Number(lineNum) < 10 ) {spaceCount += 4}
                            else if ( Number(lineNum) < 100 ) {spaceCount += 2}
                          } else {
                            //only two digit lineNums required
                            if (  Number(lineNum) < 10 ) {spaceCount += 2}
                          }
                          const lineNumString = String(lineNum) + '\u00A0'.repeat(spaceCount)
                          return (
                              <div className='optimizedRPN' key={opCode.name+lineNumString}
                                style={{ display: 'inline-block', whiteSpace: 'nowrap', height:16, width:175}}>
                                {`${lineNumString}${opCode.name} ${String(opCode.value)}`}
                              </div>
                          )
                        })
                      }
                  </div>
              </div>
            </div>
        </div>
    )
  }
}
