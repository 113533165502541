import type { GenericObject } from '../types'
import type { BasisName, PlotXyComputedData } from './xy_plotTypes'
import { FONT_FAMILY_CSS_FORMAT } from '../sharedComponents/constants'
import { measureText, measureLongestText } from '../sharedFunctions/measureText'
import { startTimer, logTime } from '../sharedFunctions/timer'
import { plotLayoutConsts } from '../viewPlotXY/plotLayoutConsts'
import { smartAxisScale_Part2_StringDataTypesOnly } from './smartAxisScale'

const DEBUG = false
const TIMER_ON = false

const errorOnNonIntegers = (obj: GenericObject): void => {
  for (var name in obj) {
    if (typeof obj[name] === 'number') {
      if (obj[name] !== Math.round(obj[name])) {
        console.log(`CALCED NON-ITEGER value ${name}: ${obj[name]} in layoutCalculator.`)
      }
    }
  }
}


/*
-------------------------------------------------------------------
|     viewWidthPx, viewHeightPx
|          |                         (TopMenuBar)
| (NavCol) |-------------------------------------------------------
|          |         focalPlanePx   (PlotFocalPlane Component)
|          |                          Main Title,
|          |                         SourceTitle,
|          |                         topMargin
|          |                         centeringGap
|          |            -----------------------------------------------
|          |            |    reactVisArea     // the svg coord system
|          |            |    reactVisAreaInPx // after scaling, in screen Px
|          |            |
|          |            |              legend
|          |  centering |         ------------------------------------
|          |    gap     |         |  plottedData
|          |     +      |         |
|          | leftMargin |  axis   |       area where coord system
|          |            |         |       is defined by the axes.
|          |            |         |
|          |            |         -------------------------------------
|          |            |               axis
|          |            -----------------------------------------------
|          --------------------------------------------------------
|------------------------------------------------------------------

*/



var initialPlotWidthObj = {
  // from left to right
  viewWidthPx: 0,
  // after removing NavCol, consists of:
  focalPlanePx: 0,  // viewWidthPx - leftNavBar - rightSideBar
  leftMarginPlusCenteringGap: 0,
  leftMargin: 0,
  centeringGap: 0,
  reactVisAreaInPx: 0,  // In screen pixels !
  reactVisArea: 0,   // In SVG pixels !
  // Consisting of:
  totalLeftAxis_displayPx: 0,
  totalLeftAxis: 0,           // svgPx (react_vis plotting px)
  // consisting of:
  leftAxisTitle: 0,
  gap_leftAxisTitle_SubTitle: 0,
  leftAxisSubTitle: 0,
  gap_Titles_TickLabel: 0,
  leftAxisTickLabel: 0,
  plottedData: 0,
  plottedData_displayPx: 0,  // svgPx
  overhangOfLastBottomAxisTickLabel: 0,
  rightMargin: 0,


  // Title Layout
  mainTitleLeft: 0,
  publisherTitleLeft: 0,
  mainTitle: 0,
  publisherTitle: 0,

  overhangOfFirstBottomAxisTickLabel: 0,

  // Legend layout summary
  legendWidths: Array<number[]>(),   // And array of rows, and each row is an array of widths.
  legendSeriesKeys: Array<number[]>(),  // The series key in the same order as the above widths

  // This is the plotted area only, corresponding to the plot's xDomain.
  leftOffsetTitle: 0,
  leftOffsetSubTitle: 0,

  // zero Axis Line offset (in the x direction) when xAxis 'zero' TickValue falls within the interior of the plottable area
  zeroAxisTranslationIn_X: 0,
  zeroAxisHalfWidthInVisCoords_X: 0,
  legendGapBetweenItems: 0,
  leftAxisTickPadding: 0,    // horizontal distance of label from tick mark
}
export type PlotWidthObj = typeof initialPlotWidthObj
export const defaultPlotWidthObj: PlotWidthObj = Object.seal(Object.assign({}, initialPlotWidthObj))

const initialPlotHeightObj = {
  // From top to bottom
  viewHeightPx: 0,  // window.innerHeight
  // after removing TopMenuBar, consists:
  focalPlanePx: 0,
  totalTitles: 0,
  topMargin: 0,
  mainTitle: 0,
  publisherTitle: 0,
  gapPublisherLegend: 0,
  centeringGap: 0,
  totalCenteringGapTitles: 0,
  reactVisAreaInPx: 0,     // In screen pixels !
  reactVisArea: 0,  // In SVG pixels !
  // Consisting of:
  totalLegend: 0,
  legend: 0,
  gapLegendPlot: 0,
  plottedData_displayPx: 0,
  plottedData: 0,
  totalBottomAxis: 0,
  bottomAxisTickLabel: 0,
  gap_TickLabel_Title: 0,
  bottomAxisTitle: 0,
  gap_bottomAxisTitle_SubTitle: 0,
  bottomAxisSubTitle: 0,
  gap_PostSubTitle: 0,
  bottomMargin: 0,
  totalCenteringGapTitlesLegend_displayPx: 0,

  // zero Axis Line offset (in the y direction) when yAxis 'zero' TickValue falls within the interior of the plottable area
  zeroAxisTranslationIn_Y: 0,
  zeroAxisHalfHeightInVisCoords_Y: 0,

  legendRowHeight: 0,
  numLegendRows: 1,

  legendGapAboveLine: 0,
  legendLineThickness: 0,
  legendGapLineText: 0,
  legendGapBelowText: 0,
  bottomAxisTickPadding: 0,    // vertical distance of label from tick mark
}
export type PlotHeightObj = typeof initialPlotHeightObj
export const defaultPlotHeightObj: PlotHeightObj = Object.seal(Object.assign({}, initialPlotHeightObj))


export type AxisFontSizes = {
  [key in BasisName]: number
}

const initalAxisFontSizes: AxisFontSizes = {
  A: 10,
  B: 10,
  C: 10
}

export const initialPlotStyleObj = {
  reactVisScale: 1,     // Global scale factor that applies to both x and y directions.
  plotTransform: 'translate(0px,0px) scale(1,1)',
  plotScaleTransform: 'scale(1,1) ',
  plotOffsetTransform: 'translate(0px,0px) ',
  displayToPlotsvgTransform: (x1: number, y1: number) => ({ x: x1, y: y1 }),
  plotsvgToDisplayTransform: (x1: number, y1: number) => ({ x: x1, y: y1 }),
  fontSizeLegend: 10,
  fontSizeTickLabel: initalAxisFontSizes,   // Different tickScales for each of the 3 axes.
  fontSizeAxisTitle: 10,
  fontSizeAxisSubTitle: 10,
  fontSizeTitle: 10,
  fontSizePublisher: 10,
  fontSizeErrMsg: 10,
  axisTickSizeOuter: 6,
  axisTickSizeInner: 2,
  axisLineThickness: 0,
  plotLineThickness: 0,
  markSizeScale: 0,
  gridLineThickness: 0,
  pinnedRowStarSize: 0,
  pinnedRowStarOutlineThickness: 0,
}
export type PlotStyleObj = typeof initialPlotStyleObj
export const defaultPlotStyleObj: PlotStyleObj = Object.seal(Object.assign({}, initialPlotStyleObj))

// Width and Height calculators are not independent because we desire a fixed aspect ratio plot shape.
// Hence we calc plotWidthObj, plotHeightObj, and plotStyleObj all in the same function:

export const plotCalculator = (plt: PlotXyComputedData, forcedViewSize: number = 0)
  : { plotStyleObj: PlotStyleObj, plotWidthObj: PlotWidthObj, plotHeightObj: PlotHeightObj } => {

  if (TIMER_ON) {
    startTimer('ReactRender_Timer')
    logTime('ReactRender_Timer', 'Call to plotCalculator')
  }

  //console.log( 'Call to plotCalculator', plt )

  // This function should return its results, rather than assigning them to
  // plotXyComputedData input object.  Because we may call plotCalculator to do some
  // geometric calculation (for example responsive interactions), and these
  // type of calculations are 'what if ?'  So the usage model of these return
  // objects determines whether the calling function should assign them, or
  // not assign them to the plotXyComputedData input object.

  const s: PlotStyleObj = Object.seal(Object.assign({}, defaultPlotStyleObj))
  const w: PlotWidthObj = Object.seal(Object.assign({}, defaultPlotWidthObj))
  const h: PlotHeightObj = Object.seal(Object.assign({}, defaultPlotHeightObj))

  if (forcedViewSize === 0) {  // Expected flow
    w.viewWidthPx = window.innerWidth
    h.viewHeightPx = window.innerHeight
    w.focalPlanePx = plt.focalPlaneWidthPx
    h.focalPlanePx = plt.focalPlaneHeightPx

  } else {
    // This is the exception used by renderSVG when we 'lie' to the plot calculator
    // in order to create an SVG with large width/height scales.
    // And hence provide plenty of resolution to render text accurately.
    // Only text spacing is the concern.  Everything else is proportional to
    // plot height, and overall svg width/height does not effect quality.
    w.viewWidthPx = forcedViewSize
    h.viewHeightPx = forcedViewSize
    w.focalPlanePx = forcedViewSize
    h.focalPlanePx = forcedViewSize
  }

  s.fontSizeTitle = plotLayoutConsts.mainTitleFontSize * plt.fontScaleTitle
  s.fontSizePublisher = plotLayoutConsts.publisherTitleFontSize * plt.fontScalePublisher

  h.topMargin = 10    // Same as nominal Table mainTitle, but no need to be identical.
  h.mainTitle = s.fontSizeTitle + 10
  h.publisherTitle = s.fontSizePublisher + 10
  h.gapPublisherLegend = 20
  h.bottomMargin = 16
  w.leftMargin = 16
  w.rightMargin = 16
  h.totalTitles = h.topMargin + h.mainTitle + h.publisherTitle + h.gapPublisherLegend


  w.mainTitle = measureText(plt.mainTitleDisplayed, `${s.fontSizeTitle}px`, 'bold') + 10
  w.publisherTitle = measureText(plt.publisherTitleDisplayed, `${s.fontSizePublisher}px`) + 10

  // Limiting to left value to be >= 0 results in:
  //    - A centered title, unless title is wider than the available width!
  //    - In case of too wide a title, align to left edge and right edge will truncate rest of title.
  //    - Other option to keep centered and truncate both left/right edges will not work because this
  //      plot title sits 'above' the NavColumn.  And hence left side of title stomps on the NavColumn.
  w.mainTitleLeft = Math.max(0, (w.focalPlanePx - w.mainTitle) / 2)
  w.publisherTitleLeft = Math.max(0, (w.focalPlanePx - w.publisherTitle) / 2)

  const availableReactVisHeight = h.focalPlanePx - h.topMargin - h.mainTitle - h.publisherTitle - h.gapPublisherLegend - h.bottomMargin
  const availableReactVisWidth = w.focalPlanePx - w.leftMargin - w.rightMargin

  // ALL plot sizes should be some proportion of either the
  //    nominalReactVisSize (the full plottable area in SVG pixels)
  //    nominalUnitScale ( the emperical 'typical' fontSize in SVG pixels)
  const { nominalReactVisSize, nominalFontScale, nominalMarkScale,
    nominalAxisLineThickness, nominalPlotLineThickness } = plotLayoutConsts

  // Set the pixel resolution (SVG) such that we have:
  //     nominalReactVisSize pixels in the longer  plot rectangle side.
  //   < nominalReactVisSize pixels in the shorter plot rectangle side.
  if (plt.aspectRatio >= 1) {
    w.reactVisArea = nominalReactVisSize
    h.reactVisArea = nominalReactVisSize / plt.aspectRatio
  } else {
    w.reactVisArea = nominalReactVisSize * plt.aspectRatio
    h.reactVisArea = nominalReactVisSize
  }
  //console.log( w.reactVisArea, h.reactVisArea )

  // Calculate the required reactVisScale such that BOTH x and y plot
  // extents are maximum and both fit with the available width/height.
  // In other words:
  //    - either the width or the height will fit exactly in the available area.
  //    - Too keep pixels square they both have the same VisScale
  //    - Hence one axis fits exactly, the other takes less room than available.
  //    - The left over room is used to calculate the centeringGap.
  //    - Hence only one axis at any given time, aspectRatio, or window size, has a non-zero centeringGap.
  let scaleInHeight = availableReactVisHeight / h.reactVisArea
  let scaleInWidth = availableReactVisWidth / w.reactVisArea
  s.reactVisScale = Math.min(scaleInHeight, scaleInWidth)

  w.reactVisAreaInPx = w.reactVisArea * s.reactVisScale
  h.reactVisAreaInPx = h.reactVisArea * s.reactVisScale

  // Center the plot:
  w.centeringGap = (availableReactVisWidth - w.reactVisAreaInPx) / 2
  h.centeringGap = (availableReactVisHeight - h.reactVisAreaInPx) / 2
  w.leftMarginPlusCenteringGap = w.leftMargin + w.centeringGap
  h.totalCenteringGapTitles = h.totalTitles + h.centeringGap
  h.totalCenteringGapTitlesLegend_displayPx =
    h.totalCenteringGapTitles + (h.legend * s.reactVisScale)

  // Scale the plot;  Also requires an offset since scaling is done from
  // the center point of the SVG
  let Xtranslation_to_upperLeft = -(w.reactVisArea - (w.reactVisArea * s.reactVisScale)) / 2
  let Ytranslation_to_upperLeft = -(h.reactVisArea - (h.reactVisArea * s.reactVisScale)) / 2
  s.plotOffsetTransform = `translate( ${Xtranslation_to_upperLeft}px, ${Ytranslation_to_upperLeft}px ) `
  s.plotScaleTransform = `scale( ${s.reactVisScale}, ${s.reactVisScale} ) `
  // Next operations are done in the order of 'rightmost first', and 'leftmost last'.
  s.plotTransform = s.plotOffsetTransform + s.plotScaleTransform
  s.displayToPlotsvgTransform = (x1, y1) => {
    const x = Xtranslation_to_upperLeft + (x1 - Xtranslation_to_upperLeft) / s.reactVisScale
    const y = Ytranslation_to_upperLeft + (y1 - Ytranslation_to_upperLeft) / s.reactVisScale
    return { x, y }
  }
  s.plotsvgToDisplayTransform = (x1, y1) => {
    const x = (x1 - w.totalLeftAxis) + (x1 * s.reactVisScale)
    const y = (y1 + (y1 * s.reactVisScale))
    return { x, y }
  }


  // The 'base' fontSize for different elements is set emperically. (PlotLayoutConsts file)
  // They scale proportionally with h.fullPlot (As the available plot render area is resized)
  // And they scale proportionally to user's slider bar fontSize settings.
  s.fontSizeTickLabel.A = Number((nominalFontScale * plt.basisA.fontScaleTickValues *
    plotLayoutConsts.fontSizeFactorTickLabel).toFixed(2))
  s.fontSizeTickLabel.B = Number((nominalFontScale * plt.basisB.fontScaleTickValues *
    plotLayoutConsts.fontSizeFactorTickLabel).toFixed(2))
  s.fontSizeTickLabel.C = Number((nominalFontScale * plt.basisC.fontScaleTickValues *
    plotLayoutConsts.fontSizeFactorTickLabel).toFixed(2))
  s.fontSizeLegend = Number((nominalFontScale * plt.fontScaleLegend *
    plotLayoutConsts.fontSizeFactorLegend).toFixed(2))
  s.fontSizeAxisTitle = Number((nominalFontScale * plt.fontScaleAxisNames *
    plotLayoutConsts.fontSizeFactorAxisTitle).toFixed(2))
  s.fontSizeAxisSubTitle = Number((nominalFontScale * plt.fontScaleAxisNames *
    plotLayoutConsts.fontSizeFactorAxisSubTitle).toFixed(2))
  s.fontSizeErrMsg = Number(nominalFontScale)
  s.axisTickSizeOuter = nominalAxisLineThickness * 2.0
  s.axisTickSizeInner = nominalAxisLineThickness * 2.0
  s.axisLineThickness = nominalAxisLineThickness
  s.plotLineThickness = nominalPlotLineThickness
  s.markSizeScale = nominalMarkScale * 1
  s.gridLineThickness = nominalPlotLineThickness * 0.2
  s.pinnedRowStarSize = nominalMarkScale * 0.45
  s.pinnedRowStarOutlineThickness = nominalPlotLineThickness * 0.1
  const { leftAxis, bottomAxis } = plt
  const leftTickFontSize = s.fontSizeTickLabel[leftAxis.basisName]
  const bottomTickFontSize = s.fontSizeTickLabel[bottomAxis.basisName]

  // On first pass through calculator, these strings are not accurate!
  // Current code just uses one dummy string of 'typical longest length'.
  // On second pass, the array 'tickUserStringsMeasureOnly' should be all the
  // actual plotted tick labels.
  var leftTickLabelMaxLength = measureLongestText(leftAxis.tickUserStringsMeasureOnly, `${leftTickFontSize}px`)
  var bottomTickLabelMaxLength = measureLongestText(bottomAxis.tickUserStringsMeasureOnly, `${bottomTickFontSize}px`)
  //var bottomTickLastLabelLength  = measureText(lastVal(bottomAxis.tickUserStringsMeasureOnly),  `${bottomTickFontSize}px` )
  var bottomTickFirstLabelLength = measureText(bottomAxis.tickUserStringsMeasureOnly[0], `${bottomTickFontSize}px`)

  w.leftAxisTitle = s.fontSizeAxisTitle
  if (plt.leftAxis.axisSubTitle) {
    // then allocate room for a sub-Title
    w.leftAxisSubTitle = s.fontSizeAxisSubTitle
    w.gap_leftAxisTitle_SubTitle = nominalFontScale * .3
    w.gap_Titles_TickLabel = nominalFontScale * .7
  } else {
    w.leftAxisSubTitle = 0
    w.gap_leftAxisTitle_SubTitle = 0
    w.gap_Titles_TickLabel = nominalFontScale * .7
  }
  w.leftAxisTickPadding = nominalFontScale * 0.2
  w.leftAxisTickLabel = Math.max(leftTickLabelMaxLength, 0) + s.axisTickSizeOuter + w.leftAxisTickPadding

  // The right side margin is the greater of the room needed for the bottom axis
  // labels, room for potential right axis labels, or room for the style open button.
  w.overhangOfLastBottomAxisTickLabel = (plt.bottomAxis.labelAngle === 0)
    ? 20 //0.5*bottomTickLastLabelLength + 0.005 * nominalReactVisSize
    : 20 // 0.5*bottomTickFontSize + 0.005 * nominalReactVisSize

  let angleInRads = plt.bottomAxis.labelAngle * Math.PI / 180
  w.overhangOfFirstBottomAxisTickLabel = (plt.bottomAxis.labelAngle === 0)
    ? 0.5 * bottomTickFirstLabelLength + 0.005 * nominalReactVisSize
    : Math.cos(angleInRads) * bottomTickFirstLabelLength
    - Math.sin(angleInRads) * 0.5 * bottomTickFontSize
    + 0.005 * nominalReactVisSize

  // This is the plotted area only, corresponding to the plot's xDomain.
  w.totalLeftAxis = w.leftAxisTitle + w.gap_leftAxisTitle_SubTitle + w.leftAxisSubTitle + w.gap_Titles_TickLabel + w.leftAxisTickLabel
  w.leftOffsetTitle = w.gap_leftAxisTitle_SubTitle + w.leftAxisSubTitle + w.gap_Titles_TickLabel + w.leftAxisTickLabel
  w.leftOffsetSubTitle = w.leftAxisTickLabel + w.gap_Titles_TickLabel

  w.plottedData = w.reactVisArea - w.totalLeftAxis - w.overhangOfLastBottomAxisTickLabel  // svgPixels
  w.totalLeftAxis_displayPx = w.totalLeftAxis * s.reactVisScale

  // BottomAxis Height calculations:
  const angle = -plt.bottomAxis.labelAngle
  // First term is horizontal labels, 2nd term is angled labels
  if (angle === 0) {
    h.bottomAxisTickPadding = nominalFontScale * 0.3
    h.bottomAxisTickLabel = s.axisTickSizeOuter + h.bottomAxisTickPadding + bottomTickFontSize
  } else {
    h.bottomAxisTickPadding = nominalFontScale * 0.3 + bottomTickFontSize * (1 - Math.sin(angle * 3.14159 / 180))
    h.bottomAxisTickLabel = s.axisTickSizeOuter + h.bottomAxisTickPadding + bottomTickLabelMaxLength * Math.sin(angle * 3.14159 / 180)
  }

  h.gap_TickLabel_Title = nominalFontScale * .2
  h.bottomAxisTitle = s.fontSizeAxisTitle
  h.gap_bottomAxisTitle_SubTitle = nominalFontScale * .3
  h.bottomAxisSubTitle = (plt.bottomAxis.axisSubTitle) ? s.fontSizeAxisSubTitle : 0
  h.gap_PostSubTitle = (plt.bottomAxis.axisSubTitle) ? s.fontSizeAxisSubTitle * 0.4 : 0
  h.totalBottomAxis = h.bottomAxisTickLabel + h.gap_TickLabel_Title + h.bottomAxisTitle +
    h.gap_bottomAxisTitle_SubTitle + h.bottomAxisSubTitle + h.gap_PostSubTitle

  // Legend Sizing:
  let fontScaleLegend = plt.fontScaleLegend
  let rightMargin = w.legendGapBetweenItems = Math.round(nominalFontScale * fontScaleLegend * .8)
  let leftMargin = rightMargin    // These set the gapsize between legends
  // This sets the vertical gap between legends lines (if more than 1 line)
  h.legendGapAboveLine = h.legendGapBelowText = Math.round(nominalFontScale * fontScaleLegend * .3)
  h.legendLineThickness = Math.round(nominalFontScale * fontScaleLegend * .2)
  h.legendGapLineText = Math.round(nominalFontScale * 0)


  const seriesLabelWidths = plt.seriesAttributesArray.map(series => {
    var testLength = Math.ceil(measureText(series.seriesTitle, `${s.fontSizeLegend}px`,
      'normal', FONT_FAMILY_CSS_FORMAT, false, false))
    return Math.max(plotLayoutConsts.minLegendWidth, testLength)
  })

  // We need to decide how many legend rows are required.
  // Legends are placed left-to-right, row0 to rowN,
  // in the order of seriesOrder.
  // This code MUST match our custom code in the Legend vis-component,
  // as to the exact criteria for deciding to start a new legend line.

  let runningWidth = leftMargin
  w.legendWidths = Array<number[]>(Array<number>())   // And array of rows, and each row is an array of widths.
  w.legendSeriesKeys = Array<number[]>(Array<number>())  // The series key in the same arrangement as the above widths
  h.numLegendRows = 1
  for (const seriesKey of plt.seriesOrder) {
    // Test whether this next series will push up beyond the right edge of the plot
    // Meaning we need an additional row of legends.  Use the same calculation
    // and logic as the Legend component, which has the same needs.
    // ASSUME: Next legend label fits horizontally
    runningWidth += seriesLabelWidths[seriesKey] + w.legendGapBetweenItems
    // But maybe we ran out of horizontal room?
    if (runningWidth - w.legendGapBetweenItems + rightMargin > w.plottedData) {
      h.numLegendRows++   // Another row
      w.legendWidths.push(Array<number>())   // an array of widths for this new row.
      w.legendSeriesKeys.push(Array<number>())
      // Initial condition for this new row.
      runningWidth = w.legendGapBetweenItems + seriesLabelWidths[seriesKey] + w.legendGapBetweenItems
    }
    w.legendWidths[h.numLegendRows - 1].push(seriesLabelWidths[seriesKey])
    w.legendSeriesKeys[h.numLegendRows - 1].push(seriesKey)
  }

  h.legendRowHeight = h.legendGapAboveLine + h.legendLineThickness + h.legendGapLineText + s.fontSizeLegend + h.legendGapBelowText
  h.legend = h.numLegendRows * h.legendRowHeight
  h.gapLegendPlot = 0.6 * s.fontSizeLegend
  h.totalLegend = h.legend + h.gapLegendPlot

  // This is the plotted area only, corresponding to the plot's yDomain.
  h.plottedData = h.reactVisArea - h.totalLegend - h.totalBottomAxis
  h.totalCenteringGapTitlesLegend_displayPx = h.totalCenteringGapTitles + (h.totalLegend * s.reactVisScale)

  // Should we re-position the x/y bold axis line to the zero point of the axis?
  if (plt.bottomAxis.domainExtended[0] < 0 && plt.bottomAxis.domainExtended[1] >= 0) { // test for 'is zero point of axis internal to plottable area?
    // don't do it for a time axis, since 1970 is zero in time units
    // don't do it for a string axis (balancing unused string slots can force domain[0] to value less than zero.)
    // don't do it for logarithic scales
    if (plt.bottomAxis.internalDataType === 'number' && plt.bottomAxis.willUseLogarithmicScale === false) {
      w.zeroAxisTranslationIn_X = w.plottedData * -plt.bottomAxis.domainExtended[0] / (plt.bottomAxis.domainExtended[1] - plt.bottomAxis.domainExtended[0])
    }
  }
  if (plt.leftAxis.domainExtended[0] < 0 && plt.leftAxis.domainExtended[1] >= 0) { // test for 'is zero point of axis internal to plottable area?
    if (plt.leftAxis.internalDataType === 'number' && plt.leftAxis.willUseLogarithmicScale === false) {
      h.zeroAxisTranslationIn_Y = -h.plottedData * -plt.leftAxis.domainExtended[0] / (plt.leftAxis.domainExtended[1] - plt.leftAxis.domainExtended[0])
    }
  }

  // For stacked and distribution plots only, we do not want the bars sitting on top of the axis.
  // Difficult to do this with SVG rendering order (buried in the vis library)
  // So instead, we will render a bar touching coordinate zero 'as close to zero as possible with obscuring the axis'
  // We know the axis width set by this calculator.
  // But we need to convert this to a vis 'delta coordinate' value!
  if (plt.bottomAxis.internalDataType === 'number' && plt.bottomAxis.willUseLogarithmicScale === false) {
    w.zeroAxisHalfWidthInVisCoords_X =
      s.axisLineThickness / w.plottedData * (plt.bottomAxis.domainExtended[1] - plt.bottomAxis.domainExtended[0]) / 2
  }
  if (plt.leftAxis.internalDataType === 'number' && plt.leftAxis.willUseLogarithmicScale === false) {
    h.zeroAxisHalfHeightInVisCoords_Y =
      s.axisLineThickness / h.plottedData * (plt.leftAxis.domainExtended[1] - plt.leftAxis.domainExtended[0]) / 2
  }

  if (DEBUG) { errorOnNonIntegers(w) }
  if (DEBUG) { errorOnNonIntegers(h) }
  //console.log('Plot height calculator output:', h )
  //console.log('Plot width calculator output:', w )
  //console.log('Plot style calculator output:', s )
  return { plotStyleObj: s, plotWidthObj: w, plotHeightObj: h }
}

export const plotCalculatorAsIcon = (): { plotStyleObj: PlotStyleObj, plotWidthObj: PlotWidthObj, plotHeightObj: PlotHeightObj } => {
  const ICON_SIZE = 64
  const plotWidthObj: PlotWidthObj = {
    // from left to right
    viewWidthPx: ICON_SIZE,
    // consisting of:
    focalPlanePx: ICON_SIZE,  // viewWidthPx - leftNavBar - rightSideBar
    leftMarginPlusCenteringGap: 0,
    leftMargin: 0,
    centeringGap: 0,
    reactVisAreaInPx: ICON_SIZE,
    reactVisArea: ICON_SIZE,   // fullPlot <= plotAvailable
    // Consisting of:
    totalLeftAxis_displayPx: 0,
    totalLeftAxis: 0,
    // consisting of:
    leftAxisTitle: 0,
    gap_leftAxisTitle_SubTitle: 0,
    leftAxisSubTitle: 0,
    gap_Titles_TickLabel: 0,
    leftAxisTickLabel: 0,
    plottedData: ICON_SIZE,
    plottedData_displayPx: ICON_SIZE,
    overhangOfLastBottomAxisTickLabel: 0,
    rightMargin: 0,

    // Title Layout
    mainTitle: 0,
    publisherTitle: 0,
    mainTitleLeft: 0,
    publisherTitleLeft: 0,
    // mainTitleWidth:0,
    // publisherTitleWidth:0,

    overhangOfFirstBottomAxisTickLabel: 0,

    // These are NOT used for rendered layout.  But summarize the legend placement
    // for the inverse plotCalculator function.
    legendSeriesKeys: [],
    legendWidths: [],

    // axisTitle and axisSubTitle Offsets with respect to the left edge of leftAxis
    leftOffsetTitle: 0,
    leftOffsetSubTitle: 0,
    // plotTitleWidth:0,  Think this can be deleted JPS Feb,2023

    // zero Axis Line offset (in the x direction) when xAxis 'zero' TickValue falls within the interior of the plottable area
    zeroAxisTranslationIn_X: 0,
    zeroAxisHalfWidthInVisCoords_X: 0,
    legendGapBetweenItems: 0,
    leftAxisTickPadding: 0,    // horizontal distance of label from tick mark
  }

  const plotHeightObj: PlotHeightObj = {

    // From top to bottom
    viewHeightPx: ICON_SIZE,
    focalPlanePx: ICON_SIZE,
    totalTitles: 0,
    topMargin: 0,
    mainTitle: 0,
    publisherTitle: 0,
    gapPublisherLegend: 0,
    centeringGap: 0,
    totalCenteringGapTitles: 0,
    reactVisAreaInPx: ICON_SIZE,
    reactVisArea: ICON_SIZE,
    totalLegend: 0,
    legend: 0,
    gapLegendPlot: 0,
    plottedData_displayPx: ICON_SIZE,
    plottedData: ICON_SIZE,
    totalBottomAxis: 0,
    bottomAxisTickLabel: 0,
    gap_TickLabel_Title: 0,
    bottomAxisTitle: 0,
    gap_bottomAxisTitle_SubTitle: 0,
    bottomAxisSubTitle: 0,
    gap_PostSubTitle: 0,
    bottomMargin: 0,
    totalCenteringGapTitlesLegend_displayPx: 0,

    // zero Axis Line offset (in the y direction) when yAxis 'zero' TickValue falls within the interior of the plottable area
    zeroAxisTranslationIn_Y: 0,
    zeroAxisHalfHeightInVisCoords_Y: 0,

    // Not used for rendering.  But tells the inverse calculator how to determine
    // which legend is clicked.
    legendRowHeight: 0,
    numLegendRows: 0,


    legendGapAboveLine: 0,
    legendLineThickness: 0,
    legendGapLineText: 0,
    legendGapBelowText: 0,
    bottomAxisTickPadding: 0,    // vertical distance of label from tick mark
  }

  const plotStyleObj: PlotStyleObj = {
    fontSizeLegend: 0,
    fontSizeTickLabel: { A: 0, B: 0, C: 0 },
    fontSizeAxisTitle: 0,
    fontSizeAxisSubTitle: 0,
    fontSizeTitle: 0,
    fontSizePublisher: 0,
    fontSizeErrMsg: 0,
    axisTickSizeOuter: 0,
    axisTickSizeInner: 0,
    axisLineThickness: 1,
    plotLineThickness: 1,
    markSizeScale: 0.5,
    gridLineThickness: 0,
    pinnedRowStarSize: 1,
    pinnedRowStarOutlineThickness: 0,
    reactVisScale: 1,
    plotTransform: '',
    plotScaleTransform: '',
    plotOffsetTransform: '',
    displayToPlotsvgTransform: (x1: number, y1: number) => ({ x: x1, y: y1 }),
    plotsvgToDisplayTransform: (x1: number, y1: number) => ({ x: x1, y: y1 }),
  }
  return { plotStyleObj, plotWidthObj, plotHeightObj }

}

/*

  Next function is called from the xy_createPlotXyComputedData.
  IT MAY CALL 'xy_plotCalculator' twice !!!!!
  1st call - Some crude, hopefully similar length or over-estimate of max(tickUserLable length)
          This first call determines (estimates!) the available axis length for
          the orthogonal axis.
  2nd call - Knowing the rough available axis length for each axis, we can pick suitable
           spacing between string labels.  NOTE!  We are skipping most string labels.

  Since we are skipping most string labels, any initial axis length estimate can now
  be refined (completely accurate).  If our original estimate was conservative, we
  can now recover otherwise wasted space.  Hence, we are encouraged to make an
  initial conservative estimate.

  This two pass exercise has NO value for numeric axes.  Because smartAxis can figure
  out all the label lengths in the first pass.  In which case the code below will
  skip the 2nd call to plotCalculator.

  However, is any of basisA, basisB, or basisC is a string axis, the first pass
  should use some quick, conservative estimate of the maxStringLength.  Then (if
  the basis.dataType==='string' )
        1) the prior 1st pass through the plotCalculator gives us a conservative
          estimate of the the axisLenghts.
        2) We recalculate the tickUserStringsNoHTML[ ] array, with the assumption
          that some (most) of the string labels will never be displayed as the
          available axisLenght does not provide sufficient room.
        3) We call plotCalculator a second time, using the set of actually
          rendered tickUserStringsNoHTML[ ].   If first estimate was conservative,
          we will recover some space.  If first estimate was actually 'short'
          then we will lose some space. But we would ned to be 'short' by
          around 15-25% before we would end up with 'overlapping' string labels.

*/


export const plotCalculator_And_StringAxisScalePart2 = (plt: PlotXyComputedData): void => {

  var result = plotCalculator(plt)
  plt.plotStyleObj = result.plotStyleObj
  plt.plotWidthObj = result.plotWidthObj
  plt.plotHeightObj = result.plotHeightObj

  // If both axis are 'number', then one pass through the
  // plotCalculator is sufficient.
  if (plt.basisA.internalDataType === 'number' &&
    plt.basisB.internalDataType === 'number') { return }

  // However, if either basisA or basisB is a string axis, then
  // we made a prior crude conservative assumption about the max String length.
  // And we DID NOT YET calculate the string axis tick marks and labels!
  // So we calc the tick position and labels in the next function calls:
  if (plt.basisA.internalDataType === 'string') {
    smartAxisScale_Part2_StringDataTypesOnly(plt.basisA, plt)
  }
  if (plt.basisB.internalDataType === 'string') {
    smartAxisScale_Part2_StringDataTypesOnly(plt.basisB, plt)
  }

  // Now that we have the correct tickLocations and labels, we re-run the
  // plot calculator.  1st estimate from plotCalculator (above) was 'conservative'.
  // This new estimate will probably recover some otherwise wasted space.
  // PlotCalculator is very quick, so not a huge cost.
  // To understand why 'string axes' cannot be done in one pass through the
  // plot calculator, see my notes in: smartAxisScale_Part2_StringDataTypesOnly()
  result = plotCalculator(plt)
  plt.plotStyleObj = result.plotStyleObj
  plt.plotWidthObj = result.plotWidthObj
  plt.plotHeightObj = result.plotHeightObj
}
