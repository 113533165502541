
import type {
  Axis,
  FilterRule,
  InternalColDataType,
  MarkShape,
  MinorStatePlot,
  Plot,
  PlotColDataType,
  SortBy,
  StringOrderDirection,
  StringOrderTypes,
  TextWithLinks,
} from '../types'
import type { DerivedColAttributes } from '../computedDataTable/getDefaultTableComputedData'
import type { FormatRule, FormattingObj } from '../sharedFunctions/numberFormat'
import type { PlotHeightObj, PlotStyleObj, PlotWidthObj } from './xy_plotCalculator'

import { getFormattingObj } from '../sharedFunctions/numberFormat'
import { getClosestEnumeratedValue, typedKeys } from '../sharedFunctions/utils'
import { plotLayoutConsts } from '../viewPlotXY/plotLayoutConsts'
import { defaultPlotHeightObj, defaultPlotStyleObj, defaultPlotWidthObj } from './xy_plotCalculator'
import { ErrorMsg } from '../viewPlotXY/ComponentXYerrMsg'

export type BasicPlotPoint = {
  type: 'basic'
  A: number
  B: number
}

export type SparsePlotPt = {
  type: 'sparse'
  A: number
  B: number
  Astring: string
  Bstring: string
  rowKey: number
}

export const getDefaultSparsePlotPt = (overrideObj: Partial<SparsePlotPt> = {}): SparsePlotPt => {
  const temp: SparsePlotPt = {
    type: 'sparse',
    A: 0,
    B: 0,
    Astring: '',
    Bstring: '',
    rowKey: -1,
  }
  Object.seal(temp)

  for (const key of typedKeys(temp)) {
    if (overrideObj[key] !== undefined) {
      temp[key] = overrideObj[key]
    }
  }

  return temp
}

export type DensePlotPt = {
  type: 'dense'
  // For plotPts representing seedHisto values
  Aindex: number, // redundant info to the A value (next)
  A: number,
  stackMag: number,         // total stack height including only real data points.
  //stackMag_ interp: number,  // total stack height for interpolated stacks

  // Next three include interpolated points between real data
  // Hence the assumption is 'Missing data between sparse data'
  // with interpolated real values between points.
  // Used for 'Area' plots
  //B_ interp        : number[],
  //B0stacked_ interp: number[],
  //Bstacked_ interp : number[],

  // Next three use 'undefined' points between real data.
  // Hence the assumption is missing data is 'NO DATA'.
  // Hence, only 'real' data points (freq>0) will plot.
  // Interpolated (undefined) pts are ignored by react-vis,
  // and can be considered equivalent to stacking values of 'zero'
  // Used for 'Bar' plots.
  B0: number[],
  B: number[],

  B0stacked: number[],
  Bstacked: number[],

  // Same as above but normalized by the total of the series.
  // Currently ONLY used for freq PDF's, where each series
  // is normalized to 100% area.  Not sure it has any meaning
  // for other sparse smoothed dataPlots.
  B0histoStackedPercent: number[],
  BhistoStackedPercent: number[],

  Bpercent: number[],  // No B0 term as this one is not allowed to stack
  BhistoPercent: number[]

  freq: number[],
  isOutOfRange: boolean[],
  // Next flag is tricky!
  // Does this B value come from interpolation, OR from real filtered series data?
  // Next flag is set to true when:
  //   when B value represents freq && A value is at the 'bars center' position.
  //   when B value is mean, min, max, sum && freq >= 1
  //   when B value is variance && freq >= 2
  // This is used in the getter functions to distinguish between
  // interpolated (no crosshairs) data real plottable data.
  isInterpolatedPt: boolean[],   // These two are inverses;
  isRealValuedPt: boolean[],     // Just added both to make the getter functions more readable.
}


export const getDefaultDensePlotPt = (overrideObj: Partial<DensePlotPt> = {}, numSeries: number = 1): DensePlotPt => {
  const temp: DensePlotPt = {
    type: 'dense',
    Aindex: 0, // redundant info to the A value (next)
    A: 0,
    stackMag: 0,        // total stack height including only real data points.
    //stackMag_ interp: 0,  // total stack height for interpolated stacks

    // Next three include interpolated points between real data
    // Hence the assumption is 'Missing data between sparse data'
    // with interpolated real values between points.
    // Used for 'Area' plots
    // B_interp        : Array(numSeries).fill(0),
    //B0stacked_ interp: Array(numSeries).fill(0),
    //Bstacked_ interp : Array(numSeries).fill(0),

    // Next three use 'undefined' points between real data.
    // Hence the assumption is missing data is 'NO DATA'.
    // Hence, only 'real' data points (freq>0) will plot.
    // Interpolated (undefined) pts are ignored by react-vis,
    // and can be considered equivalent to stacking values of 'zero'
    // Used for 'Bar' plots.
    B: Array(numSeries).fill(0),
    B0: Array(numSeries).fill(0),
    B0stacked: Array(numSeries).fill(0),
    Bstacked: Array(numSeries).fill(0),

    // For histogram Percent plots, where seedHisto is used for string BasisA
    Bpercent: Array(numSeries).fill(0),
    BhistoPercent: Array(numSeries).fill(0),
    BhistoStackedPercent: Array(numSeries).fill(0),
    B0histoStackedPercent: Array(numSeries).fill(0),

    // For histogram Percent Smoothed plots, where seedHisto is used for string BasisA
    //BhistoPercent_ interp        : Array(numSeries).fill(0),
    //BhistoStackedPercent_ interp : Array(numSeries).fill(0),
    //B0histoStackedPercent_ interp: Array(numSeries).fill(0),

    freq: Array(numSeries).fill(0),
    isOutOfRange: Array(numSeries).fill(false),
    isInterpolatedPt: Array(numSeries).fill(false),
    isRealValuedPt: Array(numSeries).fill(true),
  }

  Object.seal(temp)

  for (const key of typedKeys(temp)) {
    if (overrideObj[key] !== undefined) {
      temp[key] = overrideObj[key]
    }
  }

  return temp
}

export type PlotPt = BasicPlotPoint | SparsePlotPt | DensePlotPt

export type LinearFit = {
  intercept_BfA: number
  slope_BfA: number
  intercept_AfB: number
  slope_AfB: number
}

export const getDefaultLinearFit = (): LinearFit => {
  const temp: LinearFit = {
    intercept_BfA: 0,
    slope_BfA: 0,
    intercept_AfB: 0,
    slope_AfB: 0
  }
  return Object.seal(temp)
}

export type FilteredPtsStats = {
  numFilteredPts: number,

  minB: number,
  maxB: number,
  minA: number,
  maxA: number,

  minBculled: number,
  maxBculled: number,
  minAculled: number,
  maxAculled: number,

  sumA: number,
  sumB: number,
  sumAB: number,
  sumAA: number,
  sumBB: number,
  sumlogA: number,
  sumlogB: number,
  sumlogAB: number,
  sumAlogB: number,
  sumlogAlogB: number,
  sumlogAlogA: number,
  sumlogBlogB: number,

  isIntegersInA: boolean,
  isNonnegativeInA: boolean,

  linfit_AB: LinearFit,
  linfit_LogAB: LinearFit,
  linfit_ALogB: LinearFit,
  linfit_LogALogB: LinearFit,

}

export const getDefaultFilteredPtsStats = (overrideObj: Partial<FilteredPtsStats> = {}) => {
  const temp: FilteredPtsStats = {
    numFilteredPts: 0,

    minB: +Infinity,
    maxB: -Infinity,
    minA: +Infinity,
    maxA: -Infinity,

    minBculled: +Infinity,
    maxBculled: -Infinity,
    minAculled: +Infinity,
    maxAculled: -Infinity,

    sumA: 0,
    sumB: 0,
    sumAB: 0,
    sumAA: 0,
    sumBB: 0,
    sumlogA: 0,
    sumlogB: 0,
    sumlogAB: 0,
    sumAlogB: 0,
    sumlogAlogB: 0,
    sumlogAlogA: 0,
    sumlogBlogB: 0,

    isIntegersInA: false,
    isNonnegativeInA: false,

    linfit_AB: getDefaultLinearFit(),
    linfit_LogAB: getDefaultLinearFit(),
    linfit_ALogB: getDefaultLinearFit(),
    linfit_LogALogB: getDefaultLinearFit(),

  }
  Object.seal(temp)

  for (const key of typedKeys(temp)) {
    if (overrideObj[key] !== undefined) {
      temp[key] = overrideObj[key]
    }
  }

  return temp
}


export type GridLinesRender = {
  tickVisValues: number[],
  gridColor: string,
}

// Given an enumerated string axis 'text',
// what is the corresponding coordinate index
// and in the case of >1 plotPt per enumerated string, the statistics?
export type StringEnumerationObject = {
  mappedIndex: number
  mappedText: string

  rowKey: number[][]  // Useful ONLY if isPerfectEnumeration === true

  freq: number[]
  sum: number[]
  mean: number[]
  min: number[]
  max: number[]

  allSeriesFreqMax: number
  allSeriesMeanMax: number
  allSeriesSumMax: number
  allSeriesRowMax: number
  allSeriesFreqMin: number
  allSeriesMeanMin: number
  allSeriesSumMin: number
  allSeriesRowMin: number
  allSeriesFreqStacked: number
  allSeriesMeanStacked: number
  allSeriesSumStacked: number
}

export type StringAxis_TextToIndexObj = {
  [key: string]: StringEnumerationObject
}


// SEED HISTOGRAM CONSTANTS:
const tableExtensionInUnitsOfAxisTickWidth = 3
const arrayBinsPerSmallestBinWidthOption = 4
const backboneNumSets = 7  // backbone indices 0 to n quickly transistion from rawB to tweakedB values.


export type AntiSymmetryPts = {
  x1_index: number,
  y1_val: number,
  x2_index: number,
  y2_val: number,
  slope1_perBin: number,
  slope2_perBin: number
}

export const getDefaultAntiSymmetryPts = (): AntiSymmetryPts => {
  var obj = {
    x1_index: 0,
    y1_val: 0,
    x2_index: 0,
    y2_val: 0,
    slope1_perBin: 1,
    slope2_perBin: 1
  }
  return Object.seal(obj)
}

export type SmoothingTerms = {
  binnedVal: number[]
  linFitBinnedVal: number[]
  linFitAdjBinnedVal: number[]
  backbone: number[][]
  smoothed: number[][]
  antiSymmetryPts: AntiSymmetryPts[]   // One Object (two pts) per smoothing index
}

export const getDefaultSmoothingTerms = (): SmoothingTerms => {
  const obj: SmoothingTerms = {
    binnedVal: [],
    linFitBinnedVal: [],
    linFitAdjBinnedVal: [],
    backbone: Array(backboneNumSets).fill(null),
    smoothed: Array(plotLayoutConsts.numSmoothingKernelSteps).fill(null),
    antiSymmetryPts: Array(plotLayoutConsts.numSmoothingKernelSteps).fill(null)
  }
  return Object.seal(obj)
}

export type SeedHistoPlotPts = {
  densePlotPts: DensePlotPt[] | null,   // NOT by sKey; series arrays are embedding in plotPts
  // All metaData is by sKey:
  isEmptyOverRangeCulled: boolean[],
  minB: number[],
  maxB: number[],
  minBhistoPercent: number[],
  maxBhistoPercent: number[],
  minBstacked: number[],
  maxBstacked: number[],
  minBstacked100Percent: number[],
  maxBstacked100Percent: number[],
  minBhistoStackedPercent: number[],
  maxBhistoStackedPercent: number[],
  // This tags the smoothingIndex that was used for this specific set of points.
  // NOT required when plotPts are created.
  // Added as metaData when the plotPts are memoized.
  // Only purpose is:
  //    This saved set of data was created with smoothing Index 'n'.
  //    If you wish to re-rendered the same data (same smoothingIndex),
  //    then first check that your request matches this value.
  smoothingOrBinWidthIndex: number[],
  totalCountBySeries: number[],
  totalCountForLargestSeries: number,
  minB_sKey0_Normed: number,
  minPositiveB_allSeries: number
}

export const getDefaultPlotPtsInfoObj = (overrideObj: Partial<SeedHistoPlotPts> = {}): SeedHistoPlotPts => {
  const temp: SeedHistoPlotPts = {
    densePlotPts: null,   // NOT by sKey; series arrays are embedding in plotPts
    // All that follow are arrayed by sKey
    isEmptyOverRangeCulled: [],
    minB: [],
    maxB: [],
    minBhistoPercent: [],
    maxBhistoPercent: [],
    minBstacked: [],
    maxBstacked: [],
    minBstacked100Percent: [],
    maxBstacked100Percent: [],
    minBhistoStackedPercent: [],
    maxBhistoStackedPercent: [],
    smoothingOrBinWidthIndex: [],
    totalCountBySeries: [],
    totalCountForLargestSeries: 0,
    minB_sKey0_Normed: 1,
    minPositiveB_allSeries: 0,
  }
  Object.seal(temp)

  for (const key of typedKeys(temp)) {
    if (overrideObj[key] !== undefined) {
      temp[key] = overrideObj[key]
    }
  }

  return temp
}

export const validDataNameRoot = ['freq', 'mean', 'variance', 'meanLogB', 'varianceLogB', 'sum', 'min', 'max'] as const
export type DataNameRoot = typeof validDataNameRoot[number]
export const validDataName = [...validDataNameRoot, 'smth_freq', 'smth_mean', 'smth_variance', 'smth_meanLogB',
  'smth_varianceLogB', 'smth_sum', 'smth_min', 'smth_max'] as const
export type DataName = typeof validDataName[number]
export type PlotPtsByDataName = {
  [key in DataName]: SeedHistoPlotPts | null
}

export const extraPlottedValues = ['tableRow', 'percentile', 'linFit', 'pinnedRow'] as const
export const validPlottedValueRoots = [...validDataNameRoot, ...extraPlottedValues] as const
export type PlottedValueRoot = typeof validPlottedValueRoots[number]
export const validPlottedValues = [...validDataName, ...extraPlottedValues] as const
export type PlottedValue = typeof validPlottedValues[number]

export const validDistributionPlottedValues: PlottedValue[] = ['freq', 'percentile', 'smth_freq']

export const validVisTypeUser = ['marks', 'line', 'marksLine', 'bars', 'area',
  'stackedBars', 'stackedArea', 'stackedBars100%', 'stackedArea100%'] as const
export type VisTypeUser = typeof validVisTypeUser[number]


export const getDefaultPlotPtsByDataName = (): PlotPtsByDataName => {
  const obj: PlotPtsByDataName = {
    freq: null,
    mean: null,
    variance: null,
    meanLogB: null,
    varianceLogB: null,
    sum: null,
    min: null,
    max: null,

    smth_freq: null,
    smth_mean: null,
    smth_variance: null,
    smth_meanLogB: null,
    smth_varianceLogB: null,
    smth_sum: null,
    smth_min: null,
    smth_max: null
  }
  return Object.seal(obj)
}


export type SeedHistoData = {  // One of these objects per sKey
  Aindex_FirstCount: number,   // first freq >= 1
  Aindex_FinalCount: number,
  Aindex_First2Count: number,   // first freq >= 2 - Used for range of valid variance
  Aindex_Final2Count: number,

  Aindex_FirstPDFzero: number,  // edges of smallest legal histo bin containing firstCount
  Aindex_FinalPDFzero: number,  // These is the location where smooth PDF is forced to 'zero'

  Aindex_FirstHistoCenter: number,  // For classic (numeric) Histogram.  Center coord for first nonZero set of bars.
  Aindex_FinalHistoCenter: number,  // Used for extending the basisA axis domain, when neccessary

  isEmptySeries: boolean,           // no filtered data
  isEmptySeedHistoFreqBinning: boolean, // all seedHisto bins have freq === 0 or null (excluding first/last bins)
  seriesLineSmoothing: number,

  // Stats needed to create binnedVals any of available SmoothedTerms
  stats_sumB: number[]
  stats_sumBB: number[]
  stats_sumLogB: number[]
  stats_sumLogBLogB: number[]
  stats_minB: number[]
  stats_maxB: number[]
  stats_numSamplesPerBinCumulative: number[]
  stats_numSamplesPerBinInterpolated: number[]
  stats_minValAnyBin: number,
  stats_maxValAnyBin: number,

  freq: SmoothingTerms, // Sum( freqStrgA, freqStrgB, ... ) / numSamplesPerBin
  // Freq is a average! May be a fractional value.  (in case of dense string axis)

  mean: SmoothingTerms,
  variance: SmoothingTerms,
  meanLogB: SmoothingTerms,
  varianceLogB: SmoothingTerms,

  sum: SmoothingTerms,
  min: SmoothingTerms,
  max: SmoothingTerms,
}

export const getDefaultSeedHistoData = (): SeedHistoData => {
  const obj: SeedHistoData = {
    Aindex_FirstCount: 0,   // first freq >= 1
    Aindex_FinalCount: 0,
    Aindex_First2Count: 0,   // first freq >= 2 - Used for range of valid variance
    Aindex_Final2Count: 0,

    Aindex_FirstPDFzero: 0,  // edges of smallest legal histo bin containing firstCount
    Aindex_FinalPDFzero: 0,  // These is the location where smooth PDF is forced to 'zero'

    Aindex_FirstHistoCenter: 0,  // For classic (numeric) Histogram.  Center coord for first nonZero set of bars.
    Aindex_FinalHistoCenter: 0,

    isEmptySeries: false,
    isEmptySeedHistoFreqBinning: false,
    seriesLineSmoothing: 0,

    stats_sumB: [],
    stats_sumBB: [],
    stats_sumLogB: [],
    stats_sumLogBLogB: [],
    stats_minB: [],
    stats_maxB: [],
    stats_numSamplesPerBinCumulative: [],
    stats_numSamplesPerBinInterpolated: [],
    stats_minValAnyBin: +Infinity,
    stats_maxValAnyBin: -Infinity,

    freq: getDefaultSmoothingTerms(),
    mean: getDefaultSmoothingTerms(),
    variance: getDefaultSmoothingTerms(),
    meanLogB: getDefaultSmoothingTerms(),
    varianceLogB: getDefaultSmoothingTerms(),

    sum: getDefaultSmoothingTerms(),
    min: getDefaultSmoothingTerms(),
    max: getDefaultSmoothingTerms(),
  }
  return Object.seal(obj)
}



export type SeedHisto = {
  // All 'binWidths' are in the units of the axis (as seen by users in linear axis)
  // OR the units of the Log10(axis) when using a logarithmic scale.
  // The ratios of fullWidth, tickWidth, binWidth are always 'integer multiples'.
  arrayBinWidth: number,   // The highRes, internal binWidth of the table.
  axisTickWidth: number,   // What we see in linear plots - the distance between tick labels
  axisFullWidth: number,   // An integer multiple of axisTickWidth (typically 6 to 10)
  binWidthOptions: number[],
  binWidthLabels: string[],     // labels for linear axis scale - BinWidths
  binWidthLabelsLog: string[],  // labels for log10  axis scale - Bins per decade
  legalBinWidthIndex: number,
  arrayBinsPerAxisTick: number, // Integer ratio of axisTickWidth and arrayBinWidth
  arrayBinsPerUserBinWidth: number,
  arrayBinsPerPDFbinWidth: number,
  arrayBinsPerMinIntervalInA: number, //minimum spatial separation between real data (bin Freq > 0)
  smoothingKernelWidthLabels: number[],
  seedHistoLength: number,

  willUseLogarithmicSeedHistoBins: boolean,
  willUseLogarithmicB: boolean,
  isBasisAdataTypeNumber: boolean,
  isBasisAdataTypeString: boolean,

  // These values map a unitless seedHistogram axis
  // to the corresponding basisA values.
  Avalue_FirstAxisTick: number,
  Avalue_LastAxisTick: number,
  Aindex_FirstAxisTick: number,
  Aindex_LastAxisTick: number,
  // Typically same as above two values, except when the user has
  // constrained the plot width.
  Avalue_LeftPlotEdge: number,
  Avalue_RightPlotEdge: number,
  Aindex_LeftPlotEdge: number,
  Aindex_RightPlotEdge: number,

  Avalue_FirstHistoCenter: number,
  Avalue_FinalHistoCenter: number,

  tableExtensionInUnitsOfAxisTickWidth: number,  // design constant
  arrayBinsPerSmallestBinWidthOption: number,    // design constant
  backboneNumSets: number,                       // design constant
  DEBUG: boolean,

  data: SeedHistoData[]  // arrayed by sKey
  plotPtsByDataName: PlotPtsByDataName, // DataName: freq, mean, ... , smth_max, smth_min .
}

export const getDefaultSeedHisto = (): SeedHisto => {
  const obj: SeedHisto = {
    arrayBinWidth: 1,
    axisTickWidth: 1,
    axisFullWidth: 1,
    binWidthOptions: [],
    binWidthLabels: [],
    binWidthLabelsLog: [],
    legalBinWidthIndex: 0,   // index into user bin width legal options.
    arrayBinsPerAxisTick: 1,
    arrayBinsPerUserBinWidth: 1,
    arrayBinsPerPDFbinWidth: 1,
    arrayBinsPerMinIntervalInA: 1,
    smoothingKernelWidthLabels: [],
    seedHistoLength: 0,

    willUseLogarithmicSeedHistoBins: false,
    willUseLogarithmicB: false,
    isBasisAdataTypeNumber: true,
    isBasisAdataTypeString: false,

    Avalue_FirstAxisTick: 0,
    Avalue_LastAxisTick: 0,
    Avalue_LeftPlotEdge: 0,
    Avalue_RightPlotEdge: 0,
    Aindex_FirstAxisTick: 0,
    Aindex_LastAxisTick: 0,
    Aindex_LeftPlotEdge: 0,
    Aindex_RightPlotEdge: 0,
    Avalue_FirstHistoCenter: 0,
    Avalue_FinalHistoCenter: 0,

    tableExtensionInUnitsOfAxisTickWidth,  // design constant
    arrayBinsPerSmallestBinWidthOption,    // design constant
    backboneNumSets,                       // design constant
    DEBUG: false,
    data: [],
    plotPtsByDataName: getDefaultPlotPtsByDataName()
  }
  Object.seal(obj)
  return obj
}

export type BasisPath = 'basisA' | 'basisB' | 'basisC'
export type BasisName = 'A' | 'B' | 'C'
export type AxisPath = 'bottomAxis' | 'leftAxis' | 'topAxis' | 'rightAxis'
export type AxisName = 'Bottom' | 'Left' | 'Right' | 'Top'

export type BasisNameToBasisPath = {
  [key in BasisName]: BasisPath
}

export const baseNameToBasisPathMap: BasisNameToBasisPath = {
  A: 'basisA',
  B: 'basisB',
  C: 'basisC'
}

export type PlotRange = {
  min: number
  max: number
}

export type PlotXyComputedAxis = {

  // These values from the user's plot resource:
  isLogarithmic: boolean,              // current user's plot resource state.
  isPercentileNormalProb: boolean,
  isHistogramPercent: boolean,       // is histogram in untils of count or percent of count?

  fontScaleTickValues: number,   // Multiplier in range ~ 0.5 to 1.3
  labelAngle: number,
  axisTitle: string,
  axisSubTitle: string,
  axisTitleShort?: string,   // What is displayed for Crosshairs.
  usersMinDomain: string,
  usersMaxDomain: string,
  isMinDomainAuto: boolean,
  isMaxDomainAuto: boolean,

  doesRangeFilteredIncludeZero: boolean,  // ( rangeFiltered.min * rangeFiltered.max <= 0 )
  legalZeroValue_preLog: number | null, // bars and area need a 'zero' value at base of logarithmic scales.
  willUseLogarithmicScale: boolean,    // will we honor user's isLogarithmic request?
  willUseLogWithNegativeData: boolean, // Request by user for log scale,
  // && we will honor their request,
  // && filtered rangeFiltered < 0.


  // Error checked versions of above
  // legalIsMinConstraintAuto : boolean,
  // legalIsMaxConstraintAuto : boolean,
  // legalUsersMinConstraint  : number,
  // legalUsersMaxConstraint  : number,

  // Simply the inverse of legalIsMinDomainAuto & legalIsMaxDomainAuto
  // Redundant, but puts the resource attributes into the language used
  // throughout plotXyComputedData code
  isMinForced: boolean,
  isMaxForced: boolean,
  isInvertedAxis: boolean,

  rangeFilteredAllSeries: PlotRange
  rangeCulledAllSeries: PlotRange
  rangeRenderedAllSeries: PlotRange
  rangeSmartAxisAllSeries: PlotRange
  rangeUnconstrainedSmartAxis: PlotRange

  domainUnconstrained: [number, number],   // Values determined by smart axis from raw range of plotted data.
  domainConstrained: [number, number],     // POTENTIALLY tighter axis limits when axis isForced
  domainExtended: [number, number],  // POTENTIALLY extended domain to accomodate cosmetic reasons.
  // These are the values passed to React Component_XY

  stringOrder: StringOrderTypes,
  stringOrderDirection: StringOrderDirection,
  stringTextToIndexObj: StringAxis_TextToIndexObj | null,
  stringTextToIndex_sortedKeys: string[] | null,
  isPerfectStringEnumeration: boolean,

  internalDataType: InternalColDataType,
  tickFormatRule: FormatRule,
  tickFormatSuffixStrg: string
  tickFormattingObj: FormattingObj

  tickVisValues: number[],     // Vis coord system  (for exponential, tickVisValues = Math.log10( tickUserValues )
  tickVisValuesLight: number[] | null,
  tickUserValues: number[],    // User coord system (for exponential, tickUserValues = Math.pow( 10, tickVisValues )
  tickUserStringsNoHTML: string[],
  tickUserStringsMeasureOnly: string[],
  labelsPerDecade: number,  // Formatting of plot axis ticks varies depending on whether this value is 1 or not 1.

  basisPath: BasisPath,    //  'basisA', 'basisB', 'basisC'
  basisName: BasisName,    //  'A', 'B', 'C'
  axisPath: AxisPath,      //  'bottomAxis', 'leftAxis', 'topAxis', 'rightAxis'
  axisName: AxisName,      //  'Bottom', 'Left', 'Right', 'Top'  -- Only five currently in use.

  mousePxToPlotDomainValue: (px: number) => number,
  mousePxToPlotTickValue: (px: number) => number,
  domainValueToMousePx: (val: number) => number,

  multiAxisXform: (val: number) => number,

  nonlinearXform: (val: any) => any,
  reverseNonlinearXform: (val: any) => any,

  //getSparseVal : (Object, number) => number,
  //getVal  : (Object, number) => number,
  //getVal0 : (Object, number) => number,

  // Used for ALL families
  seedHisto: SeedHisto | null,

  legalUsersMinDomain: number,
  legalUsersMaxDomain: number,
}


export const getDefaultPlotXyComputedAxis = (inAxis: Axis): PlotXyComputedAxis => {
  const outAxis: PlotXyComputedAxis = {
    axisTitle: inAxis.axisTitle,
    axisSubTitle: inAxis.axisSubTitle || '',
    labelAngle: inAxis.labelAngle,
    fontScaleTickValues: inAxis.fontScaleTickValues,

    isMinDomainAuto: inAxis.isMinDomainAuto,
    isMaxDomainAuto: inAxis.isMaxDomainAuto,
    usersMinDomain: inAxis.usersMinDomain,
    usersMaxDomain: inAxis.usersMaxDomain,

    isLogarithmic: inAxis.isLogarithmic,
    isPercentileNormalProb: inAxis.isPercentileNormalProb,
    isHistogramPercent: inAxis.isHistogramPercent,

    axisTitleShort: undefined,
    internalDataType: 'number',
    tickFormatRule: 'defaultEng',
    tickFormatSuffixStrg: '',

    willUseLogarithmicScale: false,
    legalZeroValue_preLog: null,     // Assumption: linear plot, where nothing special needed for 'zero'.
    willUseLogWithNegativeData: false,
    doesRangeFilteredIncludeZero: true,

    // This next value is set by the smartAxisScale( ) function
    // It depends on the tickFormatRule (above) but as a general
    // rule smartAxisScale() has complete control over the tick formattting.
    // smartAxisScale will set this value, however it is an
    tickFormattingObj: getFormattingObj('defaultEng'),

    tickVisValues: [],
    tickUserValues: [],
    tickUserStringsNoHTML: [],
    tickUserStringsMeasureOnly: [],
    tickVisValuesLight: null,
    labelsPerDecade: -1,

    mousePxToPlotDomainValue: (x) => x,
    mousePxToPlotTickValue: (x) => x,
    domainValueToMousePx: (x) => x,

    multiAxisXform: (x) => x,

    nonlinearXform: (x) => x,
    reverseNonlinearXform: (x) => x,

    // All five 'ranges' correspond to some group statistic over 'table values'
    // 'ranges' are always in units of the the corresponding table values.
    // In constrast to 'domains' which MAY be Log10(range).
    // 'domains' correspond to react-vis domain.
    rangeFilteredAllSeries: { min: +Infinity, max: -Infinity },
    rangeCulledAllSeries: { min: +Infinity, max: -Infinity },  // set for by forced constraints, may limit OR expand range.
    rangeRenderedAllSeries: { min: +Infinity, max: -Infinity },  // Only used for basisB; Can vary significantly from rangeCulled

    // These two ranges returned from 'smartAxis()'
    // When no constraints, two ranges are equal.
    rangeSmartAxisAllSeries: { min: +Infinity, max: -Infinity },  // What range is actually rendered.
    rangeUnconstrainedSmartAxis: { min: +Infinity, max: -Infinity },  // The 'pretty tick mark' range. If constrained axis,
    // this range always >= constrained range, and is equal
    // to the next 'pretty range' >= to the constrained range.

    // rangeUnconstrainedSmartAxis is defined as:
    // 'Nice Tick Marks' range that MAY be wider than plotted axis range.
    // For example, when range is constrained by user:
    //    {min: 2.3, max: 6.7}  -> Used by reactVis for rendering the axis.
    // then rangeUnconstrainedSmartAxis will be:
    //    {min: 2.0, max: 7.0}  -> Used by seedHisto for data binning.
    //                             Guarantees 'nicely snapped' A-coord for crosshairs.
    // rangeUnconstrainedSmartAxis is always equal to or 'wider' than the react domain.

    // For ranges, min value is always left of max 'On The Number Line'.
    // If the user wants to 'invert' the axis such that the min value
    // is plotted on right side of plot, then next flag is set.
    // domain[0] and domain[1] alwaYS refer to left/right "On The Plot Axis' (Not the same!)
    // Hence min/max will always correspond to working with table values.
    // domain[0] and domain[1] always refer to the plotted axis tick ordering.

    domainUnconstrained: [0, 1], // What we tell reactVis for rendering.
    // The 'pretty' tick delimited axis found by SmartAxis.
    // Usually identical to domainConstrained
    // However, domainConstrained by be 'narrowed' when
    // the user 'forces' the axis extents.
    domainConstrained: [0, 1], // What we tell reactVis for rendering.
    // Usually maps to table values.
    // But may map to nonLinearXform(table Values)
    // e.g, table value '100' = domain value '2' for log scale.
    domainExtended: [0, 1], // Domain passed to react ComponentXY. Extended to account for
    // cosmetic issues such as:
    //   1. bar widths that slop over the plot boundary
    //   2. string axis ticks too near or on top of plot boundary
    //   3. None other at this time, but strictly cosmetic and
    //      does not effect plotted values and axis tick marks.
    //      OK to add cosmetic improvements where desired.
    //      Don't use the Math!  Try to keep the mathmatical
    //      calculations free of cosmetic issues.
    isInvertedAxis: false,

    axisPath: 'bottomAxis',
    axisName: 'Bottom',
    basisPath: 'basisA',
    basisName: 'A',

    stringOrder: inAxis.stringOrder,
    stringOrderDirection: inAxis.stringOrderDirection,
    stringTextToIndexObj: null,
    stringTextToIndex_sortedKeys: null,
    isPerfectStringEnumeration: false,

    seedHisto: null,

    legalUsersMinDomain: 0,
    legalUsersMaxDomain: 0,
    isMinForced: false,
    isMaxForced: false,
  }

  Object.seal(outAxis)
  outAxis.labelAngle = getClosestEnumeratedValue(outAxis.labelAngle, plotLayoutConsts.xTickAngleValues)
  return (outAxis)
}



export type PlotXyComputedSeriesAttributes = {
  // Potential series specific errors that prevent any visible rendered pts/lines.
  isNoData: boolean | undefined,
  isOutOfView: boolean | undefined,

  isDataTypeMismatchA: boolean,
  isDataTypeMismatchB: boolean,
  isDataTypeMismatchSortByCol: boolean,
  isDataTypeMismatch: boolean,

  isDeletedTableColKeyA: boolean,
  isDeletedTableColKeyB: boolean,
  isDeletedTableSortByColKey: boolean,
  isDeletedCol: boolean,

  isUnsetSeriesColKeyA: boolean,
  isUnsetSeriesColKeyB: boolean,
  isUnsetSeriesSortByColKey: boolean,
  isUnsetSeriesColKey: boolean,

  colKeyA: number,
  colKeyB: number,
  colKeyC: number,
  formatRuleA: FormatRule,
  formatRuleB: FormatRule,
  formatRuleC: FormatRule,
  formattingObjA: FormattingObj,
  formattingObjB: FormattingObj,
  formattingObjC: FormattingObj,
  internalDataTypeA: InternalColDataType,
  internalDataTypeB: InternalColDataType,
  internalDataTypeC: InternalColDataType,

  seriesTitle: string,
  seriesDescription: TextWithLinks,
  seriesFilter: FilterRule[],
  errMsg: string,

  color: string,
  colorTitle: string,
  markSize: number,
  markShape: MarkShape,

  seriesOpacity: number,
  seriesLineSmoothing: number,
  seriesSamplingDensity: number,
  numRandomSampledFamilies: number,

  sortBy: SortBy,
  sortByColKey: number,
  filteredPtsStats: FilteredPtsStats,
  filteredSeriesRowKeys: number[],
  numPoints: number,
  numCulledPoints: number,
}

export const getDefaultPlotXyComputedSeriesAttributes = (): PlotXyComputedSeriesAttributes => {
  const obj: PlotXyComputedSeriesAttributes = {
    // Potential series specific errors that prevent any visible rendered pts/lines.
    isNoData: false,
    isOutOfView: false,

    isDataTypeMismatchA: false,
    isDataTypeMismatchB: false,
    isDataTypeMismatchSortByCol: false,
    isDataTypeMismatch: false,

    isDeletedTableColKeyA: false,
    isDeletedTableColKeyB: false,
    isDeletedTableSortByColKey: false,
    isDeletedCol: false,  // If any of prior 3 values are true

    isUnsetSeriesColKeyA: true,
    isUnsetSeriesColKeyB: true,
    isUnsetSeriesSortByColKey: true,
    isUnsetSeriesColKey: true,  // If any of prior 3 values are true

    colKeyA: -1,
    colKeyB: -1,
    colKeyC: -1,
    formatRuleA: 'defaultString',
    formatRuleB: 'defaultString',
    formatRuleC: 'defaultString',
    formattingObjA: getFormattingObj('defaultString'),
    formattingObjB: getFormattingObj('defaultString'),
    formattingObjC: getFormattingObj('defaultString'),
    internalDataTypeA: 'string',
    internalDataTypeB: 'string',
    internalDataTypeC: 'string',
    // derivedFilterRules: new Array(0),

    seriesTitle: 'unset',
    seriesDescription: {
      text: '',
      links: [],
    },
    seriesFilter: [],
    errMsg: '',
    color: '#000000',
    colorTitle: 'black',
    markSize: 1,
    markShape: 'square',

    seriesOpacity: 1,
    seriesLineSmoothing: plotLayoutConsts.seriesLineSmoothingForNewPlots,
    seriesSamplingDensity: 1,
    numRandomSampledFamilies: 1,

    sortBy: 'unset',
    sortByColKey: -1,
    filteredPtsStats: getDefaultFilteredPtsStats(),
    filteredSeriesRowKeys: new Array(0),
    numPoints: 0,
    numCulledPoints: 0,
  }
  Object.seal(obj)
  return obj
}

export type ReactVisType = 'AreaSeries' | 'AreaHorizontalSeries' | 'LineMarkSeries' | 'LineSeries' | 'MarkSeries' | 'RectScryBottomA' | 'RectScryLeftA'

export type CanvasLayeringSet = 'bottom' | 'rows' | 'statLines' | 'linFit' | 'smthFit' | 'pinnedRows'

// Whatever we want to 'tell' crosshairs about the nature
// of this layer of RenderedData:

export type ReactLayerBase = {
  description: string,
  plotColDataType: PlotColDataType,
  renderedLayerID: string,
  canvasLayeringSet: CanvasLayeringSet,
  sKey: number,
  sOrderIndex: number,
  Bscale: number,

  isSmoothed: boolean,
  isRendered: boolean,   // This layer is passed to reactVis
  hasCrosshairs: boolean,// These layers are passed to Delaunay/crosshairs modules.
  layeringOrder: number, // Used for breaking ties in rendered sorting algorithm.
  visTypeUser: string,
  visTypeReact: ReactVisType,
  plotPtAttributeB0: string,
  plotPtAttributeB: string,

  markShape: MarkShape,
  markSize: number,
  color: string,
  canvasOpacity: number,   // All rendered layers on any given canvas should have same value.
  seriesOpacity: number,
  lineThickness: number,

  minB: number,
  maxB: number,

  getA0: (x: PlotPt, d: number) => any,
  getA: (x: PlotPt, d: number) => any,
  getB0: (x: PlotPt, d: number) => any,
  getB: (x: PlotPt, d: number) => any,

  getLeft0: (x: PlotPt, d: number) => any,
  getLeft: (x: PlotPt, d: number) => any,
  getBottom0: (x: PlotPt, d: number) => any,
  getBottom: (x: PlotPt, d: number) => any,

  leftPlotPtToMousePx: (x: PlotPt, sKey: number) => any,
  bottomPlotPtToMousePx: (x: PlotPt, sKey: number) => any,
}

export type ReactLayerBasic = ReactLayerBase & {
  plottedValue: 'percentile' | 'linFit'
  plotPts: BasicPlotPoint[]
}

export type ReactLayerSparse = ReactLayerBase & {
  plottedValue: 'tableRow' | 'pinnedRow'
  plotPts: SparsePlotPt[]
}

export type ReactLayerDense = ReactLayerBase & {
  plottedValue: DataName
  plotPts: DensePlotPt[]
}

export type ReactLayer = ReactLayerBasic | ReactLayerSparse | ReactLayerDense


export const getDefaultReactLayer = (plottedValue: PlottedValue, overrideObj: Partial<ReactLayer>): ReactLayer => {
  const temp: ReactLayer = {
    plottedValue,
    description: '',
    plotColDataType: '2Col',
    renderedLayerID: '',
    canvasLayeringSet: 'rows',
    sKey: 0,
    sOrderIndex: 0,

    layeringOrder: 0,
    isRendered: true,
    hasCrosshairs: true,
    Bscale: 1,
    isSmoothed: false,
    visTypeUser: '',
    visTypeReact: 'LineSeries',
    plotPtAttributeB0: '',
    plotPtAttributeB: '',

    markShape: 'square',
    markSize: 1,
    color: 'black',
    canvasOpacity: 1,
    seriesOpacity: 1,
    lineThickness: 1,

    minB: +Infinity,
    maxB: -Infinity,

    getA0: (x: any) => (x),
    getA: (x: any) => (x),
    getB0: (x: any) => (x),
    getB: (x: any) => (x),

    getLeft0: (x: any) => (x),
    getLeft: (x: any) => (x),
    getBottom0: (x: any) => (x),
    getBottom: (x: any) => (x),

    leftPlotPtToMousePx: (x: any) => (x),
    bottomPlotPtToMousePx: (x: any) => (x),

    plotPts: [],
  }
  Object.seal(temp)

  // Here is where we copy the 'over-rides' provided by parent
  for (const key of typedKeys(temp)) {
    if (overrideObj[key] !== undefined) {
      temp[key] = overrideObj[key]
    }
  }

  return temp
}

export const basisKeys = ['basisA', 'basisB', 'basisC'] as const
export type BasisKeys = typeof basisKeys[number]

export type PlotXyComputedData = {
  plotid: string,
  tableid: string,
  tablelookid: string,
  plotResourceObj: Plot, // This is the user's resource
  // We only reference this when necessary.
  // Specifically at this time: cloning for adding a new plot series.
  canEdit: boolean,

  DEBUG: boolean,
  focalPlaneWidthPx: number,
  focalPlaneHeightPx: number,
  plotWidthObj: PlotWidthObj,
  plotHeightObj: PlotHeightObj,
  plotStyleObj: PlotStyleObj,

  // Useful bools to make code more readable.
  isStacked_anyLayer: boolean,  // Stacking is a renderedLayerID attribute!
  // This says at least one renderedLayerID is stacked
  isStacked100Percent_allLayers: boolean, // Stacking100% is a plot level attribute!
  // This says use a custom axis.  Any renderedLayerID
  // not representable on the custom axis should NOT be
  // plotted (e.g. row data)! 
  isPercentile: boolean,
  isHisto: boolean,   // freq requested (any visType); Does NOT include PDF plots

  // over ANY one out of all series keys'
  isSumPlotted: boolean,   // sum  OR smth_sum
  isMeanPlotted: boolean,   // mean OR smth_mean
  isRowsPlotted: boolean,

  // Must be true over ALL series keys
  isIntegersInA: boolean,
  isNonnegativeInA: boolean,
  isSeriesDisjointInA: boolean,
  isPerfectlyEnumeratedInA: boolean,

  isUsingBasisC: boolean,
  isTransposed: boolean,
  isMirrored: boolean,
  minB_sKey0_Normed: number,

  basisA: PlotXyComputedAxis,
  basisB: PlotXyComputedAxis,
  basisC: PlotXyComputedAxis,
  bottomAxis: PlotXyComputedAxis,
  leftAxis: PlotXyComputedAxis,
  topAxis: PlotXyComputedAxis | null,
  rightAxis: PlotXyComputedAxis | null,

  derivedColAttributesArray: DerivedColAttributes[]
  minorState: MinorStatePlot,

  seriesOrder: number[],
  seriesAttributesArray: PlotXyComputedSeriesAttributes[],

  commonSeriesFilter: FilterRule[],
  seriesFilteredRowKeys: number[][]

  seriesRowPlotPts: SparsePlotPt[][],
  seriesRowPlotPts_sortedInA: SparsePlotPt[][],
  seriesRowPlotPts_culled: SparsePlotPt[][],
  seriesRowPlotPts_stats: FilteredPtsStats[],
  numCulledRowPts: number,

  plotColDataType: PlotColDataType,
  renderedLayersArr: string[],   // Direct copy from plot resource.
  canvasLayersArray: ReactLayer[][],
  delaunayLayersArray: ReactLayer[],

  pinnedRowKeys: number[],   // pinnedRowsKeys[rowKey0, rowKey1, rowKey2, ... ]

  aspectRatio: number,
  fontScaleTitle: number,
  fontScalePublisher: number,
  fontScaleLegend: number,
  fontScaleAxisNames: number,

  mainTitle: string,
  publisherTitle: string,
  mainTitleDisplayed: string,
  publisherTitleDisplayed: string,
  plotDescription: TextWithLinks,
  histogramBinIndex: number,
  bottomCanvasOpacity: number,

  userDataBaseKeyCols: number[],
  userDataBaseKeyColTitles: string[],

  fullPlotLevelErrMessage: ErrorMsg[],
  numRowsUnfiltered: number,

  horizontalGridLines: GridLinesRender,
  horizontalGridLinesLight: GridLinesRender | null,
  verticalGridLines: GridLinesRender,
  verticalGridLinesLight: GridLinesRender | null,
}


export const getDefaultPlotXyComputedData = (plot: Plot): PlotXyComputedData => {

  const plotXyComputedData: PlotXyComputedData = {
    plotid: plot.id,
    tableid: '',
    tablelookid: '',
    plotResourceObj: plot, // This is the user's resource
    // We only reference this when necessary.
    // Specifically at this time: cloning are adding a new plot series.
    canEdit: false,

    focalPlaneWidthPx: 0,
    focalPlaneHeightPx: 0,
    plotWidthObj: defaultPlotWidthObj,
    plotHeightObj: defaultPlotHeightObj,
    plotStyleObj: defaultPlotStyleObj,

    derivedColAttributesArray: new Array(0),
    minorState: plot.attributes.minorState,

    seriesOrder: plot.attributes.seriesOrder,
    seriesAttributesArray: new Array(0),

    commonSeriesFilter: new Array(0),
    seriesFilteredRowKeys: new Array(0),
    seriesRowPlotPts: new Array(0),
    seriesRowPlotPts_sortedInA: new Array(0),
    seriesRowPlotPts_stats: new Array(0),
    seriesRowPlotPts_culled: new Array(0),
    numCulledRowPts: 0,
    pinnedRowKeys: Array<number>(),

    plotColDataType: plot.attributes.plotColDataType,
    renderedLayersArr: plot.attributes.renderedLayersArr.slice(),
    canvasLayersArray: new Array(0),
    delaunayLayersArray: new Array(0),
    // delaunayD3structures: new Array(0),

    // Useful bools to make code more readable.
    isStacked_anyLayer: false,
    isStacked100Percent_allLayers: false,
    // isHistogramPercent : false,
    isPercentile: false,
    isHisto: false,   // true for histograms in both counts or percentages
    isSumPlotted: false,
    isMeanPlotted: false,
    isRowsPlotted: false,
    isSeriesDisjointInA: false,
    isPerfectlyEnumeratedInA: false,
    isIntegersInA: false,
    isNonnegativeInA: false,
    minB_sKey0_Normed: 1,

    aspectRatio: 1,
    fontScaleTitle: plot.attributes.fontScaleTitle,
    fontScalePublisher: plot.attributes.fontScalePublisher,
    fontScaleLegend: plot.attributes.fontScaleLegend,
    fontScaleAxisNames: plot.attributes.fontScaleAxisNames,

    mainTitle: plot.attributes.mainTitle,
    publisherTitle: plot.attributes.publisherTitle,
    mainTitleDisplayed: 'noTitle',
    publisherTitleDisplayed: 'noTitle',
    plotDescription: plot.attributes.plotDescription,
    histogramBinIndex: plot.attributes.histogramBinIndex,
    bottomCanvasOpacity: plot.attributes.bottomCanvasOpacity,

    userDataBaseKeyCols: new Array(0),
    userDataBaseKeyColTitles: new Array(0),

    fullPlotLevelErrMessage: new Array(0),
    numRowsUnfiltered: 0,
    DEBUG: false,
    //isSideBarVisible: false,

    horizontalGridLines: {
      tickVisValues: new Array(0),
      gridColor: plotLayoutConsts.nominalGridColor
    },
    horizontalGridLinesLight: null,
    verticalGridLines: {
      tickVisValues: new Array(0),
      gridColor: plotLayoutConsts.nominalGridColor
    },
    verticalGridLinesLight: null,

    isUsingBasisC: plot.attributes.isUsingBasisC,
    isTransposed: plot.attributes.isTransposed,
    isMirrored: plot.attributes.isMirrored,

    basisA: getDefaultPlotXyComputedAxis(plot.attributes.basisA),
    basisB: getDefaultPlotXyComputedAxis(plot.attributes.basisB),
    basisC: getDefaultPlotXyComputedAxis(plot.attributes.basisC),
    bottomAxis: getDefaultPlotXyComputedAxis(plot.attributes.basisA),
    leftAxis: getDefaultPlotXyComputedAxis(plot.attributes.basisB),
    topAxis: null,
    rightAxis: null,
  }
  Object.seal(plotXyComputedData)
  return plotXyComputedData
}

export type ParsedLayerId = {
  isSmoothed: boolean
  plottedValue: PlottedValue
  plottedValueRoot: PlottedValueRoot
  visTypeUser: VisTypeUser
  sKeyArr: number[]
  sKeyArrAsString: string
}
