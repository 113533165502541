import {list} from 'radash'
import {Component} from 'react'
import type {Tabledata, Tablelook, Table} from '../types'
import type {TableComputedData}  from '../computedDataTable/getDefaultTableComputedData'
import {ColumnData}     from './ColumnData'
import ColumnTitle      from './ColumnTitle'
import {ColumnControls} from './ColumnControls'


type Props = {
  table: Table,
  tablelook: Tablelook,
  tabledata: Tabledata,
  tableComputedData: TableComputedData,
  startColIndex: number,
  stopColIndex: number,
}
class ColumnHeaders extends Component<Props> {
  render( ) {
    const { table, tablelook, tableComputedData, startColIndex, stopColIndex } = this.props
    const { canEdit, priorHidden_ByColIndex, pinnedRowKeys, duplicateRowNames,
            sortedRowKeys, derivedColAttributesArray, missingRowNames, 
            widthObj:w, heightObj:h, styleObj:s, colOrder,
            getCellHTML_fromColKeyAndRowIndex, getCellHTML_fromColKeyAndRowKey } = tableComputedData

    return (
      <>

          { list(startColIndex,stopColIndex).map(colIndex=>{
            var colKey = colOrder[colIndex]
            let priorHiddenColumns = priorHidden_ByColIndex[colIndex]
            var { internalDataType, isMissingCol, formattingObj, isDepCol, isDeleted, 
                    units:unForcedUnits, shouldForceUnits, forcedUnits } = derivedColAttributesArray[colKey]
            if ( isDeleted ) { return null }
            var {colDescription} = table.attributes.columns[colKey]
            var {isSortable}  = table.attributes
            var isFirstVisibleCol = (w.firstVisibleLockedCol === colIndex || w.firstVisibleMovingCol === colIndex)
            var borderLeftWidth = isFirstVisibleCol ? 0 : 1
            var borderLeftColor = s.gridVertColor
            // Use a heavy border between locked/moving columns (in case of combined table)
            if (w.isCombinedTable && w.firstVisibleMovingCol === colIndex ) {
              borderLeftColor = s.isBrightField ? 'black' : 'white'
              borderLeftWidth = 4
            }
            var numPinnedRows = tablelook.attributes.pinnedRowKeys.length
            var { colTitle, isKey} = table.attributes.columns[colKey]
            var units = (shouldForceUnits) ? forcedUnits : unForcedUnits
            var {isErroneousCol} = derivedColAttributesArray[colKey]
            var isKeyAndContainsErroneousRowNames = isKey &&
                   (Object.keys(missingRowNames).length > 0 || Object.keys(duplicateRowNames).length > 0)
            var isMissingUnits = ( units === '' )
            var isMissingDescription = ( !isDepCol && colDescription.text.trim() === '' )
            var shouldColorCodeTitleRed = isErroneousCol || isKeyAndContainsErroneousRowNames || isMissingUnits || isMissingDescription


            return (

              <div className='rc_ColumnHeaders'
              key={colKey}
              ref={ n => {this.props.tableComputedData.tableDomNodes.columnsHead[colKey]=n}}
              style={{ position:'absolute', transform: `translate(${w.startColLeft[colIndex]}px, 0px)`,
              width: w.displayedColWidths[colIndex],
              height: h.totalHeaderHeight, overflow:'hidden', }} >

                <div className={'HeaderStackRelative'}
                style={{ position:'relative', width: '100%', height:'100%',
                lineHeight: 1, whiteSpace: 'nowrap',
                fontSize: s.cellFontSize, fontWeight: 'normal', }}>

                    <ColumnControls
                    foregroundColor={s.foregroundColor}
                    colHeight={h.colControls}
                    isSortable={isSortable}
                    priorHiddenColumns={priorHiddenColumns} />

                    <ColumnTitle
                    borderLeftWidth={borderLeftWidth}
                    borderLeftColor={borderLeftColor}
                    units={units}
                    isKey={isKey}
                    isKeyAndContainsErroneousRowNames={isKeyAndContainsErroneousRowNames}
                    canEdit={canEdit}
                    shouldColorCodeTitleRed={shouldColorCodeTitleRed}
                    title={colTitle}
                    borderThickness={w.borderThickness}
                    foregroundColor={s.foregroundColor}
                    gridHorzColor={s.gridHorzColor}
                    headerHeight={h.headerHeight}
                    headTopRelative={h.headTopRelative}
                    fontSize={s.cellFontSize}
                    headerBackground={s.headerCellColor}
                    keyIconSize={s.cellFontSize}
                    numPinnedRows={numPinnedRows}
                    pinnedHeight={h.pinnedHeight}/>

                {numPinnedRows > 0 &&
                    <div className={'pinnedColumnContainer'}
                      style={{ position: 'absolute', top: h.pinnedTopRelative,
                      width: '100%', height: h.rowHeight * numPinnedRows,
                      boxSizing: 'border-box', borderWidth: `0px 0px 0px ${borderLeftWidth}px`,
                      borderStyle:'solid',
                      borderLeftColor,  
                    }}>
                          <ColumnData
                          getCellHTML_fromColKeyAndRowKey={getCellHTML_fromColKeyAndRowKey} 
                          getCellHTML_fromColKeyAndRowIndex={getCellHTML_fromColKeyAndRowIndex}
                          colKey={colKey}
                          formattingObj={formattingObj}
                          sortedRowKeys={sortedRowKeys}
                          internalDataType={internalDataType}
                          pinnedRowKeys={pinnedRowKeys}
                          isMissingCol={isMissingCol}
                          backgroundEven={s.cellColorEven}
                          backgroundOdd={s.cellColorOdd}
                          gridHorzColor={s.gridHorzColor}
                          bottomOffset={h.cellBottomOffset}
                          firstRowIndex={0}
                          rowsPerGroup={numPinnedRows}
                          rowHeight={h.rowHeight}/>
                    </div>                    
                }  

                    <div className={'JustTheBottomBorder'}
                      style={{
                          position: 'absolute', 
                          // I put the bottom border exactly on top of the
                          // bottomEdge of LastPinnedRow.  This grid lines are
                          // always 1px, and I want to cover this last grid line
                          // with the variable sized border (1px to 5px).
                          // So I place this at '-1' from expected, so we see
                          // the variable sized border ONLY (not both the 
                          // variable sized border and the last grid line.
                          top: h.pinnedTopRelative + h.rowHeight * numPinnedRows - 1,
                          height: w.borderThickness,  
                          width: '100%',
                          background: s.foregroundColor,
                        //borderWidth: `${w.borderThickness}px 0px  0px 0px`,
                        //borderColor: s.foregroundColor,   // May need to change this for CSV formatted style
                        //borderStyle: 'solid',
                        //boxSizing: 'border-box',
                      }}/>
  
              </div>

          </div>
        )})}

      </>
    )
  }
}


export default ColumnHeaders
