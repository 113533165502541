import type { PlotXyComputedData } from '../computedDataPlotXY/xy_plotTypes'

import {Component}     from 'react'
import SeriesSet              from './EditPlotSeriesSet'
import constants              from '../sharedComponents/constants'


type OwnProps = {
  plotXyComputedData: PlotXyComputedData,
  onExit: ()=> void,
  isSelected: boolean,
  width: number,
  right?: number | string,
  bottom?: number | string,
  left?: number | string,
  top?: number | string,
}

type DefaultProps = {
  menuTop: number | string
  menuBottom: number | string
  menuLeft: number | string
  menuRight: number | string
}

type Props = OwnProps & DefaultProps
export default class EditPlotSeriesOrder extends Component<Props> {

  static defaultProps: DefaultProps = {
    menuTop: 'unset',
    menuBottom: 'unset',
    menuLeft: 'unset',
    menuRight: 'unset'
  }


  render() {

    const {width, right, bottom, left, top, isSelected, onExit, plotXyComputedData} = this.props
    if (!isSelected) { return null }
    const numSeries = plotXyComputedData.seriesOrder.length
    const TITLE_HEIGHT = 34
    const ROW_HEIGHT = 24
    const BOTTOM_SPACER = 16
    const EXIT_BUTTON_HEIGHT = 26
    const LEFT_MARGIN = 10
    const totalHeight= TITLE_HEIGHT + ROW_HEIGHT * numSeries + BOTTOM_SPACER + EXIT_BUTTON_HEIGHT

    return (
      <div className={'ReorderPlotSeriesContainer'}
          style={{ width, height:totalHeight,
            cursor: 'pointer',
            ...constants.CUSTOM_SELECT_POPUP_MENU_OUTLINE,
            position:'absolute', right, bottom, left, top,
            paddingLeft:LEFT_MARGIN,
          }}
      >
            <div className={'sortSeriesTitle'}
              style={{
                height:TITLE_HEIGHT,
                textAlign: 'center',
                paddingTop: 10,
                fontWeight: 'bold',
              }}
            >{'Series Order:'}</div>

            <SeriesSet plotXyComputedData={plotXyComputedData} rowHeight={ROW_HEIGHT}/>

            <div className={'spacer'} style={{height:BOTTOM_SPACER}}/>

            <div className={'clickableExitText'}
              style={{
                position: 'absolute', left:0, bottom:0,
                width: '100%', height:EXIT_BUTTON_HEIGHT,
                borderTop: '2px solid #666',
                paddingTop: 4, paddingBottom: 4, paddingLeft: 6,
                fontSize: 14, fontWeight:'bold'  }}
              onClick={onExit}
              >
              {'Exit this menu'}
            </div>

      </div>
)}}
