import type { PlotXyComputedDataOtherArgs, PlotXyComputedDataParams, PlotXyComputedDataRefs } from '../computedDataPlotXY/xy_createPlotRenderObj'
import type { TableComputedData } from '../computedDataTable/getDefaultTableComputedData'
import type { RootState } from '../redux/store'
import type { Plot, Table, Tabledata, Tablelook } from '../types'
import type {
  TableComputedDataOtherArgs,
  TableComputedDataParams,
  TableComputedDataRefs
} from '../computedDataTable/updateTableComputedData'

import React, { memo, createContext } from 'react'
import { useSelector } from 'react-redux'
import { paramsToListId } from '../jsonapi'
import { getDefaultPlotXy, getDefaultTable } from '../types'
import {
  getMemoizedPlotXyComputedData,
  getMemoizedTableComputedData,
  setMemoizedPlotXyComputedData,
  setMemoizedTableComputedData
} from '../appCode/getMemoizedComputedData'
import { createPlotXyComputedData } from '../computedDataPlotXY/xy_createPlotRenderObj'
import { getDefaultPlotXyComputedData } from '../computedDataPlotXY/xy_plotTypes'
import { updateTableComputedData_memoizedFunc } from '../computedDataTable/updateTableComputedData'
import constants from '../sharedComponents/constants'
import { getMemoizedResult } from '../sharedFunctions/getObjectDiff'
import PlotParent from '../viewPlotXY/PlotParent'
import invariant from 'invariant'

export const PlotXyComputedDataContext = createContext(getDefaultPlotXyComputedData(getDefaultPlotXy([], getDefaultTable(1))))

type OwnProps = {
  tableid: string
  plotid: string
  shouldRenderSVG: boolean
  shouldDeleteSVG: boolean
  saveSvgFinishedCallback: () => void
  deleteSvgFinishedCallback: () => void
  closeNavColumnPopups: () => void
}

const memoizedArraySum = (tableComputedData: TableComputedData): number => {

  if (!tableComputedData) { return 0 }
  var memoSum = 0
  for (const [key, value] of Object.entries(tableComputedData)) {
    if (key.includes('memoized') && Array.isArray(value)) {
      memoSum += value.length
    }
  }

  return memoSum
}

const TtGetComputedDataPlotXy: React.FC<OwnProps> = memo((props) => {
  function plotSelector(state: RootState) {
    const { plotid } = props
    const resource = state.api.resources.plots[plotid]
    return resource ? resource as Plot : null
  }

  function tableSelector(state: RootState) {
    const { tableid } = props
    return state.api.resources.tables?.[tableid] as Table ?? null
  }

  function tableDataSelector(state: RootState) {
    const { tableid } = props
    const table = state.api.resources.tables?.[tableid] as Table ?? null
    const tabledataid = table ? table.relationships?.tabledata.data.id : ''
    return state.api.resources.tabledatas?.[tabledataid] as Tabledata ?? null
  }

  function tableLookSelector(state: RootState) {
    const { tableid } = props
    const userid = state.app.authUserId ?? ''
    const userTablelookListId = paramsToListId({
      'filter[table][data][id]': tableid,
      'filter[owner][data][id]': userid,
    })
    const tablelookid = state.api.lists.tablelooks?.[userTablelookListId]?.ids?.[0] ?? ''
    return state.api.resources.tablelooks?.[tablelookid] as Tablelook ?? null
  }

  function usernameSelector(state: RootState) {
    const userid = state.app.authUserId ?? ''
    return state.api.resources.users?.[userid]?.attributes?.username ?? ''
  }

  function sessionStateSelector(state: RootState) {
    return state.session
  }

  const plot = useSelector(plotSelector)
  const table = useSelector(tableSelector)
  const tabledata = useSelector(tableDataSelector)
  const tablelook = useSelector(tableLookSelector)
  const username = useSelector(usernameSelector)
  const sessionState = useSelector(sessionStateSelector)

  invariant(plot, 'TtGetComputedDataPlotXy tried to render without a plot.')

  const { plotid, tableid, shouldRenderSVG, shouldDeleteSVG, saveSvgFinishedCallback, deleteSvgFinishedCallback, closeNavColumnPopups } = props

  // What is the available heigth/width ( is sideBar open/closed) ?
  // I will make the ASSUMPTION: sideBar open/close state is a TableTriplet parameter.
  // If I open side-bar for any one view, it stays open on all views.
  // Hence, the table/plot images being swapped are mapped to the same space.
  // Not a technical requirement, just a first guess at desired behavior.
  const isSideBarOpen = sessionState.isSideBarOpen.tableView
  const {
    NAV_COLUMN_WIDTH,
    SIDEBAR_RANGE_OF_MOTION,
    SIDEBAR_TOTAL_LEFT_BORDER_WIDTH,
    TOP_MENUBAR_HEIGHT
  } = constants
  let width = window.innerWidth - NAV_COLUMN_WIDTH - SIDEBAR_TOTAL_LEFT_BORDER_WIDTH
  if (isSideBarOpen) {
    width -= SIDEBAR_RANGE_OF_MOTION
  }
  let height = window.innerHeight - TOP_MENUBAR_HEIGHT

  // There is ALWAYS a valid tableid (which may have 0, 1, or 2) associated plotid's.
  // We know whether it is a search table or not.
  // We will also be rendering tables for other reasons, and these tables may have
  // different looks and options set for rendering, for example
  //    Is the searchBar and controls present?
  //    Should the 'publisher' line be rendered?
  //    Should the table grid be rendered?  (easy way to get a CSV looking table)
  //    ???
  // We pass this information to the updateTableComputedData() function.
  const isSearchTable = (tableid === constants.SEARCH_TABLE_ID)
  if (isSearchTable) {
    var isPublisherRendered = false
    var isSearchBarRendered = true
    var isTableGridRendered = true
  } else {
    isPublisherRendered = true
    isSearchBarRendered = false
    isTableGridRendered = true
  }

  // Create or restore tableComputedData:
  const tableRefsObj: TableComputedDataRefs = {
    table,
    tabledata,
    tablelook,
  }
  const tableParamsObj: TableComputedDataParams = {
    menuOption_isEditMode: sessionState.menuOption_isEditMode,
    username,
    tableHeight: height,
    tableWidth: width,
    scrollLeftSessionState: sessionState.activeTableScrollLeft,
    scrollTopSessionState: sessionState.activeTableScrollTop,
    activeStatsColKey: sessionState.activeStatsColKey,
    isPublisherRendered,
    isSearchBarRendered,
    isTableGridRendered,
    // Next param is always a constant, except when some debugging code in app is being used.
    renderIndex: sessionState.renderIndex
  }

  const memoizedTableComputedData = getMemoizedTableComputedData(table.id)

  const priorTableComputedData = memoizedTableComputedData[0]?.result.tableComputedData
  const tableOtherArgsObj: TableComputedDataOtherArgs = { priorTableComputedData }

  // add the array lengths to the paramsObj
  const memoSum  = memoizedArraySum(priorTableComputedData)

  tableParamsObj.renderIndex = sessionState.renderIndex + memoSum
  const maxNumMemoized = 1
  const thisTableFunc = updateTableComputedData_memoizedFunc
  const debugMemoization = false
  const tableAnswer = getMemoizedResult(memoizedTableComputedData, tableParamsObj, tableRefsObj, tableOtherArgsObj, thisTableFunc, maxNumMemoized, debugMemoization)
  setMemoizedTableComputedData(tableid, tableAnswer.newMemArr)

  // Get table computed data
  const tableComputedData = tableAnswer.result.tableComputedData
  //  console.log( '' )
  //  console.log( `call TtGetComputedDataComponent:  isNewResult=', answer.isNewResult, 'renderIndex=', ${tableParamsObj.renderIndex}`)

  const plotXyRefsObj: PlotXyComputedDataRefs = {
    tableComputedData,
    plot
  }
  const plotXyParamsObj: PlotXyComputedDataParams = {
    menuOption_isEditMode: sessionState.menuOption_isEditMode,
    username,
    derivedFilterRules: tableComputedData?.derivedFilterRuleArray ?? []
  }

  const memoizedPlotXyComputedData = getMemoizedPlotXyComputedData(plot.id)

  const plotXyOtherArgs: PlotXyComputedDataOtherArgs = {
    getTableValue: tableComputedData.getTableValue
  }
  const plotXyFunc = createPlotXyComputedData
  const plotXyAnswer = getMemoizedResult(memoizedPlotXyComputedData, plotXyParamsObj, plotXyRefsObj, plotXyOtherArgs, plotXyFunc, maxNumMemoized, debugMemoization)
  setMemoizedPlotXyComputedData(plotid, plotXyAnswer.newMemArr)
  const plotXyComputedData = plotXyAnswer.result.plt

  //console.log( 'call to render TtGetComputedData' )
  return (
    <PlotXyComputedDataContext.Provider value={plotXyComputedData}>
      <div
        style={{
          // Define the position and size of available rendering window
          position: 'absolute',
          left: constants.NAV_COLUMN_WIDTH,
          top: constants.TOP_MENUBAR_HEIGHT,
          width: tableComputedData.tableWidth,
          height: tableComputedData.tableHeight,
        }}
      >
        <div
          style={{
            // Reset the (0,0) origin as the upper-left.
            position: 'relative',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%'
          }}
        >
          <PlotParent
            plotXyComputedData={plotXyComputedData}
            tableComputedData={tableComputedData}
            shouldRenderSVG={shouldRenderSVG}
            shouldDeleteSVG={shouldDeleteSVG}
            saveSvgFinishedCallback={saveSvgFinishedCallback}
            deleteSvgFinishedCallback={deleteSvgFinishedCallback}
            closeNavColumnPopups={closeNavColumnPopups}
          />
        </div>
      </div>
    </PlotXyComputedDataContext.Provider>
  )
})

export default TtGetComputedDataPlotXy