import css from './domCss'
import type {TableLayoutProps}  from './getDefaultTableComputedData'
import {measureText}  from '../sharedFunctions/measureText'
import {getFormattingObj, numberFormat} from '../sharedFunctions/numberFormat'


// This function periodically checks the width of the browser's 'built-in'
// scrollbars.  We need to know their width to completely suppress and
// hide their behavior.
const SCROLLBAR_WIDTH_CHECK_INTERVAL = 5000
var lastCheckedBrowserScrollbarWidth: number = -SCROLLBAR_WIDTH_CHECK_INTERVAL
var scrollbarWidth : number = 0
function setScrollbarWidth() {
    if (typeof document !== 'undefined') {
        const div = document.createElement('div')
        // Fuction css() sets the style properties for the selected element
        css(div, {
            width: 100,
            height: 100,
            position: 'absolute',
            top: -9999,
            overflow: 'scroll',
            MsOverflowStyle: 'scrollbar'
        });
        if (document.body) { document.body.appendChild(div) }
        scrollbarWidth = (div.offsetWidth - div.clientWidth)
        if (document.body) { document.body.removeChild(div) }
    } else {
        scrollbarWidth = 0;
    }
    //console.log( "Determined browser's choice for scrollbar adds content width of", scrollbarWidth )
    return scrollbarWidth || 0;
}


const tableGrey = ( greyscale : number) : string => {
  const min = defaultStyleObj.tableBlack
  const max = defaultStyleObj.tableWhite
  var a: Array<number> = [0,0,0]
  var constrainedGreyscale = Math.min ( greyscale, 1 )
  constrainedGreyscale = Math.max ( greyscale, 0 )
  for (let i=0; i<3; i++) {
      a[i] = Math.round(min[i] + (max[i] - min[i]) * constrainedGreyscale)
  }
  return `rgb(${a[0]},${a[1]},${a[2]} )`
}

const pureGrey = ( greyscale : number ) : string => {
  const min = [0,0,0]
  const max = [255,255,255]
  var a: Array<number> = [0,0,0]
  for (let i=0; i<3; i++) {
      a[i] = Math.round(min[i] + (max[i] - min[i]) * greyscale)
  }
  return `rgb(${a[0]},${a[1]},${a[2]} )`
}


export const defaultStyleObj = {
  // Constant style params:
  gapTableScroll : 6,   // The gap between the right/bottom edge of table and vert/horz scrolling thumb.
  scrollThumbWidth : 14,
  colControlSize : 21,   // Keep odd so there is a clear 'center' pixel.
  funnelIconSize : 55,   // Keep odd so there is a clear 'center' pixel.
  deadSpaceToHideBrowserScrollBars : 25,
  baseFontSize: 16,
  rowNumberFontScale: 0.85,  //16px above scales to 14px
  rowNumbersPaddingRight: 8,
  borderStyle: 'solid',

  // Don't need any greyscale interpolation on the field colors.
  // So write these as a string
  fieldWhite : 'white',   //SCRY_WHITE, //'rgb(247, 247, 255)',
  fieldBlack : 'rgb(  20,   25,   29)',

  // We will interpolate greyscale colors, so write these as an array.
  // Each of three colors interpolated independently
  tableWhite : [247, 247, 255],
  tableBlack : [ 58,  62,  67],

  // Table look slider control parameters:
  globalScale_min : 0.8,
  globalScale_max : 1.6,
  globalScale_numSteps : 16,
  globalScale_fixedPrec:  2,

  mainTitleFontScale_min : 1.6,
  mainTitleFontScale_max : 3.0,
  mainTitleFontScale_numSteps : 14,
  mainTitleFontScale_fixedPrec:  1,

  publisherTitleFontScale_min : 1.4,
  publisherTitleFontScale_max : 2.5,
  publisherTitleFontScale_numSteps :  10,
  publisherTitleFontScale_fixedPrec:  1,

  borderThickness_min : 1,
  borderThickness_max : 5,
  borderThickness_numSteps : 4,
  borderThickness_fixedPrec: 0,

  tableSpacing_min : 4,
  tableSpacing_max : 20,
  tableSpacing_numSteps : 8,
  tableSpacing_fixedPrec:  0,

  rowSpacing_min : 0.8,
  rowSpacing_max : 1.25,
  rowSpacing_numSteps : 9,
  rowSpacing_fixedPrec:  2,

  greyHeadCells_min : 0,
  greyHeadCells_max : .3,
  greyHeadCells_numSteps : 6,
  greyHeadCells_fixedPrec: 2,

  greyDataCells_min : 0,
  greyDataCells_max : .5,
  greyDataCells_numSteps : 10,
  greyDataCells_fixedPrec:  2,

  greyAlternateRow_min : 0,
  greyAlternateRow_max : .3,
  greyAlternateRow_numSteps : 6,
  greyAlternateRow_fixedPrec: 2,

  greyGridHorz_min : 0,
  greyGridHorz_max : 1,
  greyGridHorz_numSteps : 10,
  greyGridHorz_fixedPrec:  1,

  greyGridVert_min : 0,
  greyGridVert_max : 1,
  greyGridVert_numSteps : 10,
  greyGridVert_fixedPrec: 1,


  // Dependent (calculated) styles:
  isBrightField: true,
  fieldColor : '#808080',
  foregroundColor : '#000000',
  headerCellColor     : '#cccccc',
  cellColorEven : '#cccccc',
  cellColorOdd  : '#cccccc',
  gridHorzColor: '#000000',
  gridVertColor: '#000000',
  cellFontSize: 1,
  rowNumberFontSize: 1,
  tableSizeIndicatorFontSize: 1,
  mainTitleFontSize : 1,
  publisherTitleFontSize : 1,
  publisherTitleLength: 0,
  mainTitleLength: 0,
}


export const styleCalculator = (inObj: TableLayoutProps): TableStyleObj => {
  const s : TableStyleObj = structuredClone( defaultStyleObj ) // new object every call.
  const header = inObj.greyHeadCells
  const dData  = inObj.greyDataCells
  const aRow   = inObj.greyAlternateRow
  if ( inObj.isBrightField ) {
    s.isBrightField   = true
    s.headerCellColor = tableGrey( .4 + header)
    s.cellColorEven   = tableGrey( .4 + dData )
    s.cellColorOdd    = tableGrey( .4 + dData - aRow )
    s.fieldColor      = s.fieldWhite
    s.foregroundColor = '#000000'
    s.gridHorzColor   = pureGrey( 1 - inObj.greyGridHorz )
    s.gridVertColor   = pureGrey( 1 - inObj.greyGridVert )
  } else {  // Dark Field
    s.isBrightField   = false
    s.headerCellColor = tableGrey( header)
    s.cellColorEven   = tableGrey( dData )
    s.cellColorOdd    = tableGrey( dData + aRow )
    s.fieldColor      = s.fieldBlack
    s.foregroundColor = '#ffffff'
    s.gridHorzColor   = pureGrey( inObj.greyGridHorz )
    s.gridVertColor   = pureGrey( inObj.greyGridVert )
  }

  if (!inObj.isTableGridRendered) {
    s.headerCellColor = s.fieldColor
    s.cellColorEven = s.cellColorOdd  = s.fieldColor
    s.gridHorzColor = s.gridVertColor = s.fieldColor
  }

  s.cellFontSize              = Math.round( s.baseFontSize * inObj.globalScale )
  s.rowNumberFontSize         = Math.round( s.baseFontSize * inObj.globalScale * s.rowNumberFontScale )
  s.tableSizeIndicatorFontSize= Math.round( s.baseFontSize * Math.sqrt(inObj.globalScale) * s.rowNumberFontScale )
  s.mainTitleFontSize         = Math.round( s.baseFontSize * inObj.globalScale * inObj.mainTitleFontScale )
  s.publisherTitleFontSize    = Math.round( s.baseFontSize * inObj.globalScale * inObj.publisherTitleFontScale )
  //let creationString = formatTRF( convertTRFtoTRobject( 'TRFa' + inObj.createdDate ), 'dd-asciMonth-yyyy' )
  //let updatedString  = formatTRF( convertTRFtoTRobject( 'TRFa' + inObj.updatedDate ), 'dd-asciMonth-yyyy' )

  //console.log( 'Table styleObject', s  )
  return s
}

export type TableStyleObj = typeof defaultStyleObj


export const defaultWidthObj = {
    // Constant params:
    minLeftRightMargin: 25,
    colresize_capture_width : 11, // Keep this an odd value
    colresize_capture_offset : 5,  // 5+1+5 equals above value of 11
    rowNumbersPaddingRight: 8,
    pinIconSize: 0,

    // Calculated or user defined params:
    mainTitle: 0,
    mainTitleLeft: 0,
    publisherTitle: 0,
    publisherTitleLeft: 0,

    tableLayoutWidth: 0,
    borderThickness: 0,
    controlSpacing: 0 ,
    control: 0, // Width off a single control.  Equals its height (square area)

    centeringOffset   : 0,

    firstVisibleLockedCol: -1,
    firstVisibleMovingCol: -1,
    lastVisibleLockedCol: -1,
    lastVisibleMovingCol: -1,

    gapLockedMoving: 0,
    isCombinedTable: false,

    lockedAllocated: 0,
    lockedAllocatedWithBorder: 0,
    totalLockedAllocated: 0,
    lockedLeft: 0,
    lockedRequired: 0,

    lockedLeftEditorExtraSpace: 0,
    lockedRightEditorExtraSpace: 0,

    movingAllocated: 0,
    movingAllocatedWithBorder: 0,
    totalMovingAllocated: 0,
    movingLeft: 0,
    movingRequired: 0,
    totalTableAllocated: 0,

    numLockedCols: 0,

    rowNumbers: 0,
    //sideBar: 0,
    tableSizeIndicator: 0,

    touchLeft: 0,
    touchWindowWidth: 0,
    touchContainerWidth: 0,
    touchContentWidth: 0,

    scrollControlTotalWidth: 0,
    horzScrollControlRight: 0,
    viewWidthPx: 0,

    displayedColWidths: Array<number>(),
    startColLeft: Array<number>(),
    priorHiddenColsByIndex: Array<number>(),
}
export type TableWidthObj = typeof defaultWidthObj

var global_shouldForceCombinedTable     : boolean = false
var global_isCombinedTableForcedValue   : boolean = false
export const enableForcedCombinedTable = (  val : boolean ) => {
    global_shouldForceCombinedTable = true
    global_isCombinedTableForcedValue = val
    //console.log( 'Forcing isCombinedTable', val )
}
export const disableForcedCombinedTable = ( ) => {
    global_shouldForceCombinedTable = false
}


export const widthCalculator = (inObj: TableLayoutProps, unusableRightWidth: number = 0) : TableWidthObj => {
    // unusableRightWidth allocates additional (unused) space, right vert scroll.  Used during column resize.
    // coords are with respect to top/left corner of the space allocated to table.  
  const w : TableWidthObj = structuredClone( defaultWidthObj )  // We must return a new object on every call!
  let {isHidden_ByColIndex, colWidth_ByColIndex, globalScale, numLockedCols} = inObj
  const numCols = colWidth_ByColIndex.length
  let getWidth = (colIndex:number): number => { return isHidden_ByColIndex[colIndex] ? 0 : colWidth_ByColIndex[colIndex] }
  w.tableLayoutWidth = inObj.tableLayoutWidth  
  w.borderThickness = inObj.borderThickness
  w.numLockedCols = numLockedCols
  w.viewWidthPx = w.tableLayoutWidth
  w.tableSizeIndicator  = 200  // No harm making this wider than necesary.
  w.controlSpacing = 29    // Use odd number, so hidden control exactly centered over column edge
  w.control = defaultStyleObj.colControlSize

  const mainTitleFontSize      = defaultStyleObj.baseFontSize * globalScale * inObj.mainTitleFontScale
  const publisherTitleFontSize = defaultStyleObj.baseFontSize * globalScale * inObj.publisherTitleFontScale
  w.mainTitle       = measureText( inObj.tableTitle,     `${mainTitleFontSize}px`,      'bold' ) + 20
  w.publisherTitle  = measureText( inObj.publisherTitle, `${publisherTitleFontSize}px` ) + 20
  w.mainTitle       = Math.min( w.mainTitle,      w.viewWidthPx )
  w.publisherTitle  = Math.min( w.publisherTitle, w.viewWidthPx )
  w.mainTitleLeft      = (w.viewWidthPx - w.mainTitle     ) / 2
  w.publisherTitleLeft = (w.viewWidthPx - w.publisherTitle) / 2

  // Row Numbers allocated width is the greatest of the funnelIcon width, worseCase rowNumber width
  // rowNumber width includes space for the pin Icon, rowNumber, and right padding.
  // Usually this is the funnelIcon.  (Still called 'rowNumber width' regardless, and found in w.rowNumbers )
  const filterIconWidth = Math.round( defaultStyleObj.funnelIconSize * Math.sqrt(globalScale) )
  const worseCaseRowNumberString = inObj.isRowNumberVisible
      ? numberFormat( String(inObj.numRowsUnfiltered), getFormattingObj('commasOnly'), 'measureOnlyString' ) as string
      : ''
  w.pinIconSize= defaultStyleObj.baseFontSize * 1.4 * Math.sqrt( globalScale )
  const rowNumberFontSize = Math.round( defaultStyleObj.baseFontSize * globalScale * defaultStyleObj.rowNumberFontScale )
  const worseCaseRowNumberWidth  = measureText( worseCaseRowNumberString, `${rowNumberFontSize}px` ) +
                                               w.rowNumbersPaddingRight + w.pinIconSize
  w.rowNumbers = Math.round(Math.max( filterIconWidth, worseCaseRowNumberWidth))
  //console.log( 'a' , filterIconWidth, worseCaseRowNumberString, rowNumberFontSize, worseCaseRowNumberWidth )

  /*  Gap between locked and moving tables.

      When the locked table 'unhide' control is needed for the last locked column,
      then this control must fit within this cosmetic gap.  If the user has
      not left sufficient room, then we need to override the user's value.
      The icon has been specifically designed for mininim width

      This test assumes the table is NOT combined.  Don't actually know yet,
      and can't decide until we have overall calculation of widths.   But
      this assumption is OK, because when/if the tables are combined, then
      w.gapLockedMoving is set to zero. */
  w.gapLockedMoving  = Math.round( inObj.tableSpacing * globalScale )   // Nominal cosmetic size.
  if ( numLockedCols > 0 && isHidden_ByColIndex[numLockedCols - 1] ) {
    w.gapLockedMoving = Math.max( w.gapLockedMoving, Math.round(defaultStyleObj.colControlSize * 0.80 - w.borderThickness ))
  }

  w.lockedRequired = 0   // These two numbers always represent the total width of visible data columns, excluding border.
  w.movingRequired = 0
  for (let colIndex=0; colIndex < colWidth_ByColIndex.length; colIndex++) {
    if (colIndex < numLockedCols) {
      w.lockedRequired += getWidth(colIndex) 
    } else {
      w.movingRequired += getWidth(colIndex) 
    }
  }

  // Allocate the space needed for the locked columns.
  // Only space for rowNumbers needed when numLockedCols === 0
  if ( w.numLockedCols === 0 ) {
    w.lockedAllocated =  0
    w.lockedAllocatedWithBorder = 0
    w.totalLockedAllocated = w.rowNumbers
    w.gapLockedMoving = 0  // Override above constant; Eliminate the gap
  } else {  // Case user having one or more locked columns:
    w.lockedAllocated = w.lockedRequired
    w.lockedAllocatedWithBorder = w.lockedAllocated + 2*w.borderThickness
    w.totalLockedAllocated = w.lockedAllocatedWithBorder + w.rowNumbers
  }

  // Total width required to show everything (all columns )
  w.scrollControlTotalWidth = defaultStyleObj.gapTableScroll + defaultStyleObj.scrollThumbWidth
  var totalRequired = w.totalLockedAllocated +
                      w.gapLockedMoving +
                      w.movingRequired +
                      2*w.borderThickness +
                      w.scrollControlTotalWidth
  //console.log('in width Calc: required widths', lockedRequired, w.movingRequired)

  // Center the table horizontally. (By setting offset_Left appropriately)
  // global_unusableRightWidth always >= 0; Potentially used during colResize animation
  // Forced a reducuction in the usable width.  Table will appear like there is
  // unused width on the far right (as is intended).
  let restrictedViewWidthPx = w.viewWidthPx - unusableRightWidth  
  let totalAvailable = restrictedViewWidthPx - 2 * w.minLeftRightMargin
  let neededWidth = Math.min( totalAvailable, totalRequired)
  w.centeringOffset = Math.round((restrictedViewWidthPx - neededWidth )/2)

  // --------------------------------------------------------------------
  //             1 OR 2 TABLE RULE !
  // When there is insufficient room for the moving table, we use a combined table.
  // This constraint may eliminate the locked tables (head and data).
  // The locked columns are moved to the moving Table.
  // Hence only one X-scrollable table, containing all columns, and no locked 
  // columns (other than the row Numbers).
  //
  // ALSO: The table is not allowed to switch between combined or separate
  // over the life of some animations:
  //     actionHideCol
  //     actionResizeCol
  //     actionDnDcol
  //     opening/closing the table styleBar
  //
  // At the start of these animations:
  //     2) global_shouldForceCombinedTable set true
  //     2) global_isCombinedTableForcedValue set to isCombineTable at start of animation 
  //
  // At the end of these animations:
  //     1) global_shouldForceCombinedTableFlag set to false
  //     2) a final stateChange must be initiated
  //     3) react render will eventually call this tableLayout function
  //     4) isCombinedTable decision is based on the post-animation layout
  //     5) table MAY re-render, changing from combined to notCombined, or vice-versa
  // --------------------------------------------------------------------
  const minWidthForMovingTables = 200
  // Assumption: If the available space for the moving table is less than above value, then combine tables:
  var tablesShouldBeCombined = totalAvailable < w.totalLockedAllocated + w.gapLockedMoving + minWidthForMovingTables
  // Unless some animation is forcing an override :
  if ( global_shouldForceCombinedTable ) { tablesShouldBeCombined = global_isCombinedTableForcedValue }
  if ( tablesShouldBeCombined ) {
      w.movingRequired += w.lockedRequired
      w.isCombinedTable = true
      w.lockedAllocated = 0
      w.totalLockedAllocated = w.rowNumbers
      w.gapLockedMoving = 0
      w.lockedRequired  = w.rowNumbers
      totalRequired = w.rowNumbers +
                      w.movingRequired +
                      2*w.borderThickness +
                      w.scrollControlTotalWidth
  }


  // ------------------------------------------------------------------
  // This is information used in the focusCalculator && ActionColResize
  // Save the cumulative column width by column index (index AS VIEWED, left to right).
  // Needed for both locked and moving sets of columns
  // Used to find the colKey (index) given an Xcoord.
  // ------------------------------------------------------------------

  for (let colIndex=0; colIndex<numCols; colIndex++) {
    var width = getWidth(colIndex) 
    if (w.firstVisibleLockedCol === -1 && colIndex <  w.numLockedCols && width > 0 ) { w.firstVisibleLockedCol = colIndex }
    if (w.firstVisibleMovingCol === -1 && colIndex >= w.numLockedCols && width > 0 ) { w.firstVisibleMovingCol = colIndex }
    if ( colIndex <  w.numLockedCols && width > 0 ) { w.lastVisibleLockedCol = colIndex }
    if ( colIndex >= w.numLockedCols && width > 0 ) { w.lastVisibleMovingCol = colIndex }
  }

  // ------------------------------------------------------------------
  // RULE FOR INSUFFICIENT WINDOW WIDTH
  // Reduce the width of movingData.
  // This rule's application results in the presence of a scrollbar
  // ------------------------------------------------------------------

  const spaceNeeded = totalRequired - totalAvailable
  if ( spaceNeeded <= 0 ) {
    w.movingAllocated = w.movingRequired
  } else {
    w.movingAllocated = Math.max( 0, w.movingRequired - spaceNeeded )
  }

  w.movingAllocatedWithBorder = w.movingAllocated + 2*w.borderThickness
  w.totalMovingAllocated = w.movingAllocatedWithBorder + w.scrollControlTotalWidth  // scrollGap + scrollThumb
  w.totalTableAllocated = w.totalLockedAllocated + w.gapLockedMoving + w.totalMovingAllocated

  // If potential external scrollBar width has not been recently determined:
  const now = Date.now()
  if ( now - lastCheckedBrowserScrollbarWidth > SCROLLBAR_WIDTH_CHECK_INTERVAL ) {
    lastCheckedBrowserScrollbarWidth = now
    setScrollbarWidth()
  }

  // Next two left positions with respect to the touchLeft (touch scroll area)
  w.lockedLeft = w.centeringOffset + w.rowNumbers + w.borderThickness  // first content in the locked table
  w.movingLeft = w.centeringOffset + w.totalLockedAllocated + w.gapLockedMoving + w.borderThickness // 1st content in the moving table
  w.touchLeft           = w.centeringOffset + w.rowNumbers
  w.touchWindowWidth    = w.totalTableAllocated - w.rowNumbers - w.scrollControlTotalWidth
  w.touchContainerWidth = w.touchWindowWidth + defaultStyleObj.deadSpaceToHideBrowserScrollBars
  w.touchContentWidth   = w.touchContainerWidth + (w.movingRequired - w.movingAllocated) - scrollbarWidth
  w.horzScrollControlRight = w.borderThickness + w.scrollControlTotalWidth

  w.displayedColWidths = []
  for (let j=0; j<numCols; j++) {
    w.displayedColWidths[j] = isHidden_ByColIndex[j] ? 0 : colWidth_ByColIndex[j]
  }
  w.startColLeft = [0]
  for (let j=0; j<numCols; j++) {
    if ( !w.isCombinedTable && j+1 === numLockedCols ) {
      w.startColLeft[j+1] = 0
    } else { 
      w.startColLeft[j+1] = w.startColLeft[j] + w.displayedColWidths[j]
    }
  }
  return w
}


/* 
// Create arrays for displayed widths, leftOffsets, and numHidden cols:
//
//    The 'priorHiddenColsByIndex' array is the same order as colOrder (positions correspond to what you see rendered).
//    However, the algorithm is easier if we compute the values in reverse order: right-to-left.
//
//    Define a concept of 'colIndexOfLastVisibleUnhideControl'
//       Assume EVERY visible column has an UnhideControl, (when a column's numHidden===0, the unhide control is just not rendered).
//       Assume the gap between locked and moving cols has an unhide control (when its val is 0, unhide arrow is not rendered).
//       Assume an unhide control to the far right of the table (when its val is 0, unhide arrow is not rendered).
//       These are NOT 'extra' unhide controls.  They are just the place where we render the unhide control
//       for a column 'hidden' in the gap between tables, or hidden off the right edge of the table.
//
//    Initialize variable 'colIndexOfLastVisibleUnhideControl' to the last colIndex
//    Re-set 'colIndexOfLastVisibleUnhideControl' every time we find a visible column (think right-to-left).
//    Re-set 'colIndexOfLastVisibleUnhideControl' to last lockedColIndex, WHEN loop reaches lastLockedColIndex
//    Whenever we find a hidden column:   priorHiddenColsByIndex[ colIndexOfLastVisibleUnhideControl ]++
//    Meaning 'hidden' columns are associated with the visible column to the right, except when no column 
//      to the right exists, as in the last column, or a lastLockedColumn.
//    Hence, 'colIndexOfLastVisibleUnhideControl' is the colIndex that 'accumulates' prior Hidden columns.
//
//    Read and interpret the priorHiddenColsByIndex[] as what you see;
//         A nonZero value will be rendered next to a visible unhide button.
//         A zero value will NOT render a unhide control.  
//         A column with a zero value May or May not be visible!  (Either case, the unhide buttom is not rendered.)
//
// For the first pass through the loop:
//    We start at last column in colOrder.  colIndexOfLastVisibleUnhideControl is pointing to this column.
//    IF hidden, then increment priorHiddenColsByIndex[ lastIndex of colOrder ]
//    This will result in the 'unhide' arrow at the far right edge of table.
//    The count assigned to this arrow will continue to increment, until we find the next visible col.
//
// Every time we find a visible column, then this colIndex becomes the next accumulator for priorHiddenColsByIndex.
// 
// One more special case:  When we get to the last locked column (colIndex === numLockedCols-1), then
// this column may or may not be hidden.  But (like the last possible column), this 'unhide' arrow
// will be displayed in the gap between locked/moving tables. We reset colIndexOfLastVisibleUnhideControl 
// to this colIndex.  It becomes the new accumulator for potential locked cols, if hidden.
//
// Use immutable objects for the return arrays
// Return the same obj reference if/when there is no change.
// So PureComponent leaf nodes in react are easier to manage.

var lastDisplayedColWidths: number[] = []
var lastStartColLeft      : number[] = []
//var lastpriorHiddenColsByIndex   : number[] = []

const colWidthCalculator2 = (inputProps:TableLayoutProps, isCombinedTable:boolean ) :
     { displayedColWidths: number[],
       startColLeft      : number[],
       } => {

  const {numLockedCols, colWidth_ByColIndex, isHidden_ByColIndex } = inputProps
  const numCols = colWidth_ByColIndex.length
  // The return values:
  var displayedColWidths : number[] = Array( numCols  ).fill(0)
  var startColLeft       : number[] = Array( numCols+1).fill(0)    // The last value is the right edge coord of rightmost column.

  // Loop from left-to-right to get displayed widths and left edge placements
  // First startColLeft is ALWAYS zero.
  // 2nd startColLeft is typically the width of the first col (if visible)
  // Remember, the startColLeft array length is (numCols+1)
  //    startColLeft starts at zero, then increasing values for next numCols.
  //    last value in array is coord of the right edge of table.
  for (let j=0; j<numCols; j++) {
    let thisWidth = isHidden_ByColIndex[j] ? 0 : colWidth_ByColIndex[j]
    let numVisibleLockedCols = (isCombinedTable) ? 0 : numLockedCols
    displayedColWidths[j] = thisWidth
    if ( j+1 === numVisibleLockedCols ) {
      startColLeft[j+1] = 0
    } else { 
      startColLeft[j+1] = startColLeft[j] + thisWidth
    }
  }
  // If no change, reuse the last array references
  displayedColWidths = isEqual( displayedColWidths, lastDisplayedColWidths )
                                    ? lastDisplayedColWidths
                                    : displayedColWidths
  startColLeft       = isEqual( lastStartColLeft, startColLeft )
                                    ? lastStartColLeft       
                                    : startColLeft
  lastDisplayedColWidths = displayedColWidths
  lastStartColLeft = startColLeft
  return { displayedColWidths, startColLeft }
}
*/


export const defaultHeightObj = {
    // Constant params:
    gapTopMainTitle    : 20,
    gapSourceTitleControls : 16,
    gapControlsHead : 4,
    gapHorzscrollStats : 10,
    gapStatsWindowBottom: 3,
    rowsPerRowGroup : 20,

    // Calculated or User defined params
    borderThickness: 0,
    colControls: 0,
    colControlsTop: 0,
    filterControlTop: 0,

    dataAllocated: 0,
    dataAllocatedWithBorder: 0,
    totalDataAllocated: 0,   // includes bottom 'horzScroll' bar.
    dataRequired: 0,
    dataTop: 0,

    numFilteredRowKeys: 0,
    doDataRowsExist: true,
    rowHeight: 0,
    rowGroupHeight: 0,
    cellBottomOffset: 0,
    numDataRows: 0,
    numRowGroups: 0,
    rowsPerGroup: 0,

    emptySpaceBelowTable: 0,  // Used inside focusCalculator
    scaledFunnelIconSize: 0,

    mainTitle: 0,
    publisherTitle: 0,
    gapHeadData: 0,

    headTop: 0,
    headTopRelative: 0,
    headerHeight: 0,
    pinnedHeight: 0,
    pinnedTopRelative: 0,
    pinnedTop: 0,
    totalHeaderHeight: 0,

    minScrollHeightBeforeHidden: 0,

    horzScrollControlTop: 0,
    statsHeight: 0,
    statsBarTop: 0,

    tableSizeIndicator: 0,

    touchTop: 0,
    touchWindowHeight: 0,
    touchContainerHeight: 0,
    touchContentHeight: 0,

    vertScrollControlTop: 0,
    horzScrollControlHeight: 0,

    totalTableAllocated: 0,

    viewHeightPx: 0,
    tableLayoutHeight: 0,
}

export type TableHeightObj = typeof defaultHeightObj

export const heightCalculator = (inObj: TableLayoutProps): TableHeightObj => {
  const {globalScale, pinnedRowKeys} = inObj
  const h : TableHeightObj = structuredClone( defaultHeightObj )  // New object every call.
  h.numFilteredRowKeys   = inObj.sortedRowKeys.length
  if ( h.numFilteredRowKeys === 0 ) {
    h.doDataRowsExist = false
    h.numFilteredRowKeys = 1 // The room we require to print an error message that all rows have been filtered out.
  }

  h.tableLayoutHeight   = inObj.tableLayoutHeight   // This is the TopMenuBar, plus the search bar if present.
  h.viewHeightPx        = h.tableLayoutHeight

  h.borderThickness     = inObj.borderThickness
  h.headerHeight        = Math.round( defaultStyleObj.baseFontSize * 2.8 * inObj.rowSpacing * globalScale )
  h.rowHeight           = Math.round( defaultStyleObj.baseFontSize * 1.6 * inObj.rowSpacing * globalScale )
  h.cellBottomOffset    = Math.max( 0, (h.rowHeight - defaultStyleObj.baseFontSize*inObj.globalScale*1.4)/2 )

  h.mainTitle           = Math.round(defaultStyleObj.baseFontSize * globalScale * inObj.mainTitleFontScale + 4 )
  h.publisherTitle      = (inObj.isPublisherRendered)   // We only allocate vertical space for data tables.
                        ? Math.round(defaultStyleObj.baseFontSize * globalScale * inObj.publisherTitleFontScale + 4 )
                        : 0

  h.tableSizeIndicator  = Math.round(defaultStyleObj.baseFontSize * globalScale * defaultStyleObj.rowNumberFontScale ) + 2
  h.statsHeight      = Math.round(defaultStyleObj.baseFontSize * 1.6 * globalScale ) + h.tableSizeIndicator

  h.colControls         = Math.round(defaultStyleObj.colControlSize * Math.sqrt(globalScale) )
  h.scaledFunnelIconSize= Math.round(defaultStyleObj.funnelIconSize * Math.sqrt(globalScale) )

  h.gapHeadData         = Math.round( inObj.tableSpacing * globalScale )
  h.dataRequired        = h.numFilteredRowKeys * h.rowHeight - 1
  h.minScrollHeightBeforeHidden = Math.round( 1.5 * h.rowHeight )

  h.colControlsTop      = h.gapTopMainTitle + h.mainTitle + h.publisherTitle
                          + h.gapSourceTitleControls  // Top of Col Controls
  h.filterControlTop    = h.colControlsTop - h.colControls / 4

  // Next two with respect to top edge of the colControls.
  h.headTopRelative     = h.colControls + h.gapControlsHead
  h.pinnedTopRelative   = h.headTopRelative + h.headerHeight + h.borderThickness
  // Next two with respect to the top of the viewHeightPx
  h.headTop             = h.colControlsTop + h.headTopRelative
  h.pinnedTop           = h.colControlsTop + h.pinnedTopRelative

  h.pinnedHeight        = pinnedRowKeys.length * h.rowHeight
  h.totalHeaderHeight   = h.colControls + h.gapControlsHead + h.headerHeight
                          +  h.pinnedHeight + 2*h.borderThickness

  h.horzScrollControlHeight = defaultStyleObj.gapTableScroll + defaultStyleObj.scrollThumbWidth
  // The vert Scroll control is inside the TouchScroll container.
  // And the top of the TouchScroll container is the topEdge of header's top border.
  h.vertScrollControlTop    = h.headerHeight + h.pinnedHeight + h.gapHeadData + 3*h.borderThickness
  const fixedTopSpace       = h.colControlsTop + h.totalHeaderHeight + h.gapHeadData
  const totalDataRequired   = h.dataRequired + 2*h.borderThickness
  const fixedBottomSpace    = h.horzScrollControlHeight + h.gapHorzscrollStats + h.statsHeight + h.gapStatsWindowBottom
  const availableDataHeight = h.viewHeightPx - fixedTopSpace - fixedBottomSpace
  h.dataAllocatedWithBorder = Math.min( availableDataHeight, totalDataRequired )
  h.totalDataAllocated      = h.dataAllocatedWithBorder + h.horzScrollControlHeight
  h.horzScrollControlTop    =
  h.emptySpaceBelowTable    = h.viewHeightPx - fixedTopSpace - h.dataAllocatedWithBorder
  h.dataTop = fixedTopSpace + h.borderThickness  // Top of vert scroll table values


  h.numRowGroups = Math.ceil( (h.viewHeightPx - fixedTopSpace - fixedBottomSpace ) / (h.rowsPerRowGroup*h.rowHeight) ) + 1
  h.numDataRows  = h.numRowGroups * h.rowsPerRowGroup
  h.rowsPerGroup = h.rowsPerRowGroup
  h.rowGroupHeight = h.rowsPerRowGroup * h.rowHeight

  // ALWAYS include at least one rowHeight of data (or space for one row height, since a single
  // row will not be displayed.  This locks the vertical position of the scrollBar and statsBar
  // to a minimum vertical position. If the window continues to get too small such that one
  // row of data is not visible, then table will effectively stop sizing vertically. And the
  // statsBar and horz scroll bar will simple extend beyond the bottom border of the window.
  h.dataAllocatedWithBorder = Math.max(h.rowHeight + 2*h.borderThickness , h.dataAllocatedWithBorder)
  h.dataAllocated = Math.max(0, h.dataAllocatedWithBorder - 2*h.borderThickness)
  h.totalTableAllocated =  fixedTopSpace + h.dataAllocatedWithBorder + h.horzScrollControlHeight

  h.horzScrollControlTop    = h.dataTop + h.dataAllocatedWithBorder - h.borderThickness + defaultStyleObj.gapTableScroll
  h.statsBarTop             = h.totalTableAllocated + h.gapHorzscrollStats

  // Parameters for the ActionScroll Component
  // Touch height extends from top of topBorder of Header, to: bottom of bottom border of allocatedData
  h.touchTop             = h.headTop
  h.touchWindowHeight    = h.headerHeight +  h.pinnedHeight + 2*h.borderThickness
                              + h.gapHeadData + h.dataAllocatedWithBorder
  h.touchContainerHeight = h.touchWindowHeight + defaultStyleObj.deadSpaceToHideBrowserScrollBars
  h.touchContentHeight   = h.touchContainerHeight + (h.dataRequired - h.dataAllocated) - scrollbarWidth

  //console.log('Height Calculator Output:', h)
  return h
}



export const getRowGroupPlacement = ( rowGroupIndex: number, scrollTop: number,
          numRowGroups: number, rowsPerGroup : number, rowGroupHeight: number ) 
                           : { topOffset:number, firstRowIndex:number } => {
  // How many 'full' rowGroups sit entirely 'above' the table, not to be rendered?
  const num_TopmostRowGroupsNotRendered = Math.floor( scrollTop / rowGroupHeight )
  const firstVisibleGroupIndex = num_TopmostRowGroupsNotRendered % numRowGroups
  const firstVisibleTopOffset  = -( scrollTop - num_TopmostRowGroupsNotRendered*rowGroupHeight)
  // How many groups from firstVisibleGroupIndex to next rowGroupIndex ?
  var numGroupsToNextRowGroupIndex = rowGroupIndex - firstVisibleGroupIndex
  if (numGroupsToNextRowGroupIndex < 0 ) { numGroupsToNextRowGroupIndex += numRowGroups }
  var topOffset = firstVisibleTopOffset + numGroupsToNextRowGroupIndex * rowGroupHeight
  var firstRowIndex = (num_TopmostRowGroupsNotRendered + numGroupsToNextRowGroupIndex) * rowsPerGroup
  return {topOffset, firstRowIndex }
}