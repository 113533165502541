import type { OptionalHTMLElement } from '../types'

import invariant from 'invariant'
import {PureComponent} from 'react'
import {orderOfAxisNameChildren,
  OPACITY_DISABLED_LABELANGLE_SLIDER,
  orderOfMinLimitChildren,
  orderOfMaxLimitChildren}  from './EditAxisStyle'
import reactDispatch                from '../sharedComponents/reactDispatch'
import {STYLE_EDITOR_RAISED_BUTTON} from '../sharedComponents/constants'
import {rStateXY_Plot}              from '../viewPlotXY/xy_responsiveState'
import dynamics                     from 'dynamics.js'
import {restorePointerEvents,
  wasSuspendPointerEventsSuccessful} from '../sharedFunctions/pointerEvents'
import { getAxisMapping }           from '../computedDataPlotXY/plotUtils'


type Props = {
  isTransposed: boolean,
  isMirrored: boolean,
}

class AxisTransposeControl extends PureComponent<Props> {

  onClickTranspose = () => {
    const { isTransposed, isMirrored } = this.props
    const callDispatch = () => reactDispatch( [{ newVal: !isTransposed, path: 'attributes.isTransposed' }] , 'Transpose Plot' )
    responsivelySwapAxisNames ( isTransposed, isMirrored, !isTransposed, isMirrored, callDispatch )
  }
  onClickMirror = () => {
    const { isTransposed, isMirrored } = this.props
    const callDispatch = () => reactDispatch( [{ newVal: !isTransposed, path: 'attributes.isTransposed' }] , 'Transpose Plot' )
    responsivelySwapAxisNames ( isTransposed, isMirrored, !isTransposed, isMirrored, callDispatch )
  }

  render () {
    const { isTransposed, isMirrored } = this.props

    const transposeMessage = isTransposed ? 'Transposed YES' : 'Transposed NO'
    const mirroredMessage  = isMirrored   ? 'Mirrored   YES' : 'Mirrored NO'

    return (
      <div className={'rc_AxisTransposeControl'}
        style={{
          width:'100%',
          height:'100%',
          boxSizing: 'border-box',
          display: 'flex', flexFlow: 'column nowrap',
        }}>


          <div className={'AxisTransposeFlipButtonContainer'}
            style={{
              display:'inline-block',
              height:40, width: 50,
              marginLeft:40, marginTop: 10,
            }}
          >
            <svg
              viewBox="0 0 50 40"
              style={STYLE_EDITOR_RAISED_BUTTON}
              xmlns='http://www.w3.org/2000/svg'
              height={40}
              width={50}
              onClick={this.onClickTranspose}
            >
              <polyline points='12,8 12,28, 40,28' stroke='black' strokeWidth='2.5'/>
              <path d="M 21 11   18 14   21 17   18 14  Q 28 11   32 24   M 28 22  32 24  33 20 " stroke='red' strokeWidth='1.5'/>
            </svg>
          </div>


{ false &&
          <div style={{ marginTop: 10, height: 16, width:'100%', color:'red'}}>{transposeMessage}</div>
}

{false &&
          <div
            style={{ marginTop: 10, height: 16, width:'100%', color:'red'}}
            onClick={this.onClickMirror}
          >{mirroredMessage}</div>
}

      </div>
    )
  }
}


export default AxisTransposeControl

type AxisTransposeAnimationObj = {
  paramA: number
}

const responsivelySwapAxisNames = ( oldTranspose:boolean, oldMirror:boolean,
    newTranspose:boolean, newMirror:boolean, callDispatch: ()=>void ) : void => {

  let action
  if ( newTranspose !== oldTranspose ) {
    action = 'xy_Plot transpose A/B axes'
  }
  else if ( newMirror !== oldMirror ) {
    action = 'xy_Plot flip A/C axes'
  } else {
    invariant(false, 'responsivelySwapAxisNames called when no change to transpose or mirror')
  }

  if ( !wasSuspendPointerEventsSuccessful(action)) {
    return
  }

  let A_old: OptionalHTMLElement = null
  let A_new: OptionalHTMLElement = null
  let B_old: OptionalHTMLElement = null
  let B_new: OptionalHTMLElement = null
  var A_oldText, A_newText, B_oldText, B_newText
  var A_labelAngle_ascending = false
  var A_labelAngle_descending= false
  var B_labelAngle_ascending = false
  var B_labelAngle_descending= false
  const A_children  = (rStateXY_Plot.axisName['basisA'] ) ?  rStateXY_Plot.axisName['basisA'].children : null
  if (A_children) {
    ({axisName: A_oldText} = getAxisMapping( 'A', oldTranspose, oldMirror ));
    ({axisName: A_newText} = getAxisMapping( 'A', newTranspose, newMirror ));
    A_old =  A_children[ orderOfAxisNameChildren.indexOf( A_oldText ) ] as HTMLElement
    A_new =  A_children[ orderOfAxisNameChildren.indexOf( A_newText ) ] as HTMLElement
  }

  const B_children  = (rStateXY_Plot.axisName['basisB'] ) ?  rStateXY_Plot.axisName['basisB'].children : null
  if (B_children) {
    ({axisName: B_oldText} = getAxisMapping( 'B', oldTranspose, oldMirror ));
    ({axisName: B_newText} = getAxisMapping( 'B', newTranspose, newMirror ));
    B_old =  B_children[ orderOfAxisNameChildren.indexOf( B_oldText ) ] as HTMLElement
    B_new =  B_children[ orderOfAxisNameChildren.indexOf( B_newText ) ] as HTMLElement
  }

  if ( (A_oldText === 'Bottom' || A_oldText === 'Top') && (A_newText === 'Left' || A_newText === 'Right')) {
    A_labelAngle_descending = true
  }
  if ( (A_oldText === 'Left' || A_oldText === 'Right') && (A_newText === 'Bottom' || A_newText === 'Top')) {
    A_labelAngle_ascending = true
  }
  if ( (B_oldText === 'Bottom' || B_oldText === 'Top') && (B_newText === 'Left' || B_newText === 'Right')) {
    B_labelAngle_descending = true
  }
  if ( (B_oldText === 'Left' || B_oldText === 'Right') && (B_newText === 'Bottom' || B_newText === 'Top')) {
    B_labelAngle_ascending = true
  }

  // If this is a transpose change, then all the minLimit/maxLimit text labels are changed.
  // If this is a mirror change, then all the minLimit/maxLimit text labels are UN-changed.
  let Amin_old: OptionalHTMLElement = null
  let Amin_new: OptionalHTMLElement = null
  let Amax_old: OptionalHTMLElement = null
  let Amax_new: OptionalHTMLElement = null
  let Bmin_old: OptionalHTMLElement = null
  let Bmin_new: OptionalHTMLElement = null
  let Bmax_old: OptionalHTMLElement = null
  let Bmax_new: OptionalHTMLElement = null
  if (oldTranspose !== newTranspose) {

    const Amin_children  = (rStateXY_Plot.minLimit['basisA'] ) ?  rStateXY_Plot.minLimit['basisA'].children : null
    const Amax_children  = (rStateXY_Plot.maxLimit['basisA'] ) ?  rStateXY_Plot.maxLimit['basisA'].children : null
    if (Amin_children && Amax_children ) {
      // When A_oldText === 'Bottom', then the A axis is currently on bottom, with a minLimit at 'Left'
      // When A_oldText !== 'Bottom', then the A axis is currently Left edge or right edge; with its minLimit at 'Bottom'
      Amin_old = Amin_children[ (A_oldText === 'Bottom') ? orderOfMinLimitChildren.indexOf('Left'  ) : orderOfMinLimitChildren.indexOf('Bottom' )] as HTMLElement
      Amin_new = Amin_children[ (A_oldText === 'Bottom') ? orderOfMinLimitChildren.indexOf('Bottom') : orderOfMinLimitChildren.indexOf('Left')] as HTMLElement
      Amax_old = Amax_children[ (A_oldText === 'Bottom') ? orderOfMaxLimitChildren.indexOf('Right' ) : orderOfMaxLimitChildren.indexOf('Top')] as HTMLElement
      Amax_new = Amax_children[ (A_oldText === 'Bottom') ? orderOfMaxLimitChildren.indexOf('Top'   ) : orderOfMaxLimitChildren.indexOf('Right')] as HTMLElement
    }

    const Bmin_children  = (rStateXY_Plot.minLimit['basisB'] ) ?  rStateXY_Plot.minLimit['basisB'].children : null
    const Bmax_children  = (rStateXY_Plot.maxLimit['basisB'] ) ?  rStateXY_Plot.maxLimit['basisB'].children : null
    if (Bmin_children && Bmax_children ) {
      // When B_oldText === 'Left', then the B axis is currently on left, with a minLimit at 'bottom'
      // When B_oldText !== 'Left', then the B axis is currently Left edge or right edge; with its minLimit at 'Bottom'
      Bmin_old = Bmin_children[ (B_oldText === 'Left') ? orderOfMinLimitChildren.indexOf('Bottom'): orderOfMinLimitChildren.indexOf('Left')] as HTMLElement
      Bmin_new = Bmin_children[ (B_oldText === 'Left') ? orderOfMinLimitChildren.indexOf('Left')  : orderOfMinLimitChildren.indexOf('Bottom')] as HTMLElement
      Bmax_old = Bmax_children[ (B_oldText === 'Left') ? orderOfMaxLimitChildren.indexOf('Top')   : orderOfMaxLimitChildren.indexOf('Right')] as HTMLElement
      Bmax_new = Bmax_children[ (B_oldText === 'Left') ? orderOfMaxLimitChildren.indexOf('Right') : orderOfMaxLimitChildren.indexOf('Top')] as HTMLElement
    }



  }



  dynamics.animate (

    { paramA: 0},
    { paramA: 1.2},
    { // Options:

      change: (obj: AxisTransposeAnimationObj)=> {
        var opacityOld = Math.max( 0, 1-obj.paramA*2 )  // varies from 1 to 0 as paramA varies from 0.0 to 0.5
        var opacityNew = Math.max( 0, obj.paramA*2-1 )  // varies from 0 to 1 as paramA varies from 0.5 to 1.0
        opacityOld = Math.min( opacityOld, 1 )  // max opacity set to one.  Why is paramA > 1 ??
        opacityNew = Math.min( opacityNew, 1 )  // Because when set to one the last frame at '1' takes to long to also render the plot
        opacityOld = opacityOld * opacityOld
        opacityNew = opacityNew * opacityNew
        var descendingLabelAngleOpacity = Math.max( OPACITY_DISABLED_LABELANGLE_SLIDER, opacityOld )
        var ascendingLabelAngleOpacity  = Math.max( OPACITY_DISABLED_LABELANGLE_SLIDER, opacityNew )
        //console.log ( opacityOld, opacityNew )
        if (A_old) { A_old.style.opacity = String(opacityOld) }
        if (B_old) { B_old.style.opacity = String(opacityOld) }
        if (A_new) { A_new.style.opacity = String(opacityNew) }
        if (B_new) { B_new.style.opacity = String(opacityNew) }

        if (Amin_old) { Amin_old.style.opacity = String(opacityOld) }
        if (Amax_old) { Amax_old.style.opacity = String(opacityOld) }
        if (Bmin_old) { Bmin_old.style.opacity = String(opacityOld) }
        if (Bmax_old) { Bmax_old.style.opacity = String(opacityOld) }

        if (Amin_new) { Amin_new.style.opacity = String(opacityNew) }
        if (Amax_new) { Amax_new.style.opacity = String(opacityNew) }
        if (Bmin_new) { Bmin_new.style.opacity = String(opacityNew) }
        if (Bmax_new) { Bmax_new.style.opacity = String(opacityNew) }

        if (A_labelAngle_ascending  && rStateXY_Plot.labelAngle['basisA'] ) {
          // $FlowFixMe
          rStateXY_Plot.labelAngle['basisA'].style.opacity =  String(ascendingLabelAngleOpacity)
        }
        if (A_labelAngle_descending && rStateXY_Plot.labelAngle['basisA'] ) {
          // $FlowFixMe
          rStateXY_Plot.labelAngle['basisA'].style.opacity = String(descendingLabelAngleOpacity)
        }
        if (B_labelAngle_ascending  && rStateXY_Plot.labelAngle['basisB'] ) {
          // $FlowFixMe
          rStateXY_Plot.labelAngle['basisB'].style.opacity =  String(ascendingLabelAngleOpacity)
        }
        if (B_labelAngle_descending && rStateXY_Plot.labelAngle['basisB'] ) {
          // $FlowFixMe
          rStateXY_Plot.labelAngle['basisB'].style.opacity = String(descendingLabelAngleOpacity)
        }

      },

      complete:( ) => {
        restorePointerEvents(action)
        callDispatch( )
      },

      type: dynamics.linear,
      duration: 200,
    }
  )

}
