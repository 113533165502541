import { configureStore } from '@reduxjs/toolkit'
import type { AnyAction, Dispatch, MiddlewareAPI } from '@reduxjs/toolkit'
import { api } from '../jsonapi'
import app from './appReducer'
import notifications  from './notificationsReducer'
import notify from './notifyReducer'
// import popup from './popupReducer'
import thumbnail from './thumbnailReducer'
import tooltip from './tooltipReducer'
import undo from './undoReducer'
import session from './sessionReducer'
import authMiddleware from './authMiddleware'
import notifyMiddleware from './notifyMiddleware'
import tooltipMiddleware from './tooltipMiddleware'
import undoMiddleware from './undoMiddleware'
import sessionMiddleware from './sessionMiddleware'
import type { ApiState } from '../jsonapi/apiReducer'
import type { AppState } from './appReducer'
import type { NotificationState } from './notificationsReducer'
import type { NotifyState } from './notifyReducer'
// import type { PopupState } from './popupReducer'
import type { ThumbnailState } from './thumbnailReducer'
import type { TooltipState } from './tooltipReducer'
import type { UndoState } from './undoReducer'
import type { SessionState } from './sessionReducer'

// ApiAction excluded due to problems with definition of AxiosXHRConfigBase
// import type {ApiAction} from './jsonapi/actions'

// export type Action = ReduxAction<Object>

// const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

// const reducers = {
//   api,
//   app,
//   notifications,
//   notify,
//   popup,
//   thumbnail,
//   tooltip,
//   undo,
//   //upload,
// }

// const combinedReducers = combineReducers(reducers)
// const composedEnhancers = composeEnhancers(
//   applyMiddleware(
//     authMiddleware,
//     notifyMiddleware,
//     reduxThunk,
//     tooltipMiddleware,
//     undoMiddleware
//   )
// )

// const store = createStore(combinedReducers, composedEnhancers)

// export type Dispatch = typeof store.dispatch
// export type RootState = ReturnType<typeof store.getState>
// export type GetState = () => RootState

// export default store

const store = configureStore({
  reducer: {
    api,
    app,
    notifications,
    notify,
    // popup,
    thumbnail,
    tooltip,
    session,
    undo
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      // serialization check only occurs in development mode
      // but can take a while when we load a new table (a lot of data to check)
      // since this is useful check for dev, we set the timeout to a longer value (default is 32ms)
      // To turn off the check, set to false
      serializableCheck: {
        ignoredActions: ['session/updateSession', 'api/apiRead', 'api/apiCreated', 'api/apiUpdated', 'api/apiDeleted'],
        ignoredPaths: ['session', 'api.resources'],
        warnAfter: 512,
      },
    })
    .prepend(undoMiddleware, sessionMiddleware, authMiddleware, notifyMiddleware, tooltipMiddleware)
    // if there was middleware we wanted to run last we could use: .concat(finalMiddleware)
})

export type AppDispatch = typeof store.dispatch
// export type RootState = ReturnType<typeof store.getState>
export type RootState = {
  api: ApiState
  app: AppState
  notifications: NotificationState
  notify: NotifyState
  // popup: PopupState
  thumbnail: ThumbnailState
  tooltip: TooltipState
  session: SessionState
  undo: UndoState
}

export type StoreType = MiddlewareAPI<Dispatch<AnyAction>, RootState>
export type MiddlewareFunc = (action: AnyAction) => AnyAction

export default store
