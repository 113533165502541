import React, {Component}   from 'react'
import type {TableHeightObj, TableWidthObj, TableStyleObj} from '../computedDataTable/layoutCalculator'
import type {DomNode}       from '../computedDataTable/getDefaultTableComputedData'
import type {TableFocalPlane_UnderlyingTarget}       from './focusCalculator'
import {focusCalculator, focusCalculator_nameToCellObj}    from './focusCalculator'
import Draggable             from 'react-draggable'
import type {DraggableData}  from 'react-draggable'

const initialX = 15
const initialY = 200
const topY  = initialY - 11
const leftX = initialX - 11
var focusColDiv   : DomNode = null
var focusRowDiv   : DomNode = null
var focusCellDiv  : DomNode = null
var outlineCellDiv: DomNode = null
var draggableDiv  : DomNode = null

const px = (value: number) : string => { return `${value}px` }


const handleDrag = ( data : DraggableData, 
  widthObj: TableWidthObj, heightObj: TableHeightObj, styleObj: TableStyleObj, 
  scrollLeft: number, scrollTop: number,
  colOrder: number[], sortedRowKeys: number[], pinnedRowKeys: number[] ): void => {
  //console.log( data.x, data.y )
  const geometry : TableFocalPlane_UnderlyingTarget  = focusCalculator( 
                                                          initialX + data.x, initialY + data.y,
                                                          widthObj, heightObj, styleObj, 
                                                          scrollLeft, scrollTop, 
                                                          colOrder, sortedRowKeys, pinnedRowKeys )

  if ( focusRowDiv ) {
    focusRowDiv.style.top = px(geometry.parentTop)
    focusRowDiv.style.height = px(geometry.parentHeight)
  }
  if ( focusColDiv ) {
    focusColDiv.style.left = px(geometry.parentLeft)
    focusColDiv.style.width = px(geometry.parentWidth)
  }
  if ( focusCellDiv ) {
    focusCellDiv.style.top    = px(geometry.cellTop)
    focusCellDiv.style.left   = px(geometry.cellLeft)
    focusCellDiv.style.height = px(geometry.cellHeight)
    focusCellDiv.style.width  = px(geometry.cellWidth)
    focusCellDiv.innerText = `Index: row${geometry.rowIndex} col${geometry.colIndex}`
  }

  // Now, given the name of the underlying feature, calculate the corresponding position
  // Above background tests the click function.
  // This outline tests the function needed for hightlighting.
  // They should match exactly.
  // Only call the function if the geometry corresponds to a valid selected cell:
  // Convert horzSlice and VertSlice convention to the name 'selection'
  // that next function uses as its input
  var name = ''
  const {focusHorzSlice:hS, focusVertSlice:vS, colIndex, rowIndex} = geometry
  if      ( hS === 'mainTitle' ) { name = 'mainTitle' }
  else if ( hS === 'publisherTitle' ) { name = 'publisherTitle' }
  else if ( hS === 'head'   && (vS === 'locked' || vS === 'moving')) { name = 'tableHeader'}
  else if ( hS === 'pinned' && (vS === 'locked' || vS === 'moving')) { name = 'pinnedCell' }
  else if ( hS === 'data'   && (vS === 'locked' || vS === 'moving')) { name = 'dataCell'   }

  //console.log( name, geometry.focusVertSlice, geometry.focusHorzSlice, geometry.rowIndex, geometry.colIndex )

  if (name !== '' ) {
      const cellObj = focusCalculator_nameToCellObj( name, colIndex, rowIndex,
                                                     scrollLeft, scrollTop,
                                                     widthObj, heightObj, styleObj )

      if ( cellObj.isCellVisible && outlineCellDiv ) {
        outlineCellDiv.style.top    = px(cellObj.top)
        outlineCellDiv.style.left   = px(cellObj.left)
        outlineCellDiv.style.height = px(cellObj.height)
        outlineCellDiv.style.width  = px(cellObj.width)
      }
  } else if ( outlineCellDiv ) {
      outlineCellDiv.style.top    = px(0)
      outlineCellDiv.style.left   = px(0)
      outlineCellDiv.style.height = px(0)
      outlineCellDiv.style.width  = px(0)
  }
}

const handleStop = ( ): void => {
  if ( focusRowDiv ) {
    focusRowDiv.style.top = px(initialY)
    focusRowDiv.style.height = px(0)
  }
  if ( focusColDiv ) {
    focusColDiv.style.left = px(initialX)
    focusColDiv.style.width = px(0)
  }
  if ( focusCellDiv ) {
    focusCellDiv.style.top    = px(0)
    focusCellDiv.style.left   = px(0)
    focusCellDiv.style.height = px(0)
    focusCellDiv.style.width  = px(0)
  }
  if ( outlineCellDiv ) {
    outlineCellDiv.style.top    = px(0)
    outlineCellDiv.style.left   = px(0)
    outlineCellDiv.style.height = px(0)
    outlineCellDiv.style.width  = px(0)
  }
  if (draggableDiv ) {
    draggableDiv.style.transform = `translate( 0px, 0px )`
    draggableDiv.style.top = px(topY)
    draggableDiv.style.left = px(leftX)
  }
}

type OwnProps = {
  colOrder     : number[],
  sortedRowKeys: number[],
  pinnedRowKeys: number[],
  widthObj  : TableWidthObj,
  heightObj : TableHeightObj,
  styleObj  : TableStyleObj,
  scrollLeft: number,
  scrollTop : number,
}

export default class ActionDebugFocusCalculator extends Component<OwnProps> {

  initFocusRowDiv = (element: DomNode): void => {
    focusRowDiv = element
  }
  initFocusColDiv = (element: DomNode): void => {
    focusColDiv = element
  }
  initFocusCellDiv = (element: DomNode): void => {
    focusCellDiv = element
  }
  initOutlineCellDiv = (element: DomNode): void => {
    outlineCellDiv = element
  }
  initDraggableDiv = (element: DomNode): void => {
    draggableDiv = element
  }


  render() {
    const {widthObj, heightObj, styleObj, colOrder, scrollTop,
      sortedRowKeys, pinnedRowKeys, scrollLeft} = this.props

    return (
      <div className={'rc_ActionDebugFocusCalculator'} >
          <div className={'focusRowDebug'}
             ref= { this.initFocusRowDiv }
             style={{ background: 'magenta',
                      opacity: '.2',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: 0,
             }}/>

           <div className={'focusColDebug'}
              ref= { this.initFocusColDiv }
              style={{ background: 'cyan',
                       opacity: '.2',
                       position: 'absolute',
                       top: 0,
                       left: 0,
                       width: 0,
                       height: '100%',
              }}/>

            <input className={'focusCellDebug'}
               ref= { this.initFocusCellDiv }
               style={{ position: 'absolute',
                        top: 0,
                        left: 0,
                        width: 0,
                        height: 0,
                        ///border: '1px solid blue',
                        boxSizing: 'border-box',
              }}/>

            <div className={'outlineCellDebug'}
              ref= { this.initOutlineCellDiv }
              style={{ position: 'absolute',
                       top: 0, left: 0, width: 0, height: 0,
                       border: '1px solid red',
                       boxSizing: 'border-box',
                       background: 'transparent',
              }}/>

           <Draggable
             onDrag ={ (e, data) => handleDrag( data, widthObj, heightObj, styleObj, 
                         scrollLeft, scrollTop, colOrder, sortedRowKeys, pinnedRowKeys )}
             onStop ={ () => handleStop( )}
             position={{x:0, y:0}}
             >
            <div  className={'ActionDebugControl'}
                  ref= { this.initDraggableDiv }
                  style={{position: 'absolute',
                          top: topY , left: leftX,
                          width: 21, height: 21,
                          background : 'green',
                          opacity: 0.3,
                          border: '2px solid green',
                          boxSizing: 'border-box',
                        }} >
                <div style={{position:'absolute', top:6, left:6, width:5, height:5, background:'blue'}}/>
            </div>
        </Draggable>
      </div>

    )
  }
}
