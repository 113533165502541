import type {ReactElement} from 'react' 
import type {Property}              from 'csstype' 
import type {DomNode, TableComputedData}  from '../computedDataTable/getDefaultTableComputedData'
import type {DraggableEvent, DraggableData} from 'react-draggable'

import {PureComponent} from 'react' 
import invariant  from 'invariant'
import dynamics   from 'dynamics.js'
import {DraggableCore}              from 'react-draggable'
import {connect}                    from 'react-redux'
import {Dispatch}                   from 'redux'
import {createTooltipVisibilityOverride} from '../redux/tooltipReducer'
import {enableForcedCombinedTable,
        disableForcedCombinedTable} from '../computedDataTable/layoutCalculator'
import {getDefaultTableComputedData}from '../computedDataTable/getDefaultTableComputedData'
import {sessionStateChangeDispatch} from '../sharedComponents/reactDispatch'
//import {MIN_COL_WIDTH_AT_NOMINAL_SCALE} from '../sharedComponents/constants'
import {initTableResponsiveState, 
        synchScrollLeft}            from './tableResponsiveState'
import rStateFloatingPalette        from '../floatingPalette/rStateFloatingPalette'
import reactDispatch                from '../sharedComponents/reactDispatch'
import { errorCheckColOrder}        from '../computedDataTable/updateTableSupportFuncs'


type ColGroup = { 
  colKey     : number, 
  groupWidth : number,
  startIndex : number,
  stopIndex  : number,
}


const DEBUG_RENDER : boolean = false
const LEFT_PARKED_POSITION : number = -1000
const OVERLAY_OPACITY_BRIGHTFIELD: string = '.15'
const OVERLAY_OPACITY_DARKFIELD  : string = '.30'


// This next component is simple wrapper:
//  - breaks out those attributes required for column DnD.
//  - sets global_tableComputedData inside the col DnD state Machine code.
export const ActionDndColFunc = ( tableComputedData : TableComputedData ) : ReactElement => { 
      const {widthObj:w, heightObj:h, styleObj:s, scrollLeft, colOrder,
             sessionStateRenderIndex} = tableComputedData
      global_tableComputedData = tableComputedData  // Layout data to be used insided the DnD animation
                                                    // The required data changes with each col Swap.
      return (
        < ActionDndCode
            displayedColWidths={w.displayedColWidths}
            scrollLeft={scrollLeft}
            colOrder={colOrder}
            isBrightField={s.isBrightField}
            topOffset={h.colControlsTop}
            dragHeight={h.totalHeaderHeight + h.gapHeadData + h.dataAllocatedWithBorder}
            startColLeft={w.startColLeft}
            colControlWidth={h.colControls}
            isCombinedTable={w.isCombinedTable}
            numLockedCols={w.numLockedCols}
            lockedLeft={w.lockedLeft}
            lockedAllocated={w.lockedAllocated}
            movingLeft={w.movingLeft}
            movingAllocated={w.movingAllocated}
            sessionStateRenderIndex={sessionStateRenderIndex}/>
      )
}


  type RenderingProps = {
      displayedColWidths: number[], 
      startColLeft: number[],
      topOffset: number,
      dragHeight: number,
      isBrightField: boolean,
      colControlWidth: number,
      colOrder: Array<number>,
      scrollLeft: number,
      isCombinedTable: boolean,
      numLockedCols: number,
      lockedLeft: number,
      lockedAllocated: number,
      movingLeft: number,
      movingAllocated: number,
      sessionStateRenderIndex: number, 
  }
  type LocalState = { IsDnDinProgress: boolean }
  type DispatchProps = { hideToolTip: (OnOff: boolean) => void }
  class ActionDndCodeRender extends PureComponent<RenderingProps & DispatchProps, LocalState> {
      initGreenLineDiv     = (element: DomNode): void => { greenLineDiv    = element }
      initScrollLeftDiv    = (element: DomNode): void => { greenScrollLeftDiv  = element }
      initDynamicSwapDiv   = (element: DomNode): void => { greenDynamicSwapDiv = element }
      initBlueLineLeftDiv  = (element: DomNode): void => { blueLineLeftDiv = element }
      initBlueLineRightDiv = (element: DomNode): void => { blueLineRightDiv= element }

      render() {
        //console.log( 're-render Action DnD' ) 
        const { isBrightField, dragHeight, topOffset, isCombinedTable,
          numLockedCols, lockedLeft, lockedAllocated, movingLeft, movingAllocated,
          colOrder, scrollLeft, colControlWidth, displayedColWidths, startColLeft} = this.props
        const {hideToolTip} = this.props
        const overlayOpacity = (isBrightField) ?  OVERLAY_OPACITY_BRIGHTFIELD : OVERLAY_OPACITY_DARKFIELD

        /*
        This component renders:
          1- A pre-built translucent green 'column'.  Placed over the dragged column during animation.
          2- A pre-built vertical 'greenLine' of 1px width. Shows the current xCoord of drag.
          3- Two pre-built vertical 'blueLines', only visible in debug mode,
              and placed at the position of where autoscrolling begins.
          4- Array of DragCapture divs.  One placed over each drag column control icon.
        */

      return (
        <div className={'rc_ActionDndCol'}
          style={{
            position: 'absolute', left:0, top:0,
            width: '100%', height: '100%'
          }} >

  {/* BlueLines identifying the begin autoscroll positions; For debugging only */}
  {DEBUG_RENDER && 
          <>
            <div className={'PreAutoScrollDebugLineLeft'}
            ref={ this.initBlueLineLeftDiv }
            style={{ position: 'absolute', left: LEFT_PARKED_POSITION, top: topOffset,
            height: dragHeight, width : 1, backgroundColor: 'blue', }} />

            <div className={'PreAutoScrollDebugLineRight'}
            ref={ this.initBlueLineRightDiv }
            style={{ position: 'absolute', left: LEFT_PARKED_POSITION, top: topOffset,
            height: dragHeight, width : 1, backgroundColor: 'blue', }} />
          </>
  }

  {/* GreenLineDiv (current drag indicator; May NOT always match the cursorX) */}
          <div className={'GreenLineDiv'}
          ref={ this.initGreenLineDiv }
          style={{ position: 'absolute', left: LEFT_PARKED_POSITION, top: topOffset,
          height: dragHeight, width : 2, backgroundColor: 'green', }} />

  {/* greenOverlay (translucent overlay with height and width of dragged column */}
          <div className={'GreenOverlay_ScrollLeftDiv'}
          ref={ this.initScrollLeftDiv }
          style={{ position: 'absolute', left: 0, top: topOffset,
          //width: 0, height: 0, 
          transform: `translate(${LEFT_PARKED_POSITION+ 200}px, 0px)`}}>

              <div className={'GreenOverlay_DynamicSwapDiv'}
              ref={ this.initDynamicSwapDiv }
              style={{ position: 'absolute', left: 0, top:0,
              transform: `translate(0px, 0px)`,
              height: dragHeight, width : 20,  // Width set later, per selected column.
              backgroundColor: 'green', opacity:overlayOpacity}} />

          </div>

  {/* Container for the draggable Capture areas. */}
          <div className={'ContainerHoldingDragCaptureAreas'}
          style={{ position: 'absolute', left: 0, top: topOffset,
          width: '100%', height: colControlWidth,
          background: DEBUG_RENDER ? 'red' : 'transparent', opacity: .5, }} >


                {[...Array(displayedColWidths.length).keys()].map ( (colIndex) => {
                // k is the left to right column index
                var colKey = colOrder[colIndex]
                var visibilityStrg : Property.Visibility = 'visible'
                let colWidth = displayedColWidths[colIndex]
                if (colWidth===0) { return null }   // hidden col
                if ( colWidth < 2*colControlWidth ) { return null }   // col so narrow, the DnD control is totally hidden
                if ( !isCombinedTable && colIndex < numLockedCols ) {  // locked column
                  var xLeft = lockedLeft + 1*colControlWidth + startColLeft[colIndex]
                  var xRight= xLeft + Math.min( colControlWidth, colWidth - 1*colControlWidth)
                  if (xLeft  > lockedLeft + lockedAllocated ) { visibilityStrg='hidden' } // table so narrow locked column control not visible
                  if (xRight > lockedLeft + lockedAllocated ) {xRight = lockedLeft + lockedAllocated}
                } else {  // moving column
                  xLeft = movingLeft - scrollLeft + startColLeft[colIndex] + 1*colControlWidth
                  xRight= xLeft + Math.min( colControlWidth, colWidth - 1*colControlWidth)
                  if (xRight < movingLeft) { visibilityStrg='hidden' }
                  if (xLeft  > movingLeft + movingAllocated) { visibilityStrg='hidden' }
                  if (xLeft  < movingLeft) {xLeft = movingLeft}
                  if (xRight > movingLeft + movingAllocated) {xRight = movingLeft + movingAllocated}
                }
                // key={colKey} below is a MUST HAVE.  Else any dispatch to re-render the columns will
                // not recognize the dragged col to be 'unneccessary to re-render'.  Without a the identical
                // key, the dragged col will unmount and a new column (domNode) will be created.  Hence
                // the drag operation is interrupted mid-code!
                return (
                        <DraggableCore
                          enableUserSelectHack={false}
                          key={colKey}
                          onStart={(e, dragData)=>handleDndColStart(e, dragData, colIndex, hideToolTip )}
                          onDrag ={(e, dragData)=>handleDndColDrag( e, dragData )}
                          onStop ={(e, _)=>handleDndColStop( e, hideToolTip )}          
                        >
                              <div className={'DndColCaptureZone'}
                              style={{ position: 'absolute', left: xLeft,
                              width : xRight - xLeft, height: '100%',
                              visibility: visibilityStrg, 
                              background: DEBUG_RENDER ? 'black' : 'transparent' }}/>

                        </DraggableCore>
                )})}
          </div>
        </div> 
      )}
  }

const mapDispatch = (dispatch: Dispatch): DispatchProps => ({
    hideToolTip: (onOff: boolean): void => {
      dispatch(createTooltipVisibilityOverride(onOff))
    },
})

export const ActionDndCode = connect(null, mapDispatch)(ActionDndCodeRender)



///////////////////////////////////////////////////////////////////////////////////////////
//        handleDndColStart, 
//        handleDndColDrag, 
//        handleDndColStop
///////////////////////////////////////////////////////////////////////////////////////////

const handleDndColStart= (e: DraggableEvent, dragData: DraggableData, indexIn:number, hideToolTip: { (OnOff: boolean): void}) => {
  e.preventDefault()
  e.stopPropagation()
  const {widthObj : w, colOrder, scrollLeft} = global_tableComputedData
  global_HideToolTip = hideToolTip  // Save function so we can 'unhide' toolTips at end of animation.
  global_GreenLineColIndex = getDraggedColIndex( dragData.x, scrollLeft)
  global_DraggedColKey = colOrder[global_GreenLineColIndex]
  global_ScrollLeft = scrollLeft     // This global value WILL diverge from global_tableComputedData
  global_CursorX = dragData.x        // Left offset from edge of tableView window
  global_GreenLineX = getGreenLineX( global_CursorX, scrollLeft )  // constrained version of CursorX
  global_GreenLineColIndex = getGreenLineColIndex( dragData.x, global_ScrollLeft ) 
  global_IsDraggingMovingCol = !( global_GreenLineColIndex < w.numLockedCols ) || w.isCombinedTable
  global_IsUserDragActive = true
  let draggedColName = global_tableComputedData.derivedColAttributesArray[global_DraggedColKey].colTitle
  global_ActionGroup = `Reorder Col "${draggedColName}"`
  initTableResponsiveState(global_tableComputedData)  
  enableForcedCombinedTable(w.isCombinedTable) // Set layout calculator to NEVER switch between
                                               // isCombinedTable true or false during the animation.
  shiftGreenCursorLine( )     // Moves the GreenCursorLine from parked postion (offscreen) to cursorX
  shiftGreenOverlay( )        // Moves the GreenOverlay    from parked postion (offscreen) to dragged Col
  stateMachine( )             // Starts the stateMachine
  hideToolTip( true )         // Hide tool tips until stateMachine exits
  rStateFloatingPalette.hideFloatingPalette( )
}

const handleDndColDrag= (e: DraggableEvent, dragData: DraggableData):void => {// debouncing not required when using react-draggable core. 
  e.preventDefault()
  e.stopPropagation()
  global_CursorX = dragData.x 
  global_GreenLineX = getGreenLineX( global_CursorX, global_ScrollLeft ) // Some constraints on greenLine location
  global_GreenLineColIndex = getGreenLineColIndex( dragData.x, global_ScrollLeft ) 
}

const handleDndColStop= (e: DraggableEvent, hideToolTip: { (OnOff: boolean): void}):void => {
  // The animation is NOT stopped here!  StateMachine continues until animations complete.
  e.preventDefault()
  e.stopPropagation()
  global_IsUserDragActive = false
}


/*       
X coord System:
    'dragData.x' === 0 is located at the right edge of the navColumn (left edge of table view)
    'cursorX' = dragData.x
    The cursor 'greenLine' is parked offscreen to the far left.
    During drag, the 'greenLine' is located at css:  left: 0, transform = `translate( ${greenLineX}px, 0px )`
    GreenLineX usually tracks cursorX, except greenLineX is constrained near the edges of the visible table range.
    Hence, the 'dragData.x', 'cursorX', are identical. And GreenLineX is identical, except constrained.

    The pertient layout.tableWidthObj parameters are:
      w.lockedLeft - offset of lockedCol div from left edge fo tableView
      w.movingLeft - offset of movingCol div from left edge of tableView
      w.displayedColWidths - By ColKey! - the rendered width.  
              Differs from resource width by global scale factor (a style option)
              Widths of '0' mean a column is hidden.  Otherwise columns have a minWidth.
      w.startColLeft - By ColIndex! - leftEdge of column with respect to the start of lockedData and movingData
              IF we have locked columns, there will be at least 2 zero values (first lockedCol and first movingCol) 
              Hidden columns appear has two or more consecutive values that are equal.
              For example, two visible lockedCols w/ two hidden lockedCols, followed by three visible movingCol:
                =>  [0,0,150,150,    0, 230, 380]  
      layoutProps.numLockedCols - in above example this is '4'
      leftEdge  location (with respect to tableView leftEdge) of colIndex === 3:
                => w.lockedLeft + w.startColLeft[3]
      rightEdge location (with respect to tableView leftEdge) of colIndex === 3:
                => w.lockedLeft + w.startColLeft[3] + w.displayedColWidths[3]
      leftEdge  location (with respect to tableView leftEdge) of colIndex === 6:
                => w.movingLeft + w.startColLeft[6] - scrollLeft 
      rightEdge location (with respect to tableView leftEdge) of colIndex === 6:
                => w.movingLeft + w.startColLeft[6] - scrollLeft + w.displayedColWidths[6] 


DEBUGGING & TESTING - This animation has more corners than the state of West Virginia!
      CombinedTable (true/fase) - When the locked columns are wide enough such that: combinedTable === true. 
      Direction of swap (dragged to left or to the right)
      Dragged/Swapped cols adjacent, or separated by other cols?
      Locked Col shifted to Moving Table, or visa-versa
      Dragged with/without Hidden cols
      Swapped with/without hidden cols
      Fat or Narrow dragged Col (affects trigger location, and emphasizes potential animation errors)
      Fat or Narrow swapped Col (affects trigger location, and emphasizes potential animation errors)
      isMoving col with/without autoscrolling
      Moving Table ScrollLeft
      Num Locked cols in the gap = 0, or greater than 0
      Num Moving cols after right edge of table = 0, or greater than 0
      Does animation complete during, and AFTER mouseUp.
*/




// CONSTANTS ( over the full duration of a DnD animation )
const px = (value:number) : string => `${value}px`
const AUTOSCROLLING_THRESHOLD_BEGINS_AT_PX  = 40  // Anything smaller creates a corner case not currently handled.
const HALF_MIN_COL_WIDTH = 20    // used as a trigger threshold when cursor at edges of table.
var   greenLineDiv        : DomNode = null
var   greenScrollLeftDiv  : DomNode = null
var   greenDynamicSwapDiv : DomNode = null
var   blueLineLeftDiv     : DomNode = null
var   blueLineRightDiv    : DomNode = null
var   global_DraggedColKey: number = 0
var   global_ActionGroup  : string = 'ColDnD' // For grouping multiple statechanges of multiple renders into 1 unDo
var   global_HideToolTip  : { (OnOff: boolean): void}

// Every time col is swapped or changes locked <==> moving, THEN react re-renders.
// tableComptedData contains all the layout information of the currently rendered view:
var   global_tableComputedData : TableComputedData = getDefaultTableComputedData(1) 

// These are the state of each individual frame:
var   global_CursorX    : number = 0      // cursor location. Tracks mouseMove.
var   global_GreenLineX : number = 0      // constrained green Line location.  Tracks mouseMove, but within constraints.
var   global_ScrollLeft : number = 0      // changes with autoScroll
var   global_GreenLineColIndex : number = 0 // Column index corresponding to current greenLine position.
var   global_IsDraggingMovingCol : boolean = false  // Changes if/when the dragged col moves between locked/moving tables.
                                                    // If isCombinedTable, this is 'true' and constant over entire animation.
var   global_IsUserDragActive    : boolean = false  // MouseDown->true; MouseUp->false; But animations MAY extend beyond dragStop
var   global_IsDynamicSwapActive : boolean = false  // When dynamics stateMachine calling itself  (dynamics library colSwap)
var   global_IsColSwapActive     : boolean = false  // When colSwap  stateMachine calling itself  (asking for pending colSwaps)



// Next functions are in the 'DragCoord' system, where 'x===0' is the left edge of the tableView.
// These functions translate the tableRendered positions into the shared coord system of DnD function.
const leftEdgeOfLockedTable  = () : number => {
  return global_tableComputedData.widthObj.lockedLeft
}
const rightEdgeOfLockedTable = () : number => {
  const {lockedLeft, displayedColWidths, numLockedCols,
    isCombinedTable, startColLeft} = global_tableComputedData.widthObj
  return (isCombinedTable || numLockedCols === 0) ? lockedLeft
                           : lockedLeft + startColLeft[numLockedCols-1] + displayedColWidths[numLockedCols-1]
}
const rightEdgeOfLockedTablePlusGap = () : number => {
  const {gapLockedMoving, isCombinedTable, borderThickness,} = global_tableComputedData.widthObj
  return (isCombinedTable) ? rightEdgeOfLockedTable()
                           : rightEdgeOfLockedTable() + gapLockedMoving + 2*borderThickness
}
const leftEdgeOfMovingTable = () : number => {
  return global_tableComputedData.widthObj.movingLeft
}
const leftEdgeOfMovingTableMinusScrollLeft = () : number => {
  return leftEdgeOfMovingTable() - global_tableComputedData.scrollLeft
}


// Next set of functions convert the position of the dragged greenLine, into
// the columnIndex of the underlying column.   Same as asking, 'Which colIndex
// is currently under the dragged GreenLine??  These functions account for
// the gapBetweenTables, scrollLeft position, hidden cols, etc.
const getMovingColIndex = ( xIn: number, scrollLeft: number ) : number => {
    const {movingLeft, displayedColWidths, numLockedCols, 
      startColLeft, isCombinedTable} = global_tableComputedData.widthObj  
    const x = xIn - movingLeft + scrollLeft// Add the hidden scrolled width back to x
    const leftMostIndex = (isCombinedTable) ? 0 : numLockedCols
    //console.log( 'movingLeft, scrollLeft, xIn, xLookup', movingLeft, scrollLeft, xIn, x  )
    const numCols = displayedColWidths.length
    let   colIndex = numCols - 1
    while( colIndex > leftMostIndex && startColLeft[colIndex] > x) { colIndex-- }
    return colIndex
}
const getLockedColIndex = ( x: number ) : number => {
    const {lockedLeft, numLockedCols, startColLeft} = global_tableComputedData.widthObj  
    x -= lockedLeft   // x==0 is now leftmost edge of locked Columns
    var colIndex = numLockedCols - 1
    while( colIndex > 0 && startColLeft[colIndex] > x) { colIndex-- }
    return colIndex
}
const getDraggedColIndex = ( x: number, scrollLeft: number ) : number => {  
    // This function is ONLY called on MouseDown, hence the greenLine will never lie in the 'gap-between-table', and the
    // dragged column will be 'mostly visible' as the drag control is left side of the column width.    
    if ( x >= leftEdgeOfMovingTable() ) { 
      return getMovingColIndex( x, scrollLeft) 
    }
    return getLockedColIndex( x )
}
const get_LastVisibleLockedColIndex = (  ) : number => {
    const { isCombinedTable, numLockedCols } = global_tableComputedData.widthObj
    if ( isCombinedTable ) { return -1 }   // There is no lockedTable; no lastVisibleLockedCol
    const { isHidden_ByColIndex } = global_tableComputedData
    var lastIndex = numLockedCols - 1  // Assume last locked column is NOT hidden
    while ( lastIndex >= 0 && isHidden_ByColIndex[lastIndex] === true ) { lastIndex -- }
    // worse case, lastIndex will be '-1' is there are NO visible locked cols (all hidden)
    return lastIndex
}
const get_FirstVisibleMovingColIndex = ( ) : number => {
    // If scrollLeft === 0, then this will be the first 'not hidden' movingTable column
    // If scrollLeft > 0, then this will be the first column see sees, ignoring those
    // column currently scrolled out-of-view to the left of the moving table
    const { isCombinedTable, numLockedCols, startColLeft } = global_tableComputedData.widthObj
    const {colOrder, isHidden_ByColIndex, scrollLeft} = global_tableComputedData
    const numCols = colOrder.length
    var firstIndex = (isCombinedTable) ? 0 : numLockedCols
    while ( startColLeft[firstIndex] < scrollLeft ||  // column scrolled out-of-view to left  
      ( firstIndex <= numCols && isHidden_ByColIndex[firstIndex] === true )) { 
        firstIndex++ 
    }
    return firstIndex
}


////////////////////////////////////////////////////////////////////////////////
//     Position the greenLine and the greenOverlay
////////////////////////////////////////////////////////////////////////////////

const shiftGreenOverlay = () => {
    const {displayedColWidths, lockedLeft, startColLeft, movingLeft,
              isCombinedTable, numLockedCols} = global_tableComputedData.widthObj
    const colIndex = global_tableComputedData.colOrder.indexOf(global_DraggedColKey)
    if ( colIndex === -1 ) { return }
    var colWidth = displayedColWidths[colIndex]
    var colLeft: number
    if ( isCombinedTable || colIndex >= numLockedCols) {
      colLeft = movingLeft + startColLeft[colIndex] - global_ScrollLeft
    } else {
      colLeft = lockedLeft + startColLeft[colIndex]
    }
    /*
    // Some optional cosmetic code.
    // If the draggedCol is only paritally visible on left side of moving table, because
    // a portion of the column is scrolled out-of-view, then trucate the greenOverlay
    // accordingly.
    // NOT being used because we would also need to make the dynamic swapping algorithm
    // do the same type of testing.  No really sure this behavior is even necessary or desired,
    // so not using at this time.  JPS
    const undesiredOverlap = movingLeft - colLeft
    if ( global_IsDraggingMovingCol && undesiredOverlap > 0 ) {
      colLeft = movingLeft
      colWidth -= undesiredOverlap
    }
    */
    if ( greenScrollLeftDiv && greenDynamicSwapDiv) {
      greenScrollLeftDiv.style.transform = `translate(${colLeft}px, 0px)`
      greenDynamicSwapDiv.style.width = px(colWidth)
    }
}
const shiftGreenOverlayOffscreen = () => {
    if ( greenScrollLeftDiv && greenDynamicSwapDiv) {
      greenScrollLeftDiv.style.transform = `translate(${LEFT_PARKED_POSITION}px, 0px)`
      greenDynamicSwapDiv.style.width = '0px'
    }
}
const getGreenLineX = (cursorX: number, scrollLeft: number ) : number => {
    const {widthObj:w} = global_tableComputedData
    var constrainedX = cursorX
    constrainedX = Math.max( constrainedX, w.lockedLeft - HALF_MIN_COL_WIDTH - 2 )
    constrainedX = Math.min( constrainedX, w.movingLeft + w.movingAllocated - HALF_MIN_COL_WIDTH )
    return constrainedX
}
const shiftGreenCursorLine = ( ) => {
    if (greenLineDiv) { 
      greenLineDiv.style.left  = px(0)
      greenLineDiv.style.transform = `translate(${global_GreenLineX}px, 0px)` 
    }
}
const shiftGreenCursorLineOffscreen = ( ) => {
    if ( greenLineDiv ) {
      greenLineDiv.style.left  = px(LEFT_PARKED_POSITION)
      greenLineDiv.style.transform = `translate(0px, 0px)`
    }
    if ( blueLineLeftDiv ) {
      blueLineLeftDiv.style.left  = px(LEFT_PARKED_POSITION)
    }
    if ( blueLineRightDiv) {
      blueLineRightDiv.style.left = px(LEFT_PARKED_POSITION)
    }
}


///////////////////////////////////////////////////////////////////////////////////////////
// NEXT FUNCTION IS DIFFICULT TO DEBUG !!
// What column lies under the greenLine is more difficult that one would 1st expect.
// Best approach to debug this is force a return 'false' in func: attemptDynamicColSwap()
// Then you can debug these cases without any column swapping.
///////////////////////////////////////////////////////////////////////////////////////////

var   lastColIndex = -1   // this state only use for debugging.  See comment at bottom of this func.
const getGreenLineColIndex = ( greenLinePosition: number, scrollLeft: number ) : number => {  
    //  This function is called onDrag().  Every mouseMove potentially changes the underlying ColIndex.
    //  When the mouse is stationary, the greenLineColIndex is constant.
    //  Note!  There is significant behavior difference when a column moves from 
    //  lockedTable => movingTable, vrs movingTable => lockedTable.   If you don't see the 
    //  difference, its because you need scrollLeft > 0 (movingTable is scrolled)
    const {widthObj, colOrder} = global_tableComputedData
    const { movingLeft, numLockedCols, lockedLeft, startColLeft, 
            displayedColWidths, isCombinedTable, movingAllocated } = widthObj
    const draggedColIndex = colOrder.indexOf(global_DraggedColKey)
    const isDraggingLockedCol = draggedColIndex < numLockedCols
    const isDraggingMovingCol = !(isDraggingLockedCol)
    var index = -1   // If branches below should ALWAYS re-set index to a valid colIndex
    var x = greenLinePosition  
    // x position may include constraints (x value is shifted left or right a bit).
    // If greenLinePosition is left of lockedTable, x is shifted 'right' to the edge of lockedTable
    // If greenLinePosition is in the gap between tables:
    //     if draggedColumn is locked, gap belongs to lockedTable and x shifted 'left'  to lockedTable
    //     if draggedColumn is moving, gap belongs to movingTable and x shifted 'right' to movingTable
    // After constraints, x will always lie in a visible column && map to a unique colIndex.
    if ( isCombinedTable || numLockedCols === 0) {
      x = Math.max( x, movingLeft )
      index = getMovingColIndex( x, scrollLeft )
    }
    else if ( isDraggingLockedCol && x < rightEdgeOfLockedTablePlusGap() ) {
        x = Math.max( x, leftEdgeOfLockedTable() )
        x = Math.min( x, rightEdgeOfLockedTable() )
        index = getLockedColIndex(x)
    }
    else if ( isDraggingLockedCol && x >= leftEdgeOfMovingTable() ) {
      x = Math.max( x, leftEdgeOfMovingTableMinusScrollLeft() )
      x = Math.min( x, leftEdgeOfMovingTableMinusScrollLeft() + movingAllocated )
      index = getMovingColIndex(x, scrollLeft)
    }
    // In next case, some movingCols are 'out-of-view' under the lockedCols
    // Constrain x to the left edge of moving table, even though greenLine may
    // be well within the lockedTable.
    // In this case ONLY movingTable column swaps are allowed.
    // A draggedMovingCol can NEVER be moved to the lockedTable UNTIL scrollLeft === 0
    else if ( isDraggingMovingCol && scrollLeft > 0 ) {
        x = Math.max( x, movingLeft )
        index = getMovingColIndex( x, scrollLeft ) 
    }
    // In this case the gapBetweenTables belongs to the first moving Col
    else if ( isDraggingMovingCol && scrollLeft === 0 && x > rightEdgeOfLockedTable() ) {
        x = Math.max( x, movingLeft )
        index = getMovingColIndex( x, scrollLeft )
    }
    // In this case, greenLine intrudes on the lockedTable.
    else if ( isDraggingMovingCol && scrollLeft === 0 && x <= rightEdgeOfLockedTable() ) {
        x = Math.max( greenLinePosition, lockedLeft)
        x = Math.min( x, startColLeft[numLockedCols-1] + displayedColWidths[numLockedCols-1] - 5 )
        index = getLockedColIndex( x ) 
    }
    else if (process.env.NODE_ENV !== 'production' ) {
      invariant( false, 'Missing case statement in getDraggedColIndex')
    }
    if ( index !== lastColIndex ) {
      // let maxScrollLeft = widthObj.movingRequired - widthObj.movingAllocated
      lastColIndex = index
    }
    return index
}



///////////////////////////////////////////////////////////////////////////////////////////
//  stateMachine( )
//  
//  We cannot rely on mouse move (handleDndColDrag) to trigger every animation.
//  We cannot rely on mouse up   (handleDndColStop) to end the animation.
//
//  Same thing, said differently:
//  We may need to trigger a colSwap, even though there is no mouse movement.
//  A colSwap animation must run to complettion, even with no mouse movement AND/OR mouse already up!
//  ColSwap animations may get stacked up, worse case: 1 swap in action; and 2 followup swaps pending.
//
//  Worse case example:  scrollLeft === 0; We grap the last on screen (visible) moving column;
//  Quickly drag to the far left of the locked table.
//  Immediately let the mouse up.
//       - Hence DnD mouse activity has finished.
//       - Current animation: Moving col swapped from right side of moving table to first moving col.
//       - Pending animation: First movingCol swapped to lastLockedCol
//       - Pending animation: Last lockedCol swapped to first locked column.      
///////////////////////////////////////////////////////////////////////////////////////////

const stateMachine = ( ) => {
    // On every frame, we potentially increment/decrement the scrollLeft position.
    // But only if mouseDown (global_IsUserDragActive === true).
    // Once mouse is released autoScrolling stops, but active or pending animations WILL continue!
    if ( global_IsUserDragActive ) {
      shiftGreenCursorLine( )
      if ( global_IsDraggingMovingCol ) {
        let velocityPerFrame = getAutoScroll_velocityPerFrame( global_CursorX )
        autoScroll( velocityPerFrame )
      }
    }
    // If a columnSwap animation is active, DO NOT ask nor attempt to start any other colSwap.
    // However, autoScrolling (above) can run concurrently with a colSwap animation.
    if ( global_IsDynamicSwapActive ) { 
      requestAnimationFrame( stateMachine ) 
      return
    }
    // At this point, no animations are active.
    // Based on cursor postion (NOT cursor movement, nor mouse down/up state) we
    // MAY need to initiate another columnSwap.  Hence, final mouseUp does not
    // interfere with swapping the dragged column to the final 'mouseUp' position.
    const didInitiateColSwap = attemptDynamicColSwap()
    if ( didInitiateColSwap ) { 
      requestAnimationFrame( stateMachine ) 
      return
    }
    // Case of no colSwaps in progress; no pendingColSwaps.
    // If still mouseDown, just let the stateMachine continue to run, 
    // including potential autoscrolling.
    if ( global_IsUserDragActive )  { 
      requestAnimationFrame( stateMachine )
      return 
    }
    // EXIT PATH TO SHUT DOWN THE STATE MACHINE !
    // No colSwaps in progress; no pending colSwaps; Mouse is up;
    // Next function should include any/all animation cleanup.
    dispatchFinalScrollLeft( global_ScrollLeft )
}


///////////////////////////////////////////////////////////////////////////////////////////
//   Are current conditions ( underlying table layout, draggedCol, and greenLine location)  
//   proper to initiate a colSwap.  IF yes, then which columns groups to swap?
//
//   ALSO DIFFICULT TO DEBUG !!
//   I find starting at the top 'easy' cases, and inserting an early return is the best for debugging.   
//   This function is called IFF there is no animation currently in progress.
//   This is the sole function that initiates all column swaps (state changes).
///////////////////////////////////////////////////////////////////////////////////////////

const NO_SWAP_false = false
const DID_SWAP_true = true
const DEBUG_CASE = false

// Is the current GreenLine in a location that should trigger a columnSwap (true/false question)?
// When true, then this function also initiates the column swap.
const attemptDynamicColSwap = ( ) : boolean => {

    // This function should never be called in the middle of Dynamic swapping!
    if (global_IsDynamicSwapActive && process.env.NODE_ENV !== 'production') {
      invariant( global_IsColSwapActive, 'During animation, we should never ask if there is a pending ColSwap')
    } else if (global_IsDynamicSwapActive) {
      return true
    }

    const {isCombinedTable, startColLeft, displayedColWidths, movingLeft, numLockedCols} = global_tableComputedData.widthObj 
    const {colOrder, numColsHiddenInGapBetweenTables, scrollLeft} = global_tableComputedData
    const greenLineColIndex = global_GreenLineColIndex
    const greenLineX = global_GreenLineX 
    const draggedColKey = global_DraggedColKey
    const dragColIndex = colOrder.indexOf( draggedColKey )
    const dragGroup : ColGroup = {
      colKey     : draggedColKey, 
      startIndex : getGroupStartIndexFromStopIndex( dragColIndex ),
      stopIndex  : dragColIndex,
      groupWidth : displayedColWidths[dragColIndex]
    }

    // lockedRight MUST be in a form that works for isCombinedTable or not.
    //var lockedRight = lockedLeft + startColLeft[numLockedCols-1] + displayedColWidths[numLockedCols-1] 
    //                             + gapLockedMoving + 2*borderThickness
         
    //if (isCombinedTable) { lockedRight -= global_ScrollLeft }
    const isLastVisibleLockedCol  = ( dragColIndex === get_LastVisibleLockedColIndex( ) )
    const isFirstVisibleMovingCol = ( dragColIndex === get_FirstVisibleMovingColIndex( ) )
    const swapGroup = createSwappingGroup( dragGroup, greenLineColIndex, greenLineX )
    const areNotSameColumn = (dragGroup.stopIndex !== swapGroup.stopIndex)
    const swapColIndex = swapGroup.stopIndex
    const isDragGroupMovingCol = (dragColIndex >= numLockedCols ) || isCombinedTable
    const isSwapGroupMovingCol = (swapColIndex >= numLockedCols ) || isCombinedTable
    const isDragGroupLockedCol = !isDragGroupMovingCol
    const isSwapGroupLockedCol = !isSwapGroupMovingCol
    const areColsFromSameTable = (isDragGroupMovingCol === isSwapGroupMovingCol)
    // Next isDragToRight and isDragToLeft MAY be both false
    const isDragToRight = ( swapColIndex > dragColIndex )  
    const isDragToLeft  = ( swapColIndex < dragColIndex) 
    const numCols = displayedColWidths.length

    // CASE:  DEGENERATE case where user has 'too many' numLockedCols.  Worse case is all columns moved to locked table.
    // Far before we reach this point, the idea of two tables is no longer useful.  And especially if the table is too wide (many cols)
    // such that the user can't make out locked from moving, then the concept of two tables becomes counter-productive.
    // Put a simple fix here to convert all the lockedCols to moving cols.  No effect on what the user
    // sees because the tables are already combined.  Just a way to avoid the degenerate case.
    // When to execute this rule is unclear.  Not necessary until/unless the user wants to work with col order.
    // So put the rule here:
    if ( isCombinedTable && numCols > 10 && numLockedCols / numCols > 0.6 ) {
        dispatchNewColOrderAndScrollLeft( colOrder, 0, global_ScrollLeft )
        // TableWidth calculator will NOT use a combined table when no locked cols.
        // Regardless of how DnD started, we force this DnD operation to 'force' a non-combined table.
        enableForcedCombinedTable( false ) 
        return DID_SWAP_true
    }

    // CASE: Shifting 1st moving column to lockedTable;  WILL skip over any potential columns in gap.
    // Cannot use isDragToLeft, but instead use a tighter criteria of the geometric position to trigger a swap.
    if ( isFirstVisibleMovingCol && greenLineX < rightEdgeOfLockedTable() ) {
        if (DEBUG_CASE) { console.log( 'CASE: 1st MovingCol to LockedCol')}
        let numColsInDraggedGroup = dragGroup.stopIndex - dragGroup.startIndex + 1
        let postSwap_NumLockedCols = numLockedCols + numColsInDraggedGroup
        let newColOrder = colOrder // assumption
        // We choose to skip over any columns hidden in the gap!
        if ( numColsHiddenInGapBetweenTables > 0 ) {
          let hiddenGroup = {
            startIndex : dragGroup.startIndex - numColsHiddenInGapBetweenTables,
            stopIndex  : dragGroup.startIndex - 1,
            groupWidth : 0,  // unused in next func call
            colKey     : 0   // unused in next func call
          }
          newColOrder = getPostSwap_ColOrder( dragGroup, hiddenGroup)
        }
        dispatchNewColOrderAndScrollLeft( newColOrder, postSwap_NumLockedCols, global_ScrollLeft )
        shiftGreenOverlay( )
        return DID_SWAP_true
    }

    // CASE: Shifting last locked column to movingTable; WILL skip over any potential columns in gap.
    // Cannot use isDragToRight, but instead use a tighter criteria of the geometric position to trigger a swap.
    if ( isLastVisibleLockedCol && (greenLineX > rightEdgeOfLockedTablePlusGap()  )) {
        if (DEBUG_CASE) { console.log( 'CASE: last LockedCol to MovingCol')}
        let numColsInDraggedGroup = dragGroup.stopIndex - dragGroup.startIndex + 1
        let postSwap_NumLockedCols = numLockedCols - numColsInDraggedGroup
        // swfmci => 'somewhatVisibleFirstMovingColIndex'
        let swfmci = getGreenLineColIndex( movingLeft, scrollLeft )
        // IF I insert draggedCol 'before' swfmci, then what proportion of the draggedCol will be visible??
        let draggedColWidth = displayedColWidths[ dragGroup.stopIndex ]
        let proportion = (startColLeft[swfmci] - scrollLeft + draggedColWidth) / draggedColWidth
        // If at least 1/2 of dragged column will be visible insert 'before swfmci', else insert after swfmci.
        let newColIndex = ( proportion > 0.5 ) ? swfmci - 1 : swfmci
        let swapGroup = {
          startIndex : dragGroup.stopIndex + 1,
          stopIndex  : newColIndex,
          groupWidth : 0,  // unused in next func call
          colKey     : 0   // unused in next func call
        }
        let postSwap_ColOrder = getPostSwap_ColOrder( dragGroup, swapGroup)
        dispatchNewColOrderAndScrollLeft( postSwap_ColOrder, postSwap_NumLockedCols, global_ScrollLeft )
        shiftGreenOverlay( )
        return DID_SWAP_true 
    }

    // CASE: isCombinedTable; any col dragged right
    // CASE: MovingCol dragged right to movingCol
    // CASE: LockedCol dragged right to lockedCol
    // NOT isLastVisibleLockedCol
    if ( areColsFromSameTable && isDragToRight && areNotSameColumn && !isLastVisibleLockedCol ) {
        if (DEBUG_CASE) { console.log( 'CASE: Animated Right Shift')}
        swapGroup.startIndex =  dragGroup.stopIndex + 1
        swapGroup.groupWidth =  getGroupWidth( swapGroup.startIndex, swapGroup.stopIndex )
        dynamicSwapColumns(dragGroup, swapGroup) 
        return DID_SWAP_true
    }

    // CASE: isCombinedTable; any col dragged left
    // CASE: MovingCol dragged left to movingCol
    // CASE: LockedCol dragged left to lockedCol
    // Not isFirstVisibleMovingCol
    if ( areColsFromSameTable && isDragToLeft && areNotSameColumn && !isFirstVisibleMovingCol ) {
        if (DEBUG_CASE) { console.log( 'CASE: Animated Left Shift')}
        swapGroup.stopIndex = dragGroup.startIndex - 1
        swapGroup.groupWidth =  getGroupWidth( swapGroup.startIndex, swapGroup.stopIndex )         
        dynamicSwapColumns(dragGroup, swapGroup) 
        return DID_SWAP_true
    }

    // CASE: Dragging a LockedCol to movingTable. ( excluding lastVisibleLockedCol case above) 
    // ONLY shift this column as far as the lastLockedCol position.  
    // (At that time if may switch tables to the moving group, then continue shifting 'rightward'.
    // Not isLastVisibleMovingCol
    if ( isDragGroupLockedCol && !isLastVisibleLockedCol && isSwapGroupMovingCol ) {
        if (DEBUG_CASE) { console.log( 'CASE: LockedCol to Last LockedCol')}
        // set the swapGroup to all following lockedCols, EXCEPT those hidden in gap between tables.
        swapGroup.stopIndex = numLockedCols - numColsHiddenInGapBetweenTables - 1
        swapGroup.startIndex = dragGroup.stopIndex + 1
        swapGroup.groupWidth =  getGroupWidth( swapGroup.startIndex, swapGroup.stopIndex )
        dynamicSwapColumns(dragGroup, swapGroup) 
        return DID_SWAP_true
    }

    // CASE: Dragging a MovingCol to lockedTable. ( excluding firstVisibleMovingCol case above) 
    // ONLY shift this column as far as the firstMovingCol position.  
    // (At that time if may switch tables to the locked group, then continue shifting 'leftward'.
    if ( isDragGroupMovingCol && !isFirstVisibleMovingCol && isSwapGroupLockedCol ) {
        if (DEBUG_CASE) { console.log( 'CASE: MovingCol to First MovingCol')}
        // set the swapGroup to all preceeding moving columns
        swapGroup.startIndex = numLockedCols
        swapGroup.stopIndex  = dragGroup.startIndex - 1
        swapGroup.groupWidth =  getGroupWidth( swapGroup.startIndex, swapGroup.stopIndex )
        dynamicSwapColumns(dragGroup, swapGroup) 
        return DID_SWAP_true
    }

    return NO_SWAP_false
}


/////////////////////////////////////////////////////////////////////////////////////////////
//   These functions create the draggedGroup and swappingGroup.
//   Each is a set of 'contiguous columns', with a start/stop sequence of colIndices
//   There is never any space beween the dragged/swapping groups.
//   A column swap fundamentally means swapping these two groups in colOrder[]
/////////////////////////////////////////////////////////////////////////////////////////////


const getGroupWidth = ( startIndex: number, stopIndex: number ) : number => {
    const {displayedColWidths, startColLeft} = global_tableComputedData.widthObj
    return startColLeft[stopIndex ] - startColLeft[startIndex] + displayedColWidths[stopIndex]
}
const createSwappingGroup = ( draggedGroup: ColGroup, activeColIndex: number, greenLineX: number ) : ColGroup => {
    const {numLockedCols, displayedColWidths, movingLeft, lockedLeft, 
             startColLeft, isCombinedTable } = global_tableComputedData.widthObj
    const {isHidden_ByColIndex, colOrder} = global_tableComputedData
    const { stopIndex: draggedColIndex } = draggedGroup
    // Define the 'activeColIndex as the location of the GreenLine.
    // It MAY be the same as the swapped column, but this depends on whether the 
    // cursor as penetrated far enough into the column to trigger a colSwap.
    // Hence the column we may wish to swap with could be:
    //     - If cursor is right of the dragged col, swapped column is either the greenLine column or preceeding col
    //     - If cursor is left  of the dragged col, swapped column is either the greenLine column or subsequent col
    if ( activeColIndex === draggedColIndex ) { return {...draggedGroup} }   // Early return
    //const isDraggedGroupLocked = draggedGroup.stopIndex < numLockedCols
    //const isDraggedGroupMoving = !isDraggedGroupLocked
    const lockedX   = greenLineX - lockedLeft  // The cursor position with respect to left edge of lockedCols
    const movingX   = greenLineX - movingLeft + global_ScrollLeft // The cursor position wwith respect to left edge of scrolled table
    const combinedX = greenLineX - lockedLeft + global_ScrollLeft // The cursor position with respect to left edge of combined table
    const isGreenLineOverCombinedTable = isCombinedTable
    const isGreenLineOverLockedTable   = activeColIndex < numLockedCols
    const isGreenLineOverMovingTable   = !isGreenLineOverLockedTable
    // Identify the colIndex under the GreenLine, 
    // as well as the position of greenLine within that column (between 0 to colWidth).
    var cursorLocation_within_swappingCol = 0
    if      (isGreenLineOverCombinedTable) { cursorLocation_within_swappingCol = combinedX - startColLeft[activeColIndex] }
    else if ( isGreenLineOverLockedTable ) { cursorLocation_within_swappingCol = lockedX   - startColLeft[activeColIndex] }
    else if ( isGreenLineOverMovingTable ) { cursorLocation_within_swappingCol = movingX   - startColLeft[activeColIndex] }
    // Calc the trigger location within the activeColIndex:
    var swappingColWidth = displayedColWidths[activeColIndex]
    var draggedColWidth  = displayedColWidths[draggedColIndex]
    const triggeringWidth = Math.max( swappingColWidth/2, swappingColWidth - draggedColWidth )
    // CASE: DRAGGING TO THE RIGHT  greenLineX 'right' of the dragged group
    //console.log( 'draggedColIndex in getSwapGroup', activeColIndex )
    if ( activeColIndex > draggedGroup.stopIndex ) {
        let xTrigger = triggeringWidth + 2
        let swapColIndex = activeColIndex   // assumption we will be swapping the greenLine column
        if ( cursorLocation_within_swappingCol <= xTrigger ) { 
          swapColIndex = backupToNextVisibleColIndex( swapColIndex, isHidden_ByColIndex)
        }
        var swapGroup = { 
          colKey: colOrder[swapColIndex],
          startIndex : getGroupStartIndexFromStopIndex(swapColIndex),
          stopIndex  : swapColIndex,
          groupWidth : displayedColWidths[swapColIndex],
        } 
    } else {
        // CASE: DRAGGING TO THE LEFT  Green cursor line 'left' of the dragged group
        let xTrigger = swappingColWidth - triggeringWidth - 2
        let swapColIndex = activeColIndex   // assumption we will be swapping the greenLine column
        if ( cursorLocation_within_swappingCol >= xTrigger ) { 
          swapColIndex = forwardToNextVisibleColIndex( swapColIndex, isHidden_ByColIndex)
        } 
        swapGroup = { 
          colKey: colOrder[swapColIndex],
          startIndex : getGroupStartIndexFromStopIndex(swapColIndex),
          stopIndex  : swapColIndex,
          groupWidth : displayedColWidths[swapColIndex],
        }
    }
    return swapGroup
}
const backupToNextVisibleColIndex = ( greenLineColIndex : number, isHidden_ByColIndex: boolean[] ) : number => {
    // THIS FUNCTION IGNORES THE TRANSISTION BETWEEN LOCKED AND MOVING COLUMNS.
    // It will find the next visible index or stop at the colIndex = 0
    // This is OK because the usage assumes we are always moving in the direction of the draggedColIndex
    var answer = greenLineColIndex - 1  // assumption
    while ( answer >= 0 && isHidden_ByColIndex[answer] ) { answer-- }
    return answer
}
const forwardToNextVisibleColIndex = ( greenLineColIndex : number, isHidden_ByColIndex: boolean[] ) : number => {
    // THIS FUNCTION IGNORES THE TRANSISTION BETWEEN LOCKED AND MOVING COLUMNS.
    // It will find the next visible index or stop at the last colIndex
    // This is OK because the usage assumes we are always moving in the direction of the draggedColIndex
    var answer = greenLineColIndex + 1  // assumption
    const numCols = isHidden_ByColIndex.length
    while ( answer < numCols && isHidden_ByColIndex[answer] ) { answer++ }
    return answer
}
const getGroupStartIndexFromStopIndex = (stopIndex : number) : number => {
    // Hidden columns belonging to the parent (visible) column are considered part of the drag/swap group.
    // We want to 'backup' from this visible dragged or swapped column, to include potential hidden
    // columns that preceed it.
    const {isHidden_ByColIndex} = global_tableComputedData
    const {isCombinedTable, numLockedCols} = global_tableComputedData.widthObj
    var   startIndex = stopIndex  // Assuming no column 'hidden' under this column
    const worseCaseStartIndex = (isCombinedTable || startIndex < numLockedCols) ? 0 : numLockedCols
    // Backup (decrement startIndex), adding additional 'hidden' columns to this dragged set.
    while ( startIndex !== worseCaseStartIndex && isHidden_ByColIndex[startIndex-1] ) { startIndex -- }
    return startIndex
}
const getPostSwap_ColOrder = ( drag : ColGroup, swap : ColGroup) : number[] => {
    const {colOrder} = global_tableComputedData
    // rename groupA and groupB to firstGroup and secondGroup, by proper sequential order!
    // The swap group must allways be extended to include all columns between drag and swap!
    if (drag.startIndex < swap.startIndex) {
      var leftGroup = {...drag}
      var rghtGroup = {...swap}
    } else {
      leftGroup = {...swap}
      rghtGroup = {...drag}
    }
    const  pre_Subset = colOrder.slice( 0, leftGroup.startIndex)
    const  leftSubset = colOrder.slice( leftGroup.startIndex, leftGroup.stopIndex + 1)
    const  rghtSubset = colOrder.slice( rghtGroup.startIndex, rghtGroup.stopIndex + 1)
    const  postSubset = colOrder.slice( rghtGroup.stopIndex + 1)
    const  newOrder = pre_Subset.concat( rghtSubset, leftSubset, postSubset )
    const  hasDuplicates = (newOrder : number[] ) : boolean => { return newOrder.length !== new Set(newOrder).size }
    const  hasLengthChange = newOrder.length !== colOrder.length
    if ( process.env.NODE_ENV !== 'production' && (hasDuplicates(newOrder) || hasLengthChange)) {
      //console.log ( drag, swap )
      invariant( false, `Col Order has duplicates: ${colOrder} => ${newOrder}` )
    } 
    return newOrder
}


/////////////////////////////////////////////////////
//   Auto Scrolling functions 
/////////////////////////////////////////////////////

var lastAutoScrollLeft = 0

const autoScroll = ( velocityPerFrame : number ) => {
    const {movingAllocated, movingRequired} = global_tableComputedData.widthObj
    global_ScrollLeft += velocityPerFrame
    global_ScrollLeft  = Math.min( global_ScrollLeft, movingRequired - movingAllocated )
    global_ScrollLeft  = Math.max( global_ScrollLeft, 0 )
    if (global_ScrollLeft === lastAutoScrollLeft) {
      // Easier to debug if we don't make updates unless scrollLeft changes. 
      return 
    }
    lastAutoScrollLeft = global_ScrollLeft
    global_GreenLineX = getGreenLineX( global_CursorX, global_ScrollLeft )
    global_GreenLineColIndex = getGreenLineColIndex( global_GreenLineX, global_ScrollLeft ) 
    synchScrollLeft( global_ScrollLeft, movingAllocated, movingRequired )
    shiftGreenOverlay( )
}
const getAutoScroll_velocityPerFrame = ( cursorX : number ) : number => {
    const { movingAllocated, movingLeft, movingRequired} = global_tableComputedData.widthObj
    const leftMovingAutoScrollThreshold  = movingLeft + AUTOSCROLLING_THRESHOLD_BEGINS_AT_PX
    const rightMovingAutoScrollThreshold = movingLeft + movingAllocated - AUTOSCROLLING_THRESHOLD_BEGINS_AT_PX
    if ( blueLineLeftDiv ) { blueLineLeftDiv.style.left  = px(leftMovingAutoScrollThreshold) }
    if ( blueLineRightDiv) { blueLineRightDiv.style.left = px(rightMovingAutoScrollThreshold) }
    const range = 40   // e.g.  range of 40 pixels means the scroll accelates from zero to max over mouse cursor range of 40
    const velocity = 3 // pixels per frame are: range / velocity
    // What I see seems slower than above calc.  But no matter -- two degrees of freedom to adjust autoscrolling.
    if ( cursorX > rightMovingAutoScrollThreshold && global_ScrollLeft < movingRequired - movingAllocated ) {
      // ScrollBar to right, positive delta scroll per frame.
      return   +(Math.min(cursorX - rightMovingAutoScrollThreshold, range) ) / velocity
    }
    if ( cursorX < leftMovingAutoScrollThreshold && global_ScrollLeft > 0 ) {
      // ScrollBar to left, negative delta scroll per frame.
      return   -(Math.min(leftMovingAutoScrollThreshold - cursorX, range) ) / velocity
    }
    return 0
}

////////////////////////////////////////////////////////////////////////////////////
//   DYNAMIC Column Swapping Animation
//   This is NOT used when shifting the draggedCol group between locked/moving tables.
////////////////////////////////////////////////////////////////////////////////////

type ColDomNodes = {colIndex:number, node:DomNode}

type ColSwapAnimationObj = {
  leftColTranslate: number
  rghtColTranslate: number
}


const dynamicSwapColumns = ( draggedGroup : ColGroup , swappingGroup: ColGroup ) : void => {
    global_IsDynamicSwapActive = true
    const { colOrder, tableDomNodes, heightObj, widthObj } = global_tableComputedData
    const {numRowGroups} = heightObj
    const {startColLeft, numLockedCols, isCombinedTable} = widthObj
    // Case of isCombinedTable:  EVERY colSwap uses this dynamic animation!
    // IF the dragged col starts locked, but ends moving, then numlockedCols++
    // IF the dragged col starts moving, but ends locked, then numlockedCols--
    // Normally we don't need to worry about this because any col swap from 
    // locked <==> moving does NOT use this animation, and the code to
    // to shift the column between tables includes the appropriate adjustment
    // to numLockeCols.  But when isCombinedTable, this function does
    // all col swaps.  Therefore, we need to do the bookkeeping here:
    var newNumLockedCols = numLockedCols   // assumption
    if ( isCombinedTable ) {
      if (draggedGroup.stopIndex  >= numLockedCols &&
          swappingGroup.stopIndex <  numLockedCols ) { newNumLockedCols++ }
      if (draggedGroup.stopIndex  <  numLockedCols &&
          swappingGroup.stopIndex >= numLockedCols ) { newNumLockedCols-- }
    }
    // Switch to a left/rght nomenclature
    const isDraggedGroupOnLeft = draggedGroup.startIndex < swappingGroup.startIndex
    const leftGroup : ColGroup = (isDraggedGroupOnLeft) ?  draggedGroup : swappingGroup
    const rghtGroup : ColGroup = (isDraggedGroupOnLeft) ? swappingGroup :  draggedGroup
    // Create an array of DomNodes that will be left/rght translated, any order is OK.
    // And we can mix together the Head DomNodes and the Data DomNodes
    // because they animate with identical opacity and translations.
    const {columnsHead, columnsData} = tableDomNodes
    const leftGroupDomNodes : ColDomNodes[] = []
    for ( let i=leftGroup.startIndex; i<=leftGroup.stopIndex; i++ ) {
      let thisColKey : number = colOrder[i]
      leftGroupDomNodes.push( {colIndex:i, node:columnsHead[thisColKey]} )
      for ( let j=0; j< numRowGroups; j++ ) {
        leftGroupDomNodes.push( {colIndex:i, node: columnsData[j][thisColKey]} )
      }
    }

    const rghtGroupDomNodes : ColDomNodes[] = []
    for ( let i=rghtGroup.startIndex; i<=rghtGroup.stopIndex; i++ ) {
      let thisColKey : number = colOrder[i]
      rghtGroupDomNodes.push( {colIndex:i, node:columnsHead[thisColKey]} )
      for ( let j=0; j< heightObj.numRowGroups; j++ ) {
        rghtGroupDomNodes.push( {colIndex:i, node:columnsData[j][thisColKey]} )
      }
    }
    // The distance a colShifts === the width of the 'opposing' column!
    const leftTotalShift  = +rghtGroup.groupWidth
    const rghtTotalShift  = -leftGroup.groupWidth

    dynamics.animate (
        { leftColTranslate: 0,               rghtColTranslate: 0},
        { leftColTranslate: leftTotalShift,  rghtColTranslate: rghtTotalShift},

        { // Options:
          change: (obj : ColSwapAnimationObj)=> {
            var xR = obj.rghtColTranslate 
            var xL = obj.leftColTranslate
            // Left 'greenish' column passes to the rght, 'over' rght column
            if ( isDraggedGroupOnLeft ) {
                for ( const thisObj of rghtGroupDomNodes ) {
                  let {colIndex, node} = thisObj
                  let newX = startColLeft[colIndex] + xR
                  if (node) { node.style.transform = `translate(${newX}px, 0px)` }
                }
                for ( const thisObj of leftGroupDomNodes ) {
                  let {colIndex, node} = thisObj
                  let newX = startColLeft[colIndex] + xL
                  if (node) { node.style.zIndex = '1'}
                  if (node) { node.style.transform = `translate(${newX}px, 0px)` }
                }
                if (greenDynamicSwapDiv) {
                  greenDynamicSwapDiv.style.transform = `translate(${xL}px, 0px)`
                }
            }
            // Right 'greenish' column passes to the left, 'over' top of left column
            if ( !isDraggedGroupOnLeft ) {
                for ( const thisObj of leftGroupDomNodes ) {
                  let {colIndex, node} = thisObj
                  let newX = startColLeft[colIndex] + xL
                  if (node) { node.style.transform = `translate(${newX}px, 0px)` }
                }
                for ( const thisObj of rghtGroupDomNodes ) {
                  let {colIndex, node} = thisObj
                  let newX = startColLeft[colIndex] + xR
                  if (node) { node.style.zIndex = '1'}
                  if (node) { node.style.transform = `translate(${newX}px, 0px)` }
                }
                if (greenDynamicSwapDiv) {
                  greenDynamicSwapDiv.style.transform = `translate(${xR}px, 0px)`
                }
            }
          },

          complete:( ) => {
              global_IsDynamicSwapActive = false
              for ( const thisObj of rghtGroupDomNodes ) {
                let {node} = thisObj
                if (node) { node.style.zIndex = '' }
                if (node) { node.style.transform = `translate(0px, 0px)` }
              }
              for ( const thisObj of leftGroupDomNodes ) {
                let {node} = thisObj
                if (node) { node.style.zIndex = '' }
                if (node) { node.style.transform = `translate(0px, 0px)` }
              }
              let newColOrder = getPostSwap_ColOrder( draggedGroup, swappingGroup )
              dispatchNewColOrderAndScrollLeft( newColOrder, newNumLockedCols, global_ScrollLeft )
              if (greenDynamicSwapDiv && greenScrollLeftDiv) {
                greenDynamicSwapDiv.style.transform = `translate(0px, 0px)`
              }
              //global_DraggedColKey = draggedGroup.colKey
              shiftGreenOverlay( )
          },
          duration: 400
        }
    )
}


// Anytime we do colSwap or changes tables, this is the react state change.
// Currently (April, 2024) colDnD is the only animation that includes reactRenders as part of the animation.
const dispatchNewColOrderAndScrollLeft = ( newColOrder: number[], newNumLockedCols: number, newScrollLeft: number ): void => {
    // Always error check colOrder before pushing to resource.
    // Once it is bad in the resource, pain in the butt to repair!
    const {derivedColAttributesArray} = global_tableComputedData
    const isDeletedArr = derivedColAttributesArray.map( c => c.isDeleted )
    const colTitleArr  = derivedColAttributesArray.map( c => c.colTitle )
    errorCheckColOrder( isDeletedArr, newColOrder, colTitleArr )
    let mods = [{ newVal: newColOrder, path: `attributes.colOrder` },
                { newVal: newNumLockedCols, path: `attributes.numLockedCols` }]
    reactDispatch( mods, 'Column drag&drop', global_ActionGroup )  
    let sessionMods = [{newVal: newScrollLeft, path: 'activeTableScrollLeft'}]
    sessionStateChangeDispatch( sessionMods, global_ActionGroup )
    const {isCombinedTable} = global_tableComputedData.widthObj
    // Note that the draggedColKey never changes throughout the animation.
    // However, the draggedColIndex does change, and whether the draggedCol is
    // currently in the locked or moving table does change.
    global_IsDraggingMovingCol = !( global_GreenLineColIndex < newNumLockedCols ) || isCombinedTable

}


// AFTER handleDragStop
// AFTER dynamic animations finishes
// AFTER we ask attemptDynamicColSwap if there are any more swaps pending
// THEN this is the final cleanup.
// 
// This function will force one final re-render. Needed because
// when we disableForcedCombinedTable(), then the state
// of isCombinedTable MAY change.  We locked this state 
// at beginning of DnD so isCombinedTable doesn't toggle during animation
// However, after DnD, the new table layout MAY wish to flip the state of isCombinedTable.
const dispatchFinalScrollLeft = (newScrollLeft : number ): void => {
    shiftGreenOverlayOffscreen() 
    shiftGreenCursorLineOffscreen()
    disableForcedCombinedTable()
    let newRenderIndex = global_tableComputedData.sessionStateRenderIndex + 1
    let sessionMods = [
      {newVal: newScrollLeft,  path: 'activeTableScrollLeft'},
      {newVal: newRenderIndex, path: 'renderIndex'}
    ]
    sessionStateChangeDispatch( sessionMods, global_ActionGroup)
    global_HideToolTip( false )
}
