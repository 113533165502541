import {list} from 'radash'
import {PureComponent, Component} from 'react'
import type {TableValues} from '../types'
import type {TableComputedData, DomNode, CellHTML}  from '../computedDataTable/getDefaultTableComputedData'
import type {FormattingObj} from '../sharedFunctions/numberFormat'
import type { DerivedColAttributes } from '../computedDataTable/getDefaultTableComputedData'




import {LINK_COLOR, nonBreakingSpace} from '../sharedComponents/constants'



type RowGroupProps = {
  tableComputedData : TableComputedData,
  sortedRowKeys : number[],
  startColLeft : number[],
  displayedColWidths : number[],
  firstVisibleLockedCol : number,
  firstVisibleMovingCol : number,
  startColIndex : number,
  stopColIndex : number,
  isBrightField : boolean,
  isCombinedTable : boolean,
  rowGroupIndex: number,
  pinnedRowKeys : number[] | null | undefined,
  backgroundEven : string,
  backgroundOdd : string,
  gridHorzColor : string,
  gridVertColor : string,
  cellBottomOffset : number,
  firstRowIndex : number,
  rowsPerGroup : number,
  rowHeight : number,
  derivedColAttributesArray: DerivedColAttributes[],
  colOrder: number[],
  tableValues: TableValues // need this prop to retrigger display if any cell values change
}
export class RowGroup extends Component<RowGroupProps> {

  rowGroupColumnRefFunc = (element: DomNode, colKey:number, rowGroupIndex: number):void=> {
    const {columnsData} = this.props.tableComputedData.tableDomNodes
    if ( !columnsData[rowGroupIndex] ) { columnsData[rowGroupIndex] = [] }
    columnsData[rowGroupIndex][colKey] = element
  }

  render( ) {
    const {rowsPerGroup, derivedColAttributesArray,
      sortedRowKeys, startColIndex, stopColIndex, startColLeft,
      displayedColWidths, colOrder, isCombinedTable, isBrightField,
      rowHeight, backgroundEven, backgroundOdd, gridHorzColor, gridVertColor,
      firstVisibleLockedCol, firstVisibleMovingCol, rowGroupIndex,
      pinnedRowKeys, firstRowIndex, cellBottomOffset, tableComputedData } = this.props
      const { getCellHTML_fromColKeyAndRowKey, getCellHTML_fromColKeyAndRowIndex } = tableComputedData
    return (
      
      <>
        {list(startColIndex,stopColIndex).map( colIndex=>{
          var colKey = colOrder[colIndex]
          var leftPosition = startColLeft[colIndex]
          var colWidth = displayedColWidths[colIndex]
          const {isMissingCol, isDeleted, internalDataType, formattingObj} = derivedColAttributesArray[colKey]
          if ( isDeleted ) { return null } 
          const isFirstVisibleCol = (firstVisibleLockedCol === colIndex || firstVisibleMovingCol === colIndex)
          var borderLeftWidth = isFirstVisibleCol ? 0 : 1
          // Use a heavy border between locked/moving columns (in case of combined table)
          var borderLeftColor = gridVertColor
          if (isCombinedTable && firstVisibleMovingCol === colIndex ) {
            borderLeftColor = isBrightField ? 'black' : 'white'
            borderLeftWidth = 4
          }

          return (

              <div className={'ColumnContainer'}
              ref={ (n)=>this.rowGroupColumnRefFunc(n,colKey,rowGroupIndex) }
              key={`${colIndex}_${colKey}`}
              style={{ position:'absolute', width:colWidth, height:'100%',
              transform: `translate(${leftPosition}px, 0px`,
              boxSizing: 'border-box', borderWidth: `0px 0px 0px ${borderLeftWidth}px`,
              borderLeftColor,
              borderStyle: 'solid',
              overflow:'hidden',  // Hides the text in underlying hidden columns
              }} >

                  <ColumnData
                  getCellHTML_fromColKeyAndRowKey={getCellHTML_fromColKeyAndRowKey} 
                  getCellHTML_fromColKeyAndRowIndex={getCellHTML_fromColKeyAndRowIndex}
                  formattingObj={formattingObj}
                  sortedRowKeys={sortedRowKeys}
                  colKey={colKey}
                  internalDataType={internalDataType}
                  pinnedRowKeys={pinnedRowKeys}
                  isMissingCol={isMissingCol}
                  backgroundEven={backgroundEven}
                  backgroundOdd={backgroundOdd}
                  gridHorzColor={gridHorzColor}
                  bottomOffset={cellBottomOffset}
                  firstRowIndex={firstRowIndex}
                  rowsPerGroup={rowsPerGroup}
                  rowHeight={rowHeight}
                  />

            </div>
        )})}
    </>
)}}



type ColumnDataProps = {
  // These params are not directly used.
  // They are implicitely used when we retrieve table values.
  // A change to any one of these will force a call to cellRender
  // for every cell in this column.
  formattingObj: FormattingObj,
  sortedRowKeys : number[],
  getCellHTML_fromColKeyAndRowKey   : { (colKey  : number, rowKey:   number) : CellHTML }, 
  getCellHTML_fromColKeyAndRowIndex : { (colKey  : number, rowIndex: number) : CellHTML },
  colKey : number,
  internalDataType : string,
  pinnedRowKeys : number[] | null | undefined,
  isMissingCol : boolean,
  backgroundEven : string,
  backgroundOdd : string,
  gridHorzColor : string,
  bottomOffset : number,
  firstRowIndex : number,
  rowsPerGroup : number,
  rowHeight : number,
}
export class ColumnData extends Component<ColumnDataProps> {

  render( ) {
    //console.log ( 'Render ColumnData' )
    const {colKey, rowsPerGroup, backgroundOdd, internalDataType,
      rowHeight, backgroundEven, gridHorzColor, isMissingCol,
      pinnedRowKeys, firstRowIndex, bottomOffset,
      getCellHTML_fromColKeyAndRowKey,  
      getCellHTML_fromColKeyAndRowIndex} = this.props
    return (
      <>

          { list (rowsPerGroup - 1).map( i=>{   // list returns an array 0 to n, length n+1
            var rowIndex  = firstRowIndex + i
            var value='', isErroneous=false, isMissingRowName=false
            var valueColor = 'unset'
            if ( isMissingCol ) {
              value = ''
              isErroneous = true
            } else if ( pinnedRowKeys ) {
              ({value, isErroneous, isMissingRowName} = getCellHTML_fromColKeyAndRowKey( colKey, pinnedRowKeys[i] ))
            } else {
              ({value, isErroneous, isMissingRowName} = getCellHTML_fromColKeyAndRowIndex( colKey, rowIndex ))
            }
            var leftRightAligned : 'flex-end' | 'flex-start' = 
                             ( internalDataType === 'number' ) ? 'flex-end' : 'flex-start'
            var background = (rowIndex % 2 === 0) ? backgroundEven: backgroundOdd
            if (isErroneous && value !== '' ) { valueColor = 'red' }
            if (isMissingRowName) {
              valueColor = 'red'
              value = nonBreakingSpace.repeat(4) + 'Missing RowName'
            }

            return(
                <DataCellRender
                  key={rowIndex}
                  rowHeight={rowHeight}
                  bottomOffset={bottomOffset}
                  value={value}
                  valueColor={valueColor}
                  background={background}
                  leftRightAligned={leftRightAligned}
                  gridHorzColor={gridHorzColor}
                  internalDataType={internalDataType}/>
            )
          })}
      </>
)}}




type DataCellRenderProps = {
  rowHeight: number,
  bottomOffset: number,
  value:string,
  valueColor: string,
  background: string,
  leftRightAligned: 'flex-end' | 'flex-start',
  gridHorzColor: string,
  internalDataType: string,
}
class DataCellRender extends PureComponent<DataCellRenderProps> {
  render( ) {
    //console.log ( '     DataCellRender')
    const { rowHeight, bottomOffset, value, valueColor, background,
      leftRightAligned, gridHorzColor, internalDataType } = this.props
    //console.log( 'DataCell Render' )

    // Links with no errors are link-color.  The link is only text.
    // NOT an actual <a> linkstuff </a>
    const textColor = ( internalDataType === 'hyperlink' && valueColor !== 'red' )
      ? LINK_COLOR
      : valueColor
    const dataCellStyle : React.CSSProperties = {
        position: 'relative', 
        display: 'flex', alignItems: 'flex-end',
        //textAlign: 'right',
        width: '100%', height: rowHeight,
        boxSizing: 'border-box', borderWidth: `0px 0px 1px 0px`,
        borderStyle: 'solid', borderBottomColor: gridHorzColor,
        justifyContent: leftRightAligned,  
        background, color:textColor,
        paddingBottom:bottomOffset, paddingLeft:5, paddingRight:5,
    }

    if (internalDataType === 'number' && value.slice(0,6) === '<span>' ) {
      return (
        <div className={'rc_DataCell'} style={dataCellStyle}
          dangerouslySetInnerHTML={{ __html: value }} />
      )
    }

    return (
      <div className={'rc_DataCell'} style={dataCellStyle}>
          {value}
      </div>
    )
}}
