import type { ReactNode } from 'react'
import type { DraggableData, DraggableEvent } from 'react-draggable'

import {Component} from 'react'
import {DraggableCore}        from 'react-draggable'
//import type {DraggableData}   from '../types'
import rStateFloatingPalette  from './rStateFloatingPalette'

const DRAG_CONTROL_SIZE = 20


type Props = {
  dispatchReactRepositionOrResize: ( x: number | null, y: number | null, delta: number | null, deltaY: number | null ) => void,
  setIsOnSizeActive: ( flag:boolean ) => void,
  onActiveResize: ( deltaLeft: number, deltaTop: number ) => void,
  children: ReactNode,
}

export default class EditCellResizableRender extends Component<Props> {

  // All these states used for responsive dragging.
  // NOT react states.
  mousePositionStartX : number = 0
  mousePositionStartY : number = 0
  mostRecentPositionX : number = 0
  mostRecentPositionY : number = 0
  isActiveRAF : boolean = false

/*
  What is e.screenX and e.screenY ???

  The mouse event has a lot of information on current (x,y) mouse position.
  Almost always we are interested in the mouse movement with respect to
  the parent container.  For this common case, the e.position attribute includes
  a e.position.x and e.position.y which is position with respect resizable
  // container (upper left corner).

  HOWEVER, we allow the resize container to also be constrained within a
  rectangular boundary.  If we try to resize beyond this boundary, we want
  to still allow sizing, but with an additional constraint that the edge does
  not go out of bounds.

  For example, the floating palette is positioned at the far right extreme
  position.  We drag 10px to the right.  But the right edge must stay
  fixed (in bounds).  No problem -- we just shift the left edge of the
  floating pallete leftward by by 10px.  Hence the palette size is still
  linearly tied to the mouse position.  Seems unnatural since mouse is moving
  to the right, but works well in practice.

  So, how do we calculate the new width in above example?  Suppose e.position.x = 10px.
    - Right edge of palette cannot move left as per the position constrains.
    - Left edge of palette will move to right to satisfy the 10px wider width.
    - e.position.x is now 20px to the right of the upper left coord of the floating palette.
  So deltaMouse position is now 20px -- which will be the request on the next frame!
  UNSTABLE SYSTEM!

  Easy to fix.  Simply find a coord system where we reference the shift in
  the mouse position to something that is NOT tied to the floating palette
  position.  Hence we use e.screenX

*/

  handleDragStart = (e: DraggableEvent, data: DraggableData): void | false => {
    e.preventDefault()
    e.stopPropagation()  
    const {screenX, screenY} = e as MouseEvent
    if (screenX === undefined || screenY === undefined) {
      return
    }
    this.mousePositionStartX = screenX
    this.mousePositionStartY = screenY
    this.mostRecentPositionX = screenX
    this.mostRecentPositionY = screenY
    this.props.setIsOnSizeActive( false )
    rStateFloatingPalette.synchStart( )
  }

// This module does NOT need throttling code.
// Left it here in case I find some exception, but believe throttling
// is being done internal to library.
// Hence, one can delete isActiveRAF and associated throttling .

  handleDrag = (e: DraggableEvent, data: DraggableData): void | false => {
    e.preventDefault()  // Otherwise dragging outside of touchScroll area will cause auto-scrolling.
    e.stopPropagation() 
    const {screenX, screenY} = e as MouseEvent
    if (screenX === undefined || screenY === undefined) {
      return
    }
    if ( this.mostRecentPositionX === screenX && this.mostRecentPositionY === screenY ) return
    this.mostRecentPositionX = screenX
    this.mostRecentPositionY = screenY
    if ( !this.isActiveRAF ) {
      window.requestAnimationFrame( ()=>this.throttledDrag(e) )
      this.isActiveRAF = true
    }
  }

  throttledDrag = (e: DraggableEvent) : void => {
    const {screenX, screenY} = e as MouseEvent
    if (screenX === undefined || screenY === undefined) {
      return
    }
    const deltaMouseX = screenX - this.mousePositionStartX
    const deltaMouseY = screenY - this.mousePositionStartY
    this.props.onActiveResize( deltaMouseX, deltaMouseY )
    this.isActiveRAF = false
  }

  handleDragStop = (e: DraggableEvent, data: DraggableData): void | false => {
    e.preventDefault()
    e.stopPropagation()
    const {screenX, screenY} = e as MouseEvent
    if (screenX === undefined || screenY === undefined) {
      return
    }
    const deltaMouseX = screenX - this.mousePositionStartX
    const deltaMouseY = screenY - this.mousePositionStartY
    this.props.setIsOnSizeActive( true )
    rStateFloatingPalette.synchStop( )
    this.props.dispatchReactRepositionOrResize( null, null, deltaMouseX, deltaMouseY )
  }

 // stopPropagation = (e: SyntheticMouseEvent<HTMLDivElement>): void => {
  //  e.stopPropagation()
  //}

 // preventDefault = (e: SyntheticMouseEvent<HTMLDivElement>): void => {
 //   e.preventDefault()
 // }

  render() {
    //console.log( 'renderingEditCellResizable' )
    return (
      <div className={'rc_EditCellResizable'}
        style={{width:'100%',
          height:'100%'
        }}
        >

          <div className={'SizedContainerForTextArea'}
            style={{
              position: 'relative', top:0, left:0,
              boxSizing:'border-box',
              width:'100%',
              height:'100%',
              //background: 'pink', //constants.FLOATING_PALETTE_BACKGROUND,
            }}
          > {this.props.children} </div>



                <DraggableCore handle='.draggableDiv'
                  enableUserSelectHack={false}
                  onStart={ (e,data) => this.handleDragStart( e, data )}
                  onDrag ={ (e,data) => this.handleDrag( e, data )}
                  onStop ={ (e,data) => this.handleDragStop( e, data )}
                  disabled={false}
                >

                  <div
                    className={'draggableDiv'}
                    style={{
                      position: 'absolute',
                      right: 0, bottom:0,
                      background: 'transparent',
                      width: DRAG_CONTROL_SIZE,
                      height: DRAG_CONTROL_SIZE,
                    }}
                    // onClick={this.stopPropagation}
                    // onWheel={this.preventDefault}
                  >
                      {/* This is the drag symbol of 3 diagonal lines in lower right corner */}
                      <svg
                        viewBox="0 0 20 20"
                        style={{opacity:0.70, fill: 'none' }}
                        xmlns='http://www.w3.org/2000/svg'
                        height={20}
                        width={20}
                      >
                        <polyline points='0,15 15,0'   stroke='#404040' strokeWidth='1'/>
                        <polyline points='5,15 15,5'   stroke='#404040' strokeWidth='1'/>
                        <polyline points='10,15 15,10' stroke='#404040' strokeWidth='1'/>
                      </svg>
                  </div>

                </DraggableCore>

      </div>
    )
  }
}
