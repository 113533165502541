import type {HighlightArray, ScryInputString}  from '../sharedFunctions/parseScryInputStringTypes'

export type SubStringInfo = {
  name:string
  text:string
  start:number
  end:number
  value: string|number
}

export type OpCode = {
  name : string
  value: string | number
  value2:string | number
  indentLevel: number // Helpful for optimization and formatting column of opcodes.
  lineNum: number
}

export type ResultByFormulaLineNum = {
  wasExecuted: boolean,
  exprResult: string|number,
  tmpVars: Object
}

export type ScryProgram = OpCode[]

export type ScryFormula = {
  formulaStrings   : string[]
  formulaSubStrings: SubStringInfo[][]     // formulaSubStrings[line index][subString index]

  // Each line must be one (but only one) of the following options:
  isBlankLine: boolean[]   // NO VISIBLE TEXT
  isCommentLine: boolean[] // ONLY a comment (no other tokens)
  isExpression: boolean[]  // An expression, whether valid or not.  (NOT either of the other two alternatives)

  program_RPN: ScryProgram  // Program:Array<opcodes>   Not optimized; But linked (executable)
  program_OPT: ScryProgram  // Program:Array<opcodes>   Optimized and Linked      (executable)

  inputArgColKeys: Array<number>

  errorID : string
  warningID : string
  highlightArray: HighlightArray
  highlightLineNums: number[]
  highlightUnderline: number

  workStrg: ScryInputString[] // The class object that matches substrings from a long line of text.
}

export const getDefaultScryFormula = (): ScryFormula => {
  // Creates unique references on each call:
  // Alternative to doing a cloneDeep.
  const result =  {
     formulaStrings: [],
     formulaSubStrings: [],
     isBlankLine: [],
     isCommentLine: [],
     isExpression: [],
     inputArgColKeys: [],
     errorID : '',
     warningID: '',
     highlightArray: [],
     highlightLineNums: [],
     highlightUnderline: -1,
     program_RPN: [],        // Program:Array<opcodes>   Not optimized; But linked (executable)
     program_OPT: [],  // Program:Array<opcodes>   Optimized and Linked      (executable)
     workStrg: []
  }
  Object.seal( result )
  return result
}

export const reservedKeywords = [
   'if', 'else', 'elif', 'pass', 'return',
   'True', 'False', 'pi', 'None',
 ]

export const functionNamesAndArgCountObject = {
  // name : [ requiredArgs, optionalArg ]
  abs: [1,0],
  max: [2,100],   // variable length
  min: [2,100],   // variable length
  ceil: [1,0],
  floor: [1,0],
  trunc: [1,0],
  round: [1,1],
  exp: [1,0],
  log: [1,0],
  log10: [1,0],
  sqrt: [1,0],
  degrees: [1,0],
  radians: [1,0],
  cos: [1,0],
  sin: [1,0],
  tan: [1,0],
  acos: [1,0],
  asin: [1,0],
  atan: [1,0],
  atan2: [2,0],
  isnan: [1,0],
  //jpsfunc: [2,0],
}


const reservedFuncKeys = Object.keys( functionNamesAndArgCountObject )
export const reservedFuncNames = reservedFuncKeys.map( x=> x+'(' )
export var longestFuncNameLength = 0

reservedFuncNames.map( x=> Math.max( longestFuncNameLength, x.length ) )

export const reservedOperators = [
  '<=', '>=', '==', '!=', '**', '//',
  '<', '>', ',', '+', '-', '*', '/', '%' ,'(', ')', '#', '=', ':',
  'and', 'or', 'not'
]
export const reservedIllegalOperators = [ '^', '&&', '||', '+=', '-=', '*=', '/=', '%=', '**=', '!' ]
export const reservedIllegalPython = [ 'as', 'assert', 'break', 'class', 'continue', 'def',
  'del', 'except', 'exec', 'finally', 'for', 'from', 'global', 'import', 'in', 'is', 'lambda',
  'print', 'raise', 'try', 'while', 'with', 'yield' ]

export const reservedColNames = reservedKeywords.concat( reservedFuncNames,
     reservedOperators, reservedIllegalOperators, reservedIllegalPython )
