import type { MouseEvent } from 'react'
import type { LightweightMod, Plot, Table, Tablelook } from '../types'
import type { PlotXyComputedData } from '../computedDataPlotXY/xy_plotTypes'
import type { TableComputedData } from '../computedDataTable/getDefaultTableComputedData'
import type { PlotFocusID } from './xy_plotCalculatorInv'

import { Component } from 'react'
import HighlightedFloatingPalette from '../floatingPalette/HighlightedFloatingPalette'
import { NAV_COLUMN_WIDTH, TOP_MENUBAR_HEIGHT } from '../sharedComponents/constants'
import { reactMinorDispatch } from '../sharedComponents/reactDispatch'
import ActionDebugPlotFocusCalc from './ActionDebugPlotFocusCalc'
import PlotLayoutMain from './PlotLayoutMain'
import { xy_plotCalculatorInv } from './xy_plotCalculatorInv'
import { rStateXY_Plot } from './xy_responsiveState'


type OwnProps = {
  plotXyComputedData: PlotXyComputedData
  plot: Plot
  table: Table
  tablelook: Tablelook
  tableComputedData: TableComputedData
  canEdit: boolean
  closeNavColumnPopups: () => void
}

export default class PlotFocalPlane extends Component<OwnProps> {

  mouseDownFocusObj: Partial<PlotFocusID> = {}

  handleMouseDown = (e: MouseEvent<HTMLDivElement>): void => {
    //console.log( 'handleMouseDown in PlotFocalPlane' )
    const { plotXyComputedData } = this.props
    const plotID = xy_plotCalculatorInv(
      e.clientX - NAV_COLUMN_WIDTH,       // x
      e.clientY - TOP_MENUBAR_HEIGHT,     // y
      plotXyComputedData.plotWidthObj,            // w
      plotXyComputedData.plotHeightObj,           // h
      plotXyComputedData.plotStyleObj,            // s
      plotXyComputedData.seriesOrder)            // seriesOrder
    this.mouseDownFocusObj = { ...plotID }
  }

  handleMouseUp = (e: MouseEvent<HTMLDivElement>): void => {
    var isMouseDownSet = (this.mouseDownFocusObj.name !== undefined)
    const { plotWidthObj, plotHeightObj, plotStyleObj, seriesOrder } = this.props.plotXyComputedData
    const focusObj: PlotFocusID = xy_plotCalculatorInv(
      e.clientX - NAV_COLUMN_WIDTH,       // x
      e.clientY - TOP_MENUBAR_HEIGHT,     // y
      plotWidthObj, plotHeightObj, plotStyleObj, seriesOrder)
    const { name: downName, seriesKey: downSeriesKey } = this.mouseDownFocusObj
    const isFocusObjSame = (downName === focusObj.name && focusObj.seriesKey === downSeriesKey)
    this.mouseDownFocusObj = {}   // Clear the mouseDown object
    //console.log( 'Plot FocalPlane mouseUpEvent - isMouseDownSet, isFocusObjSame:',
    //isMouseDownSet, isFocusObjSame, downName, focusObj.name, e.clientX, e.clientY )
    if (isMouseDownSet && isFocusObjSame) {
      this.handleValidMouseDownUpSequence(focusObj)
    }
  }


  handleValidMouseDownUpSequence = (focusObj: PlotFocusID) => {
    const plotXyComputedData = this.props.plotXyComputedData
    const { isEditorOpen: currentIsEditorOpen, selection: currentMinorStateSelection } = plotXyComputedData.minorState
    const currentSeriesKey = currentMinorStateSelection.seriesKey

    // Above function returns a name of 'leftAxis', 'bottomAxis', ....
    // We want to express the click in the selection name in terms of
    // 'basisA', 'basisB', or 'basisC'
    // This way if the user transposes or swaps any axis, the highlighted
    // selction is properly swapped as well.
    var selectionName = focusObj.name
    if (focusObj.name.slice(-4) === 'Axis') {
      switch (focusObj.name) {
        case 'bottomAxis':
          selectionName = plotXyComputedData.bottomAxis.basisPath
          break
        case 'leftAxis':
          selectionName = plotXyComputedData.leftAxis.basisPath
          break
        case 'topAxis':
          selectionName = plotXyComputedData.topAxis?.basisPath ?? selectionName
          break
        case 'rightAxis':
          selectionName = plotXyComputedData.rightAxis?.basisPath ?? selectionName
          break
      }
    }

    let mods = Array<LightweightMod>()
    switch (selectionName) {

      case 'mainTitle':
      case 'publisherTitle':
      case 'legend':
      case 'basisA':
      case 'basisB':
      case 'basisC':
        if (currentIsEditorOpen && currentMinorStateSelection.name === selectionName && currentSeriesKey === focusObj.seriesKey) {
          // Close editor if the current selected cell is clicked a 2nd time.
          mods = [{ newVal: { name: '', seriesKey: -1 }, path: 'attributes.minorState.selection' },
          { newVal: false, path: 'attributes.minorState.isEditorOpen' }]
          reactMinorDispatch(mods, 'plots', this.props.plot.id)
        } else {
          mods = [
            { newVal: { name: selectionName, seriesKey: focusObj.seriesKey }, path: 'attributes.minorState.selection' },
            { newVal: true, path: 'attributes.minorState.isEditorOpen' },
          ]
          reactMinorDispatch(mods, 'plots', this.props.plot.id)
          this.props.closeNavColumnPopups()
        }
        break

      case 'empty':
      default:
        if (currentIsEditorOpen || currentMinorStateSelection.name !== '' ||
          currentMinorStateSelection.seriesKey !== -1) {
          mods = [
            { newVal: '', path: 'attributes.minorState.selection.name' },
            { newVal: -1, path: 'attributes.minorState.selection.seriesKey' },
            { newVal: false, path: 'attributes.minorState.isEditorOpen' },
          ]
          reactMinorDispatch(mods, 'plots', this.props.plot.id)
        }
        break
    }

  }

  initPlotFocalPlane = (element: HTMLDivElement | null): void => {
    rStateXY_Plot.plotFocalPlane = element
  }

  render() {
    const { plotXyComputedData, table, tablelook, tableComputedData } = this.props
    const { canEdit } = plotXyComputedData
    const { isEditorOpen } = plotXyComputedData.minorState
    // return null
    return (

      <div className={'rc_PlotFocalPlane'}
        ref={this.initPlotFocalPlane}
        onMouseDown={this.handleMouseDown}
        onMouseUp={this.handleMouseUp}
        style={{
          position: 'absolute', top: 0, left: 0,
          width: plotXyComputedData.plotWidthObj.focalPlanePx,
          height: plotXyComputedData.plotHeightObj.focalPlanePx,
          //background: 'pink'
        }}
        tabIndex={1}>

        <PlotLayoutMain
          canEdit={canEdit}
          plotXyComputedData={plotXyComputedData}
          tableComputedData={tableComputedData}
          table={table}
          tablelook={tablelook} />

        {isEditorOpen &&
          <HighlightedFloatingPalette
            plotXyComputedData={plotXyComputedData}
            table={table}
            tablelook={tablelook}
            tableComputedData={tableComputedData} />
        }

        {false &&
          <ActionDebugPlotFocusCalc
            seriesOrder={plotXyComputedData.seriesOrder}
            plotWidthObj={plotXyComputedData.plotWidthObj}
            plotHeightObj={plotXyComputedData.plotHeightObj}
            plotStyleObj={plotXyComputedData.plotStyleObj} />
        }

      </div>
    )
  }
}
