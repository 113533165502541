import invariant from 'invariant'
import {list} from 'radash'
import type {ScryFormula, ScryProgram} from './formulaTypes'

export const RPN_createPrograms = ( scryFormula: ScryFormula, isDepColumnArr : Array<boolean> ) : void => {

    let program = RPN_createFromFormulaSubstrings( scryFormula )
    scryFormula.program_RPN = RPN_link( program )
}

// Jan 30, 2020 by JPS
// Next functions are a shunting-yard algorithm:
// In computer science, the shunting-yard algorithm is a method for parsing
// mathematical expressions specified in infix notation. It can produce
// either a postfix notation string, also known as Reverse Polish notation (RPN),
// or an abstract syntax tree (AST). The algorithm was invented by
// Edsger Dijkstra and named the "shunting yard" algorithm because its
// operation resembles that of a railroad shunting yard.


/* NOTE ON PYTHON PRECEDENCE:  Issue #1

Python has some quirks (exceptions ?) that are not usually clarified
in the table of operator precedence and associativity.

Consider the next three expressions:
 2**-1 => interpreted by python as 2**(-1)    => 2,1,subUnary,exp
-2**1  => interpreted by python as -(2**1)    => 2,1,exp,subUnary
-2**-1 => interpreted by python as -(2**(-1)) => 2,1,subUnary,exp,subUnary
Note: When subUnary follows exponentiation it is the HIGHER precedence,
regardless of what is stated in the tables of python precedence order.

ALL found documentation on python precedence says subUnary has lower precedence than exp.
You will also find, if you look hard enough, that when subUnary follows exponentiation,
then subUnary DOES have higher precedence !!!  (Which is what one would expect.)

The operator for 'bitwise not' is the only operator between exponentiation and subUnary
in the precedence order.  Fortunately, we won't be using 'bitwise not'.
Hence, we can handle this exception like so:

Given subUnary when 'right of exponent' is higher precedence.
Given subUnary when 'left  of exponent' is lower  precedence
Then set subUnary and exponentiation as equal precedence, and 'right-to-left' associativity.
Now whenever the two appear in sequence, the rightmost operator is given precedence.
And fortunately, this solution preserves the expected 'right' associativity of exponentiation.

*/



/* NOTE ON PYTHON PRECEDENCE:  Issue #2

In python, 'x < y < z'  is NOT evaluated according to the precedence rules.

The RPN according to strict precedence rules AND the RPN that
will be created by this algorithm are:
x<y<z  =>   x,y,lessThan,z,lessThan
Hence the final operation will be: (true/false < z)
Which is not what anybody likely intended.

THIS is what python considers the proper (internal) evaluation:
x<y<z  =>  (x<y) && (y<z)
An '&&' operator is inserted !!
Another example:
a <= b == c > a  =>  (a<=b) && (b==c) && (c>a)

This python exception CANNOT be emulated using this algorithm.
Since we must code for the exception, then
better to catch it in module:  formulaVerify
We will flag this as an error (readability) and require the user insert the
implied either parens or the inferred '&&' operator.

*/

const lastVal = (a:Array<any>):any => a[ a.length-1 ]

const precedence = {
  "op_**"  : 10,
  "uniSub" : 10,   // Read note above.  Don't change this to '9' as one is first tempted.
  "op_*"   : 8,
  "op_/"   : 8,
  "op_//"  : 8,
  "op_%"   : 8,
  "op_+"   : 7,
  "op_-"   : 7,
  "op_<"   : 6,
  "op_>"   : 6,
  "op_<="  : 6,
  "op_>="  : 6,
  "op_=="  : 6,
  "op_!="  : 6,
  "op_not" : 5,
  "op_and" : 4,
  "op_or"  : 3,
  "op_="   : 2,
  "op_,"   : 1,   // Comma isn't usually considered an operator.  But lower or same
                  // as the assignment operator works well for this RPN algorithm.
}


// associativity - If two consecutive operators are the same precedence level,
// then is the operator on the 'left' or 'right' given the higher precedence?

const associativity = {
  "op_**"  : 'right',
  "uniSub" : 'right',  // Normally 'left'.  But we will never see two consecutive
                                // subUnary operators produced by 'formulaParser'.  Hence we can
                                // subUnary to precedence level '8' and give it priority
                                // whenever subUnary is 'right' of exponentiation.
                                // Solves one of the two python 'corner cases' with respect to precedence.
  "op_*"  : 'left',
  "op_/"  : 'left',
  "op_//" : 'left',
  "op_%"  : 'left',
  "op_+"  : 'left',
  "op_-"  : 'left',
  "op_<"  : 'left',
  "op_>"  : 'left',
  "op_<=" : 'left',
  "op_>=" : 'left',
  "op_==" : 'left',
  "op_!=" : 'left',
  "op_not": 'left',
  "op_and": 'left',
  "op_or" : 'left',
  "op_="  : 'right',   // Does not matter as we allow only one '=' per expression.
  "op_,"  : 'left',
};


const isOperator = ( name:string ) : boolean => {
  return ( precedence[name] ) ? true : false
}


const RPN_createFromFormulaSubstrings = ( scryFormula: ScryFormula ) : ScryProgram =>  {

  // Objective: transform canonicalFormat[lineNum][tokenNum] ==>  rpn[ opCode number ]

	var rpnStack : ScryProgram = []    // Properly sequenced as created
	var shuntingStack : ScryProgram = []   // NOT properly sequenced.  So a ScryProgram dataType, but would never actually execute.
  var priorIndentLevel : number  = 0
  var currentIndentLevel: number  = 0

  //scryFormula.formulaSubStrings.forEach( (thisLine, lineNum) => {
  for ( const [lineNum, thisLine] of scryFormula.formulaSubStrings.entries() ) {

    // Skip over blank lines and comment only lines.
    // Don't want the handling of 'indent' substring to be complicated
    // by the indentation (or not) of comments.  So we only consider
    // 'indent' to be important on expression lines.
    if ( !scryFormula.isExpression[lineNum] ) { continue }

    for ( const [i, thisToken] of thisLine.entries() ) {
        var tokenName  = thisToken.name
        var value = thisToken.value

        var className = tokenName  // className is a group identifier -- simplifies the algorithm below.
        if ( className.slice(0,4) === 'func') className = 'func'
        if ( className.slice(0,4) === 'flow') className = 'flow'
        if ( isOperator(className) ) className = 'operator'
        // Convert const* name to simply 'const'.  Simpler for late optimization
        if ( tokenName.slice(0,5) === 'const' ) {
          tokenName = 'constant'
          className = 'constant'
        }
        // Convert flow statements if,else,elif,return,pass to functions.
        //if ( className.slice(0,4) === 'flow' ) {
        //  tokenName = 'func' + className.slice(4)
        //  className = 'func'
        //}

        if ( isOperator(className) ) className = 'operator'

        // Some optimization of sequence 'setVarName', 'op_='
        // This is easy/possible before we swizzle the tokens to RPN.
        // Nearly impossible to optimize post conversion to RPN
        if (tokenName === 'op_=' ) {
          // The preceding token is by definition 'setVarName'
          // And the varName being set is the precedingToken.value
          value = thisLine[i-1].value
        }

        switch ( true ) {

          case tokenName === 'endExp':
          case tokenName === 'emptySpace':
          case tokenName === 'comment':
          case tokenName === 'flowpass':
          case tokenName === 'setVarName':
                break

          case className === 'constant':
          case tokenName === 'colName' :
          case tokenName === 'varName' :
          case tokenName === 'None'    :
                rpnStack.push({name:tokenName, value, value2:'', lineNum, indentLevel:currentIndentLevel})
                break

          case className === 'flow' :
                shuntingStack.push({name:tokenName, value, value2:'', lineNum, indentLevel:currentIndentLevel})
                shuntingStack.push({name:'op_(',value:0, value2:'', lineNum, indentLevel:currentIndentLevel})   // We insert the implied open paren not included in the python syntax
                break
          case className === 'func' :
                shuntingStack.push({name:tokenName, value, value2:'', lineNum, indentLevel:currentIndentLevel})  // use the full function name:  'funcAbs', 'funcSin', ...
                break
          case tokenName === 'op_(' :
          			shuntingStack.push({name:tokenName,value, value2:'', lineNum, indentLevel:currentIndentLevel})
                break

          case tokenName === 'op_,' :
          			// pop operators off the stack until I reach the calling 'func('
          			while( lastVal(shuntingStack) && lastVal(shuntingStack).name.slice(0,4) !== 'func' ) {
          				rpnStack.push(shuntingStack.pop());
          		  }
                break

          case className === 'operator' :
                // $FlowFixMe
                let thisPre = precedence[tokenName]
                // $FlowFixMe
                let thisAss = associativity[tokenName]
                while (true) {
                  let isLastStackAnOperator = lastVal(shuntingStack) && isOperator ( lastVal(shuntingStack).name )
                  let lastPre = (lastVal(shuntingStack)) ? precedence[lastVal(shuntingStack).name] : 0
                  let leftAssAndLowerPre   = (thisAss === "left"  && thisPre <= lastPre)
                  let rightAssAndLowerPre  = (thisAss === "right" && thisPre <  lastPre)
                  let isThisOperatorLowerPrecedence = leftAssAndLowerPre || rightAssAndLowerPre
          			  if (isLastStackAnOperator && isThisOperatorLowerPrecedence) {
        			  	   rpnStack.push(shuntingStack.pop())
                  } else {
                    break
                  }
        			  }
          			//After removing all operatorStack values of lowerPrecedence, push this new operator onto the stack
          			shuntingStack.push({name:tokenName, value, value2:'', lineNum, indentLevel:currentIndentLevel})
                break


          case tokenName === 'op_)' :
          case tokenName === 'op_:' :
          case tokenName === 'endReturn':
                // pop operators off the shunting stack until we get to the
                // corresponding '(' or 'func('
                if ( !lastVal(shuntingStack) ) {break}
                while( !(lastVal(shuntingStack).name === 'op_(' || lastVal(shuntingStack).name.slice(0,4) === 'func')) {
                  rpnStack.push(shuntingStack.pop())
                }
                if ( lastVal(shuntingStack).name === 'op_(' ) {shuntingStack.pop()}
                else if ( lastVal(shuntingStack).name.slice(0,4) === 'func' ) { rpnStack.push(shuntingStack.pop()) }
                break

          case tokenName === 'indent':
                // End of prior line.  Empty the shuntingStack
                rpnStack = rpnStack.concat(shuntingStack.reverse())
                shuntingStack=[]
                // Insert a jump opcode at each indent.
                // A multiple indent will result in multiple consecutive 'jump' opcodes
                currentIndentLevel = Number(value)
                while ( priorIndentLevel > currentIndentLevel ) {
                  rpnStack.push({name:'jump', value:0, value2:'', lineNum, indentLevel:priorIndentLevel})
                  priorIndentLevel --
                }
                priorIndentLevel = currentIndentLevel
                break

          default:
                invariant ( false, `Unrecognized token name: ${tokenName}` )
        }
        // For Debugging
        if ( false ) {
          console.log( '' )
          if ( lineNum === 0 && i === 0 ) { console.log ( '     RPN LOOP BEGINS HERE!' ) }
          console.log( 'tokenName: ', tokenName )
          console.log( 'className: ', className )
          for (let j=0; j<rpnStack.length; j++) {
            console.log( `  stack ${j}:` , rpnStack[j] )
          }
          for (let j=0; j<shuntingStack.length; j++) {
            console.log( `  shunt ${j}:` , shuntingStack[j] )
          }
        }
    }
	}

  // End of prior line.  One final time we empty the shunting stack.
	let program = rpnStack.concat(shuntingStack.reverse())
  let lineIndex : number = scryFormula.formulaStrings.length
  while ( priorIndentLevel > 0 ) {
    program.push( {name:'jump', value:0, value2:0,  lineNum:lineIndex, indentLevel: priorIndentLevel}  )
    priorIndentLevel--
  }

  return program
}




const RPN_link = ( inProgram:ScryProgram ) : ScryProgram => {
  const outProgram : ScryProgram = []
  var outPtr: number = 0
  // Assume a worse case set of nested if/else/elif of no more than fifty.
  var indentOpcode = list(50).map( ()=>{return {name:'none', value:0}} )
  var jumpOpcodes  = list(50).map( ()=>[] )
  var currentLevel = 0    // 0,1,2,...
  // For every negative indent, algorithm is easier if we just 'look ahead' to
  // determine whether this current indent level does or does not have a
  // ending 'else' block.  Without a user defined ending 'else' block, we
  // need to effectively insert one.  Not actually inserted.  Just that the
  // management of the jumpPoints knows to 'pretend' the else block existed.
  const lookAhead = ( inPtr:number ) : string => {
    let i = inPtr + 1
    while (i < inProgram.length) {
      let name = inProgram[i].name
      // Cases where we are certain an 'else' block is missing.
      // These are anything that disrupts the expected if/elif/elif/else construction.
      // Or equivalently, these are the opcodes that delineate the 'end' of a user expression.
      // Hence the list can be interpreted as the next formula line is NOT an if/elif/elif/else construction
      if ( name === 'jump' || name === 'op_=' || name === 'flowreturn'  || name === 'flowif' ) { return 'missingElse' }
      // Cases where we find the 'else' block, or the potential for an 'else' block remains.
      // Just interpret these two as: Yes, if/elif/else construction still on track.  So no
      // need to continue 'looking' ahead.
      if ( name === 'flowelse' || name === 'flowelif' ) { return 'elifElse' }
      i++
    }
    // Possible for last indented line to return.
    // Hence we are out of opcodes.  Can't look ahead any farther.
    // Must be a missingElse.
    return 'missingElse'
  }

  inProgram.forEach( (opcode, inPtr) => {

    switch ( opcode.name ) {

      case 'flowif' :
      case 'flowelif' :
      case 'flowelse' :
        // currentLevel is the indent of the if/else/elif
        // NOT the indent of the subsequent block of code to follow.
        indentOpcode[currentLevel] = opcode
        outProgram.push( opcode )
        outPtr++
        currentLevel++  // Now currentLevel refers to the indented block.
        break

      case 'jump' :
        currentLevel--
        // CurrentLevel of the jump is the level of the corresponding if/elif/else line
        var thisBranch = indentOpcode[currentLevel].name
        if ( lookAhead(inPtr) === 'missingElse' ) { thisBranch = 'missingElse' }
        switch ( thisBranch ) {
              // Note:  Opcode.value ALWAYS set to 'opcode for next execution', MINUS ONE !
              case 'flowelse':
                jumpOpcodes[currentLevel].forEach ( opcode => opcode.value = outPtr-1 )
                jumpOpcodes[currentLevel] = []
                break
              case 'missingElse':
                indentOpcode[currentLevel].value = outPtr-1
                jumpOpcodes[currentLevel].forEach ( opcode => opcode.value = outPtr-1 )
                jumpOpcodes[currentLevel] = []
                break
              case 'flowif':
              case 'flowelif':
                outProgram.push( opcode )
                outPtr++
                indentOpcode[currentLevel].value = outPtr-1
                jumpOpcodes[currentLevel].push(opcode)
                break
              default:
        }
        break

      default :
        outProgram.push( opcode )
        outPtr++
    }
  })

  if ( process.env.NODE_ENV === 'development' ) {
    invariant ( currentLevel === 0, `CurrentLevel ${currentLevel} should be zero at bottom of linker` )
  }
  return outProgram
}
