import type { PlotXyComputedDataParams, PlotXyComputedDataRefs, PlotXyComputedDataResults } from '../computedDataPlotXY/xy_createPlotRenderObj'
import type { PlotXyComputedData } from '../computedDataPlotXY/xy_plotTypes'
import type {TableComputedDataParams,
             TableComputedDataRefs} from '../computedDataTable/updateTableComputedData'
import type {TableComputedData}     from '../computedDataTable/getDefaultTableComputedData'

export type MemoizedTableComputedData  = { result   : {tableComputedData: TableComputedData},
                                    paramsObj: TableComputedDataParams,
                                    refsObj  : TableComputedDataRefs
                                  }
export type MemoizedPlotXyComputedData = {
    result: PlotXyComputedDataResults
    paramsObj: PlotXyComputedDataParams
    refsObj: PlotXyComputedDataRefs
}
type TableStore   = { [id:string] : MemoizedTableComputedData[] }
type PlotXyStore  = { [id:string] : MemoizedPlotXyComputedData[] }


// The computedData stores:
const tableData  : TableStore  = {}
const plotXyData : PlotXyStore = {}




// Getters  (Returns the memoization data structure)
export const getMemoizedTableComputedData = (id: string) : MemoizedTableComputedData[] => {
    if ( tableData.hasOwnProperty(id) ) {
        return tableData[id]
    }
    return []
}

export const getMemoizedPlotXyComputedData = (id: string): MemoizedPlotXyComputedData[] => {
    if ( plotXyData.hasOwnProperty(id) ) {
        return plotXyData[id]
    }
    return []
}

// Returns ONLY the tableComputedData object
// Provides access to the active table for components outside the scope of tableRender component.
// e.g. NavBar, menuBar, ??
export const getTableComputedData = (id: string) : TableComputedData | null => {
    const temp = getMemoizedTableComputedData(id)[0]
    //console.log( 'getM', temp)
    //aconsole.log( 'getT', temp.result.tableComputedData)
    return (!temp) ? null : temp.result.tableComputedData
}

export const getPlotXyComputedData = (id: string): PlotXyComputedData | null => {
    const temp = getMemoizedPlotXyComputedData(id)[0]
    //console.log( 'getM', temp)
    //console.log( 'getT', temp.result.plt)
    return (!temp) ? null : temp.result.plt
}

// Setters
// This function from TtGetComputedData component mapping function.
// The value saved in the mapping (by table.id) will allways represent
// the lastest render.  If/When we switch tables, we can use this
// prior result as our starting point for tableComputedData
export const setMemoizedTableComputedData = (id: string, newVal : MemoizedTableComputedData[] ) : void => {
    newVal.length = 1
    tableData[id] = newVal
    //console.log( 'set', newVal )
}

export const setMemoizedPlotXyComputedData = (id: string, newVal: MemoizedPlotXyComputedData[] ): void => {
    newVal.length = 1
    plotXyData[id] = newVal
    //console.log( 'set', newVal )
}

// Deleters
export const deleteMemoizedTableComputedData = ( idArr: string[] ) : void => {
    for ( const id of idArr ) {
        //removeAttrFromObject( tableData, 'id')
        delete tableData[id]
    }
}

export const deleteMemoizedPlotXyComputedData = ( idArr: string[] ): void => {
    for ( const id of idArr ) {
        delete plotXyData[id]
    }
}









// Deleter
export const deleteMemoizedData = ( id: string[] ) : void => {


}


