import {Component} from 'react'
import type {TableComputedData} from '../computedDataTable/getDefaultTableComputedData'
import reactDispatch          from '../sharedComponents/reactDispatch'
import {sessionStateChangeDispatch} from '../sharedComponents/reactDispatch'
import StyleTitle             from '../sharedComponents/StyleTitle'
import StyleSelectBox         from '../sharedComponents/StyleSelectBox'


//const SLIDER_INDENT = 60
//const SLIDER_PRESPACER = 10
const SELECT_INDENT = 10
const SELECT_PRESPACER = 0

type Props = { 
  tableComputedData : TableComputedData,
  tabName1: string,   // Used by parent wrapper (tabControl)  
  tabName2: string,   // Used by parent wrapper (tabControl)  
}

export default class TableTabPublish extends Component<Props> {

  setEditModeTrue  = () => {
    sessionStateChangeDispatch( [{newVal: true,  path: 'menuOption_isEditMode'}], 'Set EditModeTrue')
  }
  setEditModeFalse = () => {
    sessionStateChangeDispatch( [{newVal: false, path: 'menuOption_isEditMode'}], 'Set EditModeFalse')
  }

  setIsSortableTrue = () => {
    const table = this.props.tableComputedData.table
    if (table) {
      reactDispatch( [{newVal: true,  path: 'attributes.isSortable'}], 'Set isSortableTrue',  '', 'tables', table.id)
    }
  }
  setIsSortableFalse = () => {
    const table = this.props.tableComputedData.table
    if (table) {
      reactDispatch( [{newVal: false, path: 'attributes.isSortable'}], 'Set isSortableFalse', '', 'tables', table.id)
    }
  }

  render() {
    const table = this.props.tableComputedData.table
    if ( !table ) {return null}
    const {isSortable} = table.attributes
    const {canEdit, styleObj} = this.props.tableComputedData
    const {foregroundColor} = styleObj

    return (

          <div className={'rc_Table_TabPublish'}
            style={{
              position:'relative',
              height: '100%',
              width: '100%',
              color: foregroundColor,
              display: 'flex', flexFlow: 'column nowrap', //justifyContent:'flexStart',
            }}>

                <StyleTitle  text0={'Publish - This tab is only'} text1={'visible to the table owner.'}/>

{ /* Not showing edit mode options, as clicking viewMode will drop users out of this tab ! */}             
{false && <>
                <StyleSelectBox 
                  indent={SELECT_INDENT}
                  id={'editMode'}
                  preSpacer={SELECT_PRESPACER}
                  onClick={ this.setEditModeTrue }
                  isSelected={ canEdit? true : false }
                  text={'Edit Mode - for publishers only.'}
                  foregroundColor={foregroundColor} />

                <StyleSelectBox 
                  indent={SELECT_INDENT}
                  id={'viewMode'}
                  preSpacer={SELECT_PRESPACER}
                  onClick={ this.setEditModeFalse }
                  isSelected={canEdit? false : true }
                  text={'View Mode - as seen by others.'}
                  foregroundColor={foregroundColor} />
</>}
                <StyleSelectBox 
                  indent={SELECT_INDENT}
                  id={'viewMode'}
                  preSpacer={14}
                  onClick={ this.setIsSortableTrue }
                  isSelected={isSortable ? true : false }
                  text={'Show sort controls.'}
                  foregroundColor={foregroundColor} />

                <StyleSelectBox 
                  indent={SELECT_INDENT}
                  id={'viewMode'}
                  preSpacer={SELECT_PRESPACER}
                  onClick={ this.setIsSortableFalse }
                  isSelected={isSortable ? false : true }
                  text={'Hide sort; Use input CSV order.'}
                  foregroundColor={foregroundColor} />

          </div>
    )
  }
}
