import type { TableDomNodes, GetCellHTML_fromColKeyAndRowIndex} from '../computedDataTable/getDefaultTableComputedData'
import type { TableWidthObj, TableHeightObj,
              TableStyleObj }                 from '../computedDataTable/layoutCalculator'
import { numberFormat, getFormattingObj}      from '../sharedFunctions/numberFormat'
import { getRowGroupPlacement}                from '../computedDataTable/layoutCalculator'
import { calcThumbOffset_fromScrollValue, calcThumbLength }   from './tableResponsiveState'
import { startTimer, stopTimer, logTime }     from '../sharedFunctions/timer'
import constants                              from '../sharedComponents/constants'

const isTIMER_ENABLED = false 
const px = (value: number) : string => { return `${value}px` }




// These globals are variable, potentially changing each animation frame: 
export var isScrollTopAnimationActive : boolean = false  // Visible to the scrollControls. Tells us when
                                                         // scrollTop rendering is finished, so we can then
                                                         // issue a scrollTop state change.
var isRequestToStopAnimation  : boolean = false // Similar to above, but this is the 'request' (aka onDragStop)
                                                // to stop the animation.  However, the animation may not be
                                                // finished and worse case may require (n-1) addition rowGroup catch-ups.
var nextRowGroupToSynch : number  = 0   // Which of n-rowGroups available is next to re-render;  This value cycles
                                        // cycles through rowGroups such that we guarantee all 'n' groups
                                        // are rendered on any successive 'n' calls to synchScrollTop()
var priorFirstRowIndexArr : number[] = []   // The last frame (current displayed) rowIndex assigned to the
                                            // top of each row group.  WHEN next frame's required rowIndex
                                            // doesn't match, it means the rowGroup has switched position.
                                            // (top to bottom, or bottom moved to top).
                                            // Cell values in that rowGroup become 'stale'. (no longer valid).
var staleRowGroups : number[] = []  // rowGroups with stale data, 'waiting' for rendering to 'catch-up'
                                            // Animation ends NOT on dragStop, but on (dragStop && staleRowGroups.length === 0)
var scrollTop: number = 0  // set by onDrag() event handler.



// Some globals are static constants (static over any given vertical scroll animation) 
// These are set by initiateScrollTopAnimation()
var tableDomNodes: TableDomNodes
var visibleColKeys  : number[]      // The columns currently visible. We ONLY re-render these columns.
var numRowGroups    : number 
var rowsPerGroup    : number 
var rowGroupHeight  : number
var foregroundColor : string
var colDataTypeArr  : string[]
var dataAllocated   : number
var dataRequired    : number
var getCellHTML_fromColKeyAndRowIndex : GetCellHTML_fromColKeyAndRowIndex
var isRowNumberVisible: boolean
var showErrorsInRed   : boolean


// called by onDrag() event handler:
// This is a constantly changing value during drag.
// However, IF the user interrupts the drag() and just sits
// at a fixed position, THEN this value is a 'temporary' constant.
// HOWEVER, the scrollTop animation continues to run successive 
// annimation frames!  ScrollTop animation will eventually catch-up
// all stale frames, the just wait patiently for the next onDrag()
// to modify this value.
export const setNewResponsiveStateScrollTop = ( newOffset: number) : void => { 
    scrollTop = newOffset   
}

// This function is called by the onDragStop (vertical scroll) event handler.
// It tells this module that user is no longer interested in scrolling.
// Therefore, update any remaining stale rowGroups, then signal back to
// the dragStop eventHandler (using exported isScrollTopAnimationActive)
// that the animation is finished.
export const stopScrollTopAnimation = () : void => {
    isRequestToStopAnimation = true  // called by onDragStop event handler
}


export const initiateScrollTopAnimation = ( scrollTopIn: number, scrollLeft: number, 
           w: TableWidthObj, h: TableHeightObj, s: TableStyleObj, tableDomNodesIn: TableDomNodes, 
           colOrder: number[], hiddenIndexArr: number[], colDataTypeArrIn: string[],
           isRowNumberVisibleIn: boolean, canEdit: boolean,
           getCellHTML_fromColKeyAndRowIndexIn  : GetCellHTML_fromColKeyAndRowIndexIn   ) : void => {

    // Constants over all animation frames:  
    tableDomNodes = tableDomNodesIn 
    getCellHTML_fromColKeyAndRowIndex = getCellHTML_fromColKeyAndRowIndexIn
    isScrollTopAnimationActive = true   // Set true on dragStart();  Set false when animation catchup is complete.               
    isRequestToStopAnimation   = false  // Set true on dragStop()
    scrollTop = scrollTopIn;
    ({numRowGroups, rowsPerGroup, rowGroupHeight, dataAllocated, dataRequired} = h);
    ({foregroundColor} = s);
    isRowNumberVisible = isRowNumberVisibleIn
    showErrorsInRed = canEdit
    colDataTypeArr = colDataTypeArrIn
    visibleColKeys = []  // Limit re-rendering to only columns that are visible.
    for ( const [colIndex, colKey] of colOrder.entries() ) {
      if ( hiddenIndexArr[colKey] > 0 || w.displayedColWidths[colKey] === 0 ) { }  // skip hidden and deleted cols.
      else if ( !w.isCombinedTable && colIndex < w.numLockedCols ) { visibleColKeys.push(colKey) }
      else if ( w.startColLeft[colIndex] + w.displayedColWidths[colIndex]  < scrollLeft ) { }
      else if ( w.startColLeft[colIndex] > scrollLeft + w.movingAllocated ) { }
      else { visibleColKeys.push(colKey) }
    };

    // This next glocal stores the rowNumber for each rowGroup
    // During scrolling this potentially changes with each animation frame!
    priorFirstRowIndexArr = new Array(numRowGroups)
    for (let i=0; i < numRowGroups; i++ ) {
      let {firstRowIndex} = getRowGroupPlacement( i, scrollTop, numRowGroups, rowsPerGroup, rowGroupHeight )
      priorFirstRowIndexArr[i] = firstRowIndex
    }
    if ( isTIMER_ENABLED ) {  startTimer('tableResponsiveState')  }

    // Call the first frame of recursive animation.
    scrollTopAnimation() 
}


const scrollTopAnimation = () : void => {
    staleRowGroups = updateScrollTop( scrollTop )   // Translates rows vertically; re-renders 0 or 1 rowGrops. 
                                                    // Catches-up rowGroup rendering when possible.
    //updateFloatingPaletteHighlight( w_thisFrame )   // Re-renders the cyan connecting line, if visible.
    redrawVertScrollControl_fromScrollPosition( scrollTop, dataAllocated, dataRequired )  // Repositions the scrollBar thumb.
    if ( isTIMER_ENABLED ) { logTime('tableResponsiveState', `scrollTop`) }
    // Next block is the Exit Path when breaking the recursive loop!
    if ( isRequestToStopAnimation && staleRowGroups.length === 0 ) {
        if ( isTIMER_ENABLED ) {  stopTimer('tableResponsiveState') }
        isScrollTopAnimationActive = false   // visible to the onDragStop event handler!
        isRequestToStopAnimation   = false
        restoreInitialTableResponsiveStateVertScroll()
        return  
    }
    // Else, repeat until explicite request to stop animation.
    requestAnimationFrame( scrollTopAnimation )
}


export const restoreInitialTableResponsiveStateVertScroll = (  ) : void => {
    //rStateFloatingPalette.synchStop( )
}


const updateScrollTop = ( newScrollTop: number ) : number[] => {

    const firstRowIndexArr = []
    const commasOnlyFormattingObj = getFormattingObj('commasOnly')   // If we our rendering line numbers.
    const {lockedRowGroups, movingRowGroups, lineNumRowGroups} = tableDomNodes

    // translate (position vertically) each rowGroup:
    for (let groupIndex=0; groupIndex<numRowGroups; groupIndex++ ) {
        var {topOffset, firstRowIndex} = getRowGroupPlacement( groupIndex, newScrollTop, 
                                            numRowGroups, rowsPerGroup, rowGroupHeight )
        firstRowIndexArr[groupIndex] = firstRowIndex
        let thisDomNode = lockedRowGroups[groupIndex]
        if ( thisDomNode ) {
          thisDomNode.style.transform = `translate(0px, ${topOffset}px)`
        }
        thisDomNode = movingRowGroups[groupIndex]
        if ( thisDomNode ) {
          thisDomNode.style.transform = `translate(0px, ${topOffset}px)`
        }
        thisDomNode = lineNumRowGroups[groupIndex]
        if ( isRowNumberVisible && thisDomNode ) {
          thisDomNode.style.transform = `translate(0px, ${topOffset}px)`
        }
    }

    //  Which (if any) rowGroup should be overwritten with new cell data?
    var updateGroup = -1  // Assumption; -1 mean NO rowGroup needs to be updated.
    const staleGroups = []   // Assumption;  There are no rowGroups with 'stale' values.
    var groupIndex
    for (let i=0; i<numRowGroups; i++) {
        groupIndex = (nextRowGroupToSynch+i) % numRowGroups
        // First modified group (firstRowIndex changed!) becomes our updateGroup
        // There is ONLY one updateGroup per RAF.  They sequence sequentially. 0->n, 0->n, 0->n ...
        if ( updateGroup === -1 && firstRowIndexArr[groupIndex] !== priorFirstRowIndexArr[groupIndex] ) {
          updateGroup = groupIndex
        }
        // If there is more than one rowGroup that needs to be updated, we call this 'Unfinished Business'.
        else if ( firstRowIndexArr[groupIndex] !== priorFirstRowIndexArr[groupIndex] ) {
          staleGroups.push( groupIndex )
        }
    }

    // Save info needed for next animation frame:
    if ( updateGroup >= 0 ) {  // Save info needed for next animation frame:
        nextRowGroupToSynch = updateGroup + 1  // If I update rowGroup '3' this frame, then update '4' next frame.
                                               // OK if this number exceeds number of rowGroups, because we
                                               // use it like a clock (modulo) where rowGroup 'n' same as rowGroup '0'
        // For each pass, we MAY update '1' rowGroup.
        // In which case the firstRowIndex for that group also needs to be updated                                       
        priorFirstRowIndexArr[updateGroup] = firstRowIndexArr[updateGroup]  
    }

    // Overwrite the cell values for a single updateGroup!
    let columnsData = tableDomNodes.columnsData
    if ( updateGroup >= 0 ) {
        firstRowIndex = firstRowIndexArr[updateGroup]
        // Row numbers:
        const curRowGroup = lineNumRowGroups[updateGroup]
        if ( isRowNumberVisible && curRowGroup ) {
            // Jason: I assume this fail typeScript because a child in the domTree 
            // may be some elemental prop like text, hence cannot be assumed a DomNode:
            var parentNode = curRowGroup.firstChild as HTMLElement
            var rowGroupNodes = parentNode.children as HTMLCollection
            for (let rowCounter=0; rowCounter<rowsPerGroup; rowCounter++ ) {
                var thisRowIndex = firstRowIndex + rowCounter + 1
                let newText = numberFormat( String(thisRowIndex), commasOnlyFormattingObj, 'html' )
                var cellNode = rowGroupNodes[rowCounter] as HTMLElement
                cellNode.innerText = newText
            }
        }
        // Table cells:
        for ( const colKey of visibleColKeys ) {
            var colDataType = colDataTypeArr[colKey]
            var isNumber = ( colDataType.slice(0,6) === 'number' )
            var childrenNodes = columnsData[updateGroup][colKey]?.children
            if (!childrenNodes) {
                continue
            }
            for (let rowCounter=0; rowCounter<rowsPerGroup; rowCounter++ ) {
                thisRowIndex = firstRowIndex + rowCounter
                const cellNode = childrenNodes[rowCounter] as HTMLElement
                var {value:cellContent, isErroneous} = getCellHTML_fromColKeyAndRowIndex( colKey, thisRowIndex );
                cellNode.style.color = ( showErrorsInRed && isErroneous && cellContent !== '' )
                        ? 'red'
                        : ( colDataType === 'hyperlink')
                            ? constants.LINK_COLOR
                            : foregroundColor
                if ( isNumber && cellContent.slice(0,6) === '<span>' ) {
                    cellNode.innerHTML = cellContent  // Exponential format:
                } else {
                    cellNode.innerText = cellContent  // default text values.
                }
            }
        }
    }
    return staleGroups
}


const redrawVertScrollControl_fromScrollPosition = ( scrollPosition: number, 
                                            containerPx: number, contentPx: number ) : void => {
    var {vertScrollRail:railNode, vertScrollThumb:thumbNode} = tableDomNodes 
    if ( railNode?.style ) { 
        railNode.style.height = px(containerPx)
        // IF/When NOT needed, we hide the scrollControl
        const isHidden = (containerPx >= contentPx)  
        railNode.style.visibility = isHidden ? 'hidden' : 'unset'
    }
    const thumbOffset = calcThumbOffset_fromScrollValue( containerPx, contentPx, scrollPosition )
    const thumbLength = calcThumbLength( containerPx, contentPx )
    if ( thumbNode?.style ) {
        thumbNode.style.height = px(thumbLength)
        thumbNode.style.transform = `translate(0px, ${thumbOffset}px)`
    }
}



