import type { Property } from 'csstype'
import invariant from 'invariant'
import { PureComponent } from 'react'
import StyleEnumSlider from './StyleEnumSlider'

type OwnProps = {
  indent: number     // slider placement in SideBar
  preSpacer: number  // slider placement in SideBar
  displayFixed: number
  modPath: string | string[]
  numSteps: number
  currentValue: number
  styleName: string
  valMax: number
  valMin: number
  layoutLines: number
}
type DefaultProps = {
  visibility: Property.Visibility
  marginRight: number
  titleWidth: number | undefined
  sliderWidth: number | undefined
  displayWidth: number | undefined
}
type Props = OwnProps & DefaultProps


// Wrapper around StyleEnumSlider.
// Converts inputs: numSteps, valMax, valMin, displayFixed =>  [enumLabels], [enumValues]
// Values vary exponentially from valMin to valMax inclusive.

export default class StyleExpEnumSlider extends PureComponent<Props> {

  enumLabels: string[] = Array<string>()
  enumValues: number[] = Array<number>()

  static defaultProps: DefaultProps = {
    visibility: 'unset',
    marginRight: 0,
    titleWidth: undefined,
    sliderWidth: undefined,
    displayWidth: undefined,
  }

  constructor(props: Props) {
    super(props)
    const {displayFixed, numSteps, valMax, valMin} = this.props
    invariant (numSteps > 0, 'StyleExpEnumSlider requires a non-Zero numSteps')
    for( let i=0; i<=numSteps; i++ ) {
      var userVal = valMin * Math.pow( valMax/valMin, i/numSteps )
      this.enumLabels.push( userVal.toFixed(displayFixed) )
      this.enumValues.push( Number(userVal.toFixed(displayFixed)) )
    }
    //console.log( 'enumLabels and Values', this.enumLabels, this.enumValues )
  }

  render() {
    const {modPath, currentValue, styleName, indent, preSpacer, sliderWidth,
       layoutLines, titleWidth, displayWidth} = this.props
    return (
      <StyleEnumSlider
        indent={indent}
        preSpacer={preSpacer}
        titleWidth={titleWidth}
        enumLabels={this.enumLabels}
        enumValues={this.enumValues}
        modPath={modPath}
        currentValue={currentValue}
        styleName={styleName}
        layoutLines={layoutLines}
        sliderWidth={sliderWidth}
        displayWidth={displayWidth}
      />
    )
  }
}
