import { Node } from 'estree'
import invariant from 'invariant'
import type { ReactNode } from 'react'
import constants from '../sharedComponents/constants'
import { typedKeys } from './utils'
import { ColDataTypes, formattingOptions_by_colDataType, type PrecisionMode } from '../types'

const thinSpaceChar = constants.thinSpaceChar
const hairSpaceChar = constants.hairSpaceChar
const multiplyChar  = constants.multiplyChar

export type FormattingObj = {
  // Params and constraints that manage the colHeader behaviors.
  rule: FormatRule,
  forceFullPrecision: boolean,   // Forces 'min' mode and 16 digits precision.  Used for poor man's editors.
  selectionDropDownText: string,
  //shouldForceUnits: boolean,
  forcedUnits?: string,
  allowsPrefixSuffix: boolean,
  supportedModes: PrecisionMode[],

  // User's formatting parameters.  'Units' from tableid; Rest from tablelookid.
  // Suggested values.  May be over-ridden by higher level constraints
  prefix: string,
  suffix: string,
  precisionMode: PrecisionMode,
  precisionMin: number,
  precisionFixed: number,
  units: string,

  // Params passed to the numberFormat function
  firstExpUsageUpper : number,
  firstExpUsageLower : number,
  precMode: PrecisionMode,    // Note slightly different name than corresponding state param name.
                              // Because we may 'force' a change in the users desired 'mode'
  precision: number,
  isEngineering: boolean,
  isForcedExp: boolean,
  forcedExp: number,
  prefixStrg: string,   // value used for actual formatted - either '' or prefix.
  suffixStrg: string,   // value used for actual formatted - either '' or prefix.
  useCommas: boolean,

}

/*   PRECISION MODE

precisionMode: 'std'  Always this number of significant figures
                      For example, if value is 4:
                      1.234, 0.001234,  123.4,   1.234e2 (Scientific),  123.4e3 (Eng)
                      And we pad with zeros as necessary:
                      1,200  0.001200   120.0    1.200e2                120.0e3
                      (Actually code ALWAYS includes all full precision zero's, and simply we choose NOT to strip them.)

precisionMode: 'fixed'   Always this number of digits after the decimal pt
                      For example, if value is 2:
                      1230.00  123.00  12.30   1.23   0.12    0.01
                      And examples using exponential notation, use the displayed decimal pt location, NOT the true 1's place digit location.
                      1.23e3   12.30e3   123.00e3,  1.23e-6, 12.30e-3, 1.23e-3
                      Note above rules are ambigous for some numbers.
                      For example, consider 9.55e3, fixed at 2 digits of accuracy (after the decimal).
                      Both 9.55e3 and 1.0e4 are valid solutions.  For numbers in this range of ambiguity,
                      the code (by design) will take the larger (in magnitude) of the two legal solutions.
                      We can change our mind if/when we choose on this ambiguity.

precisionMode: 'min'  Our defaultPrecision
                      The passed parameter value represents an upper limit on the number of significant Figures (same as 'std')
                      However, we strip any trailing '0' digits when possible.
                      Examples:  instrg => outStrg  Assume a upper precision limit of 4
                      1.2 => 1.2  1.25 =>1.25   1.253 => 1.253  1.2538 => 1.254 1.299999999999999 => 1.300
                      And we strip ending zeros when possible:
                      1.000 => 1   1.200 => 1.2   1.204 => 1.204  12000 => 12000
                      precisionMode 'min' and precisionMode 'std', use the same underlying format function,
                      except in the case of 'min', one final operation to strip unnecessary trailing '0's

For above 3 modes, we need at least two states to save the user's value. 
If they switch back/forth between modes, the interface for number of digits resets to the last used value.
precisionMin:    0 to 'n'   One shared value for preModes 'std' and 'min' ( which switch between padding with zero's or not)
precisionFixed:  0 to 'n'

Above values reflect the saved and displayed state of the user interface!
They are usually, BUT NOT ALWAYS, the values used for formatting.
Often, the code will force a format other than what the user is requesting for the table column.
For example the plotAxis ticks labels, or the statsBar formatting.

The underlying formatting function is passed the values: 'precMode' and 
'precision'  (shortened versions of the user interface state names)

Typically (NOT ALWAYS):
  precMode  (value used passed to formatting) = precisionMode (interface state value)
  precision (value used passed to formatting) = if precisionMode === 'fixed' then precisionFixed
                                                if precisionMode === 'std'   then precisionMin and we do not strip trailing '0' digits
                                                if precisionMode === 'min'   then precisionMin and we do     strip trailing '0' digits
*/

export const NO_EXPONENT_RULE_FIRST_EXPONENT = 24   // +/- limits
export const ENG_FIRST_EXP_USAGE_UPPER  =  6
export const ENG_FIRST_EXP_USAGE_LOWER  = -3
const MAX_TEMPORAL_FORMAT_EXPONENT =  4  // Will change to  exponential form at 9999:59:59 +1  (or 9999:59 + 1)
const MIN_TEMPORAL_FORMAT_EXPONENT = -3  // Will change to -exponential form at 0:00:00.001
const MAX_TEMPORAL_FORMAT_LIMIT  =  Math.pow(10, MAX_TEMPORAL_FORMAT_EXPONENT )
const MIN_TEMPORAL_FORMAT_LIMIT  =  Math.pow(10, MIN_TEMPORAL_FORMAT_EXPONENT )
const EPSILON  = Math.pow(2,-52)
const EPSILON2 = Math.pow(2,-51)



// We define the user's formattingParams as:
//       prefix, suffix, precisionMode, precisionMin, precisionFixed
// These are the identical attributes names that are saved to the user's tablelookid.
//
// The function below assign's default values to all 5 user's formatting values.
// If/When the input arg formatOverrides is available, we will look specifically
// for the 5 user's formatting params.  If we find any, the found values will
// override the defaults.  In practice, formatOverrides is used in one of four ways
//
// 4 ways to commonly use formatOverrides:
//  1) Pass no 2nd argument.  The formatRule's defaults (defined below) are used.
//  2) Pass the lookColumns[colKey] object. It contains all five user formatting
//     attributes and the user's 5 state parameters will override the defaults.
//  3) Pass the prior formatting object (for example if we change the formatRule)
//     In this case the current user format state values are found in the prior
//     formattingObj and are 'inherited' by the new formattingObj.
//  4) As a general number formatter for things like statsBar, poorMan's editors,
//     plotAxis values, ...   One can set one or more of these format params for
//     the local, specific usage.  For example, statsBar Kurtosis value is formatted
//     with 'fixed' mode and 2 digits of precision.  So we use 'defaultEng' formatRule,
//     and pass formatOverrides  {precMode:'fixed', precisionFixed:2}.

const sexagesimalFormattingObj : FormattingObj = {
  rule: 'internal',  selectionDropDownText: 'placeholder',
  forceFullPrecision: false,
  //shouldForceUnits: true,
  forcedUnits: 'placeholder',
  allowsPrefixSuffix:true,
  supportedModes: ['fixed'],

  // Put default values here.  These must have the same names as user's state values.
  // These are usually (but not always) over-ridden by the user's state values.
  prefix:'', suffix:'',
  precisionMode: 'min',
  precisionMin:6, precisionFixed:0, units:'',

  // The raw params used by the formatting function their default values:
  // Some of these MAY be overriden by prior userFormattingParams
  firstExpUsageUpper : 6, firstExpUsageLower : -3,  // Not used by sexagesimal format function
  precMode: 'fixed', precision: 0,
  isEngineering: false ,
  isForcedExp: false, forcedExp: 0,
  prefixStrg: '', suffixStrg: '', useCommas:true,
}



export const formatRulesByDataType = formattingOptions_by_colDataType

const display_mmss    = `hh${hairSpaceChar}:${hairSpaceChar}mm`
const display_hhmmss  = `hh${hairSpaceChar}:${hairSpaceChar}mm${hairSpaceChar}:${hairSpaceChar}ss`
// const display_degmm   = `Deg\u00B0${thinSpaceChar}mm\u2032`
// const display_degmmss = `Deg\u00B0${thinSpaceChar}mm\u2032${thinSpaceChar}ss\u2033`

const forcedUnitsByDataType = {   // Must be in SAME order as the above arrays
  'hyperlink'     : [ 'Link' ],
  'string'        : [ 'Text' ],
  'number'        : [ '', '', '', 'True/False', '' ],  // '' empty means user will define the units string.
  'numberSeconds' : [ display_hhmmss,  display_mmss,  'seconds', 'seconds', 'seconds', 'True/False', 'seconds' ],
  // 'numberDegrees' : [ display_degmmss, display_degmm, 'degrees', 'degrees', 'degrees', 'True/False', 'degrees' ],
}


export const lookupTableForForcedUnits = ( colDataType : ColDataTypes, formatRule: FormatRuleEndUser ) :
     { shouldForceUnits: boolean, forcedUnits: string} => {

  if ( colDataType === 'hyperlink'     ) { return {shouldForceUnits:true,  forcedUnits: 'Link'} }
  if ( colDataType === 'string'        ) { return {shouldForceUnits:true,  forcedUnits: 'Text'} }
  if ( formatRule  === 'boolTrueFalse' ) { return {shouldForceUnits:true,  forcedUnits: 'True/False'} }
  // boolTrueFalse test MUST precede next test:
  if ( colDataType === 'number'        ) { return {shouldForceUnits:false, forcedUnits: ''} }

  if ( colDataType === 'numberSeconds' /* || colDataType === 'numberDegrees'*/ ) {
    var index = formatRulesByDataType[colDataType].indexOf( formatRule )
    if ( index === -1 ) {
      if ( process.env.NODE_ENV === 'development' ) {
        invariant( false, `FormatRule '${formatRule}' not found in colDataType '${colDataType}'` )
      }
    } else {
      var forcedUnits = forcedUnitsByDataType[colDataType][ index ]
      return {shouldForceUnits: forcedUnits.length>0, forcedUnits}
    }
  }

  // Next line should be unreachable.
  return {shouldForceUnits: false, forcedUnits: ''}
}

export type FormatRuleEndUser = 'defaultString' | 'defaultLink' | 'defaultEng' | 'scientific' | 'noExponent' | 'internal' | 'boolTrueFalse' | 'B60seconds' | 'B60B60seconds' | 'B60degrees' | 'B60B60degrees'
export type FormatRule = FormatRuleEndUser | 'forcedExponent' | 'commasOnly' | 'B60_exponentialFallback' | 'percent'

export const getFormattingObj = ( formatRule: FormatRule, formatOverrides: Partial<FormattingObj> = {} ) : FormattingObj => {

  var formattingObj : FormattingObj

  switch ( formatRule ) {
    case 'defaultString':
    case 'defaultLink':
        formattingObj = {
          rule: formatRule,  selectionDropDownText: '',  // No type selection is provided.
          forceFullPrecision: false,

          allowsPrefixSuffix:true,
          supportedModes: [],
          // Not used or needed for text/link formatting. Just filling out the FormattingObj data type.
          firstExpUsageUpper : 0, firstExpUsageLower : 0,
          precMode: 'min', precision: 0,
          isEngineering: false ,
          isForcedExp: false, forcedExp: 0,
          prefixStrg: '', suffixStrg: '', useCommas:false,
          // Put default values here.  These must have the same names as user's state values.
          // These are usually (but not always) over-ridden by the user's state values.
          prefix:'', suffix:'',
          precisionMode: 'min',
          precisionMin:6, precisionFixed:2, units:''
        }
        break

    case 'defaultEng' :
    case 'scientific' :
        formattingObj = {
          rule: formatRule,  selectionDropDownText: 'Engineering',
          forceFullPrecision: false,

          allowsPrefixSuffix:true,
          supportedModes: ['min', 'std', 'fixed'],
          // The raw params used by the formatting function their default values:
          // Some of these MAY be overriden by prior userFormattingParams
          firstExpUsageUpper : 6, firstExpUsageLower : -3,
          precMode: 'min', precision: 6,
          isEngineering: (formatRule==='defaultEng') ? true : false ,
          isForcedExp: false, forcedExp: 0,
          prefixStrg: '', suffixStrg: '', useCommas:true,
          // Put default values here.  These must have the same names as user's state values.
          // These are usually (but not always) over-ridden by the user's state values.
          prefix:'', suffix:'',
          precisionMode: 'min',
          precisionMin:6, precisionFixed:2, units:''
        }
        break

    case 'forcedExponent' :
        formattingObj = {
          rule: 'scientific',  selectionDropDownText: 'Forced Exponent',
          forceFullPrecision: false,

          allowsPrefixSuffix:true,
          supportedModes: ['min', 'std', 'fixed'],
          // The raw params used by the formatting function their default values:
          // Some of these MAY be overriden by prior userFormattingParams
          firstExpUsageUpper : 1, firstExpUsageLower : -1,
          precMode: 'min', precision: 6,
          isEngineering: false ,
          isForcedExp: true, forcedExp: 0,
          prefixStrg: '', suffixStrg: '', useCommas:true,
          // Put default values here.  These must have the same names as user's state values.
          // These are usually (but not always) over-ridden by the user's state values.
          prefix:'', suffix:'',
          precisionMode: 'min',
          precisionMin:6, precisionFixed:2, units:''
        }
        break

    case 'noExponent' :
        formattingObj = {
          rule: 'noExponent',  selectionDropDownText: 'No Exponents',
          forceFullPrecision: false,

          allowsPrefixSuffix: true,
          supportedModes: ['min', 'std', 'fixed'],
          // The raw params used by the formatting function their default values:
          // Some of these MAY be overriden by prior userFormattingParams
          firstExpUsageUpper : 24, firstExpUsageLower : -24,
          precMode: 'min', precision: 6,
          isEngineering: true,
          isForcedExp: false, forcedExp: 0,
          prefixStrg: '', suffixStrg: '', useCommas:true,
          // Put default values here.  These must have the same names as user's state values.
          // These are usually (but not always) over-ridden by the user's state values.
          prefix:'', suffix:'',
          precisionMode: 'min',
          precisionMin:6, precisionFixed:2, units:''
        }
        break


    case 'internal' :
        formattingObj = {
          rule: 'defaultEng',  selectionDropDownText: 'Internal Value',
          forceFullPrecision: true,

          allowsPrefixSuffix:true,
          supportedModes: ['min'],
          // The raw internal params used by the formatting function
          firstExpUsageUpper : 6, firstExpUsageLower : -3,
          precMode: 'min', precision: 16,
          isEngineering: true,
          isForcedExp: false, forcedExp: 0,
          prefixStrg: '', suffixStrg: '',  useCommas:false,
          // Put default values here.  These must have the same names as user's state values.
          // These are usually (but not always) over-ridden by the user's state values.
          prefix:'', suffix:'',
          precisionMode: 'min',
          precisionMin:16, precisionFixed:1, units:''
        }
        break

    case 'commasOnly' :
          formattingObj = {
            rule: 'defaultEng',  selectionDropDownText: 'Commas Only',
            forceFullPrecision: false,

            allowsPrefixSuffix:false,
            supportedModes: ['min'],
            // The raw params used by the formatting function their default values:
            // Some of these MAY be overriden by prior userFormattingParams
            firstExpUsageUpper : 15, firstExpUsageLower : -3,
            precMode: 'min', precision: 16,
            isEngineering: false,
            isForcedExp: false, forcedExp: 0,
            prefixStrg: '', suffixStrg: '',  useCommas:true,
            // Put default values here.  These must have the same names as user's state values.
            // These are usually (but not always) over-ridden by the user's state values.
            prefix:'', suffix:'',
            precisionMode: 'min',
            precisionMin:15, precisionFixed:0, units:''
          }
          break

    case 'boolTrueFalse' :
        formattingObj = {
          rule: 'boolTrueFalse',  selectionDropDownText: 'True/False (0 => False; not 0 => True)',
          forceFullPrecision: false,

          allowsPrefixSuffix:false,
          supportedModes: [],
          // The raw params used by the formatting function their default values:
          // Some of these MAY be overriden by prior userFormattingParams
          firstExpUsageUpper : 0, firstExpUsageLower : 0,
          precMode: 'min', precision: 0,
          isEngineering: false,
          isForcedExp: false, forcedExp: 0,
          prefixStrg: '', suffixStrg: '',  useCommas:false,
          // Put default values here.  These must have the same names as user's state values.
          // These are usually (but not always) over-ridden by the user's state values.
          prefix:'', suffix:'',
          precisionMode: 'min',
          precisionMin:1, precisionFixed:0, units:''
        }
        break

  case 'B60seconds' :
        formattingObj = { ...sexagesimalFormattingObj,
          rule: formatRule,
          selectionDropDownText: 'mm:ss',
        }
        break

  case 'B60B60seconds' :
        formattingObj = { ...sexagesimalFormattingObj,
          rule: formatRule,
          selectionDropDownText: 'hh:mm:ss',
        }
        break

  case 'B60degrees' :
        formattingObj = { ...sexagesimalFormattingObj,
          rule: formatRule,
          forcedUnits: `Deg\u00B0${thinSpaceChar}mm\u2032`,
          selectionDropDownText: `Deg\u00B0${thinSpaceChar}mm\u2032`,
        }
        break

  case 'B60B60degrees' :
        formattingObj = { ...sexagesimalFormattingObj,
          rule: formatRule,
          selectionDropDownText: `Deg\u00B0${thinSpaceChar}mm\u2032${thinSpaceChar}ss\u2033`,
        }
        break

  case 'B60_exponentialFallback' :
        // Switch to this rule for any B60 or B60B60 values that are
        // so large or small, that the B60 format is nonsense.
        // This is used ONLY to format the value.
        // 'Units', DropDownText, prefix, suffix, ...  items come from
        // the B60...   formatting rule. (the rule choosen by users).
        formattingObj = {
          rule: 'defaultEng',  selectionDropDownText: '',
          forceFullPrecision: false,

          allowsPrefixSuffix:true,
          supportedModes: ['min'],
          // The raw params used by the formatting function their default values:
          // Some of these MAY be overriden by prior userFormattingParams
          firstExpUsageUpper : MAX_TEMPORAL_FORMAT_EXPONENT,
          firstExpUsageLower : MIN_TEMPORAL_FORMAT_EXPONENT,
          precMode: 'min', precision: 6,
          isEngineering: true,
          isForcedExp: false, forcedExp: 0,
          prefixStrg: '', suffixStrg: '',  useCommas:false,
          // Put default values here.  These must have the same names as user's state values.
          // These are usually (but not always) over-ridden by the user's state values.
          prefix:'', suffix:'',
          precisionMode:'min',
          precisionMin:6, precisionFixed:2, units:''
        }
        break

    case 'percent' :
        formattingObj = {
          rule: 'defaultEng',  selectionDropDownText: 'PercentRule',
          forceFullPrecision: false,
          //shouldForceUnits: false, forcedUnits: '',    // This is the value displayed at top of column.
          allowsPrefixSuffix:true,
          supportedModes: ['min', 'std', 'fixed'],
          // The raw params used by the formatting function their default values:
          // Some of these MAY be overriden by prior userFormattingParams
          firstExpUsageUpper : 6, firstExpUsageLower : -3,
          precMode: 'std', precision: 3,
          isEngineering: true,
          isForcedExp: false, forcedExp: 0,
          prefixStrg: '', suffixStrg: '', useCommas:false,
          // Put default values here.  These must have the same names as user's state values.
          // These are usually (but not always) over-ridden by the user's state values.
          prefix:'', suffix:'%',
          precisionMode: 'std',
          precisionMin:3, precisionFixed:2, units:''
        }
        break

    default :
        if ( process.env.NODE_ENV === 'development' ) {
          invariant( false, `Non-supported case for formatRule: ${formatRule}` )
        } else {
          // Fall back to default engineering.  But this is an illegal branch
          // that should be caught and code corrected during development.
          return getFormattingObj ( 'defaultEng', formatOverrides )
        }
  }
  Object.seal(formattingObj)

  // Here is where the option override values are inserted into the formattingObj
  for (const thisKey of typedKeys(formattingObj)) {
    if ( formatOverrides[thisKey] !== undefined ) {
      formattingObj[thisKey] = formatOverrides[thisKey]
    }
  }

  // Puts some limits on number of precision digits.  This is only needed
  // if the user's state object becomes 'out-of-date', or some bug in the event handler.
  // But easy to constrain here/now so why not:
  formattingObj.precisionMin   = Math.min( 16, Math.max( 1, formattingObj.precisionMin ))
  formattingObj.precisionFixed = Math.min( 16, Math.max( 0, formattingObj.precisionFixed ))
  // prefix/suffix rules:
  // 1) Use the prefix/suffix value
  // 2) Unless allowsPrefixSuffix === false
  formattingObj.prefixStrg = formattingObj.allowsPrefixSuffix ? formattingObj.prefix : ''
  formattingObj.suffixStrg = formattingObj.allowsPrefixSuffix ? formattingObj.suffix : ''
  // The parameters precMode and precision are the two values used in numberFormat()
  // Note they have slightly different names than the equivalent state names.
  // CASE #1: This formatRule supports NEITHER 'min' nor 'fixed' precision mode.
  if ( formattingObj.supportedModes.length === 0 ) {
    // Example: defaultString, defaultLink, boolTrueFalse
    // do nothing.  None of the precision attributes should be accessed.
  }
  // CASE #2: When poor man's editor, internal format, or any other reason we need full precision.
  // This attribute allows us to provide full precision without having
  // to muck with the user's state or currently supported modes or precision values.
  else if ( formattingObj.forceFullPrecision ) {
    formattingObj.precMode  = 'min'
    formattingObj.precision = 16
  }
  // CASE #3: The current user's precisionMode is not legal.
  // We fall back to the supportedModes[0].  Make sure the supportedMode[0] is the desired default!
  else if ( !formattingObj.supportedModes.includes(formattingObj.precisionMode) ) {
    let overrideMode = formattingObj.supportedModes[0]
    formattingObj.precMode  =  overrideMode
    formattingObj.precision = (overrideMode === 'fixed') ? formattingObj.precisionFixed : formattingObj.precisionMin
  }
  // CASE #4: User's requested precisionMode is supported.
  // Set the number of precision digits according to the choosen mode:
  else {
    formattingObj.precMode  = formattingObj.precisionMode
    formattingObj.precision = (formattingObj.precMode === 'fixed')
       ? formattingObj.precisionFixed
       : formattingObj.precisionMin
  }
  return formattingObj
}



// Next functions assumes input is a positive significand
// NOT doing the extra work for generic numbers here because
// this module only uses these function on unsigned significands.
// Hence, the reason these functions are NOT exported.
// And my code never calls this function with prec > 15.
// Otherwise (like toFixed() below) we need to special case
// numbers with > 15 digits.
const toScryPrecision = (x:string, prec:number ) : string => {
  let y = (Number(x)*(1+EPSILON)).toPrecision(prec)
  if ( Number(y) < EPSILON2 ) { return (0).toPrecision(prec) }
  return y
}

const toScryFixed = (x:string, prec:number ) : string => {
  // We need to worry about numbers where
  //     x.length + prec > 15 digits
  //     x.length by itself > 15 digits.
  // Problem:
  //    We can't add epsilon to x when the whole part is large
  //    without losing precision in our fixed places right of decimal.
  // For example, if whole part of x is 9 digits, then epsilon becomes
  // significant 15 digits later ( at e-6 digit. )
  // Solution:
  //    We can set fixed precision accurately out to all 15 allowed places
  //    by working only with the fractional part, regardless of the
  //    whole part's magnitude.
  // This is especially important for the noExponent format,
  // where the fixed precision may be appended to a long string of
  // whole digits.
  let parts = x.split( '.' )
  // Case of NO DECIMAL PT
  if ( parts.length === 1 && prec === 0 ) {
    return x
  }
  if ( parts.length === 1 && prec > 0 ) {
    // Not using javaScript toFixed as it doesn't
    // work with x greater than 15 digits
    return x + '.' + '0'.repeat(prec)
  }

  // Case of DECIMAL PT, BUT WHOLE PART USES ALL AVAILABLE PRECISION
  if ( parts[0].length > 15 && prec === 0 ) {
    return x
  }
  if ( parts[0].length > 15 && prec > 0 ) {
    return x + '.' + '0'.repeat(prec)
  }

  // Common case of some precision still available to round the fractional part, and precision > 0
  // We will work only with the fractional part right of the decimal pt.
  let xFracFixed = (Number( '.' + parts[1] ) + EPSILON ).toFixed(prec)
  // Above value will be of the form a.xxx
  // a is always 0 or 1, depending on whether fractional part rounded up.
  // number of 'x' digits === fixed precision.

  // We can do math with the next line because the wholePart is < 15 digits,
  // its an integer, and we can add '1' to its value in the case of rounding
  // up, but without losing any precision in the whole part.
  let wholePart = Number(parts[0])
  if ( Number(xFracFixed) === 1 ) { wholePart++ }
  xFracFixed = xFracFixed.slice(1)  // Drop the digit prior to the decimal pt. ( always a zero or one)
  let fullString = String(wholePart) + xFracFixed
  return fullString
}



export const isB60Format = (rule: string): boolean => {
  return rule.slice(0,3) === 'B60'
}
export const isBooleanFormat = (rule:string): boolean => {
  return (rule === 'boolTrueFalse')
}
export const isNotB60_or_BooleanFormat = (rule:string): boolean => {
  return ( !isB60Format(rule) && !isBooleanFormat(rule) )
}



export const addCommas = (nStr: string) : string => {
    if (nStr.length === 0) { return '' }

    var left='', right='', frac='', newFrac=''
    var result = nStr.split('.')
    left = result[0]
    // No commas for 4 digit numbers:
    if (left.length > 4) {
      var rgx = /(\d+)(\d{3})/
      while (rgx.test(left)) {
        left = left.replace(rgx, '$1,$2')
      }
    }

    if (result.length > 1) {
      right = result[1]
      result = right.split('e')
      frac = result[0]
      if (frac.length<=4) {
        newFrac=frac
      } else {
        newFrac=frac.slice(0,3)
        var len = frac.length
        for (let i=3;i<len; i++) {
          if ( i%3===0 && i<len-1 ) newFrac += thinSpaceChar  // inserting a thin space
          newFrac += frac[i]
        }
      }
    }
    return left + ((newFrac.length>0) ? `.${newFrac}`   : '') +
                  ((result.length >1) ? `e${result[1]}` : '')
}




const doesValueExceedSexagesimalFormatLimits = ( value: number, isDoubleB60 : boolean) : boolean => {
  if ( value === 0 ) return false
  if (  ( isDoubleB60 && value >= MAX_TEMPORAL_FORMAT_LIMIT * 3600 ) ||
        (!isDoubleB60 && value >= MAX_TEMPORAL_FORMAT_LIMIT * 60 )   ||
        value <= MIN_TEMPORAL_FORMAT_LIMIT )  return true
  return false
}

export const sexagesimalFormats = (numStr:string, formatParams: FormattingObj ): string => {
  var {precision, rule} = formatParams

  var value = Number(numStr)
  let sign:string = value < 0 ? '-' : ''  // Note sign value is a string!
  value = Math.abs(value)
  let isDoubleB60 = ( rule.slice(0,6) === 'B60B60' )
  let isSeconds   = ( rule.slice(-7)  === 'seconds' )

  // CASE degrees input to be written as deg mm ss
  if ( !isSeconds &&  isDoubleB60 ) { value *= 3600 }
  // CASE degrees input to be written as deg mm
  else if ( !isSeconds && !isDoubleB60 ) { value *= 60 }

  if ( doesValueExceedSexagesimalFormatLimits(value, isDoubleB60) ) {
    // Was this formatting request originally generated by a poor man's editor?
    // In which case formatParams.forceFullPrecision will equal true.
    // We want formatRule 'B60_exponentialFallback' to inherit this attribute.
    let isFullPrecision = formatParams.forceFullPrecision
    let formattingFallback = getFormattingObj('B60_exponentialFallback', {forceFullPrecision:isFullPrecision} )
    return basicNumberFormat( numStr, formattingFallback)
  }

  // Precision mode is always 'Fixed'. By definition of the B60 formats.
  // We use the decimal point to break the number (as a string) into
  // whole and fractional parts:
  var unSignedStr    = String(value)
  var decimalPtIndex = unSignedStr.indexOf('.')
  // If no decimal pt, then implied decimal pt is after last char
  if ( decimalPtIndex === -1 ) { decimalPtIndex = unSignedStr.length }

  if ( formatParams.forceFullPrecision ) {
    var wholePart      = unSignedStr.slice(0,decimalPtIndex)
    var fractionalPart = unSignedStr.slice(decimalPtIndex)
  } else if  ( precision === 0 ) {
    wholePart = String( Math.round(value))
    fractionalPart = ''
  } else {   // Using Fixed precision > 0
    var roundedVal = value.toFixed(precision)
    decimalPtIndex = roundedVal.indexOf('.')
    wholePart      = roundedVal.slice(0,decimalPtIndex)
    fractionalPart = roundedVal.slice(decimalPtIndex)
  }

  // Cast the whole part into B60B60 notation using exact integer math.
  let wholePartNum = Number(wholePart)
  var seconds, minutes, hours
  if ( isDoubleB60 ) {
    seconds = wholePartNum
    minutes = Math.floor(seconds / 60)
    hours   = Math.floor(minutes / 60)
    seconds = wholePartNum - 60*minutes
    minutes = minutes - 60*hours
    if ( isSeconds) {
      return `${sign}${hours}:` +
             `${minutes<10 ? '0' : ''}${minutes}:` +
             `${seconds<10 ? '0' : ''}${seconds}${fractionalPart}`;
    } else {
      return `${sign}${hours}\u00B0${thinSpaceChar}` +
             `${minutes<10 ? '0' : ''}${minutes}\u2032${thinSpaceChar}` +
             `${seconds<10 ? '0' : ''}${seconds}${fractionalPart}\u2033`
      //return `${sign}${hours}\u00B0` +
      //      `${minutes<10 ? '0' : ''}${minutes}\u2032` +
      //      `${seconds<10 ? '0' : ''}${seconds}${fractionalPart}\u2033`
    }
  }

  // Else this is single B60 format;  Could be deg/arcMinutes.   OR:  minutes:seconds
  // I'll write the code as minutes/seconds.  However, be careful in formating
  // that the minute/sec values will map to degrees/minutes !!
  seconds = wholePartNum
  minutes = Math.floor(seconds / 60)
  seconds = Number(wholePart) - 60*minutes
  if ( isSeconds) {
    return  `${sign}${minutes}:` +
            `${seconds<10 ? '0' : ''}${seconds}${fractionalPart}`
  } else {
    return  `${sign}${minutes}\u00B0${thinSpaceChar}` +
            `${seconds<10 ? '0' : ''}${seconds}${fractionalPart}\u2032`
  }
}



export const minFormatByStrippingTrailingZeros = ( inStrg:string ) : string => {
  var outStrg = inStrg.replace( /(\.\d*?[1-9])0+$/g, '$1' )     // deletes trailing zeros only
  outStrg = outStrg.replace( /\.0*$/, '' )   // deletes a trailing decimal pt.  or trailing '.0'
  return outStrg
}


const getNumSignificantFigures = ( s:string ):number => {
  let len = s.length
  if (s.indexOf('.') >=0) { len-- }
  return len
}


const determineTheOutputFormatExponent = ( roundedExp:number, fp:FormattingObj ) : {formattedExp:number, useExponentialForm:boolean } => {
  let useExponentialForm = false
  let formattedExp = 0
  if ( fp.isForcedExp ) {   // currently used by smartAxis to create tick labels; may or may not use exponent notation
    formattedExp = fp.forcedExp
    useExponentialForm = ( formattedExp >= fp.firstExpUsageUpper || formattedExp <= fp.firstExpUsageLower)
  } else if ( roundedExp < fp.firstExpUsageUpper && roundedExp > fp.firstExpUsageLower ) {   // no exponent notation
    formattedExp = 0
    useExponentialForm = false
  } else if ( fp.isEngineering ) {  // Engineering notation
    formattedExp = 3 * Math.floor( Number(roundedExp)/3 )
    useExponentialForm = true
  } else {  // Scientific notation
    formattedExp = roundedExp
    useExponentialForm = true
  }
  return { formattedExp, useExponentialForm }
}


// Shift significand's decimal pt to match the 'desired' formatted exponent
const shiftSignificandDecimalPt = ( significand:string, significantFigures:number, roundedExp:number, formattedExp:number, DEBUG=false ) : string => {
  var shiftedSignificand = significand
  if ( roundedExp !== formattedExp ) {
    let decimalShift = roundedExp - formattedExp
    shiftedSignificand = significand.replace( '.', '' )
    if (decimalShift < 0) {   // move decimal pt to left
      shiftedSignificand = '0.'+ '0'.repeat( -decimalShift - 1  ) + shiftedSignificand
      if (DEBUG) {console.log( `After shifting decimal pt to left by ${decimalShift}:`, significand)}
    } else if ( decimalShift < significantFigures - 1 ) { // move decimal pt to right but no need to append any new zeros
      shiftedSignificand = shiftedSignificand.slice(0,decimalShift+1) + '.' + shiftedSignificand.slice(decimalShift+1)
      if (DEBUG) {console.log( `After shifting decimal pt to right by ${decimalShift} (no padding needed):`, significand)}
    } else if ( decimalShift >= significantFigures - 1) {  // significand becomes an integer (no decimal pt.)
      shiftedSignificand = shiftedSignificand + '0'.repeat( decimalShift - significantFigures+1 )
      if (DEBUG) {console.log(`After shifting decimal pt to right by ${decimalShift} (${decimalShift - significantFigures+1} padding needed):`, significand)}
    }
  }
  return shiftedSignificand
}


const breakNumberIntoFragments = ( inStrg:string ) :
  { inSign:string, inSignificand:string, inSignificantFigures:number, inExponent:string, writtenExponent:string } => {

    const jsExpForm:string     = Number(inStrg).toExponential()
    const index:number         = jsExpForm.indexOf( 'e' )
    var inSignificand:string   = jsExpForm.slice( 0, index )
    var writtenExponent:string = jsExpForm.slice( index + 1)
    if (writtenExponent[0] === '+' ) writtenExponent = writtenExponent.slice(1)
    var inSign:string          = ( Number(inStrg) < 0 ) ? '-' : ''
    if (inSign === '-' ) { inSignificand = inSignificand.slice(1) }
    var inSignificantFigures   = getNumSignificantFigures(inSignificand)
    var inExponent:string      = writtenExponent
    return { inSign, inSignificand, inSignificantFigures, inExponent, writtenExponent }
}



export const basicNumberFormat = ( inStrg:string, formatParamsIn:FormattingObj, DEBUG:boolean=false) : string => {

  //  Break the number into Fragments.
  /*
  const jsExpForm:string     = Number(inStrg).toExponential()
  const index:number         = jsExpForm.indexOf( 'e' )
  var inSignificand:string   = jsExpForm.slice( 0, index )
  var writtenExponent:string = jsExpForm.slice( index + 1)
  if (writtenExponent[0] === '+' ) writtenExponent = writtenExponent.slice(1)
  var inSign:string          = ( Number(inStrg) < 0 ) ? '-' : ''
  if (inSign === '-' ) { inSignificand = inSignificand.slice(1) }
  var inSignificantFigures   = getNumSignificantFigures(inSignificand)
  var inExponent:string      = writtenExponent */
  var { inSign, inSignificand, inSignificantFigures, inExponent, writtenExponent } = breakNumberIntoFragments( inStrg )
  if (DEBUG) {
    console.log( 'basicNumberFormat()')
    console.log( '    inputStrg', inStrg )
    console.log( '    formatParams', formatParamsIn)
    console.log( '    inSign', inSign )
    console.log( '    inSignificand', inSignificand )
    console.log( '    inSignificantFigures', inSignificantFigures )
    console.log( '    writtenExponent', writtenExponent )
    console.log( '    inExponent', inExponent )
  }

  var formatParams : FormattingObj
  if ( formatParamsIn.forceFullPrecision ) {
    formatParams = {...formatParamsIn, precision:15, precMode:'min'  }
  } else {
    formatParams = formatParamsIn
  }

  var precision = Math.min( formatParams.precision, 15 )
  if ( formatParams.precMode === 'fixed' ) {
    var roundedSignificand = inSignificand   // Can't round UNTIL we know the exponent
  } else {
    roundedSignificand = toScryPrecision( inSignificand, precision )
  }
  let roundedSignificantFigures = getNumSignificantFigures(roundedSignificand)
  let roundedExponent = Number(inExponent)
  // Whenever we round, check that significand stays of form x.xxxx
  if (Number(roundedSignificand) === 10 ) {  // It rounded up to next place digit!
    roundedSignificand = '1.' + '0'.repeat(roundedSignificantFigures-1)
    roundedExponent++
  }

  // What exponent do we actually wish to display?
  var { formattedExp, useExponentialForm } = determineTheOutputFormatExponent ( roundedExponent, formatParams )

  if (DEBUG) {
    console.log(   'basicNumberFormat() afterRounding:')
    console.log(   '    inSign', inSign )
    console.log(   '    roundedSignificand', roundedSignificand )
    console.log(   '    roundedSignificantFigures', roundedSignificantFigures )
    console.log(   '    roundedExponent', roundedExponent )
    console.log(   '    formattedExp', formattedExp)
  }

  // Shift significand's decimal pt to match the 'desired' formatted exponent
  roundedSignificand = shiftSignificandDecimalPt ( roundedSignificand, roundedSignificantFigures, roundedExponent, formattedExp, DEBUG )
  if (DEBUG ) {
    console.log(   'basicNumberFormat() shifting Decimal pt:')
    console.log(   '    inSign', inSign )
    console.log(   '    roundedSignificand', roundedSignificand )
    console.log(   '    roundedSignificantFigures', roundedSignificantFigures )
    console.log(   '    formattedExp', formattedExp)
  }


  // Now handle the difficult case of precisionMode === 'fixed'
  // Can't determine final exponent until we round. example: 9.99e3 after rounding is 1e4.
  // Can't round until we determine the exponent, because our fixed rule rounding place depends on exponent.
  // But this works => find Exponent; shift decimal pt; round to fixed; re-findExponent; re-shift decimal pt; re-round to fixed
  // The 2nd pass is ONLY needed when 'fixed', and 'didRoundToNextDigit'.
  if (  formatParams.precMode === 'fixed' ) {
    let parts = roundedSignificand.split( '.' )
    let digitsLeftOfDecimal = parts[0].length
    roundedSignificand = toScryFixed( roundedSignificand, formatParams.precision )
    roundedSignificantFigures = getNumSignificantFigures( roundedSignificand )

    // Fixed rounding can 'round-up' to the next significant digit.
    // And depending on the exponent and current rule, there may be a simpler
    // fixed representation.  For example if 9.9...e3 rounds up to 10e3, then
    // in scientific notation, this should become 1e4.  But in exponential notation
    // 10e3 is still the best answer.
    // We check for rounding up to a new significant figure.  If 'true',
    //   - Put rounded form back into scientific notation (1eExp)
    //   - then rerun the functions to find the desired exponent and corresponding decimally shifted significand.
    parts = roundedSignificand.split('.')
    let newDigitsLeftOfDecimal = parts[0].length
    let didRoundToNextDigit = ( digitsLeftOfDecimal !== newDigitsLeftOfDecimal )
    if (didRoundToNextDigit) {
      // Put the rounded significand (it now has one additional place digit) into the form: 1.00... e?
      roundedSignificand = '1.' + '0'.repeat( roundedSignificantFigures - 1 )    // Cheap way to shift the decimal pt to second character.
      // roundedSignificantFigures is unchanged.  We are just shifting the decimal pt.
      roundedExponent = formattedExp + newDigitsLeftOfDecimal - 1;   // This is how far the decimal was shifted
      // Redo the prior calculation of proper formatted Exp and properly shifted decimal location.
      ({ formattedExp, useExponentialForm } = determineTheOutputFormatExponent ( roundedExponent, formatParams ))
      roundedSignificand = shiftSignificandDecimalPt ( roundedSignificand, roundedSignificantFigures, roundedExponent, formattedExp, DEBUG )
      // roundedSignificand may or may not have the correct precision!
      roundedSignificand = toScryFixed( roundedSignificand, formatParams.precision )
      roundedSignificantFigures = getNumSignificantFigures( roundedSignificand )
      if (DEBUG ) {
        console.log(   'Fixed Form AND significand roundedUp to next significant digit' )
        console.log(   'basicNumberFormat() after roundedUp to next significant digit:')
        console.log(   '    inSign', inSign )
        console.log(   '    roundedSignificand', roundedSignificand )
        console.log(   '    roundedSignificantFigures', roundedSignificantFigures )
        console.log(   '    formattedExp', formattedExp)
      }
    }
  }


  // Various forms of zero, for different precision Modes
  var zeroString = '0'    // default is the 'min' mode
  if ( formatParams.precMode === 'std' ) {
    zeroString = '0.'+'0'.repeat(formatParams.precision-1)
  }
  if ( formatParams.precMode === 'fixed' && formatParams.precision > 0 ) {
      zeroString = '0.'+'0'.repeat(formatParams.precision)
  }

  // For non-zero significands:
  if ( formatParams.precMode === 'min' ) {
    roundedSignificand = minFormatByStrippingTrailingZeros( roundedSignificand )
  }

  // Create the formatted string
  if ( Number(roundedSignificand) === 0 && formatParams.forcedExp !== null && formatParams.forcedExp !== 0) {    // 0 x 10^6, 0.0 x 10^6, ...
      var answer = zeroString + 'e' + String(formatParams.forcedExp)
      if (DEBUG) {console.log( 'Using zero with forced Exp form. answer:', answer)}
  } else if ( Number(roundedSignificand) === 0 ) {                                     // 0, 0.0, 0.00 etc
      answer = zeroString
      if (DEBUG) {console.log( 'Using zero significand form. answer:', answer)}
  } else if ( useExponentialForm ) {                                                   // e.g  1.2345e6, 1.23456e-6,
      answer = inSign + roundedSignificand + 'e' + String(formattedExp)
      if (DEBUG) {console.log( 'Using exponential form. answer:', answer)}
  } else {                                                                             // nonExponentialForm
      answer = inSign + roundedSignificand
      if (DEBUG) {console.log( 'Using nonExponential form. answer:', answer)}
  }
  return answer
}



export const numberFormatReactNode = ( strg: string,  formattingObj: FormattingObj ): ReactNode => {
  // Changed the way this code works such that a suffix is handled by
  // addNumberFormatReactNodeSyntax( ).
  // So remove the suffix from the noHtmlString term.
  // But add the suffix to addNumberFormatReactNodeSyntax( ) function call.
  let suffix = (formattingObj.allowsPrefixSuffix === true) ? formattingObj.suffixStrg : ''
  formattingObj.suffixStrg = ''
  const noHtmlString = numberFormatNoHTML( strg, formattingObj )
  formattingObj.suffixStrg = suffix
  return addNumberFormatReactNodeSyntax( noHtmlString, suffix  )
}

const tightTenPlot : ReactNode = <tspan>{1}<tspan dx='-0.07em'>{0}</tspan></tspan>

export const addNumberFormatReactNodeSyntax = (
       strg: string,
       suffixIn: string = '' ,
       mode:'nonSVG'|'plotSVG' = 'nonSVG',
       shouldShowSignificand: boolean = true ) : ReactNode => {

  // This function called for formatting:
  //    plot tickLabels   (Must use 'plotSVG' mode; Only current use of this mode. )
  //    slider paramaterized values (e.g. binWidth)
  //    pretty printed formulas
  //    crosshairs formatting
  //    table stats bar formatting
  //    formatted values in table cell Editor

  // We need to parse our tick labels examples:
  //    -3.4   -> nonExponenential numbers; displays as written
  //    -3.4e4 -> Exponential number; displays as -3.4 * 10**4 with a raised exponent
  //    1e-4   -> (shouldShowSignificant) ? 1 * 10**-4, otherwise 10**-4;  with -4 a raised exponent
  //    'Smith : 37'  -> a arbitray string, ending in string index;   displayes as written
  //    'Smith : 1e4  -> displays as 'Smith : 10**4 with raised exponent

  // Next regEx treats a minus sign prior to significand as part of the pre-Value.
  // It will always be preserved in the final string, even in cases where we drop
  // the significand.
  var match = strg.match('(\\d+\\.?\\d*)(e-?\\d+)$')
  var significand = (match && match[1]) ? match[1] : ''
  var exponent    = (match && match[2]) ? match[2] : ''
  var preValue = strg.slice(0, strg.length - significand.length - exponent.length)
  significand = (shouldShowSignificand) ? significand + thinSpaceChar : ''
  exponent = exponent.slice(1)   // strip the 'e'
  const suffix = (suffixIn === '' ) ? suffixIn : thinSpaceChar + suffixIn
  if (!exponent || isNaN( Number(exponent)) ) {
    // No need to format this string!
    // Return the raw string + the suffix (valid HTML)
    return strg + suffix
  }

  if ( mode === 'nonSVG' ) {   // default mode for our react application
      return  <span>
                {preValue}
                {significand}
                {constants.MultiplyElement}
                {'10'}
                <sup>
                   {hairSpaceChar}
                   {exponent}
                </sup>
                {suffix}
              </span>
  }

  else if ( mode === 'plotSVG' && shouldShowSignificand) {
      return  <tspan>
                {preValue}
                {significand}
                <tspan baselineShift='15%' style={{fontSize:'65%'}}>
                  {'\u273D'}
                </tspan>
                {tightTenPlot}
                <tspan baselineShift='super' style={{fontSize:'80%'}}>
                   {hairSpaceChar + exponent}
                </tspan>
                {suffix}
              </tspan>
  }

  else if ( mode === 'plotSVG' && !shouldShowSignificand ) {
      // display only 10superscript.  No significand or multiply sign!
      // For example: -1e4 or +1e-5   =>  -10 raised4  or  10 raised-5
      // First, append a zero to the significand to get -10 or 10  (a quick cheat to retain the proper sign)
      //let signedTen = significand + '0'
      return  <tspan>
                {preValue}
                {tightTenPlot}
                <tspan baselineShift='super' style={{fontSize:'80%', right:'0.5em'}}>
                   {hairSpaceChar + exponent}
                </tspan>
                {suffix}
              </tspan>
  }
  return ''  // Should never execute this line.  But returning empty shows the error of this path.
}

export const addStringFormatReactNodeSyntax = (
  ordinalNumber: number, strgNoHTML: string ) : Node => {

    // This function called for formatting:
    //    plot tickLabels   (Must use 'plotSVG' mode; Only current use of this mode. )
    // Current 'pretty format' is the string text, followed by its ordinal position.
    // Ordinal position uses a slighly smaller font.
    // Examples:
    //    'USA - 1st'
    //    'GER - 2nd'
    //    'WHO - 3rd'
    //    'ABC - 4th'
    //      ...
    //         11th, 12th, 13th, 14th
    //      ...
    //         20th, 21st, 22nd, 23rd, 24th,
    //      ...
    var ordinalString = String(ordinalNumber)
    if (ordinalNumber >= 10000) {
      const lastThreeDigits = ordinalString.slice(-3)
      const firstDigits = ordinalString.slice( 0, ordinalString.length-3 )
      ordinalString = firstDigits + ',' + lastThreeDigits
    }


    const lastDigit  = ordinalNumber % 10
    const last2Digit = ordinalNumber % 100
    var lastText = 'th\u2009'
    if ( lastDigit  === 1 ) { lastText = 'st\u2009' }
    if ( last2Digit === 11) { lastText = 'th\u2009' }
    if ( lastDigit  === 2 ) { lastText = 'nd' }
    if ( last2Digit === 12) { lastText = 'th\u2009' }
    if ( lastDigit  === 3 ) { lastText = 'rd\u2009' }
    if ( last2Digit === 13) { lastText = 'th\u2009' }
    const renderedString = ` -\u2009` + ordinalString + lastText
    return <tspan>
              {strgNoHTML}
              <tspan baselineShift='7.5%' style={{fontSize:'85%'}}>
                {renderedString}
              </tspan>
          </tspan>
}

const numberFormatBase = ( inStrg: string,  formattingObj: FormattingObj ): {
  prefixStrg: string
  formatted1: string
  suffixStrg: string
} => {

  const response = {
    prefixStrg: '',
    formatted1: '',
    suffixStrg: ''
  }

  const DEBUG = false
  if (inStrg === '') { return response }
  const rule = formattingObj.rule

  let formatted0: string = ''
  switch(rule) {
    case 'defaultString':
      // In most case, stringFormat is called directly.
      // Don't count on the code flow passing through this switch.
      response.formatted1 = stringFormat(inStrg, formattingObj)
      return response

    case 'defaultLink':
      // In most case, stringFormat is called directly.
      // Don't count on the code flow passing through this switch.
      response.formatted1 = hyperlinkFormat(inStrg, formattingObj)
      return response

    case 'scientific':
    case 'noExponent':
    case 'defaultEng':
      // Don't pass invalid numbers to formatting.  Fix any bad values at source!
      var num = Number(inStrg)
      if ( isNaN(num) || !isFinite(num) ) {
        response.formatted1 = 'NaN'
        return response
      }
      formatted0 = basicNumberFormat (inStrg, formattingObj, DEBUG )
      break
    case 'B60seconds':
    case 'B60B60seconds':
    case 'B60degrees':
    case 'B60B60degrees':
      // Don't pass invalid numbers to formatting.  Fix any bad values at source!
      num = Number(inStrg)
      if ( isNaN(num) || !isFinite(num) ) {
        response.formatted1 = 'NaN'
        return response
      }
      formatted0 = sexagesimalFormats(inStrg, formattingObj )
      break
    case 'boolTrueFalse':
      // Don't pass invalid numbers to formatting.  Fix any bad values at source!
      num = Number(inStrg)
      if ( isNaN(num) || !isFinite(num) ) {
        response.formatted1 = 'NaN'
        return response
      }
      formatted0 = ( Number(inStrg) === 0 ) ? 'False' : 'True'
      break
    default:
      invariant( false, `Rule "${rule}" passed to numberFormat` )
  }

  const formatted1: string = (formattingObj.useCommas) ?  addCommas(formatted0) : formatted0
  if ( formattingObj.allowsPrefixSuffix ) {
    var {prefixStrg, suffixStrg} = prepPrefixAndSuffix( formattingObj )
  } else {
    prefixStrg = ''
    suffixStrg = ''
  }

  return {
    prefixStrg,
    formatted1,
    suffixStrg
  }
}

export const numberFormat = ( inStrg: string, formattingObj: FormattingObj,
      mode: 'measureOnlyString' | 'noHtml' | 'html' ): HTMLElement | string => {

/*        MODES:
    'html'
        This mode return strings with (potential) HTML to image our best 'pretty' display.
        It will
          - raise exponents;
          - Add a suitable multiply sign (NOT the raised asterix) for exponential formats.

    'noHtml'
        Just text without any HTML: <sup>, <big>, <span>, ...
        Hence exponential numbers will include the 'e':  '3.14e5'
        'noHtml' and 'html' changes the format, but not the value, in their respective pretty or editable formats.

        The 'noHtml' is needed when initializing an editor.  (CellEditor, parameterInputs for axis limits, filter values, ...
        If you need a value to seed the resource's state into any editor, use 'noHtml'. AND also make sure to
        set format.precision to its full 16 digits, and format.precisionMode = 'min'
        Hence the full precision of the saved state becomes the initial value in the editor.

     'measureOnlyString'
        We must measure a string's width in several modules (CrossHairs, ViewCellFormula, ... )
        If you need the width of a 'noHtml' number, you can measure the text directly.
        However, if you want the width of the 'html' (pretty) string's width, you want this mode.

        The string returned is equal in width, or very close (errs on being larger), to the 'html' width.
        This string IS NOT a number, and IS NOT a string that can be cast to a number.
        It may even 'be' a number, but perhaps NOT the number you want!  Only the measured length
        has useful meaning.
  */

  const { prefixStrg, formatted1, suffixStrg } = numberFormatBase(inStrg, formattingObj)

  if ( mode === 'noHtml' ) {
    // We are done. No need to insert HTML. Just add prefix and suffix.
    // Prefix and suffix will be '' for most usage of this function.
    // I doubt option of noHtml and a prefix/suffix is actually ever used!
    //console.log( 'noHtml returnVal', formatted1 )
    return prefixStrg + formatted1 + suffixStrg
  }
  if ( mode === 'measureOnlyString' ) {
    return createMeasureOnlyString( formatted1, prefixStrg, suffixStrg )
  }
  if ( formattingObj.rule === 'boolTrueFalse' ) {
    // don't format with HTML syntax, else the 'e' character will become an exponent.
    return prefixStrg + formatted1 + suffixStrg
  }

  // Else this is some request for an HTML format:
  return addHTMLsyntax( formatted1, prefixStrg, suffixStrg )
}

export const numberFormatNoHTML = (inStrg: string, formattingObj: FormattingObj): string => {
  const { prefixStrg, formatted1, suffixStrg } = numberFormatBase(inStrg, formattingObj)
  return prefixStrg + formatted1 + suffixStrg
}

export const numberFormatMeasureOnly = (inStrg: string, formattingObj: FormattingObj): string => {
  const { prefixStrg, formatted1, suffixStrg } = numberFormatBase(inStrg, formattingObj)
  return createMeasureOnlyString( formatted1, prefixStrg, suffixStrg )
}

export const numberFormatHTML = (inStrg: string, formattingObj: FormattingObj): HTMLElement | string => {
  const { prefixStrg, formatted1, suffixStrg } = numberFormatBase(inStrg, formattingObj)
  if ( formattingObj.rule === 'boolTrueFalse' ) {
    // don't format with HTML syntax, else the 'e' character will become an exponent.
    return prefixStrg + formatted1 + suffixStrg
  }

  // Else this is some request for an HTML format:
  return addHTMLsyntax( formatted1, prefixStrg, suffixStrg )
}


const prepPrefixAndSuffix = (formattingObj:FormattingObj ) => {
  var {prefixStrg, suffixStrg} = formattingObj
  prefixStrg = prefixStrg.trimLeft()
  suffixStrg = suffixStrg.trimRight()
  prefixStrg = prefixStrg.replace( / /g, constants.nonBreakingSpace )
  suffixStrg = suffixStrg.replace( / /g, constants.nonBreakingSpace )
  if ( prefixStrg.length > 0 && prefixStrg[ prefixStrg.length-1 ] !== constants.nonBreakingSpace) {
    prefixStrg = prefixStrg + thinSpaceChar
  }
  if ( suffixStrg.length > 0 && suffixStrg[0] !== constants.nonBreakingSpace) {
    suffixStrg = thinSpaceChar + suffixStrg
  }
  return { prefixStrg, suffixStrg }
}



export const stringFormat = ( inStrg: string,  formattingObj:FormattingObj): string => {
  const {prefixStrg, suffixStrg} = prepPrefixAndSuffix( formattingObj )
  return prefixStrg + inStrg + suffixStrg
}

export const hyperlinkFormat = ( inStrg: string,  formattingObj:FormattingObj): string => {
  return inStrg
}




// ALWAYS EDIT THE measureOnlyString AND THE 'html' STRINGS TOGETHER.
// KEEP THEM IN SYNCH SO: measureText( measureOnlyString ) ~= width of 'pretty' HTML strings.

const createMeasureOnlyString = ( inString:string, prefixStrg:string, suffixStrg:string ) : string => {
  const partsExponential = inString.split( /e/i )
  const partsTemporal    = inString.split( /:/i )

  if (partsExponential.length === 2 ) {
    // adding no extra space to account for:
    // html has a 20% wider multiplyChar
    // html has 20% less with exponent
    return `${prefixStrg}${partsExponential[0]} *10${partsExponential[1]}${suffixStrg}`
  }
  if ( partsTemporal.length === 2 ) {
    // html width is slightly wider due to one <big>:</big>    Add a thinSpace
    return inString+thinSpaceChar
  }
  if ( partsTemporal.length === 3 ) {
    // html width is slightly wider due to two <big>:</big>    Add a full space
    return inString + ' '
  }
  // Default   NOT exponential or temporal format.  Hence no HTML.  Just return the inputString
  return prefixStrg + inString + suffixStrg
}


// Can't figure out how to decrease the gap between the 1 & 0 digits.
//  -0.07em looks good in the svg plots.  But need a different format for the table.
//const tightTen = `1<font style={{position:'relative', left:'1.5em'}}>2</font>`
//const tightTen = `1<font style="right:0.80em;">1</font>`
//const tightTen = `1<font style="position:relative; left:0.15em;"}>2</font>`
//const tightTen = `1<font style="left:1.15em;"}>2</font>`
const tightTen = '10'

// ALWAYS EDIT THE measureOnlyString AND THE 'html' STRINGS TOGETHER.
// KEEP THEM IN SYNCH SO: measureText( measureOnlyString ) ~= width of 'pretty' HTML strings.
export const addHTMLsyntax = (inString:string, prefixStrgIn:string, suffixStrgIn:string ): string => {

  const partsExponential = inString.split( /e/i )
  const partsTemporal    = inString.split( /:/i )
  // Replace prefix and suffix '<' characters with &lt;  Otherwise these
  // are parsed as HTML commands.
  var prefixStrg = prefixStrgIn.replace( /</g, '&lt;' )
  var suffixStrg = suffixStrgIn.replace( /</g, '&lt;' )

  if (partsExponential.length === 2 ) {
      let strg = `<span>${prefixStrg}${partsExponential[0]}${thinSpaceChar}${multiplyChar}${tightTen}<sup>${hairSpaceChar}`
                    + `${partsExponential[1]}</sup>${suffixStrg}</span>`
      return strg
  }

  if ( partsTemporal.length > 1 ) {
      if ( partsTemporal.length === 3 ) {
        return `${prefixStrg}${partsTemporal[0]}:${partsTemporal[1]}:${partsTemporal[2]}${suffixStrg}`
      } else {
        return `${prefixStrg}${partsTemporal[0]}:${partsTemporal[1]}${suffixStrg}`
      }
  }

  // default
  return prefixStrg + inString + suffixStrg
}
