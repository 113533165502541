import type { InternalColDataType, MarkShape, PlotColDataType, Tablelook } from '../types'
import type { DerivedColAttributes } from '../computedDataTable/getDefaultTableComputedData'
import type { FormatRule } from '../sharedFunctions/numberFormat'
import type {
  AxisName, AxisPath, BasisName, LinearFit, ParsedLayerId, PlotRange,
  PlotXyComputedAxis, PlotXyComputedData, PlotXyComputedSeriesAttributes,
  PlottedValue, PlottedValueRoot, SeedHisto, VisTypeUser
} from './xy_plotTypes'

import invariant from 'invariant'
import { validPlotColDataTypes } from '../types'
import { hairSpaceChar, smallPercentChar } from '../sharedComponents/constants'
import reactDispatch from '../sharedComponents/reactDispatch'
import { getFormattingObj, isB60Format, numberFormatNoHTML } from '../sharedFunctions/numberFormat'
import { plotLayoutConsts } from '../viewPlotXY/plotLayoutConsts'
import { getAvalue_From_Index } from './seedHistogram'
import {
  baseNameToBasisPathMap,
  basisKeys,
  validDistributionPlottedValues,
  validPlottedValueRoots,
  validPlottedValues,
  validVisTypeUser
} from './xy_plotTypes'

export const EPSILON = Math.pow(2, -52)

export const calcKernelWidthAndSmoothingIndex = (seedHisto: SeedHisto, seriesLineSmoothing: number): { kernelWidthInNumBins: number, smoothingIndex: number, kernelWidthInA: number } => {

  // The plot resource seriesLineSmoothing state is a number, between 1 to 100.
  // It varies logarithmically, in 'n' steps, where numSteps is a plotLayout constant.
  // In this function, n can be found by: smoothingKernelWidthLabels.length

  // Outputs: The convolution kernel width in units of binWidth
  //           The index ( 0 to n-1 ) of the smoothing label/value.
  const scale = (seriesLineSmoothing - 1) / (plotLayoutConsts.maxSmoothingKernelWidth - 1)
  const { arrayBinsPerAxisTick, axisTickWidth, smoothingKernelWidthLabels: widthLabels } = seedHisto
  const maxBins = seedHisto.tableExtensionInUnitsOfAxisTickWidth * arrayBinsPerAxisTick
  const kernelWidthInNumBins = 1 + scale * maxBins
  var smoothingIndex = widthLabels.indexOf(seriesLineSmoothing)
  // Case of NO match; Use the index of the largest option < current seriesLineSmoothing
  if (smoothingIndex === -1) {
    smoothingIndex = 0
    for (let ii = 1; ii < widthLabels.length; ii++) {
      if (widthLabels[ii] > seriesLineSmoothing) { break }
      smoothingIndex = ii
    }
  }
  const kernelWidthInA = kernelWidthInNumBins / arrayBinsPerAxisTick * axisTickWidth
  return { kernelWidthInNumBins, smoothingIndex, kernelWidthInA }

}




export const getLinearCoefficients = (sA: number, sAA: number, sB: number,
  sBB: number, sAB: number, numSamples: number, totalWeight: number): LinearFit => {

  // totalWeight is usually numSamples.
  // However, when fitting points with fractional tapWeights, totalWeight !== numSamples

  if (numSamples === 0) {
    var result: LinearFit = { intercept_BfA: 0, slope_BfA: 0, intercept_AfB: 0, slope_AfB: 0 }
  }
  else if (numSamples === 1) {
    // We choose! I say a flat line through this point
    result = {
      intercept_BfA: sB / totalWeight, slope_BfA: 0,
      intercept_AfB: sA / totalWeight, slope_AfB: Infinity
    }
  }
  else {
    var numeratorIntercept = sB * sAA - sA * sAB
    var numeratorSlope = totalWeight * sAB - sA * sB
    var denominator = totalWeight * sAA - sA * sA
    if (denominator === 0 ||
      Math.abs(numeratorIntercept - denominator) / denominator < 1e-20) {
      // If all the data lines up vertically, then the fit
      // for least vertical error is a flat line at average of B
      var intercept_BfA = sB / totalWeight
      var slope_BfA = 0
    } else {
      intercept_BfA = numeratorIntercept / denominator
      slope_BfA = numeratorSlope / denominator
    }

    numeratorIntercept = sA * sBB - sB * sAB
    numeratorSlope = totalWeight * sAB - sB * sA
    denominator = totalWeight * sBB - sB * sB
    if (denominator === 0 ||
      Math.abs(numeratorIntercept - denominator) / denominator < 1e-20) {
      var intercept_AfB = sA / numSamples
      var slope_AfB = 0
    } else {
      intercept_AfB = numeratorIntercept / denominator
      slope_AfB = numeratorSlope / denominator
    }
    result = { intercept_BfA, slope_BfA, intercept_AfB, slope_AfB }
  }
  //console.log( 'numSamples', numSamples, 'totalWeight', totalWeight, 'intercept&slope',
  //   result.intercept_BfA, result.slope_BfA)
  return result
}


export const isSeriesDataMissing = (plt: PlotXyComputedData, sKey: number): boolean => {
  let s = plt.seriesAttributesArray[sKey]
  return s.isOutOfView || s.isDataTypeMismatch ||
    s.isNoData || s.isDeletedCol || s.isUnsetSeriesColKey
}


export const extendDomainConstrained = (basis: PlotXyComputedAxis, plt: PlotXyComputedData): [number, number] => {

  // DEBUGGING: skip this cosmetic change
  //return basis.domainConstrained

  // Everything in this function is cosmetic.
  // To make sure plotted bars do not extend over the edges of the domain.
  // OR to give some room to string tick locations such that they don't lie exactly on the domain edge.

  const { canvasLayersArray, plotColDataType } = plt
  const { internalDataType, domainConstrained, willUseLogarithmicScale, seedHisto, basisName } = basis
  var minDomain = domainConstrained[0] // assumed return value
  var maxDomain = domainConstrained[1] // assumed return value

  // Does our rendered plot include any bars?
  // isBars will eventually be a plot level attribute.
  // Not sure how it will be implimented in the interface and/or plot resource.
  // For now, loop through the rendered layers and look for any layer with bars.
  var isBars = false // assumption
  for (const thisCanvas of canvasLayersArray) {
    for (const thisReactLayer of thisCanvas) {
      var { visTypeUser } = thisReactLayer
      if (visTypeUser === 'bars' ||
        visTypeUser === 'stackedBars' ||
        visTypeUser === 'stackedBars100%') { isBars = true }
    }
  }

  // Handle string axis. Two cosmetic fixes:
  //  1) We don't want the string tick mark right on the edge of the domain. Create an 'edgeGap' of 2%
  //  2) If isBars, then binWidth is '1'; Must extend by 1/2 binwidth on both edges.
  // Note: isBars ONLY applies to the 'A' axis.
  // Unlike histograms, we don't need to ask whether a 'bar' is to be rendered on the edge of the
  // plot. For strings, a bar is ALWAYS rendered at every tick mark, hence we know in advance that
  // there are bars placed at the extreme left/right 'A' axis coordinates. And hence we need to
  // extend the axis to account for the barWidth.
  if (internalDataType === 'string') {
    const edgeGap = .02 * (maxDomain - minDomain)
    if (basisName === 'B' || !isBars) {
      return [minDomain - edgeGap, maxDomain + edgeGap]
    }
    // Fall through means 'A' with bars:
    if (willUseLogarithmicScale) {
      minDomain = minDomain - .05 * (maxDomain - minDomain)
      maxDomain = Math.log10((Math.pow(10, maxDomain) + 0.6))
    } else {
      minDomain = Math.min(minDomain - 0.5, minDomain - edgeGap)
      maxDomain = Math.max(maxDomain + 0.5, maxDomain + edgeGap)
    }
    return [minDomain, maxDomain]
  }

  // From this point forward, only interested in basisA rendered with bars.
  // Exit with no extensions for all other cases
  if (basisName === 'B' || !isBars || !seedHisto) { return domainConstrained }

  // Classic (numeric) Histogram && linear basisA
  if (plt.isHisto && !willUseLogarithmicScale) {
    var extension = seedHisto.arrayBinsPerUserBinWidth / 2 * seedHisto.arrayBinWidth
    if (!basis.isMinForced) {
      minDomain = Math.min(domainConstrained[0], seedHisto.Avalue_FirstHistoCenter - extension)
    }
    if (!basis.isMaxForced) {
      maxDomain = Math.max(domainConstrained[1], seedHisto.Avalue_FinalHistoCenter + extension)
    }
    return [minDomain, maxDomain]
  }

  // Classic (numeric) Histogram && logarithmic basisA:
  //if ( basisName === 'A' && seedHisto && seedHisto.isBasisAdataTypeNumber && willUseLogarithmicScale ) {
  if (plt.isHisto && willUseLogarithmicScale) {
    if (!basis.isMinForced) {
      // Cannot subtract the extension as it may approach or pass '0'.
      // Don't need to see the entire bar, just some of it. Use a scale factor, like used for strings.
      extension = (maxDomain - minDomain) / 10  // 10%
      minDomain = Math.min(minDomain, minDomain - extension)
    }
    if (!basis.isMaxForced) {
      // We can 'add' this extension as it will never be illegal
      extension = seedHisto.arrayBinsPerUserBinWidth / 2 * seedHisto.arrayBinWidth  // linear domain!
      maxDomain = Math.max(maxDomain, Math.log10(seedHisto.Avalue_FinalHistoCenter + extension))
    }
    return [minDomain, maxDomain]   // toDo
  }

  // XY plots:
  if (plotColDataType === '2Col') {
    extension = seedHisto.arrayBinsPerMinIntervalInA / 2 * seedHisto.arrayBinWidth
    // NO extensions used for isForced axes !!!
    //
    // When the user forces the min/max extent of the basisA, then partial bars MAY render!
    // For example, a plotted plotPt left of the domain may have a bar extent into the domain.
    // And a plottedplotPt just to the right of the domain may have a bar extent out of the domain.
    // This would be difficult to correct as we have no plotpt by plotpt flag as to whether
    // the point is current within the 'A domain' or not.
    // Therefore, NOT going to handle the case where users constraints the domain and
    // thier constrained coordinate happens to truncate one of more bars. (The truncated
    // bars will belong to a pt near the domain edge, but the pt itself may be outside
    // or inside the domain.)
    // So we will only consider the most commom case where the domain is not constrained
    // by the user. A smartAxis has picked left/right edges to hold the entire dataset.
    if (!basis.isMinForced) {
      const firstCountArr = seedHisto.data.map(x => x.Aindex_FirstCount)
      const firstCountIndex = Math.min(...firstCountArr)
      const firstCountValue = getAvalue_From_Index(firstCountIndex, seedHisto)
      if (willUseLogarithmicScale) {
        minDomain = Math.min(minDomain, Math.log10(firstCountValue) - extension)
      } else { // linear extension
        minDomain = Math.min(minDomain, firstCountValue - extension)
      }
    }
    if (!basis.isMaxForced) {
      const finalCountArr = seedHisto.data.map(x => x.Aindex_FinalCount)
      const finalCountIndex = Math.min(...finalCountArr)
      const finalCountValue = getAvalue_From_Index(finalCountIndex, seedHisto)
      if (willUseLogarithmicScale) {
        maxDomain = Math.max(maxDomain, Math.log10(finalCountValue) + extension)
      } else { // linear extension
        maxDomain = Math.max(maxDomain, finalCountValue + extension)
      }
    }
    return [minDomain, maxDomain]
  }

  // Fall through: No change.
  return domainConstrained
}




// Sorted largest to smallest intentionally.
// So removing smallest (illegal) binWidths has minimal effect on the binWidthIndex.
// CONSTRAINT - ALL VALUES IS AN ARRAY SET MUST BE INTEGER MULTIPLES OF THE SMALLEST VALUE.
// ALSO!! The first element of this array is used for the plot tickSize!!  Adding a
// larger value will provide yet another coarse histogram option, but will space
// tick marks in the axis potential farther apart.  With perhaps only 3-5 tick labels
// on the axis.  So do not add an larger (coarser) binWidth without considering
// the impact on the plot tickSize
const decimal5 = [1.00, 0.50, 0.20, 0.10, 0.05]  // For significands 5<= x < 10
const decimal2 = [0.50, 0.20, 0.10, 0.04, 0.02]  // For significands 2<= x < 5
const decimal1 = [0.20, 0.10, 0.05, 0.02, 0.01]  // For significands 1<= x < 2


const getBase10binWidthOptions = (range: number): { values: number[], tickSizeExponent: number } => {
  invariant(range !== 0, 'This function cannot be passed a range of zero.')
  const parts = range.toExponential().split('e')
  const significand = Number(parts[0])
  var exponent = parts[1] ? Number(parts[1]) : 0
  var scale = Math.pow(10, exponent)   // Some power of 10
  var values, tickSizeExponent
  if (significand >= 5) { values = decimal5; tickSizeExponent = exponent }
  else if (significand >= 2) { values = decimal2; tickSizeExponent = exponent - 1 }
  else { values = decimal1; tickSizeExponent = exponent - 1 }
  //console.log(
  //  `range=${range};  ${significand}*${scale}; binWidths=${values}*${scale}`)
  values = values.map(x => x * scale)
  //console.log ( 'returning: {values, tickSizeExponent}', values, tickSizeExponent)
  // tickSizeExponent is the exponent of the largestBinWidthOption in exponential notation.
  // e.g.   IF largestBinWidthOption === smartAxis choice for tickSize === 0.05;  THEN tickSizeExponent === -2.
  return { values, tickSizeExponent }
}

export type TimeLabel = 'sec' | 'Sec' | 'min' | 'Min' | 'hour' | 'Hour'
export type TimeLabelToValue = {
  [key in TimeLabel]: number
}
const labelToValueMapping: TimeLabelToValue = {
  sec: 1,
  Sec: 1,
  min: 1,
  Min: 60,
  hour: 60,
  Hour: 3600,
}

const getBinWidthValueFromBinWidthLabel = (strg: string): number => {
  const parts = strg.split('-')
  const value = Number(parts[0]) * labelToValueMapping[parts[1] as TimeLabel]
  return value
}


// This next function is shared by smartAxisScale and Distribution plots.
// It uses the same rule to find a 'smart' tick size for axes, and a
// smart set of distribution binWidth options for distribution plots.
// The largest binWidth option === the tickSize at full range of axis.
export const getBinWidthOptions = (rangeMinIn: number, rangeMaxIn: number,
  formatRule: string, willUseLogarithmicScale: boolean)
  : { values: number[], binWidthLabels: string[], tickSizeExponent: number } => {

  // Proceed to the next smallest set of binWidth options if the
  // largest available option provides <= 5 full bins.

  // The returned values
  // - must be numbers as stored in the table.  (not formatted.)
  // - units of seconds or minutes for temporal formats
  // The range limits are set so as the number of bins (and or tickSize)
  // at is between 6 - 12, before snapping ends of the axis.

  // The returned labels are used by the binWidth slider. There
  // is lots of flexibility on what we choose to display.  Does
  // NOT need to match the formats of the column data, particularly
  // for temporal formats, where a simpler binWidth label is preferred.

  // ALSO!! The largest element of this array is used for the plot tickSize!!  ( binSizes[0] )
  // Adding a larger value will provide yet another coarse histogram option, but will space
  // tick marks in the axis potential farther apart.  With perhaps only 3-5 tick labels
  // on the axis.  So do not add an larger (coarser) binWidth without considering
  // the impact on the plot tickSize


  const rangeMin = rangeMinIn
  const rangeMax = rangeMaxIn
  var range = rangeMax - rangeMin
  if (range === 0) {
    return { values: [1], binWidthLabels: ['1'], tickSizeExponent: 0 }
  }
  var values = Array<number>()
  var binWidthLabels = Array<string>()
  var tickSizeExponent = 0

  const binWidthFormattingObj = getFormattingObj('defaultEng', { firstExpUsageUpper: 3, firstExpUsageLower: -3 })
  if (!isB60Format(formatRule) || willUseLogarithmicScale) {
    // Log axis seldom have uniform 'tickWidths', except over huge ranges.
    // We will just assume that that axis is split into no less than 5 ticks,
    // and want to find the largest binWidth that provides 5 to 10 bins.
    // We divide the total axis range by five, then can use the small
    // algorithm used for base10 (linear) binwidths.
    ({ values, tickSizeExponent } = getBase10binWidthOptions(range))
    binWidthLabels = values.map(x => numberFormatNoHTML(String(x), binWidthFormattingObj))
    return { values, binWidthLabels, tickSizeExponent }
  }

  // Don't modify the [sec,Sec,min,Min,hour,Hour] capitalization!  It has meaning. See  labelToValueMapping:Object
  // Proceed to the next smallest set of binWidth options if the
  // largest available option provides < 6 full bins.
  else if (formatRule === 'B60B60seconds' || formatRule === 'B60B60degrees') {
    switch (true) {
      case range >= getBinWidthValueFromBinWidthLabel('120-Hour'):
        let rangeInUnitsOfHours = range / 3600;
        ({ values, tickSizeExponent } = getBase10binWidthOptions(rangeInUnitsOfHours))
        binWidthFormattingObj.suffix = 'Hour'
        binWidthLabels = values.map(x => numberFormatNoHTML(String(x), binWidthFormattingObj))
        values = values.map(x => x * 3600)
        return { values, binWidthLabels, tickSizeExponent }

      // CONSTRAINT - ALL VALUES IS AN ARRAY SET MUST BE INTEGER MULTIPLES OF THE SMALLEST VALUE.
      // Else the compressSeedHistogram will end up with fractional indices.
      case range >= getBinWidthValueFromBinWidthLabel('60-Hour'):
        binWidthLabels = ['30-Min', '1-Hour', '2-Hour', '5-Hour', '10-Hour'].reverse()
        break
      case range >= getBinWidthValueFromBinWidthLabel('30-Hour'):
        binWidthLabels = ['15-Min', '30-Min', '1-Hour', '2-Hour', '5-Hour'].reverse()
        break
      case range >= getBinWidthValueFromBinWidthLabel('12-Hour'):
        binWidthLabels = ['10-Min', '20-Min', '30-Min', '1-Hour', '2-Hour'].reverse()
        break
      case range >= getBinWidthValueFromBinWidthLabel('6-Hour'):
        binWidthLabels = ['5-Min', '10-Min', '15-Min', '30-Min', '1-Hour'].reverse()
        break
      case range >= getBinWidthValueFromBinWidthLabel('3-Hour'):
        binWidthLabels = ['2-Min', '6-Min', '10-Min', '20-Min', '30-Min'].reverse()
        break
      case range >= getBinWidthValueFromBinWidthLabel('2-Hour'):
        binWidthLabels = ['1-Min', '2-Min', '5-Min', '10-Min', '20-Min'].reverse()
        break
      case range >= getBinWidthValueFromBinWidthLabel('1-Hour'):
        binWidthLabels = ['30-Sec', '1-Min', '2-Min', '5-Min', '10-Min'].reverse()
        break
      case range >= getBinWidthValueFromBinWidthLabel('30-Min'):
        binWidthLabels = ['15-Sec', '30-Sec', '1-Min', '2-Min', '5-Min'].reverse()
        break
      case range >= getBinWidthValueFromBinWidthLabel('12-Min'):
        binWidthLabels = ['10-Sec', '20-Sec', '30-Sec', '1-Min', '2-Min'].reverse()
        break
      case range >= getBinWidthValueFromBinWidthLabel('6-Min'):
        binWidthLabels = ['5-Sec', '10-Sec', '15-Sec', '30-Sec', '1-Min'].reverse()
        break
      case range >= getBinWidthValueFromBinWidthLabel('3-Min'):
        binWidthLabels = ['2-Sec', '6-Sec', '10-Sec', '20-Sec', '30-Sec'].reverse()
        break
      default:  // all ticks of units seconds.  Use the base10 algorithm
        ({ values, tickSizeExponent } = getBase10binWidthOptions(range))
        binWidthFormattingObj.suffix = 'Sec'
        binWidthLabels = values.map(x => numberFormatNoHTML(String(x), binWidthFormattingObj))
        return { values, binWidthLabels, tickSizeExponent }
    }
  }

  else if (formatRule === 'B60seconds' || formatRule === 'B60degrees') {
    switch (true) {
      case range >= getBinWidthValueFromBinWidthLabel('120-Min'):
        let rangeInUnitsOfMinutes = range / 60;
        ({ values, tickSizeExponent } = getBase10binWidthOptions(rangeInUnitsOfMinutes))
        binWidthFormattingObj.suffix = 'Min'
        binWidthLabels = values.map(x => numberFormatNoHTML(String(x), binWidthFormattingObj))
        values = values.map(x => x * 60)
        return { values, binWidthLabels, tickSizeExponent }

      // CONSTRAINT - ALL VALUES IS AN ARRAY SET MUST BE INTEGER MULTIPLES OF THE SMALLEST VALUE.
      case range >= getBinWidthValueFromBinWidthLabel('60-Min'):
        binWidthLabels = ['30-Sec', '1-Min', '2-Min', '5-Min', '10-Min'].reverse()
        break
      case range >= getBinWidthValueFromBinWidthLabel('30-Min'):
        binWidthLabels = ['15-Sec', '30-Sec', '1-Min', '2-Min', '5-Min'].reverse()
        break
      case range >= getBinWidthValueFromBinWidthLabel('12-Min'):   //P
        binWidthLabels = ['10-Sec', '15-Sec', '30-Sec', '1-Min', '2-Min'].reverse()
        break
      case range >= getBinWidthValueFromBinWidthLabel('6-Min'):
        binWidthLabels = ['5-Sec', '10-Sec', '15-Sec', '30-Sec', '1-Min'].reverse()
        break
      case range >= getBinWidthValueFromBinWidthLabel('3-Min'):    //P
        binWidthLabels = ['2-Sec', '5-Sec', '10-Sec', '20-Sec', '30-Sec'].reverse()
        break
      default:  // all ticks of units seconds.  Use the base10 algorithm
        ({ values, tickSizeExponent } = getBase10binWidthOptions(range))
        binWidthFormattingObj.suffix = 'Sec'
        binWidthLabels = values.map(x => numberFormatNoHTML(String(x), binWidthFormattingObj))
        return { values, binWidthLabels, tickSizeExponent }
    }
  }

  // Only temporal values should fall through to this point!
  // For temporal formats, we have the binWidthLabels but no values.
  if (values.length === 0) {
    values = binWidthLabels.map(strg => getBinWidthValueFromBinWidthLabel(strg))
  }
  return { values, binWidthLabels, tickSizeExponent: 0 }
}

export type RuleResponse = {
  internalDataTypeA: InternalColDataType
  internalDataTypeB: InternalColDataType
  formatRuleA: FormatRule
  formatRuleB: FormatRule
}

export const ruleForBasisDataTypeAndFormatValues0 = (series: PlotXyComputedSeriesAttributes[], seriesOrder: number[],
  derivedColAttributesArray: DerivedColAttributes[], plotColDataType: string): RuleResponse => {
  // current rule is 'first' series assigned to that axis.
  const key = seriesOrder[0]
  const colKeyA = series[key].colKeyA
  const colKeyB = series[key].colKeyB
  if (plotColDataType === '3Col') {
    return {
      internalDataTypeA: 'number',
      internalDataTypeB: 'number',
      formatRuleA: 'defaultEng',
      formatRuleB: 'defaultEng',
    }
  }
  return {
    internalDataTypeA: (colKeyA >= 0) ? derivedColAttributesArray[colKeyA].internalDataType : 'number',
    internalDataTypeB: (colKeyB >= 0) ? derivedColAttributesArray[colKeyB].internalDataType : 'number',
    formatRuleA: (colKeyA >= 0) ? derivedColAttributesArray[colKeyA].formatRule : 'defaultEng',
    formatRuleB: (colKeyB >= 0) ? derivedColAttributesArray[colKeyB].formatRule : 'defaultEng',
  }
}


export const ruleForBasisDataTypeAndFormatValues = (series: PlotXyComputedSeriesAttributes[], seriesOrder: number[],
  plotColDataType: PlotColDataType): RuleResponse => {
  // current rule is 'first' series assigned to that axis.
  const firstKey = seriesOrder[0]
  const colKeyA = series[firstKey].colKeyA
  const colKeyB = series[firstKey].colKeyB
  if (plotColDataType === '3Col') {
    return {
      internalDataTypeA: 'number',
      internalDataTypeB: 'number',
      formatRuleA: 'defaultEng',
      formatRuleB: 'defaultEng',
    }
  }
  if (plotColDataType === '1Col') {
    return {
      internalDataTypeA: series[firstKey].internalDataTypeA,
      internalDataTypeB: 'number',
      formatRuleA: series[firstKey].formatRuleA,
      formatRuleB: 'defaultEng',
    }
  }
  return {
    internalDataTypeA: (colKeyA >= 0) ? series[firstKey].internalDataTypeA : 'number',
    internalDataTypeB: (colKeyB >= 0) ? series[firstKey].internalDataTypeB : 'number',
    formatRuleA: (colKeyA >= 0) ? series[firstKey].formatRuleA : 'defaultEng',
    formatRuleB: (colKeyB >= 0) ? series[firstKey].formatRuleB : 'defaultEng',
  }
}


export const create_sKeyArrStrg_from_seriesOrder = (seriesOrder: number[]): string => {
  var sKeyArr = `[${seriesOrder[0]}`
  for (let i = 1; i < seriesOrder.length; i++) { sKeyArr += `,${seriesOrder[i]}` }
  sKeyArr += ']'
  return sKeyArr
}


export const getAxisMapping = (basisName: BasisName, isTransposed: boolean, isMirrored: boolean): { axisPath: AxisPath, axisName: AxisName } => {
  const corner = basisName + String(isTransposed) + String(isMirrored)
  switch (corner) {
    case 'Afalsefalse': return { axisPath: 'bottomAxis', axisName: 'Bottom' }
    case 'Atruefalse': return { axisPath: 'leftAxis', axisName: 'Left' }
    case 'Afalsetrue': return { axisPath: 'bottomAxis', axisName: 'Bottom' }
    case 'Atruetrue': return { axisPath: 'leftAxis', axisName: 'Left' }

    case 'Bfalsefalse': return { axisPath: 'leftAxis', axisName: 'Left' }
    case 'Btruefalse': return { axisPath: 'bottomAxis', axisName: 'Bottom' }
    case 'Bfalsetrue': return { axisPath: 'rightAxis', axisName: 'Right' }
    case 'Btruetrue': return { axisPath: 'topAxis', axisName: 'Top' }

    case 'Cfalsefalse': return { axisPath: 'rightAxis', axisName: 'Right' }
    case 'Ctruefalse': return { axisPath: 'topAxis', axisName: 'Top' }
    case 'Cfalsetrue': return { axisPath: 'leftAxis', axisName: 'Left' }
    case 'Ctruetrue': return { axisPath: 'bottomAxis', axisName: 'Bottom' }

    default:
      console.error(`error in getAxisMapping for corner: ${corner}`)
      return { axisPath: 'bottomAxis', axisName: 'Bottom' }
  }
}

const mapSingleBasisToAxis = (thisBasis: PlotXyComputedAxis, basisName: BasisName, isTransposed: boolean, isMirrored: boolean): void => {
  const { axisPath, axisName } = getAxisMapping(basisName, isTransposed, isMirrored)
  thisBasis.basisName = basisName
  thisBasis.basisPath = baseNameToBasisPathMap[basisName]
  thisBasis.axisPath = axisPath
  thisBasis.axisName = axisName
}

export const mapBasisToAxis = (plt: PlotXyComputedData, isTransposed: boolean, isMirrored: boolean): void => {
  mapSingleBasisToAxis(plt.basisA, 'A', isTransposed, isMirrored)
  mapSingleBasisToAxis(plt.basisB, 'B', isTransposed, isMirrored)
  mapSingleBasisToAxis(plt.basisC, 'C', isTransposed, isMirrored)
  // One of next two axes will be null.   (Only three basis to assign to four potential axes.)
  plt.topAxis = null
  plt.rightAxis = null
  plt[plt.basisA.axisPath] = plt.basisA
  plt[plt.basisB.axisPath] = plt.basisB
  plt[plt.basisC.axisPath] = plt.basisC
}


export const getInternalMarkShape = (markShape: MarkShape, size: number): string => {
  // Render a easier, faster shape, IF the requested size makes the faster
  // cruder shape appear equivalent.
  if (size < 1) { return 'square' }
  if (markShape === 'circle' && size < 3) { return 'octagon' }
  return markShape
}

export const calcEquivSize = (markShape: string, size: number): number => {
  let equivSize
  const circleArea = Math.PI * size * size

  // User choice for sizes: 0.5, 1, 2, 3, 4
  // May be linear, but appearance goes as size^2
  // Here's an emperical fudge table to get sizes
  // that appear more evenly spaced and useful.
  // Only value to this fudge is it lets me keep the
  // user choices 'simple', even though not rigorous.
  if (size <= 2.5 && size > 1.5) { size *= 0.8 }
  if (size <= 3.5 && size > 2.5) { size *= 0.9 }

  // Here we set the emperically set the relative sizes
  // between different shapes.  Easier than trying to
  // scale the SVG data that draws the specific shapes.
  // Emperically I set these by making the square at
  // '3pts' my emperically chosen size :
  //   which is '3pts' * myFudge = 3 * 1.3 = 3.9
  // Then setting the all others to 'appear' roughly
  // the same size as my square at 3.
  switch (markShape) {
    case 'square':
      // This is our emperical standard
      equivSize = size * 1.3
      break
    case 'circle':
      equivSize = size * .7
      break
    case 'octagon':  // Not offered to user.  But used in place of small circles.  Much faster to render.
      equivSize = size * 1.4
      break
    case 'diamond':
      equivSize = size * 1.7
      break
    case 'x': // currently not offering this shape, as very slow to render.
      equivSize = size * .8
      break
    case 'plus':
      equivSize = size * 1.4
      break
    case 'triangle':
      equivSize = size * 1.8
      break
    case 'star':  // Used for pinned row plotPTs.
      equivSize = Math.sqrt(circleArea / 1.4) // aproximation based on what feels right
      break
    default:
      equivSize = size
  }
  return equivSize * plotLayoutConsts.nominalMarkScale
}


// 0% to 100% Axis
export const forceToPercentAxis = (a: PlotXyComputedAxis): void => {
  a.tickVisValues = [0, 20, 40, 60, 80, 100]
  a.tickUserValues = a.tickVisValues.slice()
  a.tickFormatRule = 'defaultString'
  a.tickFormattingObj = getFormattingObj('defaultString')
  a.tickUserStringsNoHTML = ["0", "20", "40", "60", "80", "100"]
  a.tickUserStringsNoHTML = a.tickUserStringsNoHTML.map(s => s + hairSpaceChar + smallPercentChar)
  a.tickUserStringsMeasureOnly = a.tickUserStringsNoHTML.slice()
  a.domainConstrained = [0, 100]
  a.domainExtended = [0, 100]
}

// -100% to 100%
export const forceToDoublePercentAxis = (a: PlotXyComputedAxis): void => {
  a.tickVisValues = [-100, -75, -50, -25, 0, 25, 50, 75, 100]
  a.tickUserValues = a.tickVisValues.slice()
  a.tickFormatRule = 'defaultString'
  a.tickFormattingObj = getFormattingObj('defaultString')
  a.tickUserStringsNoHTML = ["-100", "-75", "-50", "-25", "0", "25", "50", "75", "100"]
  a.tickUserStringsNoHTML = a.tickUserStringsNoHTML.map(s => s + hairSpaceChar + smallPercentChar)
  a.tickUserStringsMeasureOnly = a.tickUserStringsNoHTML.slice()
  a.domainConstrained = [-100, 100]
  a.domainExtended = [-100, 100]
}

// -100% to 0%
export const forceToNegativePercentAxis = (a: PlotXyComputedAxis): void => {
  a.tickVisValues = [-100, -80, -60, -40, -20, 0]
  a.tickUserValues = a.tickVisValues.slice()
  a.tickFormatRule = 'defaultString'
  a.tickFormattingObj = getFormattingObj('defaultString')
  a.tickUserStringsNoHTML = ["-100", "-80", "-60", "-40", "-20", "0"]
  a.tickUserStringsNoHTML = a.tickUserStringsNoHTML.map(s => s + hairSpaceChar + smallPercentChar)
  a.tickUserStringsMeasureOnly = a.tickUserStringsNoHTML.slice()
  a.domainConstrained = [-100, 0]
  a.domainExtended = [-100, 0]
}

export const toPlottingPercent = (x: string): string => {
  const xVal = Number(x)
  const absX = Math.abs(Number(x))
  //console.log( absX )
  if (absX < .0000001) { return '0.00 %' }
  if (absX > .9999999) { return '100.0 %' }
  if (absX < 0.001000001 && absX > 0.000999999) { return '0.10 %' }  // Case of exactly 0.10 %
  if (absX > .999998 || absX < .000002) { return (xVal * 100).toFixed(6) + ' %' }
  if (absX > .99998 || absX < .00002) { return (xVal * 100).toFixed(5) + ' %' }
  if (absX > .9998 || absX < .0002) { return (xVal * 100).toFixed(4) + ' %' }
  if (absX > .998 || absX < .002) { return (xVal * 100).toFixed(3) + ' %' }
  if (absX > .98 || absX < .02) { return (xVal * 100).toFixed(2) + ' %' }
  return (xVal * 100).toFixed(2) + ' %'
}


export const linearRegression = (series: PlotXyComputedSeriesAttributes, isAlogarithmic: boolean, isBlogarithmic: boolean): { intercept_BfA: number, slope_BfA: number, intercept_AfB: number, slope_AfB: number } => {

  // next set of group statistic assume log( abs( value ) )
  // In other words all log sums ALWAYS use the abs(value).
  // We also know that a logarithmic scale is not allowed UNLESS all values are the same sign.
  // Hence summed logs are either : Sum( [ v0,v1,v2,v3,... ])   OR  Sum( -[v0,v1,v2,v3...])
  // We can tell from the sums below is all values < 0 from knowing scale is logarithmic!
  const { numPoints, filteredPtsStats } = series
  const { sumA, sumB, sumlogA, sumlogB, sumAA, sumBB, sumlogAlogA, sumlogBlogB,
    sumAB, sumlogAB, sumAlogB, sumlogAlogB } = filteredPtsStats

  if (isAlogarithmic && isBlogarithmic) {
    var sAB = sumlogAlogB
    var sA = sumlogA
    var sB = sumlogB
    var sAA = sumlogAlogA
    var sBB = sumlogBlogB
  } else if (isAlogarithmic) {
    sAB = sumlogAB
    sA = sumlogA
    sB = sumB
    sAA = sumlogAlogA
    sBB = sumBB
  } else if (isBlogarithmic) {
    sAB = sumAlogB
    sA = sumA
    sB = sumlogB
    sAA = sumAA
    sBB = sumlogBlogB
  } else {
    sAB = sumAB
    sA = sumA
    sB = sumB
    sAA = sumAA
    sBB = sumBB
  }
  const result = getLinearCoefficients(sA, sAA, sB, sBB, sAB, numPoints, numPoints)
  //console.log( 'Filtered Data Sums:  numPts, sA, sB, sAA, sAB', numPoints, sA, sB, sAA, sAB )
  //console.log( 'intercept, slope', result.C0_BfA, result.C1_BfA )
  return result
}



export const validLineSmoothing = (inValue: number): number => {
  if (inValue >= plotLayoutConsts.minSmoothingKernelWidth &&
    inValue <= plotLayoutConsts.maxSmoothingKernelWidth) { return inValue }
  // Next expression is the geometric mean.  (midpt on a logorithmic scale)
  return Math.sqrt(plotLayoutConsts.minSmoothingKernelWidth * plotLayoutConsts.maxSmoothingKernelWidth)
}



export const createBasisB_HistogramAxis = (plt: PlotXyComputedData, range: PlotRange): void => {

  // Modifies plt and basisB 'in-place'
  const internalDataTypeA = plt.basisA.internalDataType
  const { basisB } = plt
  const { isHistogramPercent } = basisB
  const isHistogramCount = !isHistogramPercent

  // Cases where we will over-ride the range in:
  var maxB = range.max
  var minB = 0       // Assumption for linear Freq (basisB) scale.
  if (maxB <= 0) {
    maxB = 10
  } else {
    // Some white space above the top of range.
    // SmartAxis scale may add more, however, can't count on smartAxis
    // scale to ALWAYS add space, so add some here.
    maxB = maxB * 1.05
  }

  // Case of very sparse Count data
  if (isHistogramCount && maxB < 4) { maxB = 4 }

  // Case of Logarithmic Scale and minCount = 1.  Need a maxB and minB such
  // that the logarithmic scale is always 'pretty', regardless of the max binCount.
  basisB.willUseLogarithmicScale = basisB.isLogarithmic
  if (basisB.willUseLogarithmicScale && isHistogramCount) {
    const maxB_requiredToForceTickValuesToIntegers = 6 // set to 5 to see 'fractional' tick labels.
    const maxB_breakPointWhereHeightOfBinCount1ChangesSize = 10000
    const minB_forTypicalMaxB = 0.51   // emperically determined param by looking at log scale plots
    const minB_forHugeMaxB = 0.30   // same
    // Next two lines assume the user is not (can not!) impose their own axis limits.
    maxB = Math.max(maxB_requiredToForceTickValuesToIntegers, maxB)
    minB = (maxB <= maxB_breakPointWhereHeightOfBinCount1ChangesSize)
      ? minB_forTypicalMaxB
      : minB_forHugeMaxB
  }

  // Case of Logorithmic Scale;  minB rendered on axis
  // must be 2x or 4x smaller than the smallest plotted value.
  if (basisB.willUseLogarithmicScale && isHistogramPercent) {
    let minBbySeries = plt.seriesFilteredRowKeys.map(s => {
      if (s.length === 0) { return 1 } //Infinity
      return 100 / s.length
    })
    minB = Math.min(...minBbySeries)
    // What will be the minB for the log plot?
    minB = (maxB / minB > 1000) ? minB / 4 : minB / 2
  }

  basisB.rangeSmartAxisAllSeries = { min: minB, max: maxB /*, isMinForced:false, isMaxForced:false, isInvertedAxis: false*/ }
  // plt.histogramBinIndex is what is saved in user state.
  // However, the length of binWidth factors and labels may be shorter
  // length, because we've truncated these arrays to eliminate binWidth
  // options smaller then min Interval in A.
  // So access the user defined binIndex, or the last binIndex, which
  // ever is shorter.
  //const legalBinIndex = Math.min(seedHisto.binWidthLabels.length, plt.histogramBinIndex)
  const seedHisto = plt.basisA.seedHisto

  if (!seedHisto) {
    return
  }
  // const smallestBinSize = (seedHisto.isLogarithmicA)
  //    ? lastVal( seedHisto.binWidthLabelsLog )
  //    : lastVal( seedHisto.binWidthLabels )
  const currentBinSize = (seedHisto.willUseLogarithmicSeedHistoBins)
    ? seedHisto.binWidthLabelsLog[seedHisto.legalBinWidthIndex]
    : seedHisto.binWidthLabels[seedHisto.legalBinWidthIndex]
  basisB.axisTitle = 'Frequency Count'
  basisB.axisSubTitle = seedHisto.willUseLogarithmicSeedHistoBins
    ? `Binwidth: \u00A0${currentBinSize} per decade`
    : `Per Binwidth of \u00A0${currentBinSize}`
  basisB.internalDataType = 'number'
  basisB.axisTitleShort = 'Bin Count'

  // Since the control is not shown, these values never
  // appear anywhere.  Just setting them to the values that
  // would otherwise be transfered from the user's plot resource.
  basisB.isMaxDomainAuto = true
  basisB.isMinDomainAuto = true
  basisB.usersMaxDomain = ''
  basisB.usersMinDomain = ''

  basisB.nonlinearXform = basisB.willUseLogarithmicScale ? (i) => Math.log10(i) : (i) => i
  if (isHistogramPercent) {
    basisB.axisTitle = 'Frequency Percent'
    basisB.axisSubTitle = `Per Binwidth of \u00A0${currentBinSize}`
    basisB.axisTitleShort = 'Freq Percent'
    basisB.tickFormatSuffixStrg = String.fromCharCode(0xFE6A)
  }
  basisB.tickFormatRule = 'defaultEng'
  if (internalDataTypeA === 'string') {
    basisB.axisSubTitle = '(Per \u00A0unique \u00A0string)'
  }
}

export const updateTablelook_recentPlots = (tableId: string, tablelook: Tablelook, plotId: string) => {
  const tablelookId = tablelook.id
  const RecentPlots = tablelook.attributes.recentPlots

  // In case of a new/changed plotid, then this plotid goes at
  // the front (most recent) of array tablelook.attributes.recentPlots.
  // Next filter skips/removes the nextPlotid (if it is already in array)
  // And then inserts the nextPlotid to front (index 0) of the array.
  var nextRecentPlots = RecentPlots.filter(strg => strg !== plotId)
  nextRecentPlots.unshift(plotId)
  const MAX_LENGTH_RECENT_PLOTS = 10
  nextRecentPlots = nextRecentPlots.slice(0, MAX_LENGTH_RECENT_PLOTS)
  const tablelook_mods = [{ newVal: nextRecentPlots, path: 'attributes.recentPlots' }]
  reactDispatch(tablelook_mods, 'new recent Plotid', '',
    'tablelooks', tablelookId,
    'tables', tableId)
}

export const parseRenderedLayerID = (strg: string): ParsedLayerId => {

  // Some safety catch code if I get stuck in a bad resource state,
  // and I'd rather debug current plot as opposed to an initData
  //strg = strg.replace( '2Col:' , '' )

  const substrings = strg.split(':')
  const sKeyArrAsString: string = substrings[2]
  const sKeyArrStr: string[] = sKeyArrAsString.slice(1, sKeyArrAsString.length - 1).split(',')
  const sKeyArr: number[] = sKeyArrStr.map(strg => Number(strg))
  const isSmoothed = substrings[0].slice(0, 5) === 'smth_'
  const plottedValueRoot = (isSmoothed) ? substrings[0].slice(5) : substrings[0]
  const parsedID = {
    isSmoothed,
    plottedValue: substrings[0] as PlottedValue,
    plottedValueRoot: plottedValueRoot as PlottedValueRoot,
    visTypeUser: substrings[1] as VisTypeUser,
    sKeyArr,
    sKeyArrAsString,
  }
  if (process.env.NODE_ENV === 'production') { return parsedID }
  // Otherwise error test this string!!
  return parsedID
}

export const errorCheckRenderedLayersArr = (plotColDataType: PlotColDataType, renderedLayersArr: string[], numSeries: number): void => {
  const numRenderedLayers = renderedLayersArr.length
  if (numRenderedLayers === 0) {
    invariant(false, `isRenderLayerIDvalid: Empty array! No renderedLayers`)
  }

  if (!validPlotColDataTypes.includes(plotColDataType)) {
    invariant(false, `Bad renderedLayersArr >> Unrecognized plotColDataType of ${plotColDataType}`)
  }

  for (const thisRenderedLayerID of renderedLayersArr) {
    var parsedLayer = parseRenderedLayerID(thisRenderedLayerID)
    const { plottedValue, plottedValueRoot, visTypeUser, sKeyArr, sKeyArrAsString } = parsedLayer

    if (!validPlottedValues.includes(plottedValue)) {
      invariant(false, `Bad renderedLayerID ${thisRenderedLayerID}  >> Unrecognized plottedValue of ${plottedValue}`)
    }
    if (!validPlottedValueRoots.includes(plottedValueRoot)) {
      invariant(false, `Bad renderedLayerID ${thisRenderedLayerID}  >> Unrecognized plottedValueRoot of ${plottedValueRoot}`)
    }
    if (!validVisTypeUser.includes(visTypeUser)) {
      invariant(false, `Bad renderedLayerID ${thisRenderedLayerID}  >> Unrecognized visTypeUser of ${visTypeUser}`)
    }

    if (sKeyArr.length <= 0) {
      invariant(false, `Bad renderedLayerID ${thisRenderedLayerID}  >> Illegal sKeyArr: length is zero`)
    }
    // Any duplicates in sKeyArr?
    // Converting sKeyArr to a 'Set' will force the set object to ignore duplicates.
    if (new Set(sKeyArr).size !== sKeyArr.length) {
      invariant(false, `Bad renderedLayerID ${thisRenderedLayerID}  >> sKeyArr includes duplicates: ${sKeyArrAsString}`)
    }
    // Any non-numeric array values?
    for (const key of sKeyArr) {
      if (isNaN(key)) {
        invariant(false, `Bad renderedLayerID ${thisRenderedLayerID}  >> Non-numeric array value ${key}`)
      }
      if (key < 0 || key >= numSeries) {
        invariant(false, `Bad renderedLayerID ${thisRenderedLayerID}  >> Out-of-range series key ${key}`)
      }
    }

    const isDistribution = validDistributionPlottedValues.indexOf(plottedValue) >= 0
    // 1Col can only use distribution values
    if (plotColDataType === '1Col' && isDistribution === false) {
      invariant(false, `iBad renderedLayerID ${thisRenderedLayerID}  >> Mismatched PlotColDataType & value: ${plotColDataType} & ${plottedValue}`)
    }
    // 3Col can only use tableRow values
    if (plotColDataType === '3Col' && plottedValue !== 'tableRow') {
      invariant(false, `Bad renderedLayerID ${thisRenderedLayerID}  >> Mismatched PlotColDataType & value: ${plotColDataType} & ${plottedValue}`)
    }
    // 3Col cannot use bars or area
    if (plotColDataType === '3Col' && (visTypeUser === 'bars' || visTypeUser === 'area')) {
      invariant(false, `Bad renderedLayerID ${thisRenderedLayerID}  >> Mismatched Algorithm & visTypeUser: ${plotColDataType} & ${visTypeUser}`)
    }
    // 2Col/3Col cannot use isDistribution values
    if ((plotColDataType === '2Col' || plotColDataType === '3Col') && isDistribution) {
      invariant(false, `Bad renderedLayerID ${thisRenderedLayerID}  >> Mismatched plotColDataType & value: ${plotColDataType} & ${plottedValue}`)
    }
  }
}

export const getGeometricNameFromSelectionName = (selectionName: string, plotXyComputedData: PlotXyComputedData): string => {
  let geometricName
  if (basisKeys.find((basisKey) => (selectionName === basisKey))) {
    const plotXyComputedDataKey = selectionName as keyof PlotXyComputedData
    const axis = plotXyComputedData[plotXyComputedDataKey] as PlotXyComputedAxis
    geometricName = axis.axisPath
  } else {
    geometricName = selectionName
  }
  return geometricName
}
