import { Component } from 'react'
import { type AnyAction, type Dispatch } from '@reduxjs/toolkit'
import { DndProvider } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import { connect } from 'react-redux'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import { initAuth, setAuthToken, setAuthUserId } from '../redux/appReducer'
import ErrorBoundary from './ErrorBoundary'
// import Home from './home/Home'
// import Privacy from './legal/Privacy'
// import Terms from './legal/Terms'
import Notify from '../viewNotifications/Notify'
import TopLevelUI from '../tablesParent/TopLevelUI'
import Install from '../viewImport/Install'
import ReactHint from '../viewTooltips/ReactHint'
import UserCreate from '../viewUser/UserCreate'
import UserLogin from '../viewUser/UserLogin'
import { refreshLoginThunk } from '../redux/loginThunks'
import UserSettings from '../viewUser/UserSettings'
import '../viewTooltips/index.css'

import { initSessionStateDispatch } from '../sharedComponents/reactDispatch'
import { RootState } from '../redux/store'
import { SessionState } from '../appCode/getDefaultSessionState'
import { updateSession } from '../redux/sessionReducer'
import { asyncDispatch } from '../sharedFunctions/utils'
//import {startTimer, stopTimer, logTime}     from './sharedComponents/timer'


// JPS code for running jest test modules in an environment better suited to console.log and debugging:
//import './numberFormat/sexagesimalFormats.test'
//import '../computedDataTable/RPN_evaluate.test'
//import invariant from 'invariant'
//invariant( false, 'Testing complete.')



type OwnProps = {}
type StateProps = {
  session: SessionState
}
type DispatchProps = {
  initAuth: () => void
  refreshUserToken: () => void
  setAuthToken: (authToken: string) => void
  setAuthUserId: (authUserId: string) => void
  setSession: (session: Partial<SessionState>) => void
}
type Props = OwnProps & StateProps & DispatchProps

export var appElement: HTMLDivElement | null | undefined = null

class AppRender extends Component<Props> {
  boundSetState: any
  constructor(props: Props) {
    super(props)
    const { initAuth } = props
    initAuth()
    //this.state = getDefaultSessionState()
    this.boundSetState = this.setState.bind(this)
  }

  // Another tab may be open, and user may log in or out,
  // so listen for changes to localStorage.
  storageChangeHandler = (e: StorageEvent): void => {
    const { setAuthToken, setAuthUserId } = this.props
    const { key, newValue } = e
    if (key === 'token') {
      setAuthToken(newValue || '')
    }
    if (key === 'userid') {
      setAuthUserId(newValue || '')
    }
  }

  findParentOfClass = (target: EventTarget & ChildNode, className: string): HTMLElement | null => {
    let p = target.parentNode
    while (p && !(p instanceof HTMLElement && p.className === className)) {
      p = p.parentNode
    }
    return p instanceof HTMLElement ? p : null
  }

  // This is a new way to find the parent of a class.
  /*
    findParentOfClassNew = (target: Element, className: string): Element | null | undefined=> {
    return target.parentElement?.closest(`.${className}`);
  }
  */

  handleMouseWheel = (e: WheelEvent) => {
    const deltaX = e.deltaX
    const deltaY = e.deltaY
    if ((deltaX !== 0 || deltaY !== 0) && e.target instanceof HTMLElement) {
      const touchContainerData = this.findParentOfClass(e.target, 'TouchContainer')
      //console.log( '===' , e.target, touchContainerData  )
      if (touchContainerData) {
        if (
          (deltaX < 0 && touchContainerData.scrollLeft === 0) ||
          // scrollContainer.scrollLeft may be floating point number
          (deltaX > 0 &&
            Math.abs(touchContainerData.scrollLeft + touchContainerData.clientWidth - touchContainerData.scrollWidth) <
              0.6) ||
          (deltaY < 0 && touchContainerData.scrollTop === 0) ||
          (deltaY > 0 &&
            Math.abs(touchContainerData.scrollTop + touchContainerData.clientHeight - touchContainerData.scrollHeight) <
              0.6)
        ) {
          e.preventDefault()
        }
      }
    }
  }

  refreshUserTokenTimer: NodeJS.Timeout = setInterval(() => {}, 0) //Valid initial value
  componentDidMount() {
    const { refreshUserToken } = this.props
    window.addEventListener('storage', this.storageChangeHandler)
    window.addEventListener('wheel', this.handleMouseWheel, { passive: false })
    window.addEventListener('resize', this.handleWindowResize, false)
    this.refreshUserTokenTimer = setInterval(() => {
      refreshUserToken()
    }, 7200000) 
  }

  componentWillUnmount() {
    window.removeEventListener('storage', this.storageChangeHandler)
    window.removeEventListener('wheel', this.handleMouseWheel)
    window.removeEventListener('resize', this.handleWindowResize)
    clearInterval(this.refreshUserTokenTimer)
  }

  // Using raf-throttle seems to make no difference in performance.
  handleWindowResize = (): void => {
    this.setState({ windowInnerWidth: window.innerWidth, windowInnerHeight: window.innerHeight })
    const partialSession = { windowInnerWidth: window.innerWidth, windowInnerHeight: window.innerHeight }
    this.props.setSession(partialSession)
  }

  render() {
    //console.log( 'call to render app' )
    const userPages = false
    initSessionStateDispatch(this.boundSetState)

    return (
      <ErrorBoundary name="App">
        <DndProvider backend={HTML5Backend}>
          <Router>
            <div
              className={'rc_AppRouter'}
              style={{
                height: '100%',
                width: '100%',
                position: 'relative',
                left: 0,
                top: 0,
                overflow: 'hidden',
              }}
            >
              <Notify />
              <ReactHint />
              <Switch>
                <Route path="/login" component={UserLogin} />

                <Route
                  path="/sitePage"
                  render={() => <TopLevelUI view={'sitePageView'} />}
                />

                <PrivateRoute
                  path="/search"
                  render={() => (
                    <>
                      <TopLevelUI view={'searchView'} />
                    </>
                  )}
                />

                <PrivateRoute
                  path="/table/:tableid"
                  render={() => <TopLevelUI view={'tableView'} />}
                />

                <PrivateRoute
                  path="/plot/:plotid"
                  render={() => <TopLevelUI view={'xyPlotView'} />}
                />

                {userPages ? (
                  <>
                    <PrivateRoute
                      path="/home"
                      render={() => <TopLevelUI view={'homeView'} />}
                    />
                  </>
                ) : null}

                <PrivateRoute path="/userCreate" render={() => <UserCreate />} />
                {userPages ? (
                  <>
                    <PrivateRoute path="/install" render={() => <Install />} />
                    <PrivateRoute path="/settings" render={() => <UserSettings />} />
                  </>
                ) : null}

                <PrivateRoute path="/" render={() => <Redirect to="/search" />} />
              </Switch>
            </div>
          </Router>
        </DndProvider>
      </ErrorBoundary>
    )
  }
}

type RouteProps = {
  path: string
  render: () => JSX.Element
}
const PrivateRoute = (props: RouteProps) => {
  return localStorage.getItem('token') ? <Route {...props} /> : <Redirect push to="/login" />
}

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>, ownProps: OwnProps): DispatchProps => ({
  initAuth: (): void => {
    dispatch(initAuth())
  },
  refreshUserToken: (): void => {
    asyncDispatch(dispatch, refreshLoginThunk())
  },
  setAuthToken: (authToken: string): void => {
    dispatch(setAuthToken(authToken))
  },
  setAuthUserId: (authUserId: string): void => {
    dispatch(setAuthUserId(authUserId))
  },
  setSession: (session: Partial<SessionState>): void => {
    dispatch(updateSession(session))
  },
})

const mapStateToProps = (state: RootState): StateProps => ({
  session: state.session,
})

const App = connect(mapStateToProps, mapDispatchToProps)(AppRender)
export default App
