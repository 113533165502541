import React, {PureComponent} from 'react'
import type {TableComputedData, DomNode} from '../computedDataTable/getDefaultTableComputedData'
import {initTableResponsiveState, 
        restoreInitialTableResponsiveState,
        synchScrollLeft }           from './tableResponsiveState'
import {initiateScrollTopAnimation,
        setNewResponsiveStateScrollTop,
        stopScrollTopAnimation}     from './tableResponsiveStateVertScroll'
import {sessionStateChangeDispatch} from '../sharedComponents/reactDispatch'
import {handleResizeColStart,
        handleResizeColDrag,
        handleResizeColStop} from './actionResizeCol'


import ScrollControl      from './ScrollControl'
import SVGwrapper2        from '../SVGs/SVGwrapper2'
import SVGresizeCol       from '../SVGs/SVGresizeCol'
import {DraggableCore}    from 'react-draggable'



const MY_CURSOR_SIZE:number = 21   // Large resize icon sitting exactly on the column edge.
const MY_CURSOR_LEFT = -500  // Offscreeen coord where this icon sits when not in use.
const MY_CURSOR_TOP  = 100
var   cursorNode: DomNode = null
const dragCaptureArray : Array<DomNode> = []
var   localTouchContainerElement : DomNode = null

type OwnProps = {
  tableComputedData: TableComputedData,
  scrollLeft: number,
  scrollTop: number,  
  foregroundColor: string,
  movingAllocated : number,
  movingRequired : number,

  horzScrollControlRight : number,
  vertScrollControlTop : number,
  vertScrollControlWidth: number,
  horzScrollControlHeight: number,
  horzScrollControlTop: number,

  dataAllocated : number,
  dataRequired : number,
  minScrollHeightBeforeHidden : number,

  touchLeft: number,
  touchWindowWidth: number,
  touchContainerWidth: number,
  touchContentWidth: number,

  touchTop: number,
  touchWindowHeight: number,
  touchContainerHeight: number,
  touchContentHeight: number,

  displayedColWidths: Array<number>,
  startColLeft: Array<number>,
  colOrder: Array<number>,
  isCombinedTable: boolean,
  numLockedCols: number,
  lockedLeft: number,
  movingLeft: number,
  borderThickness: number,

  colresize_capture_width: number,
  colresize_capture_offset: number,
}

export default class ActionScrollRender extends PureComponent<OwnProps> {

  componentDidMount ( ) {
    const {scrollLeft, scrollTop} = this.props.tableComputedData
    if ( localTouchContainerElement ) {
      localTouchContainerElement.scrollTop =  Math.round( scrollTop )
      localTouchContainerElement.scrollLeft=  Math.round( scrollLeft )
    }
  }

  componentDidUpdate ( ) {
    const {scrollLeft, scrollTop} = this.props.tableComputedData
    if ( localTouchContainerElement ) {
      localTouchContainerElement.scrollTop =  Math.round( scrollTop )
      localTouchContainerElement.scrollLeft=  Math.round( scrollLeft )
    }
  }

  refActionScroll = (element: DomNode): void => {
    this.props.tableComputedData.tableDomNodes.actionScroll = element
  }
  initCursorNode = (element: DomNode): void => {
    cursorNode = element
  }
  initLocalTouchContainerElement = (element: DomNode): void => {
    localTouchContainerElement = element
  }


  render() {
    const { foregroundColor, vertScrollControlTop,
        movingAllocated, movingRequired, horzScrollControlRight, horzScrollControlHeight,
        minScrollHeightBeforeHidden, touchTop, touchLeft, touchWindowWidth, touchWindowHeight,
        touchContainerWidth, touchContainerHeight, dataAllocated, dataRequired,
        vertScrollControlWidth, touchContentWidth, touchContentHeight, colOrder,
        numLockedCols, displayedColWidths, startColLeft, lockedLeft, movingLeft,
        isCombinedTable, borderThickness, horzScrollControlTop, 
        colresize_capture_width, colresize_capture_offset, scrollLeft, scrollTop  } = this.props

    //console.log( '' )
    //console.log( 'ActionScroll top, left:', scrollTop, scrollLeft ) 
    //console.log( 'ActionScroll moving allocated/required:', movingAllocated, movingRequired ) 

    // Globals to the event handlers at bottom of module.
    // Any onScroll() event that exactly matches these parameters
    // is result of the Render operation.  This is 'Back-Talk' from
    // the built-in browser scrollbars.  And it is ignored.
    tableComputedData = this.props.tableComputedData
    scrollLeftRendered= Math.round(scrollLeft)
    scrollTopRendered = Math.round(scrollTop)
    lastScrollLeft    = Math.round(scrollLeft)
    lastScrollTop     = Math.round(scrollTop)

    return (
      <div className={'rc_ActionScroll'}       
          ref={this.refActionScroll}
          style={{
            position: 'absolute', left: 0, top: touchTop,
            //background: 'green', opacity: 0.2,
            width: touchLeft + touchWindowWidth + vertScrollControlWidth,
            height: touchWindowHeight + horzScrollControlHeight,
          }}
      >

              {/*  Resize Cursor. Visible only during column resize. */}
              <div ref={this.initCursorNode}
              style={{position:'absolute', top: MY_CURSOR_TOP, left: MY_CURSOR_LEFT,
              visibility: 'hidden', height: MY_CURSOR_SIZE, width: MY_CURSOR_SIZE + 4 }}>
                    <SVGwrapper2>
                      <SVGresizeCol color={foregroundColor} width={MY_CURSOR_SIZE + 4} height={MY_CURSOR_SIZE} />
                    </SVGwrapper2>
              </div>

              <ScrollControl
              tableComputedData={tableComputedData}
              scrollName={'vertScroll'}
              top={vertScrollControlTop}
              right={0}
              containerPx={dataAllocated}
              contentPx={dataRequired}
              scrollPositionPx={scrollTop}
              minScrollHeightBeforeHidden={minScrollHeightBeforeHidden}
              foregroundColor={foregroundColor}
              />


              <ScrollControl
              tableComputedData={tableComputedData}
              scrollName={'horzScroll'}
              top={horzScrollControlTop - touchTop}
              right={horzScrollControlRight}
              containerPx={movingAllocated}
              contentPx={movingRequired}
              scrollPositionPx={scrollLeft}
              minScrollHeightBeforeHidden={0}
              foregroundColor={foregroundColor}
              />
  
              <div className={'TouchWindow'}
              style={{ position: 'absolute', top:0, left:touchLeft,
              height: touchWindowHeight, width : touchWindowWidth,
              //background: 'red', opacity: 0.2,
              overflow: 'hidden', }} >

{/* Adding this next div forces a 'layout/reflow'.
    Likely due to setting element.scrollLeft and scrollTop.
*/}
                <div className={'TouchContainer'}
                ref={this.initLocalTouchContainerElement }
                onScroll={ (e)=>onTouchScrollUserInput(e) }
                style={{ position: 'absolute', top:0, left:0,
                width:  touchContainerWidth, height: touchContainerHeight,
                overflow: 'scroll', }} >

                  <div className={'TouchContent'}
                  style={{ width: touchContentWidth, height: touchContentHeight, }}>

                        {[...Array(displayedColWidths.length).keys()].map ( colIndex => {
                        var colKey = colOrder[colIndex]
                        var colWidth = displayedColWidths[colIndex]
                        if (colWidth === 0) { return null }
                        var colRightEdge = startColLeft[colIndex] + colWidth
                        var firstVisibleMovingX = movingLeft - touchLeft - borderThickness + scrollLeft
                        var lastVisibleMovingX = firstVisibleMovingX + movingAllocated
                        // xCenterLine expected by colResize is with respect to the leftmost viewWidth edge.
                        if ( !isCombinedTable && colIndex < numLockedCols ) {
                          // xCenterLine expected by colResize is with respect to the leftmost viewWidth edge.
                          // xDragLeft used to render capture area in ActionScroll is with respect to content left edge
                          var xCenterLine = lockedLeft + colRightEdge
                          var xDragLeft = colRightEdge + borderThickness + scrollLeft - colresize_capture_width/2
                        } else {
                          // moving column; Some visible.
                          xCenterLine = movingLeft + colRightEdge - scrollLeft
                          xDragLeft = (movingLeft - touchLeft) + colRightEdge - colresize_capture_offset
                          if (xDragLeft < firstVisibleMovingX  ) { return null }
                          if (xDragLeft >  lastVisibleMovingX ) { return null }
                        }

                        // Debugging your code: ( set background color in the draggable child div. )
                        // First, the capture areas should align with the column edges.
                        //   - if not, must the error in the lines of code that follow.
                        // Second test the xCenterline is correct.   (above code.)
                        //   - Hover over any capture area.
                        //   - The pointer will change to resize anywhere over a capture area.
                        //   - On click, the resize cursor should become 'bold'.
                        //   - The large resize cursor should align perfectly with the column edge.
                        // Third, verify scrolling:
                        //   - the capture areas should track the scolling right edges.
                        //   - the capture areas should disappear when an edge scrolls out-of-view.

                        return (
                            <DragCapture
                              key={colIndex}
                              tableComputedData={tableComputedData}
                              colKey={colKey}
                              colIndex={colIndex}
                              xCenterLine={xCenterLine}
                              xDragLeft={xDragLeft}
                              colresize_capture_width={tableComputedData.widthObj.colresize_capture_width} />
                        )})}
                  </div>
              </div>
          </div>
      </div>
    )
  }
}



type DragCaptureProps = {
  colKey:number,
  colIndex:number,
  xCenterLine:number,
  xDragLeft: number,
  colresize_capture_width: number, 
  tableComputedData: TableComputedData,  
}
class DragCapture extends PureComponent<DragCaptureProps> {

  initDragCaptureArray = (element: DomNode): void => {
    const {colKey} = this.props
    dragCaptureArray[colKey] = element
  }



  render () {
    const {colKey, colIndex, xCenterLine, xDragLeft, colresize_capture_width,
      tableComputedData } = this.props
    return(
      <DraggableCore
        enableUserSelectHack={false}
        key={`colIndex`}
        onMouseDown={ e => e.stopPropagation() }  
        onStart={ (e, dragData)=>handleResizeColStart( e, dragData, colIndex,
          colKey, xCenterLine, cursorNode, dragCaptureArray, tableComputedData )}
        onDrag ={ (e, dragData)=>handleResizeColDrag ( e, dragData )}
        onStop ={ (e, dragData)=>handleResizeColStop ( dragData )} >

            <div className={'colResizeDragControl'}
            ref={ n => this.initDragCaptureArray(n) }
            key={colKey}
            style={{ position:'absolute', left:xDragLeft, top: tableComputedData.scrollTop,
            height: '100%', width: colresize_capture_width,
            //background : 'red' , opacity: 0.3,
            cursor : 'col-resize', }} />

      </DraggableCore>
    )
  }
}






/*

CONCERNING COMPONENT's DESIGN -

This component has two very different responsibilities.
  #1  This component sits 'over' the FocalPlane.  All event handlers that
  are prioritized over (before) the FocalPlane 'onClick' are located in this component.
        - Touch scrolling.
        - Custom vertical and horzontal scroll controls
        - Column Resize divs (narrow, tall divs that sit directly over column boundaries)
  #2  Take advantage of built-in browser touchScroll event handling -- but suppress unwanted
  browser scrollBar behaviors:
        - Hide built-in scroll controls. (Both internal or external - whichever browser chooses)
        - Prevent built-in scrolling 'Back-Talk'. (Scroll events issued by browser
          every time we programmatically tell the touchScroll component to shift.

Fortunately, there is a room for a solution (at least at this time!)


Design rules:

1) I believe every currently existing responsive state animation has one or more
  corners where it may adjust the scroll position(s). All visible components share
  code to redraw themselves (tableResponsiveState module), including changes in scroll left/top.
  However, one exception -- this module.   Rule number one:  This module may
  never by scrolled programmatically, except when it is rendered.  In other words,
  the scroll position of this module is set during render only, and may NEVER be
  modified doing a responsive animation.   For now, just accept the rule.

2) This ActionScroll component owns the two custom scrollBars, because these handlers
   need to sit above the focal plane.  The position of these scrollControls WILL change
   with many animations.  This is managed by resposiveState.updateTableBlocks() by changing
   this parent components width attribute.  The scroll controls are attached to
   the right/bottom side of the ActionScroll component.  This does not violate Rule#1.

3) The ActionScroll component owns the colResize drag controls.  Changing column
   widths definitely requires programmatically setting the scroll position.  But we
   do NOT scroll this Component's touchScroll content.  The drag control only needs to
   pass its 'deltaX' information to the resizeCol module.  This puts an additional
   burden on the resizeCol algorithm to track the scrollLeft position.  If/when
   working in the resizeCol module, it is tempting to 'just scroll the content
   container that holds the dragDivs to simplify the code.'   Don't do this!
   Rule #1 above is the more important constraint.

4) The TouchScroll module uses built-in scrollBars.  The built-in scroll
   bars issue a onScroll event whenever we set node.scrollLeft or node.scrollTop.
   This is NOT a good behavior.  If the user provides touch/scroll input, then we
   expect the onScrollEvent to be called.  HOWEVER, if our program 'instructs' the
   touchScroll to change its scroll position, then the built-in browser behavior
   "Back-talks".  It issues another onScroll event, presumably to be handled in
   the next frame.  PROBLEM: Assume user modifies our custom scroll-control. We
   issue the equivalent of a onScroll event so the app responds to the control.
   However, IF we issue any scroll command to the ActionScroll component, then
   it issues yet another onScroll event of its own.  So a scroll to position '10'
   in one frame, results in a browser issued onScroll event to '10' in the next
   frame.  Hence, two streams of onScroll events, separated in time by a frame.
   A mess!

5) Hence:  How to set the TouchScroll initial scroll values?
      How to take advantage of touch event handling, where we must capture a onScroll event?
      How to NOT issue onScroll events when we programmatically set the scroll position?

6) All responsive state handlers that need to set scroll position call some or all of:
      tableResponsiveState.synchScrollLeft
      tableResponsiveState.synchScrollTop
      horzControl.redrawScrollControl
      vertControl.redrawScrollControl
   These redraw the table.  But by rule #1, they never redraw the ActionScroll component.
   Hence no browser 'Back-talk' from programmatic changes we make in the scroll positions.

7) This leaves two case:
    Rendering the ActionScroll component requires setting node.scrollLeft and node.scrollRight.
    The browser issues one (perhaps two) onScroll events, which are programmatic -- ignore!
    However, if/when the user uses the touchScroll, we want to capture these
    onScroll events, and route the newScrollLeft and newScrollTop values to the
    redraw functions in #6 above.

    This is done in onTouchScrollUserInput( ) function.  (Which immediately follows this
    comment.)  We can identify 'Back-Talk' if the requested newScrollLeft or newScrollRight
    exactly matches the currently rendered position !

8) The only remaining module requirement is to hide the browser scroll bars.  This is
    done with a three level scrollable div:

        <div className={'TouchWindow'}>
            <div className={'TouchContainer'}>
                <div className={'TouchContent'}/>
            </div>
        </div>

    The container is set overflow: 'scroll'.
    The content is equal to, or larger than the container, holding the scrollable content.

    The top level 'window' is slightly smaller than the container, with overflow:'hidden'.
    It has dead space (unused by our application) on both the right and bottom edges.
    This is where the browser scroll bars are placed, but hidden behind our TouchWindow.

    The layout calculator module periodically queries the browser's scrollBar type:
    (internal or external) and if an external scrollBar, determines its width.
    Currently, once every 5 seconds.  So if the use changes the pointing device, it may be
    up to 5 seconds before the scrollLeft/scrollTop of the touchScroll control is
    sync'ed.  Very unlikely they would see this.  And not serious if we are wrong.  Just
    the potential for a small amount of extra or missing table at the extent of the
    scrollable area.

    The layout calculator owns the rules for how much deadSpace is required.
    Hence - We define a TouchWindow size and location.  It could be anywhere and
    any shape.  Even completely unrelated to the table location and shape!  However,
    common sense says we locate the TouchWindow directly over and aligned with
    the visible table corners. The TouchContainer is slighty larger with deadSpace
    determined by the layout calculator. This hides the scroll bars.
    The TouchContent is the TouchContainer size, plus the
    necessary additional scroll range in both X and Y directions.

    The layout Calculator determines the width/height of each of the three layers.  And
    the top/left corner position for placement.
*/


type ScrollDirection = 'horz' | 'vert' 
// These variables are initialized at each reactRender.
var tableComputedData : TableComputedData
var scrollLeftRendered: number
var scrollTopRendered: number
var newScrollLeft: number
var newScrollTop: number 


// State variables used during touch-scrolling.
var activeScrollDirection   : ScrollDirection = 'horz'
var isActiveScrolling : boolean = false
var lastScrollLeft : number = 0
var lastScrollTop  : number  = 0

/*
{initiateScrollTopAnimation,
  initResponsiveStateScrollTop,
  isScrollTopAnimationActive,
  stopScrollTopAnimation}
*/


const onTouchScrollUserInput = ( e: React.SyntheticEvent<HTMLDivElement> ) : void => {
  newScrollLeft = e.currentTarget.scrollLeft
  newScrollTop  = e.currentTarget.scrollTop
  //console.log( 'onTouchScrollUserInput', scrollLeftRendered, scrollTopRendered, newScrollLeft, newScrollTop )

  // This is how we ignore 'back-talk' programmatic scroll changes.
  // A call to onTouchScrollUserInput is 'back-talk' when:
  //    - It is an initializing (touchStart) event, identified by
  //    - scrollPosition requested === scrollPositionRendered.
  if ( isActiveScrolling === false  &&
    newScrollLeft === scrollLeftRendered && newScrollTop === scrollTopRendered &&
    newScrollLeft === lastScrollLeft     && newScrollTop === lastScrollTop )  {
    //console.log( 'TouchScroll "Back-talk" --- ignored' )
    return
  }

  //console.log( 'This is not "Back-talk" --- User has "touched" modified the TouchScroll Component.' )

  // Unlike many other 'animations', we cannot suspend pointEvents.
  // Because the input to this animation IS the pointerEvents.
  // The underlying direct DOM animation will eventually issue a
  // reactDispatch after NoActivityTimeDelayUntilReactDispatch milli-seconds.
  // We just assume that the user cannot get to some other pointer control
  // quickly enough set in motion two simultanous animations.
  const deltaScrollLeft = newScrollLeft - lastScrollLeft
  const deltaScrollTop  = newScrollTop  - lastScrollTop
  lastScrollLeft = newScrollLeft
  lastScrollTop  = newScrollTop

  // We now know and are about to set isActiveScrolling true!  
  // Most cases here are 'during scrolling' and the isActive flag is ALREADY true!
  // However, here is where we capture the very first scroll event so we can initialize
  // the tableResponsiveState.
  if (isActiveScrolling === false) { 
    // First touchScroll event! 
    // Touch scroll allows both horz and vert scrolling, even when one of the directions is still active.
    // We can't support that.  (At least without extra work)
    // So we will support scrolling ONLY in the active direction at a time. 
    activeScrollDirection= ( Math.abs(deltaScrollLeft) >= Math.abs(deltaScrollTop) ) ? 'horz' : 'vert'
    let {widthObj, heightObj, styleObj, scrollTop, scrollLeft, 
      colOrder, tablelook, table, canEdit, 
      derivedColAttributesArray } = tableComputedData
    let {getCellHTML_fromColKeyAndRowIndex} = tableComputedData 
    
    if ( activeScrollDirection === 'horz' ) {
      initTableResponsiveState( tableComputedData )
    }
    
    else if ( activeScrollDirection === 'vert' && tablelook && table) {
        setNewResponsiveStateScrollTop( scrollTop )
        let {isRowNumberVisible} = tablelook.attributes  
        let hiddenIndexArr: number[] = tablelook.attributes.lookColumns.map( c => c.hidden )
        let colDataTypeArr: string[] = derivedColAttributesArray.map( c => c.colDataType )   
        initiateScrollTopAnimation( newScrollTop, scrollLeft, 
                                    widthObj, heightObj, styleObj, tableComputedData.tableDomNodes,
                                    colOrder, hiddenIndexArr, colDataTypeArr, 
                                    isRowNumberVisible, canEdit,
                                    getCellHTML_fromColKeyAndRowIndex )  
    }
  }

  isActiveScrolling = true

  // TouchScroll allows the user to 'change directions' before the prior scroll is complete.
  // I choose NOT to support at this time.
  // IF the user changed direction, we are just going to ignore these changes to scroll position.
  // Still gives some funky stop/start behavior when scroll direction changes, but better than
  // getting non-responsive if/when uses changes direction then just continues to swipe because
  // they didn't get some instant change of direction in the visible scrolling.   Hence
  // minimal wait time to change direction is to let first direction coast to a complete stop.
  const thisFrameScrollDirection = ( Math.abs(deltaScrollLeft) >= Math.abs(deltaScrollTop) ) ? 'horz' : 'vert'
  if (activeScrollDirection !== thisFrameScrollDirection) { return }

  setTouchScrollInterval( )  // This is the only function that can shutdown scrolling!
                             // Calling this here PREVENTS shutdown!
                             // NOT calling this after a predefined interval shuts down responsive scrolling:
                             // 1) shuts the scrolling animation down (restores domNode states)
                             // 2) issues a scrollLeft or scrollTop sessionState change
                             // 3) sets isActiveScrolling = false

  // Next func call will redrawsScrollControl; translates; updates Highlights;
  if ( activeScrollDirection === 'vert' ) {
    setNewResponsiveStateScrollTop( newScrollTop ) 
  } else {
    let containerPx = tableComputedData.widthObj.movingAllocated
    let contentPx = tableComputedData.widthObj.movingRequired
    synchScrollLeft( newScrollLeft, containerPx, contentPx ) 
  }
}



const touchScrollDispatch = ( ) : void => {
  //console.log( 'Call to touchScrollDispatch' )
  isActiveScrolling = false 

  if (activeScrollDirection === 'vert') {
    stopScrollTopAnimation( )// Don't believe we need to ask whether scrollTop 'catch-up'
                             // frames are finished rendering, because we can only have
                             // about 4-6 catch-up frames.  But the timeout to decide
                             // that touchScroll has finished is far larger.
                             // But if for some reason we do need to double check
                             // easy to add that check here if needed.
    //console.log( 'sessionState ScrollTop Dispatch', newScrollTop )
    var mods = [ {newVal: newScrollTop, path: 'activeTableScrollTop'}]
    sessionStateChangeDispatch( mods, 'Vertical Touch Scroll' )
  } 
  else { // Direction = 'horz'
    restoreInitialTableResponsiveState()
    mods = [ {newVal: newScrollLeft, path: 'activeTableScrollLeft'}]
    sessionStateChangeDispatch( mods, 'Horizontal Touch Scroll' )
  }
}



const NoActivityTimeDelayUntilReactDispatch = 200
var   touchScrollTimeoutID : NodeJS.Timeout | string | number | undefined = undefined
const setTouchScrollInterval = () :void => {
  //console.log( 'reset touchScroll Interval')
  clearTimeout( touchScrollTimeoutID )
  touchScrollTimeoutID = setTimeout( touchScrollDispatch, NoActivityTimeDelayUntilReactDispatch )
}
