// @flow
import React, {PureComponent}   from 'react'
import FilterRules              from '../sharedComponents/FilterRules'
import type {Tablelook}  from '../types'
import type {TableComputedData}        from '../computedDataTable/updateTableComputedData'

type OwnProps = {
  tablelook: Tablelook,
  tableComputedData: TableComputedData,
}

export default class EditRowFiltering extends PureComponent<OwnProps> {

  render() {
    return (
          <div className={'rc_StyleTabRowFilters'} style={{ marginLeft: 10, marginTop: 10, marginRight:10}}>
                <FilterRules
                  tablelook={this.props.tablelook}
                  tableComputedData={this.props.tableComputedData}
                  multiLineMode={'2line'}
                  resourceName={'rowFilters'} />
          </div>
    )
  }
}
