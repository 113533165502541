import { PureComponent } from 'react'
import './tooltips.css'

type OwnProps = {
  containerBottom: number
  containerLeft: number
  containerRight: number
  containerTop: number
  isBrightField: boolean
  toolTipStr: string | null
  targetHeight: number
  targetLeft: number
  targetTop: number
  targetWidth: number
  toolTipPlacement: string
  toolTipAddedExtension: number
  toolTipVisible: boolean
}

// position of the tooltipEl
type LocalState = {
  arrowPos: number
  left: number
  tipOnTop: boolean
  nub_height: number
  top: number
}

class Tooltips extends PureComponent<OwnProps, LocalState> {
  state = {
    arrowPos: 0,
    left: 0,
    tipOnTop: false,
    nub_height: 8,
    top: 0,
  }

  tooltipEl: HTMLDivElement | null = null

  componentDidUpdate() {
    const { containerLeft, containerRight, containerTop, toolTipAddedExtension,
      targetHeight, targetLeft, targetTop, targetWidth, toolTipPlacement } = this.props
    if (this.tooltipEl) {
      const tooltipRect = this.tooltipEl ? this.tooltipEl.getBoundingClientRect() : { height: 0, width: 0 }
      const localX = targetLeft - containerLeft
      const localY = targetTop - containerTop
      const left = Math.min(Math.max(localX - (tooltipRect.width / 2) + targetWidth / 2, 0), containerRight - tooltipRect.width)
      //const tipOnTop = localY + targetHeight + tooltipRect.height >= containerBottom
      const tipOnTop = (toolTipPlacement === 'above')
      const top = tipOnTop ? localY - tooltipRect.height - 0 : localY + targetHeight + 0
      const naturalMiddle = localX + targetWidth / 2
      const arrowPos = naturalMiddle - left - 5
      const NOMINAL_NUB_HEIGHT = 12
      const nub_height = NOMINAL_NUB_HEIGHT + toolTipAddedExtension
      this.setState({ arrowPos, left, tipOnTop, top, nub_height })
    }
  }

  initRef = (element: HTMLDivElement | null): void => {
    this.tooltipEl = element
  }

  render() {
    const { isBrightField, toolTipStr, toolTipVisible } = this.props
    const { arrowPos, left, tipOnTop, top, nub_height } = this.state
    const modifier = isBrightField ? 'bright' : 'dark'

    if (toolTipStr && toolTipVisible) {
      return (
        <div
          className={`tooltip`}
          ref={this.initRef}
          style={{ left, top }}
        >
          {!tipOnTop &&
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              viewBox={`0 0 10 ${nub_height}`}
              height={nub_height}
              width="10"
              style={{
                position: 'relative',
                left: `${arrowPos}px`,
                display: 'block'
              }}
            >
              {/*<path d="M 10,5 h -10 l 5,-5 5,5 Z" style={{fill: isBrightField ? '#000' : '#FFF'}}/>*/}
              <path d="M 10,5 h -10 l 5,-5 5,5 Z" style={{ fill: 'red' }} />
            </svg>
          }
          <div className={`tooltip__content tooltip__content--${modifier}`}>
            {toolTipStr}
          </div>
          {tipOnTop &&
            <svg
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              viewBox={`0 0 10 ${nub_height}`}
              height={nub_height}
              width="10"
              style={{
                position: 'relative',
                left: `${arrowPos}px`,
                display: 'block'
              }}
            >
              <path
                d={`M 10,0 h -10 l 5,${nub_height} 5,-${nub_height} Z`}
                style={{
                  fill: isBrightField ? '#000' : '#FFF'
                }}
              />
              {/* <path d="M 10,0 h -10 l  5,5  5,-5  Z" style={{fill: 'red'}}/> */}
            </svg>
          }
        </div>
      )
    } else {
      return null
    }
  }
}

export default Tooltips
