import constants from '../sharedComponents/constants'

// This returns the set of points defining the highlight string
// of the floating palette.
// 1st four args are the selected cell.
// 2nd four args are the floating palette geometry.
// Draw a highlight string around the selected cell,
// and connecting the center of the selected cell (obj1) to the
// center of floating paletter (obj2)

const calcHighlightLine = (
  left0:number, top0:number, width0:number, height0:number,
  left1:number, top1:number, width1:number, height1:number ) : string => {

    // top, bottom, left, right are the Cell Highlight coords (center of the hightlight line)
    // We offset (grow) the outline by 1/2 the width of the outline.
    // Hence the inside edge of the outline hugs the selected cell.
    const offset = (constants.HIGHLIGHT_WIDTH+1)/2  // Size the cell border box 'up' by half the lineWidth
    var top    = top0 - offset
    var bottom = top0 + height0 + offset
    var left   = left0 - offset
    var right  = left0 + width0 + offset
    var topString   = ' ' + String(top)
    var bottomString= ' ' + String(bottom)
    var rightString = '   ' + String(right)
    var leftString  = '   ' + String(left)

    // (newX, newY) is the intersection point between the cell outline and
    // the line connecting the center of the palette to the center of the cell.
    // This intersection point could be on located on any one of the four cell outline edges!
    var newX: number, newY: number

    // Next function returns the coordinates of upperLeft, lowerLeft, ...
    // The four corners of the highlight around the cell.
    const ul = ():string => { return leftString+topString }
    const ur = ():string => { return rightString+topString }
    const ll = ():string => { return leftString+bottomString }
    const lr = ():string => { return rightString+bottomString }
    const intersection = ():string => { return `  ${String(newX)} ${String(newY)}  ` }

    // (paletteX, paletteY) is the coordinate in the center of the floating palette.
    var paletteX = left1 + width1/2
    var paletteY = top1  + height1/2
    const cellEditorEndpt = ` ${String(paletteX)} ${String(paletteY)}`

    // (cellX, cellY) is the coordinate in the center of the selected cell
    const cellX = left0 + (width0)/2
    const cellY = top0  + (height0)/2

    switch ( true ) {
      case paletteY > bottom :
        // Calculation assuming we intersect the yBottomBox
        var proportion = (cellY-bottom)/(paletteY-cellY)
        newY = cellY - proportion*(paletteY-cellY)
        newX = cellX - proportion*(paletteX-cellX)
        var highlightLine = cellEditorEndpt+intersection()+ll()+ul()+ur()+lr()+intersection()
        break
      case paletteY < top :
        // Calculation assuming we intersect the yTopBox
        proportion = (cellY-top)/(cellY-paletteY)
        newY = cellY + proportion*(paletteY-cellY)
        newX = cellX + proportion*(paletteX-cellX)
        highlightLine = cellEditorEndpt+intersection()+ur()+lr()+ll()+ul()+intersection()
        break
      default :
        // We know for certain we intersect either xLeftBox or xRightBox
        // We will just use the y2 value, ignoring the small amount that
        // could be justifiably clipped.
        newY = cellY
        if (paletteX<cellX) {
          newX = left
          highlightLine = cellEditorEndpt+intersection()+ul()+ur()+lr()+ll()+intersection()
        } else {
          newX = right
          highlightLine = cellEditorEndpt+intersection()+lr()+ll()+ul()+ur()+intersection()
        }
    }

    if (newX > right) {
      // Means prior calculation does NOT intersect the top or bottom axis.
      // Redo calculation to xRightBox !
      proportion = (right-cellX)/(paletteX-cellX)
      newY = cellY + proportion*(paletteY-cellY)
      newX = cellX + proportion*(paletteX-cellX)
      highlightLine = cellEditorEndpt+intersection()+lr()+ll()+ul()+ur()+intersection()
    }

    if (newX < left) {
      // Means prior calculation does NOT intersect the top or bottom axis.
      // Redo calculation to xLeftBox !
        proportion = (cellX - left)/(cellX-paletteX)
        newY = cellY + proportion*(paletteY-cellY)
        newX = cellX + proportion*(paletteX-cellX)
        highlightLine = cellEditorEndpt+intersection()+ul()+ur()+lr()+ll()+intersection()
    }

    return highlightLine
}


export default calcHighlightLine
