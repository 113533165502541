import {Component}      from 'react'
import StyleSelectBox   from '../sharedComponents/StyleSelectBox'



type OwnProps = {
    className?: string,
    label0: string,
    label1: string,
    currentSelection: number,
    onSelect0: () => void,
    onSelect1: () => void,
    optionalMsg: string,
    totalWidth: number,
    msgWidth: number,
  }

  type DefaultProps = {
    fontSize: number
    msgFontSize: number
    optionHeight: number
    preGap: number
    postGap: number
    interGap: number
  }
  
  type Props = OwnProps & DefaultProps
  export class BinarySelectionOption extends Component<Props> {

    static defaultProps: DefaultProps = {
        fontSize: 14,
        msgFontSize: 12, 
        optionHeight: 20, 
        preGap: 0,
        postGap: 10,
        interGap: 0,
    }

    render() {
        const { label0, label1, currentSelection, onSelect0, onSelect1, 
                optionHeight, preGap, interGap, postGap, optionalMsg, 
                totalWidth, msgWidth, fontSize, className} = this.props
        const totalHeight = preGap + interGap + postGap + 2*optionHeight
        return (

            <div className={className ?? 'BinaryOptions'}
                style={{
                    position:'relative',   // local coord system
                    //background:'green',
                    width: totalWidth,
                    height: totalHeight,
                    fontSize,
                }}>

                <div className={'LeftContainer'}
                    style={{
                        position: 'absolute', left:0, top:preGap,
                        width: totalWidth, height: totalHeight - preGap,
                    }}
                >
                    <StyleSelectBox
                        indent={0}
                        id={'option0'}
                        preSpacer={0}
                        fontSize={fontSize}
                        gapBoxText={4}
                        textWidth={totalWidth-10}
                        rowHeight={optionHeight}
                        onClick={ onSelect0 }
                        isSelected={ currentSelection === 0 }
                        text={label0} />

                    <StyleSelectBox
                        indent={0}
                        id={'option1'}
                        preSpacer={interGap}
                        fontSize={fontSize}
                        gapBoxText={4}
                        textWidth={totalWidth-10}
                        rowHeight={optionHeight}
                        onClick={ onSelect1 }
                        isSelected={ currentSelection === 1 }
                        text={label1} />

                </div>
 
                <div className={'RightContainer'}
                  style={{
                    position: 'absolute', left:totalWidth - msgWidth, top:preGap,
                    width: msgWidth, height: totalHeight - preGap,
                    //background:'blue',
                    color: 'red',
                    textAlign: 'center'
                  }}
                >{optionalMsg}</div>

            </div>
        )
    }
}
