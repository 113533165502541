import type { AnyAction, Middleware, PayloadAction } from '@reduxjs/toolkit'
import deepEqual from 'fast-deep-equal'
import invariant from 'invariant'
import { isEmpty } from 'lodash'
import { get } from 'radash'
import uuidv4 from 'uuid/v4'
import { ErrorTypes }  from '../../shared/ErrorTypes'
import { setAuthToken } from './appReducer'
import { createResource, updateResourceLocally } from '../jsonapi'
import type { MultiRelationship, ParentType, Resource, ResourceId, ResourceType, SingleRelationship } from '../jsonapi/types'
import { notifyError } from './notifyReducer'
import { resourceNameLocal, resourceidLocal } from '../sharedComponents/reactDispatch'
import type { MiddlewareFunc, StoreType } from './store'
import { asyncDispatch, getErrorMessage } from '../sharedFunctions/utils'
import type { Mod } from '../types'
import {
  createDoAction,
  createMinorAction,
  createNoOpAction,
  createRedoAction,
  createUndoAction,
  saveChangesCheck,
  saveChangesFail,
  saveChangesStart,
  saveChangesSuccess
 } from './undoReducer'
import type { MinorPayload, ParentID, SaveChangesCheck, SaveChangesPayload, Trigger, UndoPayload } from './undoReducer'
import validator from '../sharedFunctions/validator'

const checkFrequency = 10000 // 10 seconds
// const checkFrequency = 1000 // 1 second
// const checkFrequency = 5000 // 5 seconds
// const checkFrequency = 60 * 60 * 1000 // 1 hour
let checkForChangesInterval: number | undefined

export type Change = {
  mods: Array<Mod>,
  title: string,
  trigger: Trigger,
  actionGroup: string
}

export type Changes = Resource & {
  attributes: {
    changes: Change[]
    createdDate?: string // added on the server side
  }
  relationships: {
    owner?: SingleRelationship, // added on the server side
    parent: SingleRelationship,
    children: MultiRelationship,
  }
}


const handleSaveChangesCheck = (store: StoreType, action: PayloadAction<SaveChangesCheck>): void => {
  const state = store.getState()
  if (state.undo.curActionGroup) {
    return
  }
  const parents = state.undo.parents
  const parentTypes: ParentType[] = ['tables', 'plots']
  for (const parentType of parentTypes) {
    const parentTypeIds = parents[parentType]
    const parentIds = Object.keys(parentTypeIds)
    for (const parentId of parentIds) {
      const {pendingSaveIndex, savedAtIndex} = state.undo.saveStatus[parentType][parentId]
      const {actions} = parentTypeIds[parentId]
      if (pendingSaveIndex === -1) {
        const lastIndex = actions.length - 1
        if (savedAtIndex < lastIndex) {
          // // only trigger for one resource at a time, to make error handling reasonable
          // let to = savedAtIndex + 1
          // const {resType, resId} = actions[to]
          // while (to < lastIndex) {
          //   if (actions[to + 1].resType !== resType || actions[to + 1].resId !== resId) {
          //     break
          //   }
          //   to += 1
          // }
          let to = lastIndex
          store.dispatch(saveChangesStart({parentType, parentId, from: savedAtIndex, to}))
        }
      }
    }
  }
}

const dispatchSaveChangesCheck = (store: StoreType): void => {
  store.dispatch(saveChangesCheck({
    time: Date.now()
  }))
}

const handleAuthChange = (store: StoreType, action: PayloadAction<string>): void => {
  const authToken = action.payload
  if (authToken) {
    if (!checkForChangesInterval) {
      checkForChangesInterval = window.setInterval(dispatchSaveChangesCheck, checkFrequency, store)
    }
  } else {
    if (checkForChangesInterval) {
      window.clearInterval(checkForChangesInterval)
      checkForChangesInterval = undefined
    }
  }
}

const populateOldVal = (store: StoreType, action: PayloadAction<UndoPayload>): void => {
  const {mods} = action.payload
  const state = store.getState()
  const realMods: Mod[] = []
  for (const mod of mods) {
    const {newVal, op, path, resId, resType} = mod
    const resource = state.api.resources[resType][resId]
    invariant(resource, 'populateOldVal without an existing resource')
    const oldVal = get(resource, path, undefined)
    if (!deepEqual(oldVal, newVal)) {
      // this is a real change
      realMods.push({
        newVal,
        oldVal,
        op,
        path,
        resId,
        resType,
      })
    }
  }

  if (process.env.NODE_ENV === 'development' && realMods.length < 200) {
    let isError = false
    for (const realMod of realMods) {
      const error = validator.verifyMod(realMod.resType, realMod)
      if (error) {
        isError = true
        store.dispatch(notifyError({errorType: ErrorTypes.DATA_ERR, message: 'Invalid Mod detected, check console for details'}))
        console.log(`ValidationError: ${error.message}\nDetails: `, error.details)
      }
    }
    if (isError) {
      action.type = createNoOpAction.toString()
    }
  }

  action.payload.mods = realMods
  if (realMods.length === 0) {
    action.type = createNoOpAction.toString()
  }


}

type ChangesList = {
  type: ResourceType,
  mods: Mod[]
}

const applyChangeLocally = (store: StoreType, action: PayloadAction<ParentID>): void => {
  const {parentType, parentId} = action.payload
  const state = store.getState()
  const actions = state.undo.parents[parentType][parentId].actions
  const {mods, minorState} = actions[actions.length - 1]
  const resources = new Map<string, ChangesList>()
  if (!createDoAction.match(action)) {
    // on undo or redo reset the minorState
    if (!isEmpty(minorState)) {
      resources.set(resourceidLocal, {
        type: resourceNameLocal,
        mods: [{
          newVal: minorState,
          op: 'set',
          path: 'attributes.minorState',
          resId: resourceidLocal,
          resType: resourceNameLocal
        }]
      })
    }
  }
  for (const mod of mods) {
    if (resources.has(mod.resId)) {
      const changes = resources.get(mod.resId)!
      changes.mods.push(mod)
    } else {
      resources.set(mod.resId, {
        type: mod.resType,
        mods: [mod]
      })
    }
  }
  for (const [resId, changes] of resources) {
    store.dispatch(updateResourceLocally({type: changes.type, id: resId, mods: changes.mods}))
  }

  // also capture minorState
  // TODO: payload is readonly, need a new approach to this, or ignore until we remove minorState
  // const resource = state.api.resources?.[resourceNameLocal]?.[resourceidLocal]
  // const payload: UndoPayload = action.payload as UndoPayload
  // payload.minorState = resource?.attributes?.minorState ?? {}
}

const applyMinorChangeLocally = (store: StoreType, action: PayloadAction<MinorPayload>): void => {
  const {mods, resId, resType} = action.payload
  store.dispatch(updateResourceLocally({type: resType, id: resId, mods}))
}

const saveChanges = async (store: StoreType, parentType: ParentType, parentId: string, patch: Resource): Promise<void> => {
  try {
    await asyncDispatch(store.dispatch, createResource(patch))
    store.dispatch(saveChangesSuccess({parentType, parentId}))
  } catch (err) {
    store.dispatch(saveChangesFail({parentType, parentId}))
    // const errors = err.response?.data?.errors
    // if (errors) {
    //   store.dispatch(notifyJsonApiError(errors, ErrorTypes.SERVER_ERR))
    // } else {
      store.dispatch(notifyError({errorType: ErrorTypes.SERVER_ERR, message: getErrorMessage(err)}))
    // }
  }
}

// const applyMod = (patch: Resource, mod: Mod, resource: Resource): void => {
//   const path = _.toPath(mod.path)
//   const lastIndex = path.length - 1
//   let sourceObj = resource
//   let targetObj = patch
//   path.forEach((pathPart: string, index: number): void => {
//     if (index === lastIndex) {
//       // set the value
//
//       // now that undo actions can be modified (to combine a quick
//       // series of changes to the same value into one action), there's no
//       // way to safety check oldVal
//       targetObj[pathPart] = mod.newVal
//     } else {
//       // build up the object chain
//       if (sourceObj.hasOwnProperty(pathPart)) {
//         sourceObj = sourceObj[pathPart]
//         if (!targetObj[pathPart]) {
//           if (_.isObject(sourceObj)) {
//             if (_.isArray(sourceObj)) {
//               targetObj[pathPart] = []
//               targetObj[pathPart].length = sourceObj.length
//             } else {
//               targetObj[pathPart] = {}
//             }
//           } else {
//             warning(false, `applyMod: found path part that is not an Object at path part ${pathPart} on object: ${JSON.stringify(sourceObj)}`)
//           }
//         } else {
//           warning( _.isArray(sourceObj) === _.isArray(targetObj[pathPart]), `applyMod: mismatch in source/target array/object types on property ${pathPart}`)
//         }
//         targetObj = targetObj[pathPart]
//       } else {
//         warning(false, `applyMod: could not find proprperty ${pathPart} as part of path ${JSON.stringify(path)} in object ${JSON.stringify(sourceObj)}`)
//       }
//     }
//   })
// }

// const combineActions = (store: Store<State, Action>, resType: string, resId: string, actions: Array<UndoPayload>): Resource => {
//   const state = store.getState()
//   const currentObj = _.get(state, ['api', 'resources', resType, resId], null)
//   const patch = {
//     id: resId,
//     type: resType,
//     attributes: {},
//   }
//   if (currentObj) {
//     actions.forEach((action: UndoPayload): void => {
//       action.mods.forEach((mod: Mod): void => {
//         applyMod(patch, mod, currentObj)
//       })
//     })
//   }
//   return patch
// }

const combineChanges = (store: StoreType, resActs: Array<UndoPayload>, parentType: ResourceType, parentId: string): Changes => {
  const changes: Changes = {
    id: uuidv4(),
    type: 'changes',
    attributes: {
      changes: Array<Change>()
    },
    relationships: {
      parent: {
        data: {
          type: parentType,
          id: parentId,
        }
      },
      children: {
        data: Array<ResourceId>()
      }
    }
  }

  // collect all the children resource type and ids
  const children = {[resourceidLocal]: {type: resourceNameLocal,
                                      id: resourceidLocal }}
  for (const act of resActs) {
    for (const mod of act.mods) {
      if (!children.hasOwnProperty(mod.resId)) {
        children[mod.resId] ={
          type: mod.resType,
          id: mod.resId
        }
      }
    }

    // copy all the resActs into changes attribute
    changes.attributes.changes.push({
      mods: act.mods,
      title: act.title,
      trigger: act.trigger,
      actionGroup: act.actionGroup,
    })
  }

  // add the minorState from the current state as a mod
  const resource = store.getState().api.resources[resourceNameLocal][resourceidLocal]
  if (!isEmpty(resource.attributes.minorState)) {
    changes.attributes.changes.push({
      mods: [{
        newVal: resource.attributes.minorState,
        op: 'set',
        path: 'attributes.minorState',
        resId: resourceidLocal,
        resType: resourceNameLocal,
      }],
      title: 'minor state change',
      trigger: 'do',
      actionGroup: '',
    })
  }

  const ids = Object.keys(children)
  for (const id of ids) {
    changes.relationships.children.data.push(children[id])
  }

  return changes
}

const handleSaveChangesStart = (store: StoreType, action: PayloadAction<SaveChangesPayload>): void => {
  const state = store.getState()
  if (state.app.authToken) {
    const {from, parentType, parentId, to} = action.payload
    const undoLog = state.undo.parents[parentType][parentId]
    const actions = undoLog.actions

    // // verify that the range from/to is all for one resource
    // const {resType, resId} = actions[from + 1]
    // for (let i = from+2; i <= to; i++) {
    //   const {resType: nextResType, resId: nextResId} = actions[i]
    //   invariant(resType === nextResType && resId === nextResId, 'from/to in save changes spans multiple resources')
    // }

    const resActs = actions.slice(from + 1, to + 1)
    // const patch = combineActions(store, resType, resId, resActs)
    const changes = combineChanges(store, resActs, parentType, parentId)
    // // add updatedDate to patch
    // const now = (new Date()).toISOString()
    // patch.attributes.updatedDate = now
    saveChanges(store, parentType, parentId, changes)
  }
}


// const undoMiddleware: Middleware<{}, unknown, ThunkDispatch<unknown, unknown, AnyAction>> = (store) =>
//   (next: (action) => Action) => (
//   (action: Action): Action => {

const undoMiddleware: Middleware<(store: StoreType) => (next: MiddlewareFunc) => MiddlewareFunc> =
(store: StoreType) =>
  (next: MiddlewareFunc) => (
    (action: AnyAction): AnyAction => {

    // do something before action
    //console.log( 'middleware action', action.type, action )
    //console.log( 'middleware pendingUndoAction', pendingUndoAction !== null )
    if (setAuthToken.match(action)) {
      handleAuthChange(store, action)
    } else if (createDoAction.match(action)) {
      populateOldVal(store, action)
    }

    // let the reducer (or other middleware) do it's thing
    const result = next(action)

    // do something after action
    if (createDoAction.match(action)) {
      applyChangeLocally(store, action)
    } else if (createRedoAction.match(action)) {
      applyChangeLocally(store, action)
    } else if (createUndoAction.match(action)) {
      applyChangeLocally(store, action)
    } else if (createMinorAction.match(action)) {
      applyMinorChangeLocally(store, action)
    } else if (saveChangesCheck.match(action)) {
      handleSaveChangesCheck(store, action)
    } else if (saveChangesStart.match(action)) {
      handleSaveChangesStart(store, action)
    }

    return result
  }
)

export default undoMiddleware
