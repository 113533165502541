import invariant from 'invariant'
import { PureComponent } from 'react'
import { measureText } from '../sharedFunctions/measureText'
import { plotLayoutConsts } from './plotLayoutConsts'

/*
const errMsgTemp = [
  {type:'line', text: 'First line of error message. '},
  {type:'line', text: '2nd line of error message. '},
  {type:'line', text: 'Third'},
  {type:'line', text: '2nd line of error message. '},
  {type:'line', text: 'Third'},
  {type:'line', text: '2nd line of error message. '},
  {type:'line', text: 'Third'},
  {type:'line', text: '2nd line of error message. '},
  {type:'line', text: 'Third'},
  {type:'line', text: '2nd line of error message. '},
  {type:'line', text: 'Third'},
  {type:'line', text: '2nd line of error message. '},
  {type:'line', text: 'Third'},
  {type:'line', text: '2nd line of error message. '},
  {type:'line', text: 'Third'},
  {type:'line', text: '2nd line of error message. '},
  {type:'line', text: 'Third'},
  {type:'line', text: '2nd line of error message. '},
  {type:'line', text: 'Third'},
  {type:'line', text: '2nd line of error message. '},
  {type:'line', text: 'Third'},
  {type:'line', text: '2nd line of error message. '},
  {type:'line', text: 'Third'},
  {type:'line', text: ''},
  {type: 'table', text: [
    ['','','hi', 'tere','9.0045'],
    ['a','','4.56', '3.377733','9.0045'],
    ['','abc','9.32456', '22','9.0045'],
    ['aa','','-6', '+3e67','9.0045'],
  ]
},
  {type:'line', text: ''},
  {type:'line', text: 'one more line.  OK?'},
]
*/

const DEBUG = false
// next layout params are fractions of the fontSize
const MARGIN_BOTTOM_AS_FRACTION_OF_FONTSIZE = .20   // Space between lines
const MARGIN_RIGHT_AS_FRACTION_OF_FONTSIZE = 1    // Space between table columns
const MESSAGE_CONTAINER_BLANK_BORDER_WIDTH = 2     // Clear white space surrounding text
const MESSAGE_CONTAINER_GRIDDED_BORDER_WIDTH = 2     // Border showing grid axis at perimeter of plottable area


type ErrTextProps = {
  width: number
  text: string
  fontSize: number
  marginRight: number
}
class ErrText extends PureComponent<ErrTextProps> {
  render() {
    const { width, text, fontSize, marginRight } = this.props
    return (
      <div className={'rc_ComponentXYerrMsg'}
        style={{
          flex: '0 0 auto',
          width,
          height: fontSize,
          fontSize,
          marginBottom: MARGIN_BOTTOM_AS_FRACTION_OF_FONTSIZE * fontSize,
          marginRight,
          background: DEBUG ? 'yellow' : 'transparent'
        }}
      >{text}</div>
    )
  }
}


type ErrTableRowProps = {
  colWidths: number[]
  text: string[]
  fontSize: number
}
class ErrTableRow extends PureComponent<ErrTableRowProps> {
  render() {
    const { colWidths, text, fontSize } = this.props
    const numCol = colWidths.length
    return (
      <div className={'ErrMsg_RowOfTextTable'}
        style={{
          flex: '0 0 auto',
          display: 'flex', flexFlow: 'row nowrap',
        }}
      >
        {text.map((thisTextCol, index) => {
          var marginRight = (index === numCol - 1) ? 0 : MARGIN_RIGHT_AS_FRACTION_OF_FONTSIZE * fontSize
          return (
            <ErrText
              width={colWidths[index]}
              text={thisTextCol}
              fontSize={fontSize}
              marginRight={marginRight}
              key={index}
            />
          )
        })}
      </div>
    )
  }
}


type ErrTableProps = {
  colWidths: number[]
  text: string[][]
  fontSize: number
}
class ErrTable extends PureComponent<ErrTableProps> {
  render() {
    const { colWidths, text, fontSize } = this.props
    return (
      <div className={'ErrTable'} >
        {text.map((thisTextRow, index) => {
          return (
            <ErrTableRow
              colWidths={colWidths}
              text={thisTextRow}
              fontSize={fontSize}
              key={index}
            />)
        })}
      </div>
    )
  }
}

export type ErrorMsgLine = {
  type: 'line'
  text: string
}
export type ErrorMsgTable = {
  type: 'table'
  text: string[][]
}
export type ErrorMsg = ErrorMsgLine | ErrorMsgTable

type MainProps = {
  top: number
  left: number
  plotWidth: number
  plotHeight: number
  errMsg: ErrorMsg[]
  fontSize: number
}
export default class ComponentXYerrMsg extends PureComponent<MainProps> {

  render() {
    const { fontSize, plotWidth, plotHeight, errMsg, top, left } = this.props
    //console.log( 'call to ComponentXYerrMsg', fontSize )

    // How many lines of text total?
    // And what is worse case text length(s)?
    // Currently I assume there is either one table or no table in this errMsg structure. (only one columnWidths array.)
    // But could be extended to two or more tables if really required.
    var lineCounter = 0
    var worseCaseLineWidth = 0
    var worseCaseTableWidth = 0
    var colWidths = Array<number>()
    errMsg.forEach((thisObj) => {
      if (thisObj.type === 'line') {
        worseCaseLineWidth = Math.max(worseCaseLineWidth, measureText(thisObj.text, `${fontSize}px`))
        lineCounter++
      } else {
        // case of table
        var text = thisObj.text
        var numLines = text.length
        var numCols = text[0].length
        for (let i = 0; i < numLines; i++) {
          invariant(text[i].length === numCols, 'errMessage table rows MUST be same numCols')
        }
        lineCounter += numLines
        colWidths = Array(numCols).fill(0)  // Array of length = numColumns
        for (let lineIndex = 0; lineIndex < numLines; lineIndex++) {
          for (let colIndex = 0; colIndex < numCols; colIndex++) {
            colWidths[colIndex] = Math.max(colWidths[colIndex], measureText(text[lineIndex][colIndex], `${fontSize}px`))
          }
        }

        var thisTableWidth = colWidths.reduce((a, b) => a + b, 0) + (numCols - 1) * MARGIN_RIGHT_AS_FRACTION_OF_FONTSIZE * fontSize
        worseCaseTableWidth = Math.max(worseCaseTableWidth, thisTableWidth)
      }
    })

    // Text will sit inside a centered, empty rectangle (with a background color to hide the plot grid and potential axes).
    // Retangle will be over-sized from the required msg width and height by some constant border width.
    // If there is insufficient room in either the plottable area height or width, then we reduce
    // the fontSize as necessary.
    var containerWidth = Math.max(worseCaseTableWidth, worseCaseLineWidth) + MESSAGE_CONTAINER_BLANK_BORDER_WIDTH * fontSize
    var containerHeight = lineCounter * (fontSize) * (1 + MARGIN_BOTTOM_AS_FRACTION_OF_FONTSIZE) + MESSAGE_CONTAINER_BLANK_BORDER_WIDTH * fontSize
    var availableWidth = plotWidth - fontSize * MESSAGE_CONTAINER_GRIDDED_BORDER_WIDTH
    var availableHeight = plotHeight - fontSize * MESSAGE_CONTAINER_GRIDDED_BORDER_WIDTH
    var proportionalOversizeInEitherDirection = Math.max(containerWidth / availableWidth, containerHeight / availableHeight)
    if (proportionalOversizeInEitherDirection > 1) {
      var scaledFontSize = fontSize / proportionalOversizeInEitherDirection
      containerWidth = containerWidth / proportionalOversizeInEitherDirection
      containerHeight = containerHeight / proportionalOversizeInEitherDirection
      colWidths = colWidths.map((x) => x / proportionalOversizeInEitherDirection)
      worseCaseLineWidth = worseCaseLineWidth / proportionalOversizeInEitherDirection
    } else {
      scaledFontSize = fontSize
    }



    return (
      <div className={'errMsgContainer'}
        style={{
          position: 'absolute',
          top: top + (plotHeight - containerHeight) / 2,
          left: left + (plotWidth - containerWidth) / 2,
          width: containerWidth,
          height: containerHeight,
          display: 'flex', flexFlow: 'column nowrap', alignItems: 'center', justifyContent: 'center',
          background: DEBUG ? 'yellow' : plotLayoutConsts.backgroundColor,
        }}
      >
        {errMsg.map((thisObj, thisIndex) => {
          if (thisObj.type === 'table') {
            return (<ErrTable colWidths={colWidths} text={thisObj.text} fontSize={scaledFontSize} key={thisIndex} />)
          } else {
            return (<ErrText width={worseCaseLineWidth} text={thisObj.text} fontSize={scaledFontSize} marginRight={0} key={thisIndex} />)
          }
        })}
      </div>
    )
  }
}
