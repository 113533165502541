import type { ChangeEvent } from 'react'
import type { Column, LightweightMod, OptionalHTMLDivElement, StringOrderDirection, StringOrderTypes } from '../types'
import type { BasisPath, PlotXyComputedData } from '../computedDataPlotXY/xy_plotTypes'
import type { RootState } from '../redux/store'
import type { FormatRule }  from '../sharedFunctions/numberFormat'
import type { ScryNumber } from '../sharedFunctions/parseScryInputStringTypes'

import invariant from 'invariant'
import {Component, Fragment}   from 'react'
import {connect}                from 'react-redux'
import constants                from '../sharedComponents/constants'
import {isScryNumber}           from '../sharedFunctions/isTypeNumber'
import reactDispatch            from '../sharedComponents/reactDispatch'
import {plotLayoutConsts}       from '../viewPlotXY/plotLayoutConsts'
import {numberFormat, getFormattingObj}  from '../sharedFunctions/numberFormat'
import {STYLE_PALETTE_TEXT_INPUT_STYLES} from '../sharedComponents/constants'
import {rStateXY_Plot}          from '../viewPlotXY/xy_responsiveState'
import {cleanScryInputText2}    from '../sharedFunctions/cleanScryInputText'

import StyleEnumSlider          from '../sharedComponents/StyleEnumSlider'
import StyleLinearSlider        from '../sharedComponents/StyleLinearSlider'
import StyleSelectBox           from '../sharedComponents/StyleSelectBox'
import AxisTransposeControl     from './AxisTransposeControl'
import EditAxisStringOrder      from './EditAxisStringOrder'
import {BinarySelectionOption}  from './EditBinarySelectionOption'


const DEBUG = false
const MAIN_TITLE_INDENT = 85
const MAIN_TITLE_FONTSIZE = 16
const MAIN_TITLE_WIDTH0 = 66   // Width to reserve for the longest axisName which is 'Stacked'
const ATTRIBUTES_INDENT = 15

const TOTAL_WIDTH = 275
const AXIS_TITLE_TEXT_WIDTH = 40   // Sums to 260
const AXIS_TITLE_INPUT_WIDTH = TOTAL_WIDTH - AXIS_TITLE_TEXT_WIDTH

const AXIS_SUBTITLE_TEXT_WIDTH = 120  // Sums to 260
const AXIS_SUBTITLE_INPUT_WIDTH = TOTAL_WIDTH - AXIS_SUBTITLE_TEXT_WIDTH

const INPUTS_FONTSIZE = 12
//const SCALE_CONTROLS_WIDTH0 = 120
//const SCALE_CONTROLS_WIDTH1 = 150
//const STRING_COLKEY_SELECT_WIDTH = 142
////const DESCENDING_ASCENDING_TEXT_WIDTH = 87
//const DESCENDING_ASCENDING_FREQ_WIDTH = 220   // Longer label for distribution plots. (Where we don't show a colKey selector.)
const FONT_SIZE = 14
const ROW_HEIGHT = 20
const ROW_PADDING_TOP = (ROW_HEIGHT - FONT_SIZE)/2 - 1
const HEIGHT_GAP_BETWEEN_SUBGROUPS = 10
const TOP_SPACER = 12
const BOTTOM_SPACER = 8

export const orderOfAxisNameChildren: string[] = ['Top','Bottom', 'Left','Right']
export const OPACITY_DISABLED_LABELANGLE_SLIDER:number = 0


type OwnProps = {
  plotXyComputedData: PlotXyComputedData,
  basisPath: BasisPath,   // 'basisA', 'basisB', 'basisC'
}

type StateProps = {
  axisTitle: string,
  axisSubTitle: string,
  axisPath : string,
  axisName : string,
  tickFormatRule: FormatRule,
  maxStringIndex: number,
  labelAngle: number,

  isMaxDomainAuto: boolean,
  isMinDomainAuto: boolean,
  usersMaxDomain: string,
  usersMinDomain: string,

  columns : Column[],
  colOrder: number[],

  renderedLayersArr : string[],
  isLogarithmic               : boolean,
  isHisto                     : boolean,
  isPercentile                : boolean,
  isPercentileNormalProb      : boolean, 
  isHistogramPercent          : boolean, 
  isNumberDataType            : boolean,
  isStringDataType            : boolean,

  doesRangeFilteredIncludeZero: boolean,
  fontScaleAxisNames: number,
  fontScaleTickValues: number,
  plotColDataType: string,

  stringOrder: StringOrderTypes,
  stringOrderDirection: StringOrderDirection,
}
type Props = OwnProps & StateProps

type LocalState = {
  lastPlotid: string,
  lastBasisPath: string,
  currentMinLimitInputBox : string,   // For example:   '', '2', '2:00'
  currentMaxLimitInputBox : string,
  currentMinLimitValue    : number,   // For example:   NaN, 2, 120
  currentMaxLimitValue    : number,
  isCurrentMinLimitValid  : boolean,
  isCurrentMaxLimitValid  : boolean
}

class EditAxisStyleRender extends Component<Props, LocalState> {

  constructor(props: Props) {
    super(props)
    this.state={
      lastPlotid              : '',
      lastBasisPath           : '',
      currentMinLimitInputBox : '',
      currentMaxLimitInputBox : '',
      currentMinLimitValue    : NaN,
      currentMaxLimitValue    : NaN,
      isCurrentMinLimitValid  : true,
      isCurrentMaxLimitValid  : true,
    }
  }

  static getDerivedStateFromProps(props: Props, state: LocalState) {

    var {usersMinDomain, usersMaxDomain, tickFormatRule} = props
    // THE MIN/MAX AXIS VALUES HAVE SPECIAL RULES FOR THIER INITIATION !!
    // We initialize the poor man's editor with a formatted version
    // of the currently saved state.
    // AFTER mounting, the poor man's editor will display whatever
    // valid or invalid garbage is edited by the user. Likely NOT formatted.
    // And the local state will reflect said valid or invalid garbage.
    // ONLY valid numbers will be saved to state!  (The handler functions below)
    // And we we subsequently need to read from the resource, we initialize
    // the poor man's editor with the formatted version of the state value.
    if ( props.plotXyComputedData.plotid !== state.lastPlotid || props.basisPath !== state.lastBasisPath ) {

      let formattingObj = getFormattingObj(
           tickFormatRule, {forceFullPrecision:true, allowsPrefixSuffix:false, useCommas:false} )
      // Safety catch:  Saved poor man's editor values should ALWAYS be valid numbers, enforced by what we save to state.
      // However, just in case, I'll replace any non-numbers with an empty string.
      const {numberType:numberTypeMin} = isScryNumber( usersMinDomain )
      const {numberType:numberTypeMax} = isScryNumber( usersMaxDomain )
      if ( numberTypeMin === 'DATA_ERR' ) { usersMinDomain = '' }
      if ( numberTypeMax === 'DATA_ERR' ) { usersMaxDomain = '' }
      const newState = {
        lastPlotid              : props.plotXyComputedData.plotid,
        lastBasisPath           : props.basisPath,
        currentMinLimitInputBox : (usersMinDomain==='') ? '' : numberFormat( usersMinDomain, formattingObj, 'noHtml' ),
        currentMaxLimitInputBox : (usersMaxDomain==='') ? '' : numberFormat( usersMaxDomain, formattingObj, 'noHtml' ),
        isCurrentMinLimitValid  : true,
        isCurrentMaxLimitValid  : true,
        currentMinLimitValue    : (usersMinDomain==='') ? NaN : Number( usersMinDomain ),
        currentMaxLimitValue    : (usersMaxDomain==='') ? NaN : Number( usersMaxDomain )
      }
      return (newState)
    }
    return { }
  }

  axisTitleChangeHandler = (e: ChangeEvent<HTMLInputElement>): void => {
    const {axisTitle, axisName, basisPath} = this.props
    const options = { maxCharCount: 60 }
    var {newValue, newSelectionStop} = cleanScryInputText2( e.target.value, e.target.selectionEnd ?? 0, options )
    if ( e.target.value.length !== newValue.length ) {
      e.target.value = newValue.slice()  // This line also resets selection start to useless value.
      e.target.selectionStart = newSelectionStop
      e.target.selectionEnd   = newSelectionStop
    }
    if (axisTitle !== newValue) {
      let mods = []
      mods.push({ newVal: newValue, path: `attributes.${basisPath}.axisTitle` })
      reactDispatch( mods, `${axisName} Axis Set Title` )
    }
  }

  axisSubTitleChangeHandler = (e: ChangeEvent<HTMLInputElement>): void => {
    const {axisSubTitle, axisName, basisPath} = this.props
    const options = { maxCharCount: 60 }
    var {newValue, newSelectionStop} = cleanScryInputText2( e.target.value, e.target.selectionEnd ?? 0, options )
    if ( e.target.value.length !== newValue.length ) {
      e.target.value = newValue.slice()  // This line also resets selection start to useless value.
      e.target.selectionStart = newSelectionStop
      e.target.selectionEnd   = newSelectionStop
    }
    // Empty is considered missing, represented by a null Subtitle.
    const modValue = newValue === '' ? null : newValue
    if (axisSubTitle !== newValue) {
      let mods = []
      mods.push({ newVal: modValue, path: `attributes.${basisPath}.axisSubTitle` })
      reactDispatch( mods, `${axisName} Axis Set SubTitle` )
    }
  }


  setMinAutoTrue = ():void => {
    const {isMinDomainAuto, axisName, basisPath} = this.props
    if (isMinDomainAuto !== true) {
      let mods = [ { newVal: true, path: `attributes.${basisPath}.isMinDomainAuto` } ]
      reactDispatch( mods, `${axisName} Axis Set MinLimit` )
    }
  }
  setMinAutoFalse = ():void => {
    const {isMinDomainAuto, axisName, basisPath} = this.props
    if (isMinDomainAuto !== false) {
      let mods = [ { newVal: false, path: `attributes.${basisPath}.isMinDomainAuto` } ]
      reactDispatch( mods, `Style - ${axisName} Axis Set MinLimit` )
    }
  }
  setMaxAutoTrue = ():void => {
    const {isMaxDomainAuto, axisName, basisPath} = this.props
    if (isMaxDomainAuto !== true) {
      let mods = [ { newVal: true, path: `attributes.${basisPath}.isMaxDomainAuto` } ]
      reactDispatch( mods, `${axisName} Axis Set MaxLimit` )
    }
  }
  setMaxAutoFalse = ():void => {
    const {isMaxDomainAuto, axisName, basisPath} = this.props
    if (isMaxDomainAuto !== false) {
      let mods = [ { newVal: false, path: `attributes.${basisPath}.isMaxDomainAuto` } ]
      reactDispatch( mods, `${axisName} Axis Set MaxLimit` )
    }
  }


  minUserValChangeHandler = ( e: ChangeEvent<HTMLInputElement>): void => {
    this.userValChangeHandler( 'min', e )
  }
  maxUserValChangeHandler = ( e: ChangeEvent<HTMLInputElement>): void => {
    this.userValChangeHandler( 'max', e )
  }
  userValChangeHandler = ( whichLimit: 'min' | 'max', e: ChangeEvent<HTMLInputElement>): void => {
    const {usersMinDomain, usersMaxDomain, axisName, basisPath, isNumberDataType} = this.props

    var {newValue, newSelectionStop} = cleanScryInputText2( e.target.value, e.target.selectionEnd ?? 0 )
    if ( e.target.value.length !== newValue.length ) {
      e.target.value = newValue.slice()  // This line also resets selection start to useless value.
      e.target.selectionStart = newSelectionStop
      e.target.selectionEnd   = newSelectionStop
    }
    //const enteredString = e.target.value
    const validCharacters = (isNumberDataType)
      ? newValue.match( /[+\-\d.eE ^,:*]/g )
      : newValue.match( /[\d]/g )
    const currentString = validCharacters ? validCharacters.join('') : ''
    var parseResult: ScryNumber = isScryNumber( currentString )
    // We define valid as an empty string, or a successfully parsed number
    var isValid  = (parseResult.numberType !== 'DATA_ERR')   // Found a number or ''
    // We define NaN as an empty strign or unsuccessfully parsed number
    var currentValue = (currentString === '' || !isValid ) ? NaN : Number(parseResult.internalFormat)

    /*
    Rule 1: Put current visible input in the local state.  These are controlled inputs, paired with the local state values.
    Rule 2: Local state value may or may NOT be a valid number.
    Rule 3: Local state can be '' (no text) in which case use the auto value.
    Rule 4: ONLY push valid numbers in the serverState.  Invalid inputs have no effect on the plot or serverState
    Rule 5: 'red' font for bad:  Not a legal number, OR min/max displayed values numerically identical (axis has no domain.)
    Rule 6: 'black' for a legal value (doesn't match the other limit.)
    */

    var mods: LightweightMod[] = []
    if ( whichLimit === 'min' ) {
      this.setState({ currentMinLimitInputBox:currentString, isCurrentMinLimitValid:isValid, currentMinLimitValue:currentValue })
      // Never allow both these mods.  Just potentially one OR the other.
      // The unwritten 3rd choice is the entry exist, but is Not valid number, in which case we don't push a invalid number to the state.
      if ( currentString === '' && usersMinDomain !== '' ) {
        mods = [{ newVal: '', path: `attributes.${basisPath}.usersMinDomain` }]
      } else if ( isValid && currentString !== usersMinDomain ) {
        mods = [{newVal: String(currentValue), path: `attributes.${basisPath}.usersMinDomain` }]
      }
      reactDispatch( mods, `Style - ${axisName}Axis Set ${whichLimit}Limit` )
    }

    else {   // whichLimit === 'max'
      this.setState({ currentMaxLimitInputBox:currentString, isCurrentMaxLimitValid:isValid, currentMaxLimitValue:currentValue })
      if ( currentString === '' && usersMaxDomain !== '' ) {
        mods = [{ newVal: '', path: `attributes.${basisPath}.usersMaxDomain` }]
      } else if ( isValid && currentString !== usersMaxDomain ) {
        mods = [{ newVal: String(currentValue), path: `attributes.${basisPath}.usersMaxDomain` }]
      }
      reactDispatch( mods, `Style - ${axisName}Axis Set ${whichLimit}Limit` )
    }
  }

  setIsLogarithmicTrue = () => {
    const {axisName, basisPath } = this.props
    const mods = [ { newVal: true, path: `attributes.${basisPath}.isLogarithmic` } ]
    reactDispatch( mods, `${axisName} Axis: Set Logarithmic` )
  }
  setIsLogarithmicFalse = () => {
    const {axisName, basisPath } = this.props
    const mods = [ { newVal: false, path: `attributes.${basisPath}.isLogarithmic` } ]
    reactDispatch( mods, `${axisName} Axis: Set Linear` )
  }
  setIsPercentileNormalProbTrue = () => {
    const {axisName, basisPath } = this.props
    const mods = [ { newVal: true, path: `attributes.${basisPath}.isPercentileNormalProb` } ]
    reactDispatch( mods, `${axisName} Axis: Set percentile axis normal probability` )
  }
  setIsPercentileNormalProbFalse = () => {
    const {axisName, basisPath } = this.props
    const mods = [ { newVal: false, path: `attributes.${basisPath}.isPercentileNormalProb` } ]
    reactDispatch( mods, `${axisName} Axis: Set percentile axis linear` )
  }
  setIsHistogramPercentTrue = () => {
    const {axisName, basisPath } = this.props
    const mods = [ { newVal: true, path: `attributes.${basisPath}.isHistogramPercent` } ]
    reactDispatch( mods, `${axisName} Axis: Set histogram to frequency percent` )
  }
  setIsHistogramPercentFalse = () => {
    const {axisName, basisPath } = this.props
    const mods = [ { newVal: false, path: `attributes.${basisPath}.isHistogramPercent` } ]
    reactDispatch( mods, `${axisName} Axis: Set histogram to frequency count` )
  }




  initAxisName = (element: OptionalHTMLDivElement): void => {
    rStateXY_Plot.axisName[this.props.basisPath] = element
  }
  initLabelAngle = (element: OptionalHTMLDivElement): void => {
    rStateXY_Plot.labelAngle[this.props.basisPath] = element
  }
  initAxisLimitMin = (element: OptionalHTMLDivElement): void => {
    rStateXY_Plot.minLimit[this.props.basisPath] = ( this.props.isStringDataType ) ? null : element
  }
  initAxisLimitMax = (element: OptionalHTMLDivElement): void => {
    rStateXY_Plot.maxLimit[this.props.basisPath] = ( this.props.isStringDataType ) ? null : element
  }


  render() {
    const {axisName,  basisPath, stringOrder, doesRangeFilteredIncludeZero, isHisto,  
      labelAngle, axisTitle, isMinDomainAuto, isMaxDomainAuto, axisSubTitle, isStringDataType,
      isLogarithmic, stringOrderDirection, isNumberDataType, maxStringIndex, isPercentile,
      isPercentileNormalProb, fontScaleAxisNames, fontScaleTickValues, plotXyComputedData} = this.props
    const {isHistogramPercent} = plotXyComputedData.basisB

    const  {currentMinLimitInputBox, currentMaxLimitInputBox, currentMinLimitValue, currentMaxLimitValue,
            isCurrentMinLimitValid, isCurrentMaxLimitValid } = this.state
    // This option available for all user defined axes, EXCEPT basisB/C string axis. */
    const renderLogarithmicOption = basisPath === 'basisA' || (basisPath === 'basisB' && !isPercentile)  
    const renderHistogramPercentOption  = (isHisto && basisPath === 'basisB')
    const renderPercentileNormalProbOption  = (isPercentile && basisPath === 'basisB')
    const renderTitleSubtitleEditors = !( (isHisto || isPercentile ) && basisPath === 'basisB' )
    const isNotHistoBasisB = !(isHisto && basisPath === 'basisB' )   // We are making an exception
                                            // allowing a log scale for basisB freq values.
/*
    console.log( 'asisName', axisName)
    console.log( 'isMaxDomainAuto', isMaxDomainAuto)
    console.log( 'isMinDomainAuto', isMinDomainAuto)
    console.log( 'usersMaxDomain', this.props.usersMaxDomain)
    console.log( 'usersMinDomain', this.props.usersMinDomain)

    console.log( 'Render EditAxisStyle' )
    console.log( '   axisName:', axisName )
    console.log( '   currentMinLimitInputBox:', currentMinLimitInputBox )
    console.log( '   currentMaxLimitInputBox:', currentMaxLimitInputBox )
    console.log( '   currentMinLimitValue:', currentMinLimitValue )
    console.log( '   currentMaxLimitValue:', currentMaxLimitValue )
    console.log( '   isCurrentMinLimitValid:', isCurrentMinLimitValid )
    console.log( '   isCurrentMaxLimitValid:', isCurrentMaxLimitValid )
    console.log( '   state:', this.state )
*/
    // encode bad user limits inputs with a 'red' font.
    var minLimitFontColor2 = 'black', maxLimitFontColor2 = 'black'
    var menuDataType = 'string'
    if ( isStringDataType ) {
      if ( Number(currentMinLimitInputBox) < 1 || Number(currentMinLimitInputBox) > maxStringIndex || !isCurrentMinLimitValid )  {
        minLimitFontColor2 = 'red'
      } else {
        minLimitFontColor2 = 'black'
      }
      if ( Number(currentMaxLimitInputBox) < 1 || Number(currentMaxLimitInputBox) > maxStringIndex || !isCurrentMaxLimitValid )  {
        maxLimitFontColor2 = 'red'
      } else {
        maxLimitFontColor2 = 'black'
      }
    }

    if ( isNumberDataType ) {
      if ( !isCurrentMinLimitValid ) { minLimitFontColor2 = 'red' }
      if ( !isCurrentMaxLimitValid ) { maxLimitFontColor2 = 'red' }
      // Case of both inputs contain the same value (a axis domain of length zero)
      if ( !isNaN(currentMinLimitValue) && currentMaxLimitValue === currentMinLimitValue ) {
        minLimitFontColor2 = 'red'
        maxLimitFontColor2 = 'red'
      }
      menuDataType = 'number'
    }
    const showAngleOfTickLabels : boolean = ( axisName === 'Top' || axisName === 'Bottom')

    return (
      <div className={'rc_AxisStyleControl'}
        style={{
          //position: 'static',
          flex:'0 0 auto',
          width:'100%',
          display: 'flex',
          flexFlow: 'column nowrap',
          marginTop: TOP_SPACER,
          marginBottom: BOTTOM_SPACER,
          marginLeft: ATTRIBUTES_INDENT,
          marginRight: ATTRIBUTES_INDENT,
        }}>


        {/* Bold Location Title :   'Left Axis' or 'Bottom Axis'*/}
        <div className={'TitleRow'}
          style = {{
          alignItems: 'center',
          flex: '0 0 auto',
          display: 'flex',
          flexFlow: 'row nowrap',
          fontSize: MAIN_TITLE_FONTSIZE,
          fontWeight: 'bold',
          height: MAIN_TITLE_FONTSIZE +4,
          paddingTop: ROW_PADDING_TOP,   // (height-fontsize)/2
          position: 'relative',
          marginLeft: MAIN_TITLE_INDENT,
          background: DEBUG? 'pink':'none',
        }}>

          {/* we retain the domNode of the parent div, from which we can access/manipulate the 4 children divs */}
          <div className={'AxisNameContainer'}
            ref={ this.initAxisName }
            style={{flex:'0 0 auto', width:MAIN_TITLE_WIDTH0}}>

                {/* This div contains all 4 possible axisNames.  Overlaid.  Only one has opacity = 1.
                    Other three have opacity = 0.  On transpose or mirror, the opacities vary responsively.
                    Objective: 'Show' we are simply swapping the axis Names to perform the transformation.
                    Convienent to know the children's order when we responsively change opacities.
                    Hence the array 'orderOfAxisNameChildren' which is exported. */}
               { orderOfAxisNameChildren.map( thisChild => {
                 return (
                   <div className={'TopClass'}
                     key={thisChild}
                     style={{
                       opacity: (axisName===thisChild) ? 1:0,
                       position:'absolute',
                       left:0, top:0,
                       paddingTop: ROW_PADDING_TOP,
                       width:MAIN_TITLE_WIDTH0,
                     }}
                   >{thisChild}</div>
               )})}

          </div>
          <div className={'AxisNamePart2'} style={{flex:'0 0 auto', width: 40 }}>Axis:</div>
        </div>


        {/* User's Axis Title Input Control */}
        {/* Skip this for => distribution plot && (basisB or C) */}
{ renderTitleSubtitleEditors &&
        <Fragment>
        <div>
            <div className={'spacer'} style={{flex: '0 0 auto', height:HEIGHT_GAP_BETWEEN_SUBGROUPS/2}}/>
            <div style = {{
              alignItems: 'center',
              display: 'flex',
              flexFlow: 'row nowrap',
            }} >
              <div style={{
                  flex:'0 0 auto',
                  width: AXIS_TITLE_TEXT_WIDTH, height: ROW_HEIGHT,
                  paddingTop: ROW_PADDING_TOP,
                  fontSize: FONT_SIZE,
                }}
              >{`Title:`}</div>

              <input
                id={`${axisName}Title`}
                onChange={this.axisTitleChangeHandler}
                style={{flex: '0 0 auto',
                  fontSize: INPUTS_FONTSIZE,
                  width: AXIS_TITLE_INPUT_WIDTH, height:ROW_HEIGHT,
                  ...STYLE_PALETTE_TEXT_INPUT_STYLES,
                }}
                value={axisTitle}
                autoComplete='off'
                spellCheck='false'
              />
            </div>
        </div>

        {/* User's Axis Sub-Title Input Control */}
        <div>
            <div style = {{
              marginTop: 2,
              alignItems: 'center',
              display: 'flex',
              flexFlow: 'row nowrap',
            }} >
              <div style={{
                  flex:'0 0 auto',
                  width: AXIS_SUBTITLE_TEXT_WIDTH, height: ROW_HEIGHT,
                  paddingTop: ROW_PADDING_TOP,
                  fontSize: FONT_SIZE,
                }}
              >{`Subtitle (optional):`}</div>

              <input
                id={`${axisName}Title`}
                onChange={this.axisSubTitleChangeHandler}
                style={{flex: '0 0 auto',
                  fontSize: INPUTS_FONTSIZE,
                  width: AXIS_SUBTITLE_INPUT_WIDTH, height:ROW_HEIGHT,
                  ...STYLE_PALETTE_TEXT_INPUT_STYLES,
                }}
                value={axisSubTitle ? axisSubTitle : ''}
                autoComplete='off'
                spellCheck='false'
              />
            </div>
        </div>
        </Fragment>
}

        <div className={'vertSpacer'} style={{width:'100%', height: 10}}/>

{renderLogarithmicOption &&
        <BinarySelectionOption 
            className={'linear/logarithmic selection control'}
            label0={'Linear'}
            label1={'Logarithmic'}
            currentSelection={ isLogarithmic ? 1 : 0 }
            onSelect0={ this.setIsLogarithmicFalse}
            onSelect1={ this.setIsLogarithmicTrue }
            optionalMsg={ doesRangeFilteredIncludeZero && isLogarithmic && isNotHistoBasisB 
                    ? `Filtered data range includes 'zero'. Forcing 'Linear' axis.`
                    : '' }
            totalWidth={TOTAL_WIDTH}
            msgWidth={140}
          />
  }
  {renderHistogramPercentOption &&  
        <BinarySelectionOption 
            className={'histo count/percent selection control'}
            label0={'Frequency Counts'}
            label1={'Frequency Percent'}
            currentSelection={ isHistogramPercent ? 1 : 0 }
            onSelect0={ this.setIsHistogramPercentFalse}
            onSelect1={ this.setIsHistogramPercentTrue }
            optionalMsg={ '' }
            totalWidth={TOTAL_WIDTH}
            msgWidth={0}
          />
  }
  {renderPercentileNormalProbOption &&  
        <BinarySelectionOption 
            className={'linear/normProb selection control'}
            label0={'Linear Percentiles'}
            label1={'Normal Probability Scale'}
            currentSelection={ isPercentileNormalProb ? 1 : 0 }
            onSelect0={ this.setIsPercentileNormalProbFalse}
            onSelect1={ this.setIsPercentileNormalProbTrue }
            optionalMsg={ ''}
            totalWidth={TOTAL_WIDTH}
            msgWidth={0}
          />
  }



        {/* String Axis Controls */}
{isStringDataType && 
      <div className={'StringOrderComponentContainer'}
          style={{
            width:TOTAL_WIDTH,
            marginTop: 0,
            fontSize: FONT_SIZE,
            position:'relative', left:0, top:0,
            //background: 'yellow'
          }}>

          <EditAxisStringOrder
            stringOrder={stringOrder}
            stringOrderDirection={stringOrderDirection}
            basisName={plotXyComputedData[basisPath].basisName}
            axisName={plotXyComputedData[basisPath].axisName}
            internalDataTypeInput={menuDataType}
            rowHeight={ROW_HEIGHT}
            width={TOTAL_WIDTH}
          />

          <div className={'spacer'} style={{flex: '0 0 auto', height:HEIGHT_GAP_BETWEEN_SUBGROUPS}}/>

      </div>
}


      {/* Numerical Min and Max Range Controls */}
{ isNumberDataType && !(isHisto && basisPath === 'basisB') &&
        <div className={'LimitsContainerForNumbers'}>
            { axisLimitRow( 'min', axisName, basisPath, isStringDataType, isMinDomainAuto,
              currentMinLimitInputBox, minLimitFontColor2, this.setMinAutoTrue, this.setMinAutoFalse,
              this.minUserValChangeHandler, this.initAxisLimitMin) }
            { axisLimitRow( 'max', axisName, basisPath, isStringDataType, isMaxDomainAuto,
              currentMaxLimitInputBox, maxLimitFontColor2, this.setMaxAutoTrue, this.setMaxAutoFalse,
              this.maxUserValChangeHandler, this.initAxisLimitMax) }
              <div className={'spacer'} style={{flex: '0 0 auto', height:HEIGHT_GAP_BETWEEN_SUBGROUPS}}/>
        </div>
}

      {/* String Axis Min and Max Range Controls */}
{ isStringDataType &&
        <div className={'LimitsContainerForStrings'}>
            <div className={'LimitsControlsLabel'}
              style = {{
                fontSize:FONT_SIZE,
                height: ROW_HEIGHT,
                width: '100%',
                paddingTop: (ROW_HEIGHT - FONT_SIZE)/2,
              }}
            >
              Axis Range (1 to {maxStringIndex} ):
            </div>
            { axisLimitRow( 'min', axisName, basisPath, isStringDataType, isMinDomainAuto,
              currentMinLimitInputBox, minLimitFontColor2, this.setMinAutoTrue, this.setMinAutoFalse,
              this.minUserValChangeHandler, this.initAxisLimitMin) }
            { axisLimitRow( 'max', axisName, basisPath, isStringDataType, isMaxDomainAuto,
              currentMaxLimitInputBox, maxLimitFontColor2, this.setMaxAutoTrue, this.setMaxAutoFalse,
              this.maxUserValChangeHandler, this.initAxisLimitMax) }
            <div className={'spacer'} style={{flex: '0 0 auto', height:HEIGHT_GAP_BETWEEN_SUBGROUPS}}/>
        </div>
}
        

        <StyleLinearSlider
          indent={0}
          preSpacer={0}
          styleName={"Axis Title Size"}
          modPath={'attributes.fontScaleAxisNames'}
          currentValue={fontScaleAxisNames}
          valMin={plotLayoutConsts.fontSizeMinFactor}
          valMax={plotLayoutConsts.fontSizeMaxFactor}
          numSteps={60}
          displayFixed={2}
          layoutLines={1}
          marginRight={0}
        />

        <StyleLinearSlider
          indent={0}
          preSpacer={-4}
          styleName={"Tick Value Size"}
          modPath={`attributes.${basisPath}.fontScaleTickValues`}
          currentValue={fontScaleTickValues}
          valMin={plotLayoutConsts.tickFontSizeMinFactor}
          valMax={plotLayoutConsts.tickFontSizeMaxFactor}
          numSteps={40}
          displayFixed={2}
          layoutLines={1}
          marginRight={0}
        />

        {/* Angle of Tick Labels */}

        <div className={'dynamicOpacityContainer'}
          ref={ this.initLabelAngle }
          style={{ opacity      : showAngleOfTickLabels ? 1 : OPACITY_DISABLED_LABELANGLE_SLIDER,
                   pointerEvents: showAngleOfTickLabels ? 'auto':'none'
                }}
        >
            <StyleEnumSlider
              indent={0}
              preSpacer={-4}
              enumLabels={plotLayoutConsts.xTickAngleLabels}
              enumValues={plotLayoutConsts.xTickAngleValues}
              modPath={`attributes.${basisPath}.labelAngle`}
              currentValue={labelAngle}
              styleName='Tick Value Angle'
              layoutLines={1}
              marginRight={0}
            />
        </div>

        <AxisTransposeControl
          isTransposed={plotXyComputedData.isTransposed}
          isMirrored={plotXyComputedData.isMirrored} />

      </div>
    )
  }
}


const mapState = (state: RootState, ownProps: OwnProps): StateProps => {
  const {basisPath, plotXyComputedData } = ownProps
  //const plot      = get(state, ['api', 'resources', 'plots', plotid], null)
  const tablelook = state.api.resources.tablelooks[plotXyComputedData.tablelookid] ?? null
  const table     = state.api.resources.tables[plotXyComputedData.tableid] ?? null

  invariant(table, 'EditAxisStyle mapState with no table resource')
  const {columns} = table.attributes
  invariant(tablelook, 'EditAxisStyle mapState with no table resource')
  const {colOrder} = tablelook.attributes

  const { isPercentile, isHisto, fontScaleAxisNames, plotColDataType,
          renderedLayersArr } = plotXyComputedData
  const {axisTitle, labelAngle, isLogarithmic, stringOrder, stringOrderDirection,
      isMaxDomainAuto, isMinDomainAuto, usersMaxDomain, usersMinDomain, isPercentileNormalProb, 
      doesRangeFilteredIncludeZero, axisPath, axisName, internalDataType, tickFormatRule,
      stringTextToIndex_sortedKeys, axisSubTitle, fontScaleTickValues, isHistogramPercent} = plotXyComputedData[basisPath]
/*
  var   isOpposingAxisStringDataType = false  // false
  if ( plotColDataType === '2Col' ) {
    let thisBasisPath = plotXyComputedData[axisPath].basisPath
    // B and C map to A;  A maps to B  (B and C will always be the same internalDataType)
    let opposingBasisPath = (thisBasisPath === 'basisA') ? 'basisB' : 'basisA'
    let opposingBasisDataType = plotXyComputedData[opposingBasisPath].internalDataType
    isOpposingAxisStringDataType = ( opposingBasisDataType === 'string' )
  }
  */
  const isStringDataType = (internalDataType === 'string')

  return {
    axisTitle,
    axisSubTitle,
    axisPath,
    axisName,
    tickFormatRule,
    maxStringIndex: stringTextToIndex_sortedKeys ? stringTextToIndex_sortedKeys.length : 0,
    labelAngle,

    isMaxDomainAuto,
    isMinDomainAuto,
    usersMaxDomain,
    usersMinDomain,

    columns,
    colOrder,

    renderedLayersArr, 
    isLogarithmic,
    isHisto,
    isPercentile, 
    isPercentileNormalProb, 
    isHistogramPercent, 
    isNumberDataType: internalDataType==='number',
    isStringDataType,

    doesRangeFilteredIncludeZero,
    fontScaleAxisNames,
    fontScaleTickValues,
    plotColDataType,

    stringOrder,
    stringOrderDirection,
  }
}

const EditAxisStyle = connect(mapState, null)(EditAxisStyleRender)
export default EditAxisStyle



const LIMIT_PRESPACER = 0

const LIMIT_TEXT_WIDTH0 = 92
const LIMIT_AUTO_WIDTH  = 40
const LIMIT_INPUT_WIDTH = 78

export const orderOfMaxLimitChildren: string[] = ['Top', 'Right']
export const orderOfMinLimitChildren: string[] = ['Bottom','Left']

const axisLimitRow = (
  minOrMax: string,
  axisName: string,
  basisPath: string,
  isStringDataType: boolean,
  isAuto : boolean,
  userVal: string,
  fontColor: string,
  setMinAutoTrue: ()=>void,
  setMinAutoFalse: ()=>void,
  changeHandler: ( e: ChangeEvent<HTMLInputElement>)=>void,
  initAxisLimit: (element: OptionalHTMLDivElement)=>void,
) => {

  var limitLabels
  var isHorizontalAxis  = ( axisName === 'Top' || axisName === 'Bottom')  // This determines the opactity
  if ( isStringDataType ) {
    limitLabels = (minOrMax === 'min') ? ['First String', 'First String'] : ['Last String', 'Last String']
  } else {  // internalDataType === 'number'
    limitLabels = (minOrMax === 'min') ? orderOfMinLimitChildren.slice() : orderOfMaxLimitChildren.slice()
    limitLabels[0] += ' Limit'
    limitLabels[1] += ' Limit'
  }

  const leftPlacement = constants.FLOATING_PALETTE_BLUE_OUTLINE_WIDTH +
                        constants.FLOATING_PALETTE_BLACK_OUTLINE_WIDTH +
                        ATTRIBUTES_INDENT

  return(
    <div className={axisName}
      style = {{
      alignItems: 'center',
      display: 'flex',
      flexFlow: 'row nowrap',
      marginTop: LIMIT_PRESPACER,
      }}
    >
        <div className={'limitLabelTextContainer'}
          ref={ initAxisLimit }
          style={{
              flex:'0 0 auto',
              width: LIMIT_TEXT_WIDTH0, height: ROW_HEIGHT,
              paddingTop: ROW_PADDING_TOP, fontSize: FONT_SIZE,
              //background: 'pink',
          }}>

              {/* Two overlaid text label boxes.  One with opacity=0 and the other with opacity=1.
                  These boxes with swap opacity during a responsive isTranspose operation.
                  Either the two possible min labels are swapped:  'Bottom Lim' and 'Left Limit'
                  Or the two possible max labels are swapped: 'Top Limit' and 'Right Limit' */}
              <div style={{ position:'absolute', left:leftPlacement, right:0, width: LIMIT_TEXT_WIDTH0,
                opacity:isHorizontalAxis ? 0 : 1 }}>{limitLabels[0]}</div>
              <div style={{ position:'absolute', left:leftPlacement, right:0, width: LIMIT_TEXT_WIDTH0,
                opacity:isHorizontalAxis ? 1 : 0 }}>{limitLabels[1]}</div>

        </div>

        <StyleSelectBox
          indent={0}
          marginRight={ATTRIBUTES_INDENT}
          id={minOrMax+axisName}
          preSpacer={0}
          fontSize={FONT_SIZE}
          gapBoxText={2}
          textWidth={LIMIT_AUTO_WIDTH}
          rowHeight={ROW_HEIGHT}
          onClick={setMinAutoTrue}
          isSelected={ (isAuto) ? true : false }
          text={'Auto'} />

        <StyleSelectBox
          indent={0}
          marginRight={0}
          id={minOrMax+axisName}
          preSpacer={0}
          fontSize={INPUTS_FONTSIZE}
          gapBoxText={4}
          textWidth={LIMIT_INPUT_WIDTH}
          rowHeight={ROW_HEIGHT}
          onClick={ setMinAutoFalse }
          isSelected={ (isAuto) ? false : true }
          isInput={true}
          isInputFontColor={fontColor}
          onInputChangeHandler={changeHandler}
          text={userVal} />


    </div>

  )
}
