import {Component} from 'react'
import constants  from '../sharedComponents/constants'

class WhoIsDataTables extends Component {
  render() {
    return (

    <div className={'rc_WhoIsDataTables'}
              style={{
                position: 'absolute', left: 0, top: 0,
                paddingLeft:'40px', paddingRight: '40px',
                marginTop: '80px',
                fontFamily: constants.FONT_FAMILY_LOGO_FORMAT,
                fontSize: 20,
                maxWidth: 700,
              }}>

              <div style={{marginTop:0 }}>
                {'DataTables provides a standard, easy to use format for publishing, searching, and exploring data.'} <br/>
              </div>

              <div style={{marginTop:20 }}>
                {'Signed-in users can create tables and plots visible only to themselves. ' +
                'And later may choose to publish one or more of their tables as a service to the general public.'} <br/>
              </div>

              <div style={{marginTop:20 }}>
                {'Private group accounts (paid accounts) can publish tables with access limited to the specified group.'} <br/>
              </div>

              <div style={{marginTop:20 }}>
                  { 'DataTables.com is registered to and managed by:'} <br/>
                  { 'Scry Corporation'}  <br/>
                  { '2180 SW Burbank Place'} <br/>
                  { 'Portland OR, 97225-4919'} <br/>
              </div>

      </div>
    )
  }
}

export default WhoIsDataTables
