import {PureComponent} from 'react'
import reactDispatch          from '../sharedComponents/reactDispatch'
import type {StringOrderTypes, StringOrderDirection} from '../types'
import EditorMenuButton      from '../sharedComponents/EditorMenuButton'

const  indent = '\u00A0\u00A0'

const stringOrderMenu = {
  'alphabetical'   : {displayedName : 'Alphabetical',  menuText: ['Alphabetical']},
  '1stSeriesValue' : {displayedName : 'Value from 1st Series', 
              menuText: ['Value from 1st Series', 
                    indent+'If you wish to order by some other',
                    indent+'series, drag it to 1st position.'] },
  'allSeriesValues' : {displayedName : 'Value from all Series', 
              menuText: ['Total Value over all Series',
                    indent+'Sum of the values for each series.',
                    indent+'If your plot is stacked, this will',
                    indent+'be the same as total stack height.'] },
}

const direction_Menu_numbers = {
  'ascending' : {displayedName : 'Ascending',  menuText: ['Ascending',  indent+'Smallest to largest']},
  'descending': {displayedName : 'Descending', menuText: ['Descending', indent+'Largest to Smallest'] },
}

const direction_Menu_text = {
  'ascending' : {displayedName : 'Forward', menuText: ['Forward', indent+'A to Z, 1 to n']},
  'descending': {displayedName : 'Reverse', menuText: ['Reverse', indent+'n to 1, Z to A'] },
}

type OwnProps = {
  stringOrder: StringOrderTypes,
  stringOrderDirection: StringOrderDirection,
  basisName: 'A' | 'B' | 'C',
  axisName: string,
  rowHeight: number,
  width: number,
  internalDataTypeInput: string, 
}

export default class EditAxisStringOrder extends PureComponent<OwnProps> {

  handleStringOrder = ( newVal : string ):void => {
    const { basisName, axisName, stringOrder } = this.props
    if (stringOrder !== newVal) {
      let mods = [ { newVal, path: `attributes.basis${basisName}.stringOrder` } ]
      reactDispatch( mods, `${axisName} Axis, change plotted stringOrder` )
    }
  }

  handleStringOrderDirection = ( newVal : string ):void => {
    const { basisName, axisName, stringOrderDirection } = this.props
    if (stringOrderDirection !== newVal) {
      let mods = [ { newVal, path: `attributes.basis${basisName}.stringOrderDirection` } ]
      reactDispatch( mods, `${axisName} Axis, change plotted stringOrderDirection` )
    }
  }


  render () {
    const {stringOrder, stringOrderDirection, rowHeight, width, internalDataTypeInput} = this.props
    var currentStringOrder =  ( stringOrderMenu[stringOrder] ) ? stringOrder : 'alphabetical'
    const DIRECTION_WIDTH = 85
    const ORDER_WIDTH = width - 20 - DIRECTION_WIDTH - 20
    const thisDirection_Menu = (internalDataTypeInput === 'string') ? direction_Menu_text : direction_Menu_numbers


    return (
      <div>
          <div className={'StringAxisControlsLabel'}
              style = {{
                height: rowHeight,
                width: '100%',
                paddingTop: 2,
              }}>
            {'Order String labels by:'}
         </div>

         <EditorMenuButton
           menuItems={stringOrderMenu}
           onSelection={ this.handleStringOrder }
           selectedValue={currentStringOrder}
           height={rowHeight}
           widthDisplayText={ORDER_WIDTH}
           widthDisplayCaret={20}
           textAlign={'center'}
           menuWidth={220}
           menuLeft={0}
           menuTop={rowHeight}/>

         <EditorMenuButton
           menuItems={thisDirection_Menu}
           onSelection={ this.handleStringOrderDirection }
           selectedValue={stringOrderDirection}
           height={rowHeight}
           widthDisplayText={DIRECTION_WIDTH}
           widthDisplayCaret={20}
           textAlign={'center'}
           menuWidth={150}
           menuRight={0}
           menuTop={rowHeight}/>

      </div>


  )}
}
