import type { ChangeEvent, CSSProperties } from 'react'
import type { ColDataTypes, LightweightMod, PrecisionMode, 
              Table, Tablelook } from '../types'
import type { ErrorRows, TableComputedData } from '../computedDataTable/getDefaultTableComputedData'
import type { FormatRuleEndUser } from '../sharedFunctions/numberFormat'

import {PureComponent, Fragment} from 'react'
import { formattingOptions_by_colDataType, getDefaultColumnlook, getDefaultTableColumn } from '../types'
import reactDispatch from '../sharedComponents/reactDispatch'
import EditColHeaderInputs    from './EditColHeaderInputs'
import EditColFormula         from './EditColFormula'
import EditorMenuButton, { MenuItemValue }       from '../sharedComponents/EditorMenuButton'
import EditColMessageDisplay  from '../sharedComponents/EditColMessageDisplay'
import EditColRestore         from './EditColRestore'
import constants              from '../sharedComponents/constants'
import {deleteColHandler, exposeNewColHandler}  from '../viewTable/actionHideDeleteCol'
import SVGwrapper2            from '../SVGs/SVGwrapper2'
import EditTextWithLinks      from '../sharedComponents/EditTextWithLinks'
import SVGkey                 from '../SVGs/SVGkey'
import {HelpPanelColKey,
        HelpPanelUnits,
        HelpPanelFormulas,
        HelpPanelDescription,
        HelpPanelPrefix}      from './HelpPanels'
import {STYLE_EDITOR_RAISED_BUTTON}  from '../sharedComponents/constants'
import HorzTaperedDivider     from '../sharedComponents/HorzTaperedDivider'
import { errorCheckColOrder}  from '../computedDataTable/updateTableSupportFuncs'


const FORMULA_EDITOR_WIDTH = constants.COL_FORMULA_EDITOR_WIDTH
const MARGIN_LEFTRIGHT = 10
const TOTAL_EDITOR_WIDTH = FORMULA_EDITOR_WIDTH + 2*MARGIN_LEFTRIGHT
const DESCRIPTION_LABEL_WIDTH = 80
const DESCRIPTION_ERRMSG_WIDTH = 370
const subIndent = '\u00A0\u00A0\u00A0\u00A0'

const degMinSec = `Deg\u00B0${constants.thinSpaceChar}mm\u2032${constants.thinSpaceChar}ss\u2033`
const degMin    = `Deg\u00B0${constants.thinSpaceChar}mm\u2032`

export type DataTypeToMenuItemMap = {
  [key in ColDataTypes]: MenuItemValue
}

const dataTypeMenuItems: DataTypeToMenuItemMap = {   // in order of menu presentation
  hyperlink : {
    displayedName: 'Hyper-Link',
    menuText: ['Hyper-Links']
  },
  string : {
    displayedName: 'Text/String',
    menuText: ['Text/Strings']
  },
  number : {
    displayedName: 'Number',
    menuText: ['Numbers general']
  },
  numberSeconds: {
    displayedName: 'Number seconds',
    menuText: ['Number in seconds',
                  subIndent + 'Same as general numbers, ',
                  subIndent + 'plus format options for: ',
                  subIndent + 'mm:ss \u00A0and \u00A0hh:mm:ss']
  },
  /*
  numberDegrees: {
    displayedName: 'Number degrees',
    menuText: ['Number in degrees',
                  subIndent + 'Same as general numbers, ',
                  subIndent + 'plus format options for: ',
                  subIndent + `${degMin} \u00A0and \u00A0${degMinSec}` ]
  } */
}


// Don't change this order, unless also changing formatRulesByDataType
export type FormatRuleToMenuItemMap = {
  [key in FormatRuleEndUser]: MenuItemValue
}

const formatRuleMenuItems: FormatRuleToMenuItemMap = {
  defaultLink : {
    displayedName: 'defaultLink',
    menuText: ['defaultLink']
  },
  defaultString : {
    displayedName: 'defaultString',
    menuText: ['defaultString']
  },

  defaultEng : {
    displayedName: 'Engineering',
    menuText: ['Engineering',
               subIndent + 'Exponents:\u00A0\u00A0... , \u22126, \u22123, +6, +9, ...']
  },
  scientific : {
    displayedName: 'Scientific',
    menuText: ['Scientific',
               subIndent + 'Exponents:\u00A0\u00A0... , \u22124, \u22123, +6, +7, ...']
  },
  noExponent : {
    displayedName: 'No Exponent',
    menuText: ['No Exponent',
               subIndent + 'Normal decimal location.',
               subIndent + 'Potentially many digits.'
              ]
  },

  boolTrueFalse : {
    displayedName: 'True False',
    menuText: ['True False',
               subIndent + "If value is zero, display 'False'.",
               subIndent + "else display 'True'."
             ]
  },

  B60B60seconds : {
    displayedName: 'hh:mm:ss',
    menuText: ['hh:mm:ss']
  },
  B60seconds : {
    displayedName: 'mm:ss',
    menuText: ['mm:ss']
  },

  B60B60degrees : {
    displayedName: degMinSec,
    menuText: [degMinSec]
  },
  B60degrees : {
    displayedName: degMin,
    menuText: [degMin]
  },

  internal : {
    displayedName: 'Internal',
    menuText: ['Internal',
               subIndent + 'Internally saved value.'
             ]
  },
}


// A current formatRule may or may not be valid for a new dataType.
// Rules:
//   1) If the existing formatRule is still valid, keep it.
//   2) If the existing formatRule is not an available option, use the 1st formatRule
//      from the available options.
//   3) Put the 'default' formatRule for each colDataType as 1st in the formatRules array.

type PrecModeToMenuItemMap = {
  [key in PrecisionMode]: MenuItemValue
}

const precModeMenuItems: PrecModeToMenuItemMap = {
  'min' : {
    displayedName: '\u2009Significant Figures; no zeros appended',
    menuText: ['Significant Figures; no zeros appended']
  },
  'std' : {
    displayedName: '\u2009Significant Figures; append zeros as needed',
    menuText: ['Significant Figures; append zeros as needed']
  },
  'fixed' : {
    displayedName: '\u2009Digits to the right of decimal point.',
    menuText: ['Digits to the right of decimal point.']
  },
}

type FormatToPrecModeMap = {
  [key in FormatRuleEndUser]: PrecisionMode[]
}
const precModesByFormatRules: FormatToPrecModeMap = {   // In order of menu presentation
  B60B60seconds : ['fixed'],
  B60seconds    : ['fixed'],
  B60B60degrees : ['fixed'],
  B60degrees    : ['fixed'],
  defaultEng    : ['min', 'std', 'fixed'],
  scientific    : ['min', 'std', 'fixed'],
  noExponent    : ['min', 'std', 'fixed'],
  boolTrueFalse : [],
  internal      : [],
  defaultString : [],
  defaultLink   : [],
}


const colActionsMenuItems = {
  insertColumn : {
    displayedName: "More Column Actions\u00A0\u00A0(insert, delete, ... )",
    menuText: ["Insert a new column.",
            subIndent + 'Inserted columns are always defined by a',
            subIndent + "formula and dependent on current columns."]
  },
  cloneInsertColumn : {
    displayedName: "More Column Actions\u00A0\u00A0(insert, delete, ... )",
    menuText: ["Insert a clone of this column.",
            subIndent + 'Inserted columns are always defined by a',
            subIndent + "formula and dependent on current columns."]
  },
  deleteColumn : {
    displayedName: "More Column Actions\u00A0\u00A0(insert, delete, ... )",
    menuText: ["Delete this column"]
  },
  restoreColumn : {
    displayedName: "More Column Actions\u00A0\u00A0(insert, delete, ... )",
    menuText: ["Restore a prior deleted column",
            subIndent + 'A list of prior deleted columns and the option',
            subIndent + 'to restore or permanently delete these columns.' ]
  },
  exitActionMenu : {
    displayedName: "More Column Actions:\u00A0\u00A0insert, delete, ... ",
    menuText: ["Exit this menu"]
  },
}


// Layout constants used for labels and inputs.
// Except formula textArea.  Where fontsize is defined in the
// common constants file.  As formula fontsize must be shared
// across several modules.
const fontSize = 16
const rowHeight  = 22
// This object is a global object so I can pass it to a child Component.
// But don't want it to be a new object every time.
// So declaring it as global.
const labelStyle_RightAligned = {
  display: 'inline-block',
  overflow: 'hidden',
  verticalAlign:'top',
  marginRight: 5,
  paddingTop:  2,  // determined empirically ! will change with fontsize.
  textAlign: 'right',
  width: 64,
  height: rowHeight,
  whiteSpace: 'nowrap',
  //fontWeight: 'bold',
  //background: 'red',
}
const labelStyle_LeftAligned: CSSProperties = {
  ...labelStyle_RightAligned,
  textAlign: 'left',
  fontWeight: 'normal',
}

const textWithLinksLabelStyle_EditMode={
         ...labelStyle_LeftAligned,
         fontSize, paddingBottom: 2, marginLeft: 20,
         width: DESCRIPTION_LABEL_WIDTH}
const textWithLinksLabelStyle_ViewMode={
         ...labelStyle_LeftAligned,
         fontSize, paddingBottom: 2, marginLeft: 20,
         width: DESCRIPTION_LABEL_WIDTH}



type Props = {
  tablelook: Tablelook,
  table: Table,
  tabledataid: string,
  colKey: number,
  tableComputedData: TableComputedData,
  duplicateRowNames: ErrorRows,
  missingRowNames: ErrorRows,
}

type LocalState = {
  isHelpColKeyActive: boolean,
  isHelpPrefixActive: boolean,
  isHelpUnitsActive : boolean,
  isHelpFormulasActive: boolean,
  isHelpDescriptionActive: boolean,
  isColRestoreActive: boolean,
  isActiveTextWithLinks: boolean,
  isActiveEditColFormula: boolean,
  currentTextWithLinksErrMsg: string,
}

export default class EditColHeader extends PureComponent<Props, LocalState> {

  constructor(props: Props) {
    super(props)
    this.state={
      isHelpColKeyActive: false,
      isHelpPrefixActive: false,
      isHelpUnitsActive : false,
      isHelpFormulasActive: false,
      isHelpDescriptionActive: false,
      isColRestoreActive: false,
      isActiveTextWithLinks: false,
      isActiveEditColFormula: false,
      currentTextWithLinksErrMsg: '',
    }
  }

  passTextWithLinksActiveStateToParent = (activeState:boolean) => {
      if (activeState === true) {
        this.setState({isActiveTextWithLinks: true, isActiveEditColFormula: false})
      } else {
        this.setState({isActiveTextWithLinks: false})
      }
  }

  passEditColFormulaActiveStateToParent = (activeState:boolean) => {
      //console.log( 'EditColHeader: received isActive value from EditColFormula:', activeState )
      if (activeState === true) {
        this.setState({isActiveEditColFormula: true, isActiveTextWithLinks: false})
      } else {
        this.setState({isActiveEditColFormula: false})
      }
  }

  passTextWithLinksErrMsgToParent = ( strg:string ) => {
      //console.log( 'currentTextWithLinksErrMsg set to:', strg )
      this.setState({currentTextWithLinksErrMsg: strg})
  }

  pushedHelpWithUnitsButton = () => {
      this.setState({
        isHelpColKeyActive: false,
        isHelpPrefixActive: false,
        isHelpUnitsActive : !this.state.isHelpUnitsActive,
        isHelpFormulasActive: false,
        isHelpDescriptionActive: false,
      })
  }

  pushedHelpWithPrefixButton = () => {
      this.setState({
        isHelpColKeyActive: false,
        isHelpPrefixActive: !this.state.isHelpPrefixActive,
        isHelpUnitsActive : false,
        isHelpFormulasActive: false,
        isHelpDescriptionActive: false,
      })
  }

  pushedHelpWithKeyButton = () => {
      this.setState({
        isHelpColKeyActive: !this.state.isHelpColKeyActive,
        isHelpPrefixActive: false,
        isHelpUnitsActive : false,
        isHelpFormulasActive: false,
        isHelpDescriptionActive: false,
      })
  }

  pushedHelpWithFormulasButton = () => {
      this.setState({
        isHelpColKeyActive: false,
        isHelpPrefixActive: false,
        isHelpUnitsActive : false,
        isHelpFormulasActive: !this.state.isHelpFormulasActive,
        isHelpDescriptionActive: false,
      })
  }

  pushedHelpWithDescriptionButton = () => {
      this.setState({
        isHelpColKeyActive: false,
        isHelpPrefixActive: false,
        isHelpUnitsActive : false,
        isHelpFormulasActive: false,
        isHelpDescriptionActive: !this.state.isHelpDescriptionActive
      })
  }

  closeHelp = () :void => {
      console.log( 'call to close help')
      this.setState({
        isHelpColKeyActive: false,
        isHelpPrefixActive: false,
        isHelpUnitsActive : false,
        isHelpFormulasActive: false,
        isHelpDescriptionActive: false,
      })
  }

  handleActionMenuSelection = ( value:string ) => {
      switch( value ) {
        case 'deleteColumn':
          let shouldSetDeletionDate = true
          this.deleteColumn( this.props.colKey, shouldSetDeletionDate )
          break
        case 'insertColumn':
          this.insertColumn( this.props.colKey, 'new' )  // isClone argument set to false
          break
        case 'cloneInsertColumn':
          this.insertColumn( this.props.colKey, 'cloned' )   // isClone argument set to true
          break
        case 'restoreColumn':
          this.setState( {isColRestoreActive:true} )
          break
        case 'exitMenu' :
        default:
      }
  }

  handleExitColRestoreControl = ( )=>{
      this.setState( {isColRestoreActive:false} )
  }

  // type InsertActions = 'new'|'cloned'|'restored'
  insertColumn = ( selectedColKey: number, mode: 'new'|'cloned'|'restored' , restoredColKey:number = -1 ) => {
      // All three modes use the same insert column animation.
      // But the new column that will appear depends on the mode.
      // This function defines the state change mods that vary,
      // depending on where/how we define the newly inserted column.
      const {table, tablelook, tabledata, colOrder, widthObj:w,
          derivedColAttributesArray } = this.props.tableComputedData
      const {numLockedCols} = w
      const {columns} = table.attributes
      const selectedColumn = columns[selectedColKey]
      const lookColumn = tablelook.attributes.lookColumns[selectedColKey]
      const defaultNewColWidth = 120
      const postWidth_notGlobalScaled = ( mode === 'restored' ) ? derivedColAttributesArray[restoredColKey].constrainedScaledColWidth : defaultNewColWidth
      const newOrRestoredColKey = ( mode === 'restored' ) ? restoredColKey : columns.length
      const tableMods: LightweightMod[] = []
      const tableLookMods: LightweightMod[] = []
      const tableDataMods: LightweightMod[] = []

      const isDeletedArr_preInsert = derivedColAttributesArray.map( c => c.isDeleted )
      const colTitleArr_preInsert  = derivedColAttributesArray.map( c => c.colTitle )
      const isDeletedArr_postInsert= isDeletedArr_preInsert.slice()
      const colTitleArr_postInsert = colTitleArr_preInsert.slice()
      const indexOfSelectedColKey  = colOrder.indexOf( selectedColKey )
      const newColOrder = colOrder.slice()

      if ( mode === 'cloned' || mode === 'new' ) {
          const columnsCopy = columns.slice()
          const lookColumnsCopy = tablelook.attributes.lookColumns.slice()
          const dataColumnsCopy = tabledata.attributes.tableValues.slice()
          const newColTitle = ( mode === 'new' ) ? 'NEW COL' : 'CLONE: ' + selectedColumn.colTitle
          if ( mode === 'cloned' ) {
              let newCol = structuredClone( selectedColumn )
              let newLook = structuredClone( lookColumn )
              newCol.colTitle  = newColTitle
              newCol.isDepCol = true
              newCol.isKey = false
              newLook.hidden = 1000  // All inserted columns begin their life hidden; Then dynamically expose.
                                    // I set the hidden index very high to insure this is the first column to be 'un-hidden'.
              // if isDep, formula is a copy of current formula
              // else formula just copies prior column
              if (selectedColumn.isDepCol) {
                newCol.formula = structuredClone( selectedColumn.formula )
              } else {
                newCol.formula = [
                  `return ${constants.COL_KEY_CANONICAL_FORM}${selectedColKey}_`
                ]
              }
              columnsCopy.push(newCol)
              lookColumnsCopy.push(newLook)
          } else {  // MODE === 'new'  -- new default dependent column requiring a newColKey
              let newCol = getDefaultTableColumn({
                          formula: ['return pi'],
                          isDepCol: true,
                          colDataType: 'number',
                          colTitle: newColTitle
                        })
              let newLook = getDefaultColumnlook({
                          hidden: 1000,  // All inserted columns begin thier life hidden; Then dynamically expose.
                          width : 0,     // Not visible on first render; Dynamically grows to expandedColWidth
                          precisionFixed:0
                        })
              columnsCopy.push(newCol)
              lookColumnsCopy.push(newLook)
          }
          // Add the new or cloned column to all three table resources
          dataColumnsCopy.push(new Array(0))  // Empty array; New columns are ALWAYS dependent columns.
          tableMods.push({ newVal: columnsCopy,     path: `attributes.columns`, resId: table.id, resType: 'tables' })
          tableLookMods.push({ newVal: lookColumnsCopy, path: `attributes.lookColumns`, resId: tablelook.id, resType: 'tablelooks'  })
          tableDataMods.push({ newVal: dataColumnsCopy, path: `attributes.tableValues`, resId: tabledata.id, resType: 'tabledatas' })
          // new or cloned columns 'add a new column' to colOrder, isDeletedArr, colTitleArr
          newColOrder.splice( indexOfSelectedColKey+1, 0, newOrRestoredColKey)
          isDeletedArr_postInsert.push( false )
          colTitleArr_postInsert.push( newColTitle )
      }

      if ( mode === 'restored' ) {
          // Differs from new or cloned because we are NOT creating a new column
          // Flip the isDeleted flag for this colkey
          tableMods.push({ newVal: false, path: `attributes.columns[${newOrRestoredColKey}].isDeleted`, resId: table.id, resType: 'tables' })
          isDeletedArr_postInsert[newOrRestoredColKey] = false
          // And insert newly visible col into colOrder
          newColOrder.splice( indexOfSelectedColKey, 1, newOrRestoredColKey )
      }

      // Error check colOrder 
      errorCheckColOrder( isDeletedArr_postInsert, newColOrder, colTitleArr_postInsert )
      tableLookMods.push({newVal: newColOrder, path: 'attributes.colOrder'})
      // If selectedCol is locked, new col is locked, adding a new column to the lockedTable
      if ( indexOfSelectedColKey < numLockedCols ) {
          tableLookMods.push({newVal: numLockedCols + 1, path: 'attributes.numLockedCols'})
      }   

      let thisAction = `insert ${mode} column`
      let actionGroup = `insertCol${newOrRestoredColKey}`
      reactDispatch( tableMods, thisAction, actionGroup )  
      reactDispatch( tableLookMods, thisAction, actionGroup )  
      reactDispatch( tableDataMods, thisAction, actionGroup )  
      // The animation 'exposeNewColHandler' is called AFTER the reactDispatch/re-render.
      // Hence the column actually exist (and is rendered) prior to beginning the animation.
      // The animation is all for show!
      window.requestAnimationFrame( () => 
          exposeNewColHandler( newOrRestoredColKey, postWidth_notGlobalScaled, thisAction, actionGroup, table.id ) 
      )
  }

  /*
  nextDepColCounter = (columns: Array<Column>): number => {
      let counter = 0
      columns.forEach( column => {if(column.isDepCol) counter++ })
      return counter+1
  }


  handleDescriptionChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
    const {colKey, table} = this.props
    const newVal = e.target.value
    if (table.attributes.columns[colKey].description !== newVal) {
      let mods = [{ newVal: newVal, path: `attributes.columns[${colKey}].description` }]
      reactDispatch( mods, 'Edit column description', '', 'tables', table.id )
    }
  }
  */

  handleColDataTypeChange = (newSelection: string) => {
      const {colKey, table } = this.props
      const newColDataType = newSelection as ColDataTypes
      if (table.attributes.columns[colKey].colDataType !== newColDataType) {
        let mods: LightweightMod[] =[{ newVal: newColDataType, path: `attributes.columns[${colKey}].colDataType` }]
        reactDispatch( mods, 'Edit column data type', '', 'tables', table.id )
        // Automatically reset the formatRule to the default for this colDataType
        let defaultFormatRule = formattingOptions_by_colDataType[newColDataType][0]
        mods = [{ newVal: defaultFormatRule, path: `attributes.lookColumns[${colKey}].formatRule` }]
        reactDispatch( mods, 'Edit column data type' )
      }
  }

  handleFormatRuleChange = (newFormatRule: string) => {
      const {colKey, tablelook} = this.props
      if (tablelook.attributes.lookColumns[colKey].formatRule !== newFormatRule) {
        let mods = [{ newVal: newFormatRule, path: `attributes.lookColumns[${colKey}].formatRule` }]
        reactDispatch( mods, 'Edit column formatRule' )
      }
  }


  handlePrecisionChange = (e: ChangeEvent<HTMLInputElement>) => {
      const {colKey, tableComputedData} = this.props
      const derivedColAttributes = tableComputedData.derivedColAttributesArray[colKey]
      // We want the precMode that includes potential overrides by the formatting constraints.
      // NOT the precisionMode in the lookColumns state which will contain the last user set value.
      const {precMode, precisionMin, precisionFixed} = derivedColAttributes.formattingObj  // Will be 'fixed', 'std' or 'min'
      var newVal = Number(e.target.value)
      if ( precMode === 'fixed' ) { // legal value of 0 to 16
        newVal = Math.min(16, Math.max( 0, newVal ))
        if (newVal !== precisionFixed) {
          reactDispatch( [{ newVal, path:`attributes.lookColumns[${colKey}].precisionFixed`}], `Edit fixed formatting precision` )
        }
      }
      else {  // 'std' or 'min' mode;   legal value of 1 to 16
        newVal = Math.min(16, Math.max( 1, newVal ))
        if (newVal !== precisionMin) {
          reactDispatch( [{ newVal, path:`attributes.lookColumns[${colKey}].precisionMin`}], `Edit min/std formatting precision` )
        }
      }
  }

  handlePrecisionModeChange = (newPrecMode: string) => {
      const {colKey, tableComputedData} = this.props
      const {precMode} = tableComputedData.derivedColAttributesArray[colKey].formattingObj
      if ( precMode !== newPrecMode ) {
        reactDispatch([{ newVal:newPrecMode, path: `attributes.lookColumns[${colKey}].precisionMode` }], 'Edit formatting precisionMode' )
      }
  }

  toggleIsKey = (  ) => {
      let {colKey, table} = this.props
      let isKey = this.props.tableComputedData.derivedColAttributesArray[colKey].isKey
      let mods = [{ newVal: !isKey, path: `attributes.columns[${colKey}].isKey` }]
      reactDispatch( mods, 'Toggle is column Key', '', 'tables', table.id )
  }


  timeoutID = null

  deleteColumn = ( colKey: number, shouldSetDeletionDate: boolean) => {
      const tableMods: LightweightMod[] = []
      const tablelookMods: LightweightMod[] = []
      const date = String ( new Date() )
      const {tableComputedData} = this.props
      const {table, tablelook, colOrder, derivedColAttributesArray } = tableComputedData
      const {numLockedCols} = tableComputedData.widthObj
      const colIndex = colOrder.indexOf( colKey )
      const isLockedCol = colIndex < numLockedCols
      const newColOrder = colOrder.slice()
      newColOrder.splice( colIndex, 1 )  // Remove colKey from colOrder array.
      // Always error check colOrder before pushing to resource.
      // Once it is bad in the resource, pain in the butt to repair!!
      const nextIsDeletedArr = derivedColAttributesArray.map( c => c.isDeleted )
      nextIsDeletedArr[colKey] = true
      const colTitleArr  = derivedColAttributesArray.map( c => c.colTitle )
      errorCheckColOrder( nextIsDeletedArr, newColOrder, colTitleArr )
      tablelookMods.push({newVal: newColOrder, path: `attributes.colOrder`, resId: tablelook.id, resType: 'tablelooks'})
      if (isLockedCol) {
        tablelookMods.push({newVal: numLockedCols-1, path: `attributes.numLockedCols`, resId: tablelook.id, resType: 'tablelooks'})
      }
      if ( shouldSetDeletionDate ) {
        // True if this function was called from the 'delete column menu.'
        // False if this function was called from the 'restore column control.'

        // When the column restore control changes the isDeleted flag, we DO NOT reset the deletion date.
        // Nor do we close the Editor (the currently visible column restore control)

        // Why this works--  IFF a column appears in the column restore control, THEN isDeleted === true.
        // If column restore control does repeated restore/re-delete sequence,  then we want the original (current)
        // deletion date.  NOT a new date based on indecision while using the column restore tool.
        // And we don't want to close the editor on deletion. -- We don't need to because the deleted choice
        // will never be the current selection colHeader.

        // Hence, rules are: ONLY set a column's deletion date IF it is deleted using the deleteColumn menu item.
        //                   NEVER change the deletion date from inside the restore column control.
        //                   NEVER exit the Editor from inside the restore column control.
        tableMods.push({newVal: true, path: `attributes.columns[${colKey}].isDeleted`,   resId: table.id,     resType: 'tables'})
        tableMods.push({ newVal: date, path: `attributes.columns[${colKey}].deletedDate`, resId: table.id, resType: 'tables'})
        tablelookMods.push({ newVal:{name:'', colIndex:-1, rowIndex:-1}, 
                    path: 'attributes.minorState.selection', resId: tablelook.id,  resType: 'tablelooks'})
        tablelookMods.push({ newVal:false, path: 'attributes.minorState.isEditorOpen', resId: tablelook.id, resType: 'tablelooks'})
      }
      deleteColHandler( tableMods, tablelookMods, colKey, tableComputedData )   // This animation is done using the 'actionHideCol.js' module.
  }





  //textWithLinksErrMsg = ''
  //passTextWithLinksErrMsgToParent = ( strg:string ) => {
  //    this.textWithLinksErrMsg = strg
  //    console.log( 'textWithLinksErrMsg callback', strg )
  //}


  render() {

    const {colKey, tablelook, table, tableComputedData,
          duplicateRowNames, missingRowNames } = this.props
    const {canEdit} = tableComputedData
    const {badColNameErrorID, formatRule, formattingObj, parsedScryFormula, isMismatchedType,
          shouldForceUnits, forcedUnits, units:unForcedUnits, isKey} = tableComputedData.derivedColAttributesArray[colKey]
    const unitsValue = shouldForceUnits ? forcedUnits : unForcedUnits
    const {colDescription, isDepCol, colDataType, colTitle } = table.attributes.columns[colKey]
    const {precMode } = formattingObj
    const formatRuleOptionsForThisDataType: Partial<FormatRuleToMenuItemMap> = { }
    const formattingOptions = formattingOptions_by_colDataType[colDataType]
    for (const index in formattingOptions) {
      const thisFormatRule = formattingOptions[index]
      let menuItem = formatRuleMenuItems[thisFormatRule]
      if (index === '0') {
        // Add the text '(suggested)' to the first formatRule
        // If we're going to modify the text, first we need a deepCopy.
        // Else, any modification becomes permanent in the local definition.
        menuItem = structuredClone(menuItem)
        menuItem.menuText[0] += '\u00A0\u00A0\u00A0\u00A0\u00A0(suggested)'
      }
      formatRuleOptionsForThisDataType[thisFormatRule] = menuItem
    }
    let borderColor = constants.COLHEADER_INPUT_BORDER_COLOR

    const precModeOptionsForThisFormatRule: Partial<PrecModeToMenuItemMap> = { }
    precModesByFormatRules[formatRule].forEach( thisPrecMode => {
      precModeOptionsForThisFormatRule[thisPrecMode] = precModeMenuItems[thisPrecMode]
    })

    var { isHelpColKeyActive, isHelpUnitsActive, isHelpPrefixActive, isColRestoreActive,
          currentTextWithLinksErrMsg, isHelpDescriptionActive, isHelpFormulasActive,
          isActiveTextWithLinks, isActiveEditColFormula} = this.state

    // If I change columns, keep the last help active state.
    // Exception -- moving from a depCol to independent col should force close of formulasHelp.
    if (isHelpFormulasActive && !isDepCol) { this.closeHelp() }

    var isDataTypeNumber = colDataType.slice(0,6) === 'number'
    // isHelpFormulasActive = true
    // Hardwires a help panel open -- useful for designing the helpPanel.
    let isAnyHelpPanelVisible = (isHelpColKeyActive || isHelpUnitsActive ||
           isHelpPrefixActive || isHelpFormulasActive || isHelpDescriptionActive) && canEdit
    let isKeyUnitsOrPrefixHelpPanelVisible = (isHelpColKeyActive || isHelpUnitsActive || isHelpPrefixActive ) && canEdit
    // Name row is ONLY hidden when viewing formulas help panel in EditMode
    var isNameRowVisible  = !(canEdit && isHelpFormulasActive)
    // Units row is visible in edit Mode , except when viewing the formulas help panel.
    var isUnitsRowVisible = canEdit && !isHelpFormulasActive
    // FormatRow is hidden for all help panels OR if this column is not dataType Number.
    var isFormatRowVisible = !(isAnyHelpPanelVisible || !isDataTypeNumber)
    // Formulas only visible for dependent columns AND ( key, units, and prefix help panels NOT visible )
    var isFormulaVisible = isDepCol && !isKeyUnitsOrPrefixHelpPanelVisible && !isHelpDescriptionActive
    // Descriptions always visible, except any help panel except Description is active.
    var isDescriptionVisible = !isAnyHelpPanelVisible || isHelpDescriptionActive
    // MoreActions button always hippen by any help panel.
    var isMoreActionsButtonVisible = !isAnyHelpPanelVisible && canEdit


    // Different heights for the Description and Formulas, depending
    // on the which are displayed and which is active.
    const textWithLinksNominalEditHeight  = 100
    const editColFormulaNominalEditHeight = 100
    const editColFormulaWithHelpFormulasActive = 160
    const deltaHeight = 60
    var textWithLinksHeight  = textWithLinksNominalEditHeight   // assumption; both inActive
    var editColFormulaHeight = editColFormulaNominalEditHeight  // assumption; both inActive
    // If both textArea's are visible (textwithLinks and formula editors)
    // then tradeoff the heights so the active one gets most of the room.
    if ( isFormulaVisible && isActiveTextWithLinks ) {
      textWithLinksHeight  += deltaHeight
      editColFormulaHeight -= deltaHeight
    }
    if ( isFormulaVisible && isActiveEditColFormula ) {
      textWithLinksHeight  -= deltaHeight
      editColFormulaHeight += deltaHeight
    }
    if ( isHelpFormulasActive ) {
      editColFormulaHeight = editColFormulaWithHelpFormulasActive
    }

    /*
    Error Message Handling:
      3-sources:  Error in formula
                  Error in Description (TextWithLinks component)
                  Error in Header (dataType, colName, missing info, etc.)
      If formula is visible, formula component will display the header error
      (highest priority), otherwise the formula error message.  We need to
      pass in both errMessages as they both are generated external to the
      formula editing component.

      If formula is NOT visible, header error message is passed into the
      EditTextWithLinks component (the description).  Else, (when header
      error is displayed by the formula editor) pass in an empty string.
      Potential error messages for the TextWithLinks editor are generated inside
      the component.

      Purpose is to minimize the space and impact of error messages coming
      from various external or internal code.
      */


    const numDuplicateRowNames = Object.keys(duplicateRowNames).length
    const numMissingRowNames   = Object.keys(missingRowNames).length
    // Error message from the header inputs -- by priority:
    var errMessageHeader = ''
    if ( isMismatchedType ) {
      errMessageHeader = 'All data is erroneous or missing. This column<br>has no useful value. Wrong Data Type?'
    }
    else if ( unitsValue === '' ) {
      errMessageHeader = 'Units field (above) must be defined.'
    }
    else if ( isKey && numMissingRowNames > 0 ) {
      errMessageHeader = `${numMissingRowNames} missing row names. Remove filters and<br>sort column to force missing names to top of table.`
    }
    else if ( isKey && numDuplicateRowNames > 0 ) {
      errMessageHeader = `${numDuplicateRowNames} duplicate row names. Remove filters and<br>sort column to force duplicate names to top of table.`
    }
    else if ( badColNameErrorID !== '' ) {
      errMessageHeader  = badColNameErrorID
    }
    else if ( currentTextWithLinksErrMsg !== '' ) {
      errMessageHeader  = currentTextWithLinksErrMsg
    }

    // Error message in formula comes from the parsed formula in the tableComputedData
    var errMessageFormula = parsedScryFormula ? parsedScryFormula.errorID : ''

    if ( !canEdit ) {   // No errors displayed
      errMessageFormula = ''
      errMessageHeader = ''
    }

    var colKeyColor = (isKey) ? '#202020' : '#B0B0B0'
    var keyIconSize = 14

    return (
      <div className={'rc_EditColHeader'}
        style={{fontSize, width:TOTAL_EDITOR_WIDTH }}>

          {/* 1st Line:  Column Name & DataType */}
{isNameRowVisible &&

          <div className={'ColNameDataTypeRow'} style={{marginTop:10,}} >
              <label style={{...labelStyle_LeftAligned, marginLeft: MARGIN_LEFTRIGHT}}>
                    {'Name\u2009:'}
              </label>

              {canEdit
              ? <EditColHeaderInputs
                  id='colNameLabel'
                  mode='colName'
                  textColor={badColNameErrorID === '' ? 'black' : 'red'}
                  // isEditable={canEdit}
                  tablelookid={tablelook.id}
                  tableid={table.id}
                  colKey={colKey}
                  colDataType={colDataType}
                  formatRule={formatRule}
                  width={161}
                  height={rowHeight}
                  initializationValue={colTitle}
                />
              : <div
                  style={{...labelStyle_LeftAligned,
                    marginRight:0,
                    width:161
                  }}
                  id='colNameLabel'> {colTitle} </div>
              }

              <label style={{...labelStyle_LeftAligned,
                  width:74, marginLeft: canEdit ? 34 : 64 }}>
                    {'DataType\u2009:'}
              </label>

              {canEdit
              ?  <Fragment>
                 <EditorMenuButton
                    // id='dataTypeLabel'
                    menuItems={dataTypeMenuItems}
                    onSelection={this.handleColDataTypeChange}
                    selectedValue={colDataType}
                    height={rowHeight}
                    widthDisplayText={124}
                    widthDisplayCaret={20}
                    textAlign={'center'}
                    // width={126}
                    menuWidth={215}
                    menuRight={-30}
                    menuTop={40}
                  />


                  <button className={'helpWithKeyButton'}
                    onClick={ this.pushedHelpWithKeyButton }
                    style={{
                      display: 'inline-block', verticalAlign: 'top',
                      height: rowHeight-1, width: rowHeight*.8,
                      fontSize: 13, fontWeight: 'bold',
                      marginLeft: 19, marginTop: 0,
                      paddingTop: 0,  paddingLeft: 3,
                      ...STYLE_EDITOR_RAISED_BUTTON,
                      // next background when unselected SHOULD match the background set in STYLE_EDITOR_RAISED_BUTTON
                      background: (isHelpColKeyActive) ? constants.HIGHLIGHT_COLOR_HALF : 'rgb(230,230,230)',
                    }}>{'?'}
                  </button>

                  <div className={'HeaderKeyInput'}
                    onClick={ this.toggleIsKey }
                    style={{
                      display: 'inline-block',
                      marginLeft: 4, marginRight: 0,
                      verticalAlign: 'top',
                      height: rowHeight, width:rowHeight,
                      background: 'white',
                      borderRadius: 4, borderWidth: 2, borderStyle: 'outset', borderColor,
                    }}>
                    <SVGwrapper2>
                        <SVGkey color={colKeyColor} width={keyIconSize} height={keyIconSize} />
                    </SVGwrapper2>
                  </div>
                  </Fragment>

                : <div style={{...labelStyle_LeftAligned, width:180 }} id='dataTypeLabel'>
                    {dataTypeMenuItems[colDataType].displayedName}
                  </div>
              }
          </div>
}



          {/*  2nd Line Units, Prefix, Suffix (Visible only to curator).  */}
{isUnitsRowVisible &&
          <div className={'UnitsPrefixSuffixRow'}
            style={{position:'relative', marginTop:0, marginLeft: MARGIN_LEFTRIGHT}}
          >

              <label style={{ ...labelStyle_LeftAligned, paddingLeft: shouldForceUnits ? 0 : 20 }}>
                {'Units\u2009:'}
              </label>

              {shouldForceUnits
              ? <div style={{ ...labelStyle_LeftAligned, width:156, paddingLeft: 0}}>
                  {unitsValue}
                </div>

              : <EditColHeaderInputs
                    id='unitsName'
                    mode='colUnits'
                    // isEditable={!shouldForceUnits }
                    tablelookid={tablelook.id}
                    tableid={table.id}
                    colKey={colKey}
                    colDataType={colDataType}
                    formatRule={formatRule}
                    width={161}
                    height={rowHeight}
                    initializationValue={unitsValue}
                />
              }

              {!shouldForceUnits &&
              <button className={'helpWithUnitsButton'}
                onClick={ this.pushedHelpWithUnitsButton }
                style={{
                  position:'absolute', left:0, top:-1,
                  display: 'inline-block', verticalAlign: 'top',
                  height: rowHeight-2, width: rowHeight*.8,
                  fontSize: 13, fontWeight: 'bold',
                  paddingTop: 0,  paddingLeft: 3,
                  ...STYLE_EDITOR_RAISED_BUTTON,
                  background: (isHelpUnitsActive) ? constants.HIGHLIGHT_COLOR_HALF : 'rgb(230,230,230)',
                }}>{'?'}</button>
              }


              {colDataType !== 'hyperlink' &&
              <Fragment>

                <button className={'helpWithPrefixButton'}
                  onClick={ this.pushedHelpWithPrefixButton }
                  style={{
                    position:'absolute', top:-1,
                    display: 'inline-block', verticalAlign: 'top',
                    height: rowHeight-2, width: rowHeight*.8,
                    fontSize: 13, fontWeight: 'bold',
                    marginTop: 1, marginLeft:34,
                    paddingTop: 0,  paddingLeft: 3,
                    ...STYLE_EDITOR_RAISED_BUTTON,
                    // next background when unselected SHOULD match the background set in STYLE_EDITOR_RAISED_BUTTON
                    background: (isHelpPrefixActive) ? constants.HIGHLIGHT_COLOR_HALF : 'rgb(230,230,230)',
                  }}>{'?'}</button>

                <label style={{ ...labelStyle_LeftAligned, width:54, marginLeft:54 }}>
                     {'Prefix\u2009:'}
                </label>

                <EditColHeaderInputs
                  id='prefixLabel'
                  mode='colPrefix'
                  // canEdit={canEdit}
                  tablelookid={tablelook.id}
                  tableid={table.id}
                  colKey={colKey}
                  colDataType={colDataType}
                  formatRule={formatRule}
                  width={68}
                  height={rowHeight}
                  initializationValue={formattingObj.prefix}
                />

              <label style={{...labelStyle_LeftAligned, width: 46, marginLeft:20}}>
                    {'Suffix\u2009:'}
                </label>

                <EditColHeaderInputs
                  id='suffixLabel'
                  mode='colSuffix'
                  // isEditable={canEdit}
                  tablelookid={tablelook.id}
                  tableid={table.id}
                  colKey={colKey}
                  colDataType={colDataType}
                  formatRule={formatRule}
                  width={68}
                  height={rowHeight}
                  initializationValue={formattingObj.suffix}
                />
            </Fragment>
            }

          </div>
}


{isAnyHelpPanelVisible &&
          <Fragment>
          <div className={'vertSpacer'} style={{height:6}}/>
    {isHelpColKeyActive &&
          <HelpPanelColKey className={'colKeyHelpPanel'} onDismissClick={this.closeHelp}
            left={10} width={TOTAL_EDITOR_WIDTH - 20}/>
    }
    {isHelpUnitsActive &&
          <HelpPanelUnits className={'unitsHelpPanel'} onDismissClick={ this.closeHelp }
            width={TOTAL_EDITOR_WIDTH - 20}/>
    }
    {isHelpPrefixActive &&
          <HelpPanelPrefix className={'prefixHelpPanel'} onDismissClick={this.closeHelp}
            left={10} width={TOTAL_EDITOR_WIDTH - 20}/>
    }
          </Fragment>
}




          {/* 3rd Line Format, precision, and precisionMode (Visible only for numbers) */}
{isFormatRowVisible &&

          <div className={'formattingControls'}
            style={{ marginTop:10, marginLeft: MARGIN_LEFTRIGHT,
                    position:'relative', top:0, left:0 }} >

            <label style={labelStyle_LeftAligned}>
              {'Format\u2009:'}
            </label>

            <EditorMenuButton
              menuItems={formatRuleOptionsForThisDataType}
              onSelection={this.handleFormatRuleChange}
              selectedValue={formatRule}
              height={rowHeight}
              widthDisplayText={100}
              widthDisplayCaret={20}
              textAlign={'center'}
              menuWidth={235}
              menuRight={85}
              menuTop={-110}  />

            { precModesByFormatRules[formatRule].length >= 1 &&
              <input
                id='formatRuleLabel'
                onChange={this.handlePrecisionChange}
                style={{
                  display: 'inline-block',
                  width: 48, height: rowHeight,
                  verticalAlign: 'top',
                  border: `2px inset ${borderColor}`, borderRadius: 4,
                  marginLeft: 2, marginRight: 2, paddingTop: 2, paddingLeft:5 }}
                type='number'
                value={formattingObj.precision}
                min={0}
                max={15}
                autoComplete='off'
                spellCheck='false' />
            }

            { precModesByFormatRules[formatRule].length > 1 &&
              <EditorMenuButton
                menuItems={precModeOptionsForThisFormatRule}
                onSelection={this.handlePrecisionModeChange}
                selectedValue={precMode}
                height={rowHeight}
                widthDisplayText={290}
                widthDisplayCaret={20}
                textAlign={'left'}
                menuWidth={320}
                menuRight={-50}
                menuTop={10} /> }

            { precModesByFormatRules[formatRule].length === 1 &&
              <div style={{...labelStyle_LeftAligned, width:262, paddingLeft: 5 }} id='formatRuleLabel'>
                {precModeMenuItems[precMode].displayedName}
              </div> }

          </div>
}


{/* 4rth line: Description EditEditTextWithLinks component. */}
{isDescriptionVisible &&
            <Fragment>
            <div className={'vertSpacer'} style={{ height: isFormatRowVisible ? 0 : 8  }}/>
            <HorzTaperedDivider background={constants.FLOATING_PALETTE_BACKGROUND}/>
            <div className={'vertSpacer'} style={{ height: 4 }}/>

            <div style={{position:'relative',
              marginBottom:0, marginLeft: MARGIN_LEFTRIGHT,
              zIndex: 10,
            }}>

                <EditTextWithLinks
                  heightLabel={canEdit ? 32 : 20}
                  heightMaxViewMode={125}
                  heightEditMode={textWithLinksHeight}
                  forceActive={isActiveTextWithLinks}
                  // horzMargin={0}
                  width={FORMULA_EDITOR_WIDTH}
                  labelStyle={canEdit ? textWithLinksLabelStyle_EditMode : textWithLinksLabelStyle_ViewMode}
                  tableid={table.id}
                  colOrSeriesKey={colKey}
                  canEdit={canEdit}
                  parentMode={'colDescription'}
                  textWithLinks={colDescription}
                  passErrMsgToParent={ this.passTextWithLinksErrMsgToParent }
                  passActiveStateToParent={this.passTextWithLinksActiveStateToParent}/>


                {/* Render on top so onClick works */}
                { canEdit &&
                <Fragment>
                <button className={'helpWithDescriptionButton'}
                  onClick={ this.pushedHelpWithDescriptionButton }
                  style={{
                    position:'absolute', top:4, left: 0,
                    height: rowHeight-2, width: rowHeight*.8,
                    fontSize: 13, fontWeight: 'bold',
                    paddingTop: 0, paddingLeft: 3,
                    ...STYLE_EDITOR_RAISED_BUTTON,
                    background: (isHelpDescriptionActive) ? constants.HIGHLIGHT_COLOR_HALF : 'rgb(230,230,230)',
                  }}> {'?'} </button>

                <div className={'placementContainer'}
                  style={{position:'absolute', top:-4, left:112}}>
                    <EditColMessageDisplay
                      width={DESCRIPTION_ERRMSG_WIDTH}
                      height={38}
                      errMessage={errMessageHeader} />
                </div>
                </Fragment>
                }

              </div>
              </Fragment>

}


{/* 5th line: Dependent columns only : EditColFormula component. */}
{isFormulaVisible &&
              <Fragment>

        { !isHelpFormulasActive &&
              <Fragment>
              <div className={'vertSpacer'} style={{ height: canEdit ? 6 : 2 }}/>
              <HorzTaperedDivider background={constants.FLOATING_PALETTE_BACKGROUND}/>
              <div className={'vertSpacer'} style={{ height: 4 }}/>
              </Fragment>
        }

              <div style={{position:'relative', marginLeft: MARGIN_LEFTRIGHT}}>

                  <EditColFormula
                    heightLabel={canEdit ? 32 : 24}
                    heightMaxViewMode={125}
                    heightEditMode={editColFormulaHeight}

                    width={FORMULA_EDITOR_WIDTH}
                    tableid={table.id}
                    colKey={colKey}
                    rowKey={-1}
                    // canEdit={canEdit}
                    isCellMode={false}
                    // labelStyle={{
                    //   ...labelStyle_LeftAligned,
                    //   paddingLeft: canEdit ? 20 : 0,
                    // }}
                    tableComputedData={tableComputedData}
                    errMessageFormula={errMessageFormula}
                    errMessageHeader={''}
                    passActiveStateToParent={this.passEditColFormulaActiveStateToParent}/>

                  {/* Render on top so onClick works */}
                  { canEdit &&
                  <button className={'helpWithFormulasButton'}
                    onClick={ this.pushedHelpWithFormulasButton }
                    style={{
                      position:'absolute', left:0, top:4,
                      height: rowHeight-2, width: rowHeight*.8,
                      fontSize: 13, fontWeight: 'bold',
                      paddingTop: 0, paddingLeft: 3,
                      ...STYLE_EDITOR_RAISED_BUTTON,
                      background: (isHelpFormulasActive) ? constants.HIGHLIGHT_COLOR_HALF : 'rgb(230,230,230)',
                    }}>{'?'}</button>
                  }

              </div>
              </Fragment>
}


{isAnyHelpPanelVisible && isHelpFormulasActive &&
              <HelpPanelFormulas className={'formulasHelpPanel'}
                onDismissClick={ this.closeHelp }
              />
}

{isAnyHelpPanelVisible && isHelpDescriptionActive &&
              <HelpPanelDescription className={'descriptionHelpPanel'}
                onDismissClick={ this.closeHelp }
                left={10}
                width={TOTAL_EDITOR_WIDTH-20}
              />
}


{isMoreActionsButtonVisible
      ?   <div style={{ position:'relative', top:0, left:0, // local coord system for placement values to follow
              marginTop:8, marginBottom:4, marginLeft:116 }}>
            <EditorMenuButton
              menuItems={colActionsMenuItems}
              onSelection={ this.handleActionMenuSelection }
              selectedValue={'exitActionMenu'}
              height={rowHeight}
              widthDisplayText={270}
              widthDisplayCaret={0}
              textAlign={'center'}
              menuWidth={320}
              menuRight={-30}
              menuBottom={-15}
              shouldStyleAsButton={true} />
          </div>
      : <div className={'vertSpacer'} style={{height:8}}/>
}

{isColRestoreActive &&

          <div className={'ZeroSizeContainerToFool3rdPartyAutoSizedFloatingPallete'}
            style={{
              position:'relative',
              height:0, width:0
            }}>
              <div className={'ColRestoreContainer'}
                style={{
                  ...constants.CUSTOM_SELECT_POPUP_MENU_OUTLINE,
                  position:'relative',
                  left:130, top: -210,
                  width:475,
                }}>
                    <EditColRestore
                      onExit={this.handleExitColRestoreControl}
                      insertAnimation={this.insertColumn}
                      deleteAnimation={this.deleteColumn}
                      tableid={table.id}
                      tabledataid={this.props.tabledataid}
                      tablelookid={tablelook.id}
                      derivedColAttributesArray={tableComputedData.derivedColAttributesArray}
                      selectedColKey={colKey}
                    />
              </div>
          </div>
}

    </div>
    )
  }
}

/*
<EditColMessageDisplay
  width={totalWidth}
  horzMargin={horzMargin}
  errMessage={errMessageHeader}
  wrnMessage={''}
  canEdit={canEdit}
  colTitle={'Description'}
  labelStyle={labelStyle_RightAligned} />
*/
