import type { Table, Tablelook } from '../types'
import type { PlotXyComputedData } from '../computedDataPlotXY/xy_plotTypes'
import type { TableComputedData } from '../computedDataTable/getDefaultTableComputedData'

import { Component } from 'react'
import ComponentXY from './ComponentXY'
import CrosshairsXY from './CrosshairsXY'
import { rStateXY_Plot } from './xy_responsiveState'


type OwnProps = {
  plotXyComputedData: PlotXyComputedData,
  tableComputedData: TableComputedData,
  table: Table,
  tablelook: Tablelook,
  canEdit: boolean,
}

export default class PlotLayoutMain extends Component<OwnProps> {

  initMainTitle = (element: HTMLDivElement | null): void => {
    rStateXY_Plot.mainTitle = element
  }
  initPublisherTitle = (element: HTMLDivElement | null): void => {
    rStateXY_Plot.publisherTitle = element
  }

  render() {
    const { plotXyComputedData, canEdit, tableComputedData } = this.props

    const h = plotXyComputedData.plotHeightObj
    const w = plotXyComputedData.plotWidthObj
    const s = plotXyComputedData.plotStyleObj
    var { mainTitleDisplayed, publisherTitleDisplayed, mainTitle } = plotXyComputedData
    // If the mainTitle is missing, then mainTitleDisplayed contains an error message.
    // Otherwise, the two will be identical
    // We choose a color (black/red) is the mainTitle is missing.
    // We use the mainTitle displayed for rendering.
    const mainTitleColor = (!mainTitle || mainTitle === '') ? 'red' : 'black'
    // Changed publisher Title to default to plot owners name.  Hence never missing.
    var publisherTitleColor = 'black'

    return (
      <div className={'rc_PlotLayoutMain'}
        style={{ position: 'absolute', top: 0, left: 0 }} >

        <div className={'mainTitle'}
          ref={this.initMainTitle}
          style={{
            position: 'absolute',
            top: h.topMargin, left: 0,
            // Left position is responsive using transforms
            transform: `translate(${w.mainTitleLeft}px, 0px)`,
            width: w.mainTitle, height: h.mainTitle,
            textAlign: 'center', paddingTop: 5,
            fontSize: s.fontSizeTitle,
            fontWeight: 'bold',
            color: mainTitleColor,
            //background: 'pink',
          }} >{mainTitleDisplayed}</div>

        <div className={'publisherTitle'}
          ref={this.initPublisherTitle}
          style={{
            position: 'absolute',
            top: h.topMargin + h.mainTitle, left: 0,
            // Left position is responsive using transforms
            transform: `translate(${w.publisherTitleLeft}px, 0px)`,
            width: w.publisherTitle, height: h.publisherTitle,
            textAlign: 'center', paddingTop: 5,
            fontSize: s.fontSizePublisher,
            color: publisherTitleColor,
            //background: 'pink'
          }} >{publisherTitleDisplayed}</div>


        <div className={'ComponentXY'}
          style={{
            position: 'absolute',
            left: 0,
            top: h.topMargin + h.mainTitle + h.publisherTitle + h.gapPublisherLegend
          }}>

          <ComponentXY
            canEdit={canEdit}
            plotXyComputedData={plotXyComputedData}
            tableComputedData={tableComputedData}
          />

        </div>

        <CrosshairsXY
          plotXyComputedData={plotXyComputedData}
          tableComputedData={tableComputedData}
        />

      </div>
    )
  }
}
