import { Plot } from '../types'
import type { GetTableValue, TableComputedData } from '../computedDataTable/getDefaultTableComputedData'
import type {
  ColKeyToColValuesMap,
  FilterOtherArgs,
  FilterParams,
  FilterRefs,
  FilterResult
} from '../computedDataTable/updateTableComputedData'
import type { DerivedFilterRule } from '../sharedFunctions/filterRows'
import type { PriorMemoizedResult } from '../sharedFunctions/getObjectDiff'
import type {
  FilterSeriesOtherArgs,
  FilterSeriesParams,
  FilterSeriesRefs,
  FilterSeriesResult,
  SortSeriesOtherArgs,
  SortSeriesParams,
  SortSeriesRefs,
  SortSeriesResult,
  StatsSeriesOtherArgs,
  StatsSeriesParams,
  StatsSeriesRefs,
  StatsSeriesResult
} from './filterSeries'
import type { StringIndexOtherArgs, StringIndexParams, StringIndexRefs, StringIndexResult } from './stringAxisMap'
import type { PlotXyComputedAxis, PlotXyComputedData, PlotXyComputedSeriesAttributes, ReactLayer } from './xy_plotTypes'
import type { ErrorMsg } from '../viewPlotXY/ComponentXYerrMsg'

import invariant from 'invariant'
import { smallPercentChar } from '../sharedComponents/constants'
import {
  filterRows_memoizedFunc,
  createDerivedFilterRuleArray
} from '../sharedFunctions/filterRows'
import { startTimer, logTime, stopTimer } from '../sharedFunctions/timer'
import { plotLayoutConsts } from '../viewPlotXY/plotLayoutConsts'
import { createCulledPts_SingleSeries_memoizedFunc } from './cullPlotPts'
import { clearDelaunayD3structures } from './delaunay'
import {
  createSeriesRowPlotPts,
  createSeriesRowPlotPts_sortedInA,
  createSeriesRowPlotPts_stats
} from './filterSeries'
import { findAndRepairDegenerateRange } from './findAndRepairDegenerateRange'
import {
  createBasisB_PercentileAxis,
  createBasisB_NormalProbAxis
} from './percentilePlots'
import {
  createBasisB_HistogramAxis,
  errorCheckRenderedLayersArr,
  extendDomainConstrained,
  forceToDoublePercentAxis,
  forceToNegativePercentAxis,
  forceToPercentAxis
} from './plotUtils'
import { smartAxisScale } from './smartAxisScale'
import { createReactLayer } from './xy_createReactLayer'
import { initializePlotXyComputedData } from './xy_initializePlotRenderObj'
import { plotCalculator_And_StringAxisScalePart2 } from './xy_plotCalculator'
import { createTextToIndexObj } from './stringAxisMap'
import { getMemoizedResult } from '../sharedFunctions/getObjectDiff'


const DEBUG = false
const TIMER = false // Note; IF timing, THEN set DEBUG = false.
// Debug printing to the console cost a lot of time.
const emptyArray = Array<number>()
let memoizedSeriesFilteredRowKeys = Array<PriorMemoizedResult<FilterParams, ColKeyToColValuesMap, FilterResult>>()
let memoizedSeriesRowPlotPts = Array<PriorMemoizedResult<FilterSeriesParams, FilterSeriesRefs, FilterSeriesResult>>()
let memoizedSeriesRowPlotPts_sortedInA = Array<PriorMemoizedResult<SortSeriesParams, SortSeriesRefs, SortSeriesResult>>()
let memoizedSeriesRowPlotPts_stats = Array<PriorMemoizedResult<StatsSeriesParams, StatsSeriesRefs, StatsSeriesResult>>()
let memoizedTextToIndexObjBasisA = Array<PriorMemoizedResult<StringIndexParams, StringIndexRefs, StringIndexResult>>()
let memoizedTextToIndexObjBasisB = Array<PriorMemoizedResult<StringIndexParams, StringIndexRefs, StringIndexResult>>()


export type PlotXyComputedDataParams = {
  menuOption_isEditMode: boolean
  username: string
  derivedFilterRules: DerivedFilterRule[]
}

export type PlotXyComputedDataRefs = {
  tableComputedData: TableComputedData
  plot: Plot
}

export type PlotXyComputedDataOtherArgs = {
  getTableValue: GetTableValue
}

export type PlotXyComputedDataResults = {
  plt: PlotXyComputedData
}

export const createPlotXyComputedData = (paramsObj: PlotXyComputedDataParams, refsObj: PlotXyComputedDataRefs,
  otherArgsObj: PlotXyComputedDataOtherArgs): PlotXyComputedDataResults => {
  if (TIMER) { startTimer('id_PlotXyComputedData') }
  const { menuOption_isEditMode, username } = paramsObj
  const { tableComputedData, plot } = refsObj
  const plt = initializePlotXyComputedData(plot, tableComputedData, menuOption_isEditMode, username, DEBUG)
  const { plotColDataType, seriesOrder, renderedLayersArr, isHisto, isPercentile,
    isStacked100Percent_allLayers, isStacked_anyLayer, seriesAttributesArray } = plt
  const { derivedColAttributesArray, table, tableValuesWorking, getTableValue } = tableComputedData
  const numSeries = seriesOrder.length
  const numRows = table.attributes.numRowsUnfiltered //tableValues[0].length
  if (process.env.NODE_ENV !== 'production') {
    errorCheckRenderedLayersArr(plotColDataType, renderedLayersArr, numSeries)
  }
  const { basisA, basisB } = plt
  const isColDataType2 = (plotColDataType === '2Col')
  const seriesWideDataTypeA = basisA.internalDataType
  const seriesWideDataTypeB = (plotColDataType === '1Col') ? 'number' : basisB.internalDataType
  logTime('id_PlotXyComputedData', 'Initialize PlotXyComputedData object.')


  /////////////////////////////////////////////////////////////////////////
  //  Filter rows
  //      Each series MAY have different set of rowKeys.
  //      But ofter multiple series will share the same filtered dataSet.
  //      In case of shared dataSets (same filters), then memoization will
  //      quickly return a prior calculated result.
  /////////////////////////////////////////////////////////////////////////

  for (var sKey = 0; sKey < numSeries; sKey++) {
    let { isDeletedCol, isUnsetSeriesColKey, isDataTypeMismatch,
      seriesFilter } = seriesAttributesArray[sKey]
    var isIllegalSeries = (isDeletedCol || isUnsetSeriesColKey || isDataTypeMismatch)
    if (isIllegalSeries) {
      plt.seriesFilteredRowKeys[sKey] = emptyArray
      if (DEBUG) {
        console.log(`  Set   seriesFilteredRowKeys[${sKey}] = emptyArray`)
      }
      continue
    }
    let mergedFilterRules = plt.commonSeriesFilter.concat(seriesFilter)
    let derivedFilterRules = createDerivedFilterRuleArray(mergedFilterRules, derivedColAttributesArray)
    const filterParamsObj: FilterParams = { derivedFilterRules, numRows }
    var filterRefsObj: FilterRefs = {}
    for (const thisRule of paramsObj.derivedFilterRules) {
      // Only (at most) one column values reference per derivedFilterRule
      var { colKey: colKeyVal, enabled } = thisRule
      if (colKeyVal === -1 || !enabled) { continue }   // skip empty and disabled fitler rules.
      filterRefsObj[colKeyVal] = tableValuesWorking[colKeyVal]
    }
    var filterOtherArgsObj: FilterOtherArgs = { getTableValue, DEBUG }
    var maxNumMemoized = numSeries + 1
    var workerFunc = filterRows_memoizedFunc   // shared function with tableComputedData row filtering.
    var priorResults = memoizedSeriesFilteredRowKeys
    var { result, isNewResult, newMemArr } = getMemoizedResult(priorResults, filterParamsObj, filterRefsObj, filterOtherArgsObj, workerFunc, maxNumMemoized)
    if (isNewResult) { memoizedSeriesFilteredRowKeys = newMemArr }
    if (DEBUG) {
      (isNewResult) ? console.log(`  Calc  seriesFilteredRowKeys[${sKey}]`)
        : console.log(`  Reuse seriesFilteredRowKeys[${sKey}]`)
    }
    plt.seriesFilteredRowKeys[sKey] = result.filteredRowKeys
    plt.seriesAttributesArray[sKey].numPoints = result.filteredRowKeys.length
    plt.seriesAttributesArray[sKey].isNoData = (result.filteredRowKeys.length === 0)
  }
  logTime('id_PlotXyComputedData', 'Create seriesFilteredRowKeys.')

  /////////////////////////////////////////////////////////////////////////
  //  Create series PlotPts:  (Sparse Plot Pts1)
  //      From the filteredSeriesRowKeys, create an equal length array of plotPts.
  //      This is essentially extracting the table values for each filtered row,
  //      and formatting as the array of plottable points we will pass to reactRender.
  //      PlotPt attributes A and/or B contain the table value IFF the basis is numeric.
  //      PlotPt attributes Astring and/or Bstring contain the table value for string basis.
  /////////////////////////////////////////////////////////////////////////

  for (sKey = 0; sKey < numSeries; sKey++) {
    var { colKeyA, colKeyB, internalDataTypeA, internalDataTypeB } = seriesAttributesArray[sKey]
    const paramsObj: FilterSeriesParams = {
      seriesWideDataTypeA, seriesWideDataTypeB,
      plotColDataType, colKeyA, colKeyB, internalDataTypeA, internalDataTypeB,
    }
    // The function tableComputedData.getTableValue(row,col, hideErroneousValues) is used
    // to access cell values. However, we need the refs obj to 'watch' for
    // changes in the underlying column data.
    const refsObj: FilterSeriesRefs = {
      // valA: tableValuesWorking[colKeyA],
      // valB: tableValuesWorking[colKeyB],
      seriesFilteredRowKeys: plt.seriesFilteredRowKeys[sKey],
    }
    const otherArgsObj: FilterSeriesOtherArgs = { getTableValue }
    maxNumMemoized = numSeries + 1
    const workerFunc = createSeriesRowPlotPts
    const priorResults = memoizedSeriesRowPlotPts;
    const { result, isNewResult, newMemArr } = getMemoizedResult(priorResults, paramsObj, refsObj, otherArgsObj, workerFunc, maxNumMemoized)
    if (isNewResult) { memoizedSeriesRowPlotPts = newMemArr }
    if (DEBUG) {
      (isNewResult) ? console.log(`  Calc  seriesRowPlotPts[${sKey}]`)
        : console.log(`  Reuse seriesRowPlotPts[${sKey}]`)
    }
    plt.seriesRowPlotPts[sKey] = result.seriesRowPlotPts
  }
  logTime('id_PlotXyComputedData', 'Create SeriesRowPlotPts array.')

  /////////////////////////////////////////////////////////////////////////
  //  For each string basis, generate the TextToIndexObj
  //      Each unique string is assigned an integer index from 1 to 'n'
  //      The unique strings set is a single object, defined over ALL series,
  //      and owned by the basis!
  //      The order of the strings (which string is assigned to '1','2', ...)
  //         is specified by the user via the UI.
  /////////////////////////////////////////////////////////////////////////

  const someFilteredPtsExist = plt.seriesFilteredRowKeys.some(thisSeries => thisSeries.length > 0)
  for (const basis of [basisA, basisB]) {
    var isStringAxis = (basis.internalDataType === 'string' && someFilteredPtsExist)
    if (!isStringAxis) { continue }
    var basisName = basis.basisName  // 'A', 'B', ...
    const opposingBasis = (basisName === 'A') ? plt.basisB : plt.basisA
    const paramsObj: StringIndexParams = {
      plotColDataType: plt.plotColDataType,
      seriesOrder: plt.seriesOrder,
      basisName,
      opposingBasisName: opposingBasis.basisName,
      opposingBasisDataType: opposingBasis.internalDataType,
      stringOrder: basis.stringOrder,
      stringOrderDirection: basis.stringOrderDirection,
      isHistoOrPercentile: plt.isHisto || plt.isPercentile,
      renderedLayersArr: plt.renderedLayersArr,  // Determines some string order sorting options
      isStacked: plt.isStacked_anyLayer
    }
    // We need the refsObj to check the filtered pts over ALL series.
    // If even a single filterPts series has been modified, we need to rebuild the entire data structure.
    const { seriesRowPlotPts } = plt
    const refsObj: StringIndexRefs = seriesRowPlotPts
    const otherArgsObj: StringIndexOtherArgs = {}
    const priorResults = (basisName === 'A') ? memoizedTextToIndexObjBasisA : memoizedTextToIndexObjBasisB
    const workerFunc = createTextToIndexObj
    const maxNumMemoized = 6;
    const { result, isNewResult, newMemArr } = getMemoizedResult(priorResults, paramsObj, refsObj, otherArgsObj, workerFunc, maxNumMemoized)
    if (isNewResult) {
      if (basisName === 'A') { memoizedTextToIndexObjBasisA = newMemArr }
      else { memoizedTextToIndexObjBasisB = newMemArr }
    }
    basis.stringTextToIndexObj = result.textToIndexObj
    basis.stringTextToIndex_sortedKeys = result.sortedKeys
    basis.rangeFilteredAllSeries = { min: 1, max: result.sortedKeys.length }
    if (DEBUG) {
      if (isNewResult) {
        console.log(`  Calc  textToIndexObj for string basis${basisName}`)
      } else {
        console.log(`  Reuse textToIndexObj for string basis${basisName}`)
      }
    }
    logTime('id_PlotXyComputedData', `Create textToIndexObj string basis${basisName}`)
  }

  /////////////////////////////////////////////////////////////////////////
  //        Back-annotate stringName to A/B index value
  //        Sort-in-A
  //  We need to 'back-annotate' the plotPt A and B numerical values. For
  //  example, if the 'B' axis is string dataType, then seriesRowPlotPts contains
  //    plotPt : {
  //          A: 3.45,
  //          B: null,
  //          Astring: ''
  //          Bstring: 'Oregon' }
  //
  //  After building the textToIndexObj (above), we know that Bstring:'Oregon'
  //  now maps to B value '23'.  Because based on the user's criteria for sorting
  //  strings, 'Oregon' ranked ordinal number '23'.
  //  Hence, after 'back-anotation' we have
  //    plotPt : {
  //          A: 3.45,
  //          B: 23,
  //          Astring: ''
  //          Bstring: 'Oregon' }
  //
  //   We also sort the plotPts in 'A'.  I believe this is only useful
  //   for the 'culling algorithm' which soon follows.  But I choose to always
  //   do the sorting, whether we are culling the data or not.  Because
  //   sorted plotPts are useful for debugging data errors.
  /////////////////////////////////////////////////////////////////////////

  for (sKey = 0; sKey < numSeries; sKey++) {
    const paramsObj: SortSeriesParams = {
      isStringAxisA: (basisA.internalDataType === 'string' && someFilteredPtsExist),
      isStringAxisB: (basisB.internalDataType === 'string' && someFilteredPtsExist),
      plotColDataType,
      sortByColKey: seriesAttributesArray[sKey].sortByColKey,
      sortBy: seriesAttributesArray[sKey].sortBy
    }
    const refsObj: SortSeriesRefs = {
      textToIndexObjA: basisA.stringTextToIndexObj!,
      textToIndexObjB: basisB.stringTextToIndexObj!,
      seriesRowPlotPts: plt.seriesRowPlotPts[sKey]   // SPARSE PlotPts !
    }
    const otherArgsObj: SortSeriesOtherArgs = { getTableValue }
    const maxNumMemoized = numSeries + 1
    const workerFunc = createSeriesRowPlotPts_sortedInA
    const priorResults = memoizedSeriesRowPlotPts_sortedInA;
    const { result, isNewResult, newMemArr } = getMemoizedResult(priorResults, paramsObj, refsObj, otherArgsObj, workerFunc, maxNumMemoized)
    if (isNewResult) { memoizedSeriesRowPlotPts_sortedInA = newMemArr }
    if (DEBUG) {
      (isNewResult) ? console.log(`  Calc  seriesRowPlotPts_sortedInA[${sKey}]`)
        : console.log(`  Reuse seriesRowPlotPts_sortedInA[${sKey}]`)
    }
    plt.seriesRowPlotPts_sortedInA[sKey] = result.seriesRowPlotPts_sortedInA
  }
  logTime('id_PlotXyComputedData', 'Create seriesRowPlotPts_sortedInA.')


  //////////////////////////////////////////////////////////////////////////
  //    Some statistics are order independent (eg, mean, max, min).
  //    However, linRegression with one or more string axis requires
  //      ordering (user defined), and subsequent back annotation
  //      BEFORE we can fit a line.
  //////////////////////////////////////////////////////////////////////////

  for (sKey = 0; sKey < numSeries; sKey++) {
    const paramsObj: StatsSeriesParams = {}
    const refsObj: StatsSeriesRefs = { seriesRowPlotPts_sortedInA: plt.seriesRowPlotPts_sortedInA[sKey] }
    const otherArgsObj: StatsSeriesOtherArgs = {}
    const maxNumMemoized = numSeries + 1
    const workerFunc = createSeriesRowPlotPts_stats
    const priorResults = memoizedSeriesRowPlotPts_stats;
    const { result, isNewResult, newMemArr } = getMemoizedResult(priorResults, paramsObj, refsObj, otherArgsObj, workerFunc, maxNumMemoized)
    if (isNewResult) { memoizedSeriesRowPlotPts_stats = newMemArr }
    if (DEBUG) {
      (isNewResult) ? console.log(`  Calc  seriesRowPlotPts_stats[${sKey}]`)
        : console.log(`  Reuse seriesRowPlotPts_stats[${sKey}]`)
    }
    plt.seriesAttributesArray[sKey].filteredPtsStats = result.seriesRowPlotPts_stats
  }
  logTime('id_PlotXyComputedData', 'Create seriesRowPlotPts_stats.')


  //////////////////////////////////////////////////////////////////////////
  //    Some plot level statistics, over all series
  //    Used for setting the basis plotted ranges.
  //////////////////////////////////////////////////////////////////////////

  var numFilteredRowPts = 0
  plt.seriesFilteredRowKeys.forEach(thisSeries => numFilteredRowPts += thisSeries.length)

  var minAllSeriesA = +Infinity
  var maxAllSeriesA = -Infinity
  var minAllSeriesB = +Infinity
  var maxAllSeriesB = -Infinity
  for (const thisSeries of plt.seriesAttributesArray) {
    var { filteredPtsStats: stats } = thisSeries
    minAllSeriesA = Math.min(stats.minA, minAllSeriesA)
    maxAllSeriesA = Math.max(stats.maxA, maxAllSeriesA)
    minAllSeriesB = Math.min(stats.minB, minAllSeriesB)
    maxAllSeriesB = Math.max(stats.maxB, maxAllSeriesB)
  }
  basisA.rangeFilteredAllSeries = { min: minAllSeriesA, max: maxAllSeriesA }
  basisB.rangeFilteredAllSeries = { min: minAllSeriesB, max: maxAllSeriesB }

  /*
  //////////////////////////////////////////////////////////////////////////
      INITIALIZE BASIS (A & B)

      -Use min/max forced ranges IFF legal, otherwise autorange
      -Limit all string axis constraints from 1 to 'n', where 'n' is max number of unique strings.
      -If both constraints set AND equal, use only the minConstraint.
      -Set isInverted flag and flip min/max if necessary ( min ALWAYS < max per our dataRange definition).
      -Set the rangeCulled (over all series)
      -Determine whether any logarithmic axis request will be honored.
      -Set the linear & nonLinear basis transforms --  used in plotPt getterFuncs().

      basisA ONLY: Repair degenerate dataRange. basisA range MUST be know prior to building seedHisto.
      basisB     : CANNOT repair or even know basisB range until plotPts are created.  (done later in flow)

  //////////////////////////////////////////////////////////////////////////
  */

  initializeBasisObj(basisA, isHisto, isPercentile)
  initializeBasisObj(basisB, isHisto, isPercentile)

  // Next function repairs degenerate basisA range.
  // We can't do this for basisB at this time.  Because we do not know the
  // range of data (future plotPts based on user's requested renderedLayers)
  const params = {
    minR: basisA.rangeCulledAllSeries.min,
    maxR: basisA.rangeCulledAllSeries.max,
    isMinForced: basisA.isMinForced,
    isMaxForced: basisA.isMaxForced,
    minR_filteredData: basisA.rangeFilteredAllSeries.min,
    maxR_filteredData: basisA.rangeFilteredAllSeries.max,
    numFilteredRowPts,
    willUseLogarithmicScale: basisA.willUseLogarithmicScale,
    internalDataType: basisA.internalDataType,
  }
  var { newMin, newMax, newIsMinForced, newIsMaxForced } = findAndRepairDegenerateRange(params)
  basisA.isMinForced = newIsMinForced
  basisA.isMaxForced = newIsMaxForced
  basisA.rangeCulledAllSeries = { min: newMin, max: newMax }


  /*
  //////////////////////////////////////////////////////////////////////////

      At this point we propagate a 'lie' which improves both comprehension
      and coding.  What do we do with a logarithmic basisA request when all 'A'
      data is negative?   The 'logarithm' of a negative number is ILLEGAL
      (at least in real numbers domain).  However, a 'log scale' of negative data
      is LEGAL and we can plot it.  Because this lie (trick) avoids the complex
      domain by using some simple axis inversions.

      How to code for this case?  This corner case is not worth the effort if
      it contaminates the smartAxis scaling function, react-vis plotting
      and all the many other functions that need some knowledge of the
      plot's data range(s).  But there is a simple 'lie' we can propagate such
      that none of the functions between here and rendering are effected:
      Next example is written for basisA axis, however same logic for B

      ALL CODE RELATED TO THIS LIE: search for 'willUseLogWithNegativeData'

      IF ( basisA.willUseLogWithNegativeData ) { THEN pretend the A values are positive }
          1 - NonlinearXform for A plot pts is always written as Math.log( Math.abs(A) )
              So whether A data is always positive or always negative, we do not care.
              We ONLY care that range of A values never crosses zero:
              if ( A.min * A.max <= 0 ) { Ignore any user request for a log scale }
          2 - basisA.rangeSmartAxis is converted to a positive range
                New_rangeCulled = {
                  minVal : -(prior_rangeCulled.max),
                  maxVal : -(prior_rangeCulled.min)
                  isMinForced : prior_rangeCulled.isMaxForced,
                  isMaxForced : prior_rangeCulled.isMinForced
                }
          3 - Run all the intervening code, which assumes ONLY positive logarithmic
              plotPts/scales.  This creates the plot we want, but the plotted pts
              and axis scale are always 'positive' (right side of the number line}.

          This 'lie' can be rendered, and it will appear exactly as the 'lie'
          is intended.  If A is a log axis, it will ALWAYS be on the positive side
          of the number line, regardless of whether the filtered pts are
          positive or negative.

      FINAL STEP:   Fix the lie when we render the plot!

          In ComponentXY, when the plot is rendered, we
          negate the lie, by 'flipping the axis':
            - Reverse the direction of axis (flip the domain)
            - Reverse the order of the tick locations/labels -- You won't
                find this is the ComponentXY rendering code.  Because the
                labeling order is also 'flipped' when we reverse the domain.
                However, easier to think of this as part of fixing the lie.
            - Put a negative sign in front of the numeric tick values.

            The actual code in ComponentXY to 'reverse the lie':
            if ( willUseLogWithNegativeData ) {
              domainComponentXY   = domainExtended.reverse()       // flip the axis direction
              reactTickUserValues = tickUserValues.map( x => -x )  // label the tick values as negative.
            }

  //////////////////////////////////////////////////////////////////////////*/

  if (basisA.willUseLogWithNegativeData) {
    // Then Lie!  Flip the axis constraints to the 'positive equivalent'
    var { min, max } = basisA.rangeCulledAllSeries
    var { isMinForced, isMaxForced } = basisA
    basisA.rangeCulledAllSeries = { min: -max, max: -min }
    basisA.isMinForced = isMaxForced
    basisA.isMaxForced = isMinForced
  }
  // We can now build a 'smart axis' for basisA !!
  basisA.rangeSmartAxisAllSeries = { ...basisA.rangeCulledAllSeries }
  smartAxisScale(plt.basisA)  // Fills out the basisA axis information 'in-place'

  /*
  //////////////////////////////////////////////////////////////////////////

    We know the A axis range.
    We can now whittle down the number of filtered plot pts based on the
    and user constraints on basisA.
    When no constraints, seriesRowPlotPts_culled === seriesRowPlotPts_sortedInA
    With constraint seriesRowPlotPts_culled.length <= seriesRowPlotPts_sortedInA.length
       (none, some, or all pts may be culled)

    Culling serves two purposes.
    1) saves rendering rowValue pts that are not visible (react-vis may already do this; not sure)
    2) To determine whether seriesData is 'Out-of-View', we need to cull data, point by point.

  //////////////////////////////////////////////////////////////////////////
  */

  for (sKey = 0; sKey < numSeries; sKey++) {
    const result = createCulledPts_SingleSeries_memoizedFunc(plt, sKey, DEBUG)
    plt.seriesRowPlotPts_culled[sKey] = result.seriesRowPlotPts_culled
    plt.seriesAttributesArray[sKey].numCulledPoints = result.seriesRowPlotPts_culled.length
    // Add four new facts to the filterPtsStats
    let stats = plt.seriesAttributesArray[sKey].filteredPtsStats
    stats.minAculled = result.culledSeriesRangeA[0]
    stats.maxAculled = result.culledSeriesRangeA[1]
    stats.minBculled = result.culledSeriesRangeB[0]
    stats.maxBculled = result.culledSeriesRangeB[1]
  }


  /*
  //////////////////////////////////////////////////////////////////////////

      CREATE CANVAS LAYERS

      A canvas is defined as a plotted bitmap, aka same as js canvas obj.
      'plt.canvasLayersArray' is an array of bitmap definitions,
                                     all of identical extents: width,height,scale, ...

      Each canvas is an array: [renderLayer, renderedLayer, ... ]
      The renderLayer is a react-layer, and the set share the same canvas.
      For example, the linear fit line is on one canvas, with 3 react layers and one delaunay layer:
              Underlying: Semitransparent line that extends end-to-edge of plot (extrapolated line)
              2nd layer: Slightly fatter black/white line extends only over range of data.
                         Becomes a thin outline.
              Top layer: Colored line, only over range of data (interpolated line).
              Fourth delaunay layer, with characteristics:
                       isRendered=false, hasCrosshairs=true
                       Consist of a set of linear points that can be discovered by crosshairs.
                       Becomes a unique delaunay layer. IF/when discovered, we know crosshairs
                       should give information about a 'fitted linear regression'

      The number of canvas layers (length of canvasLayersArray) is currently determined
      by two objectives:
          1) Dense memoized information should be on its own canvas (e.g. a series scatter plot
             of thousands of points). This is for fast rendering updates.
          2) Unique crosshairs data set ( anything we need to give a name to be identifiable to
             crosshairs such as 'linearFit', 'smoothFit', 'rowPtData', 'averageValue', ...)
             must be individual canvas layers.

      The above two constraints are both arbitrary and could be improved.  For example, if we
      have 'too-many' canvas layers, non-dense layers of same type could be combined.
      For example, all seriesKeys of plotted non-dense row data could be on a single canvas.

      Also, the current delaunay data structure puts unique type/seriesKey on separate delaunay
      levels. However they can be merged onto a single level (like we originally put all series
      keys on one delaunay level (and did the bookkeepig for seriesKeys ourselves).

  //////////////////////////////////////////////////////////////////////////
  */

  // Gather together some 'PLOT-LEVEL' stats that were
  // previously collected for each series.
  // (Not currently used at this point)
  const isIntegersInA_BySeries = plt.seriesAttributesArray.map(x => x.filteredPtsStats.isIntegersInA)
  const isNonnegativeInA_BySeries = plt.seriesAttributesArray.map(x => x.filteredPtsStats.isNonnegativeInA)
  plt.isIntegersInA = isIntegersInA_BySeries.every(x => (x === true))
  plt.isNonnegativeInA = isNonnegativeInA_BySeries.every(x => (x === true))

  var allReactLayers = Array<ReactLayer>()
  renderedLayersArr.forEach(renderedLayerID => {
    var someReactLayers = createReactLayer(plt, renderedLayerID, DEBUG)
    allReactLayers = allReactLayers.concat(someReactLayers)
  })
  plt.canvasLayersArray = createCanvasLayersArray(allReactLayers)


  /*
  //////////////////////////////////////////////////////////////////////////

     Create the basisB axis
      - basisA was set by the range of filteredPlotPts data. (above)
      - basisB rangeRendered depends on 'what' the user wanted to plot.

     What determines the range we should use for the B-axis (BY PRIORITY)?
        0 - User Constrains min/max if isMinForced or isMaxForced set true.
            (User constraints used for some, but not all custom/fixed scales.)
        1 - Custom/fixed B scale for specialty plots such as percentile,
            normalProb, normalized percent or stackedPercent, ... ?
        2 - If insufficient data or only single 'B' value, uses repaired degenerate range
        3 - min/max extremes of rendered all rendered layers:
            3a - If row data (plot pts) are rendered, the min/max rangeCulled.
            3b - If mean data is rendered, the max/min mean points
            3c - Smth_mean range is defined as the 'mean' range.  Because the
                 smoothed range shrinks as a function of convolution.
                 Visually, it's better if the range does not 'jump-around' depending
                 on the smoothing slider.  By choosing to use the 'mean data range' we
                 are fixing the basisB smoothed range to the worse case smoothing alternative.
            3d - If linear regression line is rendered, the max/min range over the
                 the fitted data.
            B_range = { min : Math.min(3a,3b,3c,3d),  max : Math.max(3a,3b,3c,3d) }

  //////////////////////////////////////////////////////////////////////////
  */


  // Across all renderedLayerID's, what is the min/max rangeB by series, and overall ?
  const { minB_bySeries, maxB_bySeries } = getMinMaxB_bySeries(plt.canvasLayersArray, numSeries)
  var minB = Math.min(...minB_bySeries)
  var maxB = Math.max(...maxB_bySeries)

  var rangeRenderedAllSeries = basisB.rangeRenderedAllSeries = { min: minB, max: maxB }
  // Include the user's constraints in the total range.
  // When range is forced, then the forced value can be found in basisB.rangeCulledAllSeries
  if (basisB.isMinForced) { rangeRenderedAllSeries.min = basisB.rangeCulledAllSeries.min }
  if (basisB.isMaxForced) { rangeRenderedAllSeries.max = basisB.rangeCulledAllSeries.max }

  // If no data, set some plottable, default B range.  (Render an empty plot)
  const willUseLogarithmicScaleB = basisB.willUseLogarithmicScale
  if (rangeRenderedAllSeries.min === +Infinity || rangeRenderedAllSeries.max === -Infinity) {
    rangeRenderedAllSeries = basisB.rangeRenderedAllSeries =
      (willUseLogarithmicScaleB) ? { min: 1, max: 10 } : { min: 0, max: 10 }
  }

  // Update min/maxB after above additional constraints:
  ({ min: minB, max: maxB } = rangeRenderedAllSeries)

  invariant(!(willUseLogarithmicScaleB && minB * maxB < 0),
    `Log Baxis with minB * maxB = ${minB} * ${maxB}; Unexpected branch.`)
  basisB.willUseLogWithNegativeData = (willUseLogarithmicScaleB && minB < 0 && maxB < 0)
  if (basisB.willUseLogWithNegativeData) {
    // Then Lie!  Flip the axis constraints to the 'positive equivalent'
    ({ isMinForced, isMaxForced } = basisB);
    basisB.rangeRenderedAllSeries = { min: -maxB, max: -minB }
    basisB.isMinForced = isMaxForced
    basisB.isMaxForced = isMinForced
  }

  // NOW WE CAN CREATE THE basisB AXIS !!!

  // These next 3 options are ALL a 'custom' basisB layouts.
  // They DO NOT use smartAxisScale()
  if (isHisto) {
    createBasisB_HistogramAxis(plt, rangeRenderedAllSeries)
    smartAxisScale(plt.basisB)
  }
  else if (basisB.isPercentileNormalProb) {
    createBasisB_NormalProbAxis(plt)
  }
  else if (isPercentile) {
    createBasisB_PercentileAxis(plt)
    smartAxisScale(basisB)
  }


  // The next option is also a 'custom' scatter plot axis,
  // where basisB is scaled either:
  //    -100% to       +100% (worse case)
  //    -100% to 0%          (silly, but legal corner)
  //             0% to +100% (typical case)
  else if (isColDataType2 && isStacked100Percent_allLayers && !willUseLogarithmicScaleB) {
    if (minB >= maxB) { minB = 0; maxB = 10 }
    if (minB < 0 && maxB > 0) {
      forceToDoublePercentAxis(basisB)
    } else if (maxB > 0) {
      forceToPercentAxis(basisB)
    } else if (minB < 0) {
      forceToNegativePercentAxis(basisA)
    } else if (process.env.NODE_ENV === 'development') {
      invariant(false, `minB:${minB}, maxB:${maxB} for percentile axis out-of-range.`)
    } else {
      forceToDoublePercentAxis(basisB)
    }
  }

  // The next option is also a 'custom' scale.
  // Scatter plots, with basisB is logarithmically scaled 0% to +100% (worse case)
  // But we must replace '0%' with legalZeroValue_preLog.
  // The negative version ( 0 to -100% ) is also a valid, however silly use case.
  // Never-the-less, code also works for this corner case, the expectation
  // being that users will simply try various corners to just see what they look like.
  // You won't see the silly corner case here, because it is covered by the trick
  // to use logarithmic scales on negative data.
  else if (isColDataType2 && isStacked100Percent_allLayers && willUseLogarithmicScaleB) {
    // We know the maxB value is 100%
    // We know the minB value is > 0 (else willUseLogarithmicScale could not be true)
    // We canot use zero for bottom of scale.  We should use either 1, 0.1, 0.01, 0.001, ...
    // As the reason for even using this type of logScale is to 'blow up' some some
    // very small fractional series.
    // Find the smallest % to be plotted (minB/maxB), where maxB will be the largest
    // stack.  minB and maxB values almost certainly come from two different stacks,
    // but I don't believe that will be a problem.
    let smallestPercent = plt.minB_sKey0_Normed * 100
    let bottomPlottedPercent = Math.pow(10, Math.floor(Math.log10(smallestPercent / 2)))
    basisB.rangeSmartAxisAllSeries = { min: bottomPlottedPercent, max: 100 }
    basisB.tickFormatSuffixStrg = smallPercentChar
    smartAxisScale(basisB)
    // Use the lower domain limit from smartAxisScale for the legalZeroValue_preLog.
    basisB.legalZeroValue_preLog = Math.pow(10, basisB.domainUnconstrained[0])
  }

  // This includes all cases (most) where the rendered B-axis is determined by smartAxisScale().
  else {

    const params1 = {
      minR: basisB.rangeRenderedAllSeries.min,
      maxR: basisB.rangeRenderedAllSeries.max,
      isMinForced: basisB.isMinForced,
      isMaxForced: basisB.isMaxForced,
      minR_filteredData: basisB.rangeFilteredAllSeries.min,
      maxR_filteredData: basisB.rangeFilteredAllSeries.max,
      numFilteredRowPts,
      willUseLogarithmicScale: basisB.willUseLogarithmicScale,
      internalDataType: basisB.internalDataType
    };

    ({ newMin, newMax, newIsMinForced, newIsMaxForced } = findAndRepairDegenerateRange(params1))
    basisB.rangeRenderedAllSeries = { min: newMin, max: newMax }
    basisB.isMinForced = isMinForced = newIsMinForced
    basisB.isMaxForced = isMaxForced = newIsMaxForced

    // If basisB is linear && stacked, shift the min range to zero ( or less).
    if ((isStacked_anyLayer || isStacked100Percent_allLayers) &&
      !willUseLogarithmicScaleB && isMinForced === false) {
      basisB.rangeRenderedAllSeries.min = Math.min(0, basisB.rangeRenderedAllSeries.min)
    }
    // Let smartAxis create a 'pretty' dataRange, whether linear or logarithmic scale
    basisB.rangeSmartAxisAllSeries = { ...basisB.rangeRenderedAllSeries }
    smartAxisScale(basisB)
    // If logarithmic, let smartAxis pick the lower domain edge of the plot.
    // Then set the legalZeroValue_preLog (the location of B0 values when placed on bottom edge of plot)
    if (willUseLogarithmicScaleB) {
      basisB.legalZeroValue_preLog = Math.pow(10, basisB.domainUnconstrained[0])
    }
  }
  logTime('id_PlotXyComputedData', 'Create Baxis.')

  /*
  //////////////////////////////////////////////////////////////////////////

      Next set of functions are strictly cosmetic.  These lines can be commented
      out and the code & rendered plot are still functional/correct.  It you want
      to see the cosmetic effects, just comment out the function calls.

      1) plotCalculator_And_StringAxisScalePart2 :
            We cannot know how much room to allocate to the string axis tick
            labels until the final axis length and tick locations are known.
            Hence, plotCalculator.part1 makes a conservative estimate of the
            'room required' for axis tick labels.
            We now know exactly which tick labels with be used and their
            text lengths. plotCalculator.part2 will reallocate space
            reserved for the axis tick labels.  Result will be:
              - same size for plots that image all tick labels;
              - usually smaller size for plots that image periodic tick labels.

      2) Domain extension:
            For Bar plots, the finite bar width can extend beyond the left/right
            react-domain of the rendered axis.
            For String plots, looks better to NOT put the first/last strings
            at the extreme edges of the react-domain.
            This function checks for these cases, and will extend the
            react domain.  Like adding a bit of empty plot area to the edges
            of an axis.

  //////////////////////////////////////////////////////////////////////////
  */

  plotCalculator_And_StringAxisScalePart2(plt)
  basisA.domainExtended = extendDomainConstrained(basisA, plt)
  basisB.domainExtended = extendDomainConstrained(basisB, plt)


  /*
  //////////////////////////////////////////////////////////////////////////

    TRANSFORMS FOR DELAUNAY CROSSHAIRS FUNCTIONALITY:

       Mouse position is in (x,y) screen pixels.
       Plotted points are in (bottom,left) domain coordinates.

       Here are the set of coordinate transform to go from a table row value
       (col0, col1) to a corresponding 'screen pixel' coordinate:

       Screen/Mouse Pixels = (screenX, screenY) =
           plotDomainValueToSceenPixel: screen pixel from plot domain value (
                plotPt.getter functions: plotPt value from table value (
                  // getter functions includes stacking/normalization
                  // and include nonlinearXform (log, linear, probNorm)
                  filtered RowValue (A,B)  )))

       The plotDomainValue to ScreenPixel also needs to manage whether
       that axis is inverted (tick marks in opposite the 'nominal' direction).
       An axis is inverted if:
          - User constrained by limits and intentionally inverted direction.
          - We 'lied' to build a log axis using negative data (we flip the axis at render time)
          - The left axis is a string dataType, min value is plotted at top of the left axis.
       None or any number of these inversions may be true. Final inversion is the sum of the flips.

  //////////////////////////////////////////////////////////////////////////
  */

  // We switch from A/B referenced axes to bottom/left referenced axis.
  // Hence we can ignore the question of whether plot is transposed.
  const { bottomAxis, leftAxis } = plt
  var isInvertedBottomScale = bottomAxis.isInvertedAxis
  if (bottomAxis.willUseLogWithNegativeData) { isInvertedBottomScale = !isInvertedBottomScale }
  const domainX0 = bottomAxis.domainExtended[0]
  const domainX1 = bottomAxis.domainExtended[1]
  const domainRangeX = domainX1 - domainX0
  const plottedWidth_InScreenPx = plt.plotWidthObj.plottedData * plt.plotStyleObj.reactVisScale

  if (isInvertedBottomScale) {
    plt.bottomAxis.mousePxToPlotDomainValue = (Xpx) => {
      const proportionalPosition = Xpx / plottedWidth_InScreenPx
      return domainX1 - proportionalPosition * domainRangeX
    }
    plt.bottomAxis.domainValueToMousePx = (Xdomain) => {
      const proportionalPosition = (Xdomain - domainX0) / domainRangeX
      return (1 - proportionalPosition) * plottedWidth_InScreenPx
    }
  }
  else {  // bottom axis NOT inverted
    plt.bottomAxis.mousePxToPlotDomainValue = (Xpx) => {
      const proportionalPosition = Xpx / plottedWidth_InScreenPx
      return domainX0 + proportionalPosition * domainRangeX
    }
    plt.bottomAxis.domainValueToMousePx = (Xdomain) => {
      const proportionalPosition = (Xdomain - domainX0) / domainRangeX
      return proportionalPosition * plottedWidth_InScreenPx
    }
  }
  plt.bottomAxis.mousePxToPlotTickValue = (Xpx) =>
    bottomAxis.reverseNonlinearXform(plt.bottomAxis.mousePxToPlotDomainValue(Xpx));

  var isInvertedLeftScale = leftAxis.isInvertedAxis
  if (leftAxis.willUseLogWithNegativeData) { isInvertedLeftScale = !isInvertedLeftScale }
  if (leftAxis.internalDataType === 'string') { isInvertedLeftScale = !isInvertedLeftScale }
  const domainY0 = leftAxis.domainExtended[0]
  const domainY1 = leftAxis.domainExtended[1]
  const domainRangeY = domainY1 - domainY0
  const plottedHeight_InScreenPx = plt.plotHeightObj.plottedData * plt.plotStyleObj.reactVisScale

  if (isInvertedLeftScale) {
    plt.leftAxis.mousePxToPlotDomainValue = (Ypx) => {
      const proportionalPosition = Ypx / plottedHeight_InScreenPx
      return domainY0 + proportionalPosition * domainRangeY
    }
    plt.leftAxis.domainValueToMousePx = (Ydomain) => {
      const proportionalPosition = (Ydomain - domainY0) / domainRangeY
      return proportionalPosition * plottedHeight_InScreenPx
    }
  }
  else {
    plt.leftAxis.mousePxToPlotDomainValue = (Ypx) => {
      const proportionalPosition = Ypx / plottedHeight_InScreenPx
      return domainY1 - proportionalPosition * domainRangeY
    }
    plt.leftAxis.domainValueToMousePx = (Ydomain) => {
      const proportionalPosition = (Ydomain - domainY0) / domainRangeY
      return (1 - proportionalPosition) * plottedHeight_InScreenPx
    }
  }
  plt.leftAxis.mousePxToPlotTickValue = (Ypx) =>
    leftAxis.reverseNonlinearXform(leftAxis.mousePxToPlotDomainValue(Ypx))

  /*
  //////////////////////////////////////////////////////////////////////////
               Create the delaunayLayersArray;
    These are a subset of the sorted CanvasLayersArray.  This subset ONLY
    includes rendered layers which require a delaunay D3structure, as
    identified by the renderedLayer.hasCrosshairs === true

    Creating this array MUST come after (and retain) the sorted canvas order,
    as delaunay 'find pt' code returns the upper rendered level in case of ties.
  //////////////////////////////////////////////////////////////////////////
  */

  const delaunayLayersArray = []
  for (const thisCanvas of plt.canvasLayersArray) {
    for (const thisRenderedLayer of thisCanvas) {
      if (thisRenderedLayer.hasCrosshairs) {
        // Add the Xfroms to the rendered layer for transforming
        // a plotPt A/B value into mousePx value for Crosshairs.
        // Used to create delaunay D3 data structures.  And used
        // in crosshairs to calculate an accurate position for
        // the green crosshairs placed over a found point.
        // (Delaunay found position rounds to even pixels.
        // And a rounded position is visible as the crosshairs not
        // exactly aligned with center of plotted point.
        // But we can ignore the rounding by just putting the found
        // plotPt A/B values through these two transforms.  This is the
        // same transform as used in delaunay, except we are skipping
        // the rounding done by delaunay code. )
        thisRenderedLayer.leftPlotPtToMousePx =
          (value, sKey) => plt.leftAxis.domainValueToMousePx(
            thisRenderedLayer.getLeft(value, sKey))
        thisRenderedLayer.bottomPlotPtToMousePx =
          (value, sKey) => plt.bottomAxis.domainValueToMousePx(
            thisRenderedLayer.getBottom(value, sKey))
        delaunayLayersArray.push(thisRenderedLayer)
      }
    }
  }
  plt.delaunayLayersArray = delaunayLayersArray
  // Always destroy the delaunay D3 structures (they may or may not exist).
  // Because we assume 'everything' changes the plotted pts location
  // in terms of screen pixel location.  A conservative assumption,
  // but it works because re-creating the D3 structures code is
  // 'efficient enough' (order of ~million plotted points takes
  // less than 100 msec.)
  // Creating the 'up-to-date' D3 structures will NOT be found in
  // the createPlotXyComputedData() function!  The D3 structures are created if/when
  // the mouse enters the plot area (mouse-move event).  Plenty of time
  // before the user positions the cursor over any given point.
  clearDelaunayD3structures()


  //////////////////////////////////////////////////////////////////////////
  // Map bottom and left tickVisValues to Vis horzontal and vertical grids
  //////////////////////////////////////////////////////////////////////////

  if (plt.leftAxis.tickVisValuesLight) {
    plt.horizontalGridLinesLight =
      { tickVisValues: plt.leftAxis.tickVisValuesLight, gridColor: plotLayoutConsts.lightGridColor }
  }
  if (plt.bottomAxis.tickVisValuesLight) {
    plt.verticalGridLinesLight =
      { tickVisValues: plt.bottomAxis.tickVisValuesLight, gridColor: plotLayoutConsts.lightGridColor }
  }
  plt.horizontalGridLines =
    { tickVisValues: plt.leftAxis.tickVisValues, gridColor: plotLayoutConsts.nominalGridColor }
  plt.verticalGridLines =
    { tickVisValues: plt.bottomAxis.tickVisValues, gridColor: plotLayoutConsts.nominalGridColor }

  /*
  //////////////////////////////////////////////////////////////////////

      1) Create Any Series Specific error messages.
      These are the 'red' short messages overlaying the legend text.
      Note the priority of errors based on if/else construction.
      For example, an isUnsetSeriesColKey will also be isNoDataAfterFiltering & isNoDataAfterCulling.
      Hence, isUnsetSeriesColKey is higher priority.
      Any one of these errors prevents a series from being visible in the plot.

      2) At the same time, create the 'potential' full plot error message.
      Probably not used, but easier to create in this loop.

      3) And the logic to determine if every series is non-visible

  //////////////////////////////////////////////////////////////////////
  */

  const indent = '\u00A0'.repeat(16)
  var msg = Array<ErrorMsg>()
  msg.push({ type: 'line', text: indent + 'There is nothing to plot.' })
  var isPlotCompletelyEmpty = true   // Assumption (almost never true!)
  var isUnsetSeriesColKeyMsgAppended = false
  var isDeletedColMsgAppended = false
  var isWrongTypeMsgAppended = false
  var isNoDataMsgAppended = false
  var isOutOfViewMsgAppended = false

  for (let sKey = 0; sKey < plt.seriesAttributesArray.length; sKey++) {
    var thisSeries = plt.seriesAttributesArray[sKey]
    if (thisSeries.isUnsetSeriesColKey) {
      thisSeries.errMsg = 'Unset-Col'
      if (isUnsetSeriesColKeyMsgAppended) { }
      else {
        isUnsetSeriesColKeyMsgAppended = true
        msg.push({ type: 'line', text: '' })
        msg.push({ type: 'line', text: "'Unset-Col' : The table column that defines your" })
        msg.push({ type: 'line', text: "plotted values has not yet been set." })
      }
    } else if (thisSeries.isDeletedCol) {
      thisSeries.errMsg = 'Deleted-Col'
      if (isDeletedColMsgAppended) { }
      else {
        isDeletedColMsgAppended = true
        msg.push({ type: 'line', text: '' })
        msg.push({ type: 'line', text: "'Deleted-Col' : The table column that defines your" })
        msg.push({ type: 'line', text: "plotted values was deleted by the table owner." })
      }
    }
    else if (thisSeries.isDataTypeMismatch) {
      thisSeries.errMsg = 'Wrong-Type'
      if (isWrongTypeMsgAppended) { }
      else {
        isWrongTypeMsgAppended = true
        msg.push({ type: 'line', text: '' })
        msg.push({ type: 'line', text: "'Wrong-Type' : The data type (string, number,...)" })
        msg.push({ type: 'line', text: "does not match the first series data type." })
        if (plotColDataType === '3Col') {
          msg.push({ type: 'line', text: "Or data sorting column is not a 'number'." })
        }
      }
    }
    else if (thisSeries.isNoData) {
      thisSeries.errMsg = 'No-Data'
      if (isNoDataMsgAppended) { }
      else {
        isNoDataMsgAppended = true
        msg.push({ type: 'line', text: '' })
        msg.push({ type: 'line', text: "'No-Data' : Your filters have eliminated all data." })
      }
    }
    else if (isOutOfViewSeries(thisSeries, plt, sKey)) {
      thisSeries.errMsg = 'Out-of-View'
      if (isOutOfViewMsgAppended) { }
      else {
        isOutOfViewMsgAppended = true
        msg.push({ type: 'line', text: '' })
        msg.push({ type: 'line', text: "'Out-of-View' : All plotted data is beyond your" })
        msg.push({ type: 'line', text: "user defined axis limits. Set your axis limits" })
        msg.push({ type: 'line', text: "to 'auto' or some larger range." })
      }
    }
    else {
      // This plot has at least one visible series to render.
      isPlotCompletelyEmpty = false
    }
  }

  // Coder error if the canvasLayersArray is empty!!
  // Plot MAY or MAY not be completely empty at this point.
  // However, if isPlotCompletelyEmpty === false AND there
  // are no canvasLayers to render, then this must be some
  // internal coding error.
  if (plt.canvasLayersArray.length === 0 && !isPlotCompletelyEmpty) {
    msg = []
    msg.push({ type: 'line', text: "'Coding Err' : canvasLayersArray is empty." })
    isPlotCompletelyEmpty = true
  }

  plt.fullPlotLevelErrMessage = (isPlotCompletelyEmpty) ? msg : []

  logTime('id_PlotXyComputedData', 'Exiting undatePlotXyComputedData function')
  stopTimer('id_PlotXyComputedData', false)
  //if (DEBUG) {
  //  console.log( '  Final PlotXyComputedData', plt)
  //}

  return { plt }
}







const initializeBasisObj = (basis: PlotXyComputedAxis, isHisto: boolean, isPercentile: boolean): void => {
  const { internalDataType } = basis
  const isUsersMinDomainLegal = !(basis.usersMinDomain === '' || isNaN(Number(basis.usersMinDomain)))
  const isUsersMaxDomainLegal = !(basis.usersMaxDomain === '' || isNaN(Number(basis.usersMaxDomain)))
  basis.legalUsersMinDomain = isUsersMinDomainLegal ? Number(basis.usersMinDomain) : 0
  basis.legalUsersMaxDomain = isUsersMaxDomainLegal ? Number(basis.usersMaxDomain) : 0
  // isM..Forced is true IFF (value is legal && user is NOT using autoDomain)
  basis.isMinForced = (!basis.isMinDomainAuto && isUsersMinDomainLegal)
  basis.isMaxForced = (!basis.isMaxDomainAuto && isUsersMaxDomainLegal)
  // m..Constraints only have a value when domain is forced!
  var minConstraint = (basis.isMinForced) ? basis.legalUsersMinDomain : 0
  var maxConstraint = (basis.isMaxForced) ? basis.legalUsersMaxDomain : 0
  if (internalDataType === 'string') {
    // ALL string axis are forced domain (min and max), and constrained
    // to fall within limits of 1 to 'n'.
    var maxLimit = (basis.stringTextToIndex_sortedKeys) ? basis.stringTextToIndex_sortedKeys.length : 1
    if (basis.isMinForced &&
      (minConstraint < 1 || minConstraint > maxLimit)) { minConstraint = 1 }
    if (basis.isMaxForced &&
      (maxConstraint < 1 || maxConstraint > maxLimit)) { maxConstraint = maxLimit }
    // Ff the user has NOT given any constraints we add our own.
    // This tells smartAxis to put hard limits at the edge of the domain.
    if (!basis.isMinForced) { basis.isMinForced = true; minConstraint = 1 }
    if (!basis.isMaxForced) { basis.isMaxForced = true; maxConstraint = maxLimit }
  }
  // Now we have consistent 'legal' values and consistent isForced flags.
  // Time to invert the axis if requested
  const bothConstraintsForced = basis.isMinForced && basis.isMaxForced
  if (bothConstraintsForced && minConstraint > maxConstraint) {
    basis.isInvertedAxis = true
    // Swap min/max constraints!
    var temp = minConstraint
    minConstraint = maxConstraint
    maxConstraint = temp
  }
  // Handle case of numberDataType and both constraints defined but equal.
  // Common case when 'flipping' the axis direction.
  // String axis is OK with this.  But numberAxis needs a non-zero range.
  // There is no single right answer.  Best not to even let poor man's editor save this state.
  // However, the logic gets to be too convoluted to understand.  So we'll force
  // some non-dumping solution here.
  // We will force the min value, and 'unforce' the max value.  (?? any better rule)
  // The poor man's editor shows the userLimits in 'red'.
  if (internalDataType === 'number' && bothConstraintsForced && minConstraint === maxConstraint) {
    basis.isMaxDomainAuto = true
    basis.isMaxForced = false
  }
  basis.rangeCulledAllSeries = { ...basis.rangeFilteredAllSeries }   // assumption
  if (basis.isMinForced) { basis.rangeCulledAllSeries.min = minConstraint }
  if (basis.isMaxForced) { basis.rangeCulledAllSeries.max = maxConstraint }
  basis.doesRangeFilteredIncludeZero =
    (basis.rangeFilteredAllSeries.min * basis.rangeFilteredAllSeries.max <= 0) ||
    (basis.rangeCulledAllSeries.min * basis.rangeCulledAllSeries.max <= 0)
  // Use Logarithmic Scale ?  We may or may not support the user's request!
  basis.willUseLogarithmicScale = basis.isLogarithmic && !basis.doesRangeFilteredIncludeZero  // assumption
  const isBasisB = (basis.basisPath === 'basisB')
  if (isHisto && isBasisB) {  // Exception - We allow log scale on freq histograms. (special code for known zeros)
    basis.willUseLogarithmicScale = basis.isLogarithmic
  }
  if (isPercentile && isBasisB) { // Exception - log scale for percentile plots makes no sense
    basis.willUseLogarithmicScale = false
  }
  basis.nonlinearXform = (x) => x  // assumption (linear mapping)
  basis.reverseNonlinearXform = (x) => x  // assumption
  if (basis.willUseLogarithmicScale) {
    basis.nonlinearXform = (x) => Math.log10(Math.abs(x))
    basis.reverseNonlinearXform = (x) => Math.pow(10, x)  // the inverse
  }
  basis.willUseLogWithNegativeData = (basis.willUseLogarithmicScale && basis.rangeCulledAllSeries.min < 0)
  return
}



const createCanvasLayersArray = (reactLayersArr: ReactLayer[]): ReactLayer[][] => {
  // These are the currently defined canvas layers.
  //      'bottom',     opaque features; vistypes area and bars
  //      'rows'        row tables values plot as marks, line, or marksLine
  //      'statLines'   (mean, sum, freq, ...)
  //      'linFit'      linear fitted lines
  //      'smthFit'     smth lines and or curved best statistical fits.
  //      'pinnedRows'  'star' marker superimposed over the underlying 'row' marker.
  // The six canvas layers will be returned in an array ordered from bottom to top:
  // [ [], [], [], [], [], [] ]
  // The layer names are no longer used, just the positioning order.
  // Dense 'rows' canvas MAY be split into multiple canvas (worse case one per series).
  // Likewise, we reserve the option (not currently being used) to combine sparse canvas
  // laters (e.g. combine canvases for linFit and smthFit)

  // Within each canvas, react layers are sorted by sOrderIndex, and secondary sort
  // using layeringOrder.
  let returnArr: ReactLayer[][] = [[], [], [], [], [], []]
  for (const thisReactLayer of reactLayersArr) {
    switch (thisReactLayer.canvasLayeringSet) {
      case 'bottom': { returnArr[0].push(thisReactLayer); break }
      case 'rows': { returnArr[1].push(thisReactLayer); break }
      case 'statLines': { returnArr[2].push(thisReactLayer); break }
      case 'linFit': { returnArr[3].push(thisReactLayer); break }
      case 'smthFit': { returnArr[4].push(thisReactLayer); break }
      case 'pinnedRows': { returnArr[5].push(thisReactLayer); break }
      default:
        invariant(false, `Unrecognized canvasLayeringSet of ${thisReactLayer.canvasLayeringSet}`)
    }
  }

  for (const thisCanvas of returnArr) {
    thisCanvas.sort((a, b) => {
      // Primary sort: seriesOrder
      if (a.sOrderIndex < b.sOrderIndex) return -1
      if (a.sOrderIndex > b.sOrderIndex) return 1

      // SeondarySort: layeringOrder
      if (a.layeringOrder < b.layeringOrder) return -1
      if (a.layeringOrder > b.layeringOrder) return 1
      return 0
    })
  }

  // If there are no row pts to render, then early return:
  if (returnArr[1].length === 0) {
    return returnArr
  }
  // Otherwise:
  // Break a potentially dense row canvas into multiple canvas:
  // Easier to assume they will all be separate, and
  // then combine a sequential set of row canvas'es until
  // the density exceeds a fixed defined limit.
  let newReturnArr = [returnArr[0]]
  let remainingRowLayers = returnArr[1].slice()
  // This function breaks off group of row reactLayers, such that
  // the group consist of the first set of reactLayers that
  // exceed some arbitray MAX number of plotPts per row canvas.
  const MAX_PTS_PER_CANVAS = 2000
  const getNextMaxSizedGroup = () => {
    let totalPts = 0
    for (let i = 0; i < remainingRowLayers.length; i++) {
      totalPts += remainingRowLayers[i].plotPts.length
      if (totalPts < MAX_PTS_PER_CANVAS) {
        continue
      }
      const thisSet = remainingRowLayers.slice(0, i + 1)
      remainingRowLayers = remainingRowLayers.slice(i + 1)
      return thisSet
    }
    // For the last set, we  return layers that where NOT previously
    // included in a 2000+ sized group.  For non-dense plots, this
    // will be ALL the row layers.
    var lastSet = remainingRowLayers.slice()
    remainingRowLayers = []
    return lastSet
  }
  // Push one or more 'row Canvas sets back onto newReturnVal.
  while (remainingRowLayers.length > 0) {
    newReturnArr.push(getNextMaxSizedGroup())
  }
  // Put the the last four canvas layers back onto the stack.
  newReturnArr = newReturnArr.concat(returnArr.slice(2))
  return newReturnArr
}



const getMinMaxB_bySeries = (canvasLayersArray: ReactLayer[][], numSeries: number): { minB_bySeries: number[], maxB_bySeries: number[] } => {
  const minB_bySeries = Array(numSeries).fill(+Infinity)
  const maxB_bySeries = Array(numSeries).fill(-Infinity)
  canvasLayersArray.forEach(thisCanvas => {
    thisCanvas.forEach(thisLayer => {
      if (!thisLayer.isRendered) return   // skip non-rendered layers
      let { sKey } = thisLayer
      minB_bySeries[sKey] = Math.min(minB_bySeries[sKey], thisLayer.minB)
      maxB_bySeries[sKey] = Math.max(maxB_bySeries[sKey], thisLayer.maxB)
    })
  })
  return { minB_bySeries, maxB_bySeries }
}

const isOutOfViewSeries = (thisSeries: PlotXyComputedSeriesAttributes, plt: PlotXyComputedData, sKey: number): boolean => {

  // Most common and simplest case is basisA test:
  // IF culledRange in A does not intersect the basisA domain,
  // THEN must be out-of-view, including plotPts, linFit, and smthFits
  var { domainConstrained, willUseLogarithmicScale, willUseLogWithNegativeData } = plt.basisA
  var plottedMinA = domainConstrained[0]
  var plottedMaxA = domainConstrained[1]
  if (willUseLogarithmicScale) {
    plottedMinA = Math.pow(10, plottedMinA)
    plottedMaxA = Math.pow(10, plottedMaxA)
  }

  var { maxAculled, minAculled } = thisSeries.filteredPtsStats
  if (willUseLogWithNegativeData) {
    // Flip and negate the min/max
    const temp = maxAculled
    maxAculled = - minAculled
    minAculled = - temp
  }
  if (maxAculled < plottedMinA) return true
  if (minAculled > plottedMaxA) return true

  // Otherwise we need to ask whether all 'B' values fall outside of the domain.
  // Save this task for later, after Rev#3 plot interface is designed.
  return false


}
