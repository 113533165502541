import { ScryUIView } from '../types'

export type FloatingPaletteLayoutObj = {
  floatingPaletteLeft: number,
  floatingPaletteTop: number,
  floatingPaletteWidth: number,   // these two values come from the onSize( ) function.
  floatingPaletteHeight: number,
  cellLeft: number,
  cellTop: number,
  cellWidth: number,
  cellHeight: number,
  highlightLine: string,
  activeEditor: string,
  selectionName: string,

  minorStateRowIndex: number,   // For table selections
  minorStateColIndex: number,
  minorStateRowKey: number,
  minorStateColKey: number,

  minorStateSeriesKey: number,  // For XYPlot selections

  minorStateWidth: number | null,     // these two come from the minor state and 'force' a width/height.
  minorStateHeight: number | null,

  minWidth: number,
  maxWidth: number,
  minHeight: number,
  maxHeight: number,

  // The space we have to work with when we apply position constraints.
  sideBarWidth: number,
  availableWidthIncludingSideBar: number,
  availableHeight: number,

  view: ScryUIView,
}

export const floatingPaletteLayoutDefault: FloatingPaletteLayoutObj = {
  floatingPaletteLeft: 0,
  floatingPaletteTop: 0,
  floatingPaletteWidth: 0,   // these two values come from the onSize( ) function.
  floatingPaletteHeight: 0,
  cellLeft: 0,
  cellTop: 0,
  cellWidth: 0,
  cellHeight: 0,
  highlightLine: '',
  activeEditor: '',
  selectionName: '',

  minorStateRowIndex: 0,
  minorStateColIndex: 0,
  minorStateSeriesKey: -1,
  minorStateRowKey: -1,
  minorStateColKey: -1,

  // these two come from the minor state and 'force' the editor size.  onSize function is ignored.
  minorStateWidth: null,
  minorStateHeight: null,

  // These four put constraints on the above two size dimensions.
  minWidth: 0,
  maxWidth: 0,
  minHeight: 0,
  maxHeight: 0,

  sideBarWidth: 0,
  availableWidthIncludingSideBar: 0,
  availableHeight: 0,

  view: 'tableView',
}
