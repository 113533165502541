import type { JSX } from 'react'
import type { Plot, Table } from '../types'
import type { PlotXyComputedData } from '../computedDataPlotXY/xy_plotTypes'
import { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import constants, { SCRY_DARK_BLUE, SCRY_WHITE, NAV_COLUMN_WIDTH, TOP_MENUBAR_HEIGHT } from '../sharedComponents/constants'
import SVGdatatablesLogo from '../SVGs/SVGdatatablesLogo'
import SVGwrapper2 from '../SVGs/SVGwrapper2'
import NavColButton from './NavColButton'
import NavColLink from './NavColLink'
import PlotsList from './PlotsList'

/*
  SUMMARY OF WHAT NAV COLUMN MENU ITEMS ARE DISPLAYED WHEN -

  Currently there are eight NavColumn items (internal links or buttons)
  Five can be 'active', meaning currently selected, showing a grey background.
            - DataTables icon in upper left
            - Search
            - Home
            - Active Table
            - Active Plot
  Three are never active, but initiate popup 'action'
            - CreateTable
            - CreatePlot
            - Download (Table or Plot, depending on the current active view)


  Rules (Which NavColumn menu items displayed when):
    DataTables - always
    Search - always
    Home - always and CreateTable are always displayed.
    ActiveTable - is available and table resources available, otherwise equivalent blank space.
    CreateTable - always
    CreatePlot  - If current view is activetable or Plot
    DownloadTable - If current view is activetable and table resources available.
    DownloadPlot  - If current view is Plot and both table and plot resources available
    DeletePlot  - If current view is Plot and both table and plot resources available
    PlotsLists -  table available, and PlotsList length > 0.

*/

const logoWidth = 24
const logoHeight = 22
const rightBlackBorderWidth = 2
const dataTablesWidth = NAV_COLUMN_WIDTH - logoWidth
const logoLargeCaseFontSize = 26
const logoSmallCaseScale = '90%'
const dataTablesLogo: JSX.Element =
  <span
    className={'rc_NavColumn'}
    style={{
      width: '100%',
      color: 'black',
      fontWeight: 'bold',
      paddingLeft: 12,
      paddingTop: (TOP_MENUBAR_HEIGHT - logoLargeCaseFontSize) / 2 - 1,
      fontFamily: constants.FONT_FAMILY_LOGO_FORMAT,
      fontSize: logoLargeCaseFontSize
    }}
  >
    {'D'}
    <span style={{ fontSize: logoSmallCaseScale }}>
      {'ata'}
    </span>

    <span style={{ position: 'relative', left: '-0.1em' }}>
      {'T'}
    </span>
    <span style={{ fontSize: logoSmallCaseScale, position: 'relative', left: '-0.2em' }}>
      {'ables'}
    </span>
  </span>


// Includes popups from both the NavColumn and the TopMenuBar
export type PopupName = '' | 'createPlot' | 'createTable' | 'downloadPlot' | 'deletePlot' | 'downloadTable' | 'rowFiltering'


type OwnProps = {
  appState: any
  table: Table | null
  plotid: string
  plot: Plot | null
  plotXyComputedData: PlotXyComputedData | null
  userid: string
  tableOwnerid: string
  view: string
  // TablesParent handles open/close state of the Nav Column popups.
  // However, NavColumn owns the menu items that 'enable' the popups.
  setActivePopup: (popup: PopupName) => void
  closePopup: () => void

  tableOwnerPlotIds: string[]
  userPlotIds: string[]
}


class NavColumn extends PureComponent<OwnProps> {

  setCreatePlotActive = (): void => {
    this.props.closePopup();
    this.props.setActivePopup('createPlot')
  }

  setCreateTableActive = (): void => {
    this.props.closePopup();
    this.props.setActivePopup('createTable')
  }

  setDownloadPlotActive = (): void => {
    this.props.closePopup();
    this.props.setActivePopup('downloadPlot')
  }

  setDeletePlotActive = (): void => {
    this.props.closePopup();
    this.props.setActivePopup('deletePlot')
  }

  setDownloadTableActive = (): void => {
    this.props.closePopup();
    this.props.setActivePopup('downloadTable')
  }

  render() {
    const { table, plotid, view, plot, plotXyComputedData, tableOwnerid, userid,
      tableOwnerPlotIds, userPlotIds/*, appState*/ } = this.props
    const isTableResourcesAvailable = (!!table) // && !!tablelook && !!tabledata )
    const isPlotResourcesAvailable = (!!plot && !!plotXyComputedData)
    const isCreatePlotDisplayed =
      (view === 'tableView' && isTableResourcesAvailable) ||
      (view === 'xyPlotView' && isPlotResourcesAvailable)
    const isDownloadPlotDisplayed = (view === 'xyPlotView' && isPlotResourcesAvailable)
    const isDeletePlotDisplayed = (view === 'xyPlotView' && isPlotResourcesAvailable)
    const isDownloadTableDisplayed = (view === 'tableView' && isTableResourcesAvailable)
    const isPlotsListDisplayed = (!!table)  // Internal component does not render if no plots
    return (
      <div className={'NavColumn'}
        style={{
          position: 'absolute',
          left: 0,
          top: 0,
          width: NAV_COLUMN_WIDTH,
          height: '100%',
          backgroundColor: SCRY_DARK_BLUE,
          borderColor: 'black',
          borderStyle: 'solid',
          borderWidth: `0px ${rightBlackBorderWidth}px 0px 0px`,
          color: 'rgba(255,255,255,0.75)',
          display: 'flex',
          flexFlow: 'column nowrap',
          flexShrink: 0,
        }}
      >

        {/* Logo in upper left menu item */}
        <div className={'DataTablesUpperLeft'}
          onClick={this.props.closePopup}
          style={{
            width: NAV_COLUMN_WIDTH - rightBlackBorderWidth,
            height: TOP_MENUBAR_HEIGHT - 1,
            boxSizing: 'border-box',
            borderColor: 'black',
            borderStyle: 'solid',
            borderWidth: `0px 0px 1px 0px`,
          }}
        >

          <Link to={'/sitePage'}
            style={{
              width: '100%',
              height: '100%',
              paddingLeft: 10,
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'nowrap',
              textDecoration: 'none',
              background: (view === 'sitePage') ? 'lightgrey' : SCRY_WHITE,
            }}>
            <div
              style={{
                display: 'flex',
                width: logoWidth,
                paddingTop: (TOP_MENUBAR_HEIGHT - logoHeight) / 2,
              }}>
              <SVGwrapper2 >
                <SVGdatatablesLogo
                  height={TOP_MENUBAR_HEIGHT}
                  width={logoWidth}
                />
              </SVGwrapper2>
            </div>

            <div
              style={{
                display: 'flex',
                width: dataTablesWidth,
              }}
            >
              {dataTablesLogo}
            </div>

          </Link>
        </div>

        {/* Using marginTop, not height for spacers.
            Because browser may recalc the different height
            to optimize its internal line spacing.  */}

        {/* Search menu item */}
        <div
          className={'spacer1'}
          style={{
            width: '100%',
            marginTop: 16
          }}
        />
        <NavColLink
          closePopup={this.props.closePopup}
          isSelected={view === 'searchView'}
          url='/search'
        >
          {`Search (\u00A0all tables)`}
        </NavColLink>

        {/* Home menu item */}
        {false ? (
          <>
            <NavColLink
              isSelected={view === 'home'}
              closePopup={this.props.closePopup}
              url='/home'
            >
              {'Home\u00A0\u00A0\u00A0(my\u00A0tables)'}
            </NavColLink>
          </>
        ) : null}

        <div
          className={'spacer1'}
          style={{
            width: '100%',
            marginTop: 24
          }}
        />

        {/* The ActiveTable menu item: */}
        {(!!table && isTableResourcesAvailable)

          ?
          <NavColLink
            isSelected={view === 'tableView'}
            url={`/table/${table ? table.id : ''}`}
            closePopup={this.props.closePopup}
          >
            <div>Active Table</div>
            <div className={'activeTable_Title'}
              style={{
                marginLeft: 8,
                fontWeight: 'normal',
                fontSize: 16,
                fontFamily: 'serif',
                width: NAV_COLUMN_WIDTH - 40,
                overflow: 'hidden',
                whiteSpace: 'nowrap'
              }}
            >
              {table ? table.attributes.tableTitle : ''}
            </div>
          </NavColLink>

          :
          <div
            style={{
              paddingTop: 8,
              paddingBottom: 8,
              visibility: 'hidden'
            }}
          >
            <div>Active Table</div>
            <div className={'activeTable_Title'}
              style={{
                marginLeft: 8,
                fontWeight: 'normal',
                fontSize: 16,
                fontFamily: 'serif',
                width: NAV_COLUMN_WIDTH - 40,
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
            >
              {'text'}
            </div>
          </div>

        }


        {/* Create Private Table -- always displayed.  */}
        <div
          className={'spacer2'}
          style={{
            width: '100%',
            marginTop: 30
          }}
        />
        <NavColButton onClick={this.setCreateTableActive} >
          {'Create Private Table'}
        </NavColButton>

        {/* Create Private Plot */}
        {isCreatePlotDisplayed &&
          <NavColButton onClick={this.setCreatePlotActive} >
            {'Create Private Plot'}
          </NavColButton>
        }

        {/* Download Plot */}
        {isDownloadPlotDisplayed &&
          <NavColButton onClick={this.setDownloadPlotActive}>
            {'Download Plot'}
          </NavColButton>
        }

        {/* Delete Plot */}
        {isDeletePlotDisplayed &&
          <NavColButton onClick={this.setDeletePlotActive}>
            {'Delete Plot'}
          </NavColButton>
        }

        {/* Download Table */}
        {isDownloadTableDisplayed &&
          <NavColButton onClick={this.setDownloadTableActive}>
            {'Download Table'}
          </NavColButton>
        }

        <div
          className={'spacer3'}
          style={{
            width: '100%',
            marginTop: 20
          }}
        />

        {/* Column of available Plots */}
        {isPlotsListDisplayed &&
          <PlotsList
            tableid={table ? table.id : ''}
            currentPlotid={plotid}
            closePopup={this.props.closePopup}
            tableOwnerid={tableOwnerid}
            userid={userid}
            tableOwnerPlotIds={tableOwnerPlotIds}
            userPlotIds={userPlotIds}
          />
        }

      </div>
    )
  }
}

export default NavColumn
