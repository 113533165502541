import { list } from 'radash'
import React, { Component, PureComponent } from 'react'
import type { ReactNode } from 'react'
import createTapListener from 'teeny-tap'
import rStateFloatingPalette from '../floatingPalette/rStateFloatingPalette'
import SVGselectCaret from '../SVGs/SVGselectCaret'
import SVGwrapper2 from '../SVGs/SVGwrapper2'
import constants from './constants'
import getPathInTapListener from './getPathInTapListener'
import reactDispatch from './reactDispatch'

type TapListener = any

// We save resource colors as hex values. (e.g. seriesColor in plots)
// However, not often, but occasionally, we need to pick a contrasting color.
// For example:
//    The outline of a smoothly fitted line is black.  The purpose
//    of the outline is to make the fitted line visible, even if/when it crosses
//    a background of dense plotPts of the same color.
//    Hence, the smoothed fitted line of a black series should NOT use a black outline!
//    So my code reads:  if (series color is 'Black' or 'Navy') then use 'red' outline for smoothly fitted line.
// This type of logic only works if the set of usable hex colors are enumerated (like above).
// But since these hex colors are saved in the resource, it doesn't allow us to tweak
// the colors without also updating all the current plot resources.
// This function solves the problem.  We can tweak the hex colors, independent of
// finding the closest enumerated 'namedColor'.
export const findClosestNamedColor = ( hexColorInput: string, shouldIncludeBlackWhite:boolean=false ) :
        {colorIndex: number, colorTitle:string, value:string, fontColor:string} => {

  if ( hexColorInput === undefined ) { debugger }

  var testColors = distinctColors   // Assumption
  if (shouldIncludeBlackWhite) {
    // Add black and white to the test Colors.
    // May be redundant with list that already exists, but that's OK
    const newColors = [
     {colorTitle: 'Black', value: '#000000', fontColor: 'white'},
     {colorTitle: 'Black', value: '#000000', fontColor: 'white'}
    ]
    testColors = distinctColors.concat(newColors)

  }

  // For getting out of bad resource states:
  if (hexColorInput[0] !== '#') {
    return {colorIndex:1, ...testColors[1]}
  }

  var colorIndex = distinctColorValuesArray.indexOf( hexColorInput )
  if ( colorIndex >= 0 ) {
    return {colorIndex, ...testColors[colorIndex] }
  }

  // Else, no identical match was found.  Find the closest available match.
  // Define 'closest' as the distinctColor (above) that is 'root mean squared' smallest
  // distance (in 3-D rgb space) to the hexColorInput
  //console.log( `Color ${hexColorInput} not found; finding closest available color.` )

  var hexValue = hexColorInput.slice(1)  // strip leading '#'
  // Convert a base16 string 'hexValue', to a base10 integer.
  var bigint = parseInt(hexValue, 16)
  // shift bigint (rgb) by 16 bits, so first eight bits (before decimal) are the 'red' bits.
  // 'and' with 255 (8bits) so we capture only first eight red bits.
  var rIn = (bigint >> 16) & 255
  // shift rgb by 8 bits, so first eight bits are the 'green' bits.
  // 'and' with 255 (8bits) so we capture only first eight green bits.
  var gIn = (bigint >> 8)  & 255
  // No need to shift; first eight bits are the blue bits.
  // 'and' with 255 (8bits) so we capture only first eight blue bits.
  var bIn = bigint & 255

  var smallestDistanceSqrd = +Infinity
  var closestColorIndex = -1
  testColors.forEach( (obj,i) => {
    hexValue = obj.value.slice(1)
    bigint = parseInt(hexValue, 16)
    var rNamed = (bigint >> 16) & 255
    var gNamed = (bigint >> 8) & 255
    var bNamed = bigint & 255
    var newDistanceSqrd = (rNamed - rIn)**2 + (gNamed - gIn)**2 + (bNamed - bIn)**2
    if (newDistanceSqrd < smallestDistanceSqrd ) {
      smallestDistanceSqrd = newDistanceSqrd
      closestColorIndex = i
    }
  })

  //console.log( `Color ${hexColorInput} not found; ` +
  //  `Closest available color is ${testColors[closestColorIndex].value}` )

  return {colorIndex:closestColorIndex, ...testColors[closestColorIndex] }
}




// MUST be >= length 15 or code will dump!
export const distinctColors = [
  {colorTitle: 'Red', value: '#e6194b', fontColor: 'white'},
  {colorTitle: 'Blue', value: '#0082c8', fontColor: 'white'},
  {colorTitle: 'Yellow', value: '#ffe119', fontColor: 'black'},
  {colorTitle: 'Green', value: '#2ca43b', fontColor: 'white'},
  {colorTitle: 'Orange', value: '#f58231', fontColor: 'black'},
  {colorTitle: 'Purple', value: '#911eb4', fontColor: 'white'},
  {colorTitle: 'Cyan', value: '#46f0f0', fontColor: 'black'},
  //{colorTitle: 'Magenta', value: '#f032e6', fontColor: 'black'},  // magenta or pink; not both
  //{colorTitle: 'Lime', value: '#d2f53c', fontColor: 'black'},
//{colorTitle: 'Lavender', value: '#c67ebf', fontColor: 'black'},  // Name too long.
  //{colorTitle: 'Copper', value: '#704838', fontColor: 'white'},
  {colorTitle: 'Maroon', value: '#800000', fontColor: 'white'},
  {colorTitle: 'Olive', value: '#809000', fontColor: 'white'},
  {colorTitle: 'Teal', value: '#005050', fontColor: 'white'},
  {colorTitle: 'Beige', value: '#afaa88', fontColor: 'black'},
//{colorTitle: 'Mint', value: '#88ddb3', fontColor: 'black'},   // too pale
//{colorTitle: 'Coral', value: '#ffd8b1', fontColor: 'black'},  // too pale
  {colorTitle: 'Pink', value: '#f508ae', fontColor: 'white'},
  {colorTitle: 'Navy', value: '#000080', fontColor: 'white'},
//  {colorTitle: 'Brown', value: '#8a6724', fontColor: 'white'},
  {colorTitle: 'Violet', value: '#9B26B6', fontColor: 'white'},
  {colorTitle: 'Black', value: '#000000', fontColor: 'white'},
]
const distinctColorValuesArray = distinctColors.map( obj=> obj.value )
const distinctSizes  = [ 0.5,1,2,3,4]
const distinctSizesAsStrings = ['½pt', '1pt', '2pt', '3pt', '4pt' ]
const distinctShapes = ['square', 'diamond', 'plus', 'triangle', 'circle']

const MENU_ITEM_HEIGHT = 24
const MENU_ITEM_WIDTH_ShapeSelector  = 48
const MENU_ITEM_WIDTH_ColorSelector  = 60

const MENU_ITEM_SHAPE_WIDTH = 19
const MENU_ITEM_TEXT_WIDTH = MENU_ITEM_WIDTH_ShapeSelector - MENU_ITEM_SHAPE_WIDTH
const FONT_SIZE = 14

const EXIT_CONTAINER_HEIGHT = 32
const EXIT_BUTTON_HEIGHT = 20
const EXIT_BUTTON_WIDTH = 78

const numShapes = distinctShapes.length
const numSizes  = distinctSizes.length
const NUM_COLOR_ROWS = 5
const NUM_COLOR_COLS = 3

// I use the same height for BOTH Shape and Color selectors.
// Although the menus will have differing widths.
const MENU_HEIGHT_ShapeSelector  = MENU_ITEM_HEIGHT*numSizes
                         + 2*constants.CUSTOM_SELECT_POPUP_MENU_OUTLINE.borderWidth
                         + EXIT_CONTAINER_HEIGHT

const MENU_WIDTH_ShapeSelector = MENU_ITEM_WIDTH_ShapeSelector * numShapes
                         + 2*constants.CUSTOM_SELECT_POPUP_MENU_OUTLINE.borderWidth
const MENU_WIDTH_ColorSelector = MENU_ITEM_WIDTH_ColorSelector * NUM_COLOR_COLS
                        + 2*constants.CUSTOM_SELECT_POPUP_MENU_OUTLINE.borderWidth

const MENU_LEFT_POSITION_ShapeSelector = 3
const MENU_LEFT_POSITION_ColorSelector = - MENU_WIDTH_ColorSelector - 3


type OwnProps = {
  mode:  'shapeSelector' | 'colorSelector',
  selectedShape: string,
  selectedSize: number,
  selectedColor: string,
  seriesKey: number,
  width: number,
  height:number,
}
type LocalState = {
  isActive:boolean,
}

class ShapeAndColorPicker extends Component<OwnProps, LocalState> {

  static defaultProps = {
    selectedShape: 'diamond',
    selectedSize : 2,
    selectedColor: '#0082c8', //'Blue'
  }

  state = {
    isActive: false,
  }

  tapListener: TapListener | null = null

  componentDidUpdate() {
    if (!this.tapListener && rStateFloatingPalette.floatingPaletteDomNode) {
      this.tapListener = createTapListener(rStateFloatingPalette.floatingPaletteDomNode, this.handleTap)
    }
  }
  componentWillUnmount() {
    if (this.tapListener) {
      this.tapListener.remove()
      this.tapListener = null
    }
  }

  exitMenu   = ( ) => { this.setState({isActive:false}) }
  toggleMenu = ( ) => { this.setState({isActive: !this.state.isActive}) }
  handleTap  = ( e: React.MouseEvent<HTMLDivElement> ) => {
    const path = getPathInTapListener(e)
    const isTapShapeOrColorPicker = path.some( thisElement => {
      return ( thisElement === 'rc_ShapeAndColorPicker' )
    })
    //console.log( 'Call to isTapShapeOrColorPicker', isTapShapeOrColorPicker )
    if ( !isTapShapeOrColorPicker ) { this.exitMenu() }
  }



  getMenuItemHTML = (selectionColIndex:number, selectionRowIndex:number) : ReactNode => {
    if ( this.props.mode === 'shapeSelector') {
      return this.getShapeItemHTML( selectionColIndex, selectionRowIndex )
    } else {
      return this.getColorItemHTML( selectionColIndex, selectionRowIndex )
    }
  }

  getSelectorHTML = (selectionColIndex:number, selectionRowIndex:number) : ReactNode => {
    if ( this.props.mode === 'shapeSelector') {
       let isSelectorDisplay = true
       return this.getShapeItemHTML(selectionColIndex, selectionRowIndex, isSelectorDisplay)
    } else {
      return this.getColorSelectorHTML( selectionColIndex, selectionRowIndex )
    }
  }

  getColorSelectorHTML = (selectionColIndex:number, selectionRowIndex:number) : ReactNode => {
    const colorIndex = selectionColIndex * NUM_COLOR_ROWS + selectionRowIndex
    const {value} = distinctColors[colorIndex]
    // Size will be 4px smaller than the available selector height
    const availableHeight = this.props.height - 4
    const shrunkSize = .8 * availableHeight
    const topOffset = (availableHeight - shrunkSize)/2
    return (
      <div className={'colorContainer'}
        onClick = { ()=>this.onColorItemSelection(value) }
        style={{ position:'relative', top: topOffset, left:0, //
          background: value,
          width:shrunkSize, height:shrunkSize,
        }}/>
    )
  }


  getColorItemHTML = (selectionColIndex:number, selectionRowIndex:number) : ReactNode => {
    const colorIndex = selectionColIndex * NUM_COLOR_ROWS + selectionRowIndex
    const {colorTitle, value, fontColor} = distinctColors[colorIndex]
    return (
      <div className={'menuOption'}
        onClick = { ()=>this.onColorItemSelection(value) }
        style={{ position:'relative', top:0,left:0, // local Coords
          background: value,
          width:'100%', height:'100%',
          color: fontColor, textAlign: 'center',
          paddingTop: (MENU_ITEM_HEIGHT - FONT_SIZE) / 2 - 1,
          borderWidth:0,
         }}
      >
        {colorTitle}
      </div>
  )}

  getShapeItemHTML = (shapeIndex:number, sizeIndex:number, isSelectorDisplay:boolean=false) : ReactNode => {
    const shape = distinctShapes[ shapeIndex ]
    const size  = distinctSizes[ sizeIndex ]
    const sizeString = distinctSizesAsStrings[ sizeIndex ]
    var   borderWidth = (shapeIndex === numShapes - 1)
      ? '0px 0px 1px 0px'
      : '0px 1px 1px 0px'

    // We use the same HTML for displaying the string in the dropDown Selector control (one with the caret)
    // But we need somewhat different centering and border characteristics for placement within the control
    if ( isSelectorDisplay ) {
      borderWidth = '0'
      var top = -3
      var left = -5
    } else {
      top = 0
      left = 0
    }


    return (
      <div className={'menuOption'}
        onClick = { ()=>this.onShapeItemSelection(shape, size) }
        style={{ position:'relative', top, left, // local Coords
          //background: 'pink',
          width:'100%', height:'100%',
          borderWidth, borderColor:'#888888', borderStyle: 'solid',
         }}
      >
        <div className={'shapeChar'}
          style={{
            position:'absolute', left:2 , top:(MENU_ITEM_HEIGHT-1-MENU_ITEM_SHAPE_WIDTH)/2+1,
            width:MENU_ITEM_SHAPE_WIDTH, height:MENU_ITEM_SHAPE_WIDTH,
            //background: 'pink',
          }}>
            {this.getShape( shape, size )}
        </div>
        <div className={'sizeText'}
          style={{
            position:'absolute', top:0,left:MENU_ITEM_SHAPE_WIDTH,
            display: 'inline-block',
            width:MENU_ITEM_TEXT_WIDTH-1, height:MENU_ITEM_HEIGHT-1,
            //background: 'orange',
            paddingTop: (MENU_ITEM_HEIGHT - FONT_SIZE)/2 - 1,
            textAlign:'center',
          }}>
            {sizeString}
        </div>

      </div>

  )}

  getShape = (renderedShape: string, size:number) : ReactNode => {
    const sizeScale = Math.pow( size / 4, .8 )
    const nominalSizeForLargestMark = MENU_ITEM_SHAPE_WIDTH - 4
    const sizeSVG = nominalSizeForLargestMark * sizeScale
    //switch ( renderedShape ) {
    if ( renderedShape === 'diamond') {
          return ( <SVGwrapper2> <MarkShapeDiamond width={sizeSVG} height={sizeSVG}/> </SVGwrapper2> )
    } else if ( renderedShape === 'square' ) {
          return ( <SVGwrapper2> <MarkShapeSquare width={sizeSVG} height={sizeSVG}/> </SVGwrapper2> )
    } else if ( renderedShape === 'plus' ) {
          return ( <SVGwrapper2> <MarkShapePlus width={sizeSVG} height={sizeSVG}/> </SVGwrapper2> )
    } else if ( renderedShape === 'triangle' ) {
          return ( <SVGwrapper2> <MarkShapeTriangle width={sizeSVG} height={sizeSVG}/> </SVGwrapper2> )
    } else if ( renderedShape === 'circle' ) {
          return ( <SVGwrapper2> <MarkShapeCircle width={sizeSVG} height={sizeSVG}/> </SVGwrapper2> )
    } else {
        return null
    }
  }

  onColorItemSelection = (value: string): void => {
    const {selectedColor, seriesKey} = this.props
    if (selectedColor !== value) {
      let mods = []
      mods.push({ newVal: value, path: `attributes.series[${seriesKey}].color` })
      reactDispatch( mods, 'Style - Series Color' )
    }
  }

  onShapeItemSelection = (newShape: string, newSize:number): void => {
    const {selectedShape, selectedSize, seriesKey} = this.props
    let mods = []
    if (selectedShape !== newShape) {
      mods.push({ newVal: newShape, path: `attributes.series[${seriesKey}].markShape` })
    }
    if (selectedSize !== newSize) {
      mods.push({ newVal: newSize, path: `attributes.series[${seriesKey}].markSize` })
    }
    if (mods.length>0 ) {
      reactDispatch( mods, 'Style - Series Point Shape' )
    }
  }


  render() {
    const {width, height, selectedShape, selectedSize, mode, selectedColor} = this.props
    const {isActive} = this.state
    //console.log( 'Call to Render ShapePicker', selectedShape, selectedSize, selectedColor, this.state )

    if ( mode === 'shapeSelector') {
      var shapeIndex = distinctShapes.indexOf( selectedShape )
      var sizeIndex  = distinctSizes.indexOf( selectedSize )
      // Allow for missing selectedSize/shape, just in case we modify the list.  Safety catch!
      if ( sizeIndex  === -1 ) {sizeIndex  = distinctSizes.indexOf( constants.DEFAULT_MARK_SIZE )}
      if ( shapeIndex === -1 ) {shapeIndex = distinctShapes.indexOf( constants.DEFAULT_MARK_SHAPE )}
      var leftMenuPlacement = MENU_LEFT_POSITION_ShapeSelector
      var totalMenuWidth = MENU_WIDTH_ShapeSelector
      var menuItemWidth  = MENU_ITEM_WIDTH_ShapeSelector
      var numRows = numSizes
      var numCols = numShapes
      var selectionRowIndex = sizeIndex
      var selectionColIndex = shapeIndex
      var outlineBorderColor = 'cyan'
    } else {
      // I allow the selectedColor to NOT be included in the current enumerated colorTable.
      // This allows us to tweak colorValues and/or colorNames without having to check
      // the existing plotResources for no longer legal color values.  If we can't find
      // the color value exactly from an enumerated list, then find the closest color.
      var {colorIndex} = findClosestNamedColor( selectedColor )
      leftMenuPlacement = MENU_LEFT_POSITION_ColorSelector + this.props.width
      totalMenuWidth = MENU_WIDTH_ColorSelector
      menuItemWidth  = MENU_ITEM_WIDTH_ColorSelector
      numRows = NUM_COLOR_ROWS
      numCols = NUM_COLOR_COLS
      selectionColIndex = Math.floor( colorIndex/numRows )
      selectionRowIndex = colorIndex - selectionColIndex*numRows
      outlineBorderColor = distinctColors[colorIndex].fontColor
    }
    const indexArray = list( 0, numCols * numRows - 1 )
    const totalMenuHeight = MENU_HEIGHT_ShapeSelector  // Forced identical by design for color/shape pickers

    return (
      <div className={'rc_ShapeAndColorPicker'} style={{fontSize:FONT_SIZE}} >
        {/* DO NOT change above className.  It is used by taplistener to
            determine:  was last click inside or outside this className */}

        <div className={'selectorControl'}
          onClick={this.toggleMenu}
          style={{
            position:'relative', top:0, left:0, // local coord system
            width, height,
            display: 'inline-block',
            ...constants.STYLE_PALETTE_TEXT_INPUT_STYLES,
          }}>
              {this.getSelectorHTML(selectionColIndex, selectionRowIndex)}

              <div style={{
                  position:'absolute',
                  height: '100%', width:10,
                  top:0, right:3,
                }}
              >
                  <SVGselectCaret
                      height={'100%'}
                      width={'10px'}
                      color='black' />
              </div>
        </div>

{false &&
        <div style={{
            position:'absolute',
            height: '100%', width:10,
            top:0, right:5,
          }}
        >
            <SVGselectCaret
                height={'100%'}
                width={'10px'}
                color='black' />
        </div>
}

        { isActive &&
          <div className={'optionsArrayContainer'}
            style = {{
              position:'absolute', top: height+2, left: leftMenuPlacement,
              ...constants.CUSTOM_SELECT_POPUP_MENU_OUTLINE,
              height: totalMenuHeight, width: totalMenuWidth,
            }}
          >
            <div className={'relativeCoordsContainer'}
               style={{ position:'relative', top:0, left:0, cursor: 'default' }}>

                  { indexArray.map( i => {
                    var colIndex = Math.floor( i/numRows )
                    var rowIndex = i - colIndex*numRows
                    return (
                        <div className={'singleOptionContainer'}
                            key={i}
                            style={{
                                height: MENU_ITEM_HEIGHT, width:menuItemWidth,
                                position: 'absolute', left: colIndex * menuItemWidth, top: rowIndex * MENU_ITEM_HEIGHT,
                            }}>
                                {this.getMenuItemHTML(colIndex, rowIndex)}
                        </div>
                      )
                  })}

                  <div className={'selectionHighlight'}
                    style={{
                      position: 'relative',
                      top :selectionRowIndex * MENU_ITEM_HEIGHT,
                      left:selectionColIndex * menuItemWidth,
                      height: MENU_ITEM_HEIGHT, width:menuItemWidth,
                      borderWidth:'2px 2px 2px 2px',
                      borderColor: outlineBorderColor, borderStyle: 'solid',
                      background : 'transparent',
                    }}/>

                    {/* Exit menu at bottom of selector */}
                    <div className={'exitSelectorContainer'}
                      onClick={ this.exitMenu }
                      style={{
                        position: 'absolute', left: 0, top: MENU_ITEM_HEIGHT * numRows,
                        width: '100%', height:EXIT_CONTAINER_HEIGHT,
                        //background: 'pink',
                        borderWidth: '2px 0px 0px 0px',
                        borderStyle: 'solid', borderColor: '#888888',
                      }}>

                      <button className={'helpWithKeyButton'}
                        style={{
                          height: EXIT_BUTTON_HEIGHT, width: EXIT_BUTTON_WIDTH,
                          fontSize: 14,
                          display: 'block',
                          margin: '0 auto',
                          marginTop: (EXIT_CONTAINER_HEIGHT - EXIT_BUTTON_HEIGHT)/2,
                          ...constants.STYLE_EDITOR_RAISED_BUTTON,
                        }}>{'Exit Menu'}</button>
                    </div>

              </div>




          </div>
        }
      </div>
  )}
}

export default ShapeAndColorPicker



type SVG_Props = {
  width: number,
  height:number
}

class MarkShapeCircle extends PureComponent<SVG_Props> {
  render ( ) {
    return (
      <svg
        className={'rc_markShape_circle'}
        xmlns="http://www.w3.org/2000/svg"
        style={{isolation:'isolate', display: 'block'}}
        viewBox="0 0 101 101"
        preserveAspectRatio='none'
        width={this.props.width}
        height={this.props.height}
      >
        <circle cx="50" cy="50" r="45" style={{stroke:'#000000', strokeWidth:1, fill: '#000000' }}/>
      </svg>
    )
  }
}


class MarkShapeSquare extends PureComponent<SVG_Props> {
  render ( ) {
    return (
      <svg
        className={'rc_markShape_circle'}
        xmlns="http://www.w3.org/2000/svg"
        style={{isolation:'isolate', display: 'block'}}
        viewBox="0 0 101 101"
        preserveAspectRatio='none'
        width={this.props.width}
        height={this.props.height}
      >
        <rect x="10" y="10" width="81" height="81" style={{stroke:'#000000', strokeWidth:1, fill: '#000000' }}/>
      </svg>
    )
  }
}

class MarkShapeDiamond extends PureComponent<SVG_Props> {
  render ( ) {
    return (
      <svg
        className={'rc_markShape_circle'}
        xmlns="http://www.w3.org/2000/svg"
        style={{isolation:'isolate', display: 'block'}}
        viewBox="0 0 101 101"
        preserveAspectRatio='none'
        width={this.props.width}
        height={this.props.height}
      >
        <polygon points="1,51  51,5  100,51  51,100  1,51" style={{stroke:'#000000', strokeWidth:1, fill: '#000000' }}/>
      </svg>
    )
  }
}

class MarkShapePlus extends PureComponent<SVG_Props> {
  render ( ) {
    return (
      <svg
        className={'rc_markShape_circle'}
        xmlns="http://www.w3.org/2000/svg"
        style={{isolation:'isolate', display: 'block'}}
        viewBox="0 0 101 101"
        preserveAspectRatio='none'
        width={this.props.width}
        height={this.props.height}
      >
        <rect x="10" y="41" width="81" height="21" style={{stroke:'#000000', strokeWidth:1, fill: '#000000' }}/>
        <rect x="41" y="10" width="21" height="81" style={{stroke:'#000000', strokeWidth:1, fill: '#000000' }}/>
      </svg>
    )
  }
}


class MarkShapeTriangle extends PureComponent<SVG_Props> {
  render ( ) {
    return (
      <svg
        className={'rc_markShape_circle'}
        xmlns="http://www.w3.org/2000/svg"
        style={{isolation:'isolate', display: 'block'}}
        viewBox="0 0 101 101"
        preserveAspectRatio='none'
        width={this.props.width}
        height={this.props.height}
      >
        <polygon points="1,91  51,10  100,91  1,91" style={{stroke:'#000000', strokeWidth:1, fill: '#000000' }}/>
      </svg>
    )
  }
}
