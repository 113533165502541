import type { AnyAction, Dispatch } from '@reduxjs/toolkit'
import type { History } from 'history'
import type {MouseEvent, KeyboardEvent}              from 'react'
import type { RouteComponentProps } from 'react-router-dom'
import type { Column }  from '../types'
import type {TableFocalPlane_UnderlyingTarget}       from './focusCalculator'                  
import type { TableComputedData }   from '../computedDataTable/getDefaultTableComputedData'
import type { TooltipState }        from '../redux/tooltipReducer'  
import type { RootState } from '../redux/store'
import type { SessionState} from '../appCode/getDefaultSessionState'


import {Component}                  from 'react'
import {connect}                    from 'react-redux'
import {withRouter}                 from 'react-router-dom'
import reactDispatch                from '../sharedComponents/reactDispatch'
import {reactMinorDispatch}         from '../sharedComponents/reactDispatch'
import {focusCalculator}            from './focusCalculator'
import {isScryHyperlink_fromTableValue}   from '../sharedFunctions/isTypeHyperlink'
import {handlePinRow, 
        handleUnpinRow}             from './ActionPinRow'
import {hideColHandler, 
        unHideColHandler}           from './actionHideDeleteCol'
import {sortHandler}                from './ActionSort'
import {createTooltipDisplayAction,
       createTooltipVisibilityAction} from '../redux/tooltipReducer'
import TooltipContainer             from '../viewTooltips/TooltipContainer'
import { measureText }              from '../sharedFunctions/measureText'
import constants                    from '../sharedComponents/constants'
import {sessionStateChangeDispatch} from '../sharedComponents/reactDispatch'

// Components to Render
import LayoutMain                 from './LayoutMain'
import ActionDebugFocusCalculator from './ActionDebugFocusCalculator'
import ActionScroll               from './ActionScroll'
import ActionSort                 from './ActionSort'
import {ActionDndColFunc}         from './ActionDndCol'
import ActionPinRow               from './ActionPinRow'
import HighlightedFloatingPalette from '../floatingPalette/HighlightedFloatingPalette'

const IS_TOOLTIPS_ENABLED = true

type RouteMatchProps = {
  tableid: string
}

type OwnProps = {
  tableComputedData: TableComputedData,
  sessionState: SessionState,
  closeNavColumnPopups: ()=>void,
}
type StateProps = { toolTipResource: TooltipState }
type DispatchProps = {
  displayTooltip: (toolTipStr: string, toolTipTargetHeight: number, toolTipTargetLeft: number,
    toolTipTargetTop: number, toolTipTargetWidth: number, toolTipPlacement: string,
    toolTipAddedExtension: number ) => void,
  hideTooltip: () => void,
}
type Props = OwnProps & StateProps & DispatchProps & RouteComponentProps<RouteMatchProps>



class FocalPlaneRender extends Component<Props> {


  // Why use mouseDown and mouseUp, rather than onClick ??
  // Because we capture a 'click' over the entire focal plane, then
  // ask 'What coordinate/clickedFeature was clicked?'
  // But the mouseDown and mouseUp may NOT happen over the same
  // clickedFeature -- expecially when we have text selection (long
  // interval between down/up and different positions between down/up).
  // Two alternatives:
  //     1) Time the interval between down/up and put a minimal valid
  //     interval which defines this as a 'fast' click.
  //     2) Ask the question of 'clickFeature' at BOTH mouseDown and
  //     mouseUp.  They must be the same feature before we consider
  //     the event pair to be equivalent of a click.
  // Note that a solution is needed because we have one parent (focal plane)
  // capturing the click for many different 'clickedFeatures'.
  // At this time, we choose option #2.

  mouseDownTarget : TableFocalPlane_UnderlyingTarget | null = null

  handleMouseDown = (e: MouseEvent): void => {
    this.mouseDownTarget = this.calcFocusObj( e ) as TableFocalPlane_UnderlyingTarget
    //console.log( 'Table FocalPlane mouseDownEvent', this.mouseDownFocusObj )
  }

  handleMouseUp = (e: MouseEvent) : void => {
    // MouseDownTarget is never set if user clicks on the 'col resize handler'
    // Because it the resize handlers are blocking the onClick from the focal plane.
    //var isMouseDownSet : boolean = ( this.mouseDownTarget)
    if ( !this.mouseDownTarget ) { 
      // Then this mouse up is actually the 'end of the colResize operation'
      return
     }
     
    var underlyingTarget : TableFocalPlane_UnderlyingTarget  = this.calcFocusObj(e)
    const {caseName, colKey, rowKey} = underlyingTarget
    const {caseName:downName, colKey:downColKey, rowKey:downRowKey} = this.mouseDownTarget as TableFocalPlane_UnderlyingTarget
    const isFocusObjSame = ( downName === caseName && downColKey === colKey && downRowKey === rowKey )
    this.mouseDownTarget = null   // Clear the mouseDown object
    //console.log( 'Table FocalPlane mouseUpEvent - isMouseDownSet, isFocusObjSame:', focusObj, isMouseDownSet, isFocusObjSame )
    if ( isFocusObjSame ) {
      this.handleValidMouseDownUpSequence(underlyingTarget)
    }
  }

  calcFocusObj = (e: MouseEvent): TableFocalPlane_UnderlyingTarget => {
    const {widthObj:w, heightObj:h, styleObj:s, sortedRowKeys, colOrder, 
            scrollLeft, scrollTop, pinnedRowKeys } = this.props.tableComputedData
    const rect = e.currentTarget.getBoundingClientRect()
    const x = e.clientX - rect.left  
    const y = e.clientY - rect.top  
    // (x,y) is with respect to the top/left corner of the table (excludes NavBar and MenuBar)
    const focusObj : TableFocalPlane_UnderlyingTarget = focusCalculator(
      x, y, w, h, s,
      scrollLeft, scrollTop,
      colOrder, sortedRowKeys, pinnedRowKeys
    )
    // 'caseName' is defined as the horzSlice/vertSlice intersection
    var {focusVertSlice, focusHorzSlice, controlName} = focusObj
    let caseName = `${focusHorzSlice}-${focusVertSlice}`
    //console.log( caseName )
    if ( x <=0 || y <=0 ) {
      return ( {...focusObj, caseName, focusHorzSlice} )
    }
    // Many caseNames lie in 'do nothing areas' -- defined as any
    // focal plane area where we do NOT wish to acknowledge a 'click'.
    // Also, titles cut across many caseNames (different focusVertSlices).
    // Therefore, caseName branching is easier if we reduce the
    // number of cases to only those of interest.

    // This one must come first because colControls for last locked and
    // moving columns, plus filter control, lie in vertical slices that
    // we will otherwise consider 'empty' (equivalent to clearing the editor)
    if (controlName && controlName !== 'empty' ) {
      focusHorzSlice = 'colControls'
      caseName = `colControls-${controlName}`
    }
    // This is the empty space within the colControls horz slice:
    else if ( focusHorzSlice === 'colControls' && controlName === 'empty' ) {
      focusHorzSlice = ''
      caseName = ''
    }
    // excepting controls above, these vertical slices are empty space.
    else if (  focusVertSlice === 'leftMargin'
            || focusVertSlice === 'rightMargin'
            || focusVertSlice === 'gapBorderLockedMoving' ) {
      // Not a vertical slicewhere a 'click' has meaning.
      focusHorzSlice = ''   // Not an area where a 'click' has meaning.
      caseName = ''
    }
    // Other than controls above, these are the only horz spaces that
    // have a managed click:
    else if ( !( focusHorzSlice === 'head'
              || focusHorzSlice === 'pinned'
              || focusHorzSlice === 'data'
              || focusHorzSlice === 'mainTitle'
              || focusHorzSlice === 'publisherTitle' )) {
      // Not a horizontal slice where a 'click' has meaning.
      focusHorzSlice = ''
      caseName = ''
    }
    else if ( focusHorzSlice === 'mainTitle' ) {
      caseName = 'mainTitle'
    }
    else if ( focusHorzSlice === 'publisherTitle' ) {
      caseName = 'publisherTitle'
    }
    //console.log(caseName)
    return {...focusObj, caseName, focusHorzSlice}
  }

  handleHyperlinkClick = (history :History , columns:Column[], colKey:number, rowKey:number) => {
    if (colKey >= 0 && colKey < columns.length && rowKey >= 0 ) {
      if (columns[colKey].colDataType === 'hyperlink') {
        const {getTableValue} = this.props.tableComputedData
        const {value} = getTableValue(colKey, rowKey, true)
        const link = isScryHyperlink_fromTableValue( value )
        // Early exit (ignore click) for illegal links
        if (link.errorMsg !== '' ) { return }
        if (link.isInternal) {
          history.push(link.canonicalUrl)
        } else {
          window.open(link.canonicalUrl, '_blank', 'rel=noopener noreferrer,menubar=yes,location=yes,resizable=yes,scrollbars=yes,status=yes')
        }
      }
    }
  }

  closePopupEditor = () : void => {
    // Always set ALL four of these parameters.  Leaving them in some
    // prior valid state, especially colIndex and rowIndex,
    // can cause some test cases where the editor is believed open when
    // it is actually not visible.
    var {selection, isEditorOpen} = this.props.tableComputedData.tablelook.attributes.minorState
    if (selection.name !== '' || selection.colIndex !== -1 ||
        selection.rowIndex !== -1 || isEditorOpen) {
          var mods = [
           {newVal:{name:'', colIndex:-1, rowIndex:-1}, path: 'attributes.minorState.selection'},
           {newVal:false, path: 'attributes.minorState.isEditorOpen'},
          ]
          reactMinorDispatch(mods, 'tablelooks', this.props.tableComputedData.tablelook.id)
    }
  }


  handleValidMouseDownUpSequence = ( underlyingTarget: TableFocalPlane_UnderlyingTarget ) : void => {
    var   {caseName, colKey, rowKey, rowIndex, colIndex} = underlyingTarget
    const {columns, isSortable} = this.props.tableComputedData.table.attributes
    const {hideTooltip, history, closeNavColumnPopups, 
          tableComputedData, sessionState} = this.props
    const {canEdit} = tableComputedData
    const {activeStatsColKey} = sessionState
    const {rowSortColIds, pinnedRowKeys, minorState, colOrder} = this.props.tableComputedData.tablelook.attributes
    const {colIndex:selectionColIndex, rowIndex:selectionRowIndex, name: selectionName } = minorState.selection
    const {isEditorOpen:currentIsEditorOpen} = minorState
    const colDataType : string = (colKey >= 0) ? columns[colKey].colDataType : ''
    hideTooltip()

    // Conversion from rowIndex to rowKey needs to account for sortedRowKeys (missing rows & order)
    const {sortedRowKeys} = tableComputedData
    const selectionColKey = colOrder[ selectionColIndex ]
    const selectionRowKey = (selectionName === 'pinnedCell')
      ? pinnedRowKeys[ selectionRowIndex ]
      : sortedRowKeys[ selectionRowIndex ]

    switch ( caseName ) {

  ////////////////////////////////////////////////////////
  //  These switches are 'controls'  (NOT viewers/editors)
  ///////////////////////////////////////////////////////

      case 'colControls-hide':
          // Close the editor if it happens to be highlighting the column being hidden.
          let shouldCloseFloatingPaletteEditor = (colKey >=0 && colKey === selectionColKey )
          if ( colKey >= 0 && colKey === activeStatsColKey) {
            // We hide the statsBar BEFORE starting the animation
            let thisMode = [{newVal:-1, path: 'activeStatsColKey'}]  // -1 means no stats col to display
            sessionStateChangeDispatch( thisMode, 'Hide Column' )
          }
          hideColHandler  ( tableComputedData, colKey, shouldCloseFloatingPaletteEditor )
          break

      case 'colControls-unhide':
      case 'colControls-unhideLastLockedCol':
      case 'colControls-unhideLastMovingCol':
          // We expose the statsBar at the END of the animation (inside unHide handler)
          unHideColHandler( tableComputedData, colIndex )
          break

      case 'colControls-sortAscending':
      case 'colControls-sortDescending':
            if ( !isSortable) { break }
            const isAscending = (caseName === 'colControls-sortAscending') ? true : false
            // NOTE: rowSortColIds (number dataType) may include either +0 or -0 (both 
            //       (sort ascending colKey 0; sort descending colKey0 respectively)
            //       The sorting algorithm can recognize the difference!  
            //       So the rowSortColIds is an array of strings, not numbers.
            // Delete any prior references to the new colKey. Two copies of same key -> 2nd key has no effect.
            var nextRowSortColIds = rowSortColIds.filter( sortColKey => Math.abs(Number(sortColKey)) !== colKey )
            // Push the new colKey to the front (highest priorty) sort rule.
            nextRowSortColIds.unshift( isAscending 
                  ? '+' + String(colKey) 
                  : '-' + String(colKey) )
            // Constrain the set of sort rules to some max length:
            while (nextRowSortColIds.length > constants.ROW_SORT_COL_IDS_ARR_MAX_LENGTH) { nextRowSortColIds.pop() }
            const mods = [{ newVal: nextRowSortColIds, path : 'attributes.rowSortColIds' }]
            // Create a stateChange function to be called later (between shutterClose & shutterOpen animations)
            const reactDispatchFunc = () => {
              reactDispatch( mods, 'tablelooks', this.props.tableComputedData.tablelook.id )
              let thisMode = [{newVal:colKey, path: 'activeStatsColKey'}]  // -1 means no stats col to display
              sessionStateChangeDispatch( thisMode, 'sideEffect' )
            }
            sortHandler ( reactDispatchFunc )
            break

      case 'pinned-rowNumbers':
          handleUnpinRow( rowIndex, pinnedRowKeys, tableComputedData )
          break

      case 'data-rowNumbers':
          handlePinRow  ( rowKey, pinnedRowKeys )
          break


  ////////////////////////////////////////////////////////////////////////////////
  //  These switches are popup highlighted viewers/editors.  (NOT controls)
  ////////////////////////////////////////////////////////////////////////////////

      case 'colControls-rowFiltering':
          if (currentIsEditorOpen && selectionName === 'rowFiltering' ) { this.closePopupEditor() }
          else {
            let mods = [
             {newVal:{name:'rowFiltering', colIndex:-1, rowIndex:-1}, path: 'attributes.minorState.selection'},
             {newVal:true, path: 'attributes.minorState.isEditorOpen'},
            ]
            reactMinorDispatch( mods )
            closeNavColumnPopups()
          }
          break

      case 'pinned-locked' :
      case 'pinned-moving' :
      case 'data-locked' :
      case 'data-moving' :
          if (!canEdit && colDataType === 'hyperlink') {
            this.handleHyperlinkClick(history, columns, colKey, rowKey)
            break
          }
          let newName = (caseName.slice(0,6) === 'pinned') ? 'pinnedCell' : 'dataCell'
          if (selectionName === newName && selectionRowKey === rowKey && selectionColKey === colKey) { this.closePopupEditor() }
          else {
            reactMinorDispatch([
              {newVal:{name:newName, colIndex, rowIndex}, path: 'attributes.minorState.selection'},
              {newVal:true, path: 'attributes.minorState.isEditorOpen'},
            ])
            let thisMode = [{newVal:colKey, path: 'activeStatsColKey'}]  // -1 means no stats col to display
            sessionStateChangeDispatch( thisMode, 'sideEffect' )
            closeNavColumnPopups()
          }
          break

      case 'head-locked' :
      case 'head-moving' :
          if (currentIsEditorOpen && selectionRowIndex === rowIndex && selectionColIndex === colIndex) { this.closePopupEditor() }
          else {
            reactMinorDispatch([
             {newVal: {name:'tableHeader', colIndex, rowIndex}, path: 'attributes.minorState.selection'},
             {newVal:true, path: 'attributes.minorState.isEditorOpen'},
            ])
            let thisMode = [{newVal:colKey, path: 'activeStatsColKey'}]  // -1 means no stats col to display
            sessionStateChangeDispatch( thisMode, 'sideEffect' )
            closeNavColumnPopups()
          }
          break

      case 'mainTitle':
      case 'publisherTitle':
          if (currentIsEditorOpen && selectionName === caseName ) { this.closePopupEditor() }
          else {
            reactMinorDispatch([
             {newVal:{name:caseName, colIndex:-1, rowIndex:-1}, path: 'attributes.minorState.selection'},
             {newVal:true, path: 'attributes.minorState.isEditorOpen'},
            ])
            closeNavColumnPopups()
          }
          break

      case 'emptySpace':
      default:
          this.closePopupEditor()
          break
    }
  }



  lastToolTipsTimeStamp : number = 0

  handleMouseMove = (e: MouseEvent): void => {
    const tablelook = this.props.tableComputedData.tablelook
    if (!IS_TOOLTIPS_ENABLED) { return }
    const {isEditorOpen} = tablelook.attributes.minorState
    const {isSortable} = this.props.tableComputedData.table.attributes
    if (isEditorOpen) { return }
    const {displayTooltip, tableComputedData} = this.props
    const underlyingTarget : TableFocalPlane_UnderlyingTarget = this.calcFocusObj(e)
    const {caseName, cellHeight, cellLeft, 
           cellTop, cellWidth, focusHorzSlice, 
           colKey, rowKey} = underlyingTarget
    const {styleObj} = tableComputedData
    const {getTableValue} = tableComputedData


    // At this time, this handler is only used for tooltips when hovering
    // over the colControls.  However, we want to make sure user is 'hovering',
    // rather than the mouse simply transiting over a colControl.
    // Use a timer to determine a suitable 'hover' period.
    // Define the active 'hover' area as the
    // - filter control,
    // - the horz strip containing the column controls,
    // - the horizontal gap (vertical spacing) immediately above and
    //   below the colControls.
    var toolTipsActiveAreas =
      caseName === 'colControls-filter' ||
      focusHorzSlice === 'colControls'  ||
      focusHorzSlice === 'gapSourceTitleControls'  ||
      focusHorzSlice === 'gapControlsHead' ||
      caseName === 'data-locked' || caseName === 'data-moving' || 
      caseName === 'pinned-locked'


    // Case: Mouse exits activeArea and/or mouse movement outside acticeArea:
    if ( !toolTipsActiveAreas ) {
      this.lastToolTipsTimeStamp = 0
      //console.log('case0', toolTipsActiveAreas, this.lastToolTipsTimeStamp)
      return
    }
    // Case: Mouse moves into active area:
    else if ( this.lastToolTipsTimeStamp === 0 ) {
      this.lastToolTipsTimeStamp  = Date.now()
      //console.log('case1', toolTipsActiveAreas, this.lastToolTipsTimeStamp)
      return
    }
    // Case: Mouse movement is within active area:
    else {
      let deltaTime : number = Date.now() - this.lastToolTipsTimeStamp
      //console.log('case2', toolTipsActiveAreas, deltaTime)
      // 500ms is too long.  200 makes it feel snappier.
      // Case: Mouse movement within active area for less than hover time:
      if ( deltaTime < 200 ) return
    }

    // Case: Case: Mouse movement within active area for greater than hover time:
    //console.log( 'case3, hovered for > 1 sec', caseName )



    //console.log(`${caseName}: ${cellTop}, ${cellLeft} ${cellHeight} x ${cellWidth}`)
    var newToolTipStr = null
    var placement = 'above'      // Place tooltip above or below the colControl
    var toolTipAddedExtension = 0   // Tooltip has a nominal pointer nub; 8px in vertical height
                                    // This param extends (or shrinks the nub)
                                    // Used to place tooltip 'above' the lower sort button but
                                    // then extend the num pointer to point to the lower sort button.
                                    // Added in order for all colControl tool tips to appear 'above'
                                    // the colControls, rather than laying on top of the header cells.
    switch ( caseName ) {
      case 'data-locked':
      case 'data-moving':
      case 'pinned-locked':
        newToolTipStr = getTableValue(colKey, rowKey, true).value;
        if (newToolTipStr) {
          const link = isScryHyperlink_fromTableValue( newToolTipStr )
          const styledStringWidth = measureText(link.tableLabel, String(styleObj.cellFontSize));
          const padding = 10 + 30 //standard cell padding in pixels + 30 found in validation.    
          if ((styledStringWidth + padding ) < cellWidth) {
            newToolTipStr = '';
          } else {
            newToolTipStr = link.tableLabel
          }
        }
        placement = 'above';
        break
      case 'colControls-drag':
        newToolTipStr = 'Drag to reorder column'
        placement = 'above'
        break
      case 'colControls-filter':
        newToolTipStr = 'Filter table rows'
        placement = 'above'
        toolTipAddedExtension = 10
        break
      case 'colControls-hide':
        newToolTipStr = 'Hide column'
        placement = 'above'
        toolTipAddedExtension = cellHeight
        break
      case 'colControls-unhide':
      case 'colControls-unhideLastLockedCol':
      case 'colControls-unhideLastMovingCol':
        newToolTipStr = 'Show hidden column'
        placement = 'above'
        break
      case 'colControls-sortAscending':
        if ( !isSortable ) { break }
        newToolTipStr = 'Sort ascending (A-Z, 0-9)'
        placement = 'above'
        break
      case 'colControls-sortDescending':
        if ( !isSortable ) { break }
        newToolTipStr = 'Sort descending (Z-A, 9-0)'
        placement = 'above'
        toolTipAddedExtension = cellHeight
        break
      default:
        newToolTipStr = ''
    }
    if (newToolTipStr) {
      displayTooltip(newToolTipStr, cellHeight, cellLeft, cellTop, cellWidth, placement, toolTipAddedExtension )
    }
  }

  handleKeyUp = (e: KeyboardEvent ): void => {
    if (e.keyCode === 27) { // escape
      this.closePopupEditor()
      return
    }
    //console.log( 'handleKeyUp called')
    const {isEditorOpen} = this.props.tableComputedData.tablelook.attributes.minorState
    if (isEditorOpen === false ) { return }

    switch (e.key) {
      case 'ArrowDown':
        this.moveSelection(0,1)
        break
      case 'ArrowLeft':
        this.moveSelection(-1,0)
        break
      case 'ArrowRight':
        this.moveSelection(1,0)
        break
      case 'ArrowUp':
        this.moveSelection(0,-1)
        break
      default:
    }
  }

  // This function used to find the 'next' column when using the arrow keys.
  // Where 'next' is defined as the adjacent VISIBLE column.  We skip over
  // deleted and hidden columns.   This approach ONLY need for horz stepping,
  // because columns may be hidden.  No analogy for rows (vertical setps
  getNextVisibleColIndex = (currentColIndex : number , deltaIndex : number) : number => {
    var nextColIndex = currentColIndex
    const {isHidden_ByColIndex} = this.props.tableComputedData
    const numColumns = isHidden_ByColIndex.length
    nextColIndex += deltaIndex  // increment or decrement one column. Still in bounds? And is it visible?
    while (nextColIndex >= 0 && nextColIndex < numColumns) {
      if ( !isHidden_ByColIndex[nextColIndex]) { return nextColIndex }
      nextColIndex += deltaIndex  // increment or decrement a column and try again.
    }
    // If we run out of columns at first (leftmost) or last (rightmost) column,
    // then we don't increment the colIndex;  We return input value unchanged.
    // Same as saying we ignore left/right arrow key at the edges of the table.
    return currentColIndex
  }

// TODO:
// Add autoIndex animation when mouseDown, followed by NO mouse in some predefined time (8-10 cells per second??)
// Then auto move to next cell.  Vert motion should be much faster (cells/second) than horizontal.



  moveSelection = (horz: number, vert:number): void => {
      const {sortedRowKeys, widthObj, heightObj, pinnedRowKeys, 
            tablelook, scrollLeft, scrollTop, colOrder} = this.props.tableComputedData
      const {colIndex:currentColIndex,
             rowIndex:currentRowIndex,
             name: currentSelectionName } = tablelook.attributes.minorState.selection
      const { movingAllocated, startColLeft, displayedColWidths, 
              numLockedCols, isCombinedTable } = widthObj
      const { rowHeight, dataAllocated } = heightObj
      var mods = []
      var sessionMods = []

      // Case of Left/Right arrow keys:
      // horz means 'horizontal' arrow key. 
      // horz and vert possible values are: -1, 0, +1
      if (horz !== 0) {
        const nextColIndex = this.getNextVisibleColIndex( currentColIndex, horz )
        const leftEdge   = startColLeft[ nextColIndex ]
        const rightEdge  = leftEdge + displayedColWidths[ nextColIndex ]
        const isScrolledCol = isCombinedTable || currentColIndex >= numLockedCols
        const colKey = colOrder[nextColIndex]
        mods.push({newVal:nextColIndex, path: 'attributes.minorState.selection.colIndex'})
        // Potentially need new scrollLeft position ??
        if ( isScrolledCol ) {
          if ( rightEdge > scrollLeft + movingAllocated ) {
            sessionMods.push({newVal:rightEdge-movingAllocated, path: 'activeTableScrollLeft'})
          }
          if ( leftEdge < scrollLeft ) {
            sessionMods.push({newVal:leftEdge, path: 'activeTableScrollLeft'})
          }
        }
        reactMinorDispatch( mods )
        sessionMods.push( {newVal:colKey, path: 'activeStatsColKey'})
        sessionStateChangeDispatch( sessionMods, 'Select New Cell' )
        return
      }

      // Beyond this point, Case of up/down arrow keys
      const numPinnedRows = pinnedRowKeys.length
      var topEdge = 0
      var bottomEdge = 0
      var newSelectionName : string = currentSelectionName  // Assumption that the move stays withing the same horzSlice
      var newRowIndex : number = 0
      if ( vert === -1 ) { // All cases of moving 'up' one row
          // Define all 3 cases of moving to (or staying) on colHeader cell:
          if ( currentSelectionName === 'tableHeader'  ||
             ( currentSelectionName === 'pinnedCell' && currentRowIndex === 0 ) ||
             ( currentSelectionName === 'dataCell'   && currentRowIndex === 0 && numPinnedRows === 0 )) {
            newSelectionName  = 'tableHeader'
            newRowIndex = 0
          }
          // moving to next higher pinned row.
          else if ( currentSelectionName === 'pinnedCell' ) {
            newRowIndex = currentRowIndex - 1
          }
          // Case of topmost dataRow and need to index to bottom of pinnedRows
          else if ( currentSelectionName === 'dataCell' && currentRowIndex === 0 && numPinnedRows > 0 ) {
            newRowIndex = pinnedRowKeys.length - 1
            newSelectionName  = 'pinnedCell'
          }
          // Case of arbitrary data row moving to next higher dataRow
          else if ( currentSelectionName === 'dataCell' && currentRowIndex > 0 ) {
             newRowIndex = currentRowIndex - 1
             topEdge = newRowIndex * rowHeight
             bottomEdge = (newRowIndex+1) * rowHeight
          }
      }


      if ( vert === +1 ) { // All cases of moving 'down' one row
          // Case of moving from header to topmost pinnedRow
          if ( currentSelectionName === 'tableHeader' && numPinnedRows > 0 ) {
            newSelectionName  = 'pinnedCell'
            newRowIndex = 0
          }
          // Case of moving from 'head' to topmost data row.
          else if ( currentSelectionName === 'tableHeader' && numPinnedRows === 0 ) {
            newRowIndex = 0
            newSelectionName  = 'dataCell'
            topEdge = 0
            bottomEdge = rowHeight
          }
          // Case of moving from pinnedRows to topmost data row.
          else if ( currentSelectionName === 'pinnedCell' && currentRowIndex === numPinnedRows-1 ) {
            newRowIndex = 0
            newSelectionName = 'dataCell'
            topEdge = 0
            bottomEdge = rowHeight
          }
          // Case moving down from a pinnedRow to a pinnedRow
          else if ( currentSelectionName === 'pinnedCell' ) {
            newRowIndex = currentRowIndex + 1
          }
          // Case of arbitrary data row moving to next higher dataRow
          else if ( currentSelectionName === 'dataCell' ) {
             newRowIndex = currentRowIndex + 1
             newRowIndex = Math.min( newRowIndex, sortedRowKeys.length - 1 )
             topEdge = newRowIndex * rowHeight
             bottomEdge = (newRowIndex+1) * rowHeight
          }
      }
      if ( newRowIndex !== currentRowIndex ) {
        mods.push({ newVal: newRowIndex, path: 'attributes.minorState.selection.rowIndex' })
      }
      if (newSelectionName !== currentSelectionName ) {
        mods.push({ newVal: newSelectionName, path: 'attributes.minorState.selection.name' })
      }
      // Potentially need to move scrollTop position ??
      if ( newSelectionName === 'dataCell' ) {
        if ( bottomEdge > scrollTop + dataAllocated ) {
          sessionMods.push({newVal: bottomEdge - dataAllocated, path: 'activeTableScrollTop'})
        }
        if ( topEdge < scrollTop ) {
          sessionMods.push({newVal: topEdge,                    path: 'activeTableScrollTop'})
        }
      }
      sessionStateChangeDispatch( sessionMods, 'Shift Cell Editor to adjecent cell' )
      reactMinorDispatch( mods )
  }

  render() {
    const {tableComputedData, toolTipResource } = this.props
    const {tablelook, tabledata, table} = tableComputedData
    //console.log( ' call to FocalPlane render' )
    const {colOrder, isBrightField, minorState, pinnedRowKeys } = tablelook.attributes
    const {isEditorOpen}  = minorState
    const {widthObj:w, heightObj:h, styleObj:s, sortedRowKeys } = tableComputedData

    return (
      <div
        className={'rc_FocalPlane'}
        onKeyUp={this.handleKeyUp}
        onMouseDown={this.handleMouseDown}
        onMouseMove={this.handleMouseMove}
        onMouseUp={this.handleMouseUp}
        style={{
          outline: 'none',
          position:'relative',
          top:0, left:0,
          width: w.viewWidthPx,
          height: h.viewHeightPx,
          overflow: 'visible',  // MUST be visible, because when the sideBar closes, we need responsiveState
                                // to 'widen' the displayed table BEYOND the current allocated width.
          //background: 'white',
          //background: 'green'
        }}
        tabIndex={1}
      >

        {IS_TOOLTIPS_ENABLED &&
        <TooltipContainer
            tableComputedData={tableComputedData}
            isBrightField={isBrightField}
            toolTipResource={toolTipResource}
        />}

        {true && 
        <LayoutMain
            tableComputedData={tableComputedData}
            sessionState={this.props.sessionState}
        />}

        {isEditorOpen && 
        <HighlightedFloatingPalette
            tablelook={tablelook}
            tabledata={tabledata}
            table={table}
            tableComputedData={tableComputedData}
        />}

        {true  &&
         <ActionSort
            widthRowNumbers={w.rowNumbers}
            lockedAllocated={w.lockedAllocated}
            movingAllocated={w.movingAllocated}
            dataAllocated={h.dataAllocated}
            pinnedHeight={h.pinnedHeight}
            dataTop={h.dataTop}
            pinnedRowsTop={h.headTop + h.headerHeight}
            rowNumbersLeft={w.centeringOffset}
            lockedLeft={w.lockedLeft}
            movingLeft={w.movingLeft}
            backgroundColor={s.fieldColor}
            doDataRowsExist={h.doDataRowsExist}
        />}

        {true  && ActionDndColFunc( tableComputedData ) }

        {true  &&
        <ActionScroll
            tableComputedData={tableComputedData}
            foregroundColor={s.foregroundColor}

            scrollLeft={tableComputedData.scrollLeft}
            scrollTop={tableComputedData.scrollTop}

            movingAllocated={w.movingAllocated}
            movingRequired={w.movingRequired}

            horzScrollControlRight={w.horzScrollControlRight}
            horzScrollControlHeight={h.horzScrollControlHeight}
            horzScrollControlTop={h.horzScrollControlTop}
            vertScrollControlWidth={w.scrollControlTotalWidth}
            vertScrollControlTop={h.vertScrollControlTop}

            dataAllocated={h.dataAllocated}
            dataRequired={h.dataRequired}
            minScrollHeightBeforeHidden={h.minScrollHeightBeforeHidden}

            touchLeft={w.touchLeft}
            touchWindowWidth={w.touchWindowWidth}
            touchContainerWidth={w.touchContainerWidth}
            touchContentWidth={w.touchContentWidth}

            touchTop={h.touchTop}
            touchWindowHeight={h.touchWindowHeight}
            touchContainerHeight={h.touchContainerHeight}
            touchContentHeight={h.touchContentHeight}

            displayedColWidths={w.displayedColWidths}
            startColLeft={w.startColLeft}
            colOrder={colOrder}
            isCombinedTable={w.isCombinedTable}
            numLockedCols={w.numLockedCols}
            lockedLeft={w.lockedLeft}
            movingLeft={w.movingLeft}
            borderThickness={w.borderThickness}

            colresize_capture_width={w.colresize_capture_width}
            colresize_capture_offset={w.colresize_capture_offset}
        />}

        {true  &&
        <ActionPinRow
            tableDomNodes={tableComputedData.tableDomNodes}
            pinnedTop={h.pinnedTop}
            pinnedHeight={h.pinnedHeight}
            scrollTop={tableComputedData.scrollTop}
            dataTop={h.dataTop}
            dataHeight={h.dataAllocated}
            rowHeight={h.rowHeight}
            rowNumbersWidth={w.rowNumbers}
            centeringOffset={w.centeringOffset}
            pinIconHeight={w.pinIconSize}
            foregroundColor={s.foregroundColor}
            pinnedRowKeys={pinnedRowKeys}
            sortedRowKeys={sortedRowKeys}
            minScrollHeightBeforeHidden={h.minScrollHeightBeforeHidden}
          />}


          {false &&
          <ActionDebugFocusCalculator
            colOrder={colOrder}
            sortedRowKeys={sortedRowKeys}
            pinnedRowKeys={pinnedRowKeys}
            widthObj={w}
            heightObj={h}
            styleObj={s}
            scrollLeft={tableComputedData.scrollLeft}
            scrollTop={tableComputedData.scrollTop}
          />}


    </div>
    )
  }
}




const mapStateToProps = (state: RootState ): StateProps => {
  return { toolTipResource : state.tooltip }
}

const mapDisp = (dispatch: Dispatch<AnyAction>, ownProps: OwnProps): DispatchProps => (
  {
    displayTooltip: (toolTipStr: string, toolTipTargetHeight: number, toolTipTargetLeft: number,
      toolTipTargetTop: number, toolTipTargetWidth: number,
      toolTipPlacement:string, toolTipAddedExtension: number ): void => {
        dispatch(createTooltipDisplayAction({toolTipStr, toolTipTargetHeight,
          toolTipTargetLeft, toolTipTargetTop, toolTipTargetWidth, toolTipPlacement, toolTipAddedExtension,
          toolTipVisible: true, toolTipOverride: false}))
    },
    hideTooltip: (): void => {
      dispatch(createTooltipVisibilityAction(false))
    }
  }
)

const FocalPlaneConnected = connect(mapStateToProps, mapDisp)(FocalPlaneRender)
const FocalPlane = withRouter(FocalPlaneConnected)
export default FocalPlane
