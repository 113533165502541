import {Component}          from 'react'
import type {TableComputedData} from '../computedDataTable/getDefaultTableComputedData' 
import TtSideBarAnimation from './TtSideBarAnimation'    
import FocalPlane from '../viewTable/FocalPlane'
import type {SessionState} from '../appCode/getDefaultSessionState'

type Props = {
  sessionState : SessionState,
  tableComputedData : TableComputedData, 
  sourcePlotXyComputedState : string, 
  workingPlotXyComputedState : string, 
  closeNavColumnPopups: ()=>void, 
}

export default class TtSwapViewAnimation extends Component <Props> {
  render () {
    const { tableComputedData, closeNavColumnPopups, sessionState} = this.props
    return (
      <>
          <FocalPlane
            tableComputedData={tableComputedData}
            sessionState={sessionState}
            closeNavColumnPopups={closeNavColumnPopups} />
            
          <TtSideBarAnimation
            sessionState={sessionState}
            activeView={'table'}
            tableComputedData={tableComputedData}
            sourcePlotXyComputedState={'placeholder'}
            workingPlotXyComputedState={'placeholder'}
            closeNavColumnPopups={closeNavColumnPopups} />
      </>
  )}
}

