import type { AnyAction, Dispatch } from '@reduxjs/toolkit'
import { ErrorTypes }  from '../../shared/ErrorTypes'
import ensure from './ensure'
import { createResource, paramsToListId } from '../jsonapi'
import { notifyError, notifyJsonApiError } from './notifyReducer'
import { SEARCH_TABLE_ID, SEARCH_TABLEDATA_ID } from '../sharedComponents/constants'
import { getDefaultTablelook } from '../types'
import { asyncDispatch, deepClone } from '../sharedFunctions/utils'
import type { GetStateFunc, Mod, ModOp } from '../types'
import { createDoAction } from './undoReducer'

const DEBUG = false

export const PLOTS_SORT = '-updatedDate'

export const usersTablelookListId = (userId: string, tableid: string): string => {
  const listId = paramsToListId({
    'filter[table][data][id]': tableid,
    'filter[owner][data][id]': userId,
  })
  return listId
}

const buildListId = (tablesSort: string, tablesQuery: string, tablesTags: Array<string>): string => {
  return paramsToListId({
    'filter[tags][data][id]': tablesTags,
    'query': tablesQuery.split(/\s+/).join('|'),
    'sort': tablesSort,
  });
}

//Fetch the table and tabledata resources for the specified tableid.
//Also fetch the user's tablelook for this table, or create a new one if
//the user does not have one yet.
//Handles the special case where the tableid is SEARCH_TABLE_ID
export const ensureTableResourcesThunk = (tableid: string) => async (dispatch: Dispatch<AnyAction>, getState: GetStateFunc): Promise<void> => {
  if (DEBUG) { console.log( 'Call to ensureTableResourcesThunk' ) }
  try {
    const userid = getState().app.authUserId
    asyncDispatch(dispatch, ensure.resource('users', userid))

    if (tableid === SEARCH_TABLE_ID || tableid === SEARCH_TABLEDATA_ID) {
      const { tablesSort, tablesQuery, tablesTags, authUserId: userid } = getState().app;
      const listId = buildListId(tablesSort, tablesQuery, tablesTags)
      await asyncDispatch(dispatch, ensure.list('searchtables', listId, 0, 1000, true))
      const userListId = usersTablelookListId(userid, SEARCH_TABLE_ID)
      await asyncDispatch(dispatch, ensure.list('tablelooks', userListId, 0, 1000, false))
    } else {
      const tablePromise = asyncDispatch(dispatch, ensure.resource('tables', tableid, 'owner,tabledata'))
      const userListId = paramsToListId({
        'filter[table][data][id]': tableid,
        'filter[owner][data][id]': userid,
      })
      const userTableLookPromise = asyncDispatch(dispatch, ensure.list('tablelooks', userListId, 0, 1000, true))
      await tablePromise
      if (DEBUG) { console.log('successfull "await tablePromise"') }
      const table = (getState().api?.resources?.tables?.[tableid]) || null;
      const tableOwnerid = table?.relationships?.owner.data.id
      const ownerListId = paramsToListId({
        'filter[table][data][id]': tableid,
        'filter[owner][data][id]': tableOwnerid,
      })
      if (ownerListId !== userListId) {
        await asyncDispatch(dispatch, ensure.list('tablelooks', ownerListId, 0, 1000, true))
        if (DEBUG) { console.log('successfull "await tablelooks" when ownerListId !== userListId') }
      }
      const tabledataid = table?.relationships?.tabledata.data.id
      const tabledata = getState()?.api?.resources?.tabledatas?.[tabledataid];
      await userTableLookPromise;
      let userTablelookid = getState()?.api?.lists?.tablelooks?.[userListId]?.ids?.[0] || '';
      let userTablelook = getState()?.api?.resources?.tablelooks?.[userTablelookid] || null;
      const ownerTablelookid = getState()?.api?.lists?.tablelooks?.[ownerListId]?.ids?.[0] || '';
      const ownerTablelook = getState()?.api?.resources?.tablelooks?.[ownerTablelookid] || null;
      if (DEBUG) { console.log('successfull "await userTableLookPromise" - ', userTablelookid) }

      if (!userTablelook) {
        // We use the current owner's tablelook, rather than the default tablelook.
        let newTableLook
        if (ownerTablelook) {
          // clone it
          newTableLook = deepClone(ownerTablelook)
          newTableLook.id = ''
          delete newTableLook.attributes.createdDate
          delete newTableLook.attributes.updatedDate
          delete newTableLook?.relationships?.owner
        } else {
          const numCols = tabledata.attributes.tableValues.length
          const numRows = tabledata.attributes.tableValues[0].length
          newTableLook  = getDefaultTablelook(numRows, numCols)
        }
        newTableLook.relationships = {
          table: {
            data: {
              id: tableid,
              type: 'tables'
            }
          }
        }
        await asyncDispatch(dispatch, createResource(newTableLook))
        if (DEBUG) { console.log('successfull "await create newTableLook" when no userTablelook was available') }
        //A new tablelook was created, so we need to update the user's tablelooks list again:
        await asyncDispatch(dispatch, ensure.list('tablelooks', userListId, 0, 1000, true))
        if (DEBUG) { console.log('successfull "await newTableLook" when newTableLook was created') }
      } else if (ownerTablelook && tableOwnerid !== userid) {
        // may need to update user's to match owner's if the owner made
        // changes to the tabledata since this user last viewed it.
        const mods: Mod[] = []
        const op: ModOp = 'set'
        const ownerColOrder = ownerTablelook.attributes.colOrder
        if (ownerColOrder.length > userTablelook.attributes.colOrder.length) {
          // add the new columns to the end to the users column order,
          // in the order they are in the owner's tablelook
          const userColOrderCopy = userTablelook.attributes.colOrder.slice()
          for (const colKey of ownerColOrder) {
            if (userColOrderCopy.indexOf(colKey) < 0) {
              userColOrderCopy.push(colKey)
            }
          }
          mods.push({
            newVal: userColOrderCopy,
            path: 'attributes.colOrder',
            op,
            resId: userTablelook.id,
            resType: userTablelook.type,
          })
        }

        const ownerColLooks = ownerTablelook.attributes.lookColumns
        const userColLooks = userTablelook.attributes.lookColumns
        if (ownerColLooks.length > userColLooks.length) {
          const userColLooksCopy = userColLooks.concat(ownerColLooks.slice(userColLooks.length))
          mods.push({
            newVal: userColLooksCopy,
            path: 'attributes.lookColumns',
            op,
            resId: userTablelook.id,
            resType: userTablelook.type,
          })
        }
        if (mods.length > 0) {
          dispatch(createDoAction({title: 'table appearance updated due to table change', mods, actionGroup: '', parentType: 'tables', parentId: tableid}))
          if (DEBUG) { console.log('successfull "await createDoAction" when table appearance updated') }
        }
      }

      // get the plot list for this table
      const listId = plotsForTableListId(tableid, userid, PLOTS_SORT)
      const myPlotsPromise = asyncDispatch(dispatch, ensure.list('plots', listId, 0, 1000, true))
      let ownerPlotsPromise = Promise.resolve()
      if (tableOwnerid !== userid) {
        const ownerListId = plotsForTableListId(tableid, tableOwnerid, PLOTS_SORT)
        ownerPlotsPromise = asyncDispatch(dispatch, ensure.list('plots', ownerListId, 0, 1000, true))
      }
      await Promise.all([myPlotsPromise, ownerPlotsPromise])

      if (DEBUG) {
        const tableIdsInMemory = Object.keys(getState().api.resources?.tables || {}) 
        const tableDatasInMemory = Object.keys(getState().api.resources?.tabledatas || {}) 
        const tableLooksInMemory = Object.keys(getState().api.resources?.tablelooks || {}) 
        const plotsInMemory = Object.keys(getState().api.resources?.plots || {}) 
        console.table([
          { name: 'tableIdsInMemory', length: tableIdsInMemory.length },
          { name: 'tableDatasInMemory', length: tableDatasInMemory.length },
          { name: 'tableLooksInMemory', length: tableLooksInMemory.length },
          { name: 'plotsInMemory', length: plotsInMemory.length },
        ]);
      }
    } 
  } catch (err: any) {
    const errors = err?.response?.data?.errors || null;
    if (errors) {
      dispatch(notifyJsonApiError({errors, type: ErrorTypes.SERVER_ERR}))
    } else {
      dispatch(notifyError({errorType: ErrorTypes.SERVER_ERR, message: err.message}))
    }
  }
}

export const plotsForTableListId = (tableid: string, tableOwnerid: string, plotsSort: string): string => {
  const listId = paramsToListId({
    'filter[owner][data][id]': tableOwnerid,
    'filter[table][data][id]': tableid,
    'sort': plotsSort,
  })
  return listId
}