// See design note: client/tablesParent/CreatePlot_DesignOverview.txt

import type { AnyAction, Dispatch } from '@reduxjs/toolkit'
import { get, set } from 'radash'
import uuidv4 from 'uuid/v4'
import { ErrorTypes }  from '../../shared/ErrorTypes'
import ensure from './ensure'
import { createResource, deleteListByContents, deleteListByListIdFragment, deleteResource } from '../jsonapi'
import { notifyError, notifyJsonApiError, notifyInfo } from './notifyReducer'
import { updateTablelook_recentPlots } from '../computedDataPlotXY/plotUtils'
import { getDefaultPlotXy } from '../types'
import { asyncDispatch, deepClone, getErrorMessage } from '../sharedFunctions/utils'
import type { GetStateFunc, LightweightMod, Plot, RouterHistory, Table, Tablelook } from '../types'


export const createPlotThunk = (mods: LightweightMod[], table: Table, tablelook: Tablelook, history: RouterHistory) => {
  return async (dispatch: Dispatch<AnyAction>, getState: GetStateFunc): Promise<void> => {
    try {
      const newPlot = getDefaultPlotXy( mods, table )
      await asyncDispatch(dispatch, createResource(newPlot))
      //after its been created, update the tablelook_recentPlots
      updateTablelook_recentPlots(table.id, tablelook, newPlot.id)
      //drop the plots list so a new one is loaded
      dispatch(deleteListByListIdFragment({type: 'plots', listIdFragment: table.id}))
      history.push(`/plot/${newPlot.id}`)
    } catch (err) {
      const errors = get(err, 'response.data.errors', null)
      if (errors) {
        dispatch(notifyJsonApiError({errors, type: ErrorTypes.SERVER_ERR}))
      } else {
        dispatch(notifyError({errorType: ErrorTypes.SERVER_ERR, message: getErrorMessage(err)}))
      }
    }
  }
}


export const ensurePlotResourcesThunk = (plotid: string) => {
  return async (dispatch: Dispatch<AnyAction>, getState: GetStateFunc): Promise<void> => {
    try {
      const plotPromise = asyncDispatch(dispatch, ensure.resource('plots', plotid, 'owner,table'))
      await plotPromise
//      const plot = get(getState(), 'api.resources.plots', plotid], null)
      // now that we have the plot, we can trigger load of tabledata
//      const tableid = plot.relationships.table.data.id
//      const table = get(getState(), 'api.resources.tables', tableid], null)
//      const tabledataid = table.relationships.tabledata.data.id
//      const tableDataPromise = dispatch(ensure.resource('tabledatas', tabledataid))

//      await tableDataPromise
    } catch (err) {
      const errors = get(err, 'response.data.errors', null)
      if (errors) {
        dispatch(notifyJsonApiError({errors, type: ErrorTypes.SERVER_ERR}))
      } else {
        dispatch(notifyError({errorType: ErrorTypes.SERVER_ERR, message: getErrorMessage(err)}))
      }
    }
  }
}

export const deletePlotThunk = (plot: Plot ) => {

    return async (dispatch: Dispatch<AnyAction>, getState: GetStateFunc): Promise<void> => {
      try{
        const plotId = plot.id
        await asyncDispatch(dispatch, deleteResource(plot))
        dispatch(notifyInfo("Plot has been deleted"))
        //drop the plots list so a new one is loaded
        dispatch(deleteListByContents({type: 'plots', id: plotId}))
      } catch (err) {
        const errors = get(err, 'response.data.errors', null)
        if (errors) {
          dispatch(notifyJsonApiError({errors, type: ErrorTypes.SERVER_ERR}))
        } else {
          dispatch(notifyError({errorType: ErrorTypes.SERVER_ERR, message: getErrorMessage(err)}))
        }
      }

    }
}



export const clonePlotThunk = (plot: Plot, mods: LightweightMod[], tablelook: Tablelook | null,
                      userid:string, history : RouterHistory ) => {

  return async (dispatch: Dispatch<AnyAction>, getState: GetStateFunc): Promise<void> => {
    try {
      let newPlot = deepClone( plot )
      newPlot.id = uuidv4()
      for (const thisMod of mods) {
        newPlot = set( newPlot, thisMod.path, thisMod.newVal )
      }
/*
      // Strip previously deleted seriesKeys from the series array:
      var culledSeries = []
      newPlot.attributes.seriesOrder.forEach( (seriesKey)=> {
        culledSeries.push( newPlot.attributes.series[seriesKey] )  // This filters AND reorders!
      })
      newPlot.attributes.series = culledSeries
      newPlot.attributes.seriesOrder = range( culledSeries.length )
*/
      // Server will create or handle these props:
      delete newPlot.attributes.createdDate
      delete newPlot.attributes.updatedDate
      delete newPlot.meta
      delete newPlot.links
      delete newPlot.relationships?.owner   // Server will add this back
      delete newPlot.relationships?.table.meta
      delete newPlot.relationships?.table.links
      delete newPlot.relationships?.tags?.meta
      delete newPlot.relationships?.tags?.links
      //delete newPlot.relationships.originatorPlot.meta
      //delete newPlot.relationships.originatorPlot.links
      const originatorPlot = { data: {id: plot.id, type: plot.type }}
      if (!newPlot.relationships) {
        newPlot.relationships = {
          originatorPlot,
          table: {data: null}
        }
      } else {
        newPlot.relationships.originatorPlot = originatorPlot
      }
/*
      // CASE : clone( My Full Ownership) => My Full Ownership
      if ( userid === tableOwnerid ) {
         newPlot.attributes.isClonedForStyleOnly = false
         newPlot.relationships.originatorPlot = { data: {id: plot.id, type: 'plots' }}
      } else {  // CASE : clone( 3rd Party Ownership) => My Full Ownership
          newPlot.attributes.isClonedForStyleOnly = false
          newPlot.relationships.originatorPlot = { data: {id: plot.id, type: 'plots' }}
       }
*/
      await asyncDispatch(dispatch, createResource(newPlot))
      //after its been created, update the tablelook_recentPlots and drop the plots list so a new one is loaded
      const tableid = plot.relationships?.table.data.id
      dispatch(deleteListByListIdFragment({type: 'plots', listIdFragment: tableid}))
      if (tablelook) {
        updateTablelook_recentPlots(tableid, tablelook, newPlot.id)
      }
      //navigate to the new plot
      history.push(`/plot/${newPlot.id}`)

    } catch (err) {
      const errors = get(err, 'response.data.errors', null)
      if (errors) {
        dispatch(notifyJsonApiError({errors, type: ErrorTypes.SERVER_ERR}))
      } else {
        dispatch(notifyError({errorType: ErrorTypes.SERVER_ERR, message: getErrorMessage(err)}))
      }
    }
  }
}
