import type { FocusEvent, KeyboardEvent, MouseEvent, SyntheticEvent } from 'react'
import type { OptionalHTMLDivElement } from '../types'
import type { ScryFormula } from '../computedDataTable/formulaTypes'
import type { DerivedColAttributes, TableComputedData } from '../computedDataTable/getDefaultTableComputedData'

import {Component}       from 'react'
import reactDispatch            from '../sharedComponents/reactDispatch'
import formulaParser            from '../computedDataTable/formulaParser'
import {errorCheckLines}        from '../computedDataTable/formulaErrorCheckLines'
import {errorCheckFormulaStructure}    from '../computedDataTable/formulaErrorCheckStructure'
import ColorCodedFormula        from '../computedDataTable/formulaColorCoding'
import {CreatePrettyFormats, 
  createPrettyFormulaHTMLString} from '../computedDataTable/formulaCreatePrettyFormats'
//import type {ScryFormula}       from '../formulas/formulaTypes'
import {getDefaultScryFormula}  from '../computedDataTable/formulaTypes'
//import type { DerivedColAttributes } from './getDefaultTableComputedData'
import {createTableidSpacedStrings,
    convertToTableidColNames,
    getInputArgColKeysAndErrorCheckInputs,
    convertToUserColNames}      from '../computedDataTable/updateTableSupportFuncs'
import {findLegalCharCount}     from '../sharedFunctions/measureText'
import constants                from '../sharedComponents/constants'
import {cleanScryInputText2}    from '../sharedFunctions/cleanScryInputText'
import EditColMessageDisplay    from '../sharedComponents/EditColMessageDisplay'
import rStateFloatingPalette    from '../floatingPalette/rStateFloatingPalette'
import createTapListener        from 'teeny-tap'
import getPathInTapListener     from '../sharedComponents/getPathInTapListener'

// Both the text and overlay MUST have same line spacing.
// And it needs to be larger than nominal (100%) so the
// background blue colName indicators have a gap between lines.
const LINE_HEIGHT = '140%'
const FONT_SIZE = constants.COL_FORMULA_EDITOR_FONT_SIZE
const MAX_LEGAL_LINE_LENGTH = constants.COL_FORMULA_MAX_LINE_LENGTH
const DEBUG = false

/*  DESIGN NOTES:

This is a un-controlled component with an internal state.
The internal state contains information owned ONLY by this
component and visible ONLY to this component.

The internal information strictly limited to this component:

 1) The text currently displayed in text input field.  This text is
   initialized using 'getDerivedStateFromProps' and this initialization
   is triggered by any change to colKey.  Hence, opening the editor or
   switching columns while the editor is open will re-initialize the
   text input field to the users table.attributes.column[colKey].formula.
   However, while the text is under edit, the only consistently correct
   copy of the formula under edit exists in the local state.  Periodically
   (after TIMEOUT_DELAY milliseconds with no activity), the local state
   will be saved to table resource with a reactDispatch.  However, the text
   we save MAY NOT be an exact copy of the current text under edit as saved
   in the local state.

    -- IF the text under edit has a format error, we dispatch the erroneous text
   exactly as currently appears in the local state.  In this case the local
   formula text exactly matches the table resource

    -- IF the text under edit has no format/syntax errors, we DO NOT save
    the local state.  Instead, we save a version where we have standardized
    the spacing, indents, capitalization, etc.  Plus, we replace the column names
    with a custom encoding of the corresponding colKey.  (Effectively, formulas
    in the table resource are defined using colKeys, not colTitles.  Whereas formulas
    displayed to users (in formula viewers, or editors) use colTitles.

2) The local state owns the 'lastColKey'.  So getDerivedStateFromProps can do
   a re-initialization of the the formula text if/when we switch columns.

3) Warning message.  This is on oddball fact we must save.  We are forced
   to this choice by our higher priority decision to save a 'canonical formula
   format', rather than the formula 'as written by the user'.

   The reasoning is as follows:
   3a) Warnings are used when the user makes a python syntax error, but their
   usage is unambiguous.  So we prompt them with a warning that says 'you
   did not use the correct python, but we know what you meant and fixed your
   mistake.'   The goal here is 'teach' users python.
   Examples:
      - Forgetting a 'return' where it is obviously needed.
      - True, False, None, pi - but user writes user the wrong case.
      - Python functions start lower case - and user writes with upper case.

   3b) We do not fix the mistake in the local state (the formula under edit).
   The mistake is fixed when we save the canonical form.

   3c) Hence, potential warning messages ONLY live within this module.  We fix
   warnings before the formula is saved to the table resource.
   We could also save the warning message to the table state, but:
     - This is inconsistant with our general error handling strategy that only
       the client identifies and highlights errors.
     - Nobody else cares about a warning message, EXCEPT this component.

*/



type OwnProps = {
  heightLabel: number,
  heightMaxViewMode: number,
  heightEditMode: number,

  width: number,
  tableid: string,   // Needed to save state
  colKey:number,
  rowKey:number,
  // depColHeader selected: isCellMode is false. Pretty format is HTML expressions only.
  // depColCell   selected: isCellMode is true.  Pretty format is annotated with row's numerical values.
  isCellMode: boolean,
  // Next arg is needed for two reasons:
  //  - We use the tableComputedData.parsedScryFormula to initialize the edit window.
  //  - We need all other column's derivedAttributes to convert internally encoded
   //   colKeys to the corresponding user column names, as well as
  //    assign colKeys to user's typed colNames, etc.
  tableComputedData: TableComputedData,
  // We need all 3 below to manage message priorities and source of errors:
  // For formula's, potential errMsg can be rendered by his component ( in <EditColMessageDisplay> )
  // However, the msg to be rendered may come from either the parent component,
  // or this component.
  errMessageFormula: string,  // An error message that can only be determined in tableComputedData
  errMessageHeader: string,   // An error message from the header that we display in the
                              // available space after the text   'Formula :'
                              // Current design is this is never used. (always passed '')
                              // (not used because we render header input errors
                              // to the prior available space in the 'Description :'
  passActiveStateToParent: (activeState: boolean) => void,
}

type DefaultProps = {
  wrnMessage: string
  passActiveStateToParent: (activeState: boolean) => void
}

type Props = OwnProps & DefaultProps

type LocalState = {
  lastColKey: number,
  currentText: string,  // The string inside the textarea; Single string representing multiple formula lines.
  isActive: boolean,
  scryFormula_textarea  : ScryFormula,
}

export default class EditColFormula extends Component<Props, LocalState> {
  static defaultProps: DefaultProps = {
    wrnMessage: '',
    passActiveStateToParent: (_: boolean) => {}
  }

  constructor(props: Props) {

    super(props)
    this.state={
      lastColKey: -1,
      currentText: '',
      isActive: false,
      scryFormula_textarea  : getDefaultScryFormula( ),
    }
    document.addEventListener( 'selectionchange', this.handleSelectionChange )
  }


  static getDerivedStateFromProps( nextProps:OwnProps,   prevState:LocalState ) {
    if ( !nextProps.tableComputedData.canEdit ) {
      return {} // Initialized defaults are fine.
    }
    const {colKey, tableComputedData} = nextProps
    const {derivedColAttributesArray} = tableComputedData
    const isNewColumn = (nextProps.colKey !== prevState.lastColKey)
    const { parsedScryFormula } = derivedColAttributesArray[colKey]
    const colTitleArr           = derivedColAttributesArray.map( c => c.colTitle )
    const isBadColNameArr       = derivedColAttributesArray.map( c => c.isBadColName )
    // I need a very specific input format for this params because the 
    // function convertToUserColNames( ) is also used by some memoization code.
    const paramsObj = { colTitleArr, isBadColNameArr }
    if ( isNewColumn && parsedScryFormula ) {
      let scryFormula_textarea = Object.assign( parsedScryFormula )
            // Next func replaces encoded colKey's with the corresponding current colName:
      let result = convertToUserColNames( scryFormula_textarea.formulaSubStrings, paramsObj )
      // I can't edit the scryFormula here as it is part of the new session state.
      // Appears I don't need to modify the scryFormula_textarea.formula strings as it seems
      // to NOT effect the tool behavior.  But, not completely sure of this conclusion.
      //scryFormula_textarea.formulaSubStrings = result.formulaSubStrings
      //scryFormula_textarea.formulaStrings    = result.formulaStrings
      //let currentText = scryFormula_textarea.formulaStrings.join('\n')
      let currentText = result.formulaStrings.join('\n')
      return { lastColKey:colKey, currentText, scryFormula_textarea, }
    }
    return { }
  }

  handleSelectionChange = () => {
    // TODO: check with JPS if this should have the same logic as EditTextWithLinks
  }

  shouldComponentUpdate( nextProps:OwnProps,   nextState:LocalState ) {
    if ( nextProps.colKey            !== this.props.colKey      ||
         nextState.currentText       !== this.state.currentText ||
         nextProps.errMessageFormula !== this.props.errMessageFormula ||
         nextProps.errMessageHeader  !== this.props.errMessageHeader ||
         nextProps.heightEditMode    !== this.props.heightEditMode ||
         nextProps.tableComputedData !== this.props.tableComputedData
       ) {
       //console.log( 'EditFormula.shouldComponentUpdate? -- Yes' )
      return true
    }
    //console.log( 'EditFormula.shouldComponentUpdate? -- No' )
    return false
  }
  
  tapListener = null
  onFocusHandler = ( e: FocusEvent<HTMLTextAreaElement> ): void => {
    this.highlightEditorParentDiv( )
    if (!this.tapListener && rStateFloatingPalette.floatingPaletteDomNode) {
      this.tapListener = createTapListener(rStateFloatingPalette.floatingPaletteDomNode, this.handleTap)
    }
    this.props.passActiveStateToParent(true)
  }

  componentWillUnmount = () => {
    //console.log( 'Unmount - this.timeoutID ', this.timeoutID)
    // Is there a pending local state change we have not yet dispatched?
    // Probably not, unless the user is extremely fast on the user interface.
    // But easy to check:
    if ( this.props.tableComputedData.canEdit && this.timeoutID !== null ) {
      this.updateReactState( )
    }
    document.removeEventListener( 'selectionchange', this.handleSelectionChange )
    if (this.tapListener) {
      this.tapListener.remove()
      this.tapListener = null
    }
  }

  handleTap = ( e: MouseEvent<HTMLDivElement> ) => {
    // DEBUGGING handletap !!!!!!!
    // Next console.log is not output to the console until well after this
    // function has been called.  Reason unknown.  Happens to two nearly identical
    // tapHandlers if EditTextWithLinks and EditColFormula
    // Put and console.log into the parentComponent function: this.props.passActiveStateToParent
    // A console.log at that location appears to print 'temporally' in the correct order of execution.
    // console.log( 'EditColFormula handleTap() -> passing isActive(true) to parent')
    const path = getPathInTapListener(e)
    const isActive = path.some( thisClassName => {
      return ( thisClassName === 'rc_EditColFormula' )
    })
    this.props.passActiveStateToParent(isActive)
    this.setState({isActive})
  }


  parseFormula = ( linesArray: string[], colKey: number,
      derivedColAttributesArr: DerivedColAttributes[] ): ScryFormula => {

      // did some timing test on the speed of the parser.
      // With a formula with ! substrings to find, parse, error check-
      // About 3ms per character edit;  Plenty fast for this per character
      // parsing.
      const colTitleArr = derivedColAttributesArr.map( c => c.colTitle )
      const internalDataTypeArr = derivedColAttributesArr.map( c => c.internalDataType )
      const isBadColNameBecauseRedundantArr = derivedColAttributesArr.map( c => c.isBadColNameBecauseRedundant )
      const isDeletedArr = derivedColAttributesArr.map( c => c.isDeleted )
      const isBadColNameArr = derivedColAttributesArr.map( c => c.isBadColName)
      var scryFormula = formulaParser( linesArray, colKey, colTitleArr, isBadColNameBecauseRedundantArr, isDeletedArr, isBadColNameArr ) 
      errorCheckLines( scryFormula, colKey, colTitleArr, internalDataTypeArr )
      errorCheckFormulaStructure( scryFormula, colTitleArr )
      if ( scryFormula.errorID === '' ) {
        getInputArgColKeysAndErrorCheckInputs(  scryFormula, colKey, derivedColAttributesArr )
        if ( scryFormula.errorID !== '' ) {
          derivedColAttributesArr[colKey].isBadFormulaColRef = true
        }
      }
      return scryFormula
  }

  TIMEOUT_DELAY = 300
  timeoutID: NodeJS.Timeout | string | number | undefined = undefined


  // Replace the tab character with indent of 'FORMULA_INDENT_LENGTH'
  // Will need to reset current cursor position.
  // See note below on removing this textArea component from
  // the tabIndex rotation, and treating it 'special'
  // Specifically, where tab keypress => indent

  handleKeyDown = ( e: KeyboardEvent<HTMLTextAreaElement> ): void => {
    if ( e.key === 'Tab' ) {
      let target: HTMLTextAreaElement = e.target as HTMLTextAreaElement
      var cursor = target.selectionStart
      var priorText = target.value.substring(0,cursor)
      var priorLineFeed = cursor
      while ( priorLineFeed > 0 && priorText[priorLineFeed] !== '\n' ) { priorLineFeed-- }
      var cursorPositionInThisLine = cursor - priorLineFeed - 1 // -1 because cursor is always 1 greater then the preceeding char
      var mod4 = cursorPositionInThisLine % 4
      var numSpacesToNextTab = (mod4 === 0 ) ? constants.FORMULA_INDENT_LENGTH : constants.FORMULA_INDENT_LENGTH - mod4
      var indentStrg = (' ').repeat(numSpacesToNextTab)
      // Next line prevents tabbing focus input to next subsequent input
      // Also seems to prevent onChange handler, but not really sure on this point.
      // But need to call updateLocalState directly, less it will not be called on onChange
      e.preventDefault()   // Prevents tabbing focus input to next subsequent input  Also seems to prevent onChange handler
      const newEvent = {...e, target}
      newEvent.target.value = priorText + indentStrg + target.value.substring(cursor)
      newEvent.target.selectionStart = cursor + numSpacesToNextTab
      newEvent.target.selectionEnd   = cursor + numSpacesToNextTab
      this.updateLocalState( newEvent )
    }
  }

  updateLocalState = ( e: SyntheticEvent<HTMLTextAreaElement> ): void => {

    // Standard cleaning of input text:
    const options = { mergeSpaces : false,
                      deleteLeadingSpaces : false,
                      ignoreLineFeeds: false }
    const target: HTMLTextAreaElement = e.target as HTMLTextAreaElement
    var {newValue, newSelectionStop} = cleanScryInputText2( target.value, target.selectionEnd, options )
    const newEvent = {...e, target}
    if ( target.value.length !== newValue.length ) {
      newEvent.target.value = newValue.slice()  // This line also resets selection start to useless value.
      newEvent.target.selectionStart = newSelectionStop
      newEvent.target.selectionEnd   = newSelectionStop
    }

    // Truncation of characters if expression too long:
    let priorSelectionStart = newEvent.target.selectionStart
    let numDeletedChars = 0
    const linesArray = newEvent.target.value.split(/\r?\n/)
    linesArray.forEach( (thisLine,i) => {
          // Debugging lines.  Difficult to get proper cursor placement
          // after deletions.  Here some debugging console statements:
          //console.log( 'As entered: 1st line- numChars & text:', thisLine.length, thisLine )
      var {legalCharCount} = findLegalCharCount( thisLine, MAX_LEGAL_LINE_LENGTH, FONT_SIZE )
          //console.log( 'isLegal, lineLength, legalLineLength', isLegal, thisLine.length, legalCharCount )
      // We are going to keep one additional character after the legalCharCount.  Hence, the expression
      // remains illegal, shows as illegal with red highlighting, and the user has a character they can
      // delete to make the expression legal again.
      // Therefore, we ONLY delete one or more characters if the input length of this line is
      // greater than legalCharCount + 1
      if ( thisLine.length > legalCharCount+1 ) {
        // Next line May or May not truncate any characters!
        // Because it will keep the first illegal character.
        // Also, next line does NOT truncate characters 1:1 with added characters.
        // For example, one must add two spaces (narrow chars) before one sees a
        // coresponding trailing digit (wide character) being truncated.
        let startLen = thisLine.length
        linesArray[i] = linesArray[i].slice(0, legalCharCount+1 )
        numDeletedChars += startLen - linesArray[i].length
           //console.log( 'lineNum', i, 'startLen', startLen, 'numDeletedChars', numDeletedChars )
      }
      //console.log( '' )
    })

    if ( numDeletedChars > 0 ) {
      const truncatedTargetValue = linesArray.join( '\n' )
      newEvent.target.value = truncatedTargetValue
      newEvent.target.selectionStart = priorSelectionStart - numDeletedChars + 1
      newEvent.target.selectionEnd   = newEvent.target.selectionStart
    }

    // Parse what remains and set the local state:
    const {tableComputedData, colKey} = this.props
    const scryFormula_textarea = this.parseFormula( linesArray, colKey, tableComputedData.derivedColAttributesArray )
    this.setState({ currentText: newEvent.target.value, scryFormula_textarea })

    clearTimeout(this.timeoutID)
    this.timeoutID = setTimeout( this.updateReactState, this.TIMEOUT_DELAY)
  }

  updateReactState = ():void => {
    //console.log( 'Updating React Table Resource' )
    if ( this.timeoutID ) {
      clearTimeout(this.timeoutID)
      this.timeoutID = undefined
    }

    const {colKey} = this.props
    const {scryFormula_textarea, currentText} = this.state
    if ( scryFormula_textarea.errorID === '' ) {
      // The formula saved to table state uses canonical spacing.
      var result = createTableidSpacedStrings( scryFormula_textarea )
      //console.log( 'result', result ) 
      // and replaces colNames with '_ColKey_xx_'
      var {formulaStrings:formula} = convertToTableidColNames( result.formulaSubStrings )
    } else {
      // The formula saved to table state is exactly 'as written', including the error.
      formula = currentText.split(/\r?\n/)
    }
    //console.log( 'saved formulaLength =', formula ) 
    const mods = [{
      newVal: formula,
      path: `attributes.columns[${colKey}].formula`
    }]
    reactDispatch(mods, 'Formula Edits', '', 'tables', this.props.tableid)
  }

  // Next three components are to manage the overlay editors 'focus' highlight.
  // The input with focus is the top 'transparent text' textArea.
  // However, this textArea may be taller or shorter than the parentDiv which
  // contains the scroll bar.  So when the textArea recieves focus the focus
  // highlight does not align with the container the user believes to see.
  // So we remove the highlight from the textArea, and use the textArea
  // onFocus and onBlue to instead highlight the container they see.
  editorParentDiv : OptionalHTMLDivElement = null
  highlightEditorParentDiv = ( ) => {
    if ( this.editorParentDiv ) {
      this.editorParentDiv.style.borderStyle = 'solid'
      this.editorParentDiv.style.borderColor = constants.SCRY_SELECTION_HIGHTLIGHT_COLOR
    }
  }
  noHighlightEditorParentDiv = ( ) => {
    if ( this.editorParentDiv ) {
      this.editorParentDiv.style.borderStyle = 'inset'
      this.editorParentDiv.style.borderColor = constants.COLHEADER_INPUT_BORDER_COLOR
    }
  }


  refEditorParentDiv = (element: OptionalHTMLDivElement): void => {
    this.editorParentDiv = element
  }


  render() {
    const {heightLabel, heightMaxViewMode, heightEditMode, width, errMessageFormula, errMessageHeader,
      colKey, rowKey, tableComputedData, isCellMode } = this.props
    const {canEdit} = tableComputedData
    const {parsedScryFormula} =  tableComputedData.derivedColAttributesArray[colKey]
    const {scryFormula_textarea, currentText} = this.state
    const isValidFormula = (parsedScryFormula !== null && errMessageFormula === '')
    const numLines = Math.max( 4, scryFormula_textarea.formulaStrings.length )
    //console.log( 'Render EditColFormula',heightLabel, heightMaxViewMode, heightEditMode   )

    // To overlay the textArea and the Div, we need to emperically determine
    // the (horz,vert) alignment difference.  And it varies by browser.
    // To find emperically, set the 'color' for the textArea to 'green' (normally is set 'transparent')
    // Now both sets of text are visible.  Adjust the vertical and horizontal alignment until they align.
    //
    //  12/19/2019  Chrome 87.0.4280.88             (0,0)   (horz offset, vert offset)
    //  12/19/2019  Firefox 71.0                    (0,2)
    //  12/19/2019  Safari 14.0.1(14610.2.11.51.10) (0,2)
    //
    // Now pick a suitable alignment for all browsers.
    // Remember -- users do not see the double text.  All we are aligning is
    // the insertCursor of the TextArea to the colored text in the Div.
    // We can afford 1-2 px of error in horizontal direction.  And 0-5px in vertical direction.

    // ALSO:  For word wrapping to work identically on both boxes -- meaning the
    // effective cursor jumps to the next line at exactly the same character.
    // THEN: use the element inspector to make sure they have identical widths --
    // after padding, widths, margins, etc.

    // These two attributes shift the underlying (visible) color coded text:
    const horizontalAlignment = 0
    const verticalAlignment = 1
    // These attributes - keep identical for both the TextArea and underlying Div!!
    const horizontalPadding = 6
    const verticalPadding = 4


    // OPTIONAL ERROR AND WARNING MESSAGES:

    // 1st priority: Any formula parsing error message in scryFormula_textarea

    // 2nd priority: errMessageFormula which comes from tableComputedDataErrorID
    //    Table cache does the same 'within a formula' error checking,
    //    Hence, any error found in scryFormula_textarea will also match
    //    the passed in value for tableComputedDataErrorID.
    //    However, the tableComputedData does addition 'between column'
    //    error checks.  Specifically:
    //     1- Are one or more colName arguments part of a circular reference?
    //     2- Is a line length > max character count (this test done last in tableComputedData by necessity).
    //     3- Is an input colName missing(was deleted), redundant, or reserved?

    // 3rd priority: errMessageHeader.   Any errors found in the EditColHeader.
    //          Jan/2022 -- Header errors messages now displayed in separate area.
    //          Hence, the errMessageHeader currently hardwired to input ''.
    //     Priority 3a: column title error checker (missing, reserved, duplicate)
    //     Priority 3b: rowNames error checker (duplicate)
    //     Priority 3c: rowNames error checker (missing)
    //     Priority 3d: Missing units text input value (missing)
    //     Priority 3e: Missing column description (missing)

    // 4rth priority: wrnMessage from formula parser.
    //          Jan/2022 -- Also going to hardwire this to ''.  Althought the
    //          parser code will still check and create wrnMessages.
    //     Warnings will ONLY be found in scryFormula_textarea.  Because all warnings
    //     are 'repaired' prior to saving the formula strings to table state.
    //     Hence, tableComputedData.scryFormula will never contain a warningID!

    // 5th priority: wrnMessage from the colHeaderEditor
    //     None are checked or passed at this time. (Dec (2021)

    // Error messages NOT displayed in viewMode:
    var errMessage = ''
    var wrnMessage = ''

    if (canEdit) {
      wrnMessage = scryFormula_textarea.warningID
      // 1st priority: formula parsing error in local editor text
      errMessage = scryFormula_textarea.errorID
      // 2nd priority is any global formula errors found in
      // derivedColAttributes (column name references missing,
      // circular colName dependencies, etc)
      if ( errMessage === '' ) {
           errMessage = errMessageFormula  // from tableComputedData.derivedColAttributesArray[colkey]
      }
      // 3rd priority is when formula is valid, is error msg passed in by parent.
      // Currently, this is hardwired to empty string.
      // Hence, this error message is ONLY and EXCLUSIVELY tied to the formula parser.
      if ( errMessage === '' ) {
           errMessage = errMessageHeader  // from EditColHeader inputs.
      }
    }


    const borderColor = constants.COLHEADER_INPUT_BORDER_COLOR


    // This block of code for displaying formulas using htmlScript
    // Use only for Debugging, to insure html and reactNode formula formats
    // render identically.
    var htmlFormula = ''
    if ( DEBUG && parsedScryFormula ) {
      const htmlFormulaStrings = createPrettyFormulaHTMLString( parsedScryFormula, colKey,
        tableComputedData, isValidFormula )
      htmlFormula += '<div>'
      for (const thisLine of htmlFormulaStrings) {
        htmlFormula += thisLine
      }
      htmlFormula += '</div>'
    }


    // DO NOT modify className={'rc_EditColFormula'} !!
    // Unless you also change the reference to name in tapListener.
    return (
      <div className={'rc_EditColFormula'}
        style={{
          position:'relative',
          width, height: '100%',
          fontSize: FONT_SIZE,
        }}
      >

          <div className={'Label'}
            style={{
              height: canEdit ? 32 : 20, width: 60,
              marginLeft: canEdit ? 20 : 0,
              paddingTop: (heightLabel-FONT_SIZE)/2 -1,
              //background: 'green',
            }}>
            {'Formula\u2009:'}
          </div>

{canEdit &&
          <div className={'placementContainer'}
            style={{position:'absolute', 
              left: 92, 
              // Shift a two line error message a big off centered, upward.
              top: errMessage.length>1 ? -4 : 0,  
            }}>
            <EditColMessageDisplay
              width={width-92}
              height={heightLabel}
              errMessage={errMessage}
              wrnMessage={wrnMessage}/>
          </div>
}


{isCellMode && parsedScryFormula &&
        <div className={'EditCellFormulaComponent_viewMode'}
          style={{
            position:'relative', top:0, left:0,
            width,
            height: DEBUG ? '50%' : '100%',
            maxHeight: heightMaxViewMode,
            fontSize: FONT_SIZE,
            paddingLeft: 5, paddingTop:6, paddingBottom: 6,
            marginBottom: ( isCellMode ) ? 5 : 0,
            overflow: 'auto',
            background: constants.SCRY_WHITE,
            borderWidth: 2, borderStyle: 'inset', borderColor, borderRadius: 5,
          }}>

              <CreatePrettyFormats
                scryExpression={parsedScryFormula}
                colKey={colKey}
                rowKey={rowKey}
                tableComputedData={tableComputedData}
                returnMode={isCellMode ? 'reactCellNode' : 'reactFormulaNode' }
                isValidFormula={isValidFormula} />

          </div>
}

{ /*  DEBUGGING HTML script versions of the formula:
  This next block is a version of the pretty printed formula,
  but using htmlScript, rather than a reactNode.
  Purpose is for testing whether the reactNode and htmlScript
  yield identical rendered formulas. The reactNode is used for rendering.
  The htmlScript version is used to measure the rendered length (error checking).
  Turning on this code will create two versions of the rendered pretty formula.
  They should be identical, especially in length as that is the sole purpose
  of the htmlScript version.

  To use:
    1) Set DEBUG to true
  */}

{DEBUG && !canEdit && htmlFormula &&
        <div className={'DebuggerForHTMLscripts'}
          style={{
            position:'relative', top:0, left:0,
            width,
            height: '50%',
            fontSize: FONT_SIZE,
            paddingLeft: 5,
            // marginLeft : horzMargin,
            // marginRight: horzMargin,
            overflow: 'auto',
            //background: 'white',
            borderWidth: 2, borderStyle: 'inset', borderColor,
          }}
          dangerouslySetInnerHTML={{ __html: htmlFormula }}  // Special debugging code.
        />
}

{canEdit &&
        <div className={'EditCellFormulaComponent_editMode'}
          ref={ this.refEditorParentDiv }
          style={{
            position:'relative', top:0, left:0,
            width,
            height: heightEditMode,
            fontSize: FONT_SIZE,
            // This vert margin only here so I can toggle it between 0 and 1,
            // depending on whether focus outline (my own design for this div)
            // is or isn't present.
            overflow: 'auto',
            background: 'white',
            borderWidth: 2, borderStyle: 'inset', borderColor, borderRadius: 5,
          }}
        >

          <div className={'InternalScrolledDiv'}
            style = {{
              width:  '100%',
              height: '100%',
            }}>

            <div className={'ColorCodedFormula'}
              style={{
                position:'absolute', top:0, left:0,
                width: '100%',
                fontSize: FONT_SIZE,
                marginTop: verticalAlignment,
                marginLeft: horizontalAlignment,
                paddingTop: verticalPadding,
                paddingLeft: horizontalPadding,
                lineHeight: LINE_HEIGHT,
                wordWrap: 'break-word',
                overflow: 'hidden',
                //background: 'white',
              }}>
                 <ColorCodedFormula scryExpression={this.state.scryFormula_textarea}/>
            </div>

            {/*I allow the tab character inside formula editor.
            // Because I keep hitting the tab key to get an ident.
            // So likely others will do the same.
            // Hence tabKey in this textarea will be translated as four spaces.
            // However, we do not want users to navigate to this textArea using
            // the tab.  Because otherwise, the tab will not work to
            // navigate out of the textArea.  This component is ONLY
            // visible to the curator.  So I feel OK about making this textArea
            // 'NOT' tab navigatable. */}

            <textarea className={'OverlayTextAreaWithTransparentFont'}
              style={{
                position:'absolute', top:0, left:0,
                fontSize: FONT_SIZE,
                width: '100%',
                paddingTop: verticalPadding,
                paddingLeft: horizontalPadding,
                paddingRight: 0,
                lineHeight: LINE_HEIGHT,  // LINE_HEIGHT * max( totalLine or 10 ) is the scrollable height
                //color: 'red', opacity: 0.3,   // For debugging overlay.
                color: 'transparent', background: 'transparent', caretColor:'black',
                resize:'none',
                borderWidth: 0,
                overflow: 'hidden',
                // Next attribute hides the blue 'got focus' outline
                // The focus highlight is applied to the parent container.
                outline: 'none',
                wordBreak: 'break-all',
              }}
              onChange={this.updateLocalState}
              onFocus={ this.onFocusHandler }
              onBlur={this.noHighlightEditorParentDiv }
              onKeyDown={this.handleKeyDown}
              tabIndex={-1}
              rows={numLines}
              value={currentText}
              autoComplete='off'
              spellCheck='false'
            />

          </div>
        </div>
}

      </div>
    )
  }
}
