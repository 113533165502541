
import React, {Component} from 'react'
import type {Tablelook, Table} from '../types'
//import cleanScryTextInputs from '../cleanScryTextInputs'

type Props = {
  tablelook: Tablelook,
  table: Table,
}
export default class EditTitle extends Component<Props> {
  render() {

    //console.log( 'Call to render EditCellString'  )
    //const {currentInput} = this.state
    const fontSize = '12px'
    //const valueLength = Math.max(Math.min(measureText(String(inputValue), fontSize) + 6, 320), 100)
    //const valueLength = 200
    return (
      <div className={'rc_EditTitle'}
        style={{fontSize: fontSize, width:300, height:200}}>
          {'Here is something to render'}
      </div>
    )
  }
}
