import type { StatsBarLayout } from '../computedDataTable/getDefaultTableComputedData'
import type {
  ColumnStats,
  StatsDataType
} from '../computedDataTable/updateStatsShared'
import type { DerivedFilterRule } from '../sharedFunctions/filterRows'
import type { FormattingObj } from '../sharedFunctions/numberFormat'
import type {
  ColValues, InternalColDataType,
  Table, TableValues,
  Tabledata,
  Tablelook
} from '../types'
import type { ScryFormula } from './formulaTypes'
import type {
  AccessCellHTMLReturnType, AccessCellStringReturnType,
  ErrorRows,
  GetTableValue,
  TableComputedData,
  TableLayoutProps
} from './getDefaultTableComputedData'


import invariant from 'invariant'
import { isEqual, list } from 'radash'
import { create_sKeyArrStrg_from_seriesOrder } from '../computedDataPlotXY/plotUtils'
import { getDefaultDerivedColAttributes } from '../computedDataTable/getDefaultTableComputedData'
import { createDerivedFilterRuleArray, filterRows_memoizedFunc } from '../sharedFunctions/filterRows'
import { getMemoizedResult, hashParamsAndRefs } from '../sharedFunctions/getObjectDiff'
import { getHyperlinkLabel } from '../sharedFunctions/isTypeHyperlink'
import { getFormattingObj, lookupTableForForcedUnits, numberFormat, stringFormat } from '../sharedFunctions/numberFormat'
import { logTime, startTimer, stopTimer } from '../sharedFunctions/timer'
import { deepClone } from '../sharedFunctions/utils'
import { formattingOptions_by_colDataType } from '../types'
import {
  getDefaultStatsBarLayout,
  getDefaultTableComputedData
} from './getDefaultTableComputedData'
import { heightCalculator, styleCalculator, widthCalculator } from './layoutCalculator'
import {
  calcColumnStats_async,
  calcDependentColumnValues_memoizedFunc,
  calcNewScrollTop,
  colDataErrorCheck_memoizedFunc,
  createCircularDependencyErrorMessage,
  createStatsBarLayout_memoizedFunc,
  errorCheckColNames,
  findShortestCircularReferencePath,
  formulaParseAndErrorCheck_memoizedFunc,
  getErroneousRowNames2_memoizedFunc,
  getInputArgColKeysAndErrorCheckInputs,
  errorCheckColOrder,
  sortRows,
} from './updateTableSupportFuncs'
//import { LightweightMod }   from '../types'
import constants from '../sharedComponents/constants'

const isTIMER_ENABLED = false   // When timing, set DEBUG to false; Console.log takes a lot of time.
const DEBUG = false
const lastVal = (a:Array<any>):any => a[ a.length-1 ]

export type TableComputedDataParams = {
  menuOption_isEditMode: boolean
  username: string
  tableHeight: number
  tableWidth: number
  renderIndex: number
  scrollLeftSessionState: number
  scrollTopSessionState: number
  activeStatsColKey: number,
  isPublisherRendered: boolean,
  isSearchBarRendered: boolean,
  isTableGridRendered: boolean,
}

export type TableComputedDataRefs = {
  table: Table
  tabledata: Tabledata
  tablelook: Tablelook
}

export type TableComputedDataOtherArgs = {
  priorTableComputedData: TableComputedData
}

export type TableComputedDataResult = {
  tableComputedData: TableComputedData
}

export type BasicFormulaParams = {
  colTitleArr: string[]
  isBadColNameArr: boolean[]
}

export type FormulaParams = BasicFormulaParams & {
  // This information is for cross-column error checking.
  isDeletedArr: boolean[]
  isDepColumnArr: boolean[]
  internalDataTypeArr: InternalColDataType[]
  isBadColNameBecauseRedundantArr: boolean[]
  useOptimization: boolean
  isJestTestCall: boolean
  formula: string[]
  colKey: number
}
export type FormulaRefs = {}
export type FormulaOther = {}
export type FormulaResult = {
  parsedScryFormula: ScryFormula
  isBadFormulaSyntax: boolean
}


export type DepValuesParams = {
  colKeyDepCol: number
  isErroneousDepCol: boolean
  isMissingDepCol: boolean
  inputColKeys: number[]
  isErroneousInputs: boolean[]
  isMissingInputs: boolean[]
  parsedScryFormula: ScryFormula
}
// TODO: JKS talk to JPS about not using an object like a sparse array
type DepValuesRefsBase = {
  [key: number]: ColValues
}
export type DepValuesRefs = DepValuesRefsBase & {
  //parsedScryFormula: ScryFormula
}
export type DepValuesOther = {
  tableValuesWorking: TableValues
}
export type DepValuesResult = {
  newColData: ColValues
  errorObj: ErrorRows
  isMissing: boolean
}


export type ColErrorParams = {
  internalDataType: InternalColDataType
}
export type ColErrorRefs = {
  columnData: ColValues
}
export type ColErrorOther = {}
export type ColErrorResult = {
  erroneousCells: ErrorRows
  missingCells: ErrorRows
}


export type RowNameErrorParams = {
  keyColumns: number[]
  numRows: number
}
export type ColKeyToColValuesMap = {
  [key: number]: ColValues
}
export type RowNameErrorRefs = ColKeyToColValuesMap
export type RowNameErrorOther = {}
export type RowNameErrorResult = {
  duplicateRowNames: ErrorRows
  missingRowNames: ErrorRows
  doesKeyColumnExist: boolean
}


export type FilterParams = {
  derivedFilterRules: DerivedFilterRule[]
  numRows: number
}
export type FilterRefs = ColKeyToColValuesMap
export type FilterOtherArgs = {
  DEBUG: boolean
  getTableValue: GetTableValue,
}
export type FilterResult = {
  filterRuleCounts: number[]
  filteredRowKeys: number[]
}


export type SortParams = {
  rowSortColIds: string[]
}
export type SortRefs = {}
export type SortOtherArgs = {
  hideErroneousValues: boolean
  internalDataTypes: InternalColDataType[]
  numRowsUnfiltered: number,
  getTableValue: GetTableValue,
}
export type SortResult = {
  sortedRowKeys: number[]
}


export type StatsHashParams = {
  colKey: number    // Not needed as reflected in columnRef.  Passed as useful debug info.
  statsDataType: StatsDataType  // InternalDataType | 'booleanTrueFalse'
  // We DON'T need the filtering objects!
  // Changes in filtering, are reflected as changes in rowKeys[]
}
export type StatsHashRefs = {
  columnRef: ColValues
  rowKeys: number[]
  erroneousCells: ErrorRows
}
export type StatsHashOtherArgs = {
  DEBUG: Boolean,
  tableid: string
}
export type StatsParams = {
  statsHashCode: number
}
export type StatsRefs = {}
export type StatsOtherArgs = StatsHashParams & StatsHashRefs & StatsHashOtherArgs
export type StatsResult = null | {
  columnStats: ColumnStats
}


export type StatsBarLayoutParams = {
  colTitle: string,
  colDataType: string,
  formatRule: string,
  formattingObj: FormattingObj,
  fontSize: number,
  canEdit: boolean,
}
export type StatsBarLayoutRefs = {
  columnStats: ColumnStats
}
export type StatsBarLayoutResult = {
  statsBarLayout: StatsBarLayout
}



export const updateTableComputedData_memoizedFunc = (inputParamsObj: TableComputedDataParams,
  inputRefsObj: TableComputedDataRefs, inputOtherArgsObj: TableComputedDataOtherArgs): TableComputedDataResult => {

  if (DEBUG) { console.log(`\n  CALLING updateTableComputedData( )`) }
  if (isTIMER_ENABLED) { startTimer('id_TableComputedData') }

  // Starting point (initialization) of a new tableComputedData is the prior calculated value !!
  // No need to create an new object reference.  In practice we will only
  // overwrite the prior tableComputedData with data that must change.
  // Changing the tableid will effectively overwrite the entire structure.
  // Edits on the same tableid will replace a minimal amount of information.
  // Difficult computations use memoized values from the prior calculations (if available),
  // otherwise, this function slows down to calculate the newly needed results.
  // 'Fast-to-compute' data is done every pass through this function.
  //
  // tableComputedData MUST be a superset of information, specifically:
  //    table, tablelook, tabledata, user, and owner resources
  // A renderedTable should be a function of only the tableComputedData.
  //
  // Not a requirement, just a convention we've choosen.  May still be
  // exceptions to the 'convention', but any exceptions are not intentional.

  const { table, tablelook, tabledata } = inputRefsObj
  const { tableWidth, tableHeight, username, menuOption_isEditMode,
    scrollLeftSessionState, scrollTopSessionState,
    isPublisherRendered, renderIndex,
    isTableGridRendered, activeStatsColKey } = inputParamsObj
  const { priorTableComputedData } = inputOtherArgsObj
  const numCols = table.attributes.columns.length

  // What is a good starting point?
  // The getDefaultTableComputedData value will always work as a starting point.
  // But it doesn't carry over any memoized results from the last calculation.
  // Hence, it is the worse case (slowest) test case for this function.
  // At this time getDefaultTableComputedData is used IFF:
  //    - tableid changes && new tableid has NO prior memoized data.
  //    - numRows changed (Could be made faster, but rare event not worth the coding)

  const shouldStartFromScratch = priorTableComputedData === undefined ||
    table.id !== priorTableComputedData.tableid ||
    tabledata.attributes.tableValues[0].length !==
    priorTableComputedData.numRowsUnfiltered
  if (shouldStartFromScratch) {
    var tableComputedData = getDefaultTableComputedData(numCols)
  } else {
    // Case of starting from tableComputedData
    // Only thing we really desire or re-use is the prior memoized results.
    tableComputedData = { ...priorTableComputedData }
  }

  // These are parameters from sessionState that map to tableComputedData
  tableComputedData.scrollLeft = 0  // Ignore the values from the last sessionState or update
  tableComputedData.scrollTop = 0  // In rare occasions, they MAY be invalid because of window
  // sizing change since last sessionState value was written.
  // Later in the function (after widthObj and heightObj are calced)
  // we will put constraints on the scroll positions, such that
  // it is the 'closest legal value' to the sessionState value.
  // Rarely will the constraint actually be needed.
  tableComputedData.currentTableTriplet = 'activeTableTriplet'
  tableComputedData.sessionStateRenderIndex = renderIndex

  tableComputedData.tableid = table.id
  tableComputedData.tablelookid = tablelook.id
  tableComputedData.tabledataid = tabledata.id

  tableComputedData.table = table
  tableComputedData.tabledata = tabledata
  tableComputedData.tablelook = tablelook

  tableComputedData.username = username
  tableComputedData.menuOption_isEditMode = menuOption_isEditMode
  tableComputedData.tableWidth = tableWidth
  tableComputedData.tableHeight = tableHeight

  tableComputedData.isPublisherRendered = isPublisherRendered
  tableComputedData.isTableGridRendered = isTableGridRendered

  const userid       = tableComputedData.userid       = tablelook.relationships?.owner?.data.id
  const tableOwnerid = tableComputedData.tableOwnerid = table.relationships?.owner?.data.id
  const canEdit      = tableComputedData.canEdit      = (userid === tableOwnerid && menuOption_isEditMode)
  var   numLockedCols= tablelook.attributes.numLockedCols
  const numRowsUnfiltered = tableComputedData.numRowsUnfiltered = tabledata.attributes.tableValues[0].length
  
  // Straight copies of some attributes:
  tableComputedData.pinnedRowKeys     = tablelook.attributes.pinnedRowKeys

  // Fast access to commonly used resource information.
  const {
    memoizedColumnCellErrors_byColKey,
    memoizedParsedFormula_byColKey,
    memoizedTableValuesDep,
    memoizedErroneousRowNames,
    memoizedFilteredRowKeysArr,
    memoizedRowOrder,
    memoizedStats,
  } = tableComputedData

  const { columns, isSortable } = table.attributes
  const { lookColumns, rowFilters, rowSortColIds } = tablelook.attributes
  const hideErroneousValues = (!canEdit)

  // We never directly touch the tableValues, whether they come from
  // the tableData resource (independent columns) or from our column caculator
  // memoized result (dependent columns).  But we need a reference to each
  // column of data, in one common array which I will call
  //     tableValuesWorking
  // These columns begin as empty arrays of unknown (don't care) references.
  //
  // HERE, we copy the tableData independent column references to tableValuesWorking.
  // LATER - when dependent column formulas are calculated, memoizedTableValuesDep is
  // the true owner of the tableValues for dependent columns, and those references
  // will be transferred to tableValuesWorking.
  //
  // Hence, the purpose of tableValuesWorking is:
  //     Holds the references to column data, so we can track when/if colData changes.
  //     The App's access to the data, however I never access this array
  //         directly, but only indirectly through getTableValue()
  //     NEVER access this array directly, unless it becomes a neccessity.
  //     Otherwise, keep to the rule that all cell value access is via getTableValue()
  //
  // This array is always 'filled' in with references.
  // Hence, doesn't matter what is already present in the array at this point in time.
  // The old data is almost always valid, but ALMOST doesn't help.
  // In the case where we clone a column, the existing tableValuesWorking is 'one column short'!
  // I simply recreate the tableValuesWorking array on every pass. (guarantees the proper length)
  // Which means we MUST always write the calculated depCol values to this array:
  //       1) IF we are calculating first time, each row value is written one by one to tableValues
  //       So we will see them in our working tableValuesWorking when memoization func returns.
  //       2) However, if  memoization returns a saved value, then we MUST copy the array reference
  //       to tableValuesWorking because the RPN execution does NOT run and does Not fill in
  //       the tableValuesWorking for us.
  const tableValuesWorking = list(columns.length - 1).map(() => new Array(0))
  const { tableValues } = tabledata.attributes
  for (const [colKey, thisCol] of columns.entries()) {
    if (!thisCol.isDepCol) {
      tableValuesWorking[colKey] = tableValues[colKey]
    }
  }
  tableComputedData.tableValuesWorking = tableValuesWorking


  // MUST errCheck colNames as a complete group, in order
  // to find redundant colNames.
  // This does the full verification of colName errors:
  //     missing, reserved, or redundant, ...
  const errorCheckArray = errorCheckColNames(columns)
  var reUsedColKeys: number[] = []
  var reCalcColKeys: number[] = []

  ////////////////////////////////////////////////////////////////////////
  // CREATE DERIVED COLUMN ATTRIBUTES;
  // We set some (~half) of the attributes.
  // Others are currently defaults, to be set as they are calculated.
  ////////////////////////////////////////////////////////////////////////

  const newColumns = []
  let globalScale = tablelook.attributes.globalScale
  for (const [colKey, thisCol] of columns.entries()) {
    let lookColumnAttributes = lookColumns[colKey]
    newColumns[colKey] = {
      ...getDefaultDerivedColAttributes(),
      ...tableComputedData.derivedColAttributesArray[colKey],
      colTitle: thisCol.colTitle,
      // We force a legal width at this time, (as opposed to inside the layoutCalculator).
      // Because we need the layoutCalculator to violate this minimum width rule when
      // the calculator is used for animations, specifically hiding or unHiding a column.
      constrainedScaledColWidth: Math.round(Math.max(constants.MIN_COL_WIDTH_AT_NOMINAL_SCALE, lookColumnAttributes.width) * globalScale) ,
      hiddenVal: lookColumnAttributes.hidden,
      isHidden: lookColumnAttributes.hidden > 0,
      colDataType: thisCol.colDataType,
      isKey: thisCol.isKey,
      internalDataType: (thisCol.colDataType.slice(0, 6) === 'number') ? 'number' : thisCol.colDataType as InternalColDataType,
      isDepCol: thisCol.isDepCol,
      isDeleted: thisCol.isDeleted,
      isDeletedNotRestorable: thisCol.isDeletedNotRestorable,
      deletedDate: thisCol.deletedDate,
      badColNameErrorID: errorCheckArray[colKey].errorID,
      isBadColName: (errorCheckArray[colKey].errorID !== ''),
      isBadColNameBecauseRedundant: (errorCheckArray[colKey].errorType === 'redundant'),
    }
  }
  tableComputedData.derivedColAttributesArray = newColumns
  var { derivedColAttributesArray } = tableComputedData

  /////////////////////////////////////////////////////////////////////////
  //  Column Formatting:
  /////////////////////////////////////////////////////////////////////////

  for (const [i, thisLook] of lookColumns.entries()) {
    let { colDataType, units } = columns[i]
    let formatRule = thisLook.formatRule
    if (formattingOptions_by_colDataType[colDataType].indexOf(formatRule) === -1) {
      // We have an potential problem where column.colDataType and lookColumn.formatRule
      // are NOT orthogonal.  A colDataType and the formatRule may be incompatible!
      // This should ONLY occur if the column.colDataType is changed by table owner,
      // and the linked tablelook.formatRule (owed by another person) is no longer
      // a legal option.  In this rare case we will use the 1st formatting option
      // available from the legal list.  We will NOT change the lookColumn.formatRule
      // saved state.  Up to the tablelook owner to change this recorded state if they
      // so choose to change the displayed default format.
      formatRule = formattingOptions_by_colDataType[colDataType][0]  // 1st option in available formats is the default
      derivedColAttributesArray[i].formatRule = formatRule
      derivedColAttributesArray[i].formattingObj = getFormattingObj(formatRule)
    } else { // expected path:
      derivedColAttributesArray[i].formatRule = formatRule
      derivedColAttributesArray[i].formattingObj = getFormattingObj(formatRule, { ...thisLook })
    }
    derivedColAttributesArray[i].units = units
    let { shouldForceUnits, forcedUnits } = lookupTableForForcedUnits(colDataType, formatRule)
    derivedColAttributesArray[i].shouldForceUnits = shouldForceUnits
    derivedColAttributesArray[i].forcedUnits = forcedUnits
  }

  if (isTIMER_ENABLED) { logTime('tableComputedData', 'tableComputedData initializations.') }

  ////////////////////////////////////////////////////////////////////////
  // Error check cell values -- ONLY independent columns at this time.
  // This check is memoized.  We must know the 'Cell errors or Missing values'
  // for independent columns PRIOR to calculating the dependent column values.
  ////////////////////////////////////////////////////////////////////////

  reUsedColKeys = new Array(0)
  reCalcColKeys = new Array(0)
  let newMemResults = new Array(0)
  for (const [colKey, thisCol] of derivedColAttributesArray.entries()) {
    if (thisCol.isDeleted || thisCol.isDepCol) { continue }  // uses default initialized of {}
    let colErrorParamsObj: ColErrorParams = { internalDataType: thisCol.internalDataType }
    let colErrorRefsObj: ColErrorRefs = { columnData: tableValues[colKey] }
    let colErrorOtherObj: ColErrorOther = {}
    let maxNumMemoized = 1  // Enough for the user play with three on/off selections (venn diagrams, ... )
    let memoFunc = colDataErrorCheck_memoizedFunc
    let colErrorPriorResults = memoizedColumnCellErrors_byColKey[colKey] // Only one saved prior result per colkey.
    let { result, isNewResult, newMemArr } = getMemoizedResult(colErrorPriorResults, colErrorParamsObj, colErrorRefsObj,
      colErrorOtherObj, memoFunc, maxNumMemoized)
    newMemResults[colKey] = newMemArr
    if (isNewResult) { reCalcColKeys.push(colKey) } else { reUsedColKeys.push(colKey) }
    tableComputedData.derivedColAttributesArray[colKey].missingCells = result.missingCells
    tableComputedData.derivedColAttributesArray[colKey].erroneousCells = result.erroneousCells

    // We can now do the error check for a column with no valid data of the current dataType
    // Compare the number of rows with the number of keys in the erroneousCells object
    // If equal, every row has a corresponding error key.  There is no valid data.
    // We make an exception for a table of one row.  Is a single missing value truely
    // a dataType misMatch?  I choose the answer 'no', because our search table may
    // return a single line table.  (when no matches) with empty cells.
    var isAllDataMissingOrWrongType = (numRowsUnfiltered > 1 &&
      Object.keys(result.erroneousCells).length +
      Object.keys(result.missingCells).length >= numRowsUnfiltered)
    tableComputedData.derivedColAttributesArray[colKey].isMismatchedType = isAllDataMissingOrWrongType   // entire column missing!

  }
  tableComputedData.memoizedColumnCellErrors_byColKey = newMemResults
  if (DEBUG && reUsedColKeys.length > 0) {
    console.log(`  Reuse colData cell error checking for colKeys ${create_sKeyArrStrg_from_seriesOrder(reUsedColKeys)}`)
  }
  if (DEBUG && reCalcColKeys.length > 0) {
    console.log(`  Calc  colData cell error checking for colKeys ${create_sKeyArrStrg_from_seriesOrder(reCalcColKeys)}`)
  }
  if (isTIMER_ENABLED) { logTime('id_TableComputedData', 'Find  missing or erroneous dependent colValues') }

  ////////////////////////////////////////////////////////////////////////
  // Derived column data, reorganized by colKey.
  // Typical method to pass minimal colData to memoized worker functions.
  ////////////////////////////////////////////////////////////////////////

  const isDepColumnArr      = derivedColAttributesArray.map( c => c.isDepCol )
  const isKeyColumnArr      = derivedColAttributesArray.map( c => c.isKey )  // Refers to database 'key' icon in header
  const colTitleArr         = derivedColAttributesArray.map( c => c.colTitle )
  const isDeletedArr        = derivedColAttributesArray.map( c => c.isDeleted )
  const isBadColNameArr     = derivedColAttributesArray.map( c => c.isBadColName )
  const internalDataTypeArr = derivedColAttributesArray.map( c => c.internalDataType )
  const formattingObjArr    = derivedColAttributesArray.map( c => c.formattingObj )
  const erroneousCellsArr   = derivedColAttributesArray.map( c => c.erroneousCells )
  const isErroneousColArr   = derivedColAttributesArray.map( c => c.isErroneousCol )
  const isMissingColArr     = derivedColAttributesArray.map( c => c.isMissingCol   )
  const hiddenValArr        = derivedColAttributesArray.map( c => c.hiddenVal  )
  const isHiddenArr         = derivedColAttributesArray.map( c => c.isHidden  )
  const constrainedScaledColWidthArr    = derivedColAttributesArray.map( c => c.constrainedScaledColWidth )
  const isBadColNameBecauseRedundantArr = derivedColAttributesArray.map( c => c.isBadColNameBecauseRedundant )
   
  // Always error check colOrder before pushing to resource.
  // Once it is bad in the resource, pain in the butt to repair!!
  errorCheckColOrder( isDeletedArr, tablelook.attributes.colOrder, colTitleArr )
  const colOrder = tableComputedData.colOrder =  tablelook.attributes.colOrder
  const hiddenVal_ByColIndex = tableComputedData.hiddenVal_ByColIndex = colOrder.map( index => hiddenValArr[index] )
  const isHidden_ByColIndex = tableComputedData.isHidden_ByColIndex = colOrder.map( index => isHiddenArr[index] )
  const colWidth_ByColIndex = tableComputedData.colWidth_ByColIndex = colOrder.map( index => constrainedScaledColWidthArr[index] )


  ////////////////////////////////////////////////////////////////////////
  // Parse & compile the dependent column formulas.
  // This step is memoized because parsing formulas is compute
  // But more importantly, because we need to know whether a parsed column
  // formula has been modified (or not) before we can ask the question
  // of which columns need dependent values re-calculated. (Which is
  // compute intensive)
  //
  // This requires a lot of information beyond just the 'text' of the
  // formula.  We need to do all the required error checking within
  // a parsed formula, PLUS the error checking between columns.
  // For example: Any colTitle change requires all dependent formulas
  // to be re-parsed.  Else how to know whether that formula references
  // the modified colTitle?
  ////////////////////////////////////////////////////////////////////////

  reUsedColKeys = new Array(0)
  reCalcColKeys = new Array(0)
  newMemResults = new Array(0)
  for (const [colKey, thisCol] of derivedColAttributesArray.entries()) {
    if (!thisCol.isDepCol || thisCol.isDeleted) { continue }
    // Although MOST of the paramsObj is common across all series,
    // the creation of this object must be NEW, for every colKey.
    // Its NOT reusable because a copy is being kept witjh the memoized result.
    let formulaParamsObj: FormulaParams = {
      // This information is for cross-column error checking.
      colTitleArr, isDeletedArr, isBadColNameArr, isDepColumnArr, internalDataTypeArr,
      isBadColNameBecauseRedundantArr,
      useOptimization: true,
      isJestTestCall: false,
      // The 'text' as entered by user, or canonical formatted version of text
      // if it is comming from a prior successfully parsed (error free) saved resource.
      formula: columns[colKey].formula,
      colKey: colKey
    }
    let formulaRefsObj: FormulaRefs = {}
    let formulaOtherArgsObj: FormulaOther = {}
    let memoFunc = formulaParseAndErrorCheck_memoizedFunc
    let maxNumMemoized = 2
    let formulaPriorResultsArr = memoizedParsedFormula_byColKey[colKey]
    // <FormulaParams, FormulaRefs, FormulaOther, FormulaResult>
    let { result, isNewResult, newMemArr } = getMemoizedResult(formulaPriorResultsArr, formulaParamsObj, formulaRefsObj,
      formulaOtherArgsObj, memoFunc, maxNumMemoized)
    newMemResults[colKey] = newMemArr
    if (isNewResult) { reCalcColKeys.push(colKey) } else { reUsedColKeys.push(colKey) }
    thisCol.parsedScryFormula = result.parsedScryFormula
    thisCol.isBadFormulaSyntax = (result.parsedScryFormula.errorID !== '')
  }
  tableComputedData.memoizedParsedFormula_byColKey = newMemResults
  if (DEBUG && reUsedColKeys.length > 0) {
    console.log(`  Reuse parsed/compiled formulas for colKeys ${create_sKeyArrStrg_from_seriesOrder(reUsedColKeys)}`)
  }
  if (DEBUG && reCalcColKeys.length > 0) {
    console.log(`  Calc  parsed/compiled formulas for colKeys ${create_sKeyArrStrg_from_seriesOrder(reCalcColKeys)}`)
  }
  if (DEBUG && reCalcColKeys.length + reUsedColKeys.length === 0) {
    console.log(`  No formulas to parse & compile`)
  }
  if (isTIMER_ENABLED) { logTime('id_TableComputedData', 'Parse and compile dependent column formulas') }


  /////////////////////////////////////////////////////////////////////////
  //  Classify each column with 'isMissing' and 'isErroneous' flags.
  /////////////////////////////////////////////////////////////////////////
  /*
  DEFINITION OF AN 'ERRONEOUS' COLUMN:
      1) A Column may have different types of errors.
            - Invalid formula (many ways to write an invalid formula)
            - Valid formula, but circular dependency -- (global characteristic)
            - Valid formula, but reference to deleted col -- (global characteristic)
            - Invalid colName - redundant, reserved, missing
            - DataType that is mismatched to the column values (no valid cells)
            (probably be adding to this list as needed)
      2) Any of the above errors defines an 'Erroneous Column'.
      3) An Erroneous Column is 'something for the publisher to fix'.
      3) 'Erroneous Column' === 'Column Name rendered in RED'  (Definition works both directions)
      4) When one opens the ColumnHeader editor, the source of the error will
        highlighted with red ( name, formula, dataType, ... )

  DEFINITION OF A 'MISSING' COLUMN
      1) Any column consisting of 100% empty cells.
      2) Could be Independent col that has no valid cells because wrong data type.
      3) Could be dependent col where the col formula has an error.  Or wrong data type.
      3) Could be dependent col where an input argument is 'MISSING' or a deleted col.
      4) Could be dependent col with circular dependencies

  ERRONEOUS columns will eventually lead to MISSING columns. But for any
  individual column, the two attributes are independent.  All four possible
  permutations are possible:
      1)  Erroneous && !Missing:  A independent column with good column data, but an invalid col name.
      2)  Erroneous &&  Missing:  A dependent column with a bad formula.
      3) !Erroneous && !Missing:  The desired state for all columns
      4) !Erroneous &&  Missing:  Nothing to fix in this column, but missing because an inputArg is missing.

  Another definition of !Missing: If we can show current correct cell data,
  then we should, even if the column itself is erroneous (for example a redundant colName.)

  An ERRONEOUS column is something we expect the curator to fix.
  All MISSING columns are fixed 'indirectly' by fixing all ERRONEOUS columns

  A MISSING column is shorthand for 'All cells are empty'.  However, the
  tableData[][] and depTableData[][] may not actually be empty!  For example,
  the tableData[][] could be full of strings, yet the column is missing because
  the dataType === number.

  A depCol may already be tagged as 'isMissing' (formula error, input arg problem, ... )
  But it may also be tagged at the time of formula calculations.  Because isMissing will
  propagate to 'downstream' dependent columns.  In either case, the logic to calculate
  a column of dependent values includes:
       if (isMissinge) return new Array(numRows).fill('')
  And the column will appear in the table as empty.
  
  getInputArgColKeysAndErrorCheckInputs() returns an array of colKeys that are
  the inputs to each dependent formula.
  This function may also set errorMsg ON THE FORMULA COLUMN for problems associated with an input column:
  These are the possible errors found at this time:
      scryFormula.errorID = `Formula references a deleted column ' ${title} '.<br>Select a new column.`
      scryFormula.errorID = `Formula references a column with a missing name.<br>Give all columns a name.`
      scryFormula.errorID = `Formula references duplicate column name ' ${colTitle} '.<br>Rename column or select a new column.`
      scryFormula.errorID = `Formula references illegal column name ' ${colTitle} '.<br>Rename column or select a new column.`
  */

  const inputArgsArr: number[][] = []       // inputArgsArr[formula colKey][ input arg colKeys, ... ]
  // sparse matrix, with only dependent colKeys having data
  const dependentColKeysArr: number[] = []  // Flat list of all legal (not deleted) dependent columns.
  for (const [colKey, thisCol] of derivedColAttributesArray.entries()) {
    if (isDepColumnArr[colKey] && !isDeletedArr[colKey]) {
      dependentColKeysArr.push(colKey)
      const { parsedScryFormula } = thisCol
      invariant(parsedScryFormula, `in updateTableComputedData isDepColumnArr[colKey] is true, but parsedScryFormula is ${parsedScryFormula}`)
      var inputs = getInputArgColKeysAndErrorCheckInputs(parsedScryFormula, colKey, derivedColAttributesArray)
      inputArgsArr[colKey] = inputs
      thisCol.parsedScryFormula = { ...parsedScryFormula, inputArgColKeys: inputs }
    }
  }

  // This block of code tags columns as isErroneous and/or isMissing.
  for (const thisCol of derivedColAttributesArray) {
    const { isDepCol, isDeleted, isBadColName, isBadFormulaSyntax, isBadFormulaColRef, isMismatchedType } = thisCol
    thisCol.isErroneousCol = thisCol.isMissingCol = false   // assumptions
    if (isDepCol) {   // Dependent Column rules:
      if (isDeleted || isBadColName || isBadFormulaSyntax || isBadFormulaColRef) { thisCol.isErroneousCol = true }
      if (isDeleted || isBadFormulaSyntax || isBadFormulaColRef) { thisCol.isMissingCol = true }
    }
    else {  // Independent column rules:
      // (isMismatchedType includes case of 'all data missing')
      if (isDeleted || isBadColName || isMismatchedType) { thisCol.isErroneousCol = true }
      if (isDeleted) { thisCol.isMissingCol = true }
    }
  }
  /*
  NOTE:  At this point there may be dependent columns we believe to be 'error free',
    but potentially will soon become 'isMissing'.  Because 'isMissing' will propagate
    to dependent columns when any input argument (isErroneousCol || isMissingCol).
    Also, there may still be additional 'isErroneous' columns, because we haven't yet
    checked for circular dependencies in otherwise errorless formulas & inputs.

  Determine the proper order for processing dependent columns
  When/if this algorithm gets stuck (cannot find which of a group
  of dependent colKeys must come next), then this MUST be a circular dependency.
  Rare, but important to identify them.
  Every dependent column should appear only once in the outputs:
      dependentColKeysArr.length === colCalculationOrder.length + circularDependencies.length
  */
  const depColumnRecalcOrder: Array<number> = []
  var colKeysToProcess = dependentColKeysArr.slice()
  var nextColKeysToProcess = Array<number>()
  const testFunc = (inputColKey: number): boolean => (colKeysToProcess.includes(inputColKey))
  while (colKeysToProcess.length > 0) {
    for (const colKey of colKeysToProcess) {
      var isSomeInputUnresolved = inputArgsArr[colKey].some(testFunc)
      if (isSomeInputUnresolved) { nextColKeysToProcess.push(colKey) }
      else { depColumnRecalcOrder.push(colKey) }
    }
    if (nextColKeysToProcess.length === 0) { break }  // DONE!  all columns have been moved to colCalculationOrder[]
    if (nextColKeysToProcess.length < colKeysToProcess.length) {   // Yet more columns to move.
      colKeysToProcess = nextColKeysToProcess
      nextColKeysToProcess = []  // restart loop with an empty array
      continue
    }
    // Stuck! Must be a circular reference!
    break
  }
  tableComputedData.depColumnRecalcOrder = depColumnRecalcOrder
  const circularReferences = nextColKeysToProcess

  /////////////////////////////////////////////////////////////////////////
  //    Handling of circular colKey references:
  //    We expect nextColKeysToProcess array to be empty.  Same as saying 'All
  //    dependent columns now appear the the depColumnRecalcOrder array'.
  //    IF NOT, then those columns remaining in nextColKeysToProcess are involved
  //    in some circular reference relationship.  Likely a short circular relationship of
  //    perhaps 2 to 4 colKeys.  But in theory, could be many possible paths over a
  //    large set of colKeys!  Function findShortestCircularReferencePath()
  //    finds the 'shortest' path.  My assumption: "Fixing the shortest path is
  //    both easiest for the user, and likely to break most of any remaining loops".
  //    Hence I will mark ONLY the shortest circular path as isErroneous.  And mark
  //    other colKeys potentially involved in this mess as simply 'isMissing'.
  //    If I am wrong and fixing the shortest loop removes some but not all
  //    circular dependencies, then user will get a new, different shortest loop
  //    and this will also need to be fixed.
  /////////////////////////////////////////////////////////////////////////

  if (circularReferences.length > 0) {
    let shortestCircularPath = findShortestCircularReferencePath(circularReferences, inputArgsArr, isDepColumnArr)
    for (const colKey of circularReferences) {
      derivedColAttributesArray[colKey].isMissingCol = true  // All unresolved dependent colKeys will be 'empty' columns
      if (shortestCircularPath.includes(colKey)) {   // Those involved the in the shortest loop will also show as isErroneous.
        const { parsedScryFormula } = derivedColAttributesArray[colKey]
        invariant(parsedScryFormula, `in updateTableComputedData circularReferences contains colKey ${colKey}, but parsedScryFormula is ${parsedScryFormula}`)
        derivedColAttributesArray[colKey].isBadFormulaColRef = true
        derivedColAttributesArray[colKey].isErroneousCol = true
        parsedScryFormula.errorID =
          createCircularDependencyErrorMessage(colKey, shortestCircularPath, colTitleArr)
      }
    }
  }

  if (isTIMER_ENABLED) { logTime('id_TableComputedData', 'isMissing, isErroneous, recalcOrder, circularDependencies') }

  /////////////////////////////////////////////////////////////////////////
  //  Calculation from:  dependent column formulas
  //                to:  tableValuesDep
  /////////////////////////////////////////////////////////////////////////

  const depValuesOtherArgs = { tableValuesWorking }
  reUsedColKeys = new Array(0)
  reCalcColKeys = new Array(0)
  newMemResults = new Array(0)
  for (const colKeyDepCol of depColumnRecalcOrder) {
    let depValuesParams: DepValuesParams = {
      colKeyDepCol,   // Dependent colKey we are calculating
      isErroneousDepCol: isErroneousColArr[colKeyDepCol],
      isMissingDepCol: isMissingColArr[colKeyDepCol],
      inputColKeys: inputArgsArr[colKeyDepCol],           // colKeys of this formula's inputs
      isErroneousInputs: inputArgsArr[colKeyDepCol].map(colKey => isErroneousColArr[colKey]),
      isMissingInputs: inputArgsArr[colKeyDepCol].map(colKey => isMissingColArr[colKey]),
      parsedScryFormula: derivedColAttributesArray[colKeyDepCol].parsedScryFormula!
    }
    let depValuesRefs = {} //{  parsedScryFormula: derivedColAttributesArray[colKeyDepCol].parsedScryFormula! }
    // Include a reference for each columnData array that is used for filtering
    for (const inputColKey of inputArgsArr[colKeyDepCol]) {
      (depValuesRefs as DepValuesRefsBase)[inputColKey] = tableValuesWorking[inputColKey]
    }
    //John: The Refs here are failing comparison. need to fix.
    let memoFunc = calcDependentColumnValues_memoizedFunc
    let maxNumMemoized = 1
    let { result, isNewResult, newMemArr } = getMemoizedResult(memoizedTableValuesDep[colKeyDepCol], depValuesParams,
      depValuesRefs, depValuesOtherArgs, memoFunc, maxNumMemoized)
    newMemResults[colKeyDepCol] = newMemArr
    if (isNewResult) { reCalcColKeys.push(colKeyDepCol) } else { reUsedColKeys.push(colKeyDepCol) }
    tableValuesWorking[colKeyDepCol] = result.newColData
    derivedColAttributesArray[colKeyDepCol].isMissingCol = result.isMissing
    derivedColAttributesArray[colKeyDepCol].erroneousCells = result.errorObj
  }
  tableComputedData.memoizedTableValuesDep = newMemResults
  if (DEBUG && reUsedColKeys.length > 0) {
    console.log(`  Reuse depColumn calculations for colKeys ${create_sKeyArrStrg_from_seriesOrder(reUsedColKeys)}`)
  }
  if (DEBUG && reCalcColKeys.length > 0) {
    console.log(`  Calc  depColumn calculations for colKeys ${create_sKeyArrStrg_from_seriesOrder(reCalcColKeys)}`)
  }
  if (isTIMER_ENABLED) { logTime('id_TableComputedData', 'Calculate dependent column values.') }

  /////////////////////////////////////////////////////////////////////////
  //  Row Names (key columns) Error Checking:
  //
  //     1) Identify missing and duplicate row names. These are saved in 2 data
  //        structures, so we have options when building error messages.
  //     2) We need to do this BEFORE row sorting, because erroneous cells
  //        are always sorted to the top of a column.  Hence, identifying
  //        erroneous cells (for any reason) must precede sorting.
  //     3) We will create an array of the 'Key columns'
  //        If there are none, then missing and duplicate row names structures
  //        are just 'empty'.  No 'Key columns' error message is easy to
  //        create in the UI interface.
  //        If there are 1 to 'n' columns, then the order will ALWAYS be
  //        sequential for lowest to largest colKey.   Doesn't matter if
  //        the user rearranges the column locations.  Our algorithm here
  //        should be independent of the user's key column's positions.
  //
  /////////////////////////////////////////////////////////////////////////

  const keyColumns = new Array(0)
  for (let i = 0; i < tableValuesWorking.length; i++) {
    if (!isDeletedArr[i] && isKeyColumnArr[i]) { keyColumns.push(i) }
  }

  if (keyColumns.length > 0) {
    let rowNameErrorParamsObj: RowNameErrorParams = { keyColumns, numRows: numRowsUnfiltered }
    let rowNameErrorRefsObj: RowNameErrorRefs = {}
    for (const keyColumn of keyColumns) {
      rowNameErrorRefsObj[keyColumn] = tableValuesWorking[keyColumn]
    }
    let rowNameErrorOtherObj: RowNameErrorOther = {}
    let maxNumMemoized = 4  // Enough for the user to experiment with additional keyCols to find unique keys
    let memoFunc = getErroneousRowNames2_memoizedFunc
    let { result, isNewResult, newMemArr } = getMemoizedResult(memoizedErroneousRowNames, rowNameErrorParamsObj,
      rowNameErrorRefsObj, rowNameErrorOtherObj, memoFunc, maxNumMemoized)
    tableComputedData.memoizedErroneousRowNames = newMemArr
    var duplicateRowNames = tableComputedData.duplicateRowNames = result.duplicateRowNames
    var missingRowNames = tableComputedData.missingRowNames = result.missingRowNames
    tableComputedData.doesKeyColumnExist = result.doesKeyColumnExist
    if (DEBUG) {
      (isNewResult) ? console.log(`  Calc  erroneous row names`)
        : console.log(`  Reuse erroneous row names`)
    }
  } else {
    duplicateRowNames = tableComputedData.duplicateRowNames = {}
    missingRowNames = tableComputedData.missingRowNames = {}
    tableComputedData.doesKeyColumnExist = false
  }

  if (isTIMER_ENABLED) { logTime('id_TableComputedData', 'RowNames isDuplicate, isMissing error checking.') }


  /////////////////////////////////////////////////////////////////////////
  //  Create the getTableValue accessorFunction.
  //  This is the fundamental function to retrieve internal values (no formatting).
  //  At the very end of this module, there are various wrappers around this
  //  function, designed for the unique needs of various modules.
  /////////////////////////////////////////////////////////////////////////


  const getTableValue = tableComputedData.getTableValue =
    (colKey: number, rowKey: number, hideErroneousValues: boolean = false): AccessCellStringReturnType => {
      var value = tableValuesWorking[colKey][rowKey]
      //  isDuplicateRowName && hideErronewousValues ?
      //  Then we will still display the rowName (NOT convert it to an empty string)
      //  Hence if user looks closely they will find one or more duplicate row Names.
      //  This is OK because 'unique row names' are a considered a 'good practice', but
      //  not actually required for the functioning of our table.
      //  In other words, highlight row names in 'red' IFF we are 'showing ErroneousValues' and name is duplicate key.
      const isKey = isKeyColumnArr[colKey]
      const isMissingRowName = (isKey && missingRowNames[rowKey] !== undefined)
      const isErroneousCell = erroneousCellsArr[colKey][rowKey] === ''
      const isDuplicateRowName = (!hideErroneousValues && isKey && duplicateRowNames[rowKey] !== undefined)
      const isErroneous = isDuplicateRowName || isMissingRowName || isErroneousCell
      if (isErroneous && hideErroneousValues) { value = '' }
      return { value, isErroneous, isMissingRowName }
    }

  /////////////////////////////////////////////////////////////////////////
  //  Filter rows
  /////////////////////////////////////////////////////////////////////////

  // Next structure fast to calculate.  Not worth memoization code, even though
  // we wish to compare new structure to prior structure.
  let newDerivedFilterRules = createDerivedFilterRuleArray(rowFilters, derivedColAttributesArray)
  // This next value used to potentially set a new scrollTop position (later in the flow)
  let didDerivedFiltersChange = !isEqual(tableComputedData.derivedFilterRuleArray, newDerivedFilterRules)
  tableComputedData.derivedFilterRuleArray = newDerivedFilterRules

  let filterParamsObj = {
    derivedFilterRules: newDerivedFilterRules,
    numRows: numRowsUnfiltered,
  }
  let filterRefsObj: ColKeyToColValuesMap = {}
  // Include a refObj for each columnData array that is used for filtering
  for (const thisRule of filterParamsObj.derivedFilterRules) {
    var { colKey: colKeyVal, enabled } = thisRule
    if (colKeyVal === -1 || !enabled) { continue }   // skip empty and disabled fitler rules.
    filterRefsObj[colKeyVal] = tableValuesWorking[colKeyVal]
  }
  let filterOtherArgsObj = { DEBUG, getTableValue }
  let maxNumMemoized = 8  // Enough for the user play with three on/off selections (venn diagrams, ... )
  let memoFunc = filterRows_memoizedFunc
  let { result, isNewResult, newMemArr } = getMemoizedResult(memoizedFilteredRowKeysArr, filterParamsObj, filterRefsObj,
    filterOtherArgsObj, memoFunc, maxNumMemoized)
  tableComputedData.memoizedFilteredRowKeysArr = newMemArr
  tableComputedData.filteredRowKeys = result.filteredRowKeys
  tableComputedData.filterRuleCounts = result.filterRuleCounts
  if (DEBUG) {
    if (isNewResult) {
      console.log(`  Calc  filteredRowKeys`)
    } else {
      console.log(`  Reuse filteredRowKeys`)
    }
  }
  if (isTIMER_ENABLED) { logTime('id_TableComputedData', 'Create filteredRowKeys') }

  /////////////////////////////////////////////////////////////////////////
  //  Sort Table rows (all rows)
  //
  //  SORT ORDER depends on many factors
  //     1) changes if/when we push a colHeader sort button -> change in rowSortColIds
  //     2) how we wish to sort potential erroneous values -> change in hideErroneousValues
  //     3) any change in the column data values
  //     4) any change in the column data type
  //     5) any change in filtering.
  //
  //  HOWEVER -- we do NOT want a resort every time someone touches the data.
  //             we will re-sort ONLY if a colHeader sort button is pushed
  //  Approach -- We pass sort all the information in 1-5 above.  But it
  //             is passed the the otherArgsObj.
  //  ONLY the rowSortColIds is passed in the ParamsObj and ONLY a change
  //             in this data structure will re-sort the table.
  /////////////////////////////////////////////////////////////////////////

  if (isSortable === false) {
    var sortedRowKeys = list(numRowsUnfiltered - 1)
  }
  else {  // We sort the unfiltered row set.
    let sortParamsObj: SortParams = { rowSortColIds }
    let sortRefsObj: SortRefs = {}
    let sortOtherArgsObj: SortOtherArgs = {
      hideErroneousValues,
      internalDataTypes: rowSortColIds.map(
        thisStringIndex => (derivedColAttributesArray[Math.abs(Number(thisStringIndex))].internalDataType)
      ),
      numRowsUnfiltered,
      getTableValue,
    }
    let maxNumMemoized = 4  // To handle ascending/descending clicks between two columns
    let { result, isNewResult, newMemArr } = getMemoizedResult(memoizedRowOrder, sortParamsObj, sortRefsObj,
      sortOtherArgsObj, sortRows, maxNumMemoized)
    tableComputedData.memoizedRowOrder = newMemArr
    if (DEBUG) {
      if (isNewResult) { console.log(`  Calc  Sorted rows`) }
      else { console.log(`  Reuse Sorted rows`) }
    }
    if (isTIMER_ENABLED) { logTime('id_TableComputedData', 'Create Sorted RowKeys') }
    sortedRowKeys = tableComputedData.sortedRowKeys = result.sortedRowKeys
  }

  /////////////////////////////////////////////////////////////////////////
  //                        Calculating Column Stats
  //
  //  This can be time consuming, but also aren't critical that we have them 'immediately'.
  //  Hence, we use a workerThread:
  //
  //  1. We use the same support function for calculating unfiltered stats, and filtered stats.  Filtered stats
  //     are considered an example of the general case.  Hence filtered and unfiltered stats are
  //     all kept in the same memoization array.
  //
  //  2. We will only calc '1' column of stats at a time.  Worse case tenths of a second.
  //     But this is acceptable as the can't tell from a simple single 'col click'.
  //
  //  3. Use '1' statsMemoizationArr.  For all types of filtering (including unfiltered stats),
  //     over all columns.  The stats dataObj is very small compared to the work effort.
  //     But still not critical we have results immediately.  I will allocated a array
  //     length of 3 x NumColumns.  And 'if' I'm interested and stats over many different
  //     filtering subsets (e.g. Venn Diagram), then 3*numCols is still plenty of working space.
  //
  /////////////////////////////////////////////////////////////////////////

  // Default stats in the case of sessionState.activeStatsColKey === -1
  // Which by our definition means no 'activeCol' is currently selected
  // We set the activeCol's stats to null
  // Null is our signal to NOT render the stats.  Space is allocated, but nothing is visible.
  tableComputedData.stats_OfActiveStatsColKey = null
  let activeStatsColIndex = colOrder.indexOf( activeStatsColKey)
  let isActiveStatsColVisible = activeStatsColKey >=0 && 
                                activeStatsColKey < numCols &&
                                !isHidden_ByColIndex[activeStatsColIndex] &&
                                isDeletedArr[activeStatsColKey] === false
  tableComputedData.stats_LegalActiveStatsColKey = (isActiveStatsColVisible) ? activeStatsColKey : -1
  if (isActiveStatsColVisible) {
    const statsCol = derivedColAttributesArray[activeStatsColKey]
    // Stats extend an additional dataType to custom format 'boolTrueFalse' formatting style.
    let statsDataType = statsCol.internalDataType as StatsDataType
    if (statsDataType === 'number' && statsCol.formatRule === 'boolTrueFalse') {
      statsDataType = 'boolTrueFalse'
    }
    let statsHashParamsObj = {
      colKey: activeStatsColKey,
      statsDataType: statsCol.internalDataType,
    }
    let statsHashRefsObj = {
      columnRef: tableValuesWorking[activeStatsColKey],
      erroneousCells: erroneousCellsArr[activeStatsColKey],
      rowKeys: sortedRowKeys,
    }
    let statsParamsObj = { statsHashCode: hashParamsAndRefs(statsHashParamsObj, statsHashRefsObj) }
    let statsRefsObj = {}
    let statsOtherArgsObj = {
      ...statsHashParamsObj,
      ...statsHashRefsObj,
      DEBUG,
      tableid: table.id
    }
    let memoFunc = calcColumnStats_async
    maxNumMemoized = 3 * numCols
    let { result, isNewResult, newMemArr } = getMemoizedResult(memoizedStats, statsParamsObj, statsRefsObj,
      statsOtherArgsObj, memoFunc, maxNumMemoized)
    tableComputedData.memoizedStats = newMemArr
    tableComputedData.stats_OfActiveStatsColKey = (result === null)
      ? tableComputedData.stats_OfActiveStatsColKey = null
      : tableComputedData.stats_OfActiveStatsColKey = result.columnStats
    if (DEBUG) {
      if (isNewResult) {
        console.log(`  Calc  ColumnStats[${activeStatsColKey}]`)
      } else {
        console.log(`  Reuse ColumnStats[${activeStatsColKey}]`)
      }
    }
    if (isTIMER_ENABLED) { logTime('id_TableComputedData', 'Calculate unfiltered column stats.') }
  }


  /////////////////////////////////////////////////////////////////////////
  //  Table layout: width, height, style objects
  /////////////////////////////////////////////////////////////////////////

  // layoutProps =
  //   - the set of attributes that require a re-calc of width/height/style objects (a relayout).
  //   - the input properties into the layoutCalculator functions.
  //   - the 'current layout' properties saved in responsive state as 'inputProps'.
  //   - the object we 'perturb' in responsive state to get modified layouts between react renders.
  const layoutProps: TableLayoutProps = {
    ...deepClone(tablelook.attributes), // deep clone is needed for now because otherwise we end up
    // trying to write to the actual state, which is read-only.
    colWidth_ByColIndex,
    isHidden_ByColIndex, 
    hiddenVal_ByColIndex,
    isDeletedArr, 
    sortedRowKeys,
    numRowsUnfiltered,
    tableLayoutHeight: tableHeight,
    tableLayoutWidth: tableWidth,
    tableTitle: table.attributes.tableTitle,
    publisherTitle: table.attributes.publisherTitle,
    createdDate: table.attributes.createdDate,
    updatedDate: table.attributes.updatedDate,
    pinnedRowKeys: tablelook.attributes.pinnedRowKeys,
    canEdit,
    isPublisherRendered,
    isTableGridRendered,
  }
  tableComputedData.layoutProps = layoutProps

  // These are fast to calculate;  No memoization at this time.
  const w = tableComputedData.widthObj = widthCalculator(layoutProps)
  const h = tableComputedData.heightObj = heightCalculator(layoutProps)
  tableComputedData.styleObj  = styleCalculator(layoutProps)
  tableComputedData.scrollLeft = Math.max( 0, Math.min( scrollLeftSessionState, w.movingRequired - w.movingAllocated))
  tableComputedData.scrollTop  = Math.max( 0, Math.min( scrollTopSessionState , h.dataRequired   - h.dataAllocated  ))
  if (isTIMER_ENABLED) { logTime( 'id_TableComputedData', 'Create widthObj, heightObj, styleObj' ) }

  /////////////////////////////////////////////////////////////////////////
  //  Values needed to be displayed for the 'unhide' controls.
  //  These are the 'hidden' columns preceeding a visible 'parent' column
  //  We need to delay this calculation until after the widthObj is known.
  //  Because how we display hidden columns is slighly different, depending
  //  on isCombinedTable.
  /////////////////////////////////////////////////////////////////////////

  const priorHidden_ByColIndex = tableComputedData.priorHidden_ByColIndex = Array(colOrder.length).fill(0)
  let isHiddenAccumulator = 0
  for ( let colIndex = 0; colIndex<colOrder.length; colIndex++ ) {
    if ( !w.isCombinedTable && colIndex === numLockedCols) { isHiddenAccumulator = 0 }  // first moving column initialed to '0' priorHiddenCols
    priorHidden_ByColIndex[colIndex] = isHiddenAccumulator
    isHiddenAccumulator = isHidden_ByColIndex[colIndex] ? isHiddenAccumulator+1 : 0
  }
  // Last column may have 0 to n prior hidden children.
  // BUT! Last column itself MAY also be hidden.
  // If it is hidden then the 'unhide' control will appear to the right of the table (above vert scroll)
  if ( lastVal(isHidden_ByColIndex) ) { 
    // unhide control value is 'prior' hidden children columns, plus '1' for the hidden parent itself!
    priorHidden_ByColIndex[numCols-1]++
    tableComputedData.numColsHiddenRightOfMovingTable = priorHidden_ByColIndex[numCols-1]
  } else {
    tableComputedData.numColsHiddenRightOfMovingTable = 0
  }
  // Same argument (as above) for the last lockedColumn
  if ( !w.isCombinedTable && numLockedCols > 0 && isHidden_ByColIndex[numLockedCols - 1] ) { 
    priorHidden_ByColIndex[numLockedCols-1]++
    tableComputedData.numColsHiddenInGapBetweenTables = priorHidden_ByColIndex[numLockedCols-1]
  } else {
    tableComputedData.numColsHiddenInGapBetweenTables = 0
  }

  /////////////////////////////////////////////////////////////////////////
  //                        statsBarLayout
  //
  //  The rendered stats CAN change, even if we begin with the same memoizedStats result.
  //  Because the rendered values depend on:
  //        - user choice of formatting
  //        - tableStyle.fontSize
  //        - available window width
  //        - whether you are tableOwner or not (effects how erroneous values are handled)
  //
  //  I desire to ONLY re-render this object when the text changes (due to change
  //  in underlying stats, or any of the above).
  //
  //  I can avoid a re-render by asking 'did something change' either here, or in the react
  //  component.  However, easiest way to ask these question is to use the existing
  //  memoization wrapper.  Where I can list out my params and refs, and return a
  //  previous result if no change.  In this case I'm using memoization NOT to
  //  save computational work, but to ask 'shouldComponentUpdate'.  An easier approach
  //  then writing custom react update functions.
  ///////////////////////////////////////////////////////////////////////////

  // This next value is often 'null', in which case the default layout is 'empty'
  // and the statsBar has nothing to render. (blank area)
  tableComputedData.statsBarLayout = getDefaultStatsBarLayout()  // Assumption; nothing to render.
  let activeStats = tableComputedData.stats_OfActiveStatsColKey
  if (activeStats) {
    let { colTitle, colDataType, formatRule, formattingObj } = derivedColAttributesArray[activeStatsColKey]
    let statsBarLayoutParams = {
      colTitle, colDataType, formatRule, formattingObj, canEdit,
      fontSize: tableComputedData.styleObj.cellFontSize,
    }
    let statsBarLayoutRefs = { columnStats: activeStats }
    maxNumMemoized = 2 * numCols
    let memoFunc = createStatsBarLayout_memoizedFunc
    let { result, isNewResult, newMemArr } = getMemoizedResult(tableComputedData.memoizedStatsBarLayout,
      statsBarLayoutParams, statsBarLayoutRefs, {}, memoFunc, maxNumMemoized)
    tableComputedData.memoizedStatsBarLayout = newMemArr
    tableComputedData.statsBarLayout = result.statsBarLayout

    if (DEBUG) {
      if (isNewResult) { console.log(`  Calc  statsBarLayout`) }
      else { console.log(`  Reuse statsBarLayout`) }
    }
    if (isTIMER_ENABLED) { logTime('id_TableComputedData', 'Create statsBarLayout') }
  }

  /////////////////////////////////////////////////////////////////////////
  //  If filtering changed, we can find a 'better' scrollTop
  //  position.  ( We over-ride the current resource 'scrollTop' value)
  //  Not a requirement, but a polite touch.
  /////////////////////////////////////////////////////////////////////////

  if (didDerivedFiltersChange && priorTableComputedData) {
    var currentScrollTop = tablelook.attributes.minorState.scrollTop
    layoutProps.minorState.scrollTop = calcNewScrollTop(tableComputedData.sortedRowKeys,
      priorTableComputedData.sortedRowKeys, tableComputedData.heightObj, currentScrollTop)
    if (DEBUG) {
      console.log(`    DerivedFiltersChanged; Calc new ScrollTop`)
    }
  }


  /////////////////////////////////////////////////////////////////////////
  //  Create Accessor functions to retrieve the tableData by (row,col)
  /////////////////////////////////////////////////////////////////////////

  const { STRING_CELL_MAX_DISPLAYED_LENGTH } = constants

  // From (rowKey, colKey) return the formatted HTML version of the table value.
  // This is used for pinnedRows
  const getCellHTML_fromColKeyAndRowKey = (colKey: number, rowKey: number): AccessCellHTMLReturnType => {
    var { value: tableVal, isErroneous, isMissingRowName } = getTableValue(colKey, rowKey, hideErroneousValues)
    var value: HTMLElement | string = ''
    switch (internalDataTypeArr[colKey]) {
      case 'number':
        if (!isErroneous) { value = numberFormat(tableVal, formattingObjArr[colKey], 'html') as HTMLElement }
        break
      case 'hyperlink':
        value = getHyperlinkLabel(tableVal) as string
        break
      default:
        value = stringFormat(tableVal, formattingObjArr[colKey])
        let lineFeedIndex = value.indexOf('\n')
        if (lineFeedIndex >= 0) { value = value.slice(0, lineFeedIndex) }
        if (value.length > STRING_CELL_MAX_DISPLAYED_LENGTH) {
          value = value.slice(0, STRING_CELL_MAX_DISPLAYED_LENGTH) + ' ...'
        }
    }
    return { value, isErroneous, isMissingRowName }
  }
  tableComputedData.getCellHTML_fromColKeyAndRowKey = getCellHTML_fromColKeyAndRowKey

  // From (rowKey, colKey) return the formatted text version of the table value.
  // This is used for outputting CSV files cells
  tableComputedData.getCellText_fromColKeyAndRowKey = (colKey: number, rowKey: number): AccessCellStringReturnType => {
    var { value: tableVal, isErroneous, isMissingRowName } = getTableValue(colKey, rowKey, hideErroneousValues)
    var returnVal: string | HTMLElement = ''
    switch (internalDataTypeArr[colKey]) {
      case 'number':
        if (!isErroneous) { returnVal = numberFormat(tableVal, formattingObjArr[colKey], 'noHtml') as string }
        break
      case 'hyperlink':
        returnVal = getHyperlinkLabel(tableVal)
        break
      default:
        returnVal = stringFormat(tableVal, formattingObjArr[colKey])
        let lineFeedIndex = returnVal.indexOf('\n')
        if (lineFeedIndex >= 0) { returnVal = returnVal.slice(0, lineFeedIndex) }
        if (returnVal.length > STRING_CELL_MAX_DISPLAYED_LENGTH) {
          returnVal = returnVal.slice(0, STRING_CELL_MAX_DISPLAYED_LENGTH) + ' ...'
        }
    }
    return { value: returnVal, isErroneous, isMissingRowName }
  }

  // From (rowKey, colKey) return the formatted text version of the table value.
  // This is used for outputting CSV files cells
  tableComputedData.getCellText_noPrefixSuffixCommas = (colKey: number, rowKey: number): AccessCellStringReturnType => {
    var { value, isErroneous, isMissingRowName } = getTableValue(colKey, rowKey, hideErroneousValues)
    let formattingObj = {
      ...formattingObjArr[colKey],
      useCommas: false,
      prefix: '',
      suffix: '',
      prefixStrg: '',
      suffixStrg: ''
    }
    switch (internalDataTypeArr[colKey]) {
      case 'number':
        if (!isErroneous) { value = numberFormat(value, formattingObj, 'noHtml') as string }
        break
      case 'hyperlink':
        value = getHyperlinkLabel(value)
        break
      default:
        value = stringFormat(value, formattingObjArr[colKey])
        let lineFeedIndex = value.indexOf('\n')
        if (lineFeedIndex >= 0) { value = value.slice(0, lineFeedIndex) }
        if (value.length > STRING_CELL_MAX_DISPLAYED_LENGTH) {
          value = value.slice(0, STRING_CELL_MAX_DISPLAYED_LENGTH) + ' ...'
        }
    }
    return { value, isErroneous, isMissingRowName }
  }

  // From (rowIndex, colKey) return the formatted HTML version of the table value.
  // This is what is displayed in the sorted table cells
  tableComputedData.getCellHTML_fromColKeyAndRowIndex = (colKey: number, rowIndex: number): AccessCellHTMLReturnType => {
    if (rowIndex >= numRowsUnfiltered || rowIndex < 0) {
      // this is a common case because rowGroups may extend beyond end of valid rows.
      return { value: '', isErroneous: false/*, url:''*/, isMissingRowName: false }
    }
    const rowKey = sortedRowKeys[rowIndex]
    return getCellHTML_fromColKeyAndRowKey(colKey, rowKey)
  }


  stopTimer('id_TableComputedData')
  return { tableComputedData }
}

export default updateTableComputedData_memoizedFunc
